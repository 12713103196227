import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Card from '../../components/Card/Card';
import CardHeader from '../../components/Card/CardHeader';
import TabPanel from '../../components/UI/TabPanel';
import VideoConferencesCardMain from './VideoConferencesCardMain';
import VideoConferencesCardHistory from './VideoConferenceCardHistory';
import { setOperInfo } from '../../utils/functions/others';

function VideoConferencesCard(props) {
  const {
    setShow, meetingId, handler,
  } = props;

  const tabs = ['Главная', 'История']; // вкладки
  const [searchParams, setSearchParams] = useSearchParams(); // параметры поиска из url
  const [choice, setChoice] = useState(); // состояние выбора вкладки
  const content = {
    Главная: <VideoConferencesCardMain handler={handler} closeHandler={closeHandler} meetingId={meetingId} />,
    История: <VideoConferencesCardHistory meetingId={meetingId} />,
  };

  useEffect(() => {

  }, [searchParams]);

  // закрыть карточку
  function closeHandler() {
    setOperInfo({ subject_id: null }); // сброс id категории в оперативной информацию
    setSearchParams(''); // сброс параметром url
    setShow(false); // закрытие карточки
  }

  // обработка выбора вкладки
  function choiceHandler(tab) {
    setChoice(tab); // установить выбор
  }

  return (
    <Card setShow={closeHandler} id="video-conference__card">
      <CardHeader>
        <TabPanel tabs={tabs} choice={choice} choiceHandler={choiceHandler} />
      </CardHeader>
      {content[choice]}
    </Card>
  );
}

export default VideoConferencesCard;
