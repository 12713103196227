import React, { useState } from 'react';
import { catchHandler } from '../../utils/error_handling/error_handling';

/**
* @component Хедер таблицы - строка сортировки - ячейка сортировки
@prop {field} string Наименование поля
@prop {title} string Заголовок поля
@prop {mobile_hide} boolean // параметр скрытия при мобильном отображениии
@prop {array} array Массив данных отображаемый в таблице
@prop {setArray} function Обновление состояния массива отображаемого в таблице
@prop {sortField} string Сортируемое поле
@prop {setSortField} function Обновление сортируемого поля
@prop {ext_sorting} function Внешний обработчик сортировки
*/

// Таблица - Хедер таблицы - строка сортировки - ячейка сортировки
function SortCell(props) {
  const {
    field, title, array, mobile_hide, setArray, sortField, setSortField, ext_sorting,
  } = props; // свойства компонента

  const [defaultArray, setDefaultArray] = useState([]); // исходный отображаемый массив

  // Выбор функции для сортировки массива
  function chooseSortFunction(field) {
    // Если есть внешняя функция сортировки
    if (ext_sorting) {
      let sorting = ''; // определение направления сортировки

      if (sortField === (`${field}-reverse`)) { // Если состояние сортировки по убыванию
        sorting += 'asc'; // устанавливается по возрастанию
        setSortField(field); // установить состояние сортировки - "имя поля"
      } else if (sortField === field) { // Иначе если состояние сортировки по возрастанию
        sorting += ''; // сбросить состояние сортировки
        setSortField(''); // сбросить состояние сортировки
      } else { // Иначе значит сортировка выключена
        sorting += 'desc'; // устанавливается по убыванию
        setSortField(`${field}-reverse`); // установить состояние сортировки - "имя поля"-reverse
      }

      // Вызвать внешний обработчик
      ext_sorting(field, sorting);

      // Если внешней функции сортировки нет - выполнить локульную
    } else sortByField(field);
  }

  // Сортировка по колонке
  function sortByField(field) {
    try {
      // Определить значение ключа объекта
      function defineData(data, field) {
        const keys = field.split('.'); // разделить ключ схемы через точку
        if (!data) return null;
        // Углубиться в объект и получить данные
        function deepIntoTheObject(data, deep) {
          // Если глубина равна длине массива вложенности ключей
          if (deep === (keys.length)) return data;
          // Иначе берем зачение текущей глубины и идем дальше
          return deepIntoTheObject(data?.[keys?.[deep]], deep + 1);
        }

        // Вернуть значение
        return deepIntoTheObject(data, 0);
      }

      // Функция сортировки применяется к копии исходного массива для предотвращения мутации
      const sortArray = array.map((item) => item); // копия массива

      // Если состояние сортировки по убыванию - устанавливается по возрастанию
      if (sortField === (`${field}-reverse`)) {
        const increase = sortArray.sort((a, b) => { // сортировка по возрастанию
          const aData = defineData(a, field);
          const bData = defineData(b, field);
          if (!bData) return -1;
          if (aData < bData) return 1;
          if (aData > bData) return -1;
          return 0;
        });
        setArray(increase); // отобразить отсортированный массив
        setSortField(field); // установить состояние сортировки - "имя поля"

        // Иначе если состояние сортировки по возрастанию
      } else if (sortField === field) {
        setArray(defaultArray); // устанавливается отфильтрованный
        setSortField(''); // сбросить состояние сортировки
      } else { // Иначе устанавливается по убыванию
        setDefaultArray(array); // перед началом сортировки установить массив по умолчанию
        const decrease = sortArray.sort((a, b) => { // сортировка по убыванию
          const aData = defineData(a, field);
          const bData = defineData(b, field);
          if (!aData) return -1;
          if (aData < bData) return -1;
          if (aData > bData) return 1;
          return 0;
        });
        setArray(decrease); // записать в хранилище и отобразить отсортированный массив
        setSortField(`${field}-reverse`); // установить состояние сортировки - "имя поля"-reverse
      }
    } catch (error) {
      catchHandler(error, 'sortByField'); // обработка ошибок
    }
  }

  // Определение классов стилей
  const defineClass = () => {
    let result = 'upu-table__table-button';
    // если состояние сортировки - "имя поля" или "имя поля"-reverse - кнопка активна
    if (sortField === field || sortField === (`${field}-reverse`)) result += ' upu-table__table-button_active';
    if (mobile_hide) result += ' mobile_hide';
    return result;
  };

  // Определение значка сортировки
  const defineBadge = () => {
    let result = '↑↓'; // состояние по умолчанию
    if (sortField === field) result = '↓'; // если состояние сортировки равно полю - стрелка вниз
    if (sortField === `${field}-reverse`) result = '↑'; // если состояние сортировки равно полю reverse - стрелка вверх
    return result;
  };

  return (
    <td className={defineClass()} onClick={() => chooseSortFunction(field)}>
        {title}
        <span className="upu-table__sort-bage">{defineBadge()}</span>
    </td>
  );
}

export default SortCell;
