import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { setOperInfo } from '../../../utils/functions/others';
import TabPanel from '../../../components/UI/TabPanel';
import Logs from './Logs';
import Settings from './Settings';
import Schemes from '../Schemes/Schemes';
import CardBody from '../../../components/Card/CardBody';
import CardHeader from '../../../components/Card/CardHeader';
import Card from '../../../components/Card/Card';

/**
 * @component Администрирование базы знаний - редактирование подразделений - карточка подразделения
 * @prop {setShowCard} function - обновление состояния отображения карточки (открыть/закрыть)
 * @prop {getDivisions} function - получение подразделений для обновления таблицы
 * @prop {openedDivision} object - объект текущего направления открытого в карточке
 * @prop {setOpenedDivision} function - обновление состояния открытого подразделения
*/
function DivisionCard(props) {
  const {
    setShowCard, getDivisions, openedDivision, setOpenedDivision,
  } = props; // свойства передаваемые компоненту при вызове
  const divisionTypeId = useSelector((state) => state.kb.essence_types_id.division); // id типа сущности подразделения

  const [choice, setChoice] = useState(''); // состояние выбора вкладки
  const tabs = ['Настройки', 'Схемы', 'История']; // вкладки

  // Отменить редактирование или создание и закрыть карточку
  function cancelAndClose() {
    setShowCard(false); // закрыть карточку
    setOperInfo({ subject_id: null }); // сбросить id направления в оперативной информации
  }

  // обработка выбора вкладки
  function choiceHandler(tab) {
    setChoice(tab); // установить выбор
  }

  return (
    <Card id="divisions__card" setShow={cancelAndClose}>
      {/* Хедер карточки */}
      <CardHeader>
        <TabPanel tabs={tabs} choice={choice} choiceHandler={choiceHandler} />
      </CardHeader>

      {/* Тело карточки */}
      <CardBody>
        {/* Вкладка Настройки */}
        {choice === 'Настройки' && (
        <Settings
          getDivisions={getDivisions}
          setShowCard={setShowCard}
          openedDivision={openedDivision}
          setOpenedDivision={setOpenedDivision}
        />)}

        {/* Вкладка Схемы */}
        {choice === 'Схемы' && (
        <Schemes
          openedDivision={openedDivision}
        />)}

        {/* Вкладка Истории */}
        {choice === 'История' && (
        <Logs
          id={openedDivision.id}
          type={divisionTypeId}
        />)}
      </CardBody>
    </Card>
  );
}

export default DivisionCard;
