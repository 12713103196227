import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import ServiceBody from '../../../components/Service/ServiceBody';
import { clientSendData } from '../../../utils/functions/requests';
import { catchHandler } from '../../../utils/error_handling/error_handling';
import { alertFunction } from '../../../utils/functions/alertFunction';
import Input from '../../../components/UI/Input';
import Button from '../../../components/UI/Button/Button';
import './tempaccess.scss';

function TempAccessProlongation() {
  const { id } = useParams(); // параметр ссылки
  const [prologationInfo, setProlongationInfo] = useState({});
  const [prolongationDate, setProlongationDate] = useState(null);
  const [noProlongation, setNoProlongation] = useState(null);

  useEffect(() => {
    getTmpAccessProlongationData();
  }, []);

  async function getTmpAccessProlongationData() {
    try {
      // Формируем данные для запроса
      const reqData = {
        type: 'getTmpAccessProlongationData',
        task_id: id,
      };
        // Отправляем POST запрос на сервер и ждем ответ
      const result = await clientSendData('POST', '/get_tmp_access_prolongation_data', reqData);
      // Если запрос успешен, получаем типы временных доступов
      if (result?.success) {
        if (result.data.length > 0) {
          setProlongationInfo(result.data[0]);
          if (result.data[0].status_id !== 1) {
            setNoProlongation('helpDesk');
          } else if (result.data[0].status_id === 1) {
            setNoProlongation('prolongation');
          } else if (result.data[0].status_id === 4) {
            setNoProlongation('noTask');
          }
        } else {
          setNoProlongation('noTask');
        }
      } else {
        await alertFunction('something_went_wrong', 'client');
      }
    } catch (error) {
      // Обрабатываем ошибку
      catchHandler(error, 'getTmpAccessProlongationData');
    }
  }

  async function handleProlongation() {
    try {
      // Формируем данные для запроса
      const reqData = {
        type: 'saveProlongationData',
        task_id: id,
        prolongationDate,
      };
        // Отправляем POST запрос на сервер и ждем ответ
      const result = await clientSendData('POST', '/save_prolongation_data', reqData);
      // Если запрос успешен, получаем типы временных доступов
      if (result?.success) {
        await alertFunction('save_settings', 'client');
        await getTmpAccessProlongationData();
      } else {
        await alertFunction('something_went_wrong', 'client');
      }
    } catch (error) {
      // Обрабатываем ошибку
      catchHandler(error, 'saveProlongationData');
    }
  }

  return (
    <ServiceBody id="prolongation">
        {(() => {
          switch (noProlongation) {
            case 'prolongation': {
              return (
                <div className="prolongation-form">
                    <p>{`Продление ресурса ${prologationInfo.title} ${prologationInfo.resource}`}</p>
                    <br />
                    <p><Input onChange={(e) => setProlongationDate(e.target.value)} type="date" max={prologationInfo.max_date} /></p>
                    <p><Button onClick={handleProlongation}>Продлить</Button></p>
                </div>);
            }
            case 'noTask':
              return <div><p>Запрашиваемая задача не найдена</p></div>;
            case 'prolonged':
              return <div><p>{`Вы уже продлили ресурс ${prologationInfo.title}`}</p></div>;
            case 'helpDesk':
              return (
                <div>
                    <p>
                        {`Продлить ${prologationInfo.title} можно только через Службу ИТ-поддержки `}
                        <a href="mailto:sd@unicon.ru" className="clickable">sd@unicon.ru</a>
                    </p>
                </div>);
            default: return <p>Запрашиваемая задача не найдена</p>;
          }
        })()}
    </ServiceBody>
  );
}

export default TempAccessProlongation;
