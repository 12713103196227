/* eslint-disable no-console */
import React, { useState } from 'react';
import CardBody from '../components/Card/CardBody';
import Input from '../components/UI/Input';
import Select from '../components/UI/Select';
import Toggle from '../components/UI/Toggle';
import Button from '../components/UI/Button/Button';

function TabUI() {
  const selectArr = [
    {
      id: 1,
      key: '0',
      title: 'картошка',
      side: 'говядина',
    },
    {
      id: 2,
      key: '1',
      title: 'рис',
      side: 'кролик',
    },
    {
      id: 3,
      key: '2',
      title: 'гречка',
      side: 'курица',
    },
    {
      id: 4,
      key: '3',
      title: 'макароны',
      side: 'рыба',
    },
    {
      id: 5,
      key: '4',
      title: 'капуста',
      side: 'индейка',
    },
  ];

  const [obj, setObj] = useState({ inputVal: '', activeOption: '', toggleValue: false });

  return (
    <CardBody>
      <h1>Choose options</h1>
        <Input value={obj.inputVal} onChange={(e) => setObj({ ...obj, inputVal: e.target.value })} />
        <Select
          id="select1"
          defaultValue={obj.activeOption}
          array={selectArr}
          onChoose={(e) => setObj({ ...obj, activeOption: e.title })}
        />
        <Select
          id="select2"
          defaultValue={obj.activeOption}
          array={selectArr}
          onChoose={(e) => setObj({ ...obj, activeOption: e.title })}
        />
        <Toggle onClick={() => setObj({ ...obj, toggleValue: !obj.toggleValue })} state={obj.toggleValue?.toString()} />
        {obj.toggleValue && (
           <Button
             onClick={() => console.log(obj)}
           >
           Send
           </Button>
        )}
    </CardBody>
  );
}

export default TabUI;
