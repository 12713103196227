import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../components/UI/Button/Button';
import Loader from '../../../components/UI/Loader';
import classes from '../../../components/UI/Button/button.module.scss';
import { alertFunction } from '../../../utils/functions/alertFunction';
import { createAction } from '../../../utils/redux/store';
import { catchHandler } from '../../../utils/error_handling/error_handling';
import { clientSendData } from '../../../utils/functions/requests';
import { log } from '../../../utils/functions/others';

function FinishButton() {
  const userAnswers = useSelector((state) => state.interview.user_answers); // ответы пользователя
  const interview = useSelector((state) => state.interview.interview);
  const isWiki = useSelector((state) => state.interview.is_wiki);
  const sequenceBreak = useSelector((state) => state.interview.break);
  const { access_data, questions } = interview;
  const [isLoading, setIsLoading] = useState(false); // Состояние загрузки

  // Валидация анкеты - проверка на наличие ответов на обязательные вопросы
  const validateInterview = () => {
    if (sequenceBreak) return true;

    // массив обязательных вопросов
    const requredQuestions = questions.filter((q) => q.required);
    // удалить ответы без id ответа и комментария
    const cleanedUserAnswers = userAnswers.filter((ua) => ua.answer_id || ua.comment);
    // поиск ответа на каждый из этих вопросов
    const findRequired = requredQuestions.map((q) => {
      const findAnswer = cleanedUserAnswers.find((a) => a.question_id === q.id);
      if (findAnswer) return true; // если ответ есть - объект вопроса заменятся на true
      return false; // иначе на false
    });
    // результат - массив true и false
    // если есть хотя бы один false - валидация не пройдена
    if (findRequired.includes(false)) return false;
    return true; // иначе пройдена
  };

  // Завершить опрос и отправить данные в БД
  async function finishInterview() {
    try {
      if (validateInterview()) { // если валидация пройдена данные отправляются в БД
        const confirm = window.confirm('Завершить опрос и отправить результаты?');
        if (confirm) {
          // удалить ответы без id ответа и комментария
          const cleanedUserAnswers = userAnswers.filter((a) => a.answer_id || a.comment);
          const reqData = {
            type: 'finishInterview',
            user_answers: cleanedUserAnswers,
            access_data,
          };
          setIsLoading(true); // загружается элемент - finishButton
          const result = await clientSendData('POST', '/finish_interview', reqData); // запрос в БД
          if (result === 'success') { // если успех
            await alertFunction('thank_for_interview', 'clientPost'); // уведомление "Спасибо"
            setIsLoading(false); // загрузка завершена
            createAction('SET_USER_ANSWERS', []); // сбросить ответы пользователя
            createAction('SET_IS_FINISH', true); // опрос завершён
            document.cookie = 'current_in_sequence=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
            log({ message: `Пользователь завершил отвечать на вопросы в анкете id: ${interview.questionnaire.id} `, eventtype: 'info' });
          }
        }
      } else { // иначе
        setIsLoading(true); // загружается элемент - finishButton
        await alertFunction('required_questions', 'clientPost'); // уведомление
        setIsLoading(false); // загрузка завершена
      }
    } catch (error) {
      catchHandler(error, 'finishInterview');
      setIsLoading(false); // загрузка завершена
    }
  }

  // если не справочник и есть ответы на все обязательные вопросы
  if (!isWiki && validateInterview()) {
    return (
        <Button className={classes.button} onClick={finishInterview}>
            {isLoading ? <Loader theme="light" /> : 'Завершить опрос'}
        </Button>
    );
  } return null;
}

export default FinishButton;
