import React from 'react';

// Обёртка для больших таблиц, прокручиваемых горизонтально
// id - id таблицы
// title - название таблицы
// children - дочерний DOM элемент
// button - кнопка в хедере
// buttonHandler - обработчик кнопки

function TableWrapper(props) {
  const {
    title, button, buttonHandler, children,
  } = props;

  return (
    <div className="upu-table evaluation-table-wrapper">
      <div className="upu-table__header">
        {title}
        {button && <span className="evaluation-table-wrapper__button" onClick={buttonHandler}>{button}</span>}
      </div>
      <div className="upu-table__table-body">
        {children}
      </div>
    </div>
  );
}

export default TableWrapper;
