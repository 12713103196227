import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { catchHandler } from '../../../../utils/error_handling/error_handling';
import Logs from './Logs';
import Settings from './Settings';
import Button from '../../../../components/UI/Button/Button';
import TabPanel from '../../../../components/UI/TabPanel';
import { alertFunction } from '../../../../utils/functions/alertFunction';
import { clientSendData } from '../../../../utils/functions/requests';
import { getCategories, getQuestions } from '../../functions';
import { setOperInfo, log } from '../../../../utils/functions/others';
import CardFooter from '../../../../components/Card/CardFooter';
import Card from '../../../../components/Card/Card';
import CardHeader from '../../../../components/Card/CardHeader';
import CardBody from '../../../../components/Card/CardBody';

// Сервис анкетирования - вкладка "Категории" - карточка категории
function CategorieCard(props) {
  const { setShowCard, id } = props;

  const location = useLocation(); // адрес
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [choice, setChoice] = useState(''); // состояние выбора вкладки
  const [category, setCategory] = useState(null); // текущая категория
  const currentDirection = useSelector((state) => state.questionnaire.current_direction); // текущее направление
  const categories = useSelector((state) => state.questionnaire.categories); // категории отображаемые в таблице
  const isAllDirections = currentDirection.id === 'all'; // Выбраны все направления?
  const isNewCategory = id === 'new';
  const tabs = ['Настройки', !isNewCategory && 'История']; // вкладки

  useEffect(() => {
    awaitRequests();
  }, []);

  // Отменить редактирование или создание и закрыть карточку
  function cancelAndClose() {
    log({ message: `Пользователь закрыл карточку категории id: ${id}`, eventtype: 'info' });
    setOperInfo({ subject_id: null }); // сброс id категории в оперативной информацию
    setShowCard(false);
  }

  // Обработчик выбора вкладки
  function choiceHandler(tab) {
    setChoice(tab); // переключение вкладки
    log({ message: `Пользователь выбрал вкладку "${tab}" на карточке категории id: ${id}`, eventtype: 'info' });
  }

  // Ожидание выполнения необходимых запросов
  async function awaitRequests() {
    try {
      setOperInfo({ subject_id: id }); // запись id категории в оперативную информацию
      setIsLoading(true); // состояние - загружается
      if (id === 'new') { // если анкета создаётся
        setCategory({
          // если выбрано конкретное направление, подставляются его данные,
          // иначе данные первого, доступного пользователю направления
          direction: !isAllDirections ? currentDirection.title : null,
          direction_id: !isAllDirections ? currentDirection.id : null,
          id: 'new',
        }); // новый объект категории в хранилище
        setIsLoading(false); // загрузка завершена
      } else {
        // записать в состояние информацию о выбранной категории
        setCategory(categories.find((c) => c.id === id) || {});
        setIsLoading(false); // загрузка завершена
      }
    } catch (error) {
      catchHandler(error, 'awaitRequests');
    }
  }

  // Сохранить изменения
  async function saveCategoryChanges() {
    try {
      const reqData = {
        type: 'editCategory',
        category,
      };

      // Если текст вопроса пустой или не выбрана группа - уведомление
      if (!category.direction_id || !category.title || (category.direction_id === 'new')) alertFunction('required_fields', 'clientPost');
      else {
        setIsLoading(true); // состояние - загружается
        const result = await clientSendData('POST', '/edit_category', reqData);

        if (result === 'success') { // если успех
          alertFunction('save_settings', 'clientPost'); // уведомление - настройки сохранены
          await getCategories(location.pathname, currentDirection); // обновить информацию о категориях
          getQuestions(location.pathname, currentDirection); // обновить информацию о вопросах
          if (isNewCategory) setShowCard(false); // если категория создается - закрыть карточку
          log({ message: `Пользователь сохранил категорию "${category.title}" id: ${category.id}`, eventtype: 'info' });
        }
        setIsLoading(false); // загрузка завершена
      }
    } catch (error) {
      catchHandler(error, 'saveCategoryChanges');
      setIsLoading(false);
    }
  }

  return (
    <Card id="questionnaire__category-card" setShow={cancelAndClose} loading={isLoading}>
      <CardHeader>
          <TabPanel tabs={tabs} choice={choice} choiceHandler={choiceHandler} />
      </CardHeader>

      <CardBody>
        {choice === 'Настройки' && (
        <Settings // Вкладка настроек
          setShowCard={setShowCard}
          category={category}
          setCategory={setCategory}
        />)}

        {choice === 'История' && (
        <Logs // Вкладка истории
          id={category.id}
        />)}
      </CardBody>

      <CardFooter>
          <Button onClick={cancelAndClose}>Отменить</Button>
          <Button onClick={saveCategoryChanges}>Сохранить</Button>
      </CardFooter>
    </Card>
  );
}

export default CategorieCard;
