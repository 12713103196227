import React, { useEffect, useState } from 'react';
import { createAction } from '../../../utils/redux/store';
import { clientSendData } from '../../../utils/functions/requests';
import { catchHandler } from '../../../utils/error_handling/error_handling';
import Dismissal from './Dismissal/Dismissal';
import Loader from '../../../components/UI/Loader';
import ServiceBody from '../../../components/Service/ServiceBody';
import Table from '../../../components/Table/Table';
import TBody from '../../../components/Table/TBody';
import TRow from '../../../components/Table/TRow';
import TData from '../../../components/Table/TData';
import TFoot from '../../../components/Table/TFoot';
import THead from '../../../components/Table/THead';
import { log, tableToExcel } from '../../../utils/functions/others';

/**
* @component Система контроля РП - Система Выходного интервью
*/
function ProbationOutputInterview() {
  const [isLoading, setLoading] = useState(true); // состояние загрузки
  const [showCard, setShowCard] = useState(false); // Отображение карточки выходного интервью
  const [users, setUsers] = useState([]); // список сотрудников
  const headers = [
    {
      id: 1, field: 'displayname', title: 'Сотрудник', align: 'left',
    },
    {
      id: 2, field: 'title', title: 'Должность', align: 'left',
    },
    {
      id: 3, field: 'department', title: 'Отдел', align: 'left',
    },
    {
      id: 4, field: 'extensionattribute11', title: 'Грейд', align: 'right',
    },
    { id: 5, field: 'dismissal_date', title: 'Дата увольнения' },
    {
      id: 6, field: 'name', title: 'Тип увольнения', align: 'left',
    },
    { id: 7, field: 'answer', title: 'ВИ' },
  ];

  useEffect(() => {
    getDismissedUsers();
  }, []);

  // Получение сотрудников из БД
  async function getDismissedUsers() {
    try {
      setLoading(true);
      const reqData = { type: 'getDismissedUsers' };
      const result = await clientSendData('POST', '/get_dismissed_users', reqData); // запрос в БД
      if (result) setUsers(result); // запись сотрудников в состояние
      setLoading(false);
    } catch (error) {
      catchHandler(error, 'get_dismissed_users');
    }
  }

  // Получение карточки выходного интервью
  async function getOutputInterview(user) {
    try {
      const reqData = {
        type: 'getOutputInterview',
        user: user.id,
      };
      const result = await clientSendData('POST', '/get_output_interview', reqData); // запрос в БД
      if (result) createAction('SET_OUTPUT_INTERVIEW', result || {}); // запись карточки выходного интервью в хранилище
    } catch (error) {
      catchHandler(error, 'get_output_interview');
    }
  }

  // Обработка нажатия на кнопку карточки выходного интервью
  async function showDismissalCard(user) {
    try {
      // Получение карточки выходного интервью
      await getOutputInterview(user);
      // Записываем в хранилище пользователя выходного интервью
      createAction('SET_DISMISSAL_USER', user);
      // Установка отображения карточки выходного интервью
      setShowCard(true);
      log({ message: `Пользователь открыл карточку выходного интервью сотрудника ${user.displayname}`, subject_id: user.id });
    } catch (error) {
      catchHandler(error, 'showDismissalCard');
    }
  }

  return (
    <ServiceBody>
      {isLoading ? <Loader /> : (
        <Table id="dismissal__table">
          <THead
            title="Выходное интервью"
            headers={headers}
            array={users}
            setArray={setUsers}
            button_place="row"
          />

          <TBody>
            {users.map((user) => (
              <TRow key={user.id}>
                {headers.map((item) => {
                  const { id, field, align } = item;
                  const value = user[field];
                  if (field !== 'answer') return <TData key={id} align={align}>{value}</TData>;
                  return <TData key={id}>{value ? '✓' : ''}</TData>;
                })}
                <TData onClick={() => showDismissalCard(user)}>
                  <img src="../../icons/button/settings.svg" alt="setting" />
                </TData>
              </TRow>
            ))}
          </TBody>

          <TFoot>
            <TRow>
              <TData onClick={() => { tableToExcel('dismissal__table', 'Выходное интервью'); log({ message: 'Пользователь нажал кнопку \'Скачать в Excel\'' }); }}>
                Скачать в Excel
              </TData>
            </TRow>
          </TFoot>
        </Table>
      )}

      {/* Карточка пользователя (показывается если состояние видимости - true) */}
      {showCard && (
      <Dismissal
        setShowCard={setShowCard}
        getDismissedUsers={getDismissedUsers}
      />
      )}
    </ServiceBody>
  );
}

export default ProbationOutputInterview;
