import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { catchHandler } from '../../../../utils/error_handling/error_handling';
import { clientSendData } from '../../../../utils/functions/requests';
import { createPeriod, getMonthName, getPeriodByInterval } from '../../functions';
import TableWrapper from '../../Components/TableWrapper/TableWrapper';
import Loader from '../../../../components/UI/Loader';
import Canvas from '../../Components/Canvas/Canvas';

/**
* @component Сервис отчётов анкетирования - Расширенный отчёт
* 6 - Общая удовлетворенность
*/
function ReportRelations() {
  const isShowChart = useSelector((state) => state.reports.is_show_chart);// состояние видимости графика
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [rawReport, setRawReport] = useState([]); // сырые данные отчёта
  const [report, setReport] = useState([]); // готовые данные отчёта
  const [months, setMonths] = useState([]); // период по месяцам
  const [searchParams, setSearchParams] = useSearchParams(); // параметры ссылки
  const start_date = searchParams.get('start_date'); // дата начала
  const end_date = searchParams.get('end_date'); // дата кончания
  const period = !!(start_date && end_date); // наличие периода
  const title = 'Оценка взаимоотношений в коллективе';

  useEffect(() => {
    getReportSatisfaction(); // получить отчёт по выходному интервью
  }, []);

  // получить отчёт по выходному интервью
  async function getReportSatisfaction() {
    try {
      const reqData = {
        type: 'getReportSatisfaction',
        period,
        start_date,
        end_date,
      };
      setIsLoading(true); // состояние - загружется
      const result = await clientSendData('POST', '/get_oi_report_satisfaction', reqData);
      if (result?.length > 0) {
        // если нет периода - создать на основании дат результата
        if (!period) setMonths(createPeriod(result, 'dismissal_date'));
        // Иначе - создать на основании дат периода
        else setMonths(getPeriodByInterval('month', start_date, end_date));

        setRawReport(result);
        createReport(result);
      } else setIsLoading(false); // загрузка завершена
    } catch (error) {
      catchHandler(error, 'getReportSatisfaction');
      setIsLoading(false); // загрузка завершена
    }
  }

  // Создание отчёта на основе полученных данных
  function createReport() {
    setReport([
      { title: 'Наставник', value: 'mentor' },
      { title: 'Руководитель', value: 'head' },
      { title: 'Коллеги', value: 'colleagues' },
    ]);
    setIsLoading(false); // загрузка завершена
  }

  if (isLoading) return <Loader />;
  if (isShowChart) {
    return (
      <Canvas
        id="reports-oi-experience__canvas"
        type="bar"
        title={title}
        label="Средняя оценка"
        legend_position="top"
        labels={report.map((row) => row.title)}
        values={report.map((row) => {
          // Все оценки уволившихся
          const ratingTotal = rawReport.map((item) => item[row.value]);
          // Среднее значение (сумма значений массива разделить на длину)
          const averageTotal = ratingTotal.reduce((a, b) => a + b, 0) / ratingTotal.length || 0;
          return averageTotal;
        })}
      />
    );
  }
  return (
    <TableWrapper id="reports-oi-relations" title={title} back_button chart_button>
      <table id="reports-oi-relations" className="upu-table">
        <thead className="upu-table__table-head">
          <tr className="upu-table__table-row">
            <td className="upu-table__table-data">Период</td>
            {months.map((month) => <td key={month} className="upu-table__table-data">{getMonthName(month)}</td>)}
            <td className="upu-table__table-data">Итого</td>
          </tr>
        </thead>
        <tbody className="upu-table__table-body">
          {/* Проходим по отчёту */}
          {report.map((row) => {
            // Все оценки уволившихся
            const ratingTotal = rawReport.map((item) => item[row.value]);
            // Среднее значение (сумма значений массива разделить на длину)
            const averageTotal = ratingTotal.reduce((a, b) => a + b, 0) / ratingTotal.length || 0;
            // Выводим ячейки (Средняя оценка)
            return (
              // Выводим строки
              <tr key={row.title} className="upu-table__table-row">

                {/* Первая ячейка - название */}
                <td className="upu-table__table-data"><b>{row.title}</b></td>

                {/* Далее проходим по месяцам */}
                {months.map((month) => {
                  // Находим уволившихся в текущем месяце
                  const filterValues = rawReport.filter((value) => {
                    const dismissalDate = new Date(value.dismissal_date).getMonth(); // месяц увольнения
                    const fieldDate = new Date(month).getMonth(); // месяц в колонке
                    return dismissalDate === fieldDate; // вернуть совпадения месяцев
                  });
                  // Оценки уволившихся в текущем месяце
                  const rating = filterValues.map((item) => item[row.value]);
                  // Среднее значение (сумма значений массива разделить на длину)
                  const average = rating.reduce((a, b) => a + b, 0) / rating.length || 0;
                  // Выводим ячейки (Средняя оценка на дату)
                  return <td key={row.title + month} className="upu-table__table-data">{Math.ceil((average) * 100) / 100}</td>;
                })}

                {/* Последняя ячейка - средняя оценка за весь период */}
                <td className="upu-table__table-data">{Math.ceil((averageTotal) * 100) / 100}</td>
              </tr>
            );
          })}
        </tbody>
        <tfoot className="upu-table__table-foot" />
      </table>
    </TableWrapper>
  );
}

export default ReportRelations;
