import React, { useState } from 'react';

/**
 * @component - Боковая панель
 * @prop {id} string - уникальный идентификатор DOM-элемента
*/

function ServicePanel(props) {
  const { id, children } = props;

  const [showPanel, setShowPanel] = useState(true); // состояние отображения панелиыы

  // Переключение видимости панели
  function togglePanel(e) {
    setShowPanel((state) => !state); // Переключить видимости панели
    e.stopPropagation();
  }

  return (
    <aside id={id} className={`panel ${showPanel ? 'panel_active' : ''}`} onClick={togglePanel}>
      {/* Кнопка скрытия/показа панели */}
      <div className={`panel__toggle ${showPanel ? 'panel__toggle_active' : ''}`}>
        <img src="../../icons/chevron.svg" alt="chevron" />
      </div>
      {children}
    </aside>
  );
}

export default ServicePanel;
