import { catchHandler } from '../../utils/error_handling/error_handling';

// ID статусов бронирования
export const bookingStatuses = {
  reserved: 1,
  canceled: 2,
  completed: 3,
  confirmed: 4,
};

// Настройки отображения бронирования мест по умолчанию
export const defaultTableState = {
  booking: {
    title: 'Мои бронирования',
    bookingType: 'user',
    page: 1,
    limit: 10, // количество строк в таблице
    switch_data: {
      current: 'active',
      titles: {
        all: 'Все',
        active: 'Активные',
      },
    },
  },

  bookingAdmin: {
    title: 'Бронирования пользователей',
    bookingType: 'admin',
    page: 1,
    limit: 50, // количество строк в таблице
    switch_data: {
      current: 'bookings',
      titles: {
        bookings: 'По бронированиям',
        places: 'По местам',
      },
    },
  },
};

// Наименования колонок таблиц
export const headers = {
  booking: [
    { title: '№', field: 'id', align: 'right' }, // title то что видет пользователь field ключ объекта из базы данных align выравнивание
    { title: 'Место', field: 'workplace', align: 'left' },
    { title: 'Статус', field: 'status', align: 'left' },
    {
      title: 'Дата начала', field: 'date_start', align: 'left', mobile_hide: true,
    },
    {
      title: 'Дата окончания', field: 'date_end', align: 'left', mobile_hide: true,
    },
  ],

  bookingAdmin: {
    bookings: [
      { title: '№', field: 'id', align: 'right' }, // title то что видет пользователь field ключ объекта из базы данных align выравнивание
      { title: 'Блок', field: 'block', align: 'center' },
      { title: 'Зона', field: 'zone', align: 'center' },
      { title: 'Место', field: 'place', align: 'center' },
      { title: 'Статус', field: 'status', align: 'left' },
      {
        title: 'Дата начала', field: 'date_start', align: 'left', mobile_hide: true,
      },
      {
        title: 'Время начала', field: 'time_start', align: 'left', mobile_hide: true,
      },
      {
        title: 'Дата окончания', field: 'date_end', align: 'left', mobile_hide: true,
      },
      {
        title: 'Время окончания', field: 'time_end', align: 'left', mobile_hide: true,
      },
      { title: 'Пользователь', field: 'user', align: 'left' },
      { title: 'Грейд', field: 'grade', align: 'center' },
      { title: 'Должность', field: 'title', align: 'center' },
      { title: 'Департамент', field: 'department', align: 'left' },
    ],
    places: [
      { title: '№', field: 'id', align: 'right' }, // title то что видет пользователь field ключ объекта из базы данных align выравнивание
      //   { title: 'Место', field: 'workplace', align: 'left' },
      { title: 'Блок', field: 'block', align: 'center' },
      { title: 'Зона', field: 'zone', align: 'center' },
      { title: 'Место', field: 'place', align: 'center' },
      { title: 'Тип', field: 'workplace_type', align: 'left' },
      { title: 'Статус', field: 'status', align: 'left' },
      {
        title: 'Дата начала', field: 'date_start', align: 'left', mobile_hide: true,
      },
      {
        title: 'Дата окончания', field: 'date_end', align: 'left', mobile_hide: true,
      },
      { title: 'Пользователь', field: 'user', align: 'left' },
    ],
  },
};

export async function getNextWorkingDay() {
  try {
    // Проверка является ли день выходным
    async function checkIsDayOff(date) {
      const response = await fetch(`https://isdayoff.ru/${date}`);
      return response.json();
    }

    let result;
    const today = new Date(); // сегодня
    /**
     * Добавляем к сегодняшнему дню по одному до тех пор пока не будет найден рабочий день
     * Глубина проверки - 15 дней
     */
    for (let i = 1; i < 15; i++) {
      let nextDate = new Date(today);
      nextDate.setDate(today.getDate() + i);
      nextDate = nextDate.toLocaleDateString('fr-CA', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }); // yyyy-mm-dd);
      // eslint-disable-next-line no-await-in-loop
      const isDayOff = await checkIsDayOff(nextDate);
      if (isDayOff === 0) {
        result = nextDate;
        break;
      }
    }
    return result;
  } catch (error) {
    catchHandler(error, 'getNextWorkingDay');
    return null;
  }
}
