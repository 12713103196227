// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.forms__period {
  display: flex;
  flex-flow: column;
}
.forms__period > * {
  margin: 2px 0px;
}
.forms__period_vertical {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 100%;
}
.forms__period_vertical > * {
  margin: 4px;
}`, "",{"version":3,"sources":["webpack://./src/components/Forms/forms.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,iBAAA;AAAJ;AACI;EACE,eAAA;AACN;AAEI;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;AAAN;AAEM;EACE,WAAA;AAAR","sourcesContent":[".forms {\n  &__period {\n    display: flex;\n    flex-flow: column;\n    & > * {\n      margin: 2px 0px;\n    }\n\n    &_vertical {\n      display: flex;\n      flex-flow: row wrap;\n      justify-content: center;\n      width: 100%;\n\n      & > * {\n        margin: 4px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
