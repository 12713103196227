import React, { useEffect, useState } from 'react';
import Input from '../../../components/UI/Input';
import { catchHandler } from '../../../utils/error_handling/error_handling';
import { alertFunction } from '../../../utils/functions/alertFunction';
import { clientSendData } from '../../../utils/functions/requests';
import { backlightHandler } from '../../../utils/functions/others';
import TextArea from '../../../components/UI/TextArea';
import Select from '../../../components/UI/Select';
import Toggle from '../../../components/UI/Toggle';
import InputSearch from '../../../components/UI/InputSearch';
import CardSetting from '../../../components/Card/CardSetting';
import Button from '../../../components/UI/Button/Button';
import classes from '../../../components/UI/Button/button.module.scss';

/**
* @component Система контроля задач - карточка задачи - вкладка "Настройки"
* @prop {task} object - объект задачи
* @prop {setTask} function - Функция обновления состояния отображения объекта задачи
* @prop {handleTask} function - Функция обработки данных задачи в БД
*/
function Settings(props) {
  const { task, setTask, handleTask } = props;

  const [userBacklight, setUserBacklight] = useState(''); // состояние подсветки поиска пользователя
  const [managerBacklight, setManagerBacklight] = useState(''); // состояние подсветки поиска менеджера
  const [statuses, setStatuses] = useState([]); // статусы
  const [sources, setSources] = useState([]); // источники задачи (сервисы)
  const [users, setUsers] = useState([]); // пользователи (для выбора сотрудника и менеджера)

  useEffect(() => {
    awaitRequests();
    // eslint-disable-next-line
    }, [])

  // Ожидание выполнения необходимых запросов
  async function awaitRequests() {
    // setIsLoading(true)
    await getAddInfo(); // получаем доп данные
    // setIsLoading(false)
  }

  // Получить статусы
  async function getAddInfo() {
    try {
      const reqData = {
        type: 'getAddInfo',
      };
      const result = await clientSendData('POST', '/get_add_info', reqData);
      if (result === 'bad_request') {
        alertFunction('bad_request', 'clientPost');
      } else {
        setStatuses(result.statuses); // Результат записывается в переменную
        setSources(result.sources); // Результат записывается в переменную
      }
    } catch (error) {
      catchHandler(error, 'getAddInfo');
    }
  }

  // Удалить задачу
  // async function deleteTask() {
  //     // try {
  //     //     // подтверждение
  //     //     let confirm = window.confirm("Удалить задачу?")
  //     //     if (confirm) { // если подтверждено
  //     //         const reqData = {
  //     //             type: "deleteTask",
  //     //             task_id: task.id // id задачи
  //     //         }
  //     //         setIsLoading(true) // загружается
  //     //         const result = await clientSendData("POST", "/delete_task", reqData) // запрос в БД
  //     //         if (result === "bad_request") { // если некорректный запрос
  //     //             alertFunction("bad_request", "clientPost") // уведомление
  //     //             setIsLoading(false) // загрузка окончена
  //     //         } else if (result === "success") { // если успех
  //     //             getTasks() // обновить данные в таблице
  //     //             alertFunction("save_settings", "clientPost") // уведомление
  //     //             setIsLoading(false) // загрузка окончена
  //     //             setShowCard(false) // закрыть карточку
  //     //         }
  //     //     } else return // выход если не подтверждено
  //     // } catch (error) {
  //     //     catchHandler(error, "deleteTask") // обработчик ошибок
  //     //     setIsLoading(false) // состояние - не загружается
  //     // }
  // }

  // Обработка подсветок поиска пользователя и менеджера
  function whoBacklightHandler(who, array) {
    if (who === 'user') backlightHandler(array, setUserBacklight); // Обновление подсветки поля выбора пользователя
    if (who === 'manager') backlightHandler(array, setManagerBacklight); // Обновление подсветки поля выбора менеджера
  }

  // Поиск пользователя
  async function searchUser(e, who) {
    try {
      // При поиске сотрудника, его id сбрасывается и устанавливается только при выборе
      // это необходимо для валидации
      changeSetting({ [`${who}_id`]: null });

      if (e.target.value.length <= 1 || !e.target.value) {
        // При открытии формы редактирования e.target.value отсутствует, но имя пользователя отображается в поле
        // поэтому поле подсвечивается зелёным
        if (e.target.value) {
          if (who === 'user') setUserBacklight('green');
          if (who === 'manager') setManagerBacklight('green');
        }
        setUsers([]); // сброс массива
        whoBacklightHandler(who, []); // Обновление подсветки поля выбора
      } else {
        const reqData = {
          type: 'searchMentor',
          query: e.target.value,
        };
        const result = await clientSendData('POST', '/search_mentor', reqData); // Запрос в БД
        if (result === 'bad_request') alertFunction('bad_request', 'clientPost'); // если некорректный запрос
        else {
          whoBacklightHandler(who, result); // Обновление подсветки поля выбора
          setUsers(result); // Запись пользователей в хранилище
        }
      }
    } catch (error) {
      catchHandler(error, 'searchUser');
    }
  }

  // Изменить значение поля
  function changeSetting(setting) {
    setTask((state) => ({ ...state, ...setting }));
  }

  // Проверить на наличие значение
  function checkValue(value) {
    if (value) return value;
    return '';
  }

  // Функция выбора пользователя
  function chooseUser(user, who) {
    if (who === 'user') setUserBacklight('green'); // Обновление подсветки поля выбора пользователя
    if (who === 'manager') setManagerBacklight('green'); // Обновление подсветки поля выбора менеджера
    changeSetting({ [`${who}_id`]: user.id, [who]: user.title });
  }

  // Функция завершения задачи
  function finishTask() {
    if (window.confirm('Завершить задачу?')) {
      handleTask({
        ...task,
        status_title: 'Выполнена',
        status_id: 5,
      });
    }
  }

  return (
    <div className="upu-card__block">

      {/* Заголовок */}
      <CardSetting title="Заголовок" invalid={!task.short_title}>
        <Input
          placeholder="Заголовок"
          value={checkValue(task.short_title)}
          onChange={(e) => changeSetting({ short_title: e.target.value })}
        />
      </CardSetting>

      {/* Полный заголовок */}
      <CardSetting title="Полный заголовок" invalid={!task.long_title}>
        <TextArea
          placeholder="Полный заголовок"
          value={checkValue(task.long_title)}
          onChange={(e) => changeSetting({ long_title: e.target.value })}
        />
      </CardSetting>

      {/* Краткое содержание */}
      <CardSetting title="Краткое содержание" invalid={!task.short_text}>
        <Input
          placeholder="Краткое содержание*"
          value={checkValue(task.short_text)}
          onChange={(e) => changeSetting({ short_text: e.target.value })}
        />
      </CardSetting>

      {/* Полное содержание */}
      <CardSetting title="Полное содержание" invalid={!task.long_text}>
        <TextArea
          placeholder="Полное содержание"
          value={checkValue(task.long_text)}
          onChange={(e) => changeSetting({ long_text: e.target.value })}
        />
      </CardSetting>

      {/* Статус */}
      <CardSetting title="Статус" invalid={!task.status_id}>
        <Select
          id="tasks__status-select"
          array={statuses}
          onChoose={(status) => changeSetting({
            status_title: status.title,
            status_id: status.id,
          })}
          defaultValue={task.status_title}
        >
          Статус
        </Select>
      </CardSetting>

      {/* Источник */}
      <CardSetting title="Источник" invalid={!task.source_id}>
        <Select
          id="tasks__source-select"
          array={sources}
          onChoose={(source) => changeSetting({
            source_id: source.id,
            source: source.alias,
            source_title: source.title,
          })}
          defaultValue={task.source_title}
        >
          Источник
        </Select>
      </CardSetting>

      {/* Дата начала */}
        <CardSetting title="Дата начала">
        <Input
          type="date"
          placeholder="Дата начала"
          value={checkValue(task.date_start)}
          onChange={(e) => changeSetting({ date_start: e.target.value })}
        />
        </CardSetting>

      {/* Дата завершения */}
        <CardSetting title="Дата завершения">
        <Input
          type="date"
          placeholder="Дата завершения"
          value={checkValue(task.date_end)}
          onChange={(e) => changeSetting({ date_end: e.target.value })}
        />
        </CardSetting>

      {/* Выбор пользователя */}
      <CardSetting title="Выбор пользователя" invalid={!task.user_id}>
        <InputSearch
          id="tasks__user-search"
          array={users}
          onChoose={(user) => chooseUser(user, 'user')}
          onSearch={(e) => searchUser(e, 'user')}
          backlight={userBacklight}
          defaultValue={checkValue(task.user)}
        />
      </CardSetting>

      {/* Уведомление на почту */}
        <CardSetting title="Уведомление пользователя на почту">
        <Toggle
          state={String(checkValue(task.notify_smtp))}
          onClick={() => changeSetting({ notify_smtp: !task.notify_smtp })}
        />
        </CardSetting>

      {/* Уведомление в телеграм */}
        <CardSetting title="Уведомление пользователя в телеграм">
        <Toggle
          state={String(checkValue(task.notify_telegram))}
          onClick={() => changeSetting({ notify_telegram: !task.notify_telegram })}
        />
        </CardSetting>

      {/* Push уведомление */}
        <CardSetting title="Уведомление пользователя в телеграм в Push">
        <Toggle
          state={String(checkValue(task.notify_push))}
          onClick={() => changeSetting({ notify_push: !task.notify_push })}
        />
        </CardSetting>

      {/* Интервал уведомлений пользователя */}
        <CardSetting title="Интервал уведомлений пользователя">
        <Input
          type="number"
          min="0"
          value={checkValue(task.interval_notify)}
          onChange={(e) => changeSetting({ interval_notify: +e.target.value })}
        />
        </CardSetting>

      {/* Дата последнего уведомления пользователя */}
        <CardSetting title="Дата последнего уведомления пользователя">
        <Input
          type="date"
          value={checkValue(task.last_date_notify)}
          onChange={(e) => changeSetting({ last_date_notify: e.target.value })}
        />
        </CardSetting>

      {/* Выбор менеджера */}
        <CardSetting title="Выбор менеджера">
        <InputSearch
          id="tasks__manager-search"
          array={users}
          onChoose={(user) => chooseUser(user, 'manager')}
          onSearch={(e) => searchUser(e, 'manager')}
          backlight={managerBacklight}
          defaultValue={checkValue(task.manager)}
        />
        </CardSetting>

      {/* Уведомление менеджера на почту */}
        <CardSetting title="Уведомление менеджера на почту">
        <Toggle
          state={String(checkValue(task.manager_notify_smtp))}
          onClick={() => changeSetting({ manager_notify_smtp: !task.manager_notify_smtp })}
        />
        </CardSetting>

      {/* Уведомление менеджера в телеграм */}
      <CardSetting title="Уведомление менеджера в телеграм">
        <Toggle
          state={String(checkValue(task.manager_notify_telegram))}
          onClick={() => changeSetting({ manager_notify_telegram: !task.manager_notify_telegram })}
        />
      </CardSetting>

      {/* Интервал уведомлений менеджера */}
      <CardSetting title="Интервал уведомлений менеджера">
        <Input
          type="number"
          min="0"
          value={checkValue(task.interval_notify_manager)}
          onChange={(e) => changeSetting({ interval_notify_manager: +e.target.value })}
        />
      </CardSetting>

      {/* Дата последнего уведомления менеджера */}
      <CardSetting title="Дата последнего уведомления менеджера">
        <Input
          type="date"
          value={checkValue(task.last_date_notify_manager)}
          onChange={(e) => changeSetting({ last_date_notify_manager: e.target.value })}
        />
      </CardSetting>

      {/* Завершение задачи */}
      {task.status_id !== 5 && (
      <CardSetting title="Завершить задачу">
        <Button className={classes.button_ghost} onClick={finishTask}>Завершить</Button>
      </CardSetting>)}

      {/* Удаление задачи */}
      {/* <CardSetting title="Удалить задачу">
          <Button className={classes.button_ghost} onClick={deleteTask}>Удалить</Button>
      </CardSetting> */}

    </div>
  );
}

export default Settings;
