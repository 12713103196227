import React from 'react';

/**
* @component Анимация загрузки
@prop {theme} string цветовая тема (light для отображения на темных элементах)
*/

function Loader(props) {
  const { theme } = props;
  return <span className={theme === 'light' ? 'loader loader_light' : 'loader'} />;
}

export default Loader;
