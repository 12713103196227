import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import Button from '../../../../components/UI/Button/Button';
import Input from '../../../../components/UI/Input';
import Select from '../../../../components/UI/Select';
import { catchHandler } from '../../../../utils/error_handling/error_handling';
import { alertFunction } from '../../../../utils/functions/alertFunction';
import { clientSendData } from '../../../../utils/functions/requests';
import { getCategories } from '../../functions';
import classes from '../../../../components/UI/Button/button.module.scss';
import TwoChoice from '../../../../components/UI/TwoChoice';
import InputSearch from '../../../../components/UI/InputSearch';
import { backlightHandler, setOperInfo, log } from '../../../../utils/functions/others';
import CardSetting from '../../../../components/Card/CardSetting';
import { stringMatch } from '../../../../utils/functions/stringHandling';

// Сервис анкетирования - вкладка "Категории" - карточка категории - вкладка Настройки
function Settings(props) {
  const {
    setShowCard,
    category,
    setCategory,
  } = props; // свойтства переданные компоненту при вызове

  const location = useLocation(); // адрес
  const userDirections = useSelector((state) => state.questionnaire.user_directions); // направления доступные пользователю
  const currentDirection = useSelector((state) => state.questionnaire.current_direction); // текущее направление
  const allCategories = useSelector((state) => state.questionnaire.categories); // все категории
  const [selectedDirection, setSelectedDirection] = useState(category.direction_id);
  const [backlight, setBacklight] = useState(''); // состояние подсветки результатов поиска категорий
  // Отфильтрованные категории
  const filteredCategories = allCategories.filter((c) => c.id !== category.id && c.parent_id !== category.id && c.direction_id === selectedDirection);
  // - отсутствует текущая категория
  // - отсутствуют категории, у которых текущая является родителем
  // - содержит категории у которых направление совпадает с выбранным в карточке категории
  // Это сделано для предотвращение ситуации когда у категории различаются направления родителя и самой категории

  const noParentCategoryOption = { // Опция с отсутствием родительской категории
    id: null,
    title: 'Отсутствует',
  };
  const [catChoiceType, setCatChoiceType] = useState('select'); // тип выбора родительский категорий (селект или поиск)
  const [categories, setCategories] = useState(filteredCategories);

  // Изменение направления категории
  function changeDirection(d) {
    setSelectedDirection(d.id);
    setCategory({
      ...category,
      direction_id: d.id, // обновление id направления
      direction: d.title, // обновление имени направления
      parent_id: null, // обновление id категории
      parent: null, // обновление имени категории
    });
    log({ message: `Пользователь в карточке анкеты ${category.title}(id: ${category.id}) выбрал направление "${d.title}" id: ${d.id}`, eventtype: 'info' });
  }

  // Изменение родительской категории
  function changeCategory(c) {
    setCategory({
      ...category,
      parent_id: c.id, // обновление id категории
      parent: c.title, // обновление имени категории
    });
    log({ message: `Пользователь в карточке категории ${category.title}(id: ${category.id}) выбрал родительскую категорию "${c.title}" id: ${c.id}`, eventtype: 'info' });
  }

  // Изменение заголовка категории
  function changeCategoryTitle(e) {
    setCategory({
      ...category,
      title: e.target.value, // обновление заголовка
    });
  }

  // Удалить категорию
  async function deleteCategory() {
    try {
      const message = 'Удаление категории также удалит связанные с ней подкатегории и вопросы. Удалить?';
      const confirm = window.confirm(message);
      if (confirm) {
        const reqData = {
          type: 'deleteCategory',
          category_id: category.id,
        };
        const result = await clientSendData('POST', '/delete_category', reqData); // запрос в БД
        if (result === 'success') { // если успех
          alertFunction('save_settings', 'clientPost'); // уведомление  - настройки сохранены
          await getCategories(location.pathname, currentDirection); // обновить категории
          setOperInfo({ subject_id: null }); // сброс id категории в оперативной информацию
          setShowCard(false); // закрыть карточку
          log({ message: `Пользователь удалил категорию id: ${category.id}`, eventtype: 'info' });
        }
      } else return;
    } catch (error) {
      catchHandler(error, 'deleteQuestion');
    }
  }

  // Поиск по категориям
  function searchCategory(e) {
    if (!e.target.value) { // Если не введено ничего
      setCategories([]); //  массив категорий обнуляется
      backlightHandler([], setBacklight); // инпут подсвечивается красным
    } else {
      // Результат поиска - это отфильтрованный исходный массив, в котором искомая строка содержится в элементах массива
      const searchResult = filteredCategories.filter((c) => stringMatch(e.target.value, c.title)); // поиск в заголовке категории
      if (e.target.value === '/') { // если введен "/"
        setCategories(filteredCategories); // показать все категории
        backlightHandler(filteredCategories, setBacklight);
      } else if (searchResult.length > 0) {
        setCategories(searchResult); // если есть результат - он показывается
        backlightHandler(searchResult, setBacklight);
      } else {
        setCategories([]); // иначе - сброс поиска
        backlightHandler([], setBacklight);
      }
    }
  }

  // Обработчик переключателя между выборами категорий
  function catChoiceTypeHandler(currentChoice) {
    if (currentChoice) setCatChoiceType('search');
    else setCatChoiceType('select');
    setBacklight(''); // сброс подсветки
  }

  return (
    <div className="questions-card__block">
      {/* Выбор направления к которой относится категория */}
      <CardSetting title="Направление" invalid={!category.direction}>
        <Select
          id="category-card__direction"
          array={userDirections}
          onChoose={changeDirection}
          defaultValue={category.direction}
        />
      </CardSetting>

      {/* Выбор родительской категории */}
      <CardSetting title="Родительская">
        <div className="questions-card__setting-wrapper">
          <TwoChoice
            id="categories__two-choice"
            handler={catChoiceTypeHandler}
            img_1={<img src="../../../../icons/search.svg" alt="search" />}
            img_2={<img src="../../../../icons/chevron.svg" alt="chevron" />}
          />

          {catChoiceType === 'select' && (
          <Select
            id="categories__parent-category-select"
            array={[noParentCategoryOption, ...filteredCategories]}
            onChoose={changeCategory}
            defaultValue={filteredCategories.length > 0 ? category.parent : noParentCategoryOption.title}
          />)}

          {catChoiceType === 'search' && (
          <InputSearch
            id="categories__parent-category-choose"
            array={categories}
            onSearch={searchCategory}
            onChoose={(c) => { changeCategory(c); setBacklight('green'); }}
            defaultValue={filteredCategories.length > 0 ? category.parent : noParentCategoryOption.title}
            placeholder="/ все категории"
            backlight={backlight}
          />)}
        </div>
      </CardSetting>

      {/* Заголовок категории */}
      <CardSetting title="Заголовок" invalid={!category.title}>
          <Input onChange={changeCategoryTitle} defaultValue={category.title || ''} placeholder="Заголовок" />
      </CardSetting>

      {/* Кнопка удалить только для редактируемой категории */}
      {category.id !== 'new' && (
      <CardSetting title="Удалить">
        <Button className={classes.button_ghost} onClick={deleteCategory}>Удалить</Button>
      </CardSetting>)}
    </div>
  );
}

export default Settings;
