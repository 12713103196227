import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { createAction } from '../../utils/redux/store';
import Input from './Input';

/**
 * @component Поиск с выбором вариантов
 * @prop {id} array (Обязательный) идентификатор DOM элемента
 * @prop {array} array (Обязательный) массив вариантов выбора (объект должен содержать ключи id и title)
 * @prop {onSearch} function (Обязательный) обработчик ввода
 * @prop {onChoose} function (Обязательный) обработчик выбора - получает на вход выбранный объект
 * @prop {onPressEnter} function обработчик нажатия на клавишу Enter - получает на вход массив найденных объектов
 * @prop {defaultValue} string значение по умолчанию
 * @prop {backlight} string состояние подсветки (red, yellow, green)
 * @prop {placeholder} string атрибут инпута - placeholder
 * @prop {loading} boolean параметр загрузки (показывает анимацию при загрузке)
 * @prop {disabled} boolean атрибут инпута - disabled
 * @prop {delay} number задержка перед поиском
*/

function InputSearch(props) {
  const {
    id,
    array,
    onSearch,
    onChoose,
    onPressEnter,
    defaultValue,
    backlight,
    placeholder,
    loading,
    disabled,
    delay = 600, // задержка перед поиском (если не передана, то 600мс)
  } = props;

  const isActive = useSelector((state) => state.visibility.select[id]);
  const inputRef = useRef(null); // ссылка на DOM элемент инпута для прямой подмены значения на выбранное
  const [searchEvent, setSearchEvent] = useState(null); // данные для поиска
  const [isMounted, setIsMounted] = useState(false); // параметр монтирования компонента (для отмена первого useEffecta)

  useEffect(() => {
    inputRef.current.value = defaultValue || '';// изменение содержимого инпута при изменении переменной
  }, [defaultValue]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isMounted) {
      const timeOutId = setTimeout(async () => { // запуск таймаута выполнения поиска
        inputHandler(searchEvent);
      }, delay);
      return () => clearTimeout(timeOutId); // очистка таймаута при размонтированиии компонента
    } setIsMounted(true);
  }, [searchEvent]);

  // Обработка ввода
  function inputHandler(e) {
    onSearch(e); // передача в обработчик ввода родителя
    if (e.target.value.length > 0) { // если есть данные
      createAction('TOGGLE_SELECT', { key: id, value: true }); // открытие окнка выбора
    } else createAction('TOGGLE_SELECT', { key: id, value: false }); // иначе закрыть окно выбора
  }

  // Обработка выбора
  function chooseHandler(row) {
    onChoose(row); // Передача выбранного объекта в обработчик выбора родителя
    inputRef.current.value = row.source_title || row.title; // замена значение инпута на выбранное
    createAction('TOGGLE_SELECT', { key: id, value: false }); // закрытие окна выбора
  }

  // Обработка нажатий клавиатруры
  function onKeyHandler(e) {
    if (e.key === 'Enter' && onPressEnter) { // Если нажата кнопка Enter и передан обработчик нажатия
      onPressEnter(array); // Выполнить обработчик нажатия
      createAction('TOGGLE_SELECT', { key: id, value: false }); // закрытие окна выбора
    }
  }

  return (
    <div className={`custom-select ${loading ? 'upu__loading' : ''}`}>
      <Input
        placeholder={placeholder}
        onChange={setSearchEvent}
        onKeyUp={onKeyHandler}
        defaultValue={defaultValue}
        ref={inputRef}
        type="text"
        className={backlight ? `input input_${backlight}` : 'input'} // если передано свойство подсветки - стили меняются
        disabled={disabled}
      />
      <div className={array.length > 0 && isActive ? 'custom-select__dropdown custom-select__dropdown_active' : 'custom-select__dropdown'}>
        {array && array.map((row) => (
          <span onClick={() => chooseHandler(row)} key={row.id} className="custom-select__option">
            {row.title}
          </span>
        ))}
      </div>
    </div>
  );
}

export default InputSearch;
