import React from 'react';
import { setUnique } from '../../../../utils/functions/others';
import { sortObjectKeys } from '../functions';
import RadioButton from '../../../../components/UI/RadioButton';
import CheckBox from '../../../../components/UI/CheckBox';

/**
* @component Управление Базой знаний - Сущность(вопрос или тема) - Карточка сущности - Форма выбора switch поля - иерархия - строка иерархии
* @prop {object} object - объект темы
* @prop {scheme} object - схема сущности
* @prop {field} string - поле схемы
* @prop {value} string|array - значение поля в текущей сущности
* @prop {handler} function - обработчик изменения
* @prop {divisionId} number - id подразделения (при вызове из карточки подразделения)
* @prop {initialData} array - исходные данные полученные из БД
* @prop {markParents} function - пометка родителей выбранных элементов
* @prop {marked} array - условно выбранные сущности (если выбраны их дети)
* @prop {highlighted} array - выделенные сущности (при поиске)
* @prop {openEssences} function - открытие сущностей
* @prop {openedEssences} array - массив открытых сущностей
* @prop {setOpenedEssences} function - обновление состояния массива открытых сущностей
*/
function HierarchyLine(props) {
  const {
    object, scheme, field, value, handler, divisionId, initialData, markParents, openedEssences, marked, highlighted, openEssences, setOpenedEssences,
  } = props;

  const { id } = object;
  const themesSorting = 'asc'; // Сортировка тем иерархии
  const isSingleChoice = scheme[field]?.type === 'number'; // Одиночный выбор?
  const isMultipleChoice = scheme[field]?.type === 'array'; // Множественный выбор?
  const switchData = scheme[field]?.switch; // switch данные поля
  const switchColumn = switchData?.switch_column; // switch колонка для отображения
  const parent_key = switchData?.parent_key; // ключ для создания иерархической структуры

  // --------------------Обработка отображения------------------------
  const isOpened = (id) => openedEssences.includes(id); // Сущность открыта?
  const isMarked = (id) => (marked.includes(id)); // Сущность помечена?
  const isHighlighted = (id) => (highlighted.includes(id)); // Сущность выделена?

  // Проверить состояние выбора
  function isChoosed(id) {
    // если массив выбора содержит переданный id - вернуть true, иначе false
    if (isSingleChoice) return value === id;
    // если массив выбора содержит переданный id - вернуть true,
    if (isMultipleChoice) return value?.includes(id);
    // иначе false
    return false;
  }

  // Определить класс
  const defineClass = (object) => {
    let result = 'hierarchy__expand';
    if (!object?.children) result += ' hierarchy__expand_hide';
    if (isOpened(object?.id)) result += ' hierarchy__expand_active';
    return result;
  };

  // --------------------Обработка действий------------------------

  // Открыть/закрыть конкретную сущность
  function toggleEssence(event, id) {
    event.stopPropagation();
    if (openedEssences.includes(id)) closeEssences([id]); // если найдена среди активных - сущность закрывается
    else openEssences([id]); // если нет - сущность открывается
  }

  // Закрыть сущности
  function closeEssences(array) {
    // оставить только те, которых нет в array
    const newArray = openedEssences.filter((item) => !array.includes(item));
    setOpenedEssences(newArray);
  }

  // Выбрать/сбросить сущность
  function toggleValue(object) {
    const { id } = object; // id объекта
    const switchFieldName = `switch_${field}`; // наименование ключа с именем для отображения

    let newValue = null;
    let newTitle = null;

    if (divisionId) { // если компонент вызывется из карточки подразделения
      const children = defineChildren(object); // определяем детей объекта
      if (value) { // если присутствует массив value
        // если массив выбора уже содержит переданный id - удалить из массива значений
        if (value.includes(id)) {
          newValue = value.filter(
            (item) => item !== id // id
              && !children.includes(item) // его детей
              && item !== +object[parent_key], // его родителя (чтобы отобразить родителя и подтему)
          );
          // иначе добавить в текущий массив переданный id, его детей и оставить уникальные значения
        } else newValue = [...value, id, ...children].filter(setUnique);
        // если value отсутствует - добавить выбранный id и его детей в массив
      } else newValue = [id, ...children];
    } else if (isSingleChoice) { // Если тип поля число - обработка единственного выбора
      if (value === id) { // если значение равно выбранному id - значит выбор деактивируется
        newValue = null; // сбросить значения
        newTitle = null; // сбросить значения
      } else { // иначе выбор активируется
        newValue = id; // id выбранного элемента
        newTitle = object[switchData?.switch_column]; // заголовок выбранного элемента
      }
    } else if (isMultipleChoice) { // Если тип поля массив - обработка множественного выбора
      // Создать заголовки на основании обновлённого массива id
      function createTitles(array) {
        const result = array.map((item) => {
          const findObject = initialData.find((row) => row.id === item);
          return findObject?.[switchData?.switch_column];
        });
        return result.join(', ');
      }

      if (value) { // если присутствует массив value
        // если массив выбора уже содержит переданный id -  удалить id из массива значений
        if (value.includes(id)) newValue = value.filter((item) => item !== id);
        // иначе добавить в текущий массив переданный id
        else newValue = [...value, id];
      } else newValue = [id]; // если value отсутствует - добавить выбранный id в массив

      newTitle = createTitles(newValue); // обновить заголовок на основании нового значения
    } else return;

    handler({ //
      [field]: newValue,
      [switchFieldName]: newTitle,
    });
    markParents(newValue, initialData); // пометить родителей элементов
  }

  // Определить id дочерних элементов объекта в иерархии
  function defineChildren(parent) {
    const result = []; // массив для записи id детей

    // Функция добавления детей в массив
    function addChild(object) {
      if (object?.children) { // если объект содержит children
        const { children } = object;
        Object.keys(children).forEach((key) => { // пройти по ключам children
          result.push(children[key].id); // и запись id в массив
          // если ключ содержит детей вызвать функцию заново передав ей дочерний элемент
          if (children[key]?.children) addChild(children[key]);
          // иначе выйти из рекурсии
        });
      }// иначе выйти из рекурсии
    }

    addChild(parent); // записать id дочерних элеменов

    return result; // вернуть массив
  }

  // --------------------Отображение-----------------------------

  // Показать детей объекта
  function showChildrens(object) {
    if (object?.children) {
      return (
        <div className={`hierarchy__children ${isOpened(object?.id) ? 'hierarchy__children_active' : ''}`}>
          {sortObjectKeys(object.children, switchColumn, themesSorting).map((key) => {
            const child = object.children[key];
            return (
              <HierarchyLine
                key={key}
                object={child}
                scheme={scheme}
                field={field}
                value={value}
                initialData={initialData}
                handler={handler}
                markParents={markParents}
                marked={marked}
                highlighted={highlighted}
                openEssences={openEssences}
                openedEssences={openedEssences}
                setOpenedEssences={setOpenedEssences}
              />
            );
          })}
        </div>
      );
    } return null;
  }

  return (
    <div className="hierarchy__line">
      <div className="hierarchy__title">
        {/* Свернуть/развернуть сущность */}
        <div className={defineClass(object)} onClick={(event) => toggleEssence(event, id)} />
        {/* Для единственного выбора отображается радиокнопка */}
        {isSingleChoice && (
        <RadioButton
          id={`hierarchy__radio_${id}`}
          group="hierarchy__radio"
          ismarked={String(isMarked(id))}
          onChange={() => toggleValue(object)}
          defaultChecked={isChoosed(id)}
        />)}

        {/* Для множественного выбора отображается чекбокс */}
        {isMultipleChoice && (
        <CheckBox
          id={`hierarchy__checkbox_${id}`}
          ismarked={String(isMarked(id))}
          onChange={() => toggleValue(object)}
          defaultChecked={isChoosed(id)}
        />)}
        <span className={isHighlighted(id) ? 'hierarchy__title-content_active' : 'hierarchy__title-content'}>
          {object[switchColumn]}
        </span>
      </div>
      {showChildrens(object)}
    </div>
  );
}
export default HierarchyLine;
