import React from 'react';
import { hideSelect } from '../../utils/functions/others';
import Close from '../UI/Close';
import CardBody from './CardBody';
import CardFooter from './CardFooter';
import CardHeader from './CardHeader';

/**
* @component Карточка
  @prop {id} string - (Обязательный) идентификатор DOM-элемента карточки
  @prop {background_id} string - идентификатор DOM-элемента обертки карточки
  @prop {setShow} function - (Обязательный) Функция закрытия карточки
  @prop {loading} boolean - параметр загрузки (для стилей во время загрузки)
  @prop {hideCloseButton} boolean - параметр, скрывающий кнопку закрытия карточки
*/

function Card(props) {
  const {
    id, setShow, loading, hideCloseButton, background_id, children,
  } = props;

  return (
    <div id={background_id} className="upu-card__background">
      {loading ? ( // если идет загрузка - отобразятся пустые части карточки с анимацией фона
      <div id={id} className="upu-card upu-card_loader">
        <Close onClick={() => setShow(false)} />
        <CardHeader />
        <CardBody />
        <CardFooter />
      </div>
      ) : (
      <div id={id} className="upu-card" onClick={hideSelect}>
        {/* Закрыть карточку */}
        {!hideCloseButton && <Close onClick={() => setShow(false)} />}
        {children}
      </div>)}
    </div>
  );
}

export default Card;
