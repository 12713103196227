import React, { useEffect, useState } from 'react';
import { alertFunction } from '../../../../utils/functions/alertFunction';
import { catchHandler } from '../../../../utils/error_handling/error_handling';
import { clientSendData } from '../../../../utils/functions/requests';
import CardHeader from '../../../../components/Card/CardHeader';
import CardBody from '../../../../components/Card/CardBody';
import Card from '../../../../components/Card/Card';
import CardSetting from '../../../../components/Card/CardSetting';
import Input from '../../../../components/UI/Input';
import Select from '../../../../components/UI/Select';
import CardFooter from '../../../../components/Card/CardFooter';
import Button from '../../../../components/UI/Button/Button';
import Toggle from '../../../../components/UI/Toggle';
import { getNextWorkingDay } from '../../common';
import WorkZone from '../../WorkZone';
import '../../workplaces.scss';
import InputSearch from '../../../../components/UI/InputSearch';

/**
  * @component Бронирование мест - бронирования пользователя - карточка бронирования
  * @prop {setShowCard} function - обновление состояния отображения карточки (открыть/закрыть)
  * @prop {refreshTable} function - обновление данных таблицы
*/
function NewBookingCard(props) {
  const {
    setShowCard, refreshTable, chosenArea, setShowAreas,
  } = props;

  const [booking, setBooking] = useState({ date: '' }); // объект с данными бронирования
  const [isLoading, setIsLoading] = useState(false); // состояние выбора вкладки
  const [isSearching, setIsSearching] = useState(false); // состояние загрузки при поиске
  const [relatedData, setRelatedData] = useState({ // сопутствующая информация
    zones: [], //
    places: [], //
    types: [],
    users: [],
    time: [],
    admins: [],
    admin: '', //
  });
  const isAdmin = relatedData.admin !== null;
  const currentDate = new Date(); // текущая дата
  const today = currentDate.getDate() <= 9
    ? `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`
    : `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${currentDate.getDate()}`; // текущая дата для ограничения выбора дня брони

  useEffect(() => {
    getBookingRelatedData();
  }, []);

  async function getBookingRelatedData() {
    try {
      setIsLoading(true);
      const reqData = { type: 'getBookingRelatedData' };
      const result = await clientSendData('POST', '/get_booking_related_data', reqData);
      if (result?.success) {
        setRelatedData((state) => ({ ...state, ...result.data }));
      }
      const nextWorkingDay = await getNextWorkingDay();
      setBooking((state) => ({ ...state, date: nextWorkingDay }));
    } catch (error) {
      catchHandler(error, 'getBookingRelatedData');
    } finally {
      setIsLoading(false);
    }
  }

  async function getPlacesFromZone(zone) {
    try {
      const reqData = {
        type: 'getPlacesFromZone',
        zone: zone.id,
        date: booking.date,
      };
      const result = await clientSendData('POST', '/get_places_from_zone', reqData);
      if (result?.success) {
        setRelatedData((state) => ({ ...state, places: result.data.places }));
      }
    } catch (error) {
      catchHandler(error, 'getPlacesFromZone');
    }
  }

  // Валидация бронирования перед сохранением
  // Если каждый из ключей в массиве прошел валидацию
  const validateBooking = () => (['zone_id', 'place_id', 'date_start', 'date_end', 'date'].every((key) => validateField(key)));
  // Валидация отдельного ключа
  const validateField = (field) => {
    if (field === 'date_start' && booking?.whole_day) return true;
    if (field === 'date_end' && booking?.whole_day) return true;
    return Boolean(booking[field]);
  };

  // Забронировать место
  async function bookWorkplace() {
    try {
      if (validateBooking()) {
        setIsLoading(true);
        const reqData = { type: 'bookWorkplace', booking };
        const result = await clientSendData('POST', '/book_workplace', reqData);
        if (result?.success) {
          if (result?.intersection) {
            await alertFunction('booking_intersection', 'clientPost');
          } else {
            // await refreshTable();
            await alertFunction('booking_created', 'clientPost');
            if (!isAdmin) {
              closeHandler();
            } else {
              setBooking((state) => ({
                ...state,
                user_id: null,
                user_title: null,
              }));
            }
          }
        }
      } else {
        await alertFunction('required_fields', 'clientPost');
      }
    } catch (error) {
      catchHandler(error, 'bookWorkplace');
    } finally {
      setIsLoading(false);
    }
  }

  // закрыть карточку
  const closeHandler = () => {
    setShowCard(false);
    setShowAreas(true);
  };
  // const closeHandler = () => setShowCard(false);

  // Выбор даты, если бронирует администратор
  async function chooseDate(e) {
    setBooking((state) => ({
      ...state,
      date: e.target.value,
    }));
  }
  // Выбор зоны
  async function chooseZone(zone) {
    setBooking((state) => ({
      ...state,
      zone_id: zone?.id,
      zone_title: zone?.title,
      place_title: null,
      place_id: null,
    }));
    await getPlacesFromZone(zone);
  }

  // Выбор места
  function choosePlace(place) {
    setBooking((state) => ({
      ...state,
      place_id: place?.id,
      place_title: place?.title,
      type_id: place?.type_id,
      type_title: place?.type_title,
    }));
  }

  const castBookingDate = (date) => new Date(date).toLocaleDateString('ru-RU');

  // - можно выбрать только следующий рабочий день (проверять по производственному календарю)

  const [chosenPlace, setChosenPlace] = useState({ title: '', id: '' });

  const clickPlace = (e) => {
    // setChosenPlace(place.title);
    setChosenPlace({
      ...chosenPlace, title: e.title, id: e.id,
    });
    setBooking((state) => ({
      ...state,
      zone_id: e.zone_id,
      place_id: e.id,
    }));
  };

  async function searchWrapper(e) {
    setIsSearching(true); // поиск выполняется
    if (e.target.value.length === 0) setBooking((prev) => ({ ...prev }));
    try {
      const reqData = {
        type: 'searchUser',
        query: e.target.value,
      };
      const result = await clientSendData('POST', '/search_user', reqData);
      if (result?.success) setRelatedData((state) => ({ ...state, users: result.data }));
      setIsSearching(false);
      return [];
    } catch (error) {
      catchHandler(error, 'searchUser');
      setIsSearching(false);
      return [];
    }
  }
  async function getBookingInfo(bookingData) {
    try {
      const reqData = { type: 'getBookingInfo', bookingData };
      const result = await clientSendData('POST', '/get_booking_info', reqData);
      if (result?.success) {
        if (!result.data.bookingPermission) alertFunction('booking_intersection', 'client');
        setRelatedData((state) => ({ ...state, ...result.data }));
      }
      // const nextWorkingDay = await getNextWorkingDay();
      // setBooking((state) => ({ ...state, date: nextWorkingDay }));
    } catch (error) {
      catchHandler(error, 'getBookingRelatedData');
    } finally {
      setIsLoading(false);
    }
  }
  // Выбор сотрудника
  async function chooseUser(user) {
    setBooking((state) => ({ ...state, user_id: user.id, user_title: user.title }));
    const bookingData = {
      ...booking, user_id: user.id, date: booking.date, end_day: booking.date, date_start: booking.date_start, date_end: booking.date_end,
    };
    await getBookingInfo(bookingData);
  }

  return (
    <Card id="workplaces__new-booking-card__user" setShow={closeHandler} loading={isLoading}>
      <CardHeader>
        {/*  <Select
            id="workplaces__booking-choose-zone"
            array={relatedData.zones}
            onChoose={chooseZone}
            defaultValue={booking?.zone_title}
  /> */}
    <span className="card_title">Рабочая зона:</span>
{' '}
<span className="zone_number">{chosenArea.title}</span>
      </CardHeader>
      <CardBody>
        <div className="info">
          <div className="left">
        <CardSetting title="Дата бронирования:">
        {isAdmin ? <p className="choose_date">
<Input
  type="date"
  min={today}
  onChange={chooseDate}
  defaultValue={booking.date}
 // onChange={(event) => chooseDates({ date_start: event.target.value })}
/>

                   </p> : <p>{castBookingDate(booking.date)}</p>}

        </CardSetting>

       {/*  <CardSetting title="Место:" invalid={!booking?.place_id}>
        <Input
          placeholder="Выберите место"
          defaultValue={chosenPlace.title}
        />
         {/* <Select
            id="workplaces__booking-choose-place"
            array={relatedData.places.filter((place) => {
              if (booking?.zone_id) return place.zone_id === booking.zone_id;
              return true;
            })}
            onChoose={choosePlace}
            defaultValue={booking?.place_title}
            disabled={!relatedData.places.length}
          />

        </CardSetting> */}
        <CardSetting title="Выбрать весь день:">
          <Toggle
            state={String(booking?.whole_day)}
            onClick={() => setBooking((state) => ({
              ...state,
              whole_day: !booking?.whole_day,
            }))}
          />
        </CardSetting>
        {isAdmin && (<CardSetting>
          <div id="user-picker__wrapper">
        <InputSearch
          id="helpdesk__new-ticket-search-initiator"
          array={relatedData.users}
          onSearch={(e) => searchWrapper(e)}
          onChoose={chooseUser}
          placeholder="Выберите сотрудника"
           // defaultValue={booking.user_title}
          loading={isSearching}
          disabled={!booking?.date}
          //  onChoose={(user) => setSelectUsers(user)}
          defaultValue={booking?.user_title}
        />
          </div>
                     </CardSetting>)}

          </div>

         <div className="right">
         <CardSetting title="С:" invalid={!booking?.whole_day && !booking?.date_start}>
       {/*   <Input
            type="time"
            onChange={(event) => setBooking((state) => ({
              ...state,
              date_start: event.target.value,
            }))}
            disabled={booking?.whole_day}
          /> */}
           <Select
             id="start_time"
             array={relatedData.time}
             onChoose={(event) => setBooking((state) => ({
               ...state,
               date_start: event.title,
             }))}
             defaultValue={booking.date_start}
             disabled={booking?.whole_day}
           />
         </CardSetting>
        <CardSetting title="До:" invalid={!booking?.whole_day && !booking?.date_end}>
        {/*  <Input
            type="time"
            onChange={(event) => setBooking((state) => ({
              ...state,
              date_end: event.target.value,
            }))}
            disabled={booking?.whole_day}
          /> */}
           <Select
             id="end_time"
             array={relatedData.time}
             onChoose={(event) => setBooking((state) => ({
               ...state,
               date_end: event.title,
             }))}
             defaultValue={booking.date_end}
             disabled={booking?.whole_day}
           />
        </CardSetting>
        {isAdmin && (<div className="chosen_user">
        <CardSetting title={booking.user_title} />
                     </div>)}
         </div>
        </div>
   {/* Динамическое изменение отрисовки внутренней зоны */}

        <WorkZone
          clickPlace={clickPlace}
          zoneId={chosenArea.id}
          bookingDate={booking.date}
          stayHighlighted
        />

      </CardBody>
      <CardFooter>
        <Button onClick={bookWorkplace}>Забронировать место</Button>
        <Button onClick={closeHandler}>Закрыть</Button>
      </CardFooter>
    </Card>
  );
}

export default NewBookingCard;
