import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { createAction } from '../../utils/redux/store';

/**
 * @component - Кнопка панели навигации
 * @prop {area} object - объект области отображения (area) {id: number, title: string}
*/

function NavButton(props) {
  const { area } = props; // свойства, передающиеся компоненту при его вызове
  const { title, id } = area; // деструктуризация объекта area

  const [showServices, setShowServices] = useState(false);
  const services = useSelector((state) => state.services.user_services);
  // Показать только отображаемые сервисы относящиеся к текущему id area
  const displayedServices = services.filter((item) => item.area === id && item.parameters.visibility);
  const buttonHeight = 50;

  // Обработчик клика на область
  function onAreaClick(e) {
    // клик сработает только на саму кнопку,
    // передача события дальше не осуществится
    e.stopPropagation();
    setShowServices(!showServices);
  }

  // Обработчик клика на ссылку
  function onLinkClick(link) {
    createAction('TOGGLE_MENU', false);
    setShowServices(false);
    localStorage.setItem('last_service', link);
  }

  if (displayedServices.length > 0) { // Если в текущем разделе пользователю доступны сервисы
    return ( // компонент отобразится
      <div className="nav-button" onClick={onAreaClick}>

        {/* Заголовок области */}
        <p className="nav-button__title nav-button__area-title">{title}</p>
        <img
          className={`nav-button__chevron ${showServices ? 'nav-button__chevron_active' : ''}`}
          src="../../icons/chevron.svg"
          alt="chevron-down"
        />

        {/* Сервисы относящиеся к области */}
        <div
          className="nav-button__service-list"
          style={{ maxHeight: showServices ? `${displayedServices.length * buttonHeight}px` : 0 }}
        >
          {displayedServices.map((service) => (
            <Link
              key={service.id}
              to={service.link}
              className="nav-button__title"
              onClick={() => { onLinkClick(service.link); }}
              style={{ maxHeight: `${buttonHeight}px` }}
            >
            {/* <div className="vertical">-</div> */}
            {`${service.title}`}
            </Link>
          ))}
        </div>
      </div>
    );
  } return null; // Если пользователю не доступен ни один сервис раздела, компонент не отобразится
}

export default NavButton;
