import React from 'react';

/**
 * @component Логический переключатель
@prop {state} string (Обязательный) булевое значение в виде строки
@prop {onClick} function обработчик клика
@prop {disabled} boolean переключатель не активен визуально и онклик не работает
*/

function Toggle(props) {
  const { state, onClick, disabled } = props;

  const defineClassName = () => {
    let result = 'toggle';
    if (state === 'true') result += ' toggle_active';
    if (disabled) result += ' toggle_disabled';
    return result;
  };

  // Обработчик клика
  function clickHandler(e) {
    // Если переключатель не выключен
    if (!disabled) onClick(e);
  }
  return (
        <div onClick={clickHandler} className={defineClassName()}>
            <div className="toggle__circle" />
        </div>
  );
}

export default Toggle;
