/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import Datetime from 'react-datetime';
import moment from 'moment';
import 'react-datetime/css/react-datetime.css';
import 'moment/locale/ru';
import { catchHandler } from '../../utils/error_handling/error_handling';
import { clientSendData } from '../../utils/functions/requests';
import { alertFunction } from '../../utils/functions/alertFunction';
import CardBody from '../../components/Card/CardBody';
import Loader from '../../components/UI/Loader';
import CardFooter from '../../components/Card/CardFooter';
import CardSetting from '../../components/Card/CardSetting';
import IconButton from '../../components/UI/Button/IconButton';
import Button from '../../components/UI/Button/Button';
import Select from '../../components/UI/Select';
import Input from '../../components/UI/Input';
import Toggle from '../../components/UI/Toggle';
import InputSearch from '../../components/UI/InputSearch';
import './videoConference.scss';

function VideoConferencesCardMain(props) {
  const {
    meetingId, setShow, handler, closeHandler, refreshTable,
  } = props;
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [meeting, setMeeting] = useState({ timestamp_start: '', timestamp_end: '' }); // объект с данными по видеовстрече
  const [data, setData] = useState({}); // объект с дополнительными данными по видеовстрече
  const [isRepeating, setIsRepeating] = useState(true); // состояние тогла для пароля
  const [isLobby, setIsLobby] = useState(true); // состояние тогла для пароля
  const [selectUsers, setSelectUsers] = useState({});
  const [users, setUsers] = useState([]);
  const [participiants, setParticipiants] = useState([]); // участники видеоконференции
  const isNewMeeting = meetingId === 'new';
  const [changeLog, setChangeLog] = useState({}); // объект изменений для истории
  const [isSearching, setIsSearching] = useState(false); // состояние загрузки при поиске
  const [timestamp_start, setTimestampStart] = useState(null);
  const [dateStart, setDateStart] = useState(null);
  const [timestamp_end, setTimestampEnd] = useState(null);
  const repeating = [
    { id: 1, value: 'DAILY', title: 'Ежедневно' },
    { id: 2, value: 'WEEKLY', title: 'Еженедельно' },
  ];
  // const timestampStartTimeMoment = meeting?.timestamp_start ? moment(meeting?.timestamp_start, 'HH:mm') : null;
  // const timestampEndTimeMoment = meeting?.timestamp_end ? moment(meeting?.timestamp_end, 'HH:mm') : null;

  useEffect(() => {
    getVcCard();
  }, []);

  useEffect(() => {
    changeTimeDateMeeting();
  }, [timestamp_start, timestamp_end, dateStart]);

  // Получение данных для настройки уведомления
  async function getVcCard() {
    try {
      const reqData = {
        type: 'getVCCard',
        id: meetingId, // id события
      };

      setIsLoading(true);
      const result = await clientSendData('POST', '/get_vc_card', reqData);
      setTimestampStart(moment(result.meeting?.timestamp_start, 'YYYY-MM-DD HH:mm').format('HH:mm'));
      setTimestampEnd(moment(result.meeting?.timestamp_end, 'YYYY-MM-DD HH:mm').format('HH:mm'));
      setDateStart(moment(result.meeting?.timestamp_start, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD'));
      setMeeting(result.meeting);
      setData(result.meeting.data);
      console.log(timestamp_start);
      console.log(meeting);
      if (result.meeting.id !== 'new') {
        setParticipiants(result.participiants);
        setChangeLog({ ...(JSON.parse(JSON.stringify({ ...result.meeting.data, ...result.meeting }))) });
      }
      setIsLoading(false);
    } catch (error) {
      catchHandler(error, 'getVCCard');
    }
  }

  async function searchWrapper(e) {
    setIsSearching(true); // поиск выполняется
    if (e.target.value.length === 0) setSelectUsers((prev) => ({ ...prev }));
    try {
      if (e.target.value.length > 2) {
        const reqData = {
          type: 'vcMeetingSearchUser',
          query: e.target.value,
        };
        const result = await clientSendData('POST', '/vc_meeting_search_user', reqData);
        setSelectUsers({ id: e.target.value, title: e.target.value, mail: e.target.value });
        if (result?.success) setUsers(result.data);
        setIsSearching(false);
        return [];
      }
      return [];
    } catch (error) {
      catchHandler(error, 'vcMeetingSearchUser');
      setIsSearching(false);
      return [];
    }
  }

  // Сохранение данных и отправка на маршрут
  async function editMeeting(action) {
    try {
      setIsLoading(true);
      const reqData = {
        type: 'editVCCard', meeting, action, data, changeLog,
      };
      const result = await clientSendData('POST', '/edit_vc_card', reqData);
      if (result?.success) {
        if (meeting.id === 'new') {
          await alertFunction('create_meeting', 'clientPost');
        } else if (action === 'delete') {
          await alertFunction('delete_meeting', 'clientPost');
        } else {
          await alertFunction('save_settings', 'clientPost');
        }
        handler();
        // await refreshTable();
      }
    } catch (error) {
      catchHandler(error, 'editVCCard');
    } finally {
      setIsLoading(false);
    }
  }

  async function deleteMeeting() {
    const confirm = window.confirm('Удалить видеоконференцию?');
    if (!confirm) {
      setIsLoading(false);
    } else {
      editMeeting('delete');
      closeHandler();
    }
  }

  async function saveMeeting(meetingEdit, meetingData) {
    const objectForVerified = { ...meetingEdit, ...meetingData };
    const requiredFields = ['timestamp_start', 'subject', 'timestamp_end'].every((key) => objectForVerified[key]);
    if (requiredFields && data.users.length > 0) {
      // Если дата начала равна или наступает после даты завершения - уведомление
      if (meetingEdit.timestamp_start >= meetingEdit.timestamp_end) {
        await alertFunction('wrong_period', 'clientPost');
      } else {
        editMeeting('update');
        handler();
        closeHandler();
      }
    } else {
      await alertFunction('required_fields', 'clientPost');
    }
  }

  async function changeMeeting(setting) {
    setMeeting({ ...meeting, ...setting });
  }

  async function changeData(setting) {
    setData({ ...data, ...setting });
  }

  // Добавление пользователей
  async function addParticipiants(participiant) {
    if (Object.keys(participiant).length > 0) {
      if (participiants.filter((el) => el.id === participiant.id).length === 0) {
        setParticipiants((state) => [...state, participiant]);
        const newUsers = data.users;
        newUsers.push(participiant.mail);
        setData((state) => ({ ...state, users: newUsers }));
        setSelectUsers({});
      }
    }
  }

  async function deleteParticipiants(usersId) {
    const userEmail = participiants.find((el) => el.id === usersId);
    const filteredUsers = data.users.filter((el) => el !== userEmail.mail);
    setData((state) => ({ ...state, users: filteredUsers }));
    setParticipiants(participiants.filter((el) => el.id !== usersId));
  }

  async function switchOnLobby() {
    if (isLobby) {
      setData((state) => ({ ...state, lobby: true }));
    } else {
      setData((state) => ({ ...state, lobby: false }));
    }
    setIsLobby(!isLobby);
  }

  async function switchOnRepeating() {
    if (isRepeating) {
      setData((state) => ({ ...state, isRepeating: true }));
    } else {
      setData((state) => ({ ...state, isRepeating: false }));
    }
    setIsRepeating(!isRepeating);
  }

  function defaultDate(action) {
    let date = new Date();
    date = ++date + 3600000;
    date = new Date(date);
    if (action === 'start') date.setMinutes(0);
    else date.setMinutes(30);
    date.setSeconds(0);
    return date;
  }

  function changeTimeDateMeeting() {
    const NewDateStart = typeof dateStart === 'string' ? dateStart : moment(dateStart).format('YYYY-MM-DD');

    const NewTimestamp_start = typeof timestamp_start === 'string' ? timestamp_start : moment(timestamp_start).format('HH:mm');

    const NewTimestamp_end = typeof timestamp_end === 'string' ? timestamp_end : moment(timestamp_end).format('HH:mm');

    setMeeting((state) => ({ ...state, timestamp_start: `${NewDateStart} ${NewTimestamp_start}`, timestamp_end: `${NewDateStart} ${NewTimestamp_end}` }));
  }

  function changeTime(time) {
    setTimestampStart(time);
    const newTime = time.clone().add(0.5, 'hours');
    setTimestampEnd(newTime);
  }

  if (isLoading) return <CardBody><Loader /></CardBody>;
  return (
    <>
        <CardBody>
            {/* ТЕМА ВИДЕОКОНФЕРЕНЦИИ */}
            <CardSetting title="Тема" invalid={!meeting?.subject}>
            {isNewMeeting ? (
                <Input
                  type="text"
                  onChange={(e) => changeMeeting({
                    subject: e.target.value,
                  })}
                  defaultValue={meeting?.subject}
                />
            ) : (
              meeting.subject
            )}
            </CardSetting>
            {/* ДАТА */}
            <CardSetting title="Дата" invalid={!dateStart}>
                <Datetime
                  onChange={(date) => setDateStart(date)}
                  input
                  inputProps={{
                    placeholder: 'Выберите дату',
                    readOnly: true,
                  }}
                  dateFormat="YYYY-MM-DD"
                  timeFormat={false} // Установите false, чтобы исключить время
                  value={dateStart}
                />
            </CardSetting>
            {/* ВРЕМЯ НАЧАЛА */}
            <CardSetting title="Время начала" invalid={!timestamp_start}>
            <Datetime
              onChange={(time) => changeTime(time)}
              input
              inputProps={{
                placeholder: 'Выберите время',
                readOnly: true,
              }}
              dateFormat={false}
              timeFormat="HH:mm"
              timeConstraints={{ minutes: { step: 30 } }}
              value={timestamp_start}
            />
            </CardSetting>
            {/* ВРЕМЯ ЗАВЕРШЕНИЯ */}
            <CardSetting title="Время завершения" invalid={!timestamp_end}>
            <Datetime
              onChange={(time) => setTimestampEnd(time)}
              input
              inputProps={{
                placeholder: 'Выберите время',
                readOnly: true,
              }}
              dateFormat={false}
              timeFormat="HH:mm"
              timeConstraints={{ minutes: { step: 30 } }}
              value={timestamp_end}

            />
            </CardSetting>

            {/* УЧАСТНИКИ КОНФЕРЕНЦИИ */}
            <CardSetting title="Участники" invalid={!participiants.length}>
            {isNewMeeting ? (
                <>
                    <div className="tags__input-block">
                    <InputSearch
                      id="inputSearch"
                      placeholder="Введите пользователя Юникона или email внешнего участника"
                      array={users}
                      onSearch={(e) => searchWrapper(e)}
                      onChoose={(user) => setSelectUsers(user)}
                      defaultValue={selectUsers?.title}
                    />
                    <div onClick={() => (addParticipiants(selectUsers))} className="tags__button">
                        <img className="tags__img" src="../../icons/cancel.svg" alt="add-tag" />
                    </div>
                    </div>
                    <div className="tags__list">
                    {participiants?.map((el) => <p className="tags__tag" key={el.id}>
                        <span onClick={() => deleteParticipiants(el.id)} className="upu__close">X</span>
                        {el.title}
                                                </p>)}
                    </div>
                </>
            ) : (
              participiants?.map((el, index) => el.title + (index !== participiants.length - 1 ? ', ' : ''))
            )}
            </CardSetting>

             {isNewMeeting && (
                <CardSetting title="Активировать комнату ожидания">
                    <Toggle
                      state={String(!isLobby)}
                      onClick={switchOnLobby}
                    />
                </CardSetting>
             )}

             {/* Доступ в лобби */}
            {data.lobby === true && !isNewMeeting && (
                <CardSetting title="Комната ожидания">
                    <span>Активирована</span>
                </CardSetting>
            )}

 {/* -----------ПОВТОРЯЮЩАЯСЯ ВСТРЕЧА----------- */}
            {/* {isNewMeeting && (
                <CardSetting title="Повторяющаяся встреча">
                    <Toggle
                      state={String(!isRepeating)}
                      onClick={switchOnRepeating}
                    />
                </CardSetting>
            )} */}
           {!isRepeating && (
            <>
                <CardSetting title="Период повторения">
                    <Select
                      id="videoconference-choose-repeating"
                      array={repeating}
                      onChoose={(repeat) => setData((state) => ({
                        ...state,
                        repeating_id: repeat.id,
                        repeating_value: repeat.value,
                        repeating: repeat.title,
                      }))}
                      defaultValue={data.repeating || 'Период'}
                      loading={isSearching}
                    />
                </CardSetting>
                <CardSetting title="Дата окончания повторения">
                    <Datetime
                      onChange={(date) => changeData({ until: date })}
                      input
                      inputProps={{
                        placeholder: 'Выберите дату',
                        readOnly: true,
                      }}
                      dateFormat="YYYY-MM-DD"
                      timeFormat={false}
                      value={data?.until || defaultDate('until')}
                    />
                </CardSetting>
            </>
           )}
            {/* ССЫЛКА */}
            <CardSetting title="Ссылка" invalid={!data?.link}>
                <span><a href={`https://uniplace.unicon.ru/video_conferences/conf/${data.link}`}>{data.link}</a></span>
            </CardSetting>

              {/* УДАЛЕНИЕ */}
        {!isNewMeeting && (
        <CardSetting title="Удалить видеоконференцию">
          <IconButton onClick={() => deleteMeeting()} icon="delete" theme="red" />
        </CardSetting>)}

        </CardBody>
        <CardFooter>
            <Button onClick={() => saveMeeting(meeting, data)}>Сохранить</Button>
            <Button onClick={closeHandler}>Закрыть</Button>
        </CardFooter>
    </>
  );
}

export default VideoConferencesCardMain;
