import React, { useState } from 'react';
import { clearHtmlString, stringMatch } from '../../../../utils/functions/stringHandling';
import { catchHandler } from '../../../../utils/error_handling/error_handling';
import Button from '../../../../components/UI/Button/Button';
import Input from '../../../../components/UI/Input';
import Card from '../../../../components/Card/Card';
import CardBody from '../../../../components/Card/CardBody';
import CardFooter from '../../../../components/Card/CardFooter';
import Table from '../../../../components/Table/Table';
import THead from '../../../../components/Table/THead';
import TRow from '../../../../components/Table/TRow';
import TData from '../../../../components/Table/TData';
import TBody from '../../../../components/Table/TBody';

/**
* @component Управление Базой знаний - Вопросы - карточка вопроса - Форма поиска и замены
* @prop {scheme} object - схема вопроса
* @prop {question} object - объект вопроса
* @prop {setQuestion} function - обновление состояния объекта вопроса
* @prop {setShow} function - обновление состояния отображения (открыть/закрыть форму)
*/
function Replace(props) {
  const {
    scheme, question, setQuestion, setShow,
  } = props;

  const [searchResults, setSearchResults] = useState([]); // результаты поиска
  const [searchString, setSearchString] = useState(''); // заменяющая строка
  const [replaceString, setReplaceString] = useState(''); // заменяющая строка
  const range = 60; // количество символов до и после результата
  // поля для поиска
  const changeableFields = Object.keys(question).filter((key) => (
    (question[key] && scheme[key]?.type === 'html')
    // || (question[key] && scheme[key]?.type === "string")
  ));
  // массив для поиска
  const searchArray = changeableFields.map((key) => ({ key, string: clearHtmlString(question[key]) }));

  // Функция поиска
  function searchHandler(e) {
    try {
      setSearchString(e.target.value);

      if (e.target.value.length > 1) {
        const result = [];
        // Результат поиска - массив
        const findMatches = searchArray.map((item) => {
          let match = stringMatch(e.target.value, item.string, 'all');
          match = match?.map((row) => row.index);
          return {
            key: item.key,
            indexes: match || [],
          };
        });
        // findMatches = findMatches.filter(item => item.indexes.length > 0)
        findMatches.forEach((item) => {
          const { key, indexes } = item;
          indexes.forEach((index) => {
            result.push({ key, index });
          });
        });
        setSearchResults(result);
      } else setSearchResults([]);
    } catch (error) {
      catchHandler(error, 'searchHandler');
    }
  }

  // Добавить контекст
  const addContext = (key, index) => {
    const text = clearHtmlString(question?.[key]); // обработать строку (убрать теги, кавычки и т.д.)
    const startIndex = index - range < 0 ? 0 : index - range; // определить начальный индекс
    const endIndex = index + range > text.length ? text.length : index + range; // определить конечный индекс
    const regexp = new RegExp(searchString, 'ig'); // регулярка с искомым текстом (все совпадения игнорируя регистр)

    let result = text?.slice(startIndex, endIndex); // вырезать кусок текста для отображения
    result = result.replaceAll(regexp, `<span style='display:inline; color: red'>${searchString}</span>`); // подкрасить в искомый текст
    if (startIndex > 0) result = `...${result}`; // если индекс не первый добавить многоточие в начале
    if (endIndex < text.length) result += '...'; // если индекс не последний добавить многоточие в конце
    return result;
  };

  // Обработчик замены
  function replaceHandler() {
    if (searchString.length > 0) {
      const confirm = window.confirm(`Заменить ${searchString} на ${replaceString}?`); // подтверждение
      if (confirm) { // если пользователь подтверждает
        const copy = { ...question }; // копия вопроса
        const regexp = new RegExp(searchString, 'ig'); // регулярка для замены всех вхождений

        changeableFields.forEach((field) => { // пройти по изменяемым ключам
          const newString = question[field].replaceAll(regexp, replaceString); // заменить данные
          copy[field] = newString; // записать в копию
        });
        setQuestion(copy); // обновить состояние вопроса
        setSearchString(''); // сбросить искомую строку
      }
    }
  }

  return (
    <Card background_id="knowledge-base__replace-form" setShow={setShow}>
      <CardBody>
        <Table>
          <THead>
            <TRow>
              <TData>
                <Input
                  value={searchString}
                  onChange={searchHandler}
                  placeholder="Что найти"
                />
              </TData>
              <TData>
                <Input
                  value={replaceString}
                  onChange={(e) => setReplaceString(e.target.value)}
                  placeholder="На что заменить"
                />
              </TData>
            </TRow>
          </THead>
          {/* Тело таблицы */}
          <TBody>
            {searchResults.map((item) => (
              <TRow key={item.key + item.index}>
                <TData>{scheme[item.key]?.name}</TData>
                <td className="upu-table__table-data" dangerouslySetInnerHTML={{ __html: addContext(item.key, item.index) }} />
              </TRow>
            ))}
          </TBody>
        </Table>
      </CardBody>
      <CardFooter>
        <Button onClick={replaceHandler}>Заменить</Button>
      </CardFooter>
    </Card>
  );
}

export default Replace;
