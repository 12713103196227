import React, { useEffect, useState } from 'react';
import { authorization } from '../../utils/functions/authenticate';
import { setOperInfo } from '../../utils/functions/others';
import { clientSendData } from '../../utils/functions/requests';
import { catchHandler } from '../../utils/error_handling/error_handling';
import UserInterface from './UserInterface/UserInterface';
import AdminInterface from './AdminInterface/AdminInterface';
import Service from '../../components/Service/Service';
import Loader from '../../components/UI/Loader';
import Cap from '../Cap/Cap';
import './portalState.scss';

/**
* @component Состояние портала
* Создание контролей(работ/мероприятий), разделение их на события с различными статусами
* Отправка уведомлений на основании статусов событий
* Имеет 2 интерфейса, доступ к которым настраивается
*/
function PortalState() {
  const [isAuthorized, setIsAuthorized] = useState(false); // Состояние авторизации
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [pages, setPages] = useState([]); // страницы доступные пользователю
  const components = { // Компоненты для отображения в сервисе
    admin: <AdminInterface />, // Интерфейс администратора
    user: <UserInterface />, // Интерфейс пользователя
  };

  useEffect(() => {
    setOperInfo();
    awaitRequests();
  }, []);

  async function awaitRequests() {
    setIsLoading(true);
    const checkAuthorization = await authorization(); // авторизация
    setIsAuthorized(checkAuthorization);
    if (checkAuthorization) await getPsInterfaces(); // получить интерфейсы пользователя
    setIsLoading(false);
  }

  // Получить интерфейсы доступные пользователю
  async function getPsInterfaces() {
    try {
      const reqData = {
        type: 'getPsInterfaces',
        location: window.location.pathname,
      };
      const result = await clientSendData('POST', '/get_ps_interfaces', reqData);
      if (result) {
        const userPages = []; // Пустой массив для страниц
        result?.forEach((item) => {
          const component = item?.component; // Имя компонента
          // Проверяем, есть ли такой компонент в объекте 'components'
          if (components[component]) {
            // Если компонент существует, добавляем две страницы
            userPages.push(
              { // Компонент с основной информацией
                id: component, // идентификатор страницы
                title: item.title, // заголовок страницы
                component: components[component], // соответствующий компонент
              },
            );
          }
        });
        setPages(userPages); // Результат записывается в состояние
      }
    } catch (error) {
      catchHandler(error, 'getPsInterfaces');
    }
  }

  if (isLoading) return <Loader />; // если идет загрузка - показать лоадер
  if (isAuthorized) { // если пользователь авторизован
    // если пользователю не доступен ни один интерфейс - заглушка
    if (pages.length === 0) {
      return (
        <div className="upu-service_padding-top">
          <h2 className="upu-service__title">Доступные инфтерфейсы отсутствуют</h2>
        </div>
      );
    }

    return ( // иначе показываем то, что доступно пользователю
      <Service
        id="portal-state"
        pages={pages}
        horizontal
      />
    );
  } return <Cap />; // если пользователь не авторизован - показать заглушку
}

export default PortalState;
