import React, { useEffect, useState } from 'react';
import { catchHandler } from '../../../utils/error_handling/error_handling';
import { clientSendData } from '../../../utils/functions/requests';

/**
* @component Состояние портала - интерфейс администратора - Статусы - Боковая панель
*/
function StatusesLegend() {
  const [statuses, setStatuses] = useState([]); // все статусы
  const [openedStatus, setOpenedStatus] = useState();
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const headers = [
    { id: 1, title: 'Цвет', field: 'color' },
    {
      id: 2, title: 'Статус', field: 'title', align: 'left',
    },
  ];

  useEffect(() => {
    handlePsStatuses('get');
  }, []);

  // Обработка действий со статусами
  async function handlePsStatuses(action) {
    try {
      const reqData = {
        type: 'GetLegendStatuses',
        status: openedStatus,
        action,
      };

      setIsLoading(true);
      const result = await clientSendData('POST', '/get_legend_statuses', reqData);
      if (result?.success) {
        setStatuses(result.data);
      }
    } catch (error) {
      catchHandler(error, 'GetLegendStatuses');
    } finally {
      setIsLoading(false);
    }
  }
  return (
    <div className="div-panel">
    <div className="div-panel__inner">
      {headers.map((header) => {
        const { title, id } = header;
        return (
          <div className="column" key={id}>
            <strong>{title}</strong>
          </div>
        );
      })}
    </div>
    {statuses.map((status) => (
      <div
        className="left-panel"
        key={`${status.id}-${Math.random()}`}
      >
        {headers.map((header) => {
          const { field } = header;
          return (
            <div className="column" key={field}>
              {field !== 'color' ? status[field] : (
                <div style={{
                  backgroundColor: status[field],
                  width: '20px',
                  height: '20px',
                  borderRadius: '50%',
                  display: 'inline-block',
                  marginRight: '5px',
                }}
                />
              )}
            </div>
          );
        })}
      </div>
    ))}
    </div>
  );
}

export default StatusesLegend;
