import React, { useState } from 'react';
import InfoBlock from './InfoBlock';
import Panel from './Panel/Panel';
import Service from '../../../components/Service/Service';

/**
* @component Сервис отчётов анкетирования - Базовый отчёт
*/
function BasicReport() {
  const [currentReport, setCurrentReport] = useState(null); // текущий отображаемый отчёт
  return (
    <Service id="reports-basic" vertical>
      <Panel setCurrentReport={setCurrentReport} />
      <InfoBlock currentReport={currentReport} />
    </Service>
  );
}

export default BasicReport;
