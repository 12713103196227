import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { createAction, store } from '../redux/store';
import { catchHandler } from '../error_handling/error_handling';
import { getCookie } from './cookies';
import { sendData } from './requests';

/**
 * @function Запись в лог
 * @prop {data} object - (Обязательный) Объект с данными для лога
 * @key {message} string - (Обязательный) сообщение для лога
 * @key {eventtype} string - тип события (info/warning/error) 'info' по умолчанию
 * @key {subject_id} string - id субъекта над которым совершается действие
*/
export async function log(data) {
  const { message, eventtype = 'info', subject_id } = data;

  const operInfo = store.getState().oper_info; // получение объекта оперативной информации
  const { session_id, alias, log_level } = operInfo; // деструктуризация объекта оперативной информации
  const reqData = {
    session_id, // id сессии
    log: { // данные для лога
      source: alias, // источник - псевдоним сервиса
      level: log_level, // уровень логирования
      eventtype, // тип события
      subject_id: subject_id || operInfo?.subject_id, // id субъекта
      message, // сообщение
      type: session_id ? 'user' : 'system', // тип записи
      sys_type: 'frontend',
    },
  };
  // Если лог включен - пишем все
  if (operInfo.log) sendData('POST', '/logger_frontend', reqData);
  // Если выключен, пишем только ошибки
  if (!operInfo.log && eventtype === 'error') sendData('POST', '/logger_frontend', reqData);

  // }
}

/**
 * @function Запись оперативной информации в хранилище
 * @prop {info} object - Объект с дополнительной информацией (необязательный аргумент)
*/
export function setOperInfo(info = {}) {
  try {
    let route = window.location.pathname; // текущий адрес
    if (route.includes('interview')) route = '/interview/'; // если это страница интервью - оставить только interview
    const services = store.getState().services.aliases; // Массив объектов всех сервисов ({link, alias})
    const service = services.find((item) => item.link === route); // Ищем сервис по текущему адресу
    const foundedAlias = service?.alias || null; // найденный псевдоним
    const alias = foundedAlias || info?.alias || route; // найденный или переданный псевдоним или адрес

    // Поместить в хранилище оперативную информацию
    createAction('SET_OPER_INFO', {
      ...info,
      alias,
      url: route,
      session_id: getCookie('session'),
      // page: true
    });
  } catch (error) {
    catchHandler(error, 'setOperInfo');
  }
}

/**
 * @function Определение клиента(браузера) пользователя
*/
export function getUserAgent() {
  try {
    const { userAgent } = window.navigator;
    if (userAgent.indexOf('Firefox') > -1) return 'Mozilla Firefox';
    if (userAgent.indexOf('Opera') > -1) return 'Opera';
    if (userAgent.indexOf('Trident') > -1) return 'Internet Explorer';
    if (userAgent.indexOf('Edge') > -1) return 'Microsoft Edge';
    if (userAgent.indexOf('Chrome') > -1) return 'Google Chrome';
    if (userAgent.indexOf('Safari') > -1) return 'Apple Safari';
    return 'unknown';
  } catch (error) {
    catchHandler(error, 'getUserAgent');
    return 'error';
  }
}

/**
 * @function Сохранение таблицы в excel файл
 * @prop {id} string - (Обязательный) id DOM-элемента таблицы
 * @prop {filename} string - (Обязательный) имя сохраняемого файла
*/
// Функция сохранения таблицы в excel файл
export function tableToExcel(id, filename) {
  const uri = 'data:application/vnd.ms-excel;base64,';
  // eslint-disable-next-line no-useless-concat
  const template = '\uFEFF' + `
  <html 
    xmlns:o="urn:schemas-microsoft-com:office:office" 
    xmlns:x="urn:schemas-microsoft-com:office:excel" 
    xmlns="http://www.w3.org/TR/REC-html40"
  >
    <head>
      <!--[if gte mso 9]>
        <xml>
          <x:ExcelWorkbook>
            <x:ExcelWorksheets>
              <x:ExcelWorksheet>
                <x:Name>
                  {worksheet}
                </x:Name>
                <x:WorksheetOptions>
                  <x:DisplayGridlines/>
                </x:WorksheetOptions>
              </x:ExcelWorksheet>
            </x:ExcelWorksheets>
          </x:ExcelWorkbook>
        </xml>
      <![endif]-->
    </head>
    <body>
        <table>{table}</table>
    </body>
  </html>`;

  function base64(s) {
    return window.btoa(unescape(encodeURIComponent(s)));
  }

  function format(s, c) {
    return s.replace(/{(\w+)}/g, (m, p) => c[p]);
  }

  // Убрать ненужные данные из html
  function cleanHtml(html) {
    let result = html.replace(/<img .*?>/gm, ''); // удалить изображения
    result = result.replace(/<input .*?>/gm, ''); // удалить поля ввода
    result = result.replaceAll(/class=".*?"/gm, "style='mso-number-format:\"@\"'"); // замена класса на строковый формат ячейки
    result = result.replaceAll(/[↑↓]/gm, ''); // удалить символы сортировки
    return result;
  }

  const table = document.getElementById(id).cloneNode(true); // копия сохраняемой таблицы
  const tHead = table.querySelector('thead'); // хедер таблицы
  tHead.querySelector('.upu-table__header')?.remove(); // удаление строки с заголовком таблицы
  tHead.querySelector('tr[class*="filter-block"]')?.remove(); // удаление строки с фильтрами (filter-block или filter-block_active)
  const tBody = table.querySelector('tbody'); // тело таблицы

  const ctx = {
    worksheet: 'Worksheet',
    table: cleanHtml(`${tHead.innerHTML}\n${tBody.innerHTML}`), // удалить ненужные данные из html
  };
  const link = document.createElement('a');
  link.download = `${filename || 'Без имени'}.xls`;
  link.href = uri + base64(format(template, ctx));
  link.click();
}

/**
 * @function Сохранение таблицы в word файл
 * @prop {data} HtmlString - (Обязательный) HTML контент для сохранения в виде строки
 * @prop {filename} string - (Обязательный) имя сохраняемого файла
*/
export function saveAsWord(data, filename) {
  const header = `
  <html 
    xmlns:o='urn:schemas-microsoft-com:office:office' 
    xmlns:w='urn:schemas-microsoft-com:office:word' 
    xmlns='http://www.w3.org/TR/REC-html40'
  >
    <head>
      <meta charset='utf-8'>
      <title>Export HTML to Word Document with JavaScript</title>
    </head>
    <body>`;

  const footer = `
    </body>
  </html>`;
  const html = header + data + footer;
  const blob = new Blob(['\ufeff', html], {
    type: 'application/msword',
  });
  const url = `data:application/vnd.ms-word;charset=utf-8,${encodeURIComponent(html)}`;
  const checkFileName = filename ? `${filename}.doc` : 'document.doc';
  // Create download link element
  const downloadLink = document.createElement('a');
  document.body.appendChild(downloadLink);
  if (navigator.msSaveOrOpenBlob) {
    navigator.msSaveOrOpenBlob(blob, checkFileName);
  } else {
    downloadLink.href = url;
    downloadLink.download = checkFileName;
    downloadLink.click();
  }
  document.body.removeChild(downloadLink);
}

/**
 * @function Сравнение значений объектов
 * @prop {object1} object - (Обязательный) Объект для сравнения
 * @prop {object2} object - (Обязательный) Объект для сравнения
*/
export function compareObjectsValues(object1, object2) {
  if (object1 && object2) {
    const keys = Object.keys(object1); // ключи первого объекта
    const check = keys.map((key) => { // проходим циклом по ключам
      if (object1[key] === object2[key]) return true; // если значение одинаковых ключей равны - true
      return false; // иначе false
    }); // на выходе из цикла получаем массив булевых значений
    if (check.includes(false)) return false; // если есть хотя бы один false - функция возвращает false
    return true; // иначе true
  } return false;
}

/**
 * @function Глубокое сравнение объектов с учётом вложенности
 * @prop {object1} object - (Обязательный) Объект для сравнения
 * @prop {object2} object - (Обязательный) Объект для сравнения
*/
export function deepObjectsComparing(object1, object2) {
  // Если одинаковые ссылки на объекты, значит они равны или одинаковые данные
  if (object1 === object2) return true;
  // Если данные не являются объектами или объекты отсутствуют - вернуть false
  if (typeof object1 !== 'object' || typeof object2 !== 'object' || !object1 || !object2) return false;
  // Ключи объектов
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  // Если количество ключей отличается - вернуть false
  if (keys1.length !== keys2.length) return false;
  // Пройти по ключам первого объекта и сравнить значения рекурсивно
  const result = keys1.every((key) => deepObjectsComparing(object1[key], object2[key]));
  // Вернуть результат
  return result;
}

/**
 * @function Получение css свойства
 * @prop {element} HtmlElement - (Обязательный) DOM элемент которому принадлежит свойство
 * @prop {name} string - (Обязательный) название свойства (например fontSize, width, height)
*/
export function getCssProperty(element, name) {
  return window.getComputedStyle(document.documentElement).getPropertyValue(name);
}

/**
 * @function Обработка подсветки элемента на основании длины массива
 * @prop {array} array - (Обязательный) Массив для проверки
 * @prop {setState} function - (Обязательный) функция обновления состояния подсветки
*/
export function backlightHandler(array, setState) {
  if (array.length > 1) setState('yellow'); // если найдено больше 1 результата - желтый
  else if (array.length === 1) setState('green'); // если найден один результат - зеленый
  else setState('red'); // иначе красный
}

/**
 * @function Запрос подтверждения при закрытии вкладки или браузера
*/
export function closeWindowAlert() {
  // Функция которая выполнится при срабатывании события
  const beforeUnLoad = (e) => {
    e.preventDefault(); // предотвратить действие по умолчанию
    e.stopPropagation(); // остановить погружение в родительские элементы
    e.returnValue = ''; // возвращаемое значение (не отображается)
  };

  // добавить слушатель
  window.addEventListener('beforeunload', beforeUnLoad);
  return () => {
    // удалить слушатель
    window.removeEventListener('beforeunload', beforeUnLoad);
  };
}

// Скрыть всплывающее меню селекта
/**
 * @function Запрос подтверждения при закрытии вкладки или браузера
 * @prop {event} object - (Обязательный) Объект события event
*/
export function hideSelect(event) {
  if (event) event.stopPropagation(); // предотвратить погружение клика в родительские элементы
  createAction('TOGGLE_SELECT', false); // скрыть всплывающее меню селекта
}

/**
 * @function Получение уникальных значений при использовании метода массива filter
 * @prop {value} any - (Обязательный) текущий элемент массива
 * @prop {index} number - (Обязательный) индекс текущего элемента массива
 * @prop {self} array - (Обязательный) ссылка на сам массив
 * Пример - array.filter(setUnique)
*/
export const setUnique = (value, index, self) => self.indexOf(value) === index;

/**
 * @function Определение данные объекта по вложенному ключу "key1.key2.key3"
 * @prop {objectData} object - (Обязательный) Объект с данными
 * @prop {field} string - (Обязательный) Ключ по которому нужно получить данные
*/
export function defineData(objectData, field) {
  const keys = field.split('.'); // разделить ключ схемы через точку

  // Углубиться в объект и получить данные
  function deepIntoTheObject(data, deep) {
    // если нет данных - возвращаем null
    if (!data) return '';
    // Если глубина равна длине массива вложенности ключей
    if (deep === (keys.length)) {
      if (typeof data === 'object') return JSON.stringify(data);
      return String(data);
    }
    // Иначе берем значение текущей глубины и идем дальше
    return deepIntoTheObject(data?.[keys?.[deep]], deep + 1);
  }

  // Вернуть значение
  return deepIntoTheObject(objectData, 0);
}

/**
 * @function Сохранение html элемента в pdf файл
 * @prop {id} string - (Обязательный) id DOM-элемента таблицы
 * @prop {filename} string - (Обязательный) имя сохраняемого файла
*/
export async function pageToPdf(id, filename) {
  try {
    const content = document.getElementById(id);
    html2canvas(content)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        // eslint-disable-next-line new-cap
        const pdf = new jsPDF('landscape', 'px', 'A4');
        pdf.addImage(imgData, 'JPEG', 0, 0);
        pdf.save(`${filename || 'page'}.pdf`);
      });
  } catch (error) {
    catchHandler(error, 'createPdf');
  }
}

/**
 * @function Создание независимой копии объекта или массива
 * @prop {obj} object - (Обязательный) id DOM-элемента таблицы
 * Можно передать как объект, так и массив
*/
export function createCopy(obj) {
  function copyProps(clone) {
    Object.keys(obj).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        // eslint-disable-next-line no-param-reassign
        clone[key] = createCopy(obj[key]);
      }
    });
  }

  // Создание неизменяемой копии объекта
  function cloneObj() {
    const clone = {};
    copyProps(clone);
    return clone;
  }

  // Создание неизменяемой копии массива
  function cloneArr() {
    return obj.map((item) => createCopy(item));
  }

  // Получение типа объекта
  const type = Object.prototype.toString.call(obj).slice(8, -1).toLowerCase();

  // Возвращаем копию в зависимости от типа исходных данных
  if (type === 'object') return cloneObj();
  if (type === 'array') return cloneArr();

  return obj;
}

/**
 * @function Скачивание Blob
 * @prop {dataUrl} string - (Обязательный) Строка представляющая собой данные файла в формате Data URL
 * @prop {filename} string - (Обязательный) имя сохраняемого файла
*/
export function downloadDataUrl(dataUrl, filename) {
  const link = document.createElement('a');
  link.href = dataUrl;
  link.download = filename;
  link.click();
}
