import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { setOperInfo } from '../../../utils/functions/others';
import { catchHandler } from '../../../utils/error_handling/error_handling';
import { clientSendData } from '../../../utils/functions/requests';
// import { convertTicketId } from '../common';
import QuestionnaireProcess from './QuestionnaireProcess/QuestionnaireProcess';
import CardHeader from '../../../components/Card/CardHeader';
import CardBody from '../../../components/Card/CardBody';
import TabPanel from '../../../components/UI/TabPanel';
import History from './History';
import Card from '../../../components/Card/Card';

/**
  * @component Клиентский портал - заявки - карточка заявки
  * @prop {setShowCard} function - обновление состояния отображения карточки (открыть/закрыть)
  * @prop {refreshTable} function - обновление данных таблицы
*/
function QuestionnaireCard(props) {
  const { setShowCard, refreshTable } = props;

  const tabs = ['Работа по вопросу', 'История']; // вкладки
  const [searchParams, setSearchParams] = useSearchParams(); // параметры поиска из url
  const [ticketID, setTicketID] = useState(null); // id открытой карточки
  const [isLoading, setIsLoading] = useState(false); // состояние выбора вкладки
  const [choice, setChoice] = useState(); // состояние выбора вкладки
  const content = {
    'Работа по вопросу': <QuestionnaireProcess ticketID={ticketID} />,
    История: <History subject_id={ticketID} type="1" />,
  };

  // закрыть карточку
  function closeHandler() {
    setOperInfo({ subject_id: null }); // сброс id категории в оперативной информацию
    setSearchParams(''); // сброс параметром url
    setShowCard(false); // закрытие карточки
    document.title = 'UNIPLACE';
  }

  // обработка выбора вкладки
  function choiceHandler(tab) {
    setChoice(tab); // установить выбор
  }

  return (
    <Card id="helpdesk__ticket-card" setShow={closeHandler}>
      <CardHeader>
        <TabPanel tabs={tabs} choice={choice} choiceHandler={choiceHandler} />
      </CardHeader>
      <CardBody>
        {content[choice]}
      </CardBody>
    </Card>
  );
}

export default QuestionnaireCard;
