import React from 'react';
import InfoBlock from './InfoBlock/InfoBlock';
import Panel from './Panel/Panel';

// Отображение в виде таблицы
function TableOutput() {
  return (
    <div id="interview" className="upu-service">
        <Panel />
        <InfoBlock />
    </div>
  );
}

export default TableOutput;
