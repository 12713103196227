import React from 'react';
import './asap.scss'; // Убедитесь, что у вас есть файл стилей для ProgressBar

// Компонент ProgressBar, который принимает значение progress
function ProgressBar({ progress, fileName }) {
  return (
    <div className="progress-bar">
      <div className="progress-bar__filler" style={{ width: `${progress}%` }}>
        {/* Текст внутри полосы прогресса */}
        <span className="progress-bar__text">
          {progress < 100 ? `${progress.toFixed(0)}% ${fileName}` : 'Загрузка завершена'}
        </span>
      </div>
    </div>
  );
}

export default ProgressBar;
