import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { catchHandler } from '../../../../utils/error_handling/error_handling';
import { clientSendData } from '../../../../utils/functions/requests';
import Card from '../../../../components/Card/Card';
import CardHeader from '../../../../components/Card/CardHeader';
import CardFooter from '../../../../components/Card/CardFooter';
import CardSetting from '../../../../components/Card/CardSetting';
import Wrapper from '../../../../components/Basic/Wrapper';
import InputFit from '../../../../components/UI/InputFit/InputFit';
import Button from '../../../../components/UI/Button/Button';

/**
* @component База знаний - Вопросы - форма поиска
* @prop {setShow} function - обновление состояния отображения (открыть/закрыть форму)
* @prop {getQuestionsKb} function - получение вопросов из БД
* @prop {setQuestions} function - обновление состояния вопросов
* @prop {isUserInterface} boolean - форма вызвана из пользовательского интерфеса
*/
function SearchForm(props) {
  const {
    setShow, getQuestionsKb, setQuestions, isUserInterface,
  } = props;

  const [searchData, setSearchData] = useState([]);
  const questionScheme = useSelector((state) => state.kb.active_scheme.question); // схема вопроса
  const { scheme, id } = questionScheme; // активная схема вопроса и её id
  const currentDivision = useSelector((state) => state.kb.current_division); // текущее подразделение
  const formRef = useRef(null);
  const [hardSearch, setHardSearch] = useState(false); // состояние параметра строгого поиска

  // Определение searchable полей
  let searchableFields = Object.keys(scheme).filter((field) => scheme[field]?.searchable);
  // Удаление user_hide полей если форма вызывается из пользовательского интерфейса
  searchableFields = isUserInterface ? searchableFields.filter((field) => !scheme[field]?.user_hide) : searchableFields;

  // Обработчик добавления данных для поиска
  function inсludeHandler(field, value) {
    if (value) { // если инпут не пустой
      const findObject = searchData.find((item) => item.field === field); // найти объект текущего поля
      if (findObject) {
        setSearchData(searchData.map((item) => { // обновить данные фильтрации
          if (item.field === field) { // найти объект текущего поля
            return { ...item, value }; // и обновить значение
          } return item;
        }));
      } else { // если объект не найден
        setSearchData([...searchData, { // добавить его в массив
          field, // поле
          value, // значение
          exclude: null, // массив исключений
          type: scheme[field].type, // тип поля
        }]);
      }
    } else { // иначе (инпут пустой)
      // обновить данные фильтрации - оставить объекты всех полей кроме текущего (удалить объект текущего поля)
      setSearchData(searchData.filter((item) => item.field !== field));
    }
  }

  // Обработчик добавления исключений
  function exсludeHandler(field, value) {
    const findObject = searchData.find((item) => item.field === field); // найти объект текущего поля
    const array = value?.length > 0 ? value : null;
    if (findObject) {
      setSearchData(searchData.map((item) => { // обновить данные фильтрации
        if (item.field === field) { // найти объект текущего поля
          return { ...item, exclude: array }; // и обновить значение
        } return item;
      }));
    } else { // если объект не найден
      setSearchData([...searchData, { // добавить его в массив
        field, // поле
        value: '', // значение
        exclude: array, // массив исключений
        type: scheme[field].type, // тип поля
      }]);
    }
  }

  // Вернуть тип string для определённых типов полей
  function checkType(type) {
    switch (type) {
      case 'string':
      case 'json': // при перечисленных выше типах
      case 'html':
      case 'number':
        return 'string';

      default: return type;
    }
  }

  // Обработчик поиска по всем полям
  async function searchQuestions() {
    try {
      if (searchData.length > 0) {
        const reqData = {
          type: 'kbSearch',
          searchObj: {
            type: 'full',
            scheme_id: id,
            searchArray: searchData, // массив данных для поиска [{field: "field", value: "value"}, ...]
            hardSearch,
            table: 'kb_questions',
            column: 'essence',
            division_id: currentDivision.id,
          },
        };

        const result = await clientSendData('POST', '/kb_search', reqData);
        if (result) {
          // если массив id не пустой - запросить вопросы по этим id
          if (result.length > 0) {
            await getQuestionsKb(result);
            setShow(false);
          } else {
            setQuestions([]); // иначе сбросить массив вопросов
            setShow(false);
          }
        }
      } else return;
    } catch (error) {
      catchHandler(error, 'searchQuestions');
    }
  }

  return (
    <Card setShow={setShow}>
      {/* Хедер карточки */}
      <CardHeader>
        <h2 className="upu-card__title">Поиск по полям</h2>
      </CardHeader>

      {/* Тело карточки */}
      <form ref={formRef} className="upu-card__body">
        <CardSetting title="Строгий поиск:">
          <InputFit type="bool" value={hardSearch} onChange={(value) => setHardSearch(value)} />
        </CardSetting>

        {searchableFields.map((field) => {
          const { name, type } = scheme[field]; // получить наименование и тип поля из схемы
          const checkedType = checkType(type); // проверить тип
          return (
          <CardSetting title={`${name}:`}>
            <Wrapper flexFlow="row nowrap">
            <InputFit type={checkedType} onChange={(value) => inсludeHandler(field, value)} placeholder="Включить" />
            {/* Показать добавление исключений для определённых типов полей */}
            {(checkedType === 'string' || checkedType === 'number' || checkedType === 'big_number' || checkedType === 'day_of_week') && (
            <InputFit type="array" onChange={(value) => exсludeHandler(field, value)} />)}
            </Wrapper>
          </CardSetting>
          );
        })}
      </form>

      {/* Футер карточки */}
      <CardFooter>
        <Button onClick={() => formRef.current?.reset()}>Сбросить</Button>
        <Button onClick={searchQuestions}>Найти</Button>
      </CardFooter>
    </Card>
  );
}

export default SearchForm;
