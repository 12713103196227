import React, { useEffect, useState } from 'react';
import { alertFunction } from '../../../../utils/functions/alertFunction';
import { catchHandler } from '../../../../utils/error_handling/error_handling';
import { clientSendData } from '../../../../utils/functions/requests';
import CardHeader from '../../../../components/Card/CardHeader';
import CardBody from '../../../../components/Card/CardBody';
import Card from '../../../../components/Card/Card';
import CardSetting from '../../../../components/Card/CardSetting';
import Input from '../../../../components/UI/Input';
import Select from '../../../../components/UI/Select';
import CardFooter from '../../../../components/Card/CardFooter';
import Button from '../../../../components/UI/Button/Button';
import Toggle from '../../../../components/UI/Toggle';
import InputSearch from '../../../../components/UI/InputSearch';

/**
  * @component Бронирование мест - бронирования пользователя - карточка бронирования
  * @prop {setShowCard} function - обновление состояния отображения карточки (открыть/закрыть)
  * @prop {refreshTable} function - обновление данных таблицы
*/
function NewBookingCard(props) {
  const { setShowCard, refreshTable } = props;
  const [booking, setBooking] = useState({}); // объект с данными бронирования
  const [isLoading, setIsLoading] = useState(false); // состояние выбора вкладки
  const [relatedData, setRelatedData] = useState({ // сопутствующая информация
    zones: [], //
    places: [], //
    types: [], //
    users: [],
  });
  const [isSearching, setIsSearching] = useState(false); // состояние загрузки при поиске
  const [selectUsers, setSelectUsers] = useState({});
  const [participiants, setParticipiants] = useState([]);
  const [data, setData] = useState({ users: [] });
  const [usersBookingArray, setUsersBookingArray] = useState([]);
  const currentDate = new Date();
  const today = currentDate.getDate() <= 9
    ? `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`
    : `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${currentDate.getDate()}`;

  useEffect(() => {
    getBookingRelatedData();
  }, []);

  async function searchWrapper(e) {
    setIsSearching(true); // поиск выполняется
    if (e.target.value.length === 0) setBooking((prev) => ({ ...prev }));
    try {
      const reqData = {
        type: 'searchUser',
        query: e.target.value,
      };
      const result = await clientSendData('POST', '/search_user', reqData);
      if (result?.success) setRelatedData((state) => ({ ...state, users: result.data }));
      setIsSearching(false);
      return [];
    } catch (error) {
      catchHandler(error, 'searchUser');
      setIsSearching(false);
      return [];
    }
  }

  async function getBookingRelatedData() {
    try {
      setIsLoading(true);
      const reqData = { type: 'getBookingRelatedData' };
      const result = await clientSendData('POST', '/get_booking_related_data', reqData);
      if (result?.success) {
        setRelatedData((state) => ({ ...state, ...result.data }));
      }
      // const nextWorkingDay = await getNextWorkingDay();
      // setBooking((state) => ({ ...state, date: nextWorkingDay }));
    } catch (error) {
      catchHandler(error, 'getBookingRelatedData');
    } finally {
      setIsLoading(false);
    }
  }

  // Валидация бронирования перед сохранением
  // Если каждый из ключей в массиве прошел валидацию
  const validateBooking = () => (['zone_id', 'place_id', 'date_start'].every((key) => validateField(key)));
  // Валидация отдельного ключа
  const validateField = (field) => {
    if (field === 'date_start' && booking?.whole_day) return true;
    if (field === 'date_end' && booking?.whole_day) return true;
    return Boolean(booking[field]);
  };

  // Забронировать место
  async function bookWorkplace() {
    try {
      if (validateBooking()) {
        setIsLoading(true);
        const reqData = { type: 'bookWorkplace', booking, usersBookingArray };
        const result = await clientSendData('POST', '/book_workplace', reqData);
        if (result?.success) {
          if (result?.intersection) {
            await alertFunction('booking_intersection', 'clientPost');
          } else {
            await refreshTable();
            await alertFunction('booking_created', 'clientPost');
            closeHandler();
          }
        }
      } else {
        await alertFunction('required_fields', 'clientPost');
      }
    } catch (error) {
      catchHandler(error, 'bookWorkplace');
    } finally {
      setIsLoading(false);
    }
  }

  async function getBookingInfo(bookingData) {
    try {
      const reqData = { type: 'getBookingInfo', bookingData };
      const result = await clientSendData('POST', '/get_booking_info', reqData);
      if (result?.success) {
        if (!result.data.bookingPermission) alertFunction('booking_intersection', 'client');
        setRelatedData((state) => ({ ...state, ...result.data }));
      }
      // const nextWorkingDay = await getNextWorkingDay();
      // setBooking((state) => ({ ...state, date: nextWorkingDay }));
    } catch (error) {
      catchHandler(error, 'getBookingRelatedData');
    } finally {
      setIsLoading(false);
    }
  }

  // закрыть карточку
  const closeHandler = () => setShowCard(false);

  async function chooseDates(dateObj) {
    setBooking((state) => ({
      ...state,
      ...dateObj,
      zone_id: null,
      zone_title: null,
      place_id: null,
      place_title: null,
    }));
    const bookingData = { ...booking, ...dateObj };
    const verifyFields = ['user_id', 'date_start', 'date_end'].every((key) => Boolean(bookingData[key]));
    if (verifyFields) await getBookingInfo(bookingData);
  }

  // Выбор сотрудника
  async function chooseUser(user) {
    setSelectUsers((state) => ({ ...state, user_id: user.id, user_title: user.title }));
    setBooking((state) => ({ ...state, user_id: user.id, user_title: user.title }));
    const bookingData = { ...booking, user_id: user.id };
    await getBookingInfo(bookingData);
  }

  // Выбор зоны
  async function chooseZone(zone) {
    setBooking((state) => ({
      ...state,
      zone_id: zone?.id,
      zone_title: zone?.title,
      place_id: null,
      place_title: null,
    }));
    const bookingData = { ...booking, zone: zone.id };
    await getBookingInfo(bookingData);
  }

  // Выбор места
  function choosePlace(place) {
    setBooking((state) => ({
      ...state,
      place_id: place?.id,
      place_title: place?.title,
      type_id: place?.type_id,
      type_title: place?.type_title,
    }));
  }

  function chooseBlock(block) {
    setBooking((state) => ({
      ...state,
      block_title: block?.title,
    }));
  }

  // Добавление пользователей
  async function addParticipiants(participiant) {
    if (participiant.user_id) {
      if (participiants.filter((el) => el.id === participiant.user_id).length === 0) {
        setParticipiants((state) => [...state, participiant]);
        const newUsers = data.users;
        newUsers.push(participiant.user_id);
        setData((state) => ({ ...state, users: newUsers }));
        setUsersBookingArray((state) => [...state, booking]);
        setSelectUsers({});
      }
    }
  }

  async function deleteParticipiants(usersId) {
    const user = participiants.find((el) => el.id === usersId);
    const filteredUsers = data.users.filter((el) => el !== user.id);
    setData((state) => ({ ...state, users: filteredUsers }));
    setParticipiants(participiants.filter((el) => el.id !== usersId));
  }

  const blocks = [{ title: 'A' }];

  return (
    <Card id="workplaces__new-booking-card" setShow={closeHandler} loading={isLoading}>
      <CardHeader />
      <CardBody>
        <CardSetting title="Бронирование места" />
        <CardSetting title="Дата и время начала:" invalid={!booking?.whole_day && !booking?.date_start}>
          <div className="datetime_container">
          <Input
            type="date"
            min={today}
            onChange={(event) => chooseDates({ date: event.target.value })}
          />
           <Select
             id="start_time"
             array={relatedData.time}
             onChoose={(e) => chooseDates({ date_start: e.title })}
             defaultValue={booking.date_start}
           />
          </div>
        </CardSetting>
        <CardSetting title="Дата и время окончания:" invalid={!booking?.whole_day && !booking?.date_start}>
          <div className="datetime_container">
          <Input
            type="date"
            min={today}
            onChange={(event) => chooseDates({ end_day: event.target.value })}
          />
           <Select
             id="end_time"
             array={relatedData.time}
             onChoose={(e) => chooseDates({ date_end: e.title })}
             defaultValue={booking.date_end}
           />
          </div>
        </CardSetting>
       {/* <CardSetting title="Дата и время окончания:">
          <Input
            type="datetime-local"
            onChange={(event) => chooseDates({ date_end: event.target.value.replace('T', ' ') })}
          />
        </CardSetting> */}
        <CardSetting title="Сотрудник:" invalid={!booking?.user_id}>
          <div style={{ width: '300px', display: 'flex' }}>
          <InputSearch
            id="helpdesk__new-ticket-search-initiator"
            array={relatedData.users}
            onSearch={(e) => searchWrapper(e)}
            onChoose={(user) => chooseUser(user)}
            placeholder="Выберите сотрудника"
           // defaultValue={booking.user_title}
            loading={isSearching}
            disabled={!booking?.date_start}
          //  onChoose={(user) => setSelectUsers(user)}
            defaultValue={selectUsers?.user_title}
          />
        {/*  <div onClick={() => (addParticipiants(selectUsers))} className="tags__button">
                        <img className="tags__img" src="../../icons/cancel.svg" alt="add-tag" />
          </div> */}
          </div>
        </CardSetting>

<CardSetting title="Блок:" invalid={!booking?.block_title}>
<Select
  id="workplaces__booking-choose-block"
  array={blocks}
  onChoose={chooseBlock}
  defaultValue={booking?.block_title}
  disabled={!booking?.user_id}
/>
</CardSetting>

        <CardSetting title="Зона:" invalid={!booking?.zone_id}>
          <Select
            id="workplaces__booking-choose-zone"
            array={relatedData.zones.filter((el) => el.block === booking.block_title)}
            onChoose={chooseZone}
            defaultValue={booking?.zone_title}
            disabled={!relatedData?.bookingPermission}
          />
        </CardSetting>
        <CardSetting title="Место:" invalid={!booking?.place_id}>
          <Select
            id="workplaces__booking-choose-place"
            array={relatedData.places.filter((place) => {
              if (booking?.zone_id) return place.zone_id === booking.zone_id;
              return true;
            })}
            onChoose={choosePlace}
            defaultValue={booking?.place_title}
            disabled={relatedData?.places.length === 0}
          />
        </CardSetting>
          <div style={{ display: 'flex', justifyContent: 'end', padding: '2px 8px' }}>
          <Button onClick={() => (addParticipiants(selectUsers))}>Добавить сотрудника</Button>
          </div>

        <div className="tags__list">
                    {participiants?.map((el) => <p className="tags__tag" key={el.id}>
                        <span onClick={() => deleteParticipiants(el.id)} className="upu__close">X</span>
                        {el.user_title}
                                                </p>)}
        </div>
      </CardBody>
      <CardFooter>
        <Button onClick={bookWorkplace}>Забронировать место</Button>
        <Button onClick={closeHandler}>Закрыть</Button>
      </CardFooter>
    </Card>
  );
}

export default NewBookingCard;
