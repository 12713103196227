import React, { useState, useEffect } from 'react';
import Table from '../../components/Table/Table';
import THead from '../../components/Table/THead';
import TRow from '../../components/Table/TRow';
import TData from '../../components/Table/TData';
import TBody from '../../components/Table/TBody';
import { catchHandler } from '../../utils/error_handling/error_handling';
import Input from '../../components/UI/Input';
import EditorButton from '../../components/UI/Button/EditorButton';
import TFoot from '../../components/Table/TFoot';
import CheckMark from '../../components/UI/CheckMark';
import Cancel from '../../components/UI/Cancel';
import { clientSendData } from '../../utils/functions/requests';
import Wrapper from '../../components/Basic/Wrapper';
import ServiceBody from '../../components/Service/ServiceBody';
import TextArea from '../../components/UI/TextArea';
import Select from '../../components/UI/Select';

function StandardSolutions() {
  const [standartSolutions, setStandartSolutions] = useState([]);
  const [SolutionsGroups, setSolutionsGroups] = useState([]);
  const [inputText, setInputText] = useState({});
  const [createSolution, setCreateSolution] = useState(false);
  const [newGroupName, setNewGroupName] = useState('');
  const [editingRow, setEditingRow] = useState(null);

  const headers = [
    { title: 'Заголовок', key: 'title' },
    { title: 'Сообщение', key: 'content' },
    { title: 'Группа', key: 'group_id' },
  ];

  function makeSolution() {
    setCreateSolution(true);
    setEditingRow(null);
  }

  function cancelCreating(e) {
    e.preventDefault(); // предотвращение действий формы по умолчанию
    setNewGroupName(''); // сброс имени нового направления
    setCreateSolution(false); // изменение состояния создания группы
  }

  function editing(solution) {
    setEditingRow(solution.id);
    setInputText({ title: solution.title, content: solution.content, group_id: solution.group_id });
  }

  function changeGroup(id, group) {
    const newSolutions = standartSolutions.map((el) => {
      if (el.id === id) {
        return { ...el, group_id: group.id, group_title: group.title };
      }
      return el;
    });
    setStandartSolutions(newSolutions);
    setInputText((state) => ({ ...state, group_id: group.id }));
  }

  useEffect(() => {
    getStandartSolutions();
  }, []);

  async function getStandartSolutions() {
    try {
      const reqData = {
        type: 'getStandartSolutions',
      };
      // setIsLoading(true); // загружается
      const result = await clientSendData('POST', '/get_standart_solutions', reqData); // запрос в БД action: useState

      if (result) {
        setStandartSolutions(result.data); // запись в переменную
        setSolutionsGroups(result.groups); // запись в переменную
      }
    } catch (error) {
      catchHandler(error, 'getDirections'); // обработчик ошибок
    } // finally { setIsLoading(false); }
  }

  async function editStandartSolutions(editAction, inputText, editId = null) {
    try {
      const reqData = {
        id: editId,
        action: editAction,
        type: 'editStandartSolutions',
        ...inputText,
      };
      const result = await clientSendData('POST', '/edit_standart_solutions', reqData); // запрос в БД
    } catch (error) {
      catchHandler(error, 'getDirections'); // обработчик ошибок
    } finally {
      setEditingRow(null);
      setCreateSolution(false);
      setInputText({});
      getStandartSolutions();
      // setIsLoading(false);
    }
  }

  return (
    <ServiceBody>
      <Table id="solutions">
        <THead headers={headers} title="Стандартные решения" />
        { /* Тело таблицы */}
        <TBody>
          {standartSolutions.map((solution) => (
            <TRow key={solution.id}>
              {editingRow === solution.id
                ? <>
                  <TData>
                    <Wrapper>
                      <Input
                        defaultValue={solution.title}
                        onChange={(e) => setInputText((text) => ({ ...text, title: e.target.value }))}
                      />
                    </Wrapper>
                  </TData>
                  <TData>
                    <Wrapper>
                      <TextArea
                        defaultValue={solution.content}
                        onChange={(e) => setInputText((text) => ({ ...text, content: e.target.value }))}
                      />
                    </Wrapper>
                  </TData>
                  <TData>
                    <Select
                      defaultValue={solution.group_title}
                      array={SolutionsGroups}
                      onChoose={(group) => changeGroup(solution.id, group)}
                    />
                    <CheckMark onClick={() => editStandartSolutions('update', inputText, solution.id, solution.group_title)} />
                    <Cancel onClick={() => setEditingRow(null)} />
                    <EditorButton onClick={() => editStandartSolutions('delete', {}, solution.id, solution.group_title)} icon="delete" />
                  </TData>
                  </>
                : <>
                    <TData>
                      <span onClick={() => editing(solution)} className="clickable">{solution.title}</span>
                    </TData>
                    <TData>
                      <span onClick={() => editing(solution)} className="clickable">{solution.content}</span>
                    </TData>
                    <TData>
                      <span onClick={() => editing(solution)} className="clickable">{solution.group_title}</span>
                    </TData>
                  </>}
            </TRow>
          ))}
        </TBody>
        { /* Футер */ }
          <TFoot>
            {createSolution ? (
              <TRow>
                  <TData>
                  <Wrapper>
                      <Input placeholder="Введите заголовок" onChange={(e) => setInputText((text) => ({ ...text, title: e.target.value }))} />
                      <TextArea placeholder="Введите сообщение" onChange={(e) => setInputText((text) => ({ ...text, content: e.target.value }))} />
                      <Select
                        defaultValue="Выберите группу"
                        array={SolutionsGroups}
                        onChoose={(group) => setInputText((text) => ({ ...text, group_id: group.id }))}
                      />
                      <CheckMark onClick={() => editStandartSolutions('create', inputText, null)} />
                      <Cancel onClick={cancelCreating} />
                  </Wrapper>
                  </TData>
              </TRow>
            ) : (
              <TRow>
                <TData onClick={makeSolution}>Добавить решение</TData>
              </TRow>
            )}
          </TFoot>
      </Table>
    </ServiceBody>
  );
}

export default StandardSolutions;
