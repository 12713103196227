import React, { useState } from 'react';
import ImageMapper from 'react-img-mapper';
import ServiceBody from '../../components/Service/ServiceBody';
import { blocks } from './data';
import './workplaces.scss';
import NewBookingCard from './Booking/BookingCard/NewBookingCard';

function Block(props) {
  const { block } = props;
  const [showBookingCard, setShowBookingCard] = useState(false);
  const [showAreas, setShowAreas] = useState(true);
  const [chosenArea, setChosenArea] = useState({ id: '', title: '' });
  const [hoveredAreaTitle, setHoveredAreaTitle] = useState('');

  const enterArea = (area, index, event) => {
    const currentZone = event.target;
    currentZone.title = area.title; // Устанавливаем title для вывода всплывающего сообщения
    setHoveredAreaTitle(area.title);
  };

  const clickArea = (area) => {
    setShowBookingCard(!showBookingCard);
    setShowAreas(!showAreas);
    setChosenArea({ id: area.id, title: area.title });
  };

  return (
    <ServiceBody>
    {showAreas && (<ImageMapper
      width={1300}
      src={`../../block_${block}.png`}
      map={blocks[block].map}
      onClick={clickArea}
      onMouseEnter={enterArea}
      alt={hoveredAreaTitle}
    />)}

      {showBookingCard && (
  <NewBookingCard
    setShowCard={setShowBookingCard}
    setShowAreas={setShowAreas}
    chosenArea={chosenArea}
  />
      )}
    </ServiceBody>

  );
}

export default Block;
