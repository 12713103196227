import React from 'react';
import { useSelector } from 'react-redux';
import { createAction } from '../../utils/redux/store';

/**
 * @component Уведомление
 Компонент лежит в хэдере приложения, управляется через redux хранилище
*/

function NoticeNew() {
  const isShowNotice = useSelector((state) => state.notice.show);
  const message = useSelector((state) => state.notice.message); // текст сообщения
  const type = useSelector((state) => state.notice.type); // тип сообщения (error/info)

  return (
    <div className={`notice-new notice-new_${isShowNotice ? type : 'hide'}`} onClick={() => createAction('HIDE_NOTICE')}>
      {message}
      <img src={`../../icons/notifications/${type}.svg`} alt={type} className="notice-new__img" />
    </div>
  );
}

export default NoticeNew;
