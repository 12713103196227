import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { catchHandler } from '../../../../utils/error_handling/error_handling';
import { clientSendData } from '../../../../utils/functions/requests';
import { convertTicketId, defaultTableState, headers } from '../../common';
import TData from '../../../../components/Table/TData';
import TRow from '../../../../components/Table/TRow';
import PaginatedTable from '../../../../components/Table/PaginatedTable/PaginatedTable';
import CardBody from '../../../../components/Card/CardBody';

/**
  * @component ХелпДеск - активы - по активам - карточка актива - вкладка "Заявки"
  * @prop {assetID} string - id актива
*/

function Tickets(props) {
  const { assetID } = props;
  const [assetTickets, setAssetTickets] = useState([]); // заявки связанные с активом
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [tableState, setTableState] = useState(defaultTableState.assets.asset_tickets); // состояние таблицы
  const [total, setTotal] = useState(0); // общее кол-во строк при текущих настройках
  // const [searchParams, setSearchParams] = useSearchParams(); // параметры поиска в url

  useEffect(() => {
    if (assetID) getTickets(); // запросить информацию по заявке
  }, [tableState]);

  // Получить заявки
  async function getTickets() {
    try {
      const reqData = {
        type: 'getTickets',
        tableState,
        total,
        asset_id: assetID,
      };
      setIsLoading(true);
      const result = await clientSendData('POST', '/get_tickets', reqData);
      if (result?.success) {
        setAssetTickets(result.tickets); // Результат записывается в состояние
        setTotal(result.total);
      }
    } catch (error) {
      catchHandler(error, 'getTickets');
    } finally {
      setIsLoading(false);
    }
  }

  // function openTicket(id) {
  //   setSearchParams({ ticket: convertTicketId(id) });
  // }

  if (assetTickets.length === 0) return <CardBody><h2 className="upu-service__title">Связанные запросы отсутствуют</h2></CardBody>;
  return (
    <CardBody>
      <PaginatedTable
        id="helpdesk__assset-tickets"
        state={tableState}
        setState={setTableState}
        headers={headers.assets.asset_tickets}
        total={total}
      >
        {assetTickets.map((ticket) => (
          <TRow key={ticket.id}>
            {headers.assets.asset_tickets.map((column) => {
              const { field, align, mobile_hide } = column;
              return (
              <TData key={field} align={align} loading={isLoading} mobileHide={mobile_hide}>
                {(() => {
                  switch (field) {
                    case 'id': return `#${convertTicketId(ticket[field])}`;
                    case 'title': return (
                      <Link className="clickable" to={`?ticket=${convertTicketId(ticket.id)}`}>{ticket[field]}</Link>
                    );
                    case 'performer': return (
                      <Link className="clickable" to={`?user=${ticket.performer_id}`}>{ticket[field]}</Link>
                    );
                    default: return ticket[field];
                  }
                })()}
              </TData>);
            })}
          </TRow>
        ))}
      </PaginatedTable>
    </CardBody>
  );
}

export default Tickets;
