import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { setOperInfo } from '../../../../utils/functions/others';
import Card from '../../../../components/Card/Card';
import CardHeader from '../../../../components/Card/CardHeader';
import TabPanel from '../../../../components/UI/TabPanel';
import History from './History';
import Main from './Main/Main';
import Tickets from './Tickets';

/**
  * @component ХелпДеск - активы - карточка актива
  * @prop {setShowCard} function - обновление состояния отображения карточки (открыть/закрыть)
  * @prop {refreshTable} function - обновление данных таблицы
*/
function AssetCard(props) {
  const { setShowCard, refreshTable } = props;

  const [searchParams, setSearchParams] = useSearchParams(); // параметры поиска из url
  const [assetID, setAssetID] = useState(null); // id открытой карточки
  const tabs = [
    'Главная',
    assetID !== 'new' && 'Запросы', // не отображается в карточке создания актива
    assetID !== 'new' && 'История', // не отображается в карточке создания актива
  ]; // вкладки
  const [choice, setChoice] = useState(tabs[0]); // состояние выбора вкладки
  const content = { // Компоненты ля вкладок
    Главная: <Main
      assetID={assetID}
      refreshTable={refreshTable}
      closeHandler={closeHandler}
    />,
    История: <History type="2" subject_id={assetID} />,
    Запросы: <Tickets assetID={assetID} />,
  };

  useEffect(() => {
    const assetId = searchParams?.get('asset'); // взять id заявки из url
    if (assetId) { // если id есть
      if (Number(assetId) || assetId === 'new') {
        setAssetID(assetId); // обновить состояние текущего ID актива
        setOperInfo({ subject_id: assetId }); // записываем id актива в оперативную информацию
      } else closeHandler();
    }
  }, [searchParams]);

  // закрыть карточку
  function closeHandler() {
    setOperInfo({ subject_id: null }); // сброс id категории в оперативной информацию
    setSearchParams(''); // сброс параметром url
    setShowCard(false); // закрытие карточки
  }

  // обработка выбора вкладки
  function choiceHandler(tab) {
    setChoice(tab); // установить выбор
  }

  return (
    <Card id="helpdesk__asset-card" setShow={closeHandler}>
      <CardHeader>
        <TabPanel tabs={tabs} choice={choice} choiceHandler={choiceHandler} />
      </CardHeader>
      {content?.[choice]}
    </Card>
  );
}

export default AssetCard;
