import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { clientSendData } from '../../../utils/functions/requests';
import { catchHandler } from '../../../utils/error_handling/error_handling';
import { defaultTableState, headers } from '../common';
import TRow from '../../../components/Table/TRow';
import TData from '../../../components/Table/TData';
import UserCard from './UserCard/UserCard';
import AssetCard from './AssetCard/AssetCard';
import PaginatedTable from '../../../components/Table/PaginatedTable/PaginatedTable';
import ServiceBody from '../../../components/Service/ServiceBody';

/**
* @component ХелпДеск - Интерфейс активов
*/
function Assets() {
  const tableId = 'helpdesk__assets';
  const [searchParams, setSearchParams] = useSearchParams(); // параметры поиска в url
  const [total, setTotal] = useState(0);
  const [assets, setAssets] = useState([]); // отображаемые активы
  const [tableState, setTableState] = useState(JSON.parse(localStorage.getItem(tableId)) || defaultTableState.assets.main);
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [showAssetCard, setShowAssetCard] = useState(false); // состояние отображения карточки актива
  const [showUserCard, setShowUserCard] = useState(false); // состояние отображения карточки пользователя
  const [filter, setFilter] = useState([]);
  const footerButton = tableState.switch_data.current === 'asset' ? {
    title: 'Добавить актив',
    handler: () => openCard('new'),
  } : null;

  useEffect(() => {
    localStorage.setItem(tableId, JSON.stringify(tableState));
    getAssets();
  }, [tableState]);

  useEffect(() => { // При закрытии карточки пользователя обновить данные таблицы
    if (!showUserCard) getAssets();
  }, [showUserCard]);

  useEffect(() => { // При закрытии карточки актива обновить данные таблицы
    if (!showAssetCard) getAssets();
  }, [showAssetCard]);

  useEffect(() => {
    // проверка параметров url при их изменении (для открытия карточки)
    if (searchParams.size > 0) { // если есть какие то параметры
      // если url содержит параметр поиска "asset" открывается карточка актива
      if (searchParams?.get('asset')) {
        // если была открыта карточка пользователя - она закрывается во избежание наложения
        if (showUserCard) setShowUserCard(false);
        setShowAssetCard(true);
      }
      // если url содержит параметр поиска "user" открывается карточка пользователя
      if (searchParams?.get('user')) {
        // если была открыта карточка актива - она закрывается во избежание наложения
        if (showAssetCard) setShowAssetCard(false);
        setShowUserCard(true);
      }
      // else setSearchParams(''); // иначе это другие параметры - сбрасываем их
    }
  }, [searchParams]);

  // Получить заявки
  async function getAssets() {
    try {
      const reqData = {
        type: 'getAssets',
        tableState,
        total,
      };
      setIsLoading(true);
      const result = await clientSendData('POST', '/get_assets', reqData);
      if (result?.success) {
        setAssets(result.data); // Результат записывается в состояние
        setTotal(result.total);
        setFilter(result.filter);
      }
    } catch (error) {
      catchHandler(error, 'getAssets');
    } finally {
      setIsLoading(false);
    }
  }

  // Открыть карточку
  function openCard(id) {
    // установить в параметры поиска id актива или пользователя
    setSearchParams({ [tableState.switch_data.current]: id });
  }

  return (
    <ServiceBody>
      <PaginatedTable
        id={`${tableId}_${tableState.switch_data.current}`}
        state={tableState}
        setState={setTableState}
        headers={headers.assets.main[tableState.switch_data.current]}
        total={total}
        footerButton={footerButton}
        filterFields={filter}
        filterFieldsFunc={setFilter}
      >
        {assets.map((asset) => (
          <TRow key={asset.id}>
            {headers.assets.main[tableState.switch_data.current].map((column) => {
              const { field, align, mobile_hide } = column;
              return (
              <TData key={field} align={align} loading={isLoading} mobileHide={mobile_hide}>
                {(() => {
                  switch (field) {
                    case 'title': return (
                      <Link className="clickable" to={`?asset=${asset.id}`}>{asset[field]}</Link>
                    );
                    case 'user': return (
                      <Link className="clickable" to={`?user=${asset.user_id}`}>{asset[field]}</Link>
                    );
                    case 'name': return (
                      <Link className="clickable" to={`?user=${asset.id}`}>{asset[field]}</Link>
                    );
                    default: return asset[field];
                  }
                })()}
              </TData>);
            })}
          </TRow>
        ))}
      </PaginatedTable>

      {showUserCard && (
      <UserCard
        setShowCard={setShowUserCard}
        refreshTable={() => setTableState({ ...tableState })}
      />
      )}
      {showAssetCard && (
      <AssetCard
        setShowCard={setShowAssetCard}
        refreshTable={() => setTableState({ ...tableState })}
      />
      )}
    </ServiceBody>
  );
}

export default Assets;
