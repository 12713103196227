import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { deepObjectsComparing, setOperInfo } from '../../../../utils/functions/others';
import Card from '../../../../components/Card/Card';
import CardHeader from '../../../../components/Card/CardHeader';
import TabPanel from '../../../../components/UI/TabPanel';
import CardBody from '../../../../components/Card/CardBody';
import CardFooter from '../../../../components/Card/CardFooter';
import Button from '../../../../components/UI/Button/Button';
import { clientSendData } from '../../../../utils/functions/requests';
import { catchHandler } from '../../../../utils/error_handling/error_handling';
import Settings from './Settings';
import Logs from './Logs';
import { alertFunction } from '../../../../utils/functions/alertFunction';

// Система Оценок - интерфейс сотрудника - карточка заявления
function ApplicationCard(props) {
  const {
    id, // id заявления
    projectId, // id проекта (если есть, значит карточка открывается из раздела "Мои данные" сотрудника)
    setShowCard, // функция изменения отображения карточки
    findApplications, // функция запроса заявления (для обновления после изменений)
  } = props; // свойства передаваемые компоненту при вызове

  const operInfo = useSelector((state) => state.oper_info); // id пользователя из сессии
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [choice, setChoice] = useState(''); // состояние выбора вкладки
  const [applicationDefault, setApplicationDefault] = useState({}); // текущее заявление
  const [application, setApplication] = useState({}); // текущее заявление
  const isNewApp = id === 'new'; // это новое заявление?
  const tabs = ['Настройки', !isNewApp && 'История']; // вкладки
  const isChanged = !deepObjectsComparing(applicationDefault, application); // показатель изменения исходного объекта
  const isFromProject = projectId !== undefined; // параметр открытия из раздела "Мои данные"

  // Параметр деактивации кнопки создания нового заявления
  // Все нижеперечисленные поля должны присутствовать в объекте нового заявления
  const isCreateDisabled = Boolean(
    !application?.date_start
        || !application?.date_end
        || !application?.client_id
        || !application?.approver_id
        || !application?.project_id
        || !application?.contract_id,
  );

  // Параметр деактивации кнопки изменении заявления
  const isEditDisabled = Boolean( // кнопка неактивна
    !isChanged // если не было изменений в объекте
        || !application?.date_start // нет даты начала
        || !application?.date_end // нет даты завершения
        || !application?.approver_id, // нет id руководителя
  );

  useEffect(() => {
    handleApplicationId();
  }, []);

  // Закрыть карточку
  function cancelAndClose() {
    setShowCard(false);
    setOperInfo({ subject_id: null });
  }

  // обработка выбора вкладки
  function choiceHandler(tab) {
    setChoice(tab); // установить выбор
  }

  // Обработать id заявления
  async function handleApplicationId() {
    setOperInfo({ subject_id: id }); // записать в оперативную информацию id
    // Если id не "new" значит октрывается существующее заявление
    if (id !== 'new') {
      setIsLoading(true);
      await getApplication(); // запросить данные заявления
      setIsLoading(false);
      // Иначе заявление новое - добавить id в объект
    } else if (projectId) {
      setIsLoading(true);
      await getDataByProject(); // запросить данные по проекту
      setIsLoading(false);
    } else {
      setApplication({ id });
    }
  }

  // Получение информации о заявлении
  async function getApplication() {
    try {
      const reqData = {
        type: 'getApplication',
        application_id: id,
      };
      const result = await clientSendData('POST', '/get_application', reqData); // запрос в БД
      if (result) {
        setApplication(result);
        setApplicationDefault(result);
      } else setApplication(null);
    } catch (error) {
      catchHandler(error, 'getApplication'); // обработка ошибок
    }
  }

  // Получение данных по id проекта для создания заявления
  async function getDataByProject() {
    try {
      const reqData = {
        type: 'getDataByProject',
        project_id: projectId,
      };
      const result = await clientSendData('POST', '/get_data_by_project', reqData); // запрос в БД
      if (result) {
        setApplication({ id: 'new', ...result });
        setApplicationDefault({ id: 'new', ...result });
      } else setApplication(null);
    } catch (error) {
      catchHandler(error, 'getDataByProject'); // обработка ошибок
    }
  }

  // Создать новое заявление
  async function createNewApplication() {
    try {
      const now = new Date();
      const timestamp = new Date(now.setHours(now.getHours() + 3)).toISOString();
      const userId = operInfo.user_id;
      const {
        project_id, contract_id, client_id, approver_id, date_start, date_end,
      } = application;
      const application_object = {
        project_id,
        contract_id,
        client_id,
        approver_id,
        date_start,
        date_end,
        user_id: userId,
        changes_id: userId,
        status_id: 1,
        draft: true,
        version: 1,
        created_at: timestamp,
      };

      const reqData = {
        type: 'createNewApplication',
        url: operInfo?.url,
        alias: operInfo?.alias,
        application: application_object,
      };
      setIsLoading(true);
      const result = await clientSendData('POST', '/create_new_application', reqData); // запрос в БД
      // Если не удалось найти утверждающего для 2 этапа согласования - уведолмение
      if (result?.main_approver === false) {
        await alertFunction('no_main_approver', 'clientPost');
      } else {
        await findApplications(); // обновить таблицу
        cancelAndClose(); // закрыть карточку
      }
      setIsLoading(false);
    } catch (error) {
      catchHandler(error, 'createNewApplication'); // обработка ошибок
    }
  }

  // Сохранить измененное заявление
  async function editApplication() {
    try {
      const reqData = {
        type: 'editApplication',
        application_id: application.id,
        date_start: application.date_start,
        date_end: application.date_end,
        approver_id: application.approver_id,
        alias: operInfo?.alias,
      };
      setIsLoading(true);
      await clientSendData('POST', '/edit_application', reqData); // запрос в БД
      setIsLoading(false);
      await findApplications(); // обновить таблицу
      cancelAndClose(); // закрыть карточку
    } catch (error) {
      catchHandler(error, 'editApplication'); // обработка ошибок
    }
  }

  // Удалить заявление
  async function deleteApplication() {
    try {
      const confirm = window.confirm('Вы уверены что хотите удалить?');
      if (confirm) {
        const reqData = {
          type: 'deleteApplication',
          application_id: id,
          alias: operInfo?.alias,
        };
        setIsLoading(true);
        await clientSendData('POST', '/delete_application', reqData); // запрос в БД
        setIsLoading(false);
        await findApplications(); // обновить таблицу
        cancelAndClose(); // закрыть карточку
      }
    } catch (error) {
      catchHandler(error, 'deleteApplication'); // обработка ошибок
    }
  }

  return (
    <Card id="evaluation__application-card" setShow={cancelAndClose} loading={isLoading}>
        <CardHeader>
            <TabPanel tabs={tabs} choice={choice} choiceHandler={choiceHandler} />
        </CardHeader>

        <CardBody>
            {/* Вкладка Настройки */}
            {choice === 'Настройки' && (
            <Settings
              isFromProject={isFromProject}
              object={application}
              setObject={setApplication}
              deleteApplication={deleteApplication}
            />)}
            {/* Вкладка Истории */}
            {choice === 'История' && <Logs id={id} />}
        </CardBody>

        <CardFooter>
            <Button onClick={cancelAndClose}>Закрыть</Button>

            {isNewApp // Если заявление создаётся - кнопка "Создать"
              ? <Button onClick={createNewApplication} disabled={isCreateDisabled}>Создать</Button>
              : application // Иначе если есть возможность редактирования - кнопка "Сохранить"
                ? <Button onClick={editApplication} disabled={isEditDisabled}>Сохранить</Button>
                // Иначе ничего
                : null}
        </CardFooter>
    </Card>
  );
}

export default ApplicationCard;
