import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { clientSendData } from '../../utils/functions/requests';
import { catchHandler } from '../../utils/error_handling/error_handling';
import CardBody from '../../components/Card/CardBody';
import ServiceBody from '../../components/Service/ServiceBody';
import Wrapper from '../../components/Basic/Wrapper';
import Button from '../../components/UI/Button/Button';
import { alertFunction } from '../../utils/functions/alertFunction';

function Estimation() {
  const { id } = useParams(); // параметр ссылки
  const [initiatorId, setInitiatorId] = useState();
  const [marking, setMarking] = useState({ mark: '', comment: '' });
  const [ticketNumber, setTicketNumber] = useState('');
  const [ticketId, setTicketId] = useState('');
  const [ticketEstimation, setTicketEstimation] = useState(null);
  const [isShown, setIsShown] = useState(true);
  // ССЫЛКА ДЛЯ ПОДСТАВКИ В ПИСЬМО
  // <a href='https://upu.unicon.ru/estimation/${ticket_id}'>ссылка<a></a>
  useEffect(() => {
    // Здесь происходит проверка на наличие id
    if (!id) console.log('todo');// TODO Выбрасывать ошибку
    else {
      getTicketEstimation(id);
    }
  }, []);

  async function getTicketEstimation(ticket_id) {
    try {
      const reqData = {
        type: 'getTicketEstimation',
        id: ticket_id,
      };
      const result = await clientSendData('POST', '/get_ticket_estimation', reqData);
      if (result?.success) {
        console.log(result);
        setInitiatorId(result.initiator_id);
        setTicketId(result.request_id);
        setTicketNumber(result.ticket_number);
        setTicketEstimation(result.estimation);
      //  console.log(estimationTicket);
      }
    } catch (error) {
      catchHandler(error, 'getTicketEstimation');
    }
  }

  async function saveEstimation(ticket_id, user_id, mark, comment) {
    try {
      const reqData = {
        ticketId: ticket_id,
        userId: user_id,
        marking: mark,
        commentText: comment,
        type: 'saveEstimation',
      };
      if (marking.mark !== '') {
        const result = await clientSendData('POST', '/save_estimation', reqData);
        if (result) {
          await alertFunction('thank_for_interview', 'client');
          setIsShown(false);
        }
      } else { await alertFunction('required_fields', 'client'); }
    } catch (error) {
      catchHandler(error, 'editEmailTemplate'); // обработчик ошибок
    }// finally { getTicketEstimation(); }
  }

  return (
    initiatorId !== null && ticketNumber !== null && ticketEstimation === null && isShown ? <div>
<ServiceBody id="estimation">
    <div className="markingTitle">
    Оцените, пожалуйста, работу по заявке №
    {ticketNumber}
    </div>
      <div className="estimForm">
      <div className="markValue">
Ваша оценка:
{marking.mark}
      </div>
      <Wrapper className="values">
      <div onClick={() => setMarking({ ...marking, mark: '1' })} className="smiley">&#128546;</div>
      <div onClick={() => setMarking({ ...marking, mark: '2' })} className="smiley">&#128530;</div>
      <div onClick={() => setMarking({ ...marking, mark: '3' })} className="smiley">&#128528;</div>
      <div onClick={() => setMarking({ ...marking, mark: '4' })} className="smiley">&#128578;</div>
      <div onClick={() => setMarking({ ...marking, mark: '5' })} className="smiley">&#129321;</div>
      </Wrapper>
      <textarea placeholder="Оставьте здесь Ваш комментарий! Он будет для нас очень ценным ✍️" className="formComment" onChange={(e) => setMarking({ ...marking, comment: e.target.value })} />
     <Button onClick={() => saveEstimation(ticketId, initiatorId, marking.mark, marking.comment)}>Сохранить ответ</Button>
      </div>
</ServiceBody>

                                                                                            </div> : <div className="noEsteems">Спасибо за Вашу оценку &#128522;</div>

  );
}

export default Estimation;
