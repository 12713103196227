import React, { useState } from 'react';
import { clearHtmlString } from '../../../utils/functions/stringHandling';
import { alertFunction } from '../../../utils/functions/alertFunction';
import Card from '../../../components/Card/Card';
import CardHeader from '../../../components/Card/CardHeader';
import CardBody from '../../../components/Card/CardBody';
import CardFooter from '../../../components/Card/CardFooter';
import CardSetting from '../../../components/Card/CardSetting';
import TextProcessor from '../../../components/TextProcessor/TextProcessor';
import IconButton from '../../../components/UI/Button/IconButton';
import Button from '../../../components/UI/Button/Button';
import Input from '../../../components/UI/Input';

/**
* @component Состояние портала - интерфейс администратора - Статусы - карточка статуса
* @prop {status} object - статус, открытый в карточке
* @prop {setStatus} function - обновление состояния статуса, открытого в карточке
* @prop {handler} function - обработчик действий со статусами (создание/изменение/удаление)
* @prop {setShow} function - обновление состояния отображения карточки (открыть/закрыть)
*/
function StatusCard(props) {
  const {
    status, setStatus, handler, setShow,
  } = props;

  const [isTPOpen, setIsTPOpen] = useState(false); // состояние отображения текстового процессора
  const isNewStatus = status.id === 'new';
  const closeTP = () => setIsTPOpen(false); // закрытие текстового процессора

  // Сохранить объект статуса
  async function saveStatus() {
    // Если указан заголовок и описание
    if (['title', 'color', 'message'].every((key) => status[key])) {
      // Определяем действие на основании id статуса (создание или изменение)
      const action = isNewStatus ? 'create' : 'edit';
      // Дожидаемся обработки запроса в БД
      await handler(action, status);
      // Закрываем карточку
      setShow(false);
      // Если не указан заголовок или описание - уведомление
    } else await alertFunction('required_fields', 'clientPost');
  }

  function checkIsTPOpen() {
    if (isTPOpen) {
      const confirm = window.confirm('Изменения в текстовом процессоре будут утеряны. Продолжить?');
      if (confirm) saveStatus();
    } else saveStatus();
  }

  // Удалить статус
  async function deleteStatus() {
    // Запросить подтверждение
    const confirm = window.confirm('Удалить?');
    // Если подтверждено - удалить из БД
    if (confirm) {
      // Дожидаемся обработки запроса в БД
      await handler('delete', status);
      // Закрываем карточку
      setShow(false);
    }
  }

  // Изменить html содержание уведомления
  function changeContent(content) {
    const checkedContent = clearHtmlString(content).trim() ? content : null;
    setStatus((prev) => ({ ...prev, message: checkedContent }));
    closeTP(); // закрыть редактор
  }

  return (
    <Card id="portal-state__status-card" setShow={setShow}>
      <CardHeader />
      <CardBody>

        {/* НАИМЕНОВАНИЕ СТАТУСА */}
        <CardSetting title="Наименование" invalid={!status?.title}>
          <Input
            placeholder="Наименование"
            value={status?.title || ''}
            onChange={(e) => setStatus((prev) => ({ ...prev, title: e.target.value }))}
          />
        </CardSetting>

        {/* ВЫБОР ЦВЕТА */}
        <CardSetting title="Цвет заливки" invalid={!status?.color}>
          <Input
            type="color"
            value={status?.color}
            onChange={(e) => setStatus((prev) => ({ ...prev, color: e.target.value }))}
          />
        </CardSetting>

        {/* КОНТЕНТ */}
        <CardSetting title="Текст уведомления" invalid={!status?.message}>
          {isTPOpen ? (
          <TextProcessor
            handler={changeContent}
            content={status.message || ''}
            cancel={closeTP}
          />) : (
          <span onClick={() => setIsTPOpen(true)} className="clickable">
            {status?.message ? clearHtmlString(status.message) : 'Открыть редактор'}
          </span>)}
        </CardSetting>

        {/* УДАЛЕНИЕ */}
        {!isNewStatus && (
        <CardSetting title="Удалить статус" invalid={!status?.color}>
          <IconButton icon="delete" theme="red" onClick={deleteStatus} />
        </CardSetting>)}
      </CardBody>

      <CardFooter>
        <Button onClick={checkIsTPOpen}>Сохранить</Button>
        <Button onClick={() => setShow(false)}>Отменить</Button>
      </CardFooter>
    </Card>
  );
}

export default StatusCard;
