import React, { useEffect, useState } from 'react';
import { catchHandler } from '../../utils/error_handling/error_handling';
import { clientSendData } from '../../utils/functions/requests';
import { downloadDataUrl } from '../../utils/functions/others';
import { alertFunction } from '../../utils/functions/alertFunction';
import CardFooter from '../../components/Card/CardFooter';
import CardHeader from '../../components/Card/CardHeader';
import Button from '../../components/UI/Button/Button';
import CardBody from '../../components/Card/CardBody';
import Table from '../../components/Table/Table';
import THead from '../../components/Table/THead';
import TBody from '../../components/Table/TBody';
import TData from '../../components/Table/TData';
import TRow from '../../components/Table/TRow';
import Card from '../../components/Card/Card';

/**
* @component Инструкции - карточка инструкции
* @prop {id} number - id инструкции из upu_instructions
* @prop {setShow} function - функция обновления состояния отображения карточки (открыть/закрыть)
* @prop {getInstructions} function - получение инструкций из БД (для обновления таблицы)
*/
function InstructionCard(props) {
  const { id, setShow, getInstructions } = props;

  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [history, setHistory] = useState([]); // контроли
  const headers = [
    { id: 1, title: 'Ссылка', field: 'link' },
    { id: 2, title: 'Кто', field: 'created_by' },
    { id: 3, title: 'Когда', field: 'created_at' },
  ];

  useEffect(() => {
    getInstructionHistory();
  }, []);

  // Получение истории инструкции
  async function getInstructionHistory() {
    try {
      const reqData = {
        type: 'getInstructionHistory',
        instruction_id: id, // id
      };

      setIsLoading(true);
      const result = await clientSendData('POST', '/get_instruction_history', reqData);
      if (result) setHistory(result);
      setIsLoading(false);
    } catch (error) {
      catchHandler(error, 'getInstructionHistory');
    }
  }

  // Скачать инструкцию
  function downloadInstruction(file, name) {
    try {
      const reader = new FileReader(); // API чтения файлов
      const resBlob = new Blob([new Uint8Array(file)]);
      reader.onloadend = ((e) => downloadDataUrl(e.target.result, `Инструкция ${name}`));
      reader.readAsDataURL(resBlob);
    } catch (error) {
      catchHandler(error, 'uploadInstruction');
    }
  }

  // Удалить инструкцию
  async function deleteInstruction(instructionId) {
    try {
      const confirm = window.confirm('Удалить?');
      if (confirm) {
        const reqData = {
          type: 'deleteInstruction',
          instruction_id: instructionId,
        };
        setIsLoading(true); // загрузка началась
        const result = await clientSendData('DELETE', '/delete_instruction', reqData);
        if (result === 'success') {
          await getInstructions();
          await alertFunction('save_settings', 'clientPost');
          setShow(false);
        }
        setIsLoading(false); // загрузка завершена
      }
    } catch (error) {
      catchHandler(error, 'deleteInstruction');
    }
  }

  return (
    <Card id="instructions__card" setShow={setShow} loading={isLoading}>
      <CardHeader />
      <CardBody>
        <Table id="instructions__history-table">
          <THead title="История">
            <TRow>
              {headers.map((item) => <TData key={item.id}>{item.title}</TData>)}
            </TRow>
          </THead>
          <TBody>
            {history.map((row) => (
              <TRow key={row.id}>
                {headers.map((item) => {
                  const { field } = item;
                  const value = row[field];
                  if (field !== 'link') return <TData key={item.id} loading={isLoading}>{value}</TData>;
                  return (
                    <TData key={item.id} loading={isLoading}>
                      <span className="clickable" onClick={() => downloadInstruction(row.file.data, row.link)}>
                        {value}
                      </span>
                    </TData>
                  );
                })}
              </TRow>
            ))}
          </TBody>
        </Table>
      </CardBody>
      <CardFooter>
        <Button onClick={() => deleteInstruction(id)}>Удалить</Button>
        <Button onClick={() => setShow(false)}>Закрыть</Button>
      </CardFooter>
    </Card>
  );
}

export default InstructionCard;
