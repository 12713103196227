import React, { useEffect, useState } from 'react';
import { catchHandler } from '../../utils/error_handling/error_handling';
import { authorization } from '../../utils/functions/authenticate';
import { setOperInfo } from '../../utils/functions/others';
import { clientSendData } from '../../utils/functions/requests';
import Service from '../../components/Service/Service';
import ServiceBody from '../../components/Service/ServiceBody';
import Table from '../../components/Table/Table';
import TBody from '../../components/Table/TBody';
import TData from '../../components/Table/TData';
import TFoot from '../../components/Table/TFoot';
import THead from '../../components/Table/THead';
import TRow from '../../components/Table/TRow';
import Loader from '../../components/UI/Loader';
import TaskCard from './TaskCard/TaskCard';
import Cap from '../Cap/Cap';
import './tasks.scss';

/**
* @component Система контроля задач
* Список всех задач с возможность редактирования
*/
function Tasks() {
  const [isAuthorized, setIsAuthorized] = useState(false); // Состояние авторизации
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [showCard, setShowCard] = useState(false); // состояние отображения карточки
  const [currentTaskId, setCurrentTaskId] = useState(null); // id открываемой анкеты
  const [choosedRow, setChoosedRow] = useState(null); // выбранная строка таблицы
  const [tasks, setTasks] = useState([]); // все задачи
  const headers = [ // Заголовки полей с наименованиями
    { title: 'Пользователь', field: 'user' },
    { title: 'Заголовок', field: 'short_title' },
    { title: 'Источник', field: 'source' },
    { title: 'Статус', field: 'status_title' },
  ];

  useEffect(() => {
    setOperInfo();
    awaitRequests();
  }, []);

  async function awaitRequests() {
    setIsLoading(true);
    const checkAuthorization = await authorization(); // авторизация
    setIsAuthorized(checkAuthorization);
    if (checkAuthorization) await getTasks();
    setIsLoading(false);
  }

  async function getTasks() {
    try {
      const reqData = {
        type: 'getTasks',
      };
      const result = await clientSendData('POST', '/get_tasks', reqData);
      if (result) setTasks(result); // Результат записывается в состояние
    } catch (error) {
      catchHandler(error, 'getTasks');
    }
  }

  function openTask(id) {
    setCurrentTaskId(id); // обновить состояние id
    setShowCard(true); // открыть карточку
  }

  if (isLoading) return <Loader />;
  if (isAuthorized) { // если авторизован
    return (
      <Service id="tasks">
        <ServiceBody>
          <Table id="tasks__table" short_last>
            <THead
              title="Все задачи"
              headers={headers}
              array={tasks}
              setArray={setTasks}
              button_place="row"
            />
            <TBody>
              {tasks.map((task) => {
                const { id } = task;
                return (
                  <TRow key={id} onClick={() => setChoosedRow(id)} active={choosedRow === id}>
                    {headers.map((item) => (
                      <TData key={item.field}>
                        {task[item.field]}
                      </TData>
                    ))}
                    <TData onClick={() => openTask(id)}>
                      <img src="../../icons/file-text.svg" alt="file" />
                    </TData>
                  </TRow>
                );
              })}
            </TBody>
            <TFoot>
              <TRow>
                <TData onClick={() => openTask('new')}>Создать задачу</TData>
              </TRow>
            </TFoot>
          </Table>
        {showCard && ( // карточка задачи (покажется, если showCard = true)
        <TaskCard
          id={currentTaskId}
          getTasks={getTasks}
          setShow={setShowCard}
        />)}
        </ServiceBody>
      </Service>
    );
  } return <Cap />;
}
export default Tasks;
