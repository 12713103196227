/**
* @function Разделение периода времени по интервалам
* @prop {interval} string - day/month/year
* @prop {start} string - YYYY-MM-DD
* @prop {end} string - YYYY-MM-DD
*/
export function getPeriodByInterval(interval, start, end) {
  const today = new Date().toLocaleString('sv').split(' ')[0]; // текущая дата в формате 'YYYY-MM-DD'
  if (!interval) return [today]; // если не указан интервал - вернет today ['YYYY-MM-DD']
  if (interval === 'year') return [today]; // в разработке
  if (interval === 'day') return [today]; // в разработке
  if (interval === 'month') {
    if (start && end) { // если есть даты
      const startDate = new Date(new Date(start).setDate(1)); // установить первое число песяца
      const endDate = new Date(new Date(end).setDate(1)); // установить первое число песяца

      // Разница в месяцах + разница в годах * 12
      const difference = endDate.getMonth() - startDate.getMonth() + (12 * (endDate.getFullYear() - startDate.getFullYear()));
      const result = [];
      // Проходим по разнице месяцев
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i <= difference; i++) {
        const startCopy = new Date(startDate); // копия исходной даты старта для предотвращения ее изменения
        if (i < difference) { // если итерация не последняя
          const addMonthToStart = new Date(startCopy.setMonth(startCopy.getMonth() + i)); // добавить количество месяцев
          const formatDate = addMonthToStart.toLocaleString('sv').split(' ')[0];
          result.push(formatDate); // Добавить результат в массив
        } else { // иначе итерация последняя
          result.push(endDate.toLocaleString('sv').split(' ')[0]); // Добавить дату окончания в массив
        }
      }
      return result; // вернуть массив месяцев
    } return [today];
  }
  return [today]; // иначе вернет today ['YYYY-MM-DD']
}

/**
* @function Получение наименования месяца и года из даты
* @prop {month} string - YYYY-MM-DD
*/
export function getMonthName(month) {
  const monthTitle = new Date(month).toLocaleString('default', { month: 'long' });
  const year = new Date(month).getFullYear();
  const result = `${monthTitle} ${year}`;
  return result;
}

/**
* @function Создание массива месяцев из периода отчёта
* @prop {array} array - массив отчёта
* @prop {field} string - наименование колокни со значением даты
*/
export function createPeriod(array, field) {
  const datesArray = array.map((d) => { // Оставить в отчёте только даты
    if (d[field]) return new Date(d[field]); // если есть значение даты - вернуть его
    return null; // иначе вернуть null
  });
  const filterDates = datesArray.filter((date) => date); // Убрать null из массива
  const minDate = new Date(Math.min(...filterDates)).toLocaleString('sv').split(' ')[0]; // минимальная дата YYYY-MM-DD
  const maxDate = new Date(Math.max(...filterDates)).toLocaleString('sv').split(' ')[0]; // максимальная дата YYYY-MM-DD

  const months = getPeriodByInterval('month', minDate, maxDate); // период по месяцам между датами
  return months; // вернуть массив месяцев
}

/**
* @function Подсчёт стажа
* @prop {start} string - дата начала
* @prop {end} string - дата завершения
*/
export function countExperience(start, end) {
  const startDate = new Date(start);
  const endDate = new Date(end);
  // Разница в месяцах + разница в годах * 12
  const difference = endDate.getMonth() - startDate.getMonth() + (12 * (endDate.getFullYear() - startDate.getFullYear()));
  const result = Math.floor((difference / 12) * 10) / 10;
  return result;
}

/**
* @function Изменение разделительного знака числа для корректной обработки в excel
* @prop {number} number - число
*/
export function formatNumberToExcel(number) {
  return number.toString().replace('.', ',');
}

/**
* @function Проверка длины массива (чтобы убрать нули из отчёта)
* @prop {array} array - массив
*/
export const checkLength = (array) => {
  const { length } = array;
  return length > 0 ? length : '';
};
