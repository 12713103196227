import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useSearchParams } from 'react-router-dom';
import { catchHandler } from '../../utils/error_handling/error_handling';
import { alertFunction } from '../../utils/functions/alertFunction';
import { clientSendData } from '../../utils/functions/requests';
import Loader from '../../components/UI/Loader';
import Division from '../../components/UI/Division';
import UserTaskCard from './TaskCard/UserTaskCard';

/**
* @component Задачи пользователя
* Список карточек задач пользователя
*/
function UserTasks() {
  const [searchParams, setSearchParams] = useSearchParams(); // параметры поиска url
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [userTasks, setUserTasks] = useState([]); // задачи пользователя
  const [showCard, setShowCard] = useState(false); // открыть/закрыть карточку
  const main = document.querySelector('.main');

  useEffect(() => {
    awaitRequests();
    // eslint-disable-next-line
  }, [])

  async function awaitRequests() {
    setIsLoading(true); // состояние - загружается
    await checkSearchParams();
    await getUserTasks();
    setIsLoading(false); // загрузка завершена
  }

  // Проверка наличия параметров поиска в url
  async function checkSearchParams() {
    const taskId = searchParams?.get('task');
    // если url содержит параметр поиска "task" открывается карточка задачи
    if (taskId) {
      // Если id из url число - показать карточку
      if (+taskId) setShowCard(true);
      // иначе показать уведомление
      else await alertFunction('incorrect_task_id', 'clientPost');
    }
  }

  // Получение задач пользователя
  async function getUserTasks() {
    try {
      const reqData = { type: 'getUserTasks' };
      const result = await clientSendData('POST', '/get_user_tasks', reqData);
      if (result) setUserTasks(result); // Результат записывается в состояние
      else return;
    } catch (error) {
      catchHandler(error, 'getUserTasks');
    }
  }

  // Показать карточку
  function openTask(id) {
    setSearchParams({ task: id });
    setShowCard(true);
  }

  if (isLoading) return <Loader />;
  if (userTasks.length === 0) return null;
  return (
    <div className="user-tasks">
      {userTasks.map((task) => {
        const {
          id, short_title, short_text, status, date_end,
        } = task;
        const title = short_title.length > 50 ? `${short_title.slice(0, 50)}...` : short_title;
        return (
          <div key={id} className="user-tasks__task-button" onClick={() => openTask(id)}>
            <p className="user-tasks__info-row">{title}</p>
            <Division />
            <p className="user-tasks__info-row">{status}</p>
          </div>
        );
      })}

      {showCard && main && (
        createPortal(<UserTaskCard setShow={setShowCard} />, main)
      )}
    </div>
  );
}

export default UserTasks;
