import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { catchHandler } from '../../../../utils/error_handling/error_handling';
import { alertFunction } from '../../../../utils/functions/alertFunction';
import { clientSendData } from '../../../../utils/functions/requests';
import Loader from '../../../../components/UI/Loader';
import Toggle from '../../../../components/UI/Toggle';
import THead from '../../../../components/Table/THead';
import TData from '../../../../components/Table/TData';
import TRow from '../../../../components/Table/TRow';
import TBody from '../../../../components/Table/TBody';

/**
* @component Управление Базой знаний - Сущность(вопрос или тема) - Карточка сущности - Форма выбора switch поля - таблица
* @prop {scheme} object - схема сущности
* @prop {field} string - поле схемы
* @prop {value} string|array - значение поля в текущей сущности
* @prop {handler} function - обработчик изменения
*/
function Table(props) {
  const {
    scheme, field, value, handler,
  } = props;
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [tableData, setTableData] = useState([]); // данные таблицы
  const [currentRowId, setCurrentRowId] = useState(''); // id нажатой строки
  const currentDivision = useSelector((state) => state.kb.current_division); // текущее подразделение
  const switchData = scheme[field]?.switch; // switch данные поля

  // Создание заголовков
  const headers = [{
    title: 'Наименование',
    field: switchData?.switch_column,
  }];

  useEffect(() => {
    awaitRequests();
  }, []);

  // Функция ожидания выполнения необходимых запросов
  async function awaitRequests() {
    setIsLoading(true);
    await getTableData();
    setIsLoading(false);
  }

  // Получить данные для таблицы
  async function getTableData() {
    try {
      const reqData = {
        type: 'getTableData',
        switch_data: switchData,
        division_id: currentDivision.id,
      };
      const result = await clientSendData('POST', '/get_table_data', reqData); // Запрос в БД
      if (result === 'bad_request') alertFunction('bad_request', 'clientPost'); // если некорректный запрос
      else {
        setTableData(sortArray(result)); // Отсортировать массив и записать в переменную
        // createStructure(result)
      }
    } catch (error) {
      catchHandler(error, 'getTableData');
    }
  }

  // Проверить состояние выбора
  function isChoosed(id) {
    if (scheme[field]?.type === 'number') {
      // если массив выбора содержит переданный id - вернуть true, иначе false
      return value === id;
    }
    if (scheme[field]?.type === 'array') {
      // если массив выбора содержит переданный id - вернуть true, иначе false
      return value?.includes(id);
    } return false;
  }

  // Подготовить значение и отправить во внешний обработчик
  function prepareValue(object) {
    const { id } = object;
    const title = object[switchData?.switch_column];
    const switchFieldName = `switch_${field}`;
    const fieldData = scheme[field];

    // Если тип поля число - обработка единственного выбора
    if (fieldData?.type === 'number') {
      // если значение равно выбранному id - значит toggle выключается, передаём во внешний обработчик null
      if (value === id) {
        handler({
          [field]: null,
          [switchFieldName]: null,
        });
      } else { // иначе передаём во внешний обработчик переданный id
        handler({
          [field]: id,
          [switchFieldName]: title,
        });
      }
    } else if (fieldData?.type === 'array') { // Если тип поля массив - обработка множественного выбора
      // Создать заголовки на основании обновлённого массива id
      function createTitles(array) {
        const result = array.map((item) => {
          const findObject = tableData.find((row) => row.id === item);
          return findObject?.[switchData?.switch_column];
        });
        return result.join(', ');
      }

      // если присутствует массив value
      if (value) {
        // если массив выбора уже содержит переданный id - передаём во внешний обработчик массив без него
        if (value.includes(id)) {
          const newValue = value.filter((item) => item !== id);
          const newTitles = createTitles(newValue);
          handler({
            [field]: newValue,
            [switchFieldName]: newTitles,
          });
        } else { // иначе передаём во внешний обработчик текущий массив + переданный id
          const newValue = [...value, id];
          const newTitles = createTitles(newValue);
          handler({
            [field]: newValue,
            [switchFieldName]: newTitles,
          });
        }
        // если value отсутствует - передаём массив с выбранным id
      } else {
        const newValue = [id];
        const newTitles = createTitles(newValue);
        handler({
          [field]: newValue,
          [switchFieldName]: newTitles,
        });
      }
    }
  }

  // Переместить выбранные элементы массива в начало
  function sortArray(array) {
    if (scheme[field]?.type === 'number') {
      const checkedObjects = array.filter((item) => value === item.id); // выбранный элемент
      const spliceChecked = array.filter((item) => value !== item.id); // невыбранные элементы
      return [...checkedObjects, ...spliceChecked]; // объединение массивов
    } if (scheme[field]?.type === 'array') {
      const checkedObjects = array.filter((item) => value?.includes(item.id)); // выбранные элементы
      const spliceChecked = array.filter((item) => !value?.includes(item.id)); // невыбранные элементы
      return [...checkedObjects, ...spliceChecked]; // объединение массивов
    }
    return [];
  }

  if (isLoading) return <Loader />;
  return (
    <table className="upu-table">
      {/* Хедер таблицы */}
      <THead
        title={scheme[field]?.name}
        headers={headers}
        array={tableData}
        setArray={setTableData}
        button_place="row"
      />
      {/* Тело таблицы */}
      <TBody>
        {tableData?.map((row) => (
          <TRow key={row.id} onClick={() => setCurrentRowId(row.id)} active={currentRowId === row.id}>
            {/* Поле для отображения */}
            <TData key={field}>{row[switchData?.switch_column]}</TData>
            <TData>
              {/* Переключатель выбора */}
              <Toggle state={String(isChoosed(row.id))} onClick={() => prepareValue(row)} />
            </TData>
          </TRow>
        ))}
      </TBody>
    </table>
  );
}

export default Table;
