import React from 'react';

/**
* @component Линия-разделитель
*/

function Division() {
  return <div className="division" id="division" />;
}

export default Division;
