import React from 'react';
import Hierarchy from './Hierarchy';
import Table from './Table';
import Card from '../../../../components/Card/Card';

/**
* @component Управление Базой знаний - Сущность(вопрос или тема) - Карточка сущности - Форма выбора switch поля
* @prop {scheme} object - схема сущности
* @prop {field} string - поле схемы
* @prop {value} string|array - значение поля в текущей сущности
* @prop {handler} function - обработчик изменения
* @prop {initialObject} object - исходный объект (для сброса)
* @prop {setShow} function - обновление состояния отображения карточки (открыть/закрыть форму)
*/
function ChooseForm(props) {
  const {
    scheme, field, value, handler, initialObject, setShow,
  } = props;

  const parent_key = scheme[field]?.switch?.parent_key; // ключ для создания иерархической структуры

  return (
    <Card
      id="knowledge-base__choose-form_card"
      background_id="knowledge-base__choose-form"
      setShow={setShow}
    >
      {parent_key ? ( // Если есть parent_key
      <Hierarchy // показать иерархию
        scheme={scheme}
        field={field}
        value={value}
        initialObject={initialObject}
        handler={handler}
      />) : (
      <Table // иначе показать таблицу
        scheme={scheme}
        field={field}
        value={value}
        handler={handler}
      />)}
    </Card>
  );
}

export default ChooseForm;
