import React, { useEffect, useState } from 'react';
import Table from '../../../components/Table/Table';
import THead from '../../../components/Table/THead';
import TBody from '../../../components/Table/TBody';
import TRow from '../../../components/Table/TRow';
import TData from '../../../components/Table/TData';
import { clientSendData } from '../../../utils/functions/requests';
import { catchHandler } from '../../../utils/error_handling/error_handling';
import TFoot from '../../../components/Table/TFoot';
import ServiceBody from '../../../components/Service/ServiceBody';
import StatusesLegend from './StatusesLegend';
import ServicePanel from '../../../components/Service/ServicePanel';

/**
* @component Состояние портала - интерфейс пользователя
*/
function UserInterface() {
  const [services, setServices] = useState([]); // все события
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [headers, setHeaders] = useState([]);
  const [reload, setReload] = useState(true);

  useEffect(() => {
    createHeaders();
    handlePsServices();
  }, [reload]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setReload((state) => !state); // Обновить состояние tableState каждые 10 сек
    }, 60000);
    return () => clearInterval(intervalId);
  }, []);

  async function createHeaders() {
    const array = [{ id: 0, title: 'Сервис', field: 'title' }];
    for (let j = 1; j < 8; ++j) {
      const day = new Date();
      day.setDate(day.getDate() + j - 1);
      const day_str = day.getDate().toString().padStart(2, '0');
      const month_str = (day.getMonth() + 1).toString().padStart(2, '0');
      const result_str = `${day_str}. ${month_str}`;
      array.push({ id: j, title: result_str, field: 'current_status_id' });
    }
    setHeaders(array);
  }
  // Обработка действий с событиями
  // Получение данных о
  async function handlePsServices() {
    try {
      const reqData = {
        type: 'accessiblePsServices',
      };
      setIsLoading(true);
      const result = await clientSendData('POST', '/accessible_ps_services', reqData);
      if (result.success) {
        setServices(result.data);
      }
      setIsLoading(false);
    } catch (error) {
      catchHandler(error, 'accessiblePsServices');
    }
  }

  // Иначе показываем таблицу
  return (
    <ServiceBody horizontal>
      <ServicePanel>
        <StatusesLegend />
      </ServicePanel>
      <Table id="portal-state__users">
        <THead title="Панель доступности сервисов">
          <TRow>
            {headers.map((item) => <TData key={item.id}>{item.title}</TData>)}
          </TRow>
        </THead>
        <TBody>
          {services.map((row) => (
            <TRow key={row.item.id}>
              {headers.map((column) => {
                const { id, field } = column; // деcтруктуризация объекта колонки
                const value = row?.item[field]; // значение текущего ключа в объекте строки
                const displayValue = value === row.item.current_status_id ? ' ' : value;
                return (
                  <TData key={id} loading={isLoading}>
                    <div title={row?.status_message[id - 1]}>
                      <span title={row.item.description}>{displayValue || (displayValue ?? '-')}</span>
                      {field === 'current_status_id' && (
                        <div
                          style={{
                            backgroundColor: row?.status_array[id - 1],
                            width: '20px',
                            height: '20px',
                            borderRadius: '50%',
                          }}
                        />
                      )}
                    </div>
                  </TData>
                );
              })}
            </TRow>
          ))}
        </TBody>
        <TFoot />
      </Table>
    </ServiceBody>
  );
}

export default UserInterface;
