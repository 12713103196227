import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { catchHandler } from '../../../../utils/error_handling/error_handling';
import { alertFunction } from '../../../../utils/functions/alertFunction';
import { stringMatch } from '../../../../utils/functions/stringHandling';
import { clientSendData } from '../../../../utils/functions/requests';
import { getQuestions } from '../../functions';
import { backlightHandler, setOperInfo, log } from '../../../../utils/functions/others';
import Button from '../../../../components/UI/Button/Button';
import classes from '../../../../components/UI/Button/button.module.scss';
import Input from '../../../../components/UI/Input';
import Loader from '../../../../components/UI/Loader';
import Select from '../../../../components/UI/Select';
import InputSearch from '../../../../components/UI/InputSearch';
import TwoChoice from '../../../../components/UI/TwoChoice';
import CardSetting from '../../../../components/Card/CardSetting';
import TextArea from '../../../../components/UI/TextArea';

// Сервис анкетирования - вкладка "Вопросы" - карточка вопроса - вкладка Настройки
function Settings(props) {
  // свойства переданные компоненту при вызове
  const {
    setShowCard, currentQuestion, setCurrentQuestion, answersOutputs,
  } = props;

  const location = useLocation(); // Адрес
  const userGroups = useSelector((state) => state.questionnaire.user_directions); // группы доступные пользователю
  const allCategories = useSelector((state) => state.questionnaire.categories); // все категории
  const currentDirection = useSelector((state) => state.questionnaire.current_direction); // текущее направление
  const [backlight, setBacklight] = useState(''); // состояние подсветки результатов поиска категорий
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [catChoiceType, setCatChoiceType] = useState('select'); // тип выбора категорий (селект или поиск)
  // выбранное в карточке направление
  const [selectedDirection, setSelectedDirection] = useState(currentQuestion.group_id);
  // Отфильтрованные категории, у которых направление совпадает с выбранным в карточке вопроса
  const filteredCategories = allCategories.filter((c) => c.direction_id === selectedDirection);
  const [categories, setCategories] = useState(filteredCategories);

  // Изменение направления вопроса
  function changeDirection(d) {
    setSelectedDirection(d.id);
    setCurrentQuestion({
      ...currentQuestion,
      group_id: d.id, // обновление id направления
      group: d.title, // обновление имени направления
      category: null, // сброс имени категории
      category_id: null, // сброс id категории
    }); // обновление состояния
    log({ message: `Пользователь в карточке вопроса id: ${currentQuestion.id} выбрал направление "${d.title}" id: ${d.id}`, eventtype: 'info' });
  }

  // Изменение категории вопроса
  function changeCategory(c) {
    setCurrentQuestion({
      ...currentQuestion,
      category_id: c.id, // обновление id категории
      category: c.source_title || c.title, // обновление имени категории
      // c.source_title - при выборе из поиска, c.title - при выборе из селекта
    }); // запись в состояние
    log({ message: `Пользователь в карточке вопроса id: ${currentQuestion.id} выбрал категорию "${c.title}" id: ${c.id}`, eventtype: 'info' });
  }

  // Изменение типа вывода ответа вопроса
  function changeAnswersOutput(c) {
    setCurrentQuestion({
      ...currentQuestion,
      output_id: c.id, // обновление id вывода
      output_title: c.title, // обновление имени вывода
    }); // запись в состояние
    log({ message: `Пользователь в карточке вопроса id: ${currentQuestion.id} выбрал формат выбора ответов "${c.title}" id: ${c.id}`, eventtype: 'info' });
  }

  // Изменение заголовка вопроса
  function changeQuestionTitle(e) {
    setCurrentQuestion({
      ...currentQuestion,
      title: e.target.value, // обновление заголовка вопроса
    }); // запись в состояние
  }

  // Изменение содержания вопроса
  function changeQuestionContent(e) {
    setCurrentQuestion({
      ...currentQuestion,
      question: e.target.value, // обновление вопроса
    }); // запись в состояние
  }

  // Проверка связи вопроса с анкетами перед его удалением
  async function checkQuestionRelations() {
    try {
      const reqData = {
        type: 'checkQuestionRelations',
        question_id: currentQuestion.id,
      };
      setIsLoading(true); // загружается
      const result = await clientSendData('POST', '/check_question_relations', reqData); // запрос в БД
      if (result) {
        let relatedQuestionnairies = ''; // список связанных анкет
        result.forEach((q, i) => {
          if (i > 0) relatedQuestionnairies += `, "${q.title}"`;
          else relatedQuestionnairies += q.title;
        });
        // если есть анкеты к которым принадлежит вопрос
        const confirmMessage = result.length > 0 // они перечисляются в подтверждении
          ? `Данный вопрос связан с ${relatedQuestionnairies}. Действительно удалить?` // иначе сообщается что вопрос ни с чем не связан
          : 'Данный вопрос не входит ни в одну анкету. Удалить?'; //

        const сonfirm = window.confirm(confirmMessage); // запрашивается подтверждение

        if (сonfirm) { // если пользователь подтвердил
          setIsLoading(true); // загружается
          await deleteQuestion(currentQuestion.id); // удаление вопроса
          setIsLoading(false); // загрузка завершена
          log({ message: `Пользователь удалил вопрос id: ${currentQuestion.id}`, eventtype: 'info' });

          if (result.length > 0) { // если вопрос связан с анкетами
            const logData = {
              type: 'questionnaireLog',
              questionnaire_id: null, // id анкеты
              question_id: currentQuestion.id, // id вопроса
              title: 'Удаление вопроса', // заголовок
              message: `Вопрос удалён из анкет ${relatedQuestionnairies}`, // сообщение
            };
          }
        }
      }
      setIsLoading(false); // загрузка завершена
    } catch (error) {
      catchHandler(error, 'checkQuestionRelations');
      setIsLoading(false); // загрузка завершена
    }
  }

  // Удалить вопрос
  async function deleteQuestion(question_id) {
    try {
      const reqData = {
        type: 'deleteQuestion',
        question_id,
      };
      const result = await clientSendData('POST', '/delete_question', reqData); // запрос в БД
      if (result === 'success') { // если успех
        await getQuestions(location.pathname, currentDirection); // обновить вопросы
        setOperInfo({ subject_id: null }); // сброс id вопроса в оперативной информации
        alertFunction('save_settings', 'clientPost'); // уведомление  - настройки сохранены
        setShowCard(false); // закрыть карточку
      }
    } catch (error) {
      catchHandler(error, 'deleteQuestion');
    }
  }

  // Поиск по категориям
  function searchCategory(e) {
    if (!e.target.value) { // Если не введено ничего
      setCategories([]); //  массив категорий обнуляется
      backlightHandler([], setBacklight); // инпут подсвечивается красным
    } else {
      // Результат поиска - это отфильтрованный исходный массив, в котором искомая строка содержится в элементах массива
      const searchResult = filteredCategories.filter((c) => stringMatch(e.target.value, c.title)); // поиск в заголовке категории
      if (e.target.value === '/') { // если введен "/"
        setCategories(filteredCategories); // показать все категории
        backlightHandler(filteredCategories, setBacklight);
      } else if (searchResult.length > 0) {
        setCategories(searchResult); // если есть результат - он показывается
        backlightHandler(searchResult, setBacklight);
      } else {
        setCategories([]); // иначе - сброс поиска
        backlightHandler([], setBacklight);
      }
    }
  }

  // Обработчик переключателя между выборами категорий
  function catChoiceTypeHandler(currentChoice) {
    if (currentChoice) setCatChoiceType('search');
    else setCatChoiceType('select');
  }

  return (
    <div className="questions-card__block">
      {/* Выбор направления к которой относится вопрос */}
      <CardSetting title="Направление" invalid={!currentQuestion?.group_id}>
        <Select
          id="questions-card__direction"
          array={userGroups}
          onChoose={changeDirection}
          defaultValue={currentQuestion.group}
        >
            Направление
        </Select>
      </CardSetting>

      {/* Выбор категории к которой относится вопрос */}
      <CardSetting title="Выбор категории" invalid={!currentQuestion?.category_id}>
        <div className="questions-card__setting-wrapper">
          <TwoChoice
            id="questionnaire-questions__two-choice"
            handler={catChoiceTypeHandler}
            img_1={<img src="../../../../icons/search.svg" alt="search" />}
            img_2={<img src="../../../../icons/chevron.svg" alt="chevron" />}
          />

          {catChoiceType === 'select' && (
          <Select
            id="questions-card__category-select"
            array={filteredCategories}
            onChoose={changeCategory}
            defaultValue={filteredCategories.length > 0 ? currentQuestion.category : 'Варианты отсутствуют'}
          >
            Категории
          </Select>)}

          {catChoiceType === 'search' && (
          <InputSearch
            id="questions-card__category-search"
            array={categories}
            onSearch={searchCategory}
            onChoose={(c) => { changeCategory(c); setBacklight('green'); }}
            defaultValue={currentQuestion.category}
            placeholder="все категории"
            backlight={backlight}
          />)}
        </div>
      </CardSetting>

      {/* Выбор типа вывода ответов */}
      <CardSetting title="Формат выбора" invalid={!currentQuestion?.output_id}>
        <Select
          id="questions-card__answer-output"
          array={answersOutputs}
          onChoose={changeAnswersOutput}
          defaultValue={currentQuestion.output_title}
        >
          Формат выбора
        </Select>
      </CardSetting>

      {/* Заголовок вопроса */}
      <CardSetting title="Заголовок" invalid={!currentQuestion?.title}>
        <Input
          onChange={changeQuestionTitle}
          defaultValue={currentQuestion.title}
          placeholder="Заголовок"
        />
      </CardSetting>

      {/* Содержание вопроса */}
      <CardSetting title="Содержание" invalid={!currentQuestion?.question}>
        <TextArea
          onChange={changeQuestionContent}
          defaultValue={currentQuestion.question}
          placeholder="Содержание"
        />
      </CardSetting>

      {/* Кнопка удалить только для редактируемого вопроса */}
      {currentQuestion.id !== 'new' && (
      <CardSetting title="Удаление вопроса">
        <Button className={classes.button_ghost} onClick={checkQuestionRelations}>
          {isLoading ? <Loader /> : 'Удалить' }
        </Button>
      </CardSetting>)}
    </div>
  );
}

export default Settings;
