import React from 'react';
import MenuLine from './MenuLine';

function Menu({
  data, onItemClick, activeItem, setActiveItem, openedItems, setOpenedItems,
}) {
  return (
    <div>
      {data.map((item) => (
        <MenuLine
          key={item.id}
          object={item}
          onItemClick={onItemClick}
          activeItem={activeItem}
          setActiveItem={setActiveItem}
          openedItems={openedItems}
          setOpenedItems={setOpenedItems}
        />
      ))}
    </div>
  );
}

export default Menu;
