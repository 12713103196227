import React, { useEffect, useState } from 'react';
import Loader from '../../../../components/UI/Loader';
import TableComponent from '../../../../components/UI/TableComponent';
import { getSubjectLog } from '../../functions';

// Сервис анкетирования - вкладка "Категории" - карточка категории - вкладка История
function Logs(props) {
  const { id } = props;
  const [logs, setLogs] = useState([]); // логи по направлению
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки

  useEffect(() => {
    getLogs(); // получить логи
  }, []);

  // Обёртка для функции получения логов для управления состоянием загрузки
  async function getLogs() {
    setIsLoading(true); // состояние - загружается
    await getSubjectLog('category', id, setLogs); // унифицированная функция получения логов
    setIsLoading(false); // загрузка завершена
  }

  if (isLoading) return <div className="upu-card__block"><Loader /></div>;
  return (
    <div className="upu-card__block">
      {logs.length > 0
        ? <TableComponent id="categories__logs" data={logs} />
        : <h2 className="upu-service__title">История отсутствует</h2>}
    </div>
  );
}

export default Logs;
