import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { clientSendData } from '../../../../utils/functions/requests';
import { alertFunction } from '../../../../utils/functions/alertFunction';
import { catchHandler } from '../../../../utils/error_handling/error_handling';
import { backlightHandler, log } from '../../../../utils/functions/others';
import InputSearch from '../../../../components/UI/InputSearch';
import Input from '../../../../components/UI/Input';
import Select from '../../../../components/UI/Select';
import CheckBox from '../../../../components/UI/CheckBox';
import Cancel from '../../../../components/UI/Cancel';
import CheckMark from '../../../../components/UI/CheckMark';
import IconButton from '../../../../components/UI/Button/IconButton';
import Loader from '../../../../components/UI/Loader';
import Table from '../../../../components/Table/Table';
import THead from '../../../../components/Table/THead';
import TRow from '../../../../components/Table/TRow';
import TData from '../../../../components/Table/TData';
import TBody from '../../../../components/Table/TBody';
import TFoot from '../../../../components/Table/TFoot';

function QuestionnaireAccess(props) {
  const { currentQuestionnaire } = props;
  const questionnaire_id = currentQuestionnaire.questionnaire.id; // Получаем id текущей анкеты
  const accessDefault = {
    questionnaire_id,
    period: false, // наличие срока действия
    period_date: null, // дата истечения срока действия
    type_id: null, // id типа
    type_name: '', // название типа
    user_assigned_id: null, // id пользователя которому назначено
    user_assigned_name: null, // имя пользователя которому назначено
  };
  const operInfo = useSelector((state) => state.oper_info); // оперативная информация
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [accessTypes, setAccessTypes] = useState([]); // типы доступов к анкете
  const [accesses, setAccesses] = useState([]); // доступы к анкете
  const [addAccess, setAddAccess] = useState(false); // Состояние добавление новых прав
  const [newAccess, setNewAccess] = useState(accessDefault);
  const [editAccess, setEditAccess] = useState(false); // Состояние редактирование прав
  const [editingAccess, setEditingAccess] = useState(accessDefault);

  const [users, setUsers] = useState([]); // Найденные пользователи
  const [backlight, setBacklight] = useState(''); // Состояние подсветки поля выбора пользователя

  const headers = [
    { id: 1, title: 'Создатель', field: 'creator' },
    { id: 2, title: 'Тип доступа', field: 'name' },
    { id: 3, title: 'Кому назначена', field: 'assigned_user' },
    { id: 4, title: 'Срок действия', field: 'period_date' },
    { id: 5, title: 'Ссылка', field: 'link' },
    { id: 6, title: 'Управление', field: 'creator' },

  ];
  useEffect(() => {
    getQuestionnairуAccess(); // Получение прав на анкету
    getQuestionnaireAccessTypes(); // Получение типов доступа
  }, []);

  // Получение прав доступа на анкету
  async function getQuestionnairуAccess() {
    try {
      const reqData = {
        type: 'getQuestionnairiesAccess',
        questionnaire_id,
      };
      setIsLoading(true);
      const result = await clientSendData('POST', '/get_questionnaire_access', reqData); // запрос в БД
      if (result) setAccesses(result);
      setIsLoading(false);
    } catch (error) {
      catchHandler(error, 'getQuestionnairiesAccess');
      setIsLoading(false);
    }
  }

  // Создание новых прав на анкету
  async function addQuestionnaireAccess() {
    try {
      // Валидация
      if (newAccess.period && !newAccess.period_date) { // Если включен срок действия доступа но не установлена дата
        alertFunction('qa_access_period_date', 'clientPost'); // уведомление о необходимости заполнения даты
      } else if (newAccess.type_id === 2 && !newAccess.user_assigned_id) { // Если доступ "персональный", но не выбран пользователь
        alertFunction('qa_access_personal_user', 'clientPost'); // уведомление о необходимости выбора пользователя
      } else if (!newAccess.type_id) { // Если не выбран тип доступа
        alertFunction('qa_access_select', 'clientPost'); // уведомление о необходимости выбора типа доступа
      } else {
        const reqData = {
          type: 'addQuestionnairiesAccess',
          new_access: newAccess,
          url: operInfo?.url,
        };
        setIsLoading(true);
        const result = await clientSendData('POST', '/add_questionnaire_access', reqData); // запрос в БД
        if (result) {
          getQuestionnairуAccess(); // обновление состояние отображаемых прав
          alertFunction('save_settings', 'clientPost'); // уведомление о сохранении
          resetAdd(); // сбросить режим создания
          log({ message: `Пользователь в карточке анкеты id: ${currentQuestionnaire.questionnaire.id} сохранил новые права` });
        }
        setIsLoading(false);
      }
    } catch (error) {
      catchHandler(error, 'addQuestionnaireAccess');
    }
  }

  // Получение типов прав на анкету
  async function getQuestionnaireAccessTypes() {
    try {
      const reqData = {
        type: 'getQuestionnaireAccessTypes',
      };
      setIsLoading(true);
      const result = await clientSendData('POST', '/get_questionnaire_access_types', reqData); // запрос в БД
      if (result) setAccessTypes(result); // запись типов доступа к анкетам в состояние
      setIsLoading(false);
    } catch (error) {
      catchHandler(error, 'getQuestionnaireAccessTypes');
      setIsLoading(false);
    }
  }

  // Удаление прав доступа к анкете
  async function deleteAcces(id) {
    // Уведомление о подтверждении удаления
    const confirm = window.confirm('Уверены, что хотите удалить права?');
    try {
      // Если подтверждено
      if (confirm) {
        const reqData = {
          type: 'delQuestionnaireAccess',
          id,
        };
        setIsLoading(true);
        const result = await clientSendData('POST', '/del_questionnaire_access', reqData); // запрос в БД
        if (result === 'bad_request') { // если некорректный запрос
          alertFunction('bad_request', 'clientPost'); // уведомление
          setIsLoading(false);
        } else {
          getQuestionnairуAccess(); // обновление состояние отображаемых прав
          setIsLoading(false);
        }
      }
    } catch (error) {
      catchHandler(error, 'delQuestionnaireAccess');
      setIsLoading(false);
    }
  }

  // Редактирование прав доступа
  async function changeQuestionnaireAccess() {
    try {
      // Валидация
      if (editingAccess.period && !editingAccess.period_date) { // Если включен срок действия доступа но не установлена дата
        alertFunction('qa_access_period_date', 'clientPost'); // уведомление о необходимости заполнения даты
      } else if (editingAccess.type_id === 2 && !editingAccess.user_assigned_id) { // Если доступ "персональный", но не выбран пользователь
        alertFunction('qa_access_personal_user', 'clientPost'); // уведомление о необходимости выбора пользователя
      } else if (!editingAccess.type_id) { // Если не выбран тип доступа
        alertFunction('qa_access_select', 'clientPost'); // уведомление о необходимости выбора типа доступа
      } else {
        const reqData = {
          type: 'changeQuestionnaireAccess',
          editing_access: editingAccess,
        };
        setIsLoading(true);
        const result = await clientSendData('POST', '/change_questionnaire_access', reqData); // запрос в БД
        if (result) {
          getQuestionnairуAccess(); // обновление состояние отображаемых прав
          alertFunction('save_settings', 'clientPost'); // уведомление о сохранении
          resetEdit(); // сбросить режим редактирования
          setIsLoading(false);
          log({ message: `Пользователь в карточке анкеты id: ${currentQuestionnaire.questionnaire.id} сохранил изменение прав id: ${editingAccess.access_id}` });
        }
        setIsLoading(false);
      }
    } catch (error) {
      catchHandler(error, 'changeQuestionnaireAccess');
      setIsLoading(false);
    }
  }

  // Поиск пользователя и выбор пользователя
  async function searchUser(e) {
    try {
      // При поиске сотрудника, его id сбрасывается и устанавливается только при выборе
      // это необходимо для валидации

      setNewAccess({ // обновление объекта нового доступа
        ...newAccess,
        user_assigned_id: null,
        user_assigned_name: e.target.value,
      });

      setEditingAccess({ // обновление объекта существующего доступа
        ...editingAccess,
        user_assigned_id: null,
        user_assigned_name: e.target.value,
      });

      if (e.target.value.length <= 1 || !e.target.value) {
        // При открытии формы редактирования e.target.value отсутствует, но имя пользователя отображается в поле
        // поэтому поле подсвечивается зелёным
        if (e.target.value) setBacklight('green');
        setUsers([]); // сброс массива
        backlightHandler([], setBacklight); // Обновление подсветки поля выбора пользователя
      } else {
        const reqData = {
          type: 'searchMentor',
          query: e.target.value,
        };
        const result = await clientSendData('POST', '/search_mentor', reqData); // Запрос в БД
        if (result) {
          backlightHandler(result, setBacklight); // Обновление подсветки поля выбора пользователя
          setUsers(result); // Запись пользователей в хранилище
        }
      }
    } catch (error) {
      catchHandler(error, 'searchUser');
    }
  }

  // Обработка выбора пользователя
  function chooseUser(user, source) {
    setBacklight('green'); // Установка цвета поля
    if (source === 'creating') { // если источник выбора  - создание доступа
      setNewAccess({ // обновление объекта нового доступа
        ...newAccess,
        user_assigned_id: user.id,
        user_assigned_name: user.title,
      });
      log({ message: `Пользователь в карточке анкеты id: ${currentQuestionnaire.questionnaire.id} при добавлении новых прав выбрал пользователя ${user.title} id: ${user.id}` });
    }
    if (source === 'editing') { // если источник выбора  - редактирование доступа
      setEditingAccess({ // обновление объекта редактируемого доступа
        ...editingAccess,
        user_assigned_id: user.id,
        user_assigned_name: user.title,
      });
    }
  }

  // Вкл режим редактирования и запись редактируемого доступа в переменную
  function startEditAccess(access) {
    setEditAccess(access.id);
    setEditingAccess({
      ...editingAccess,
      access_id: access.id,
      period: access.period, // наличие срока действия
      period_date: access.period_date, // дата истечения срока действия
      type_id: access.type_id, // id типа
      type_name: access.name, // id типа
      user_assigned_id: access.user_assigned_id, // id пользователя которому назначено
      user_assigned_name: `${access.assigned_sn} ${access.assigned_initials}`, // имя пользователя которому назначено
    });
    log({ message: `Пользователь в карточке анкеты id: ${currentQuestionnaire.questionnaire.id} нажал на кнопку изменения прав id: ${access.id}` });
  }

  // Отменить создание доступа
  function resetAdd() {
    setAddAccess(false); // выкл состояние создания доступа
    setNewAccess(accessDefault); // сброс объекта нового доступа
    setBacklight(''); // сброс подсветки
  }

  // Отменить изменение доступа
  function resetEdit() {
    setEditAccess(false); // выкл состояние изменения доступа
    setEditingAccess(accessDefault); // сброс объекта изменяемого доступа
    setBacklight(''); // сброс подсветки
    log({ message: `Пользователь в карточке анкеты id: ${currentQuestionnaire.questionnaire.id} отменил изменение прав` });
  }

  // Выбрать тип доступа
  function chooseType(type) {
    setNewAccess({
      ...newAccess,
      type_id: type.id,
      type_name: type.title,
    });
    log({ message: `Пользователь в карточке анкеты id: ${currentQuestionnaire.questionnaire.id} при добавлении новых прав выбрал тип ${type.title} id: ${type.id}` });
  }

  // Открыть форму создания доступа
  function openAddForm() {
    setAddAccess(true);
    log({ message: `Пользователь в карточке анкеты id: ${currentQuestionnaire.questionnaire.id} нажал кнопку "Добавить права"` });
  }

  // вкл/выкл период срок действия доступа
  function togglePeriod(e, row) {
    setEditingAccess({
      ...editingAccess,
      period: e.target.checked,
      period_date: e.target.checked ? row.period_date : null, // если чекбокс выкл, то период сбрасывается иначе ставится исходный
    });
    log({ message: `Пользователь в карточке анкеты id: ${currentQuestionnaire.questionnaire.id} при изменении прав(id: ${row.id}) ${e.target.checked ? 'установил' : 'снял'} чекбокс "Срок действия"` });
  }

  return (
    <div className="questions-card__block">
      {isLoading ? <Loader /> : (
      <Table id="questionnaire-access__table">

        <THead>
          <TRow>
            {headers.map((header) => <TData key={header.id}>{header.title}</TData>)}
          </TRow>
        </THead>

        <TBody>
          {accesses.map((row) => (
            <TRow key={row.id}>
              <TData>{row.creator}</TData>
              <TData align="left">
                {
                // editAccess === row.id ?
                //     <Select
                //         id="questionnaire-access__editing-type"
                //         array={questionnaire_types}
                //         onChoose={(choose) => setEditingAccess({
                //             ...editingAccess,
                //             type_id: choose.id,
                //             type_name: choose.title,
                //             user_assigned_id: null
                //         })}
                //         defaultValue={editingAccess.type_name}
                //     /> :
                row.name
                }
              </TData>
              <TData>
                {
                // editAccess === row.id && editingAccess.type_id === 2 ?
                // <InputSearch
                //     id="questionnaire-access__editing-mentor-search"
                //     array={mentors}
                //     onChoose={(user) => chooseUser(user, "editing")}
                //     onSearch={searchUser}
                //     backlight={backlight}
                //     defaultValue={editingAccess.user_assigned_id === row.user_assigned_id ? row.assigned_sn : ""}
                // />
                // :
                // editAccess === row.id && editingAccess.type_id !== 2 ? ""
                // :
                row.assigned_user || ''
                }
              </TData>
              <TData>
                {editAccess === row.id ? (
                <div className="access-period">
                  <CheckBox
                    value={editingAccess.period}
                    onChange={(e) => togglePeriod(e, row)}
                    defaultChecked={editingAccess.period}
                  />

                  {editingAccess.period && ( // если чекбокс вкл
                  <Input // показывается инпут
                    type="date"
                    onChange={(e) => setEditingAccess({ ...editingAccess, period_date: e.target.value })}
                    defaultValue={editingAccess.period_date}
                  />)}
                </div>
                ) : (
                  row.period_date && `до ${row.period_date}`)}
              </TData>
              <TData>
                <Link to={`/interview/${row.link}`} className="clickable">ссылка</Link>
              </TData>

              {/* Если состояние редактирования прав */}
              <TData>
                {editAccess === row.id ? (
                <div className="access-manage">
                  <CheckMark onClick={changeQuestionnaireAccess} />
                  <Cancel onClick={resetEdit} />
                </div>
                ) : (
                <div className="access-manage">
                  <IconButton icon="edit" onClick={() => startEditAccess(row)} />
                  {/* <IconButton icon="delete" onClick={() => deleteAcces(row.id)}/> */}
                </div>)}
              </TData>
            </TRow>
          ))}
        </TBody>

        {/* Футер таблицы */}
        <TFoot>
          {/* Если состояние добавления прав -  Выводим поля для ввода значений */}
          {addAccess ? (
          <TRow>
            <TData />
            <TData>
              <Select
                id="questionnaire-access__new-type"
                array={accessTypes}
                onChoose={chooseType}
                defaultValue={newAccess.type_name}
              >
                Выберите тип
              </Select>
            </TData>
            <TData>
              {newAccess.type_id === 2 && (
              <InputSearch
                id="questionnaire-access__creating-mentor-search"
                array={users}
                onChoose={(user) => chooseUser(user, 'creating')}
                onSearch={searchUser}
                backlight={backlight}
                defaultValue={newAccess.user_assigned_name}
              />)}
            </TData>
            <TData>
              <div className="access-period">
                <CheckBox
                  value={newAccess.period}
                  onChange={(e) => {
                    setNewAccess({ ...newAccess, period: e.target.checked });
                    log({
                      message: `Пользователь в карточке анкеты id: ${currentQuestionnaire.questionnaire.id} 
                      при добавлении новых прав ${e.target.checked ? 'установил' : 'снял'} чекбокс "Срок действия"`,
                    });
                  }}
                  defaultChecked={newAccess.period}
                />
                {newAccess.period && (
                <Input
                  type="date"
                  onChange={(e) => setNewAccess({ ...newAccess, period_date: e.target.value })}
                />)}
              </div>
            </TData>
            <TData />
            <TData>
              <div className="access-manage">
                <CheckMark onClick={addQuestionnaireAccess} />
                <Cancel
                  onClick={() => {
                    resetAdd();
                    log({ message: `Пользователь в карточке анкеты id: ${currentQuestionnaire.questionnaire.id} отменил добавление новых прав` });
                  }}
                />
              </div>
            </TData>
          </TRow>
          ) : (
          <TRow>
            <TData onClick={openAddForm}>Добавить права</TData>
          </TRow>)}
        </TFoot>
      </Table>)}
    </div>
  );
}

export default QuestionnaireAccess;
