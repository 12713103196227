import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { catchHandler } from '../../../../utils/error_handling/error_handling';
import { alertFunction, alertFunctionLocal } from '../../../../utils/functions/alertFunction';
import { clientSendData } from '../../../../utils/functions/requests';
import Loader from '../../../../components/UI/Loader';
import IconButton from '../../../../components/UI/Button/IconButton';
import Toggle from '../../../../components/UI/Toggle';
import Select from '../../../../components/UI/Select';
import Input from '../../../../components/UI/Input';
import Tags from '../../../../components/UI/InputFit/Tags/Tags';
import CardSetting from '../../../../components/Card/CardSetting';
import ToggleSetting from './ToggleSetting';

/**
 * @component Администрирование базы знаний - редактирование подразделений - карточка подразделения - вкладка Cхемы - карточка схемы - вкладка Настройки
 * @prop {scheme} object - объект схемы (непосредственно настройки полей)
 * @prop {setScheme} function - обновление состояния объекта схемы
 * @prop {schemeData} object - объект данных схемы (название, версия, статус и т.д)
 * @prop {setSchemeData} function - обновление состояния объекта данных схемы
 * @prop {setShowCard} function - обновление состояния отображения карточки (открыть/закрыть)
 * @prop {getSchemes} function - получение схем (для обноления таблицы)
 * @prop {newKey} string|boolean - имя создаваемого ключа (для автофокуса)
*/
function Settings(props) {
  const {
    scheme, setScheme, schemeData, setSchemeData, setShowCard, getSchemes, newKey,
  } = props;

  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [editingKey, setEditingKey] = useState(null); // редактируемый ключ
  const [dataTypes, setDataTypes] = useState([]); // типы данных
  const schemeTypes = useSelector((state) => state.kb.scheme_types); // типы схем
  const essenceTypes = useSelector((state) => state.kb.essence_types_id); // типы схем
  const operators = [{ id: 1, title: 'AND' }, { id: 2, title: 'OR' }]; // операторы для условия подмены данных
  const sortingOptions = [
    { id: 1, title: 'Выкл', value: null },
    { id: 2, title: 'ASC', value: 'ASC' },
    { id: 3, title: 'DESC', value: 'DESC' }]; // направления сортировки
  const isQuestionScheme = schemeData?.type === essenceTypes.question_scheme; // Это схема вопроса?
  const isThemeScheme = schemeData?.type === essenceTypes.theme_scheme; // Это схема темы?

  useEffect(() => {
    awaitRequests(); // выполнение запросов для получения необходимой информации
  }, []);

  async function awaitRequests() {
    setIsLoading(true); // состояние - загружается
    await getDataTypes(); // получить типы данных
    setIsLoading(false); // загрузка завершена
  }

  // Получить список типов данных
  async function getDataTypes() {
    try {
      const reqData = {
        type: 'getDataTypes',
      };
      const result = await clientSendData('POST', '/get_data_types', reqData);
      if (result) setDataTypes(result); // Результат записывается в состояние
    } catch (error) {
      catchHandler(error, 'getDataTypes');
    }
  }

  // Удалить схему
  async function deleteScheme() {
    try {
      const confirm = window.confirm('Удалить схему?');
      if (confirm) { // если пользователь подтвердил удаление
        const reqData = {
          type: 'editScheme',
          scheme: schemeData,
          action: 'delete',
        };
        const result = await clientSendData('POST', '/edit_scheme', reqData);
        if (result === 'bad_request') alertFunction('bad_request', 'clientPost');
        else {
          await getSchemes(); // обновить схемы
          setShowCard(false); // закрыть схему
        }
      } else return; // если пользователь отменил удаление - выход из функции
    } catch (error) {
      catchHandler(error, 'deleteScheme');
    }
  }

  // ------------------------Изменение ключей------------------------

  // Изменить наименование ключа
  function changeFieldName(field, name) {
    // Найти в схеме поле с таким же именем как и вводит пользователь
    const isKeyAlreadyExist = Object.keys(scheme).find((row) => row === name);
    if (isKeyAlreadyExist) { // если такое поле существует
      alertFunctionLocal('Такое поле уже существует!'); // уведомление
      // ! В этом случае у пользователя отсутствует возможность ввести наиенования поля, уже присутствуещего в схеме
    } else { // если полей с таким наименованием нет
      const copy = Object.fromEntries( // создать объект из вхождений
        Object.entries(scheme).map(([key, value]) => { // пройти по вхождениям объекта scheme
          if (key === field) return [name, value]; // если ключ равен редактируемому полю - заменить ключ на вводимое имя
          return [key, value]; // иначе вернуть вхождение без изменений
        }),
      );
      setScheme(copy); // обновить схему
    }
  }

  // Удалить ключ
  function deleteField(field) {
    const confirm = window.confirm('Удалить ключ из схемы?');
    if (confirm) { // Если пользователь подтвердил удаление
      // все поля за исключением удаляемого
      const restFields = Object.keys(scheme).filter((row) => row !== field);
      // создать из оставшихся полей новый объект
      const newScheme = restFields.reduce((acc, key) => Object.assign(acc, { [key]: scheme[key] || null }), {});
      setScheme(newScheme);
    } // иначе выход их функции
  }

  // ------------------------Изменение настроек ключей-----------------

  // Изменить настройку ключа схемы
  function changeSetting(field, setting) {
    setScheme({ ...scheme, [field]: { ...scheme[field], ...setting } });
  }

  // Выбор типа
  function chooseType(field, type) {
    // Если тип данных поля не число или массив - switch выключается
    if (type === 'number' || type === 'array') {
      changeSetting(field, { type });
    } else {
      changeSetting(field, {
        type,
        switch: null,
      });
    }
  }

  function chooseTableSorting(field, direction) {
    const copy = { ...scheme };
    Object.keys(copy).forEach((key) => { // пройти по ключам схемы
      const keyData = copy[key]; // настройки ключа
      if (key === field) { // если это редактируемый ключ
        keyData.show_in_table.sorting = direction.value; // установить значение сортировки
        // Иначе если есть ключ show_in_table.sorting - сбросить его
      } else if (keyData?.show_in_table?.sorting) keyData.show_in_table.sorting = null;
    });
    setScheme(copy);
  }

  // Переключить searchable
  function toggleSearchable(field) {
    changeSetting(field, { searchable: !scheme[field].searchable });
  }

  // Переключить switch
  function toggleSwitch(field) {
    // Если switch включается - добавить в него объект со значениями
    if (!scheme[field].switch) {
      if (isQuestionScheme) {
        changeSetting(field, {
          switch: {
            switch_table: null,
            switch_column: null,
          },
        });
      } else {
        changeSetting(field, {
          switch: {
            switch_table: 'kb_themes',
            switch_column: "essence->>'title'",
          },
        });
      }
      // Если switch выключается - сбросить в Null
    } else {
      changeSetting(field, {
        switch: null,
      });
    }
  }

  // Переключить switch_clause
  function toggleSwitchClause(field) {
    // Если switch_clause включается - добавить в него объект со значениями
    if (!scheme[field].switch?.switch_clause) {
      changeSetting(field, {
        switch: {
          ...scheme[field]?.switch,
          switch_clause: {
            clauses: [],
            operator: 'AND',
          },

        },
      });
      // Если switch_clause выключается - сбросить в Null
    } else {
      changeSetting(field, {
        switch: {
          ...scheme[field]?.switch,
          switch_clause: null,
        },
      });
    }
  }

  // Переключить show in table
  function toggleShowTable(field) {
    // Если show_in_table включается - добавить в него объект со значением
    if (!scheme[field].show_in_table) {
      changeSetting(field, {
        show_in_table: {
          order: 0,
        },
      });
      // Если show_in_table выключается - сбросить в false
    } else {
      changeSetting(field, {
        show_in_table: false,
      });
    }
  }

  // Переключить required
  function toggleRequired(field) {
    // Если required включается - включить его и сбросить в null required_clause
    if (!scheme[field].required) {
      changeSetting(field, {
        required: true,
        required_clause: null,
      });
      // Если required выключается - выключить его
    } else {
      changeSetting(field, {
        required: false,
      });
    }
  }

  // Переключить required clause
  function toggleRequiredClause(field) {
    // Если required_clause включается - добавить в него объект со значением
    if (!scheme[field].required_clause) {
      changeSetting(field, {
        required_clause: {
          key: '',
          existence: false,
        },
      });
      // Если required_clause выключается - сбросить в Null
    } else {
      changeSetting(field, {
        required_clause: null,
      });
    }
  }

  // Переключить required clause existence
  function toggleRequiredClauseExistence(field) {
    changeSetting(field, {
      required_clause: {
        ...scheme[field]?.required_clause,
        existence: !scheme[field]?.required_clause?.existence,
      },
    });
  }

  // Переключить параметр parent
  function toggleParent(field) {
    const copy = { ...scheme };
    Object.keys(copy).forEach((key) => { // пройти по ключам схемы
      const keyData = copy[key]; // настройки ключа
      if (key === field) { // если это редактируемый ключ
        keyData.parent = !keyData.parent; // переключить значение на противоположное
        // Иначе если есть ключ parent - выключить его
      } else if (keyData?.parent) keyData.parent = false;
    });
    setScheme(copy);
  }

  // Переключить параметр parent
  function toggleLinkThemes(field) {
    const copy = { ...scheme };
    Object.keys(copy).forEach((key) => { // пройти по ключам схемы
      const keyData = copy[key]; // настройки ключа
      if (key === field) { // если это редактируемый ключ
        keyData.link_kb_themes = !keyData.link_kb_themes; // переключить значение на противоположное
        // Иначе если есть ключ link_kb_themes - выключить его
      } else if (keyData?.link_kb_themes) keyData.link_kb_themes = false;
    });
    setScheme(copy);
  }

  // Переключить зависимость
  function toggleDependence(field) {
    if (!scheme[field]?.dependence) {
      changeSetting(field, {
        dependence: {
          key: '',
          existence: false,
          hide: false,
          reset: false,
        },
      });
    } else changeSetting(field, { dependence: null });
  }

  // Выбрать/сбросить параметр использования значения поля как имени выгружаемого файла
  function toggleDownloadName(field) {
    const copy = { ...scheme };
    Object.keys(copy).forEach((key) => { // пройти по ключам схемы
      const keyData = copy[key]; // настройки ключа
      if (key === field) { // если это редактируемый ключ
        keyData.download_name = !keyData.download_name; // переключить значение на противоположное
        // Иначе если есть ключ download_name - выключить его
      } else if (keyData?.download_name) keyData.download_name = false;
    });
    setScheme(copy);
  }

  if (isLoading) return <Loader />;
  return (
    <div id="scheme" className="upu-card__block">
      {/* Статус схемы */}
      <CardSetting title="Статус">
        <Toggle
          state={String(Boolean(schemeData.active))}
          onClick={() => setSchemeData({ ...schemeData, active: !schemeData.active })}
        />
      </CardSetting>

      {/* Тип схемы */}
      <CardSetting title="Тип схемы" invalid={!schemeData?.type}>
        <Select
          id="scheme__data-scheme-type-select"
          array={schemeTypes}
          onChoose={(type) => setSchemeData({ ...schemeData, type: type.id })}
          defaultValue={schemeTypes.find((item) => item.id === schemeData?.type)?.title || 'Тип схемы'}
        />
      </CardSetting>

      {/* Название схемы */}
      <CardSetting title="Название схемы" invalid={!schemeData?.title}>
        <Input
          placeholder="Название схемы"
          value={schemeData?.title}
          onChange={(e) => setSchemeData({ ...schemeData, title: e.target.value })}
        />
      </CardSetting>

      {/* Настройки поля */}
      {Object.keys(scheme).map((row, index) => {
        const canFieldBeSwitch = scheme[row].type === 'number' || scheme[row].type === 'array';
        return (
          <div key={index} id="scheme__key" className="upu-card__setting">
            {/* Имя ключа */}
            <div className="upu-card__setting">
              <p id="scheme__key-title" className="upu__setting-title">{`${row}:`}</p>
              {editingKey === index ? (
              <Input
                autoFocus // установить фокус на инпут при его появлении
                onBlur={() => setEditingKey(null)}
                placeholder="Ключ"
                value={row}
                onChange={(e) => changeFieldName(row, e.target.value)}
              />) : (
              <div className="upu-card__setting-wrapper">
                <IconButton icon="edit" onClick={() => setEditingKey(index)} />
                <IconButton icon="delete" onClick={() => deleteField(row)} />
              </div>)}
            </div>

            {/* Заголовок поля */}
            <CardSetting title="name:" invalid={!scheme[row]?.name}>
              <Input
                autoFocus={newKey === row}
                placeholder="Заголовок поля"
                value={scheme[row].name}
                onChange={(e) => changeSetting(row, { name: e.target.value })}
              />
            </CardSetting>

            {/* Тип данных */}
            <CardSetting title="type:" invalid={!scheme[row]?.type}>
              <Select
                id={`scheme__data-type-select_${index}`}
                array={dataTypes.map((row) => ({ id: row.id, title: row.name }))}
                onChoose={(type) => chooseType(row, type.title)}
                defaultValue={scheme[row].type || 'Тип данных'}
              />
            </CardSetting>

            {/* Порядок отображение поля в карточке вопроса */}
            <CardSetting title="order:">
              <Input
                type="number"
                min="1"
                placeholder="Порядок в карточке"
                defaultValue={scheme[row]?.order || ''}
                onChange={(e) => changeSetting(row, { order: +e.target.value })}
              />
            </CardSetting>

            {/* Отображения поля в таблице */}
            <CardSetting title="show_in_table">
              <Toggle
                state={String(Boolean(scheme[row].show_in_table))}
                onClick={() => toggleShowTable(row)}
              />

              {scheme[row]?.show_in_table && (// Порядок отображения в таблице
              <CardSetting title="order:" invalid={!scheme[row].show_in_table?.order}>
                <Input
                  type="number"
                  min="1"
                  placeholder="Порядок в таблице"
                  defaultValue={scheme[row].show_in_table?.order || ''}
                  onChange={(e) => changeSetting(row, {
                    show_in_table: {
                      ...scheme[row].show_in_table,
                      order: +e.target.value,
                    },
                  })}
                />
              </CardSetting>)}

              {scheme[row]?.show_in_table && (// Порядок отображения в таблице
              <CardSetting title="sorting:">
                <Select
                  id={`scheme__choose-sorting_${row}`}
                  array={sortingOptions}
                  onChoose={(direction) => chooseTableSorting(row, direction)}
                  defaultValue={scheme[row].show_in_table?.sorting || 'Выкл'}
                />
              </CardSetting>)}
            </CardSetting>

            {/* Использоваие имени поля при выгрузке вопроса */}
            <CardSetting title="download_name">
              <Toggle
                state={String(Boolean(scheme[row].download_name))}
                onClick={() => toggleDownloadName(row)}
              />
            </CardSetting>

            {isQuestionScheme && (// только для схемы вопроса
            <ToggleSetting // Скрытие поля в пользовательском интерфейсе
              field={row}
              setting="user_hide"
              value={scheme[row]?.user_hide}
              handler={() => changeSetting(row, { user_hide: !scheme[row]?.user_hide })}
            />)}

            <ToggleSetting // Поиск в данном поле
              field={row}
              setting="searchable"
              value={scheme[row]?.searchable}
              handler={toggleSearchable}
            />

            {isThemeScheme && (// только для схемы темы
            <ToggleSetting // Ключ является родительским?
              field={row}
              setting="parent"
              value={scheme[row]?.parent}
              handler={toggleParent}
            />)}

            {isQuestionScheme && (// только для схемы вопроса
            <ToggleSetting // Ключ для связи вопроса и темы
              field={row}
              setting="link_kb_themes"
              value={scheme[row]?.link_kb_themes}
              handler={toggleLinkThemes}
            />)}

            <ToggleSetting // Обязательность заполнения поля
              field={row}
              setting="required"
              value={scheme[row]?.required}
              handler={toggleRequired}
            />

            {!scheme[row].required && (// если required выключен
            <ToggleSetting // Условие обязательности заполнения поля
              field={row}
              setting="required_clause"
              value={scheme[row]?.required_clause}
              handler={toggleRequiredClause}
            >
              {/* Ключ для определения условия */}
              {scheme[row]?.required_clause && (// если required_clause включен
              <CardSetting title="key:" invalid={!scheme[row]?.required_clause?.key}>
                <Input
                  placeholder="имя ключа"
                  defaultValue={scheme[row]?.required_clause?.key}
                  onChange={(e) => changeSetting(row, {
                    required_clause: {
                      ...scheme[row].required_clause,
                      key: e.target.value,
                    },
                  })}
                />
              </CardSetting>)}

              {scheme[row]?.required_clause && (
              <ToggleSetting // Обязательность заполнения поля
                field={row}
                setting="existence"
                value={scheme[row]?.required_clause?.existence}
                handler={toggleRequiredClauseExistence}
              />)}

            </ToggleSetting>)}

            <ToggleSetting // Зависимость от другого поля
              field={row}
              setting="dependence"
              value={scheme[row]?.dependence}
              handler={toggleDependence}
            >
              {/* Наименование ключа от которого определяется зависимость */}
              {scheme[row]?.dependence && (// если dependence включен
              <CardSetting title="key:" invalid={!scheme[row]?.dependence?.key}>
                <Input
                  placeholder="имя ключа"
                  defaultValue={scheme[row]?.dependence?.key}
                  onChange={(e) => changeSetting(row, {
                    dependence: {
                      ...scheme[row].dependence,
                      key: e.target.value,
                    },
                  })}
                />
              </CardSetting>)}

              {scheme[row]?.dependence && (
              <ToggleSetting // Наличие значения ключа от которого определяется зависимость
                field={row}
                setting="existence"
                value={scheme[row]?.dependence?.existence}
                handler={(row) => changeSetting(row, {
                  dependence: {
                    ...scheme[row].dependence,
                    existence: !scheme[row]?.dependence?.existence,
                  },
                })}
              />)}

              {scheme[row]?.dependence && (
              <ToggleSetting // Обязательность заполнения поля
                field={row}
                setting="hide"
                value={scheme[row]?.dependence?.hide}
                handler={(row) => changeSetting(row, {
                  dependence: {
                    ...scheme[row].dependence,
                    hide: !scheme[row]?.dependence?.hide,
                  },
                })}
              />)}

              {scheme[row]?.dependence && (
              <ToggleSetting // Обязательность заполнения поля
                field={row}
                setting="reset"
                value={scheme[row]?.dependence?.reset}
                handler={(row) => changeSetting(row, {
                  dependence: {
                    ...scheme[row].dependence,
                    reset: !scheme[row]?.dependence?.reset,
                  },
                })}
              />)}
            </ToggleSetting>

            {canFieldBeSwitch && (// только для типов number и array
            <ToggleSetting // Подмена данных ключа
              field={row}
              setting="switch"
              value={scheme[row]?.switch}
              handler={toggleSwitch}
            >
              {/* Таблица для подмены данных */}
              {scheme[row].switch && (
              <CardSetting title="switch_table:" invalid={!scheme[row].switch?.switch_table}>
                <Input
                  disabled={!isQuestionScheme}
                  placeholder="таблица"
                  defaultValue={scheme[row].switch?.switch_table}
                  onChange={(e) => changeSetting(row, {
                    switch: {
                      ...scheme[row].switch,
                      switch_table: e.target.value,
                    },
                  })}
                />
              </CardSetting>)}

              {/* Колонка для подмены данных (только для схемы вопроса) */}
              {scheme[row].switch && (
              <CardSetting title="switch_column:" invalid={!scheme[row].switch?.switch_column}>
                <Input
                  disabled={!isQuestionScheme}
                  placeholder="поле таблицы"
                  defaultValue={scheme[row].switch?.switch_column}
                  onChange={(e) => changeSetting(row, {
                    switch: {
                      ...scheme[row].switch,
                      switch_column: e.target.value,
                    },
                  })}
                />
              </CardSetting>)}

              {/* Условие для подмены данных (только для схемы вопроса) */}
              {scheme[row].switch && (
              <CardSetting title="switch_clause:">
                <Toggle
                  state={String(Boolean(scheme[row].switch?.switch_clause))}
                  onClick={() => toggleSwitchClause(row)}
                />

                {/* Условия для подмены данных */}
                {scheme[row].switch?.switch_clause && (
                <CardSetting title="clauses:" invalid={!scheme[row].switch?.switch_clause?.clauses}>
                  <Tags
                    array={scheme[row].switch?.switch_clause?.clauses}
                    onChange={(value) => changeSetting(row, {
                      switch: {
                        ...scheme[row].switch,
                        switch_clause: {
                          ...scheme[row]?.switch?.switch_clause,
                          clauses: value,
                        },
                      },
                    })}
                  />
                </CardSetting>)}

                {/* Оператор условий для подмены данных */}
                {scheme[row].switch?.switch_clause && (
                <CardSetting title="operator:">
                  <Select
                    id="scheme__switch-clause-operator-select"
                    array={operators}
                    onChoose={(operator) => changeSetting(row, {
                      switch: {
                        ...scheme[row].switch,
                        switch_clause: {
                          ...scheme[row]?.switch?.switch_clause,
                          operator: operator.title,
                        },
                      },
                    })}
                    defaultValue={scheme[row].switch?.switch_clause?.operator}
                  />
                </CardSetting>)}
              </CardSetting>)}

              {/* Родительский ключ для создания иерархии */}
              {scheme[row].switch && (
              <CardSetting title="parent_key:">
                <Input
                  placeholder="Ключ родителя"
                  defaultValue={scheme[row].switch?.parent_key}
                  onChange={(e) => changeSetting(row, {
                    switch: {
                      ...scheme[row].switch,
                      parent_key: e.target.value,
                    },
                  })}
                />
              </CardSetting>)}
            </ToggleSetting>)}

            {/* Автозамена из указанного поля при отсутствии значения */}
            <CardSetting title="auto_replace:">
              <Input
                placeholder="Замена из поля"
                defaultValue={scheme[row]?.auto_replace || ''}
                onChange={(e) => changeSetting(row, { auto_replace: e.target.value })}
              />
            </CardSetting>

            <ToggleSetting // Обязательность заполнения поля
              field={row}
              setting="inherit"
              value={scheme[row]?.inherit}
              handler={() => changeSetting(row, { inherit: !scheme[row]?.inherit })}
            />
          </div>
        );
      })}
      <CardSetting title="Удалить схему">
        <IconButton icon="delete" theme="red" onClick={deleteScheme} />
      </CardSetting>
      <div id="scheme__controls" className="upu-card__setting">
        <p id="scheme__version" className="upu__setting-title">{`Version: ${schemeData?.version}`}</p>
      </div>
    </div>
  );
}

export default Settings;
