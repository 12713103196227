export const headers = {
  permissions: [
    { title: 'Наименование', key: 'title' },
    { title: 'Доступы', key: 'values' },
  ],
};

export const rowNames = {
  asset_editing_groups: 'Доступ к редактированию активов',
  coordinators: 'Координаторы',
  admins: 'Администраторы',
};
