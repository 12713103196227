import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { setOperInfo } from '../../../../utils/functions/others';
import { catchHandler } from '../../../../utils/error_handling/error_handling';
import { clientSendData } from '../../../../utils/functions/requests';
import { convertTicketId } from '../../common';
import TicketProcess from './TicketProcess/TicketProcess';
import CardHeader from '../../../../components/Card/CardHeader';
import CardBody from '../../../../components/Card/CardBody';
import TabPanel from '../../../../components/UI/TabPanel';
import History from './History';
import Ticket from './Ticket';
import Tasks from './Tasks/Tasks';
import Card from '../../../../components/Card/Card';

/**
  * @component ХелпДеск - заявки - карточка заявки
  * @prop {setShowCard} function - обновление состояния отображения карточки (открыть/закрыть)
  * @prop {refreshTable} function - обновление данных таблицы
*/
function TicketCard(props) {
  const { setShowCard, refreshTable } = props;

  const tabs = ['Запрос', 'Работа по запросу', 'Наряды', 'История']; // вкладки
  const [searchParams, setSearchParams] = useSearchParams(); // параметры поиска из url
  const [ticketID, setTicketID] = useState(null); // id открытой карточки
  const [isLoading, setIsLoading] = useState(false); // состояние выбора вкладки
  const [choice, setChoice] = useState(); // состояние выбора вкладки
  const content = {
    'Работа по запросу': <TicketProcess ticketID={ticketID} />,
    Запрос: <Ticket ticketID={ticketID} refreshTable={refreshTable} closeHandler={closeHandler} />,
    Наряды: <Tasks ticketID={ticketID} />,
    История: <History subject_id={ticketID} type="1" />,
  };

  useEffect(() => {
    const ticketFromUrl = searchParams?.get('ticket'); // взять id заявки из url
    if (ticketFromUrl) { // если id есть
      const convertedId = convertTicketId(ticketFromUrl); // конвертировать в число (как id в БД)
      if (convertedId) {
        setTicketID(convertedId); // запросить информацию по заявке
        setOperInfo({ subject_id: convertedId }); // записываем id заявки в оперативную информацию
        checkPerformerTasks(convertedId);
      } else closeHandler();
    }
  }, [searchParams]);

  // Проверка наличия активных нарядов на сотруднике техподдержки или его группе
  async function checkPerformerTasks(ticket_id) {
    try {
      const reqData = { type: 'checkPerformerTasks', ticket_id };
      setIsLoading(true);
      const result = await clientSendData('POST', '/check_performer_tasks', reqData);
      // Если есть активные наряды - выбрать вкладку нарядов
      if (result?.success && result.count > 0) setChoice(tabs[2]);
    } catch (error) {
      catchHandler(error, 'checkPerformerTasks');
    } finally {
      setIsLoading(false);
    }
  }

  // закрыть карточку
  function closeHandler() {
    setOperInfo({ subject_id: null }); // сброс id категории в оперативной информацию
    setSearchParams(''); // сброс параметром url
    setShowCard(false); // закрытие карточки
    document.title = 'UNIPLACE';
  }

  // обработка выбора вкладки
  function choiceHandler(tab) {
    setChoice(tab); // установить выбор
  }

  return (
    <Card id="helpdesk__ticket-card" setShow={closeHandler}>
      <CardHeader>
        <TabPanel tabs={tabs} choice={choice} choiceHandler={choiceHandler} />
      </CardHeader>
      <CardBody>
        {content[choice]}
      </CardBody>
    </Card>
  );
}

export default TicketCard;
