import React from 'react';
import { QRCodeCanvas } from 'qrcode.react';

function VCardPicture(props) {
  const {
    position, surname, givenname, link, mobile, email, avatar,
  } = props;
  return (
  // <ServiceBody>
      <div id="vcard-wrapper">
        <div className="user-page__avatar-wrapper">
          <img
            className="user-page__user-avatar"
            src={avatar || '../../icons/user_color.png'}
            alt="avatar"
          />
        </div>

        <div className="vcard-fio">
          <p>
            {surname}
            <br />
            {' '}
            {givenname}
          </p>
        </div>

        <div className="vcard-info">
          {/*   <p className="hint">Должность | Направление, отрасль</p> */}
          <p className="vcard-position">
          {position}
          </p>
          <p className="vcard-org">Юникон АО</p>
        </div>

        <div className="qr-wrapper">
          <QRCodeCanvas
            className="qr"
          //   onClick={() => generateImg()}
            value={link}
            size={140}
            bgColor="#ffffff"
            fgColor="#000000"
            level="H"
            includeMargin={false}
          />
        </div>

        <div className="vcard-contacts">
          <p>{mobile}</p>
          <p>{email}</p>
          <p><a className="vcard-contacts" href="https://www.unicon.ru">www.unicon.ru</a></p>
        </div>

        <div className="logo">
          <img src="../../../unicon/unicon_logo_no_sphere.png" alt="logo" />
        </div>
      </div>
  // </ServiceBody>
  );
}

export default VCardPicture;
