import React, { useState, useEffect } from 'react';
import CardBody from '../../../../components/Card/CardBody';
import Button from '../../../../components/UI/Button/Button';
import Card from '../../../../components/Card/Card';
import CardHeader from '../../../../components/Card/CardHeader';
import CardFooter from '../../../../components/Card/CardFooter';
import CardSetting from '../../../../components/Card/CardSetting';
import CheckBox from '../../../../components/UI/CheckBox';
import Select from '../../../../components/UI/Select';
import { clientSendData } from '../../../../utils/functions/requests';
import { catchHandler } from '../../../../utils/error_handling/error_handling';
import RadioButton from '../../../../components/UI/RadioButton';

function EditQuestionCard(props) {
  const {
    setShowQuestionCard,
    accessQuestionId,
    closeHandler,
    currentQuestionnaire,
    questions,
    setQuestions,
    setCurrentQuestionnaire,
    saveQuestionnaire,
  } = props;
  const [isLoading, setIsLoading] = useState(false); // Состояние загрузки
  const [isLast, setIsLast] = useState(null);
  const [allAnswers, setAllAnswers] = useState([]);
  const [answers, setAnswers] = useState([]); // ответы в выбранном вопросе
  const [skipQuestion, setSkipQuestion] = useState({});
  const [skipQuestionAnswers, setSkipQuestionAnswers] = useState([]);

  useEffect(() => {
  //  chooseQuestions();
    fetchAllAnswers();
  }, []);

  // сделать вопрос последним
  function toggleLast(id) {
    if (isLast === id) {
      setIsLast(null);
    } else {
      setIsLast(id);
      setAnswers(allAnswers.filter((item) => item.question_id === id));
    }
  }

  // развернуть графу "пропустить вопрос"
  /*  function toggleSkipQuestion(e) {
    const skippedQuestion = { id: '', title: '' };
    skippedQuestion.title = e.title;
    skippedQuestion.id = e.id;
    if (skipQuestion.id === e.id) {
      setSkipQuestion(null);
    } else {
      setSkipQuestion((state) => ({
        ...state, ...skippedQuestion,
      }));
      setSkipQuestionAnswers(allAnswers.filter((item) => item.question_id === e.id));
    }
  } */

  function toggleSkipQuestion(e, qId) {
    const skippedQuestion = {};
    skippedQuestion[qId] = e.title;
    if (skipQuestion.id === e.id) {
      setSkipQuestion(null);
    } else {
      setSkipQuestion((state) => ({
        ...state, ...skippedQuestion,
      }));
      setSkipQuestionAnswers({ ...skipQuestionAnswers, [qId]: { qId: e.id, array: allAnswers.filter((item) => item.question_id === e.id) } });
    }
  }

  // получить все ответы
  async function fetchAllAnswers() {
    try {
      const reqData = {
        type: 'fetchAllAnswers',
      };
      setIsLoading(true); // состояние - загружается
      const result = await clientSendData('POST', '/fetch_all_answers', reqData);// запрос в БД
      if (result) {
        setAllAnswers(result.data);
      }
      setIsLoading(false); // загрузка завершена
    } catch (error) {
      catchHandler(error, 'getQuestions');
      setIsLoading(false); // загрузка завершена
    }
  }

  // установить ответ, после которого вопрос становится завершающим в опросе
  function breakAnswerSetter(q, a) {
    const temp_questions = questions.map((el) => (el.id === q ? { ...el, break: a.id } : el));
    setQuestions(temp_questions);
    setCurrentQuestionnaire({
      ...currentQuestionnaire,
      questions: temp_questions, // он добавляется
    });
  }
  function currentChoiceHandler(answerId) {
    // поиск текущего ответа в массиве ответов
    const findAnswer = answers.find((a) => a.answer_id === answerId);
    if (findAnswer) return true;
    return false;
  }

  // установить ответ, после которого пропускается вопрос
  function dependenceAnswerSetter(e, q) {
    const temp_questions = questions.map((el) => (el.id === skipQuestionAnswers[q].qId ? { ...el, dependence: q, dependence_answer: e.target.id } : el));
    setQuestions(temp_questions);
    setCurrentQuestionnaire({
      ...currentQuestionnaire,
      questions: temp_questions, // он добавляется
    });
    // console.log(currentQuestionnaire.questions);
  }

  return (
    <Card id="edit__question-card" setShow={setShowQuestionCard}>
        <CardHeader />
        <CardBody>

        <CardSetting title="Комментарий">
        <CheckBox
          id={`questionaire-card__checkbox_${accessQuestionId}`}

        />
        </CardSetting>

        <CardSetting title="Сделать вопрос завершающим">
        <CheckBox
          onClick={() => toggleLast(accessQuestionId)}
          value={isLast === accessQuestionId}
        />
        </CardSetting>
        {isLast !== null && isLast === accessQuestionId && <div className="questions-card__question-choose">
          {answers.map((a) => <div key={a.id}>
            <CardSetting>
{a.answer}
<RadioButton
  id={a.id}
  onChange={() => breakAnswerSetter(accessQuestionId, a)}
 // defaultChecked={() => currentChoiceHandler(a.id)}
  group={`answer-${isLast}`}
/>
            </CardSetting>
                              </div>)}
                                                           </div>}

        <CardSetting title="Пропустить вопрос">
        <Select
          defaultValue={skipQuestion[accessQuestionId]}
          id={accessQuestionId}
          array={questions}
          onChoose={(e) => { toggleSkipQuestion(e, accessQuestionId); }}
        />
        </CardSetting>
        {skipQuestion[accessQuestionId] && (<div className="questions-card__question-choose">
          {skipQuestionAnswers[accessQuestionId].array.map((sa) => <CardSetting key={sa.id}>
              {sa.answer}
              <RadioButton
                id={sa.id}
                // value={Object.values(radioButtonSkipAnswerArray).includes(sa.id)}
                onChange={(e) => dependenceAnswerSetter(e, accessQuestionId)}
                defaultChecked={() => currentChoiceHandler(sa.id)}
                group={`skip-answer-${accessQuestionId}`}
              />
                                                                   </CardSetting>)}
                                            </div>)
        }

        </CardBody>
        <CardFooter>
            <Button onClick={closeHandler}>Отменить</Button>
            <Button onClick={saveQuestionnaire}>Сохранить</Button>
        </CardFooter>
    </Card>
  );
}

export default EditQuestionCard;
