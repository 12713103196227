import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { catchHandler } from '../../../../../utils/error_handling/error_handling';
import { alertFunction } from '../../../../../utils/functions/alertFunction';
import { setOperInfo, log } from '../../../../../utils/functions/others';
import { clientSendData } from '../../../../../utils/functions/requests';
import { autoReplaceHandler, validateObject, сreateNewObject } from '../../../Common/functions';
import Card from '../../../../../components/Card/Card';
import CardHeader from '../../../../../components/Card/CardHeader';
import CardBody from '../../../../../components/Card/CardBody';
import CardFooter from '../../../../../components/Card/CardFooter';
import TabPanel from '../../../../../components/UI/TabPanel';
import Button from '../../../../../components/UI/Button/Button';
import History from '../../../Common/History';
import Settings from './Settings';

/**
* @component Управление Базой знаний - Темы - Карточка темы
* @prop {id} function - id выбранной темы
* @prop {setShowCard} function - Обновление состояния отображения карточки (открыть/закрыть)
* @prop {getThemesKb} function - Получение тем в выбранном подразделении
* @prop {parentTheme} object - Объект родительской темы (при вызове карточки из карточки другой темы)
*/

function ThemeCard(props) {
  const {
    id, setShowCard, getThemesKb, parentTheme,
  } = props;

  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [choice, setChoice] = useState(''); // состояние выбора вкладки
  const [theme, setTheme] = useState(null); // текущая тема
  const [themeInitial, setThemeInitial] = useState(null); // текущая тема (исходные данные)
  const currentDivision = useSelector((state) => state.kb.current_division); // выбранное подразделение
  const scheme = useSelector((state) => state.kb.active_scheme.theme?.scheme); // активная схема темы
  const themeTypeId = useSelector((state) => state.kb.essence_types_id.theme); // id типа сущности темы
  const isNewTheme = id === 'new'; // Тема создаётся?
  const tabs = ['Настройки', !isNewTheme && 'История']; // вкладки

  // Отменить редактирование или создание и закрыть карточку
  function closeHandler() {
    setOperInfo({ subject_id: null }); // сброс id категории в оперативной информацию
    setShowCard(false);
    log({ message: `Пользователь закрыл карточку темы id: ${id}` });
  }

  // Обработчик выбора вкладки
  function choiceHandler(tab) {
    setChoice(tab); // переключение вкладки
  }

  useEffect(() => {
    awaitRequests();
  }, []);

  // Ожидание выполнения необходимых запросов
  async function awaitRequests() {
    try {
      setOperInfo({ subject_id: id }); // запись id темы в оперативную информацию
      setIsLoading(true); // состояние - загружается
      if (id === 'new') { // если тема создаётся
        let newTheme = { id: 'new' };
        // если в карточку передана родительская тема
        if (parentTheme) newTheme = сreateNewObject(parentTheme, 'theme', 'theme');
        setTheme(newTheme); // обновить состояние темы
        setIsLoading(false); // загрузка завершена
      } else {
        await getThemeKb(); // обновить темы в таблице
        setIsLoading(false); // загрузка завершена
      }
    } catch (error) {
      setIsLoading(false); // загрузка завершена
      catchHandler(error, 'awaitRequests');
    }
  }

  // // Проверить имя темы
  // async function checkDoubles() {
  //     try {
  //         const reqData = {
  //             type: "checkDoubles",
  //             table: "kb_themes",
  //             column: "essence->>'title'",
  //             exclude_id: theme.id,
  //             value: theme?.essence?.title
  //         }

  //         setIsLoading(true) // состояние - загружается
  //         const result = await clientSendData("POST", "/check_doubles", reqData)

  //         if (result === "bad_request") { // если некорректный запрос
  //             alertFunction("bad_request", "clientPost") // уведомление
  //             setIsLoading(false) // загрузка завершена
  //         } else {
  //             setIsLoading(false) // загрузка завершена
  //             return result
  //         }
  //     } catch(error) {
  //         catchHandler(error, "checkThemeName")
  //         setIsLoading(false)
  //     }
  // }

  // Получить данные темы
  async function getThemeKb() {
    try {
      const reqData = {
        type: 'getThemeKb',
        theme_id: id,
        scheme, // Текущая схема
      };
      setIsLoading(true); // состояние - загружается
      const result = await clientSendData('POST', '/get_theme_kb', reqData);

      if (result === 'bad_request') { // если некорректный запрос
        alertFunction('bad_request', 'clientPost'); // уведомление
        setIsLoading(false); // загрузка завершена
      } else {
        setTheme(result);
        setThemeInitial(result);
        setIsLoading(false); // загрузка завершена
      }
    } catch (error) {
      catchHandler(error, 'getThemeKb');
      setIsLoading(false);
    }
  }

  // Обработчик кнопки "Сохранить"
  async function saveHandler() {
    const action = theme.id === 'new' ? 'create' : 'edit'; // action  определяется исходя из id темы
    // const isExistDouble = await checkDoubles() // поиск тем с таким же именем
    // if (isExistDouble) alertFunction("theme_name_match", "clientPost")
    // else
    changeTheme(action);
    log({ message: `Пользователь в карточке темы id: ${id} нажал кнопку "Сохранить"` });
  }

  // Подготовка темы перед отправкой в бд
  function prepareTheme(theme) {
    let copy = { ...theme };

    // 1) обработка авто замен
    copy = autoReplaceHandler(scheme, copy);

    // 2) Экранировать одинарные кавычки
    Object.keys(scheme).forEach((key) => { // пройти по ключам схемы
      const keyData = scheme[key]; // настройки ключа
      if (keyData?.type === 'html') { // если тип данных html
        // Если html поле содержит данные - экранировать одинарные кавычки, иначе вернуть null
        const jsonData = copy[key] ? copy[key]?.replaceAll("'", "''") : null;
        copy[key] = jsonData; // обновить данные объекта
      }
    });

    return copy;
  }

  // Сохранить изменения темы
  // action - create/edit/delete
  async function changeTheme(action, extMessage) {
    try {
      // Если не пройдена валидация на основании действия - уведомление
      if (!validateObject(action, scheme, theme)) alertFunction('required_fields', 'clientPost');
      else {
        let message = extMessage || '';
        if (action === 'create') message += 'Создать тему?';
        if (action === 'edit') message += 'Сохранить изменения?';
        if (action === 'delete') message += 'Удалить тему?';
        const confirm = window.confirm(message); // подтверждение

        if (confirm) {
          const reqData = {
            type: 'editThemeKb',
            theme: prepareTheme(theme),
            action,
            division_id: currentDivision.id,
            scheme,
          };

          setIsLoading(true); // состояние - загружается
          const result = await clientSendData('POST', '/edit_theme_kb', reqData);
          if (result === 'success') { // если успех
            alertFunction('save_settings', 'clientPost'); // уведомление - настройки сохранены
            if (action === 'edit') await getThemeKb(); // обновить информацию о теме
            if (getThemesKb) await getThemesKb(); // обновить информацию о темах
            if (action === 'create' || action === 'delete') setShowCard(false); // если тема создается или удаляется - закрыть карточку
          }
          setIsLoading(false); // загрузка завершена
        }
      }
    } catch (error) {
      catchHandler(error, 'changeTheme');
      setIsLoading(false);
    }
  }

  return (
    <Card setShow={setShowCard} loading={isLoading} hideCloseButton>
      <CardHeader>
        <TabPanel tabs={tabs} choice={choice} choiceHandler={choiceHandler} />
      </CardHeader>

      <CardBody>
        {/* Вкладка настроек */}
        {choice === 'Настройки' && (
        <Settings
          theme={theme}
          initial_object={themeInitial}
          setTheme={setTheme}
          changeTheme={changeTheme}
        />)}

        {/* Вкладка истории */}
        {choice === 'История' && (
        <History
          id={id}
          type={themeTypeId}
        />)}

      </CardBody>

      <CardFooter>
        <Button onClick={closeHandler}>Закрыть</Button>
        <Button onClick={saveHandler}>Сохранить</Button>
      </CardFooter>
    </Card>
  );
}

export default ThemeCard;
