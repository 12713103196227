import React, { useEffect, useState } from 'react';
import { alertFunction } from '../../../../utils/functions/alertFunction';
import { catchHandler } from '../../../../utils/error_handling/error_handling';
import { clientSendData } from '../../../../utils/functions/requests';
import { searchTicketData, convertTicketId } from '../../common';
import CardHeader from '../../../../components/Card/CardHeader';
import CardBody from '../../../../components/Card/CardBody';
import Card from '../../../../components/Card/Card';
import CardSetting from '../../../../components/Card/CardSetting';
import Input from '../../../../components/UI/Input';
import TextArea from '../../../../components/UI/TextArea';
import Select from '../../../../components/UI/Select';
import InputSearch from '../../../../components/UI/InputSearch';
import CardFooter from '../../../../components/Card/CardFooter';
import Button from '../../../../components/UI/Button/Button';

/**
  * @component ХелпДеск - заявки - карточка создания заявки
  * @prop {setShowCard} function - обновление состояния отображения карточки (открыть/закрыть)
  * @prop {refreshTable} function - обновление данных таблицы
*/
function NewTicketCard(props) {
  const { setShowCard, refreshTable } = props;

  const [ticket, setTicket] = useState({}); // объект с данными заявки
  const [isLoading, setIsLoading] = useState(false); // состояние выбора вкладки
  const [isSearching, setIsSearching] = useState(false); // состояние загрузки при поиске
  const [dataInfo, setDataInfo] = useState({});
  const [additionalInfo, setAdditionalInfo] = useState({ // дополнительная информация
    statuses: [], // типы статусов
    priorities: [], // приоритеты заявок
    categories: [], // категории
    performers: [], // исполнители
    groups: [], // группы исполнителей
    initiator: [], // инициаторы (при поиске)
    asset: [], // активы (при поиске)
  });

  useEffect(() => {
    getCommonTicketData();
  }, []);

  async function getCommonTicketData() {
    try {
      setIsLoading(true);
      const reqData = { type: 'getCommonTicketData' };
      const result = await clientSendData('POST', '/get_common_ticket_data', reqData);
      if (result?.success) {
        setAdditionalInfo((state) => ({ ...state, ...result.data }));
        setDataInfo((state) => ({ ...state, ...result.data }));
        const firstStatus = result.data.statuses[0];
        const firstPriority = result.data.priorities[0];
        setTicket({
          status_id: firstStatus?.id,
          status: firstStatus?.title,
          priority_id: firstPriority?.id,
          priority: firstPriority?.title,
        });
      }
    } catch (error) {
      catchHandler(error, 'getCommonTicketData');
    } finally {
      setIsLoading(false);
    }
  }

  async function searchWrapper(e, field) {
    try {
      setIsSearching(true); // поиск выполняется
      if (e.target.value.length === 0) setTicket((prev) => ({ ...prev, [`${field}_id`]: null, [field]: null }));
      const result = await searchTicketData(e.target.value, field); // получение данных
      setAdditionalInfo((state) => ({ ...state, [field]: result })); // обновление состояния по переданному ключу
    } catch (error) {
      catchHandler(error, 'searchWrapper');
    } finally {
      setIsSearching(false);
    }
  }

  async function createTicket() {
    try {
      const validateTicket = ['status_id', 'priority_id', 'initiator_id', 'title', 'treatment_id'].every((key) => ticket[key]);
      if (validateTicket) {
        setIsLoading(true);
        const reqData = { type: 'createTicket', ticket };
        const result = await clientSendData('POST', '/create_ticket/manual', reqData);
        if (result?.success) {
          await refreshTable();
          await alertFunction('ticket_created', 'clientPost');
          goToUrl(result.data);
          closeHandler();
        }
      } else {
        await alertFunction('required_fields', 'clientPost');
      }
    } catch (error) {
      catchHandler(error, 'getCommonTicketData');
    } finally {
      setIsLoading(false);
    }
  }

  function chooseCategory(category) {
    setTicket((state) => ({
      ...state,
      category_id: category.id,
      category: category.title,
      group_id: category.group_id,
      group: category.group_title,
      performer_id: null,
      performer: null,
    }));
    setDataInfo((state) => ({
      ...state,
      groups: additionalInfo.groups.filter((el) => el.id === category.group_id),
      performers: additionalInfo.performers.filter((el) => el.group_id.includes(category.group_id)),
    }));
  }

  function chooseGroup(group) {
    const firstCategory = additionalInfo.categories.find((item) => item.group_id === group.id);
    setTicket((state) => ({
      ...state,
      category_id: firstCategory.id,
      category: firstCategory.title,
      group_id: group.id,
      group: group.title,
    }));
    setDataInfo((state) => ({
      ...state,
      performers: additionalInfo.performers.filter((el) => el.group_id.includes(group.id)),
    }));
  }

  function choosePerformer(performer) {
    const firstCategory = additionalInfo.categories.find((item) => performer.group_id.includes(item.group_id));
    setTicket((state) => ({
      ...state,
      performer_id: performer.id,
      performer: performer.title,
      group_id: performer.group_id[0],
      group: performer.group_title[0],
    }));
    setDataInfo((state) => ({
      ...state,
      groups: additionalInfo.groups.filter((el) => performer.group_id.includes(el.id)),
    }));
  }

  // закрыть карточку
  function closeHandler() {
    setShowCard(false); // закрытие карточки
  }

  function goToUrl(ticketId) {
    const link = document.createElement('a');
    link.href = `?ticket=${convertTicketId(ticketId)}`;
    link.click();
  }

  return (
    <Card id="helpdesk__new-ticket-card" setShow={closeHandler} loading={isLoading}>
      <CardHeader />
      <CardBody>
        {/* -----------ТИП ОБРАЩЕНИЯ----------- */}
        <CardSetting title="Тип обращения:" invalid={!ticket.treatment_id}>
          <Select
            id="helpdesk__new-ticket-type-of-treatment"
            array={additionalInfo.treatments}
            onChoose={(treatment) => setTicket((state) => ({ ...state, treatment_id: treatment.id, treatment: treatment.title }))}
            defaultValue={ticket.treatment || 'Тип обращения'}
            loading={isSearching}
          />
        </CardSetting>
        {/* -----------СТАТУС----------- */}
        {/* <CardSetting title="Статус:" invalid={!ticket.status_id}>
          <Select
            id="helpdesk__new-ticket-status"
            array={additionalInfo.statuses}
            onChoose={(status) => setTicket((state) => ({ ...state, status_id: status.id, status: status.title }))}
            defaultValue={ticket.status || 'Статус'}
            loading={isSearching}
          />
        </CardSetting> */}
        {/* -----------ПРИОРИТЕТ----------- */}
        {/* <CardSetting title="Приоритет:" invalid={!ticket.priority_id}>
          <Select
            id="helpdesk__new-ticket-choose-priority"
            array={additionalInfo.priorities}
            onChoose={(priority) => setTicket((state) => ({ ...state, priority_id: priority.id, priority: priority.title }))}
            defaultValue={ticket.priority || 'Приоритет'}
            loading={isSearching}
          />
        </CardSetting> */}
        {/* -----------КАТЕГОРИЯ----------- */}
        {/* <CardSetting title="Сервис:">
          <Select
            id="helpdesk__new-ticket-choose-category"
            array={additionalInfo.categories}
            onChoose={chooseCategory}
            defaultValue={ticket.category}
            loading={isSearching}
            search
          />
        </CardSetting> */}
        {/* -----------ГРУППА----------- */}
        {/* <CardSetting title="Группа:">
          <Select
            id="helpdesk__new-ticket-choose-group"
            array={dataInfo.groups}
            onChoose={chooseGroup}
            defaultValue={ticket.group}
            loading={isSearching}
          />
        </CardSetting> */}
        {/* -----------ИСПОЛНИТЕЛЬ----------- */}
        {/* <CardSetting title="Исполнитель:">
          <Select
            id="helpdesk__new-ticket-choose-performer"
            array={dataInfo.performers}
            onChoose={choosePerformer}
            defaultValue={ticket.performer}
            loading={isSearching}
          />
        </CardSetting> */}
        {/* -----------ИНИЦИАТОР----------- */}
        <CardSetting title="Инициатор:" invalid={!ticket.initiator_id}>
          <InputSearch
            id="helpdesk__new-ticket-search-initiator"
            array={additionalInfo.initiator}
            onSearch={(e) => searchWrapper(e, 'initiator')}
            onChoose={(initiator) => setTicket((state) => ({ ...state, initiator_id: initiator.id, initiator: initiator.title }))}
            placeholder="ФИ инициатора запроса"
            defaultValue={ticket.initiator}
            loading={isSearching}
          />
        </CardSetting>
        {/* -----------АКТИВ----------- */}
        {/* <CardSetting title="Актив:">
          <InputSearch
            id="helpdesk__new-ticket-search-asset"
            array={additionalInfo.asset}
            onSearch={(e) => searchWrapper(e, 'asset')}
            onChoose={() => {}}
            placeholder="Название актива"
            defaultValue={ticket.asset}
            loading={isSearching}
          />
        </CardSetting> */}
        {/* -----------ТЕМА----------- */}
        <CardSetting title="Тема" invalid={!ticket.title}>
          <Input
            placeholder="Тема запроса"
            defaultValue={ticket.title}
            onChange={(e) => setTicket((state) => ({ ...state, title: e.target.value }))}
            disabled={isSearching}
          />
        </CardSetting>
        {/* -----------СООБЩЕНИЕ----------- */}
        <CardSetting>
          <TextArea
            placeholder="Сообщение"
            defaultValue={ticket.content}
            onChange={(e) => setTicket((state) => ({ ...state, content: e.target.value }))}
            disabled={isSearching}
          />
        </CardSetting>
      </CardBody>
      <CardFooter>
        <Button onClick={createTicket}>Создать</Button>
        <Button onClick={closeHandler}>Закрыть</Button>
      </CardFooter>
    </Card>
  );
}

export default NewTicketCard;
