/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useSelector } from 'react-redux';
import { createAction } from '../../../../utils/redux/store';
import { catchHandler } from '../../../../utils/error_handling/error_handling';
import { alertFunction } from '../../../../utils/functions/alertFunction';
import { backlightHandler, log } from '../../../../utils/functions/others';
import { clientSendData, clientSendFile, domain } from '../../../../utils/functions/requests';
import InputSearch from '../../../../components/UI/InputSearch';
import Input from '../../../../components/UI/Input';
import Loader from '../../../../components/UI/Loader';
import Select from '../../../../components/UI/Select';

/**
* @component Система контроля РП - Система контроля испытательных сроков - Карточка сотрудника - форма с данными о сотруднике
* @prop {isLoading} boolean - Состояние загрузки
* @prop {setIsLoading} function - Обновление состояния загрузки
* @prop {backlight} string - Состояние подсветки инпута поиска
* @prop {setBacklight} function - Обновление состояния подсветки инпута поиска
* @prop {checkStatus} function - Сравнение текущего статуса с переданным в функцию
* @prop {getCollaborator} function - Обновление данных карточки
*/
function EmployeeForm(props) {
  // Деструктуризация свойств компонента
  const {
    isLoading, setIsLoading, checkStatus, getCollaborator, backlight, setBacklight,
  } = props;
  const isEditing = useSelector((state) => state.probation.editing); // состояние редактирования
  const employeeInfo = useSelector((state) => state.probation.employee); // информация о сотруднике
  const partners = useSelector((state) => state.probation.partners); // массив партнёров
  const mentors = useSelector((state) => state.probation.mentors); // массив наставников
  const editEmployeeInfo = useSelector((state) => state.probation.edit_employee); // редактируемая информация о сотруднике

  // Деструктуризация информации о сотруднике
  const {
    sn, initials, date_creation, date_probation_end, date_release, id, mentor, mentor_id, partner, status, status_id, form_1, form_1_path, form_2, form_2_path,
  } = employeeInfo;

  // Выбор даты выхода
  function chooseReleaseDate(e) {
    log({ message: `Пользователь выбрал дату выхода - ${e.target.value}`, eventtype: 'info' });
    createAction('SET_EDITING_FIELD', { date_release: e.target.value }); // запись даты в хранилище
  }

  // Выбор даты окончания стажировки
  function chooseEndDate(e) {
    log({ message: `Пользователь выбрал дату окончания стажировки - ${e.target.value}`, eventtype: 'info' });
    createAction('SET_EDITING_FIELD', { date_probation_end: e.target.value }); // запись даты в хранилище
  }

  // Поиск ментора
  async function searchMentor(e) {
    try {
      // При поиске наставника, его id сбрасывается и устанавливается только при выборе
      // это необходимо для валидации
      createAction('SET_EDITING_FIELD', { mentor_id: null });

      // Если не введено ничего либо введено меньше 2 символов
      // массив наставников обнуляется и инпут подсвечивается красным
      if (!e.target.value || e.target.value.length <= 2) {
        // При открытии формы редактирования e.target.value отсутствует, но имя наставника отображается в поле
        // поэтому поле подсвечивается зелёным
        if (mentor_id) setBacklight('green'); // подсветка зелёным

        createAction('SET_MENTORS', []); // сброс массива
        backlightHandler([], setBacklight); // подсветка красным
      } else {
        const reqData = {
          type: 'searchMentor',
          query: e.target.value,
        };

        const result = await clientSendData('POST', '/search_mentor', reqData); // запрос в БД
        if (result) { // иначе
          backlightHandler(result, setBacklight); // подсветка в зависимости от результата
          createAction('SET_MENTORS', result); // запись массива наставников в хранилище
        }
      }
    } catch (error) {
      catchHandler(error, 'searchMentor'); // обработка ошибок
    }
  }

  // Выбор ментора
  function chooseMentor(mentor) {
    log({ message: `Пользователь выбрал наставника - ${mentor.title}`, eventtype: 'info' });
    setBacklight('green'); // подсветка красным
    createAction('SET_EDITING_FIELD', { // Запись в хранилище данных о выбранном наставнике
      mentor_id: mentor.id,
      mentor: mentor.title,
    });
  }

  // Выбор партнёра
  function choosePartner(partner) {
    log({ message: `Пользователь выбрал партнёра - ${partner.title}`, eventtype: 'info' });
    createAction('SET_EDITING_FIELD', { // Запись в хранилище данных о выбранном партнёре
      partner_id: partner.id,
      partner: partner.title,
    });
  }

  // Загрузка анкеты
  async function uploadForm(e, number) {
    try {
      log({ message: `Пользователь нажал "Загрузить анкету №${number}"`, eventtype: 'info' });
      if (!e) { // если событие отсутствует (пользователь нажал "отменить" при выборе файла)
        log({ message: `Пользователь отменил загрузку анкеты №${number}"`, eventtype: 'info' });
        return; // выход из функции
      } if (e && !e.target.files[0].type.includes('pdf')) { // если тип файла не pdf
        alertFunction('incorrect_file_format', 'clientPost');
      } else {
        const formData = new FormData(); // FormData для отправки файла
        // добавление данных к запросу
        formData.append('type', 'uploadForm');
        formData.append('file', e.target.files[0], e.target.files[0].name); // файл и его имя
        formData.append('number', number); // номер анкеты (1 или 2)
        formData.append('user_id', id); // id сотрудника
        formData.append('user_name', `${sn}_${initials}`); // имя сотрудника
        formData.append('status_id', status_id); // id текущего статуса
        // formData.append("domen", domen) // домен (используется для создания пути загрузки анкеты с сервера)

        setIsLoading(true); // состояние - загружается
        const result = await clientSendFile('POST', '/upload_form', formData); // запрос в БД
        if (result === 'bad_request') { // если некорректный запрос
          alertFunction('bad_request'); // уведомление
          setIsLoading(false); // загрузка завершена
        } else if (result === 'success') { // если запрос выполнен успешно
          alertFunction('file_upload_success', 'clientPost'); // уведомление
          setIsLoading(false); // загрузка завершена
          getCollaborator(); // обновление данных
          log({ message: `Пользователь загрузил анкету №${number}`, eventtype: 'info' });
        } else if (result.error) { // если запрос выполнен с ошибкой
          alertFunction('file_upload_error', 'clientPost'); // уведомление
          console.log(result.error); // ошибка в консоль
          setIsLoading(false); // загрузка завершена
        } else { // иначе что то пошло не так
          alertFunction('something_went_wrong', 'clientPost'); // уведомление
          setIsLoading(false); // загрузка завершена
        }
      }
    } catch (error) {
      catchHandler(error, 'uploadForm'); // обработка ошибки
    }
  }

  return (
    <form className="employee__form">

      {/* Дата создания */}
      <div className="employee__info-row">
        <span className="employee__key">Дата создания</span>
        <span className="employee__value">{isLoading ? <Loader /> : date_creation}</span>
      </div>

      {/* Текущий статус */}
      <div className="employee__info-row">
        <span className="employee__key">Текущий статус</span>
        <span className="employee__value">{isLoading ? <Loader /> : status}</span>
      </div>

      {/* Дата выхода сотрудника */}
      <div className="employee__info-row">
        <span className="employee__key">Дата выхода сотрудника*</span>
        {isEditing ? (
        <Input
          type="date"
          defaultValue={date_release}
          onChange={chooseReleaseDate}
        />) : (
        <span className="employee__value">{isLoading ? <Loader /> : date_release}</span>)}
      </div>

      {/* Дата завершения ИС */}
      <div className="employee__info-row">
        <span className="employee__key">Дата завершения ИС*</span>
        {isEditing && status_id !== 18 ? (
        <Input
          type="date"
          defaultValue={date_probation_end}
          onChange={chooseEndDate}
        />) : (
        <span className="employee__value">{isLoading ? <Loader /> : date_probation_end}</span>)}
      </div>

      {/* Наставник */}
      <div className="employee__info-row">
        <span className="employee__key">Наставник*</span>
        {isEditing ? (
        <InputSearch
          id="employee-card-form__mentors"
          array={mentors}
          defaultValue={mentor}
          onChoose={chooseMentor}
          onSearch={searchMentor}
          backlight={backlight}
        />) : (
        <span className="employee__value">{isLoading ? <Loader /> : mentor}</span>)}
      </div>

      {/* Партнёр */}
      <div className="employee__info-row">
        <span className="employee__key">Партнёр*</span>
        {isEditing ? (
        <Select
          id="employee-card-form__partners"
          array={partners}
          defaultValue={editEmployeeInfo.partner}
          onChoose={choosePartner}
        />) : (
        <span className="employee__value">{isLoading ? <Loader /> : partner}</span>)}
      </div>

      {/* Анкета №1 (отображается если статус >= 3) */}
      {checkStatus('>=', 3) && (
      <div className="employee__info-row">
        <span className="employee__key">Анкета №1</span>
        {form_1 ? (
        <a href={domain + form_1_path} className="clickable">{isLoading ? <Loader /> : 'Скачать Анкету №1'}</a>) : (
        <>
          <label className="file-upload__label clickable" htmlFor="upload-form_1">Загрузить Анкету №1</label>
          <input
            onChange={(e) => uploadForm(e, 1)}
            className="file-upload__input-file"
            id="upload-form_1"
            type="file"
            name="file"
            accept=".pdf"
          />
        </>)}
      </div>)}

      {/* Анкета №2 (отображается если статус >= 6) */}
      {checkStatus('>=', 6) && (
      <div className="employee__info-row">
        <span className="employee__key">Анкета №2</span>
        {form_2 ? (
        <a href={domain + form_2_path} className="clickable">{isLoading ? <Loader /> : 'Скачать Анкету №2'}</a>) : (
        <>
          <label className="file-upload__label clickable" htmlFor="upload-form_2">Загрузить Анкету №2</label>
          <input
            onChange={(e) => uploadForm(e, 2)}
            className="file-upload__input-file"
            id="upload-form_2"
            type="file"
            name="file"
            accept=".pdf"
          />
        </>)}
      </div>)}
    </form>
  );
}

export default EmployeeForm;
