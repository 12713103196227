import React, { useEffect, useState } from 'react';
import Service from '../../components/Service/Service';
import './workplaces.scss';
import Booking from './Booking/Booking';
import BookingAdmin from './BookingAdmin/BookingAdmin';
import { setOperInfo } from '../../utils/functions/others';
import { authorization } from '../../utils/functions/authenticate';
import { clientSendData } from '../../utils/functions/requests';
import { catchHandler } from '../../utils/error_handling/error_handling';
import Loader from '../../components/UI/Loader';
import Cap from '../Cap/Cap';
import Block from './Block';
import BookingReport from './BookingReport';

function Workplaces() {
  const [isAuthorized, setIsAuthorized] = useState(false); // Состояние авторизации
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [pages, setPages] = useState([]); // страницы доступные пользователю
  const currentDate = new Date();
  const today = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${currentDate.getDate()}`;
  const components = { // Компоненты для отображения в сервисе
    user: <Booking />, // Интерфейс пользователя
    admin: <BookingAdmin />, // Интерфейс администратора
    blockA: <Block block="a" />,
    blockB: <Block block="b" />,
    report: <BookingReport today={today} />,
  };

  useEffect(() => {
    setOperInfo();
    awaitRequests();
  }, []);

  async function awaitRequests() {
    setIsLoading(true);
    const checkAuthorization = await authorization(); // авторизация
    setIsAuthorized(checkAuthorization);
    if (checkAuthorization) await getWpbInterfaces(); // получить интерфейсы пользователя
    setIsLoading(false);
  }

  // Получить интерфейсы доступные пользователю
  async function getWpbInterfaces() {
    try {
      const reqData = {
        type: 'getWpbInterfaces',
        location: window.location.pathname,
      };
      const result = await clientSendData('POST', '/get_wpb_interfaces', reqData);
      if (result.success) {
        const userPages = result?.data?.sort((a, b) => a.id - b.id).map((item) => {
          const { id, title, component } = item;
          return ({
            id, // идентификатор страницы
            title, // заголовок страницы
            component: components[component], // соответствующий компонент
          });
        });
        setPages(userPages); // Результат записывается в состояние
      }
    } catch (error) {
      catchHandler(error, 'getWpbInterfaces');
    }
  }

  if (isLoading) return <Loader />; // если идет загрузка - показать лоадер
  if (isAuthorized) { // если пользователь авторизован
    // если пользователю не доступен ни один интерфейс - заглушка
    if (pages.length === 0) {
      return (
        <div className="upu-service_padding-top">
          <h2 className="upu-service__title">Доступные интерфейсы отсутствуют</h2>
        </div>
      );
    }
    // иначе показываем то, что доступно пользователю
    return <Service id="workplaces" pages={pages} horizontal />;
  } return <Cap />; // если пользователь не авторизован - показать заглушку
}

export default Workplaces;
