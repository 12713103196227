import React from 'react';

/**
* @component Кнопка "Отменить" (крестик)
@prop {onClick} function (Обязательный) Обработчик клика
@prop {disabled} boolean сделать кнопку неактивной
*/

function Cancel(props) {
  const { disabled, onClick } = props;

  // Обработка клика
  function clickHandler(e) {
    if (!disabled) onClick(e);
  }

  // Определение класса
  const defineClass = () => {
    let result = 'upu__cancel';
    if (disabled) result += '_disabled';
    return result;
  };

  return <img onClick={clickHandler} className={defineClass()} src="../../icons/cancel.svg" alt="cancel" />;
}

export default Cancel;
