import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import { clientSendData } from '../../../utils/functions/requests';
import { catchHandler } from '../../../utils/error_handling/error_handling';
import { alertFunction } from '../../../utils/functions/alertFunction';
import {
  activeTicketStatuses, convertTicketId, defaultTableState, headers,
} from '../common';
import ServiceBody from '../../../components/Service/ServiceBody';
import PaginatedTable from '../../../components/Table/PaginatedTable/PaginatedTable';
import TRow from '../../../components/Table/TRow';
import TData from '../../../components/Table/TData';
import TicketCard from './TicketCard/TicketCard';
import Modal from '../../../components/UI/Modal';
import Select from '../../../components/UI/Select';
import CardSetting from '../../../components/Card/CardSetting';
import NewTicketCard from './TicketCard/NewTicketCard';

/**
* @component ХелпДеск - Интерфейс службы поддержки
*/
function Support() {
  const tableId = 'helpdesk__tickets';
  const [searchParams, setSearchParams] = useSearchParams(); // параметры поиска в url
  const [total, setTotal] = useState(0);
  const [tickets, setTickets] = useState([]); // отображаемые заявки
  const [tableState, setTableState] = useState(JSON.parse(localStorage.getItem(tableId)) || defaultTableState.support.main);
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [showCard, setShowCard] = useState(false); // состояние отображения карточки
  const [showCreateCard, setShowCreateCard] = useState(false); // состояние отображения карточки создания
  const [isUnite, setIsUnite] = useState(false); // состояние объединения заявок
  const [ticketForUnite, setTicketForUnite] = useState({ main: null, list: [] }); // заявки для объединения
  const operInfo = useSelector((state) => state.oper_info); // оперативная информация
  const currentUser = operInfo.user_id; // id текущего пользователя
  const sdParametres = useSelector((state) => state.helpdesk.parameters); // настройки хелпдеска из sd_parameters
  const isCoordinator = sdParametres?.coordinators?.includes(String(currentUser));
  const isSuperUser = sdParametres?.admins?.includes(String(currentUser));
  const [displayModal, setDisplayModal] = useState(false);
  const uniteButton = isUnite ? {
    title: ticketForUnite.list.length > 1 ? `Объединить запросы (${ticketForUnite.list.length})` : 'Выберите запрос для объединения',
    handler: () => {
      if (ticketForUnite.list.length > 1) setDisplayModal(true);
    },
  } : null;

  useEffect(() => {
    localStorage.setItem(tableId, JSON.stringify(tableState));
    getTickets();
  }, [tableState]);

  useEffect(() => { // При закрытии карточки обновить данные таблицы
    if (!showCard) getTickets();
  }, [showCard]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTableState((prevTableState) => ({ ...prevTableState })); // Обновить состояние tableState каждые 10 сек
    }, 30000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    // проверка параметров url при их изменении (для открытия карточки)
    if (searchParams.size > 0) { // если есть какие то параметры
      // если url содержит параметр поиска "ticket" открывается карточка вопроса
      if (searchParams?.get('ticket')) setShowCard(true);
      // else setSearchParams(''); // иначе это другие параметры - сбрасываем их
    }
  }, [searchParams]);

  // Получить заявки
  async function getTickets() {
    try {
      const reqData = {
        type: 'getTickets',
        tableState,
        total,
      };
      setIsLoading(true);
      const result = await clientSendData('POST', '/get_tickets', reqData);
      if (result?.success) {
        setTickets(result.tickets); // Результат записывается в состояние
        setTotal(result.total);
      }
    } catch (error) {
      catchHandler(error, 'getTickets');
    } finally {
      setIsLoading(false);
    }
  }

  // Объединить заявки
  async function uniteTickets() {
    try {
      const reqData = {
        type: 'uniteTickets',
        ticketForUnite,
      };
      setIsLoading(true);
      const result = await clientSendData('POST', '/unite_tickets', reqData);
      if (result?.success) {
        toggleUnite(); // выкл режим объединения
        await alertFunction('save_settings', 'clientPost'); // показать уведомление
        await getTickets(); // обновить состояние таблицы
      } else if (result.excludeTickets.length) alertFunction('sd_aassociate_tickets_fail', 'clientPost', result.excludeTickets);
      else alertFunction('something_went_wrong', 'clientPost');
    } catch (error) {
      catchHandler(error, 'uniteTickets');
    } finally {
      setIsLoading(false);
    }
  }

  // Переключение режима объединения
  function toggleUnite() {
    setIsUnite((state) => (!state)); // вкл/выкл режим объединения
    setTicketForUnite({ main: null, list: [] }); // сброс заявок для объединения
  }

  // Выбрать заявку для объединения
  // можно выбрать заявку только в активном статусе
  function chooseForUnite(ticket) {
    if (isUnite) { // если включен режим объединения, то обрабатываем клик
      // Если id уже есть в списке - убрать его
      if (ticketForUnite.list.includes(ticket.id)) {
        setTicketForUnite((state) => ({
          ...state,
          list: state.list.filter((i) => i !== ticket.id),
        }));
      // Иначе id нет в списке
      } else if (activeTicketStatuses.includes(ticket.status_id)) {
        setTicketForUnite((state) => ({
          ...state,
          list: [...state.list, ticket.id],
        }));
      } else alertFunction('unite_denied', 'clientPost');
    }
  }

  // Выбрать главную заявку для объединения
  function chooseMainUnited(main) {
    setTicketForUnite((state) => ({
      ...state,
      main: {
        id: main.id,
        title: main.title,

      },
    }));
  }

  // // Открыть заявку
  // function openTicket(id) {
  //   // установить в параметры поиска id заявки с добавленными нулями
  //   setSearchParams({ ticket: convertTicketId(id) });
  // }

  return (
    <ServiceBody>

      <PaginatedTable
        id={tableId}
        state={tableState}
        setState={setTableState}
        headers={headers.support.main}
        total={total}
        footerButton={uniteButton}
        optButton={(isSuperUser || isCoordinator) && {
          title: 'Создать запрос',
          handler: () => setShowCreateCard(true),
        }}
      >
        {tickets.map((ticket) => (
          <TRow key={ticket.id} active={ticketForUnite.list.includes(ticket.id)} onClick={() => chooseForUnite(ticket)}>
            {headers.support.main.map((column) => {
              const { field, align, mobile_hide } = column;
              return (
              <TData key={field} align={align} loading={isLoading} mobileHide={mobile_hide}>
                {(() => {
                  switch (field) {
                    case 'id': return (
                      <div className="helpdesk__ticket-priority">
                      {`#${ticket.ticket_number}`}
                      {(ticket.all_tasks === ticket.my_group_complited_tasks) && ticket.all_tasks !== 0
                      && <div style={{ background: '#00EE69', opacity: '0.2' }} />}
                      </div>);
                    case 'priority': return (
                    <div className="helpdesk__ticket-priority">
                      {ticket[field]}
                      <div style={{ background: `${ticket?.priority_color}`, opacity: '0.2' }} />
                    </div>);
                    case 'title': return (
                      isUnite ? ticket[field] : <Link className="clickable" to={`?ticket=${convertTicketId(ticket.id)}`}>{ticket[field]}</Link>
                    );
                    case 'status': return (
                      <div className="helpdesk__ticket-priority">
                        {ticket[field]}
                        <div style={{ background: ` ${ticket?.status_color}`, opacity: '0.2' }} />
                      </div>);
                    case 'initiator':
                    case 'performer': return (
                      isUnite ? ticket[field] : <Link className="clickable" to={`?user=${ticket[`${field}_id`]}`}>{ticket[field]}</Link>
                    );
                    default: return ticket[field];
                  }
                })()}
              </TData>);
            })}
            <TData loading={isLoading} onDoubleClick={toggleUnite}>
              {!isUnite && ticket.my_group_tasks > 0
              && <span className={ticket.my_group_tasks > ticket.my_group_tasks_busy ? 'helpdesk__task-badge_active' : 'helpdesk__task-badge_busy'}>task ⬤</span>}
              {/* <img src="../../icons/file-text.svg" alt="file" /> */}
            </TData>
          </TRow>
        ))}
      </PaginatedTable>

      {showCard && (
      <TicketCard
        setShowCard={setShowCard}
        refreshTable={() => setTableState({ ...tableState })}
      />
      )}

      {showCreateCard && (
      <NewTicketCard
        setShowCard={setShowCreateCard}
        refreshTable={() => setTableState({ ...tableState })}
      />
      )}

      <Modal active={displayModal} setActive={setDisplayModal} callback={uniteTickets}>
        <CardSetting title="Выберите главный запрос">
          {displayModal
          && <Select
            id="helpdesk__tickets-choose-main-unite"
            array={tickets.filter((item) => ticketForUnite.list.includes(item.id))}
            onChoose={chooseMainUnited}
            defaultValue={ticketForUnite.main?.title}
          />}
        </CardSetting>
      </Modal>
    </ServiceBody>
  );
}

export default Support;
