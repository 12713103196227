// Список таблиц с логами
export const tables = [
  { id: 1, title: 'upu_log_backend', table: 'upu_log_backend' },
  { id: 2, title: 'upu_log_frontend', table: 'upu_log_frontend' },
  { id: 3, title: 'upu_log_audit', table: 'upu_log_audit' },
];
// Опции количества записей на странице при отображении
export const limits = [
  { id: 1, title: 100 },
  { id: 2, title: 250 },
  { id: 3, title: 500 },
];
// Заголовки таблицы по умолчанию
export const headersDefault = [
  { id: 1, title: 'timestamp', field: 'timestamp' },
  {
    id: 2, title: 'user_id', field: 'data.user_id', align: 'right',
  },
  { id: 3, title: 'source', field: 'data.source' },
  {
    id: 4, title: 'message', field: 'data.message', align: 'left',
  },
];

export const periodDefault = {
  from: new Date().toISOString().slice(0, 10),
  to: new Date().toISOString().slice(0, 10),
};
