import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { tableToExcel } from '../../../../utils/functions/others';
import { createAction } from '../../../../utils/redux/store';

/**
* @component Обёртка для больших таблиц, прокручиваемых горизонтально
* @prop {id} string - (Обязательный) Идентификатор DOM элемента таблицы
* @prop {title} string - (Обязательный) Название таблицы
* @prop {back_button} boolean - Кнопка "Вернуться назад"
* @prop {chart_button} boolean - Кнопка "График"
*/
function TableWrapper(props) {
  const {
    id, title, back_button, chart_button, children,
  } = props;

  const [searchParams, setSearchParams] = useSearchParams();

  // Вернуться назад
  function goBack() {
    createAction('SET_CURRENT_COMPONENT', 'basic'); // переключть отображаемый компонент на основной
    setSearchParams(''); // сбросить параметры поиска
  }

  // Показать график
  function showChart() {
    createAction('SET_SHOW_CHART', true);
  }

  return (
    <div className="upu-table reports__table-wrapper" id={`${id}-wrapper`}>
      <div className="upu-table__header">
        {title}
        {chart_button && <span className="reports__table-wrapper__go-back" onClick={showChart}>График</span>}
      </div>
      {back_button && <div className="upu-table__table-button" onClick={goBack}>← на страницу отчётов</div>}
      <div className="upu-table__table-body">{children}</div>
      <div className="upu-table__table-button" onClick={() => tableToExcel(id, title)}>Cкачать в Excel</div>
    </div>
  );
}

export default TableWrapper;
