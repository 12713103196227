import React from 'react';
import ServiceBody from '../../../components/Service/ServiceBody';

/**
* @component Заглушка для интерфейса Базы знаний
* @prop {message} string - Текст сообщения для отображения
*/
function CapKB(props) {
  const { message } = props;
  return (
    <ServiceBody>
      <h3 className="upu-service__title">{message}</h3>
    </ServiceBody>
  );
}

export default CapKB;
