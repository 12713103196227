import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import CheckBox from '../../../components/UI/CheckBox';
import Select from '../../../components/UI/Select';
import { createAction } from '../../../utils/redux/store';
import RadioButton from '../../../components/UI/RadioButton';
import { closeWindowAlert, log } from '../../../utils/functions/others';
import SearchUpuUser from '../../../components/Forms/SearchUpuUser';

function Answers(props) {
  const { answers, question } = props;

  const currentQuestionFromStore = useSelector((state) => state.interview.current_question);
  const userAnswers = useSelector((state) => state.interview.user_answers); // ответы пользователя
  const interview = useSelector((state) => state.interview.interview);
  const isWiki = useSelector((state) => state.interview.is_wiki);
  const { questionnaire } = interview;
  const currentQuestion = question || currentQuestionFromStore;
  const currentQuestionAnswers = answers.filter((a) => a.question_id === currentQuestion.id); // варианты ответа на текущий вопрос
  const currentQuestionAnswer = userAnswers.find((ua) => ua.question_id === currentQuestion.id); // выбранный ответ на текущий вопрос

  useEffect(() => {
    closeWindowAlert(); // подтверждение закрытия окна
  }, []);

  // Обработчик текущего выбора ответа
  function currentChoiceHandler(answerId) {
    // поиск текущего ответа в массиве ответов
    const findAnswer = userAnswers.find((ua) => ua.answer_id === answerId);
    if (findAnswer) return true;
    return false;
  }

  // Обработка множественного выбора ответа пользователем
  function multiChooseHandler(e, a) {
    if (e.target.checked) { // если чекбокс - true
      const answer = { // создаем объект ответа
        questionnaire_id: questionnaire.id,
        question_id: currentQuestion.id,
        answer_id: a.id,
        comment: '',
      };
      createAction('SET_USER_ANSWERS', [...userAnswers, answer]); // и добавляем к массиву
    } else { // иначе удаляем из массива
      const deleteAnswer = userAnswers.filter((answer) => answer.answer_id !== a.id);
      createAction('SET_USER_ANSWERS', deleteAnswer);
    }
    log({ message: `Пользователь в анкете id: ${questionnaire.id} в вопросе id: ${currentQuestion.id} выбрал вариант id: ${a.id}`, eventtype: 'info' });
  }

  // Обработка выбора ответа при поиске пользователя
  function singleChooseHandler(answer) {
    if (currentQuestionAnswer) { // если есть ответ(ы) на текущий вопрос
      const refreshUserAnswer = userAnswers.map((ua) => { // Обновляем данные в массиве ответов
        if (ua.question_id === currentQuestion.id) { // Находим ответ на текущий вопрос
          return {
            ...ua,
            answer_id: answer.id, // обновляем answer_id
            answer_content: answer.title, // обновляем answer_content
          };
        } return ua; // остальные без изменений
      });
      createAction('SET_USER_ANSWERS', refreshUserAnswer); // перезаписываем ответы в хранилище
    } else { // если нет ответа на текущий вопрос
      const newAnswer = { // создаем объект ответа
        questionnaire_id: questionnaire.id,
        question_id: currentQuestion.id,
        answer_id: answer.id,
        answer_content: answer.title,
        comment: '',
      };
      createAction('SET_USER_ANSWERS', [...userAnswers, newAnswer]); // он добавляется к массиву
    }
    log({ message: `Пользователь в анкете id: ${questionnaire.id} в вопросе id: ${currentQuestion.id} выбрал вариант id: ${answer.id}`, eventtype: 'info' });
  }

  // Сброс выбранного ответа
  function resetAnswerWhileSearch() {
    const refreshUserAnswer = userAnswers.filter((ua) => ua.question_id !== currentQuestion.id);
    createAction('SET_USER_ANSWERS', refreshUserAnswer); // перезаписываем ответы в хранилище
    createAction('SET_COMMENT', ''); // сбросить отображаемый комментарий
  }

  if (isWiki) { // В режиме справочника покажутся только правильные ответы
    return (
      currentQuestionAnswers // ответы к текущему вопросу
        .filter((answer) => answer.correct) // оставляем только правильные
        .map((answer) => (
        <div key={answer.id} className="interview__answer">
          {answer?.answer.split('\n') // разделить строку по переносам
            .map((row, index) => ( // показать каждую в параграфе
            <p key={index} className="interview__answer-content">{row}</p>))}
        </div>
        // </div> dangerouslySetInnerHTML={{ __html: answer?.answer }}/>
        ))
    );
  } // Если не справочник - показываются элемента выбора ответов
  switch (currentQuestion.output_id) { // в зависимости от типа вывода
    case 1: return ( // Радио
      currentQuestionAnswers.map((a) => (
        <div key={a.id} className="interview__answer">
          <RadioButton
            id={`interview__radio-answer-${a.number}`}
            group={`question-${currentQuestion.id}`}
            value={a.id}
            onChange={() => singleChooseHandler(a)}
            defaultChecked={currentChoiceHandler(a.id)}
          >
          {a?.answer.split('\n') // разделить строку по переносам
            .map((row, index) => ( // показать каждую в параграфе
            <p key={index} className="interview__answer-content">{row}</p>))}
              {/* <div
                className="interview__answer-content"
                dangerouslySetInnerHTML={{ __html: a.answer }}
              /> */}
          </RadioButton>
        </div>
      ))
    );
    case 2: return ( // Чекбокс
      currentQuestionAnswers.map((a) => (
        <div key={a.id} className="interview__answer">
          <CheckBox
            id={`interview__checkbox-answer-${a.number}`}
            onChange={(e) => multiChooseHandler(e, a)}
            checked={currentChoiceHandler(a.id)}
          >
          {a?.answer.split('\n') // разделить строку по переносам
            .map((row, index) => ( // показать каждую в параграфе
            <p key={index} className="interview__answer-content">{row}</p>))}

              {/* <div
                className="interview__answer-content"
                dangerouslySetInnerHTML={{ __html: a.answer }}
              /> */}
          </CheckBox>
        </div>
      ))
    );
    case 3: return ( // Селект
        <Select
          id={`interview__select-answer_${currentQuestion.id}`}
          array={currentQuestionAnswers.map((a) => ({ ...a, title: a.answer }))}
          onChoose={(answer) => singleChooseHandler(answer)}
          defaultValue={currentQuestionAnswer?.answer_content}
        />
    );
    case 4: return null; // активируется поле комментария, находящееся под каждым ответом
    case 5: return ( // Выбор пользователя с поиском
        <SearchUpuUser
          onChoose={singleChooseHandler}
          onSearch={resetAnswerWhileSearch}
          placeholder="Начните вводить ФИО"
          defaultValue={currentQuestionAnswer?.answer_content}
        />
    );
    default: return <p>Отсутствует формат вывода</p>;
  }
}

export default Answers;
