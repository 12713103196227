import React from 'react';
import { useSelector } from 'react-redux';
import { createAction } from '../../utils/redux/store';
import Help from '../Help/Help';
import './footer.scss';
import '../Help/help.scss';

/**
* @component Футер портала (нижняя часть, отображающаяся на всех страницах)
*/

function Footer() {
  const isOpen = useSelector((state) => state.visibility.help);

  function toogleHelp(e) {
    e.stopPropagation();
    createAction('TOGGLE_HELP', !isOpen);
  }
  return (
    <div className="footer">
      <img
        onClick={toogleHelp}
        className="help-button"
        src="../../icons/question.png"
        alt="help"
      />
    <img className="footer__logo" src="../../../unicon/unicon_logo_no_sphere.png" alt="unicon_logo" />
      <Help />
    </div>
  );
}

export default Footer;
