import React, { useEffect, useState, useRef } from 'react';
import Button from '../../../components/UI/Button/Button';
import Input from '../../../components/UI/Input';
import Loader from '../../../components/UI/Loader';
import Toggle from '../../../components/UI/Toggle';
import { log } from '../../../utils/functions/others';
import { stringMatch } from '../../../utils/functions/stringHandling';
import CardSetting from '../../../components/Card/CardSetting';

function QuestionsRequest() {
  const [isLoading, setIsLoading] = useState(false);
  const [questions, setQuestions] = useState([
    { id: 1, title: 'Уставы Компании (компаний Группы) и Договоры об учреждении (создании) обществ; все изменения и дополнения в течение 20__ года и за период с "__" _______ 20__ года по "__" ______ 20__ года. Договор об учреждении (создании) общества.', isCustom: false },
    { id: 2, title: 'Регистрационные документы Компании (Компаний Группы): свидетельство о государственной регистрации юридического лица, свидетельство о постановке на учет в налоговых органах, во внебюджетных фондах, документ органов статистики о присвоении кодов.', isCustom: false },
    { id: 3, title: 'Информация по уставному капиталу: акционеры (участники) Компании (компаний Группы) с указанием долей в капитале за период с "__" ______20__ года по "__" ______ 20__ года; выписки из реестра акционеров; документы по увеличению/уменьшению акционерного капитала (по дополнительной эмиссии акций; выкупу собственных акций у акционеров); смене собственников. ', isCustom: false },
    { id: 5, title: 'По филиальной сети (если применимо): наименование филиала (агентства/представительства),  принципы управления филиальной сетью и финансового взаимодействия головной организации и филиалов, в т.ч. наличие внутренних нормативных документов, система внутреннего контроля деятельности филиалов,  положения о филиалах, доверенности управляющих, решения о создании, документы о регистрации филиалов.', isCustom: false },
    { id: 6, title: 'Уставы Компании (компаний Группы) и Договоры об учреждении (создании) обществ; все изменения и дополнения в течение 20__ года и за период с "__" _______ 20__ года по "__" ______ 20__ года. Договор об учреждении (создании) общества.', isCustom: false },
    { id: 7, title: 'Регистрационные документы Компании (Компаний Группы): свидетельство о государственной регистрации юридического лица, свидетельство о постановке на учет в налоговых органах, во внебюджетных фондах, документ органов статистики о присвоении кодов.', isCustom: false },
    { id: 8, title: 'Информация по уставному капиталу: акционеры (участники) Компании (компаний Группы) с указанием долей в капитале за период с "__" ______20__ года по "__" ______ 20__ года; выписки из реестра акционеров; документы по увеличению/уменьшению акционерного капитала (по дополнительной эмиссии акций; выкупу собственных акций у акционеров); смене собственников. ', isCustom: false },
    { id: 9, title: 'По филиальной сети (если применимо): наименование филиала (агентства/представительства),  принципы управления филиальной сетью и финансового взаимодействия головной организации и филиалов, в т.ч. наличие внутренних нормативных документов, система внутреннего контроля деятельности филиалов,  положения о филиалах, доверенности управляющих, решения о создании, документы о регистрации филиалов.', isCustom: false },
  ]);
  const [currentQuestionnaire, setCurrentQuestionnaire] = useState({ questions: [] });
  const [filteredQuestions, setFilteredQuestions] = useState([
    {
      title: '1. Юридическая информация, СВК, описание бизнеса компании',
      id: 1,
      questions: [
        { id: 1.1, text: 'Уставы Компании (компаний Группы) и Договоры об учреждении (создании) обществ; все изменения и дополнения в течение 20__ года и за период с __ _______ 20__ года по __ ______ 20__ года. Договор об учреждении (создании) общества.' },
        { id: 1.2, text: 'Регистрационные документы Компании (Компаний Группы): свидетельство о государственной регистрации юридического лица, свидетельство о постановке на учет в налоговых органах, во внебюджетных фондах, документ органов статистики о присвоении кодов.' },
        { id: 1.3, text: 'Информация по уставному капиталу: акционеры (участники) Компании (компаний Группы) с указанием долей в капитале за период с __ ______20__ года по __ ______ 20__ года; выписки из реестра акционеров; документы по увеличению/уменьшению акционерного капитала (по дополнительной эмиссии акций; выкупу собственных акций у акционеров); смене собственников.' },
        { id: 1.4, text: 'По филиальной сети (если применимо): наименование филиала (агентства/представительства), принципы управления филиальной сетью и финансового взаимодействия головной организации и филиалов, в т.ч. наличие внутренних нормативных документов, система внутреннего контроля деятельности филиалов, положения о филиалах, доверенности управляющих, решения о создании, документы о регистрации филиалов.' },
        { id: 1.5, text: 'Описание организационной структуры компании (системы управления), в т.ч. в виде блок-схемы.' },
        { id: 1.6, text: 'Положения о структурных подразделениях и отделах (в т.ч. бухгалтерская служба, внутренний аудит), действовавших в 20__ году.' },
        { id: 1.7, text: 'Отчеты внутренних аудиторов.' },
        { id: 1.8, text: 'Органы управления обществом. Список членов руководства, в том числе аудиторского комитета, области их ответственности, административная структура, участие в деятельности компании.' },
        { id: 1.9, text: 'Пользуется ли компания услугами привлеченного эксперта для целей подготовки финансовой отчетности?' },
        { id: 1.10, text: 'Пользуется ли компания услугами обслуживающих (сервисных) организаций (как часть СВК) для целей подготовки финансовой отчетности?' },
        { id: 1.11, text: 'Описание системы внутреннего контроля (СВК) Компании (при наличии). Внутренние нормативные документы Компании, устанавливающие порядок функционирования СВК.' },
        { id: 1.12, text: 'Пользуется ли компания услугами внешних юристов (перечень юристов, даты оказания услуг, изложение сути вопроса). Договоры, ответы юристов, прочая подтверждающая документация.' },
        { id: 1.13, text: 'Опишите все незаконченные судебные разбирательства и условные обязательства. Примерами могут быть гарантии продаж, финансовые гарантии и обязательства по восстановлению природных ресурсов. При описании проанализируйте, как эти вопросы влияют на финансовую отчетность, и не возникают ли возможные риски существенных искажений (вследствие мошенничества или ошибки) в результате этих тяжб и/или условных фактов хозяйственной деятельности.' },
        { id: 1.14, text: 'Опишите, какие существуют методы выявления, методы оценки существенности и вероятности возникновения, а также меры предосторожности в отношении возникших бизнес-рисков компании, имеющих отношение к финансовой отчетности компании.' },
        { id: 1.15, text: 'Протоколы решений Собраний акционеров (в том числе о распределении прибыли 20__ года, о назначении Юникон АО официальным аудитором на 20__ год), Правления, Совета директоров и других органов управления Компании (компаний Группы) по вопросам производственно-хозяйственной и финансовой деятельности предприятия с "01" января 20__ года по "__"______ 20__ года.' },
        { id: 1.16, text: 'Распорядительные документы органов управления Компании (компаний Группы) о назначении должностных лиц, ответственных за совершение хозяйственных операций от имени Общества, организацию и ведение бухгалтерского учета и составление бухгалтерской отчетности.' },
        { id: 1.17, text: 'Реестр договоров по финансово-хозяйственной деятельности за 20__ год и с __ _______ 20__ года по __ ______ 20__ года.' },
        { id: 1.18, text: 'Информация по предполагаемой продаже хозяйственных и географических сегментов деятельности (прекращаемая деятельность): вид деятельности, дата прекращения деятельности, влияние на результаты хозяйственной деятельности. Утвержденный план продажи/реорганизации.' },
        { id: 1.19, text: 'Информация о будущих обязательствах, таких как заключение новых договоров, влекущих значительные финансовые последствия для предприятия, социальные обязательства.' },
        { id: 1.20, text: 'Подробное описание деятельности компании (бизнеса), в том числе с учетом отраслей, географического расположения, основного регулирующего законодательства в области осуществления хозяйственной деятельности предприятия, особых рыночных и отраслевых факторов, которые могут повлиять на деятельность компании.' },
        { id: 1.21, text: 'Долгосрочный план развития компании, описание стратегии развития компании. В том числе, с учетом совершившихся или предполагаемых крупных приобретений бизнеса, реорганизации, перемещения бизнеса в другие географические регионы. Разработка или предложение новых видов товаров, работ или услуг либо освоение новых видов деятельности.' },
        { id: 1.22, text: 'Прогнозы в отношении движения финансовых потоков, доходов, их анализ с учетом обоснования применяемых оценочных значений. Сравнение прогнозных данных за предшествующие периоды с фактическими результатами; сравнение прогнозных данных за текущий период с результатами, достигнутыми на дату проведения аудита.' },
        { id: 1.23, text: 'Перечень основных поставщиков и покупателей; основные рынки компании, основные источники формирования выручки, описание существенных особенностей формирования выручки компании (например, электронная система торговли). Описание политики по признанию выручки.' },
        { id: 1.24, text: 'Существенные изменения в составе основных поставщиков и покупателей.' },
        { id: 1.25, text: 'Описание ключевых показателей деятельности компании, являющихся показателями успешной деятельности. Действия, которые, по мнению руководства, являются существенными для достижения важнейших целей бизнеса.' },
        { id: 1.26, text: 'Информация об операциях, зависящих от неустойчивых рынков, например, фьючерсная торговля. Информация о событиях или хозяйственные операции, которые сопряжены с существенной неопределенностью стоимостных параметров, например, оценочные показатели.' },
        { id: 1.27, text: 'Какие существуют соглашения с ключевым персоналом.' },
        { id: 1.28, text: 'Информация о наличии обязательств по охране окружающей среды. Их отражение в бухгалтерском учете.' },
        { id: 1.29, text: 'Информация о получении государственных субсидий: цели субсидирования, дата возникновения, суммы, условия субсидирования; договоры. Информация о прочем целевом финансировании.' },
        { id: 1.30, text: 'Документы, подтверждающие нахождение активов (объектов основных средств и незавершенного строительства, финансовых вложений, запасов) в залоге или ином обременении правом удержания имущества третьими лицами по состоянию на "__" ______20__ года.' },
        { id: 1.31, text: 'Перечень действующих лицензий (сертификатов) с указанием изменений в их составе, за 20__ год и с "__" _______ 20__ года по "__" ______ 20__ года. Копии лицензий (сертификатов) с приложениями (при наличии лицензируемых видов деятельности).' },
        { id: 1.32, text: 'Документы (акты, решения и т.п.) о проверках Компании (компаний Группы) и вступлении в силу решений различными контролирующими государственными органами, в т.ч. налоговыми, лицензирующими органами в 20__ году и с "__" _______ 20__ года по "__" ______ 20__ года, а также за предыдущие налоговые периоды в случае, если взаимоотношения по расчетам могут относиться к отчетному периоду – 20__ года.' },
        { id: 1.33, text: 'Условия начисления дивидендов по обыкновенным/привилегированным акциям.' },
        { id: 1.34, text: 'Информация о принятии решения о выплате дивидендов за 20__ год с предоставлением подтверждающих документов.' },
        { id: 1.35, text: 'Кадровая политика компании. Штатное расписание, корпоративные положения, компенсационная и социальная политика, коллективный договор (соглашение), положения о премировании, образец типового трудового договора, действовавших в 20__ году.' },
        { id: 1.36, text: 'Информация о существенных изменениях, связанных с руководством компании, в т.ч. увольнение руководства.' },
        { id: 1.37, text: 'Среднесписочная численность работников Компании (включая совместителей и работников несписочного состава) за 20__ год, в т.ч. за каждый месяц отдельно.' },
      ],

    },
    {
      title: '2. Общая финансовая информация',
      id: 2,
      questions: [
        {
          id: 2.1,
          text: 'Финансовая отчетность (ФО) Компании/консолидированная отчетность Компаний Группы, в том числе квартальная ФО Компании (Компаний Группы), подготовленная в соответствии с РСБУ на 31 декабря 20__ года',
        },
        {
          id: 2.2,
          text: 'Аудиторское заключение по (консолидированной) бухгалтерской (финансовой) отчетности за 20__ , 20__ годы (в случае если проводился аудит другим аудитором);',
        },
        {
          id: 2.3,
          text: 'Развернутая оборотно-сальдовая ведомость по всем счетам бухгалтерского учета (включая забалансовые) Компании (компаний Группы) по РСБУ за 20__ год.',
        },
        {
          id: 2.4,
          text: 'Обороты по счетам (главная книга) Компании (компаний Группы) за 20__ год в разрезе субсчетов, с разбивкой по каждому месяцу.',
        },
        {
          id: 2.5,
          text: 'Список сотрудников бухгалтерии с указанием объектов учета, которые они ведут и телефонов, а также руководителей служб и отделов предприятия с указанием их телефонов.',
        },
        {
          id: 2.6,
          text: 'Учетная политика Компании (Компаний Группы) для целей бухгалтерского учета  по РСБУ (МСФО) и для целей налогообложения со всеми приложениями за 20__, 20__ годы; Приказы об утверждении учетной политики. Изменения в учетной политике за аудируемый период. Рабочий план счетов.',
        },
        {
          id: 2.7,
          text: 'Утвержденный порядок документооборота, действовавший в 20__ году.',
        },
        {
          id: 2.8,
          text: 'Бухгалтерские справки по исправлениям замечаний предыдущего аудитора (если применимо).',
        },
        {
          id: 2.9,
          text: '"Учетные оценки, принятые для составления финансовой отчетности (учет сомнительной задолженности, учет амортизации, учет предстоящих бонусов, отпусков, торговых скидок и т.п.). ',
        },
        {
          id: 2.10,
          text: 'Информация о применяемых методах при выборе оценочных значений для целей бухгалтерского учета с приложением расчетов оценочных значений, в т.ч. эффективных процентных ставок. ',
        },
        {
          id: 2.11,
          text: 'Учет и методы формирования резерва под обесценение активов (ОС, НМА, финансовых вложения, запасов, дебиторской задолженности)."',
        },
        {
          id: 2.12,
          text: 'Внутренние документы, подготовленные Клиентом по неоднозначным вопросам бухгалтерского учета, включая письма по вопросам методологии бухгалтерского учета, которые Департамент бухгалтерского и налогового учета материнской компании Группы направляет дочерним компаниям (если применимо).',
        },
        {
          id: 2.13,
          text: 'Информацию о фактически проведенных в 20__ году и предстоящих инвентаризациях активов (основные средства, нематериальные активы, запасы, финансовые вложения, денежные средства, дебиторская задолженность и т.п.) и обязательств; Перечень и копии Приказов, Графики проведения инвентаризаций, список материально ответственных лиц (МОЛ), инвентаризационные ведомости, акты.',
        },
        {
          id: 2.14,
          text: 'Пользуется ли компания услугами независимых оценщиков? В случае проведения независимой оценки - предоставить отчеты независимых оценщиков.',
        },
        {
          id: 2.15,
          text: 'Информация о фактически проведенных в 20__ году переоценках активов (основные средства, нематериальные активы, финансовые вложения).',
        },
        {
          id: 2.16,
          text: 'Информация о неденежных операциях (зачету, бартеру и пр.).',
        },
        {
          id: 2.17,
          text: 'Информация об условных фактах хозяйственной деятельности (УФХД), в т.ч. с раскрытием следующего:  дата возникновения, описание характера УФХД, сумма, в случае возможности ее оценки, примерная оценка вероятности последствий УФХД.',
        },
        {
          id: 2.18,
          text: 'Информация о событиях после отчетной даты, в т.ч. с раскрытием следующего  характер события, оценка финансовых последствий или констатация невозможности такой оценки (с обоснованием).',
        },
        {
          id: 2.19,
          text: 'Учет отдельных операций на забалансовых счетах: информация по учету товарно-материальных и других ценностей, принятых на ответственное хранение, материалов, принятых в переработку, товаров, принятых на комиссию.',
        },
        {
          id: 2.20,
          text: 'Информация о вознаграждениях ключевого управленческого персонала.',
        },
      ],
    },
    {
      title: '3. Система Информационных технологий (ИТ)',
      id: 3,
      questions: [
        {
          id: 3.1,
          text: 'Наименования применяемого в Компании (компаниях Группы) программного обеспечения (ПО) с указанием области применения (бизнес-процесса), в частности  для ведения бухгалтерского учета (в т.ч., учета основных средств, расчета заработной платы), составления ФО, учета реализации, складского учета и т.п.',
        },
        {
          id: 3.2,
          text: 'Информация о том, приобретен ли программный продукт или создан своими силами.',
        },
        {
          id: 3.3,
          text: 'Информация об изменениях в среде ИТ за 20__ год. Указать вид изменений  существенное (в т.ч. внедрение нового ПО); связанные с техническим обслуживанием; с конфигурацией; формированием отчетов; экстренные изменения; патчи (несущественные поправки); обновления; либо без изменений. Подтверждающая документация о существенных изменениях в ИТ среде. Планирование существенных изменений в ИТ среде.',
        },
        {
          id: 3.4,
          text: 'Информация о существенных сбоях в работе обеспечения ИТ, закрепленная актами.',
        },
        {
          id: 3.5,
          text: 'Регламенты по санкционированию доступа к приложениям ИТ.',
        },
      ],
    },
    {
      title: '4. Детальный запрос информации по областям ФО',
      id: 4,
      subcategories: [
        {
          id: 4.1,
          title: '4.1 Учет основных средств, незавершенного строительства, НМА, запасов:',
          questions: [
            {
              id: 4.111,
              text: 'Правоустанавливающие документы на объекты недвижимости, включая земельные участки.',
            },
            {
              id: 4.112,
              text: 'Ключевые договоры страхования имущества, действовавшие в 20__ году. ',
            },
            {
              id: 4.113,
              text: 'Ключевые договоры страхования имущества, действовавшие в 20__ году. ',
            },
            {
              id: 4.114,
              text: 'Правоустанавливающие документы на объекты недвижимости, включая земельные участки.',
            },
            {
              id: 4.115,
              text: 'Правоустанавливающие документы на объекты недвижимости, включая земельные участки.',
            },
            {
              id: 4.116,
              text: 'Правоустанавливающие документы на объекты недвижимости, включая земельные участки.',
            },
            {
              id: 4.117,
              text: 'Правоустанавливающие документы на объекты недвижимости, включая земельные участки.',
            },
          ],
        },
        {
          id: 4.2,
          title: '4.2. Учет финансовых вложений:',
          questions: [
            {
              id: 4.211,
              text: 'Учет основных средств, незавершенного строительства, НМА, запасов:',
            },
            {
              id: 4.212,
              text: 'Выписки из реестров акционеров, подтверждающие права собственности Компании (компаний Группы) на данные ценные бумаги по состоянию на "31"______20__ года и на "31"______20__ года, а также договоры покупки и прочие документы, подтверждающие инвестиции Компании (компаний Группы).',
            },
            {
              id: 4.213,
              text: 'Расчеты по определению стоимости инвестиций с приложением подтверждающих документов.',
            },
            {
              id: 4.214,
              text: 'Изменение оценки отдельных видов финансовых вложений в бухгалтерском учете и отчетности.',
            },
            {
              id: 4.215,
              text: 'Документы, подтверждающие факт начисления в пользу Компании дивидендов за 20__ год.',
            },
            {
              id: 4.216,
              text: 'Книга учета ценных бумаг за 20__ год.',
            },
            {
              id: 4.217,
              text: 'Договоры на предоставление займов другим компаниям. Расчет начисленных процентов по выданным займам за 20__ год по каждому договору.',
            },
          ],
        },

        {
          id: 4.3,
          title: '4.3 Денежные средства:',
          questions: [
            {
              id: 4.311,
              text: 'Перечень счетов, открытых в кредитных учреждениях, на "31" _________20__ года.',
            },
            {
              id: 4.312,
              text: 'Договоры с банком на открытие всех типов счетов, действующих в 20__ году. Документы по закрытию счетов.',
            },
            {
              id: 4.313,
              text: 'Кассовая книга; акты ревизии кассы за 20__ год.',
            },
          ],
        },

        {
          id: 4.4,
          title: '4.4 Дебиторская  и кредиторская задолженность:',
          questions: [
            {
              id: 4.411,
              text: 'Документы инвентаризации дебиторской задолженности для целей бухгалтерского и налогового учета в разрезе сроков возникновения и категорий клиентов, акты инвентаризации.',
            },
            {
              id: 4.412,
              text: 'Подробная расшифровка дебиторской/кредиторской задолженности по всем счетам в разрезе контрагентов за 20__год с указанием сальдо задолженности на начало и конец года, а также оборотов по дебету и кредиту. ',
            },
            {
              id: 4.413,
              text: 'Расчет резерва по сомнительным долгам для целей бухгалтерского и налогового учета. Списки списанной дебиторской/кредиторской задолженности на "__"_________20__ года и на "__"_________20__ года, а также указание признаков для списания. ',
            },
          ],
        },
        // ... добавьте остальные вопросы
      ],
    },
  ]);
  const [isAllAdded, setIsAllAdded] = useState(false);
  const [newQuestion, setNewQuestion] = useState('');
  const [expandedCategories, setExpandedCategories] = useState({}); // состояние для раскрытия категорий
  const [expandedSubcategories, setExpandedSubcategories] = useState({}); // состояние для раскрытия категорий

  useEffect(() => {
    const allAdded = questions.every((q) => currentQuestionnaire.questions.some((question) => question.id === q.id));
    setIsAllAdded(allAdded);
  }, [questions, currentQuestionnaire]);

  function toggleDefaultHandler(q) {
    const result = currentQuestionnaire.questions.find((question) => question.id === q.id);
    return !!result;
  }

  function toggleQuestion(q) {
    const isQuestionInCurrent = toggleDefaultHandler(q);
    if (!isQuestionInCurrent) {
      setCurrentQuestionnaire({
        ...currentQuestionnaire,
        questions: [...currentQuestionnaire.questions, q],
      });
      log({ message: `Пользователь добавил в анкету вопрос id: ${q.id}`, eventtype: 'info' });
    } else {
      const updatedQuestions = currentQuestionnaire.questions.filter((question) => question.id !== q.id);
      setCurrentQuestionnaire({
        ...currentQuestionnaire,
        questions: updatedQuestions,
      });
      log({ message: `Пользователь убрал из анкеты вопрос id: ${q.id}`, eventtype: 'info' });
    }
  }

  function downloadFiles() {
    console.log('скачать архив');
  }

  function searchQuestion(e) {
    const string = e.target.value.toLowerCase();
    const searchResult = filteredQuestions.map((category) => ({
      ...category,
      questions: category.questions.filter((q) => stringMatch(string, q.text)),
    }));

    setFilteredQuestions(searchResult);
  }

  function toggleAllQuestions() {
    if (!isAllAdded) {
      setCurrentQuestionnaire({
        ...currentQuestionnaire,
        questions,
      });
    } else {
      setCurrentQuestionnaire({
        ...currentQuestionnaire,
        questions: [],
      });
    }
    setFilteredQuestions(filteredQuestions);
    log({ message: `Пользователь нажал кнопку ${isAllAdded ? 'Сбросить все' : 'Добавить все'}`, eventtype: 'info' });
  }

  function addNewQuestion() {
    if (newQuestion.trim() === '') return;

    const newQuestionObj = {
      id: questions.length + 1,
      title: newQuestion,
      isCustom: true,
    };

    setQuestions([...questions, newQuestionObj]);
    setFilteredQuestions([...filteredQuestions, { title: 'Новая категория', id: newQuestionObj.id, questions: [newQuestionObj] }]);

    // Добавление нового вопроса в текущую анкету сразу с активным тумблером
    setCurrentQuestionnaire({
      ...currentQuestionnaire,
      questions: [...currentQuestionnaire.questions, newQuestionObj],
    });

    setNewQuestion('');

    log({ message: `Пользователь добавил новый вопрос: ${newQuestion}`, eventtype: 'info' });
  }

  function removeCustomQuestion(id) {
    setQuestions(questions.filter((q) => q.id !== id));
    setFilteredQuestions(filteredQuestions.map((category) => ({
      ...category,
      questions: category.questions.filter((q) => q.id !== id),
    })));
    setCurrentQuestionnaire({
      ...currentQuestionnaire,
      questions: currentQuestionnaire.questions.filter((q) => q.id !== id),
    });
    log({ message: `Пользователь удалил вопрос id: ${id}`, eventtype: 'info' });
  }

  const dragQuestion = useRef(null);
  const draggedOverQuestion = useRef(null);
  function handleSort() {
    if (dragQuestion.current !== null && draggedOverQuestion.current !== null) {
      const questionsClone = [...filteredQuestions];
      const dragIndex = parseInt(dragQuestion.current, 10);
      const dropIndex = parseInt(draggedOverQuestion.current, 10);

      const temp = questionsClone[dragIndex];
      questionsClone[dragIndex] = questionsClone[dropIndex];
      questionsClone[dropIndex] = temp;

      setFilteredQuestions(questionsClone);
      setCurrentQuestionnaire({
        ...currentQuestionnaire,
        questions: questionsClone,
      });
    }
  }

  if (isLoading) return <Loader />;

  // Функция для переключения состояния раскрытия категории
  const toggleCategory = (id) => {
    setExpandedCategories((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  // Функция для переключения состояния раскрытия подкатегории
  const toggleSubcategory = (id) => {
    setExpandedSubcategories((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  return (
    <div className="questions-card__block">
      <div className="questions-card__block_space-between">
        <Input placeholder="Поиск вопроса" onChange={searchQuestion} />
        <Button onClick={downloadFiles}>Скачать архив</Button>
        <Button onClick={toggleAllQuestions}>{isAllAdded ? 'Сбросить все' : 'Добавить все'}</Button>
      </div>

      {
  filteredQuestions.map((category) => (
    <div key={category.id} className="questions-card__block_align-left">
      <CardSetting key={category.id} title={category.title} active={expandedCategories[category.id]}>
        <img
          className={`ticket-task__chevron ${expandedCategories[category.id] ? 'ticket-task__chevron_active' : ''}`}
          onClick={() => toggleCategory(category.id)}
          src="../../../../../../icons/chevron.svg"
          alt="chevron"
        />
      </CardSetting>
      {expandedCategories[category.id] && (
        <div className="questions-card__questions-list">
          {category.subcategories ? (
            category.subcategories.map((subcategory) => (
              <div key={subcategory.id} className="questions-subcategory">
                <CardSetting key={subcategory.id} title={subcategory.title} active={expandedSubcategories[subcategory.id]}>
                  <img
                    className={`ticket-task__chevron ${expandedSubcategories[subcategory.id] ? 'ticket-task__chevron_active' : ''}`}
                    onClick={() => toggleSubcategory(subcategory.id)}
                    src="../../../../../../icons/chevron.svg"
                    alt="chevron"
                  />
                </CardSetting>
                {expandedSubcategories[subcategory.id] && (
                  <div className="questions-card__questions-list">
                    {subcategory.questions.map((q, index) => (
                      <div
                        key={q.id}
                        id={index}
                        className="questions-card__question-choose-panel"
                        draggable
                        onDragStart={(e) => { dragQuestion.current = e.target.id; }}
                        onDragEnter={(e) => { draggedOverQuestion.current = e.target.id; }}
                        onDragEnd={handleSort}
                        onDragOver={(e) => e.preventDefault()}
                      >
                        <Toggle state={String(toggleDefaultHandler(q))} onClick={() => toggleQuestion(q)} />
                        <span id={index} className="clickable">
                          {q.text}
                        </span>
                        {q.isCustom && (
                          <span onClick={() => removeCustomQuestion(q.id)} className="delete-question">X</span>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))
          ) : (
            category.questions.map((q, index) => (
              <div
                key={q.id}
                id={index}
                className="questions-card__question-choose-panel"
                draggable
                onDragStart={(e) => { dragQuestion.current = e.target.id; }}
                onDragEnter={(e) => { draggedOverQuestion.current = e.target.id; }}
                onDragEnd={handleSort}
                onDragOver={(e) => e.preventDefault()}
              >
                <Toggle state={String(toggleDefaultHandler(q))} onClick={() => toggleQuestion(q)} />
                <span id={index} className="clickable">
                  {q.text}
                </span>
                {q.isCustom && (
                  <span onClick={() => removeCustomQuestion(q.id)} className="delete-question">X</span>
                )}
              </div>
            ))
          )}
        </div>
      )}
    </div>
  ))
}
      {filteredQuestions.length === 0 && (
        <div className="questions-card__question-choose">
          Вопросы отсутствуют
        </div>
      )}

     <div className="questions-card__block_add_new">
        {/*  <div className="questions-card__block_align-left">
          <div className="questions-card__question-choose-panel"> */}
          <CardSetting>
            <Input
              id="question-input"
              className="input-field"
              placeholder="Добавить свой вопрос"
              value={newQuestion}
              onChange={(e) => setNewQuestion(e.target.value)}
            />
            <Button onClick={addNewQuestion}>Добавить</Button>
          </CardSetting>
     </div>
        {/*  </div>
      </div> */}
    </div>
  );
}

export default QuestionsRequest;
