// Тип заявки
export const taskTypes = [
  {
    title: 'Создание рабочего места',
    id: 1,
  },
  {
    title: 'Продление учетной записи',
    id: 2,
  },
];

// Тип договора
export const contractTypes = [
  {
    title: 'Штатный сотрудник',
    id: 3,
  },
  {
    title: 'Договор подряда',
    id: 4,
  },
];

// Офисы
export const offices = [
  {
    title: 'ПРЕО-8',
    id: 1,
  },
  {
    title: 'В обособленном подразделение',
    id: 2,
  },
];

// Офисы
export const officesAp = [
  {
    title: 'ПРЕО-8',
    id: 1,
  },
  {
    title: 'В обособленном подразделение',
    id: 2,
  },
  {
    title: 'БЦ Чери',
    id: 3,
  },
];

// Группы
export const groups = [
  {
    title: 'VP_Partner_Director',
    id: 1,
    description: 'В наименовании должности содержится: «партнер», «директор»',
  },
  {
    title: 'VP_Manager_Profassistant',
    id: 2,
    description: 'В наименовании должности содержится: «старший менеджер», «менеджер», «ассистент менеджера», «ведущий *», «помощник руководителя», «профессиональный ассистент»',
  },
  {
    title: 'VP_Grade4_and_below',
    id: 3,
    description: 'в наименовании должности НЕ содержится: «партнер», «директор», «старший менеджер», «менеджер», «ассистент менеджера», «ведущий *», «помощник руководителя», «профессиональный ассистент»',
  },

  {
    title: 'Без группы',
    id: 4,
    description: 'Сотруднику не требуется бронировать место в офисе',
  },
];

// Список городов
export const cities = [
  {
    title: 'Архангельск',
    id: 1,
  },
  {
    title: 'Астрахань',
    id: 2,
  },
  {
    title: 'Белгород',
    id: 3,
  },
  {
    title: 'Вологда',
    id: 4,
  },
  {
    title: 'Воронеж',
    id: 5,
  },
  {
    title: 'Иркутск',
    id: 6,
  },
  {
    title: 'Казань',
    id: 7,
  },
  {
    title: 'Красноярск',
    id: 8,
  },
  {
    title: 'Москва',
    id: 9,
  },
  {
    title: 'Новосибирск',
    id: 10,
  },
  {
    title: 'Норильск',
    id: 11,
  },
  {
    title: 'Пермь',
    id: 12,
  },
  {
    title: 'Ростов-на-Дону',
    id: 13,
  },
  {
    title: 'Рязань',
    id: 14,
  },
  {
    title: 'Саранск',
    id: 15,
  },
  {
    title: 'Саратов',
    id: 16,
  },
  {
    title: 'Санкт-Петербург',
    id: 17,
  },
  {
    title: 'Сочи',
    id: 18,
  },
  {
    title: 'Сургут',
    id: 19,
  },
  {
    title: 'Тверь',
    id: 20,
  },
  {
    title: 'Тюмень',
    id: 21,
  },
  {
    title: 'Уфа',
    id: 22,
  },
  {
    title: 'Челябинск',
    id: 23,
  },
];
