import React from 'react';
import { useNavigate } from 'react-router';
import './cap.scss';

/**
 * @component Заглушка при отсутствии авторизации
 * @prop {type} string - Тип заглушки (NOAUTH/BLOCK/LOGOUT)
*/
function Cap(props) {
  const { message } = props;
  const history = useNavigate();

  // Обновить страницу
  function reload() {
    window.location.reload();
  }

  return (
    <div className="cap">
      <h2 className="cap__title">{message || 'Доступ запрещён!'}</h2>
      <p className="cap__title">
        <span className="clickable" onClick={reload}>Обновите </span>
        страницу, если ситуация повторяется
        <a className="clickable" href={`mailto:sd@unicon.ru?body=Добрый день! Отсутствует доступ к ресурсу ${window.location.href}.&subject=Доступ к ресурсам UPU`}> обратитесь </a>
        в ИТ-Отдел
      </p>
      <p className="clickable" onClick={() => history(-1)}>Вернуться назад</p>
    </div>
  );
}

export default Cap;
