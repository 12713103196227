import React, { useState, useEffect } from 'react';
import CardBody from '../../../components/Card/CardBody';
import CardSetting from '../../../components/Card/CardSetting';
import Input from '../../../components/UI/Input';
import CardFooter from '../../../components/Card/CardFooter';
import Button from '../../../components/UI/Button/Button';
import Card from '../../../components/Card/Card';
import CardHeader from '../../../components/Card/CardHeader';
import Select from '../../../components/UI/Select';
import InputSearch from '../../../components/UI/InputSearch';
import { clientSendData } from '../../../utils/functions/requests';
import { catchHandler } from '../../../utils/error_handling/error_handling';
import { alertFunction } from '../../../utils/functions/alertFunction';

function NewTempAccessCard(props) {
  const {
    closeHandler, setShowCard, refreshTable,
  } = props;

  const [tickets, setTickets] = useState([]); // переменная состояния для поиска запросов
  const [accessTypes, setAccessTypes] = useState([]);
  const [newAccessCard, setNewAccessCard] = useState({ id: 'new' });

  useEffect(() => {
    getTmpAccessTickets();
  }, []);

  // Получение данных для создания задачи
  async function getTmpAccessTickets() {
    try {
      // Формируем данные для запроса
      const reqData = {
        type: 'getTmpAccessTickets',
      };
      // Отправляем POST запрос на сервер и ждем ответ
      const result = await clientSendData('POST', '/get_tmp_access_tickets', reqData);
      // Если запрос успешен, получаем типы временных доступов
      if (result?.success) {
        setAccessTypes(result.accessTypes);
      }
    } catch (error) {
      // Обрабатываем ошибку
      catchHandler(error, 'getTickets');
    }
  }

  // Функция searchWrapper принимает событие e и выполняет поиск билетов на основе введенного значения в поле ввода
  async function searchWrapper(e) {
  // Если значение в поле ввода пустое, то обновляем состояние билетов без фильтрации
    if (e.target.value.length === 0) setTickets([]);
    if (e.target.value.length > 2) {
      try {
      // Формируем объект запроса с типом и значением для поиска
        const reqData = {
          type: 'tmpAccessSearchTicket',
          query: e.target.value,
        };
        // Отправляем асинхронный запрос на сервер для поиска билетов
        const result = await clientSendData('POST', '/tmp_access_search_ticket', reqData);
        // Если запрос успешен, обновляем состояние билетов с полученными данными
        if (result?.success) setTickets(result.data);
        // Возвращаем пустой массив
        return [];
      } catch (error) {
      // Обрабатываем ошибку, вызывая функцию catchHandler с переданным типом ошибки
        catchHandler(error, 'tmpAccessSearchTicket');
        // Возвращаем пустой массив
        return [];
      }
    } return [];
  }

  async function saveNewTempAccessCard(newAccessCardData) {
    try {
      const reqData = {
        data: newAccessCardData,
        type: 'saveNewTempAccessCard',
      };
      if (newAccessCard.ticket_number) {
        const result = await clientSendData('POST', '/save_new_temp_access_card', reqData);
        if (result.success) {
          await alertFunction('create_task', 'client');
          setShowCard(false);
          refreshTable();
        } else alertFunction('something_went_wrong', 'client');
      } else alertFunction('required_fields', 'client');
    } catch (error) {
      catchHandler(error, 'saveNewTempAccessCard'); // обработчик ошибок
    }
  }

  return (
    <Card setShow={setShowCard} id="new_tmp_access_card">
      <CardHeader />
      <CardBody>
        <CardSetting title="№ запроса:">
            <InputSearch
              id="new_temp_access_card"
              array={tickets}
              placeholder="Номер запроса"
              onSearch={(e) => searchWrapper(e)}
              onChoose={(ticket) => setNewAccessCard({
                ...newAccessCard, ticket_id: ticket.id, ticket_number: ticket.title, initiator_id: ticket.initiator_id, initiator: ticket.initiator,
              })}
            />

        </CardSetting>
        <CardSetting title="Инициатор:">{newAccessCard.initiator}</CardSetting>
        <CardSetting title="Тип задачи:">
          <Select
            id="select-access_type"
            array={accessTypes}
            defaultValue={newAccessCard.task_type_title}
            onChoose={(accessType) => setNewAccessCard((state) => ({
              ...state,
              task_type: accessType.id,
              task_type_title: accessType.title,
              notify_days: accessType.parameters.notify_days,
            }))}
          />
        </CardSetting>
        <CardSetting title="Дата исполнения:"><Input type="date" onChange={(e) => setNewAccessCard({ ...newAccessCard, date_fulfilled: e.target.value })} /></CardSetting>
        <CardSetting title="Ресурс:"><Input onChange={(e) => setNewAccessCard({ ...newAccessCard, resource: e.target.value })} /></CardSetting>
        <CardSetting title="Уведомить за (количество дней):">
          <div>
            <Input defaultValue={newAccessCard?.notify_days} type="number" min="1" id="days" onChange={(e) => setNewAccessCard({ ...newAccessCard, notify_days: e.target.value })} />
            {/* {' '} дней */}
          </div>
        </CardSetting>
        <CardSetting title="Комментарий:" />
        <textarea className="tempAccessComment" onChange={(e) => setNewAccessCard({ ...newAccessCard, comment: e.target.value })} />
      </CardBody>
      <CardFooter>
        <Button onClick={() => saveNewTempAccessCard(newAccessCard)}>
          Создать
        </Button>
        <Button onClick={closeHandler}>Закрыть</Button>
      </CardFooter>
    </Card>
  );
}

export default NewTempAccessCard;
