import React from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../../../components/UI/Button/Button';
import TextArea from '../../../../../components/UI/TextArea';
import classes from '../../../../../components/UI/Button/button.module.scss';
import { createAction } from '../../../../../utils/redux/store';
import Answers from '../../Answers';
import { commentActivity, leaveComment } from '../../functions';

// Стандартное отображение - блок информации
function InfoBlock() {
  const userAnswers = useSelector((state) => state.interview.user_answers); // ответы пользователя
  const interview = useSelector((state) => state.interview.interview);
  const isWiki = useSelector((state) => state.interview.is_wiki);
  const isShowFormMobile = useSelector((state) => state.interview.is_show_form_mobile);
  const currentQuestion = useSelector((state) => state.interview.current_question);
  const isSearching = useSelector((state) => state.interview.is_searching);
  const { questionnaire, answers } = interview;
  const questionsFound = useSelector((state) => state.interview.questions_found);
  const currentComment = useSelector((state) => state.interview.comment); // текущий комментарий

  // Показать/скрыть форму с заголовками вопросов
  function toggleFormMobile(e) {
    e.stopPropagation(); // Предотвратить погружения события в родительский элемент
    createAction('SET_IS_SHOW_FORM_MOBILE', !isShowFormMobile);
  }

  // Выбрать из результатов поиска
  function chooseSearchResult(q) {
    createAction('SET_CURRENT_QUESTION', q); // установить выбранный вопрос
    createAction('SET_IS_SEARCHING', false); // выйти из поиска
  }

  return (
    <div id="interview__info-block" className="upu-service__info-block" onClick={() => createAction('SET_IS_SHOW_FORM_MOBILE', false)}>
      {/* Развернуть меню */}
      <img className="interview__chevron" src="../../icons/chevron.svg" alt="chevron" onClick={toggleFormMobile} />
      {/* Заголовок анкеты */}
      <h2 id="interview__title" className="upu-service__title">{questionnaire.title}</h2>

      <div className="interview__wrapper">
        {/* Результаты поиска */}
        {isSearching && (
        <div className="interview__question-answer">
          {questionsFound.length === 0 ? (// если массив с результатами пустой
          <h2 id="interview__title" className="upu-service__title">Совпадений не найдено</h2>
          ) : ( // иначе результаты
            questionsFound.map((q) => (
            <div key={q.id} className="interview__search-result" onClick={() => chooseSearchResult(q)}>
              <p className="interview__search-result-title">{q.title}</p>
              {/* Заголовок вопроса */}
              <p className="interview__question-content">{q.search_title}</p>
              {/* Содержание вопроса */}
            </div>
            )))}
          <Button className={classes.button} onClick={() => createAction('SET_IS_SEARCHING', false)}>Вернуться к ответу</Button>
        </div>)}

        {/* При выдаче результатов поиска вопрос скрывается */}
        {!isSearching && (
        <div className="interview__question-answer">
          {/* <div
            className="interview__question-content"
            dangerouslySetInnerHTML={{ __html: currentQuestion?.question }}
          /> */}

          {/* Содержание вопроса */}
          <div className="interview__question-content">
            {currentQuestion?.question.split('\n') // разделить строку по переносам
              .map((row, index) => ( // показать каждую в параграфе
              <p key={index} className="interview__question-row">{row}</p>))}
          </div>

          {/* Ответы */}
          <Answers answers={answers} />

          {/* Поле комментария */}
          {!isWiki && currentQuestion && ( // если режим справочника выкл и выбран вопрос
          // если комментарии включены или на текущий вопрос можно ответить только комментарием
            questionnaire?.comments || currentQuestion.output_id === 4) && (
          <div className="interview__comment-block">
            <TextArea // тогда показывается поле для ввода комментария
              id="interview__textarea"
              placeholder="Комментарий"
              value={currentComment} // содержание элемента контролируется переменной
              disabled={commentActivity(userAnswers, currentQuestion)}
              onChange={(e) => leaveComment(e, userAnswers, currentQuestion, questionnaire)}
            />
          </div>)}
        </div>)}
      </div>
    </div>
  );
}

export default InfoBlock;
