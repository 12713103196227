import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router';
import { Navigate } from 'react-router-dom';
import ErrorBoundary from '../error_handling/ErrorBoundary';
import routes from './routes';
import Loader from '../../components/UI/Loader';
import CapPage from '../../pages/Cap/CapPage';

/**
* @component Маршрутизатор приложения
- Для каждого маршрута указывается путь и компонент для рендера
- Каждый компонент обёрнут в предохранитель ErrorBoundary для обработки ошибок рендера, возникающих в компоненте
*/

function AppRouter() {
  const isAuth = useSelector((state) => state.auth.auth); // состояние аутентификации
  const isLogOut = useSelector((state) => state.auth.is_logout); // состояние выхода из системы
  const isSessionBlocked = useSelector((state) => state.auth.blocked); // состояние блокировки сессии
  const isAuthenticating = useSelector((state) => state.auth.is_authenticating); // состояние процесса аутентификации

  // Если пользователь не аутентифицирован,
  // Или сессия заблокирована,
  // Или пользователь вышел из системы -
  // Показывается страница с сообщением и любой маршрут перенаправляется главную страницу
  if (!isAuth || isSessionBlocked || isLogOut) {
    let type = '';
    if (!isAuth) type = 'NOAUTH';
    else if (isSessionBlocked) type = 'BLOCK';
    else if (isLogOut) type = 'LOGOUT';

    return (
      <Routes>
        <Route path="/" element={<CapPage type={type} />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    );
  }

  // Если аутентификация в процессе - показывается лоадер
  if (isAuthenticating) return <Loader />;

  // Иначе аутентификация прошла успешно - отображаем необходимую страницу
  return (
    <Routes>
      {routes.map((route) => {
        const { path, component } = route;
        return (
          <Route
            key={path}
            path={path}
            element={<ErrorBoundary>{component}</ErrorBoundary>}
          />
        );
      })}
    </Routes>
  );
}

export default AppRouter;
