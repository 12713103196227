import React, { useRef, useState } from 'react';
import { catchHandler } from '../../utils/error_handling/error_handling';
import { stringMatch } from '../../utils/functions/stringHandling';

/**
* @component Хедер таблицы - строка фильтрации - ячейка фильтрации
@prop {type} string тип поля
@prop {field} string Наименование поля
@prop {mobile_hide} boolean // параметр скрытия при мобильном отображениии
@prop {setArray} function Обновление состояния массива отображаемого в таблице
@prop {filterField} string Фильтруемое поле
@prop {setFilterField} function Обновление фильтруемого поля
@prop {setSortField} function Обновление сортируемого поля
@prop {defaultArray} array Исходный массив данных отображаемый в таблице
@prop {setFilteredArray} function Обновление состояния отфильтрованного массива
@prop {ext_search} function Внешний обработчик фильтрации
@prop {disable_search} boolean Параметр выключения поиска в колонке
*/

function FilterCell(props) {
  const {
    type, // тип поля
    field, // наименование поля
    mobile_hide, // параметр скрытия при мобильном отображениии
    setArray, // обновление состояния массива, отображаемого в таблице
    filterField, // фильтруемое поле
    setFilterField, // обновление фильтруемого поля
    setSortField, // обновление сортируемого поля
    defaultArray, // исходный массив данных отображаемый в таблице
    setFilteredArray, // обновление состояние отфильтрованного массива
    ext_search, // внешний обработчик фильтрации
    disable_search, // параметр выключения поиска в колонке
    // isFilter, // состояние видимости блока фильтрации

  } = props; // свойства компонента

  const inputRef = useRef(null); // ссылка на инпут как DOM элемент
  //   const [startDate, setStartDate] = useState(''); // дата начала периода
  //   const [endDate, setEndDate] = useState(''); // дата окончания периода
  //   const inputStartRef = useRef(null); // ссылка на инпут как DOM элемент
  //   const inputEndRef = useRef(null); // ссылка на инпут как DOM элемент

  // Выбор обработчика поиска
  function chooseHandler(e) {
    if (e.type === 'change') { // при изменении данных
      if (ext_search) ext_search(e, field); // если присутствует внешний обработчик он выполняется
      else searchHandler(e); // иначе выполняется локальный обработчик
    }
    if (e.type === 'keyup' && ext_search) { // при нажатии клавиш
      ext_search(e, field); // если присутствует внешний обработчик он выполняется
    }
  }

  // Обработчик поиска по колонке
  function searchHandler(event) {
    try {
      setFilterField(field); // установить фильтруемое поле

      // Результаты фильтрации
      const searchResult = defaultArray.filter((item) => stringMatch(event.target.value, String(item[field])));

      // если строка поиска не пустая - отображение в таблице результатов поиска
      if (event.target.value.length > 0) {
        setArray(searchResult);
        setFilteredArray(searchResult);
      } else setArray(defaultArray); // иначе - отображение в таблице массива по умолчанию
    } catch (error) {
      catchHandler(error, 'searchHandler'); // обработка ошибок
    }
  }

  //   // Проверить строковую дату
  //   function checkDateString(string) {
  //     const date = new Date(string); // преобразование в объект даты
  //     if (date.toString() === 'Invalid Date') return null;
  //     return date;
  //   }

  //   // Фильтрация по дате
  //   function filterByDate(start, end) {
  //     try {
  //       setFilterField(field); // установить фильтруемое поле

  //       const startDate = checkDateString(start); // преобразование строки в объект
  //       const endDate = checkDateString(end); // преобразование строки в объект
  //       let searchResult = [];

  //       if (start && !end) { // если выбрана только дата начала
  //         searchResult = defaultArray.filter((item) => { // Результаты фильтрации
  //           const itemDate = checkDateString(item[field]); // Проверка значания ключа
  //           if (itemDate) return itemDate > startDate; // Возвращаем значения позже даты начала
  //           return true;
  //         });
  //       }
  //       if (start && end) { // если есть начало и конец периода
  //         searchResult = defaultArray.filter((item) => { // Результаты фильтрации
  //           const itemDate = checkDateString(item[field]); // Проверка значания ключа
  //           if (itemDate) return itemDate > startDate && itemDate < endDate; // Возвращаем значения между началом и концом
  //           return true;
  //         });
  //       }
  //       if (!start && end) { // если выбрана только дата окончания
  //         searchResult = defaultArray.filter((item) => { // Результаты фильтрации
  //           const itemDate = checkDateString(item[field]); // Проверка значания ключа
  //           if (itemDate) return itemDate < endDate; // Возвращаем значения раньше даты окончания
  //           return true;
  //         });
  //       }
  //       setArray(searchResult); // обновить отображаемый массив
  //       setFilteredArray(searchResult); // обновить отфильтрованный массив (для сортировки)
  //     } catch (error) {
  //       catchHandler(error, 'filterByDate'); // обработка ошибок
  //     }
  //   }

  // Обработчик выбора поля для поиска
  function onClickHandler() {
    // Если поиск выполняется в другом поле, остальные сбрасываются
    if (filterField !== field) {
      setSortField(''); // сбросить сортируемое поле
      setFilterField(field); // установить поле для поиска
      setArray(defaultArray); // сбросить фильтры
    }
  }

  // Если поиск выполняется в другом поле, остальные сбрасываются
  if (inputRef.current && filterField !== field) inputRef.current.value = '';
  //   if (type === 'date') { // если тип ячейки - дата
  //     // Сбросить инпуты дат если поиск выполняется в другом поле
  //     if ((inputStartRef.current || inputEndRef.current) && filterField !== field) {
  //       inputStartRef.current.value = ''; // сброс значения даты начала
  //       inputEndRef.current.value = ''; // сброс значения даты окончания
  //     }
  //     return (
  //         <td className="upu-table__table-data">
  //             <input
  //                 type={filterField !== field ? 'text' : 'date'}
  //                 ref={inputStartRef}
  //                 className={filterField !== field ? 'input input_disabled' : 'input date-input'}
  //                 placeholder="от"
  //                 onChange={(e) => {
  //                 const dateString = e.target.value ? `${e.target.value} 00:00:00` : ''; // начало с 00:00
  //                 setStartDate(`${e.target.value} 00:00:00`); // установить дату начала
  //                 filterByDate(dateString, endDate);
  //                 }}
  //                 onClick={onClickHandler}
  //                 disabled={disable_search}
  //             />
  //             <input
  //                 type={filterField !== field ? 'text' : 'date'}
  //                 ref={inputEndRef}
  //                 className={filterField !== field ? 'input input_disabled' : 'input date-input'}
  //                 placeholder="до"
  //                 onChange={(e) => {
  //                 const dateString = e.target.value ? `${e.target.value} 23:59:59` : ''; // окончание до 23:59
  //                 setEndDate(`${e.target.value} 23:59:59`); // установить дату окончания
  //                 filterByDate(startDate, dateString);
  //                 }}
  //                 onClick={onClickHandler}
  //                 disabled={disable_search}
  //             />
  //         </td>
  //     );
  //   }
  return (
    <td className={`upu-table__table-data${mobile_hide ? ' mobile_hide' : ''}`}>
        <input
          type="text"
          ref={inputRef}
          className={filterField !== field ? 'input input_disabled' : 'input search-input'}
          placeholder=" "
          onChange={chooseHandler}
          onKeyUp={chooseHandler}
          onClick={onClickHandler}
          disabled={disable_search}
        />
    </td>
  );
}

export default FilterCell;
