import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { createAction } from '../../../../../utils/redux/store';
import TextProcessor from '../../../../../components/TextProcessor/TextProcessor';

/**
* @component Управление Базой знаний - Вопросы - Карточка вопроса - вкладка редактирования текста через текстовый процессор
* @prop {question} object - Объект вопроса
* @prop {setQuestion} function - Обновление состояния объекта вопроса
* @prop {field} string - наименование ключа объекта, значение которого редактируется
*/
function TextProcessorTab(props) {
  const { question, setQuestion, field } = props;

  const isTPOpen = useSelector((state) => state.kb.is_tp_open); // состояние активности текстового процессора
  const questionRef = useRef(null); // блок отображения вопроса  как DOM элемент

  // Обновить изменённый вопрос
  function textProcessorHandler(html) {
    questionRef.current.innerHTML = html; // обновить отображаемый вопрос
    createAction('SET_IS_TP_OPEN', false); // выкл состояние активности текстового процессора

    const copy = { ...question }; // копия исходного объекта вопроса
    copy[field] = html; // обновление вопроса
    setQuestion(copy);
  }

  return (
    <div className="upu-card__block">
      {/* Блок отображения вопроса */}
      <div
        dangerouslySetInnerHTML={{ __html: question[field] || '' }} // аналог SetInnerHTML
        ref={questionRef}
        onClick={() => createAction('SET_IS_TP_OPEN', true)} // включить состояние активности текстового процессора
        className={isTPOpen ? 'upu-card__question' : 'upu-card__question_active'}
      />

      {/* Если вопрос редактируется - показывается тектовый процессор */}
      {isTPOpen && (
      <TextProcessor
        content={questionRef.current.innerHTML}
        handler={textProcessorHandler}
        cancel={() => createAction('SET_IS_TP_OPEN', false)} // выкл состояние активности текстового процессора
      />)}
    </div>
  );
}

export default TextProcessorTab;
