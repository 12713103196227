import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { clientSendData } from '../../../utils/functions/requests';
import { alertFunction } from '../../../utils/functions/alertFunction';
import { catchHandler } from '../../../utils/error_handling/error_handling';
import { authorization } from '../../../utils/functions/authenticate';
import { setOperInfo, log } from '../../../utils/functions/others';
import { chooseDivision, getUserDivisions } from '../Common/functions';
import Loader from '../../../components/UI/Loader';
import Select from '../../../components/UI/Select';
import Service from '../../../components/Service/Service';
import ServicePanel from '../../../components/Service/ServicePanel';
import ThemeHierarchy from './ThemeHierarchy';
import QuestionCard from './QuestionCard/QuestionCard';
import Questions from './Questions';
import CapKB from '../Common/CapKB';
import Cap from '../../Cap/Cap';
import '../Common/common.scss';
import './interface.scss';

/**
* @component База знаний - Интерфейс пользователя (основной компонент)
*/
function Interface() {
  const [isAuthorized, setIsAuthorized] = useState(false); // Состояние авторизации
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [questionsId, setQuestionsId] = useState([]); // массив id вопросов, относящихся к выбранным темам
  const [showCard, setShowCard] = useState(false); // состояние отображения карточки вопроса
  const [searchParams, setSearchParams] = useSearchParams(); // параметры поиска url
  const activeThemeScheme = useSelector((state) => state.kb.active_scheme.theme); // данные активной схемы темы
  const activeQuestionScheme = useSelector((state) => state.kb.active_scheme.question); // данные активной схемы вопроса
  const userDivisions = useSelector((state) => state.kb.user_divisions); // подразделения доступные пользователю
  const currentDivision = useSelector((state) => state.kb.current_division); // выбранное подразделение
  const areThereAvailableDivisions = userDivisions.length > 0; // есть доступные подразделения?
  const isThereActiveSchemes = activeThemeScheme && activeQuestionScheme; // есть активные схемы?
  const questionScheme = activeQuestionScheme?.scheme; // активная схема вопроса
  const numberRecentQuestions = 50; // количество последних вопросов для показа

  useEffect(() => {
    setOperInfo();
    awaitRequests();
  }, []);

  async function awaitRequests() {
    setIsLoading(true);
    const checkAuthorization = await authorization(); // авторизация
    setIsAuthorized(checkAuthorization); // обновление состояния авторизации
    await getUserDivisions(); // получить подразделения пользователя
    checkSearchParams();
    setIsLoading(false);
  }

  // Получить вопросы по выбранной теме
  async function getThemesQuestions(array) {
    try {
      if (array.length > 0) {
        const reqData = {
          type: 'getThemesQuestions',
          location: window.location.pathname,
          themes_id: array,
          division_id: currentDivision?.id,
          scheme: questionScheme,
        };
        const result = await clientSendData('POST', '/get_themes_questions', reqData);
        if (result === 'bad_request') alertFunction('bad_request', 'clientPost');
        else if (result.length > 0) setQuestionsId(result); // Результат записывается в состояние
        else setQuestionsId(null);
      } else return;
    } catch (error) {
      catchHandler(error, 'getThemesQuestions');
    }
  }

  // Получить последние вопросы
  async function getLastQuestions() {
    try {
      const themeKey = Object.keys(questionScheme).find((key) => questionScheme[key].link_kb_themes);
      const reqData = {
        type: 'getLastQuestions',
        location: window.location.pathname,
        division: currentDivision,
        number: numberRecentQuestions,
        theme_key: themeKey,
      };
      const result = await clientSendData('POST', '/get_last_questions', reqData);
      if (result === 'bad_request') alertFunction('bad_request', 'clientPost');
      else {
        log({ message: `Пользователь нажал кнопку "Последние ${numberRecentQuestions} вопросов" в дереве тем вопросов`, eventtype: 'info' });
        if (result.length > 0) setQuestionsId(result); // Результат записывается в состояние
        else setQuestionsId(null);
      }
    } catch (error) {
      catchHandler(error, 'getThemesQuestions');
    }
  }

  // Проверка наличия параметров поиска в url
  function checkSearchParams() {
    // если url содержит параметр поиска "question" открывается карточка вопроса
    if (searchParams?.get('question')) setShowCard(true);
  }

  if (isLoading) return <Loader />; // если загружается - показывается лоадер
  if (isAuthorized) { // если авторизован
    // если пользователю не доступно ни одно подразделение
    if (!areThereAvailableDivisions) {
      return (
        <Service id="interface"><CapKB message="Доступные подразделения отсутствуют" /></Service>
      );
    } // сообщение

    // если не выбрано подразделение
    if (!currentDivision) {
      return ( // выбор подразделения и сообщение
      <Service id="interface" vertical>
        <ServicePanel id="interface__hierarchy">
          {/* Выбор подразделения */}
          {userDivisions.length > 1 && (
          <Select
            id="knowledge-base__select-division"
            array={userDivisions}
            onChoose={chooseDivision}
            defaultValue={currentDivision?.title || 'Подразделение'}
          />)}
        </ServicePanel>
        <CapKB message="Выберите подразделение" />
      </Service>
      );
    }

    // если нет активных схем в выбранном подразделении
    if (!isThereActiveSchemes) {
      return ( // выбор подразделения и сообщение
        <Service id="interface" vertical>
          <ServicePanel id="interface__hierarchy">
            {/* Выбор подразделения */}
            {userDivisions.length > 1 && (
            <Select
              id="knowledge-base__select-division"
              array={userDivisions}
              onChoose={chooseDivision}
              defaultValue={currentDivision?.title || 'Подразделение'}
            />)}
          </ServicePanel>
          <CapKB message="В выбранном подразделении отсутствуют активные схемы" />
        </Service>
      );
    }

    // Иначе - выбор подразделения, темы, вопросы
    return (
      <Service id="interface" vertical>

        <ThemeHierarchy
          getThemesQuestions={getThemesQuestions}
          getLastQuestions={getLastQuestions}
          setQuestionsId={setQuestionsId}
          numberRecentQuestions={numberRecentQuestions}
        />

        {questionsId ? (// вопросы отображаются если есть массив id
        <Questions range={questionsId} setShowCard={setShowCard} />) : (
        <CapKB message="Вопросы отсутствуют" />)}

        {/* Если нужно отобразить вопрос -  он отображается */}
        {showCard && <QuestionCard setShowCard={setShowCard} /> }

      </Service>
    );
  } return <Cap />; // если не авторизован - заглушка
}

export default Interface;
