import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { catchHandler } from '../../../../utils/error_handling/error_handling';
import { clientSendData } from '../../../../utils/functions/requests';
import { clearHtmlString } from '../../../../utils/functions/stringHandling';
import {
  checkLength,
  createPeriod,
  getMonthName,
  getPeriodByInterval,
} from '../../functions';
import TableWrapper from '../../Components/TableWrapper/TableWrapper';
import Loader from '../../../../components/UI/Loader';
import Canvas from '../../Components/Canvas/Canvas';

/**
* @component Сервис отчётов анкетирования - Расширенный отчёт
* 3 - Куда уходят работать увольняющиеся
*/
function ReportNewJob() {
  const isShowChart = useSelector((state) => state.reports.is_show_chart);// состояние видимости графика
  const [months, setMonths] = useState([]); // период по месяцам
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [rawReport, setRawReport] = useState([]); // сырые данные отчёта
  const [report, setReport] = useState([]); // готовые данные отчёта
  const [searchParams, setSearchParams] = useSearchParams(); // параметры ссылки
  const start_date = searchParams.get('start_date'); // дата начала
  const end_date = searchParams.get('end_date'); // дата кончания
  const period = !!(start_date && end_date); // наличие периода
  const title = 'Куда уходят работать увольняющиеся';

  useEffect(() => {
    getReportNewJob(); // получить отчёт по выходному интервью
  }, []);

  // получить отчёт по выходному интервью
  async function getReportNewJob() {
    try {
      const reqData = {
        type: 'getReportNewJob',
        period,
        start_date,
        end_date,
      };
      setIsLoading(true); // состояние - загружется
      const result = await clientSendData('POST', '/get_oi_report_new_job', reqData);
      if (result?.report?.length > 0) {
        // если нет периода - создать на основании дат результата
        if (!period) setMonths(createPeriod(result.report, 'dismissal_date'));
        // Иначе - создать на основании дат периода
        else setMonths(getPeriodByInterval('month', start_date, end_date));

        setRawReport(result.report);
        createReport(result);
      } else setIsLoading(false); // загрузка завершена
    } catch (error) {
      catchHandler(error, 'getReportNewJob');
      setIsLoading(false); // загрузка завершена
    }
  }

  // Создание отчёта на основе полученных данных
  function createReport(report) {
    setReport(report.answers.map((a) => {
      const userAnswers = report.report.filter((r) => r.answer_id === a.id);
      return {
        title: a.answer, value: userAnswers,
      };
    }));

    setIsLoading(false); // загрузка завершена
  }

  if (isLoading) return <Loader />;
  if (isShowChart) {
    return (
      <Canvas
        id="reports-oi-new-job__canvas"
        title={title}
        label="Количество, %"
        labels={report.map((row) => clearHtmlString(row.title))}
        values={report.map((row) => (row.value.length / rawReport.length) * 100)}
      />
    );
  }
  return (
    <TableWrapper id="reports-oi-new-job" title={title} back_button chart_button>
      <table id="reports-oi-new-job" className="upu-table">
        <thead className="upu-table__table-head">
          <tr className="upu-table__table-row">
            <td className="upu-table__table-data">Период</td>
            {months.map((month) => <td key={month} className="upu-table__table-data">{getMonthName(month)}</td>)}
            <td className="upu-table__table-data">Итого</td>
          </tr>
        </thead>
        <tbody className="upu-table__table-body">
          {/* Проходим по отчёту */}
          {report.map((row) => (
            // Выводим строки
            <tr key={row.title} className="upu-table__table-row">
              {/* Первая ячейка - описание стажа */}
              <td className="upu-table__table-data" dangerouslySetInnerHTML={{ __html: row.title }} />
              {/* Далее проходим по месяцам */}
              {months.map((month) => {
                // Находим уволившихся в текущем месяце
                const filterValues = row.value.filter((value) => {
                  const dismissalDate = new Date(value.dismissal_date).getMonth(); // месяц увольнения
                  const fieldDate = new Date(month).getMonth(); // месяц в колонке
                  return dismissalDate === fieldDate; // вернуть совпадения месяцев
                });
                // Выводим ячейки (Длина массива уволившихся в текущем месяце)
                return <td key={row.title + month} className="upu-table__table-data">{checkLength(filterValues)}</td>;
              })}
              {/* Последняя ячейка - общее количество */}
              <td className="upu-table__table-data">{row.value.length}</td>
            </tr>
          ))}
        </tbody>
        <tfoot className="upu-table__table-foot">
          {/* Последняя строка - итого за месяц */}
          <tr className="upu-table__table-row">
            {/* Первая ячейка - Итого */}
            <td className="upu-table__table-data"><b>Итого</b></td>

            {months.map((month, index) => {
              // Находим уволившихся в текущем месяце
              const filterValues = rawReport.filter((value) => {
                const dismissalDate = new Date(value.dismissal_date).getMonth(); // месяц увольнения
                const fieldDate = new Date(month).getMonth(); // месяц в колонке
                return dismissalDate === fieldDate; // вернуть совпадения месяцев
              });
              return (
                // Выводим ячейки
                <td key={month + index} className="upu-table__table-data">
                  {/* Длина массива уволившихся в текущем месяце */}
                  {filterValues.length}
                </td>
              );
            })}
            {/* Последняя ячейка - общее количество за все даты */}
            <td className="upu-table__table-data">{rawReport.length}</td>
          </tr>
        </tfoot>
      </table>
    </TableWrapper>
  );
}

export default ReportNewJob;
