/* eslint-disable no-param-reassign */
import { catchHandler } from '../../../utils/error_handling/error_handling';
import { alertFunction } from '../../../utils/functions/alertFunction';
import { clientSendData } from '../../../utils/functions/requests';
import { createAction, store } from '../../../utils/redux/store';
import { log } from '../../../utils/functions/others';

/**
* @function Управление Получение подразделений доступных пользователю
*/
export async function getUserDivisions() {
  try {
    const reqData = {
      type: 'getUserDivisions',
      location: window.location.pathname,
    };
    const result = await clientSendData('POST', '/get_user_divisions', reqData);
    if (result) {
      createAction('SET_USER_DIVISIONS', result); // Результат записывается в состояние
      await chooseDivision(result[0]);
    }
  } catch (error) {
    catchHandler(error, 'getUserDivisions');
  }
}

/**
* @function Выбор подразделения
* @arg {division} object - (Обязательный) объект выбранного подразделения
*/
export async function chooseDivision(division) {
  createAction('SET_CURRENT_DIVISION', division); // обновить состояние в хранилище
  await getActiveScheme(division.id); // получить активную схему подразделения
  log({ message: `Пользователь выбрал подразделение ${division.title} id: ${division.id}` });
}

/**
* @function Получение данных активной схемы
* @arg {division} number (Обязательный) id выбранного подразделения
*/
export async function getActiveScheme(division_id) {
  try {
    // если подразделение выбрано
    if (division_id) {
      const reqData = {
        type: 'getScheme',
        division_id,
      };
      // Выполняется запрос на получение активной схемы
      const result = await clientSendData('POST', '/get_scheme', reqData);
      // Если есть результат - запись активной схемы в хранилище
      if (result) createAction('SET_ACTIVE_SCHEME', result);
      // если подразделение не выбрано - уведомление
    } else alertFunction('choose_division', 'clientPost');
  } catch (error) {
    catchHandler(error, 'getActiveScheme');
  }
}

/**
* @function Сортировка полей объекта на основании параметров схемы
* @arg {settings} object (Обязательный) объект с данными для сортировки
* @key {scheme} object - (Обязательный) схема сущности
* @key {key} string - (Обязательный) ключ для сортировки (например 'show_in_table')
* @key {sub_key} string - вложенный ключ параметра (например 'show_in_table.order')
* @key {fields} array - (Обязательный) поля для сортировки
*/
export function sortFieldsByScheme(settings) {
  const {
    scheme, key, sub_key, fields,
  } = settings;

  const sortedFields = fields.sort((a, b) => {
    let aOrder = scheme[a]?.[key];
    let bOrder = scheme[b]?.[key];
    if (sub_key) {
      aOrder = aOrder[sub_key];
      bOrder = bOrder[sub_key];
    }
    // Показать сначала те поля, у которых есть параметр order, затем остальные
    if (!aOrder) return 1;
    if (!bOrder) return -1;
    if (aOrder === bOrder) return 0;
    return aOrder < bOrder ? -1 : 1;
  });

  return sortedFields;
}

/**
* @function Определение обязательности заполнения поля
* @arg {field_data} object (Обязательный) объект настроек поля в схеме
* @arg {object} object (Обязательный) объект сущности (тема/вопрос)
*/
export function isFieldRequired(field_data, object) {
  // условие для обязательного заполнения
  const clause = field_data?.required_clause;
  // если поле обязательно для заполнения - вернуть true
  if (field_data?.required) return true;
  // если есть условие для обязательного заполнения
  if (clause) {
    // данные условия
    const { key, existence } = clause;
    // если значение ключа в объекте равно значению условия в схеме - вернуть true
    if (Boolean(object[key]) === existence) return true;
    return false;
  } return false;
}

/**
* @function Валидация объекта на основании параметра required в схеме объекта
* @arg {action} string (Обязательный) тип действия, производимого с сущностью (create/edit/delete)
* @arg {scheme} object (Обязательный) схема сущности (темы/вопроса)
* @arg {object} object (Обязательный) объект сущности (тема/вопрос)
*/
export function validateObject(action, scheme, object) {
  if (action === 'create' || action === 'edit') { // объект создаётся или редактируется
    // Обязательные, либо обязательные с условием к заполнению поля
    const requiredFields = Object.keys(scheme).filter((item) => isFieldRequired(scheme[item], object));

    const result = requiredFields.map((item) => { // проходим по полям
      if (object[item]) return true; // если объект содержит значение по ключу вернётся true
      return false; // иначе false
    });

    if (!result.includes(false)) return true; // если результат не содержит false значение - валидация пройдена
    return false; // иначе не пройдена
  } return true; // иначе валидация пройдена
}

/**
* @function Обработка автозамены значения ключа
* @arg {scheme} object (Обязательный) схема сущности (темы/вопроса)
* @arg {object} object (Обязательный) объект сущности (тема/вопрос)
*/
export function autoReplaceHandler(scheme, object) {
  // копия объекта
  const copy = { ...object };
  // поля имеющие настройку auto_replace
  const fieldsForReplace = Object.keys(scheme).filter((field) => scheme[field]?.auto_replace);
  // проходим по полученным полям
  fieldsForReplace.forEach((field) => {
    let value = copy[field]; // значение ключа в объекте сущности
    // значение отсутствует только если оно undefined или null
    const isValueMissing = value === undefined || value === null;
    // если значение отсутствует
    if (isValueMissing) {
      // Берем наименование ключа из которого брать значение для замены
      const replacementKey = scheme[field].auto_replace;
      // Устанавливаем в текущее поле значение из замещающего ключа
      // если и там значения нет, то устанавливается null
      value = copy[replacementKey] || null;
    }
  });

  // Вернуть обновленный объект
  return copy;
}

/**
* @function Определение значения скрытия ключа схемы
* @arg {fieldData} object (Обязательный) объект настроек поля в схеме
* @arg {object} object (Обязательный) объект сущности (тема/вопрос)
*/
export function defineHideValue(fieldData, object) {
  const dependence = fieldData?.dependence;
  if (Boolean(object[dependence?.key]) === dependence?.existence && dependence?.hide) return true;
  return false;
}

/**
* @function Обработка изменений зависимых ключей объекта
* @arg {setting} object (Обязательный) Объект с данными, которые необходимо добавить в сущность (например {title: "Вопрос"})
* @arg {scheme} object (Обязательный) тема сущности
* @arg {object} object (Обязательный) объект сущности (тема/вопрос)
*/
export function dependentKeysHandler(setting, scheme, object) {
  const dependentKeys = Object.keys(scheme).filter((key) => scheme[key]?.dependence); // Зависимые ключи
  const trackingKeys = dependentKeys.map((key) => scheme[key].dependence?.key); // Отслеживаемые ключи
  const changingKeys = Object.keys(setting); // Изменяемые ключи

  // Проходим по изменяемых ключам
  changingKeys.forEach((key) => {
    const newValue = setting[key]; // новое значение ключа
    object[key] = newValue; // обновить значение изменяемого ключа

    // если текущий ключ является отслеживаемым
    if (trackingKeys.includes(key)) {
      // Ключи подлежащие изменению (те, у которых в dependence.key указан текущий объект итерации )
      const keysToChange = dependentKeys.filter((dkey) => scheme[dkey].dependence?.key === key);
      keysToChange.forEach((tckey) => {
        // Настройки зависимости для текущего изменяемого ключа
        const dependenceSetting = scheme[tckey].dependence;
        // Если выполняется условие зависимости и включен параметр сброса
        if ((Boolean(object[dependenceSetting.key]) === dependenceSetting?.existence) && dependenceSetting?.reset) {
          object[tckey] = null; // сбросить значение зависимого ключа
          const switchKey = `switch_${tckey}`; // зависимый switch ключ
          // если присутствует зависимый switch ключ - его тоже сбрасываем
          if (object[switchKey]) object[switchKey] = null;
          // Если условие зависимости не выполняется - ничего не делаем
        }
      });
    }
  });
  return object;
}

/**
* @function Получение логов по сущности
* @arg {id} number - (Обязательный) id сущности в таблице БД
* @arg {type} number - (Обязательный) id типа сущности
*/
export async function getKbHistory(id, type) {
  try {
    const reqData = {
      type: 'getKbHistory',
      type_id: type,
      object_id: id,
    };
    // запрос в БД
    const result = await clientSendData('POST', '/get_kb_history', reqData);
    if (result) return result;
    return [];
  } catch (error) {
    catchHandler(error, 'getKbHistory');
    return [];
  }
}

/**
* @function Создание нового объекта с наследуемыми свойствами
* @arg {object} object - (Обязательный) объект источник
* @arg {source} string - (Обязательный) тип источника (theme/question)
* @arg {target} string - (Обязательный) тип нового объекта (theme/question)
*/
export function сreateNewObject(object, source, target) {
  const newObject = { id: 'new' }; // Новый объект
  const themeScheme = store.getState().kb.active_scheme.theme.scheme; // активная схема темы
  const questionScheme = store.getState().kb.active_scheme.question.scheme; // активная схема вопроса

  if (source === 'theme') { // если тип источника - тема
    if (target === 'theme') { // если целевой объект - тема
      // Копирование наследуемых полей
      const inheritFields = Object.keys(themeScheme).filter((key) => themeScheme[key]?.inherit);
      inheritFields.forEach((key) => {
        newObject[key] = object[key];
        // если поле switch, добавить switch значение
        if (themeScheme[key]?.switch) newObject[`switch_${key}`] = object[`switch_${key}`];
      });
      // Копирование родительских полей
      const parentFields = Object.keys(themeScheme).filter((key) => themeScheme[key]?.switch?.parent_key);
      parentFields.forEach((key) => {
        if (themeScheme[key]?.type === 'number') {
          newObject[key] = object?.id;
          newObject[`switch_${key}`] = object?.title;
        }
        if (themeScheme[key]?.type === 'array') {
          newObject[key] = [object?.id];
          newObject[`switch_${key}`] = object?.title;
        }
      });
    } else if (target === 'question') { // если целевой объект - вопрос
      /*
      Берем схему темы
      Берем схему вопроса
      Берем поля для наследования из схемы вопроса
      Так как копирование идет из темы то брать данные можно только из нее,
      соответсвенно сравниваем поля для наследования из схемы вопроса с полями для схемы темы
      Если поле в схеме темы поле есть то берем данные из ключа этого поля
      Если поля в схеме темы нет, то проставляем пустоту
      */
      // Копирование наследуемых полей
      const inheritFields = Object.keys(questionScheme).filter((key) => questionScheme[key]?.inherit);
      inheritFields.forEach((key) => {
        newObject[key] = object[key];
        // если поле switch, добавить switch значение
        if (questionScheme[key]?.switch) newObject[`switch_${key}`] = object[`switch_${key}`];
      });
      // Копирование родительских полей
      const parentFields = Object.keys(questionScheme).filter((key) => questionScheme[key]?.switch?.parent_key);
      parentFields.forEach((key) => {
        if (questionScheme[key]?.type === 'number') {
          newObject[key] = object?.id;
          newObject[`switch_${key}`] = object?.title;
        }
        if (questionScheme[key]?.type === 'array') {
          newObject[key] = [object?.id];
          newObject[`switch_${key}`] = object?.title;
        }
      });
    } else return newObject;
  } if (source === 'question') { // если тип источника - вопрос
    // .....
  }

  return newObject;
}

/**
* @function Сортировка ключей объекта
* @arg {object} object - (Обязательный) объект
* @arg {sortKey} string - (Обязательный) ключ, по которому выполняется сортировка
* @arg {sorting} string - направление сортировки (asc/desc) asc по умолчанию
*/
export function sortObjectKeys(object, sortKey, sorting) {
  const asc = Object.keys(object).sort((a, b) => { // По возрастанию
    if (object[a][sortKey] < object[b][sortKey]) return -1;
    if (object[a][sortKey] > object[b][sortKey]) return 1;
    return 0;
  });
  const desc = Object.keys(object).sort((a, b) => { // По возрастанию
    if (object[a][sortKey] < object[b][sortKey]) return 1;
    if (object[a][sortKey] > object[b][sortKey]) return -1;
    return 0;
  });

  switch (sorting) {
    case 'asc': return asc;
    case 'desc': return desc;
    default: return asc;
  }
}

/**
* @function Запись/удаление состояния поиска в/из LocalStorage
* @arg {type} string - тип поиска (fast/string)
* @arg {data} array - массив данных поиска (поле, что ищем)
*/
export function setSearchState(type, data) {
  // ключ в LocalStorage в котором хранится состояние поиска kb_search
  const lSKey = store.getState().kb.ls_key;
  // удаление %*
  const regexp = /[%*]/g;
  // Если есть данные поиска - записать в LS
  if (type && data) {
    if (type === 'fast') {
      const result = data.map((item) => item.value?.replace(regexp, '')?.toLowerCase());
      localStorage.setItem(lSKey, JSON.stringify(result));
    }
    if (type === 'string') {
      let result = data?.replace(regexp, '')?.toLowerCase();
      result = result.split(' '); // разделить на массив
      result.filter((item) => item); // удалить пустые значения
      localStorage.setItem(lSKey, JSON.stringify(result));
    }
    // Если данных поиска нет - удалить их из LS
  } else localStorage.removeItem(lSKey);
}

// export function defineSwitchValue(data) {
//   const {
//     scheme, field, value, switch_data, switch_column,
//   } = data;
//   let result = value;
//   const type = scheme[field]?.type; // тип данных поля
//   const fieldSwitch = scheme[field]?.switch; // switch данные поля
//   if (value && fieldSwitch) { // если поле имеет параметр switch
//     if (type === 'number') { // если тип поля - число
//       result = Number(result); // привести к значение числу
//       result = switch_data[field]?.find((item) => item.id === result); // найти в объекте switch данных объект
//       result = result?.[switch_column]; // взять из найденного объекта необходимый ключ
//     }
//     if (type === 'array') { // если тип поля - массив
//       result = JSON.parse(result); // строку '[1,2,3]' привести к массиву
//       result = result.map((row) => {
//         // Найти в switch данных значения по каждому id из массива
//         const result = switch_data[field]?.find((item) => item.id === row);
//         // вернуть из найденного объекта значение ключа switch_column
//         return result?.[switch_column];
//       });
//       result = result.join(', \n'); // привести массив к строке и добавить разделители
//     }
//   }
//   return result;
// }
