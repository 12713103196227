import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { setOperInfo } from '../../../../utils/functions/others';
import { catchHandler } from '../../../../utils/error_handling/error_handling';
import { clientSendData } from '../../../../utils/functions/requests';
import CardHeader from '../../../../components/Card/CardHeader';
import CardBody from '../../../../components/Card/CardBody';
import TabPanel from '../../../../components/UI/TabPanel';
import History from './History';
import Main from './Main';
import Card from '../../../../components/Card/Card';

/**
  * @component Бронирование - места - карточка с бронированием
  * @prop {setShowCard} function - обновление состояния отображения карточки (открыть/закрыть)
  * @prop {refreshTable} function - обновление данных таблицы
*/
function BookingAdminCard(props) {
  const { setShowCard, refreshTable, bookingId } = props;

  const tabs = ['Главная', 'История']; // вкладки
  const [searchParams, setSearchParams] = useSearchParams(); // параметры поиска из url
  const [isLoading, setIsLoading] = useState(false); // состояние выбора вкладки
  const [choice, setChoice] = useState(); // состояние выбора вкладки
  const content = {
    Главная: <Main refreshTable={refreshTable} closeHandler={closeHandler} bookingId={bookingId} />,
    История: <History bookingId={bookingId} type="1" />,
  };

  useEffect(() => {

  }, [searchParams]);

  // закрыть бронирование
  function closeHandler() {
    setOperInfo({ subject_id: null }); // сброс id категории в оперативной информацию
    setSearchParams(''); // сброс параметром url
    setShowCard(false); // закрытие карточки
  }

  // обработка выбора вкладки
  function choiceHandler(tab) {
    setChoice(tab); // установить выбор
  }

  return (
    <Card id="workplaces__booking-card" setShow={closeHandler} loading={isLoading}>
      <CardHeader>
        <TabPanel tabs={tabs} choice={choice} choiceHandler={choiceHandler} />
      </CardHeader>
      {content[choice]}
    </Card>
  );
}

export default BookingAdminCard;
