import React, { useEffect, useState } from 'react';
import { clientSendData } from '../../utils/functions/requests';
import { catchHandler } from '../../utils/error_handling/error_handling';
import { alertFunction } from '../../utils/functions/alertFunction';
import { log } from '../../utils/functions/others';
import ServiceBody from '../../components/Service/ServiceBody';
import Table from '../../components/Table/Table';
import TBody from '../../components/Table/TBody';
import THead from '../../components/Table/THead';
import TRow from '../../components/Table/TRow';
import TData from '../../components/Table/TData';
import TFoot from '../../components/Table/TFoot';
import Input from '../../components/UI/Input';
import Loader from '../../components/UI/Loader';
import Toggle from '../../components/UI/Toggle';
import './adminPanel.scss';

/**
 * @component Таблица настроек планировщика
 * @prop {table} string - название таблицы с настройками в БД
 * @prop {title} string - название таблицы
*/
function Scheduler(props) {
  const { title, table } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [changed, setChanged] = useState(false); // Установка состояния изменений параметров
  const [services, setServices] = useState([]); // службы планировщика
  const [servicesDefault, setServicesDefault] = useState([]); // службы планировщика до изменений

  const headers = [
    { id: 1, title: 'Сервис', field: 'name' },
    { id: 2, title: 'Путь к скрипту', field: 'script' },
    { id: 3, title: 'Период выполнения(мс)', field: 'run_period' },
    { id: 4, title: 'Последний запуск', field: 'last_run' },
    { id: 5, title: 'Статус', field: 'active' },
  ];

  useEffect(() => {
    getSchedulerServices();
  }, []);

  async function getSchedulerServices() {
    try {
      const reqData = { type: 'getSchedulerServices', table };
      setIsLoading(true);
      const result = await clientSendData('POST', '/get_scheduler_services', reqData); // запрос в БД
      if (result) {
        setServices(result);
        setServicesDefault(result);
      } else return;
      setIsLoading(false);
    } catch (error) {
      catchHandler(error, 'getSchedulerServices');
      setIsLoading(false);
    }
  }

  // Запись изменения параметров в хранилище
  function changeService(obj) {
    const copy = services.map((item) => {
      if (item.id === obj.id) return obj;
      return item;
    });
    setChanged(true);
    setServices(copy);
  }

  // Сохранение измененных параметров в БД
  async function saveSchedulerServices() {
    try {
      const reqData = {
        type: 'saveSchedulerServices',
        services,
        prevServices: servicesDefault,
        table,
      };
      setIsLoading(true);
      const result = await clientSendData('POST', '/save_scheduler_services', reqData); // запрос в БД

      if (result) {
        await alertFunction('save_settings', 'clientPost');
        setChanged(false);
        let message = 'Пользователь изменил: ';
        const chParameters = [];
        servicesDefault.forEach((el) => {
          const changes = services.find((service) => service.id === el.id);
          const keys = Object.keys(el);
          keys.forEach((key) => {
            if (el[keys[key]] !== changes[keys[key]]) {
              chParameters.push(`сервис ${el.name} изменен параметр ${keys[key]} с ${el[keys[key]]} на ${changes[keys[key]]}`);
            }
          });
        });
        message += chParameters.join(',');
        log({ message });
      } else return;
    } catch (error) {
      catchHandler(error, 'save_scheduler_services');
    } finally {
      setIsLoading(false);
    }
  }

  // Обработка кнопки отмена
  function cancel() {
    getSchedulerServices();
    setChanged(false);
  }

  if (isLoading) return <Loader />;
  return (
    <ServiceBody>
      <Table id="admin-panel__scheduler-table">
        <THead
          title={title}
          headers={headers}
          array={services}
          setArray={setServices}
        />
        <TBody>
          {services.map((service) => {
            const {
              id, name, script, run_period, last_run, active,
            } = service;
            return (
              <TRow key={id}>
                <TData align="left">{name}</TData>
                <TData>
                  <Input
                    value={script}
                    onChange={(e) => changeService({ ...service, script: e.target.value })}
                  />
                </TData>
                <TData>
                  <Input
                    type="number"
                    value={run_period}
                    onChange={(e) => changeService({ ...service, run_period: e.target.value })}
                  />
                </TData>
                <TData align="right">{last_run}</TData>
                <TData>
                  <Toggle
                    state={String(active)}
                    onClick={() => changeService({ ...service, active: !service.active })}
                  />
                </TData>
              </TRow>
            );
          })}
        </TBody>

        {changed && ( // Футер таблицы
          <TFoot className="upu-table__table-foot">
          <TRow>
            <TData onClick={cancel}>Отменить</TData>
            <TData onClick={saveSchedulerServices}>Сохранить</TData>
          </TRow>
          </TFoot>)}
      </Table>
    </ServiceBody>
  );
}

export default Scheduler;
