import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../components/UI/Button/Button';
import TabPanel from '../../../components/UI/TabPanel';
import { catchHandler } from '../../../utils/error_handling/error_handling';
import { alertFunction } from '../../../utils/functions/alertFunction';
import { setOperInfo } from '../../../utils/functions/others';
import { clientSendData } from '../../../utils/functions/requests';
import Settings from './Settings';
import { getTask } from '../functions';
import Card from '../../../components/Card/Card';
import CardHeader from '../../../components/Card/CardHeader';
import CardBody from '../../../components/Card/CardBody';
import CardFooter from '../../../components/Card/CardFooter';
import Logs from './Logs';

/**
* @component Система контроля задач - карточка задачи
* @prop {id} number - id задачи
* @prop {setShow} function - Функция обновления состояния отображения карточки
* @prop {getTasks} function - Функция получения всех задач для обновления в таблице
*/
function TaskCard(props) {
  const { id, setShow, getTasks } = props; // свойства передаваемые компоненту при вызове

  const operInfo = useSelector((state) => state.oper_info);
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [choice, setChoice] = useState(''); // состояние выбора вкладки
  const [task, setTask] = useState({}); // текущая задача
  const isNewTask = task?.id === 'new'; // это новая задача
  const tabs = ['Настройки', !isNewTask && 'История']; // вкладки

  // Закрыть карточку
  function cancelAndClose() {
    setShow(false);
    setOperInfo({ subject_id: null });
  }

  useEffect(() => {
    awaitRequests();
  }, []);

  // Ожидание выполнения необходимых запросов
  async function awaitRequests() {
    setIsLoading(true);
    setOperInfo({ subject_id: id });
    const result = await getTask(id, 'admin'); // получаем данные задачи
    setTask(result);
    setIsLoading(false);
  }

  // обработка выбора вкладки
  function choiceHandler(tab) {
    setChoice(tab); // установить выбор
  }

  // Валидация данных перед отправкой
  function validateTask(object) {
    const {
      short_title, short_text, long_title, long_text, source, source_id, status_id, date_start, date_end,
    } = object;
    const arrayOfValues = [short_title, long_title, short_text, long_text, status_id, source, source_id];
    if (!arrayOfValues.every(Boolean)) {
      alertFunction('required_fields', 'clientPost');
      return false;
    }
    if (date_start > date_end) {
      alertFunction('wrong_period', 'clientPost');
      return false;
    }
    return true;
  }

  // Создать или редактировать задачу
  async function handleTask(taskObject) {
    try {
      const { log_level } = operInfo; // данные из оперативной информации
      const reqData = {
        type: 'tasks',
        task: taskObject || task, // объект задачи
        log_level, // уровень логирования
        method: id === 'new' ? 'create' : 'edit',
      };

      if (validateTask(reqData.task)) { // если валидация пройдена
        setIsLoading(true); // загружается
        const result = await clientSendData('POST', '/tasks', reqData); // запрос в БД
        if (typeof result === 'number') { // иначе
          const updatedTask = await getTask(result, 'admin'); // получить обновленные данные задачи
          setTask(updatedTask);
          await getTasks(); // обновить данные в таблице
          await alertFunction('save_settings', 'clientPost'); // уведомление
        }
        setIsLoading(false); // загрузка окончена
      }
    } catch (error) {
      catchHandler(error, 'tasks'); // обработчик ошибок
      setIsLoading(false); // состояние - не загружается
    }
  }

  return (
    <Card id="tasks__card" setShow={cancelAndClose} loading={isLoading}>
      <CardHeader>
        <TabPanel tabs={tabs} choice={choice} choiceHandler={choiceHandler} />
      </CardHeader>

      <CardBody>
        {choice === 'Настройки' && (
        <Settings
          task={task}
          setTask={setTask}
          handleTask={handleTask}
        />)}

        {choice === 'История' && (
        <Logs
          id={task?.id}
        />)}
      </CardBody>

      <CardFooter>
        <Button onClick={cancelAndClose}>Закрыть</Button>
        <Button onClick={() => handleTask()}>Сохранить</Button>
      </CardFooter>
    </Card>
  );
}

export default TaskCard;
