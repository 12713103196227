import React, { useEffect, useState } from 'react';
import { clientSendData } from '../../../utils/functions/requests';
import { catchHandler } from '../../../utils/error_handling/error_handling';
import { defaultTableState, headers } from '../common';
import ServiceBody from '../../../components/Service/ServiceBody';
import PaginatedTable from '../../../components/Table/PaginatedTable/PaginatedTable';
import TRow from '../../../components/Table/TRow';
import TData from '../../../components/Table/TData';
import NewBookingAdminCard from './BookingAdminCard/NewBookingAdminCard';
import BookingAdminCard from './BookingAdminCard/BookingAdminCard';
import WorkplaceCard from './WorkplaceCard/WorkplaceCard';
import Modal from '../../../components/UI/Modal';
import CardSetting from '../../../components/Card/CardSetting';
import Input from '../../../components/UI/Input';
import { alertFunction } from '../../../utils/functions/alertFunction';

/**
 * @component  Интерфейс администратора
 */
function BookingAdmin() {
  const tableId = 'workplaces_booking_admin';
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [entries, setEntries] = useState([]); // отображаемые бронирования/места
  const [tableState, setTableState] = useState(
    JSON.parse(localStorage.getItem(tableId)) || defaultTableState.bookingAdmin,
  );
  const [showCard, setShowCard] = useState(false); // состояние отображения карточки бронирования
  const [showCreateCard, setShowCreateCard] = useState(false); // состояние отображения карточки создания
  const [showPlaceCard, setShowPlaceCard] = useState(false); // состояние отображения карточки места
  const [entryId, setEntryId] = useState(false); // id выбранной записи
  const [displayModal, setDisplayModal] = useState(false);
  const [booking, setBooking] = useState({}); // объект с данными бронирования
  const [reportDates, setReportDates] = useState({}); // объект с данными отчета
  const footerButton = {
    title: 'Отчёт',
    handler: () => setDisplayModal(true),
  };

  useEffect(() => {
    getBookingsAdmin();
  }, [tableState]);

  // Получить заявки
  async function getBookingsAdmin() {
    try {
      const reqData = {
        type: 'getBookingAdmin',
        tableState,
        total,
      };
      setIsLoading(true);
      const result = await clientSendData('POST', '/get_booking_admin', reqData);
      if (result?.success) {
        setEntries(result.data); // Результат записывается в состояние
        setTotal(result.total);
      }
    } catch (error) {
      catchHandler(error, 'getBookingAdmin');
    } finally {
      setIsLoading(false);
    }
  }

  // Открыть карточку
  function openCard(id) {
    setEntryId(id);
    switch (tableState.switch_data.current) {
      case 'bookings': setShowCard(true);
        break;
      case 'places': setShowPlaceCard(true);
        break;
      default: break;
    }
  }

  async function createReport() {
    try {
      if (+new Date(reportDates.date_end) < +new Date(reportDates.date_start)) {
        alertFunction('report_date_fail', 'client');
        setDisplayModal(true);
      } else if (!reportDates.date_start && !reportDates.date_end) {
        alertFunction('booking_report_dates', 'client');
        setDisplayModal(true);
      } else {
        const reqData = {
          type: 'getReport',
          reportDates,
        };

        const result = await clientSendData('POST', '/get_report', reqData);
        if (result?.success) {
          const link = document.createElement('a');
          const uri = 'data:text/csv;charset=UTF-8,';
          link.download = `Отчет по бронированию ${reportDates.date_start} ${reportDates.date_end}.csv`;
          link.href = uri + encodeURIComponent(result.data);
          link.click();
          alertFunction('booking_report_success', 'client');
        } else {
          alertFunction('report_no_data', 'client');
          setDisplayModal(true);
        }
      }
    } catch (error) {
      catchHandler(error, 'getReport');
    }
  }

  return (
    <ServiceBody>
      <PaginatedTable
        id={tableId}
        state={tableState}
        setState={setTableState}
        headers={headers.bookingAdmin[tableState.switch_data.current]}
        total={total}
        footerButton={footerButton}
        optButton={{
          title: 'Забронировать место',
          handler: () => setShowCreateCard(true),
        }}
      >
        {entries.map((row) => (
          <TRow key={row.id}>
            {headers.bookingAdmin[tableState.switch_data.current].map((column) => {
              const { field, align, mobile_hide } = column;
              return (
                <TData
                  key={field}
                  align={align}
                  loading={isLoading}
                  mobileHide={mobile_hide}
                >
                  {row[field]}
                </TData>
              );
            })}
            <TData loading={isLoading} onClick={() => openCard(row.id)}>
              <img src="../../icons/file-text.svg" alt="file" />
            </TData>
          </TRow>
        ))}

      </PaginatedTable>
      {showCreateCard && (
        <NewBookingAdminCard
          setShowCard={setShowCreateCard}
          refreshTable={() => setTableState({ ...tableState })}
        />
      )}

      {showCard && (
        <BookingAdminCard
          bookingId={entryId}
          setShowCard={setShowCard}
          refreshTable={() => setTableState({ ...tableState })}
        />
      )}

      {showPlaceCard && (
        <WorkplaceCard
          placeId={entryId}
          setShowCard={setShowPlaceCard}
        />
      )}
             <Modal active={displayModal} setActive={setDisplayModal} callback={createReport}>
             <CardSetting title="Дата и время начала:" invalid={!reportDates?.date_start}>
          <Input
            type="date"
            onChange={(event) => setReportDates((state) => ({ ...state, date_start: event.target.value.replace('T', ' ') }))}
          />
             </CardSetting>
        <CardSetting title="Дата и время окончания:" invalid={!reportDates?.date_end}>
          <Input
            type="date"
            onChange={(event) => setReportDates((state) => ({ ...state, date_end: event.target.value.replace('T', ' ') }))}
          />
        </CardSetting>
             </Modal>
    </ServiceBody>
  );
}

export default BookingAdmin;
