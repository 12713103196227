/* eslint-disable no-console */
import { catchHandler } from '../error_handling/error_handling';
import { createAction } from '../redux/store';
import { alertFunction, alertFunctionLocal } from './alertFunction';
import { sessionHandler } from './authenticate';
import { getCookie, setCookie } from './cookies';
import { stringToCamelCase } from './stringHandling';
import { setOperInfo } from './others';

export const isLocalHost = window.location.host === 'localhost:3000';
export const domain = isLocalHost ? 'http://localhost:4000' : 'https://uniplace.unicon.ru:1443';

/**
 * @function Запрос сервисных куки
 * @prop {name} string - (Обязательный) имя куки
*/
export async function getServiceCookie(name) {
  try {
    const cookie = getCookie(name);
    if (cookie) return cookie; // Если куки с указанным именем существует - возвращает значение
    // Иначе запрос данных и запись в куки
    const camelCaseName = stringToCamelCase(name, '_'); // Имя куки в CamelCase
    const reqData = {
      type: `getServiceParametres${camelCaseName}`, // Тип запроса
      session_id: getCookie('session'),
    };
    const result = await sendData('POST', `/get_service_parametres_${name}`, reqData);

    if (!result || result === 'bad_request') { // если маршрут недоступен или некорректный запрос
      // addIntoLog('Ошибка при получении ip адреса', 'error', 'get_service_cookie'); // запись в лог
      return null; // функция возвращает null
    } // иначе
    setCookie(name, result, { 'max-age': 600 }); // Запись в куки, время жизни куки - 10мин
    return result; // функция возвращает результат
  } catch (error) {
    catchHandler(error, 'getServiceCookie');
    return error;
  }
}

/**
 * @function Клиентский запрос на сервер
 * @prop {method} string - (Обязательный) метод запроса
 * @prop {url} string - (Обязательный) адрес запроса
 * @prop {data} object - (Обязательный) данные для передачи
*/
export async function clientSendData(method, url, data) {
  try {
    const userIp = await getServiceCookie('ip_session'); // ip - результат выполнения функции
    const reqData = { // клиентские данные, которые добавляются к запросу
      app_side: 'client',
      user_ip: userIp,
      session_id: getCookie('session'),
      ...data,
    };
    // Если getServiceCookie возвращает null - функция возвращает bad request для последующей обработки
    if (!userIp) return { status: 'bad_request' };
    return sendData(method, url, reqData); // функция возвращает sendData
  } catch (error) {
    catchHandler(error, 'clientSendData');
    return error;
  }
}

/**
 * @function Запрос на сервер
 * @prop {method} string - (Обязательный) метод запроса
 * @prop {url} string - (Обязательный) адрес запроса
 * @prop {data} object - (Обязательный) данные для передачи
*/
export async function sendData(method, url, data) {
  try {
    const response = await fetch(domain + url, {
      method,
      headers: {
        'Access-Control-Allow-Origin': 'http://uniplace.unicon.ru',
        'Access-Control-Allow-Methods': 'POST, PUT, PATCH, GET, DELETE, OPTIONS',
        'Access-Control-Allow-Headers': 'Origin, X-Api-Key, X-Requested-With, Content-Type, Accept, Authorization',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    const result = await response.json();
    if (result?.status === 'bad_request') {
      alertFunction('bad_request', 'clientPost');
      return null;
    }
    if (result?.status === 'error') {
      console.log(result?.error);
      return null;
    } return result; // При успешном выполнении возвращает данные ответа от сервера в JSON формате
  } catch (error) {
    console.log(error);
    return error;
  }
}

/**
 * @function Отправка файла на сервер
 * @prop {method} string - (Обязательный) метод запроса
 * @prop {url} string - (Обязательный) адрес запроса
 * @prop {formData} object - (Обязательный) данные для передачи в формате FormData
*/
export async function clientSendFile(method, url, formData) {
  try {
    const userIp = await getServiceCookie('ip_session'); // ip - результат выполнения функции
    formData.append('app_side', 'client');
    formData.append('user_ip', userIp);
    formData.append('session_id', getCookie('session'));

    const response = await fetch(domain + url, {
      method,
      body: formData,
    });
    const result = await response.json();
    if (result === 'bad_request') {
      alertFunction('bad_request', 'clientPost');
      return null;
    }
    if (result?.status === 'error') {
      console.log(result?.error);
      return null;
    } return result; // При успешном выполнении возвращает данные ответа от сервера в JSON формате
  } catch (error) {
    catchHandler(error, 'clientSendFile');
    return error;
  }
}

/**
 * @function Получение общей информации необходимой для работы портала
*/
export async function getCommonData() {
  try {
    const reqData = {
      type: 'getCommonData',
    };
    const result = await clientSendData('POST', '/get_common_data', reqData);
    if (result) {
      const { areas, aliases, log } = result;
      const log_level = log ? 3 : 1; // если лог включён, то ставим уровень 3 - info иначе 1 - error
      createAction('SET_AREAS', areas); // области отображения сервисов
      createAction('SET_ALIASES', aliases); // краткие наименования сервисов
      setOperInfo({ log, log_level }); // параметры логирования
    }
  } catch (error) {
    catchHandler(error, 'getCommonData');
  }
}

/**
 * @function Проверка статуса соединения с БД и запуска обработки сессии
*/
export async function checkConnectionStatus() {
  try {
    const start = new Date();
    const reqData = { type: 'getConnectionStatus' };

    const result = await sendData('POST', '/get_connection_status', reqData); // Проверка соединения с БД
    if (result) {
      // eslint-disable-next-line default-case
      switch (result.status) {
        case 'bad_request':
          alertFunction('bad_request');
          createAction('SET_IS_AUTHENTICATING', false); // остановить процесс аутентификации
          break;
        case 'ok': // Если соединение есть - выполняются необходимые запросы к БД
          await sessionHandler(); // обработка сессии
          await getCommonData(); // Получение общей информации необходимой для работы портала
          break;
        case 'error': // Если соединения нет - выводится уведомление
          alertFunctionLocal('Отсутствует соединение с БД'); // уведомление хардкодом, т.к. выполняется в случае недоступности БД
          createAction('SET_IS_AUTHENTICATING', false); // остановить процесс аутентификации
          break;
      }
    } else {
      alertFunctionLocal('В результате запроса на сервер возникла ошибка!');
      createAction('SET_IS_AUTHENTICATING', false); // остановить процесс аутентификации
    }
    console.debug(`${(new Date() - start) / 1000}с`);
  } catch (error) {
    catchHandler(error, 'checkConnectionStatus');
    alertFunctionLocal('Сервер недоступен. Обратитесь в службу поддержки!');
    createAction('SET_IS_AUTHENTICATING', false); // остановить процесс аутентификации
  }
}
