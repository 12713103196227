import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { clientSendData } from '../../../../../utils/functions/requests';
import { catchHandler } from '../../../../../utils/error_handling/error_handling';
import { downloadDataUrl } from '../../../../../utils/functions/others';
import { activeTicketStatuses } from '../../../common';
import CardSetting from '../../../../../components/Card/CardSetting';
import ImgFrame from '../../../../../components/UI/ImgFrame';
import TicketProcessMessage from './TicketProcessMessage';
import SendForm from './SendForm';

/**
  * @component ХелпДеск - заявки - карточка заявки - вкладка "Работа по заявке"
  * @prop {ticketID} number - id заявки
*/
function TicketProcess(props) {
  const { ticketID } = props;
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [ticketData, setTicketData] = useState({ info: {}, process: [] }); // данные процесса по заявке
  const [currentUser, setCurrentUser] = useState(true);
  const [imageSrc, setImageSrc] = useState(null);
  const [size, setSize] = useState('medium');
  const {
    initiator_id, initiator, performer = '', status_id,
  } = ticketData.info; // деструктуризация данных по заявке
  const messagesEndRef = useRef(null);

  useEffect(() => {
    scrollToBottom();
  }, [ticketData.process]);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    getTicketProcess(); // запросить информацию по заявке при каждом изменении ticketID
  }, [ticketID]);

  // Получение данных по переписке
  async function getTicketProcess() {
    try {
      const reqData = { type: 'getTicketProcess' };
      setIsLoading(true); // загружается
      const result = await clientSendData('POST', `/get_ticket_process/${ticketID}`, reqData); // запрос в БД
      if (result?.success) { // если запрос выполнен успешно
        const { info, process } = result;
        setTicketData({ info, process });
        setCurrentUser(result.performer);
      }
    } catch (error) { // если ошибка
      catchHandler(error, 'ticketTasksHandler'); // обрабатываем ошибку
    } finally {
      setIsLoading(false); // загрузка завершена
    }
  }

  // Скачать вложение
  // id - id записи из sd_ticket_chat
  // fileName - имя файла
  async function downloadAttachment(id, fileName) {
    try {
      const reqData = { type: 'getAttachment', name: fileName };
      setIsLoading(true);
      const result = await clientSendData('POST', `/get_attachment/${id}`, reqData);
      if (result?.success) {
        const fileExtension = fileName.split('.').pop().toLowerCase(); // получаем расширение файла
        const imageFileTypes = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg']; // типы файлов, которые мы считаем изображениями

        // Если расширение файла присутствует в массиве типов файлов-изображений
        if (imageFileTypes.includes(fileExtension)) {
          setImageSrc(result.data);
          setSize('active');
        } else {
          downloadDataUrl(result.data, fileName);
        }
      }
    } catch (error) {
      catchHandler(error, 'downloadAttachment');
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className={`ticket-process${!activeTicketStatuses.includes(status_id) ? ' ticket-process_disabled' : ''}`}>
      <CardSetting title="Общение по запросу:" />

      <div className="ticket-process__content">
         <div className={`ticket-process__content_wrapper${isLoading ? ' upu__loading' : ''}`}>
          {/* <TicketProcessMessage
            date_created={date_created}
            subject={title}
            sender={initiator}
            content={content}
          /> */}

{
  ticketData.process?.map((item) => (
    <TicketProcessMessage
      key={item.id}
      date_created={item.date_created}
      subject={item.title}
      sender_type={item.sender_type}
      sender={item.sender_name}
      content={
        item.files && item.files.length > 0
          ? (
            <>
              {item.content}
              <img
                src="../../icons/button/paperclip.svg"
                alt="paperclip"
                title="Вложение"
              />
            </>
          )
          : item.content
      }
      message_type={item.message_type}
    />
  ))
}
<div ref={messagesEndRef} />
         </div>
      </div>

      {/* ФОРМА ОТПРАВКИ ТОЛЬКО ДЛЯ РАБОЧЕЙ ЗАЯВКИ */}
      { activeTicketStatuses.includes(status_id) && (
      <SendForm
        ticketID={ticketID}
        ticketInfo={ticketData.info}
        getTicketProcess={getTicketProcess}
        falsePerformer={currentUser}
      />) }

      <div className="ticket-process__inputs">
        <CardSetting title="Дата создания:">{ticketData.info.date_created}</CardSetting>
        <CardSetting title="Инициатор">
          <Link className="clickable" to={`?user=${initiator_id}`}>{initiator}</Link>
        </CardSetting>
        <CardSetting title="Исполнитель">{performer}</CardSetting>
        <CardSetting title="Вложения к запросу:" />
        {/* ВЛОЖЕНИЯ  */}
        {ticketData.process
          .filter((message) => message.files)
          .map((message) => {
            const { id, files, date_created } = message; //
            return files.map((fileName) => (
              <CardSetting key={id + fileName} title={date_created} loading={isLoading}>
                <span className="clickable" onClick={() => downloadAttachment(id, fileName)}>
                  {fileName}
                </span>
              </CardSetting>
            ));
          })
        }
      </div>

  <ImgFrame size={size} setSize={setSize} imageSrc={imageSrc} />

    </div>
  );
}

export default TicketProcess;
