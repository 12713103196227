// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#zones .zone-scheme {
  width: 50%;
}

#create-zone-choose .custom-select__option {
  font-size: 13px;
}`, "",{"version":3,"sources":["webpack://./src/pages/AdminWorkplaces/admin_workplaces.scss"],"names":[],"mappings":"AACI;EACI,UAAA;AAAR;;AAKI;EACI,eAAA;AAFR","sourcesContent":["#zones {\n    & .zone-scheme {\n        width: 50%;\n    }\n}\n\n#create-zone-choose {\n    & .custom-select__option {\n        font-size: 13px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
