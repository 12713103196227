import React, { useEffect, useState } from 'react';
import { getKbHistory } from '../../functions';
import ObjectDisplay from '../../../../components/Card/ObjectDisplay';
import Loader from '../../../../components/UI/Loader';

/**
 * @component Администрирование базы знаний - редактирование подразделений - карточка подразделения - вкладка Cхемы - карточка схемы - вкладка история
 * @prop {id} number - id схемы в таблице БД
 * @prop {type} number - id типа сущности
*/
function Logs(props) {
  const { id, type } = props;

  const [logs, setLogs] = useState([]); // история
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки

  useEffect(() => {
    getLogs(); // получить логи
  }, []);

  // Обёртка для функции получения логов для управления состоянием загрузки
  async function getLogs() {
    setIsLoading(true); // состояние - загружается
    const result = await getKbHistory(id, type); // получения логов
    setLogs(result);
    setIsLoading(false); // загрузка завершена
  }

  // если загружается - показывается лоадер
  if (isLoading) return <Loader />;
  // Если логи пустые - показывается заголовок об их отсутствии
  if (logs.length === 0) return <h2 className="upu-service__title">История отсутствует</h2>;
  // Иначе отображается история
  return (
    <div id="scheme__logs" className="upu-card__block">
      {logs.map((entry) => (
        <div key={entry.id} className="question-history">
          <ObjectDisplay object={entry} />
        </div>
      ))}
    </div>
  );
}

export default Logs;
