/* eslint-disable react/no-unknown-property */
import React, { useState } from 'react';
import classes from './button.module.scss';
import { catchHandler } from '../../../utils/error_handling/error_handling';

/**
* @component Кнопка
@prop все переданные свойства записываются в атрибуты input
@prop {onClick} function (Обязательный) Обработчик клика
@prop {disabled} boolean сделать кнопку неактивной
*/

function Button({ children, ...props }) {
  const { onClick, disabled } = props;

  const [isClicked, setIsClicked] = useState(false); // состояние нажатия на кнопку

  // Обработчик нажатия
  async function clickHandler(e) {
    try {
      if (!isClicked) { // если состояние false
        setIsClicked(true); // меняем на true
        await onClick(e); // дожидаемся выполнения функции
        setIsClicked(false); // возвращаем состояние на исходное
      }
    } catch (error) {
      catchHandler(error, 'clickHandler');
    }
  }

  // Определение класса стилей
  const defineClass = () => {
    let result = classes.button;
    if (disabled) result += ` ${classes.disabled}`;
    if (isClicked) result += ` upu__loading ${classes.disabled}`;
    return result;
  };

  return (
    <button
      className={defineClass()}
      {...props} // ВАЖНО!!! НЕ изменять положение этой строки, влияет на стили
      disabled={isClicked || disabled}
      onClick={clickHandler}
      type="button"
      data-testid="button"
    >
      {children}
    </button>
  );
}

export default Button;
