import React, { useState, useEffect } from 'react';
import './asap.scss';
import TRow from '../../components/Table/TRow';
import TData from '../../components/Table/TData';
import { clientSendData } from '../../utils/functions/requests';
import { catchHandler } from '../../utils/error_handling/error_handling';
import ServiceBody from '../../components/Service/ServiceBody';
import PaginatedTable from '../../components/Table/PaginatedTable/PaginatedTable';
import RequestCard from './RequestCard/RequestCard';

function Results() {
  const tableId = 'asap_result';
  const [asapData, setAsapData] = useState([]); // все встречи
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [openedMeeting, setOpenedMeeting] = useState(); // открытая в карточке результатов тестов
  const [showCard, setShowCard] = useState(false); // состояние отображения карточки
  const [calendarData, setCalendarData] = useState({}); // данные календаря

  const defaultTableState = {
    title: 'Просмотр результатов',
    page: 1,
    limit: 50, // количество строк в таблице
    switch_data: {
      current: 'active',
      titles: {
        all: 'Все',
        active: 'Активные',
      },
    },
  };

  const [tableState, setTableState] = useState(
    JSON.parse(localStorage.getItem(tableId)) || defaultTableState,
  );
  const headers = [ // Заголовки таблицы
    { id: 1, title: 'Номер задания', field: 'id' },
    { id: 2, title: 'Дата', field: 'date' },
    { id: 3, title: 'Статус', field: 'task_status' },
  ];

  useEffect(() => {
    GetAsapData();
    fetchCalendarData();
  }, [tableState]);

  // Получение данных о результатах тестирования
  async function GetAsapData() {
    try {
      const reqData = {
        type: 'getAsapData',
        tableState,
      };
      setIsLoading(true);
      const result = await clientSendData('POST', '/get_asap_data', reqData);
      if (result.success) {
        setAsapData(result.data);
        setTotal(result.total);
        setCalendarData(result.calendar); // сохранение данных календаря в состоянии
      }
      setIsLoading(false);
    } catch (error) {
      catchHandler(error, 'getAsapData');
    }
  }

  async function fetchCalendarData() {
    try {
      const reqData = {
        type: 'getAsapCalendarData',
      };
      setIsLoading(true);
      const result = await clientSendData('POST', '/get_asap_calendar_data', reqData);
      if (result.success) {
        setCalendarData(result.calendar); // сохранение данных календаря в состоянии
        console.log(calendarData);
      }
      setIsLoading(false);
    } catch (error) {
      catchHandler(error, 'getSAspCalendarData');
    }
  }

  function openCard(id) {
    setOpenedMeeting(id);
    setShowCard(true);
  }

  // Если параметр отображения карточки - true, показываем карточку
  if (showCard) {
    return (
      <RequestCard
        meetingId={openedMeeting}
        handler={GetAsapData}
        setShow={setShowCard}
        refreshTable={() => setTableState({ ...tableState })}
        calendarData={calendarData} // передача данных календаря в RequestCard
        onBackToResults={() => setShowCard(false)}
      />
    );
  }

  // Иначе показываем таблицу
  return (
    <div id="asap_results_table">
      <ServiceBody>
        <PaginatedTable
          id="asap_results"
          headers={headers}
          state={tableState}
          setState={setTableState}
          total={total}
        >
          {asapData.map((row) => (
            <TRow key={row.id}>
              {headers.map((column) => {
                const { id, field } = column; // дектруктуризация объекта колонки
                const value = row?.[field]; // значение текущего ключа в объекте строки
                return (<TData key={id} loading={isLoading}>
                  {typeof (value) === 'object' ? value.join(', ') : value ?? '-'}
                        </TData>);
              })}

              <TData onClick={() => openCard(row.id)} loading={isLoading}>
                <img src="../../icons/file-text.svg" alt="file" />
              </TData>
            </TRow>
          ))}
        </PaginatedTable>
      </ServiceBody>
    </div>
  );
}

export default Results;
