import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { backlightHandler, log, setOperInfo } from '../../../../utils/functions/others';
import { clientSendData } from '../../../../utils/functions/requests';
import { alertFunction } from '../../../../utils/functions/alertFunction';
import { createAction } from '../../../../utils/redux/store';
import { catchHandler } from '../../../../utils/error_handling/error_handling';
import InputSearch from '../../../../components/UI/InputSearch';
import Button from '../../../../components/UI/Button/Button';
import TextArea from '../../../../components/UI/TextArea';
import Select from '../../../../components/UI/Select';
import Loader from '../../../../components/UI/Loader';
import Close from '../../../../components/UI/Close';
import Input from '../../../../components/UI/Input';
import Log from './Log';
import './dismissal.scss';

/**
* @component Система контроля РП - Система Выходного интервью - Карточка увольнения
* @prop {setShowCard} function - Обновление состояния отображения карточки (для закрытия)
* @prop {getDismissedUsers} function - Обновление данных таблицы (получение пользователей)
*/
function Dismissal(props) {
  const { setShowCard, getDismissedUsers } = props;

  let dismissalTypes = useSelector((state) => state.probation.dismissed_types); // Типы увольнения
  const outputInterviewQuestionnaire = useSelector((state) => state.probation.output_questionnaire); // id анкеты ВИ
  const outputInterview = useSelector((state) => state.probation.output_interview); // Данные выходного интервью
  const operInfo = useSelector((state) => state.oper_info); // оперативная информация
  const mentors = useSelector((state) => state.probation.mentors); // массив наставников
  const user = useSelector((state) => state.probation.dismissal_user); // текущий пользователь ВИ

  const [backlight, setBacklight] = useState(''); // состояние подсветки
  const [isLoading, setIsLoading] = useState(true); // состояние загрузки
  const userGroupsDefaultOption = { id: 'all', title: 'Показать все' }; // Опция по умолчанию для выбора типа увольнения
  const [data, setData] = useState({
    dismissal_type_id: Object.keys(outputInterview).length > 0 ? outputInterview.dismissal_type_id : undefined,
    dismissal_type_name: Object.keys(outputInterview).length > 0 ? outputInterview.name : userGroupsDefaultOption.title,
    dismissal_date: Object.keys(outputInterview).length > 0 ? outputInterview.dismissal_date : undefined,
    no_dismiss: Object.keys(outputInterview).length > 0 ? outputInterview.no_dismiss : false,
    note: Object.keys(outputInterview).length > 0 ? outputInterview.note : '',
  });

  if (Object.keys(outputInterview).length === 0) {
    dismissalTypes = dismissalTypes.filter((el) => el.title !== 'Не задан');
  }
  const answer = Object.keys(outputInterview).length > 0 ? outputInterview.answer : false;

  useEffect(() => {
    sendQueries();
  }, []);

  async function sendQueries() {
    setOperInfo({ subject_id: user.id }); // запись id сотрудника в оперативную информацию
    setIsLoading(true);
    await getDismissedTypes();
    await getOutputInterviewQuestionnaire();
    await getOutputInterviewLog();
    await getMentor();
    setIsLoading(false);
  }

  // Получение типов увольнения
  async function getDismissedTypes() {
    try {
      const reqData = {
        type: 'getDismissedTypes',
      };
      const result = await clientSendData('POST', '/get_dismissed_types', reqData); // запрос в БД
      if (result === 'bad_request') { // если некорректный запрос
        alertFunction('bad_request', 'clientPost'); // уведомление
      } else {
        createAction('SET_DISMISSED_TYPES', result); // запись типов увольнений в хранилище
      }
    } catch (error) {
      catchHandler(error, 'get_dismissed_types');
    }
  }

  // Получение наставника
  async function getMentor() {
    try {
      if (Object.keys(outputInterview).length > 0 && outputInterview.mentor_id !== null) {
        setData({ ...data, mentor: outputInterview.mentor, mentor_id: outputInterview.mentor_id });
      } else {
        const reqData = {
          type: 'getMentor',
          user_id: user.id,
        };
        const result = await clientSendData('POST', '/get_mentor', reqData); // запрос в БД
        if (result === 'bad_request') { // если некорректный запрос
          alertFunction('bad_request', 'clientPost'); // уведомление
        } else {
          setData({ ...data, ...result }); // запись типов увольнений в хранилище
        }
      }
    } catch (error) {
      catchHandler(error, 'get_mentor');
    }
  }

  async function verifyQuestionnaireAccess() {
    try {
      const reqData = {
        type: 'verifyQuestionnaireAccess',
        user_id: user.id,
      };
      const result = await clientSendData('POST', '/verify_questionnaire_access', reqData); // запрос в БД
      if (result) return result;
      return false;
    } catch (error) {
      catchHandler(error, 'get_dismissed_types');
      return false;
    }
  }

  // Удаление прав доступа к анкете
  async function deleteAccess(id) {
    // Уведомление о подтверждении удаления
    try {
      const reqData = {
        type: 'delQuestionnaireAccess',
        id,
      };
      setIsLoading(true);
      const result = await clientSendData('POST', '/del_questionnaire_access', reqData); // запрос в БД
      if (result === 'bad_request') { // если некорректный запрос
        alertFunction('bad_request', 'clientPost'); // уведомление
      }
    } catch (error) {
      catchHandler(error, 'delQuestionnaireAccess');
    }
  }

  // Сохранение выходного интервью
  // eslint-disable-next-line consistent-return
  async function saveOutputInterview() {
    try {
      const logObj = {};
      let questionnaire = outputInterviewQuestionnaire;
      const verifyDate = new Date();
      verifyDate.setDate(verifyDate.getDate() + 1);
      verifyDate.setHours(0, 0, 0, 0);
      const selectDate = new Date(data.dismissal_date);
      selectDate.setHours(0, 0, 0, 0);
      let newAccess = {};
      let change_dismiss_date = false;
      // Проверка заполнения
      // Если не выбран тип увольнения, уведомление о заполнении
      if (outputInterview.dismissal_type_id !== data.dismissal_type_id || outputInterview.dismissal_date !== data.dismissal_date || outputInterview.note !== data.note || outputInterview.mentor_id !== data.mentor_id) {
        if (outputInterview.dismissal_date !== data.dismissal_date || data.dismissal_date === undefined) {
          // Если не заполнена дата увольнения, уведомление о заполнении
          change_dismiss_date = true;
          if (data.dismissal_date === undefined || data.dismissal_date === '') return alertFunction('wrong_date_output_interview', 'clientPost');
          if (selectDate < verifyDate) return alertFunction('verify_dismissal_date', 'clientPost');
        }
        if (data.dismissal_type_id === undefined || data.dismissal_type_id === 'all') return alertFunction('wrong_dismissal_type', 'clientPost');
        if (outputInterview.dismissal_type_id !== data.dismissal_type_id) {
          // Тип увольнения по собственному желанию
          if (data.dismissal_type_id === 1) {
            // Создаем персональные права на анкету выходного интервью
            const accessResult = await addQuestionnaireAccess();
            newAccess = accessResult;
          } else {
            questionnaire = null;
            // Если тип любой другой, проверяем есть ли персональный доступ к анкете выходного интервью
            const access = await verifyQuestionnaireAccess();
            // Если есть, удаляем
            if (access) await deleteAccess(access.id);
          }
        }
        if (Object.keys(outputInterview).length > 0) {
          logObj.title = 'Изменение';
          logObj.message = '';
          logObj.output_interview_id = outputInterview.id;

          // Запись лога изменений
          // Запись лога при изменении даты увольнения
          if (outputInterview.dismissal_date !== data.dismissal_date) {
            logObj.message += `Изменена дата увольнения с ${outputInterview.dismissal_date} на ${data.dismissal_date}. `;
          }
          // Запись лога при изменении типа увольнения
          if (outputInterview.name !== data.dismissal_type_name) {
            logObj.message += `Изменен тип увольнения с ${outputInterview.name} на ${data.dismissal_type_name}`;
          }
          // Запись лога при изменении типа увольнения
          if (outputInterview.note !== data.note) {
            logObj.message += `Изменено примечание с ${outputInterview.note} на ${data.note}`;
          }
          if (outputInterview.mentor_id !== data.mentor_id) {
            logObj.message += `Изменен наставник с ${outputInterview.mentor} на ${data.mentor}`;
          }
        }
        // Создание объекта для записи
        const reqData = {
          type: 'saveOutputInterview',
          data: {
            dismissal_user: user.id,
            dismissal_type: data.dismissal_type_id,
            dismissal_date: data.dismissal_date,
            questionnaire,
            access: newAccess,
            no_dismiss: false,
            prev_no_dismiss: data.no_dismiss,
            change_dismiss_date,
            note: data.note,
            mentor_id: data.mentor_id ? data.mentor_id : null,
          },
          log: { ...logObj },
        };

        const result = await clientSendData('POST', '/save_output_interview', reqData); // запрос в БД
        if (result === 'bad_request') { // если некорректный запрос
          alertFunction('bad_request', 'clientPost'); // уведомление
        } else {
          alertFunction('save_settings', 'clientPost'); // уведомление о сохранении
          setShowCard(false); // Установка состояния показа карточки
          getDismissedUsers(); // Получении пользователей для выходного интервью
          createAction('SET_OUTPUT_INTERVIEW', []); // Очищение хранилища выходного интервью
        }
      } else {
        setShowCard(false); // Установка состояния показа карточки
        createAction('SET_OUTPUT_INTERVIEW', {}); // Очищение хранилища выходного интервью
      }
      log({ message: `Пользователь сохранил карточку выходного интервью сотрудника ${user.displayname}`, eventtype: 'info', subject_id: user.id });
    } catch (error) {
      catchHandler(error, 'save_output_interview');
    }
  }

  // Сохранение выходного интервью
  async function cancelDismiss() {
    try {
      const confirm = window.confirm('Вы уверены, что хотите отменить увольнение?');
      if (confirm) {
        const logObj = {
          title: 'Изменение',
          output_interview_id: outputInterview.id,
          message: 'Увольнение сотрудника отменено ',
        };
        const newAccess = {};

        // Создание объекта для записи
        const reqData = {
          type: 'saveOutputInterview',
          data: {
            dismissal_user: user.id,
            dismissal_type: 5,
            dismissal_date: null,
            questionnaire: outputInterviewQuestionnaire,
            access: newAccess,
            no_dismiss: true,
          },
          log: { ...logObj },
        };

        const result = await clientSendData('POST', '/save_output_interview', reqData); // запрос в БД
        if (result === 'bad_request') { // если некорректный запрос
          alertFunction('bad_request', 'clientPost'); // уведомление
        } else {
          alertFunction('save_settings', 'clientPost'); // уведомление о сохранении
          setShowCard(false); // Установка состояния показа карточки
          getDismissedUsers(); // Получении пользователей для выходного интервью
          createAction('SET_OUTPUT_INTERVIEW', []); // Очищение хранилища выходного интервью
        }
      }
    } catch (error) {
      catchHandler(error, 'save_output_interview');
    }
  }

  // Получение id анкеты выходного интервью
  async function getOutputInterviewQuestionnaire() {
    try {
      const reqData = {
        type: 'getOutputInterviewQuestionnaire',
      };
      const result = await clientSendData('POST', '/get_output_interview_questionnaire', reqData); // запрос в БД
      if (result === 'bad_request') { // если некорректный запрос
        alertFunction('bad_request', 'clientPost'); // уведомление
      } else {
        // Запись id анкеты выходного интервью в хранилище
        createAction('SET_OUTPUT_QUESTIONNAIRE', result);
      }
    } catch (error) {
      catchHandler(error, 'save_output_interview');
    }
  }

  // Добавление персональных прав на анкету
  async function addQuestionnaireAccess() {
    try {
      const reqData = {
        type: 'addQuestionnairiesAccess',
        noNotify: true,
        url: operInfo?.url,
        new_access: {
          questionnaire_id: outputInterviewQuestionnaire,
          period: false,
          period_date: undefined,
          type_id: 2,
          type_name: 'персональный',
          user_assigned_id: user.id,
          user_assigned_name: user.displayname,
        },
      };
      const result = await clientSendData('POST', '/add_questionnaire_access', reqData); // запрос в БД
      if (result) return result; // созданный объект доступа из qa_questionnaire_access
      return {}; // обработка пустого объекта происходит дальше
    } catch (error) {
      catchHandler(error, 'getQuestionnairies');
      return {}; // обработка пустого объекта происходит дальше
    }
  }

  // Получения лога изменений анкеты выходного интервью
  async function getOutputInterviewLog() {
    try {
      const reqData = {
        type: 'getOutputInterviewLog',
        interview_id: Object.keys(outputInterview).length > 0 ? outputInterview.id : null,
      };
      const result = await clientSendData('POST', '/get_output_interview_log', reqData); // запрос в БД
      if (result === 'bad_request') { // если некорректный запрос
        alertFunction('bad_request', 'clientPost'); // уведомление
      } else {
        createAction('SET_OUTPUT_INTERVIEW_LOGS', result); // запись уволенных сотрудников в хранилище
      }
    } catch (error) {
      catchHandler(error, 'get_output_interview_log');
    }
  }

  // Обработка закрытия карточки и нажатия кнопки Отмена
  function cancelAndClose() {
    setOperInfo({ subject_id: null }); // сброс id сотрудника в оперативной информации
    // Установка состояния закрытия карточки
    setShowCard(false);
    // Очистка логов изменения в хранилище
    createAction('SET_OUTPUT_INTERVIEW_LOGS', []);
    log({ message: `Пользователь закрыл карточку выходного интервью сотрудника ${user.displayname}`, eventtype: 'info', subject_id: user.id });
  }

  // Обработка выбора
  function chooseDismissalType(choose) {
    // Установка состояния типа увольнения (id)
    setData({
      ...data,
      dismissal_type_id: choose.id,
      dismissal_type_name: choose.title,
    });
    log({ message: `Пользователь выбрал тип увольнения '${choose.title}' в карточке выходного интервью сотрудника ${user.displayname}`, eventtype: 'info' });
  }

  // Выбор ментора
  function chooseMentor(mentor) {
    setBacklight('green'); // подсветка красным
    setData({
      ...data,
      mentor_id: mentor.id,
      mentor: mentor.title,
    });
    log({ message: `Пользователь выбрал ментора '${mentor.title}' в карточке выходного интервью сотрудника ${user.displayname}`, eventtype: 'info', subject_id: user.id });
  }

  // Поиск ментора
  async function searchMentor(e) {
    try {
      // При поиске наставника, его id сбрасывается и устанавливается только при выборе
      // это необходимо для валидации
      createAction('SET_EDITING_FIELD', { mentor_id: null });

      // Если не введено ничего либо введено меньше 2 символов
      // массив наставников обнуляется и инпут подсвечивается красным
      if (!e.target.value || e.target.value.length <= 2) {
        // При открытии формы редактирования e.target.value отсутствует, но имя наставника отображается в поле
        // поэтому поле подсвечивается зелёным
        if (outputInterview.mentor_id) setBacklight('green'); // подсветка зелёным

        createAction('SET_MENTORS', []); // сброс массива
        backlightHandler([], setBacklight); // подсветка красным
      } else {
        const reqData = {
          type: 'searchMentor',
          query: e.target.value,
        };

        const result = await clientSendData('POST', '/search_mentor', reqData); // запрос в БД
        if (result) {
          backlightHandler(result, setBacklight); // подсветка в зависимости от результата
          createAction('SET_MENTORS', result); // запись массива наставников в хранилище
        }
      }
    } catch (error) {
      catchHandler(error, 'searchMentor'); // обработка ошибок
    }
  }

  return (
    <div className="employee__background">
      {isLoading ? <Loader /> : (
      <div className="employee">
        <Close onClick={() => cancelAndClose()} />
        <h2 className="employee__title">{user.displayname}</h2>

        <div className="employee__form-wrapper">
          <form className="employee__form">
            <div className="employee__info-row">
              <span className="employee__key">Тип увольнения</span>
              {/* Проверка есть ответ или нет для блокировки поля */}
              {answer ? (
              <span className="employee__value">{data.dismissal_type_name}</span>) : (
              <Select
                array={[userGroupsDefaultOption, ...dismissalTypes]}
                onChoose={(choose) => chooseDismissalType(choose)}
                defaultValue={data.dismissal_type_name}
              />)}
            </div>
            <div className="employee__info-row">
              <span className="employee__key">Дата увольнения</span>
              {/* Проверка есть ли ответ для блокировки поля */}
              {answer ? (
              <span className="employee__value">{data.dismissal_date}</span>) : (
              <Input
                type="date"
                className="input"
                defaultValue={data.dismissal_date}
                onChange={(e) => setData({ ...data, dismissal_date: e.target.value })}
              />)}
            </div>
            {/* Отображается ссылка на анкету если она есть */}
            {Object.keys(outputInterview).length > 0 && outputInterview.link && (
            <div className="employee__info-row">
              <span className="employee__key">Ссылка</span>
              <Link className="employee__value clickable" to={`/interview/${outputInterview.link}`}>Выходное интервью</Link>
            </div>)}

            {/* Отображается поле наставника */}
            <div className="employee__info-row">
              <span className="employee__key">Наставник</span>
              <InputSearch
                id="questionnaire-access__creating-mentor-search"
                array={mentors}
                onChoose={chooseMentor}
                onSearch={searchMentor}
                backlight={backlight}
                defaultValue={data.mentor}
              />
            </div>

            {/* Отображается поле примечания */}
            <div className="employee__info-row">
              <span className="employee__key">Примечание</span>
              <TextArea
                defaultValue={data.note}
                onChange={(e) => setData({ ...data, note: e.target.value })}
              />
            </div>
            {/* {Object.keys(outputInterview).length > 0 && */}
            {outputInterview.no_dismiss && (
            <div className="employee__info-row">
              <span className="employee__key">Статус</span>
              <span className="employee__value">Увольнение отменено</span>
            </div>)}
          </form>
          <div className="employee__button-block">

            { // Кнопка отображается если есть карточка увольнения и параметр no_dismiss - false
            Object.keys(outputInterview).length > 0 && !outputInterview.no_dismiss && (
            <Button onClick={cancelDismiss}>Отменить увольнение</Button>)}

            <Button onClick={saveOutputInterview}>Сохранить</Button>
            <Button onClick={cancelAndClose}>Отмена</Button>
          </div>
        </div>
        <Log />
      </div>)}
    </div>
  );
}

export default Dismissal;
