import React from 'react';

/**
* @component Кнопка "Стрелка"
@prop {onClick} function (Обязательный) Обработчик клика
@prop {direction} string направление отображения стрелки
*/

function Arrow(props) {
  const { onClick, direction = 'left' } = props;

  function clickHandler(e) {
    if (onClick) onClick(e);
  }
  return (
    <img
      onClick={clickHandler}
      className={`arrow arrow_${direction}`}
      src="../../icons/arrow.svg"
      alt={`arrow-${direction}`}
    />
  );
}

export default Arrow;
