// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#questionnaire-questionnairies {
  display: flex;
  flex-direction: column;
}
#questionnaire-questionnairies__info-block {
  max-height: calc(100% - 32px);
}
#questionnaire-questionnairies__table {
  max-width: 1000px;
}
#questionnaire-questionnairies__table thead tr:not(:first-child) {
  grid-template-columns: 2fr 1fr 1fr 1fr;
}
#questionnaire-questionnairies__table tbody tr {
  grid-template-columns: 2fr 1fr 1fr 1fr;
}
#questionnaire-questionnairies__logs tr {
  grid-template-columns: 0.5fr 1fr 1fr 1fr 4fr;
}

#questionnaire-access__table .access-manage {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  height: 100%;
}
#questionnaire-access__table .access-manage > * {
  margin: 0 2px;
}
#questionnaire-access__table .access-period {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
}
#questionnaire-access__table .access-period > * {
  margin: 0 2px;
}
#questionnaire-access__table .custom-select {
  font-weight: initial;
}`, "",{"version":3,"sources":["webpack://./src/pages/Questionnaire/Questionnairies/questionnairies.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;AACE;EACE,6BAAA;AACJ;AAEE;EACE,iBAAA;AAAJ;AACI;EACE,sCAAA;AACN;AAEI;EACE,sCAAA;AAAN;AAKI;EACE,4CAAA;AAHN;;AAgBI;EACE,aAAA;EACA,qBAAA;EACA,uBAAA;EACA,YAAA;AAbN;AAcM;EACE,aAAA;AAZR;AAgBI;EACE,aAAA;EACA,qBAAA;EACA,2BAAA;EACA,mBAAA;EACA,YAAA;EACA,WAAA;AAdN;AAeM;EACE,aAAA;AAbR;AAiBI;EACE,oBAAA;AAfN","sourcesContent":["#questionnaire-questionnairies {\n  display: flex;\n  flex-direction: column;\n\n  &__info-block {\n    max-height: calc(100% - 32px);\n  }\n\n  &__table {\n    max-width: 1000px;\n    & thead tr:not(:first-child) {\n      grid-template-columns: 2fr 1fr 1fr 1fr;\n    }\n\n    & tbody tr {\n      grid-template-columns: 2fr 1fr 1fr 1fr;\n    }\n  }\n\n  &__logs {\n    & tr {\n      grid-template-columns: 0.5fr 1fr 1fr 1fr 4fr;\n    }\n  }\n}\n\n#questionnaire-access {\n  &__table {\n    // & tbody td {\n    // flex-flow: row nowrap;\n    // justify-content: center;\n    // align-items: center;\n    // overflow: inherit;\n    // }\n    & .access-manage {\n      display: flex;\n      flex-flow: row nowrap;\n      justify-content: center;\n      height: 100%;\n      & > * {\n        margin: 0 2px;\n      }\n    }\n\n    & .access-period {\n      display: flex;\n      flex-flow: row nowrap;\n      justify-content: flex-start;\n      align-items: center;\n      height: 100%;\n      width: 100%;\n      & > * {\n        margin: 0 2px;\n      }\n    }\n\n    & .custom-select {\n      font-weight: initial;\n    }\n  }\n}\n\n// @media screen and (max-width: 780px) {\n//   #questionnaire-questionnairies {\n//     &__info-block {\n//       width: 100%;\n//     }\n//   }\n// }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
