// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.access-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  transition: 0.3s;
}
.access-form__row {
  display: flex;
  flex-flow: row wrap;
  padding: 4px 12px;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #647D91;
  border-radius: 7px;
  transition: 0.3s;
}
.access-form__sub-form_enabled {
  width: 100%;
  border: 1px solid #647D91;
  padding: 4px;
  border-radius: 8px;
  margin: 8px 0;
  transition: 0.3s;
  max-height: 300px;
  overflow-y: auto;
  background-color: #ffffff;
}
.access-form__sub-form_disabled {
  height: auto;
  max-height: 0;
  overflow: hidden;
  transition: 0.3s;
}`, "",{"version":3,"sources":["webpack://./src/components/Forms/AccessForm/accessForm.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,sBAAA;EACA,WAAA;EACA,wBAAA;EAAA,mBAAA;EACA,gBAAA;AADJ;AAGI;EACI,aAAA;EACA,mBAAA;EACA,iBAAA;EACA,mBAAA;EACA,8BAAA;EACA,yBAAA;EACA,kBAAA;EACA,gBAAA;AADR;AASQ;EACI,WAAA;EACA,yBAAA;EACA,YAAA;EACA,kBAAA;EAEA,aAAA;EACA,gBAAA;EACA,iBAAA;EACC,gBAAA;EACA,yBAAA;AARb;AAUQ;EACI,YAAA;EACA,aAAA;EACA,gBAAA;EACA,gBAAA;AARZ","sourcesContent":["@use \"../../../variables\" as var;\n\n.access-form {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    height: fit-content;\n    transition: .3s;\n\n    &__row {\n        display: flex;\n        flex-flow: row wrap;\n        padding: 4px 12px;\n        align-items: center;\n        justify-content: space-between;\n        border: 1px solid #647D91;\n        border-radius: 7px;\n        transition: .3s;\n\n        & :last-child {\n            //border-bottom: none;\n        }\n    }\n\n    &__sub-form {\n        &_enabled {\n            width: 100%;\n            border: 1px solid var.$ext_color8;\n            padding: 4px;\n            border-radius: 8px;\n            //box-shadow: var.$default-shadow_inset;\n            margin: 8px 0;\n            transition: .3s;\n            max-height: 300px;\n             overflow-y: auto;\n             background-color: #ffffff;\n        }\n        &_disabled {\n            height: auto;\n            max-height: 0;\n            overflow: hidden;\n            transition: .3s;\n        }\n    }\n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
