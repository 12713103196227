import React, { useState, useEffect } from 'react';
import '../clientsportal.scss';
import TRow from '../../../components/Table/TRow';
import TData from '../../../components/Table/TData';
import PaginatedTable from '../../../components/Table/PaginatedTable/PaginatedTable';
import ServiceBody from '../../../components/Service/ServiceBody';
import ProjectRequests from '../ProjectRequests';
import QuestionnaireCard from './QuestionnaireCard';
import ServicePanel from '../../../components/Service/ServicePanel';
import Menu from '../Menu';
import ClientsPortal from '../ClientsPortal';

function QuestionnaireSheet({
  projectID, openedItems, setOpenedItems, activeItem, setActiveItem,
}) {
  const tableId = 'q_sheet';
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [openedMeeting, setOpenedMeeting] = useState();
  const [showCard, setShowCard] = useState(false);
  const [showProjectRequest, setShowProjectRequest] = useState(false);
  // const [activeItem, setActiveItem] = useState(null);
  // const [openedItems, setOpenedItems] = useState(['all-projects']);
  const [showClientsPortal, setShowClientsPortal] = useState(false);

  const menuData = [
    {
      id: 'all-projects',
      title: 'Все проекты',
      children: [
        {
          id: 'project-1',
          title: 'Проект №1',
          children: [
            { id: 'request-1-1', title: 'Запрос 1-1' },
            { id: 'request-1-2', title: 'Запрос 1-2' },
          ],
        },
        {
          id: 'project-2',
          title: 'Проект №2',
          children: [
            { id: 'request-2-1', title: 'Запрос 2-1' },
            { id: 'request-2-2', title: 'Запрос 2-2' },
          ],
        },
      ],
    },
  ];

  const questionsData = {
    'request-1-1': [
      { name: 'Вопрос 1.1', status: 'Заполнено' },
      { name: 'Вопрос 1.2', status: 'В процессе' },
    ],
    'request-1-2': [
      { name: 'Вопрос 2.1', status: 'Новый' },
      { name: 'Вопрос 2.2', status: 'Заполнено' },
    ],
    'request-2-1': [
      { name: 'Вопрос 2-1.1', status: 'В процессе' },
      { name: 'Вопрос 2-1.2', status: 'Новый' },
    ],
    'request-2-2': [
      { name: 'Вопрос 2-2.1', status: 'Заполнено' },
      { name: 'Вопрос 2-2.2', status: 'В процессе' },
    ],
  };

  const headers = [
    { id: 1, title: 'Вопрос', field: 'name' },
    { id: 2, title: 'Статус', field: 'status' },
  ];

  const defaultTableState = {
    title: 'Список вопросов по запросу',
    page: 1,
    limit: 50,
    data: [],
  };

  const [tableState, setTableState] = useState(
    JSON.parse(localStorage.getItem(tableId)) || defaultTableState,
  );

  console.log(projectID);

  useEffect(() => {
    setTableState((prevState) => ({ ...prevState, data: questionsData[projectID] || [] }));
  }, [projectID]);

  if (showProjectRequest) {
    return <ProjectRequests
      projectID={openedMeeting}
      setShowCard={setShowCard}
      activeItem={activeItem}
      openedItems={openedItems}
      setOpenedItems={setOpenedItems}
      setActiveItem={setActiveItem}
      refreshTable={() => setTableState({ ...tableState })}
    />;
  }

  if (showClientsPortal) {
    return <ClientsPortal
      projectID={openedMeeting}
      setShowCard={setShowCard}
      openedItems={openedItems}
      activeItem={activeItem}
      setOpenedItems={setOpenedItems}
      setActiveItem={setActiveItem}
      refreshTable={() => setTableState({ ...tableState })}
    />;
  }

  function openCard(id) {
    setOpenedMeeting(id);
    setShowCard(true);
  }

  function handleMenuItemClick(id) {
    setActiveItem(id);

    const isProject = menuData[0].children.some((project) => project.id === id);

    if (id === 'all-projects') {
      setShowClientsPortal(true);
    } else if (isProject) {
      setOpenedMeeting(id);
      setShowProjectRequest(true);
    } else {
      // Найдите проект, которому принадлежит запрос
      const parentProject = menuData[0].children.find((project) => project.children.some((request) => request.id === id));

      setOpenedItems((prevState) => {
        const newOpenedItems = [...prevState];

        // Добавьте родительский проект, если он еще не открыт
        if (parentProject && !newOpenedItems.includes(parentProject.id)) {
          newOpenedItems.push(parentProject.id);
        }

        // Добавьте или удалите сам запрос
        if (newOpenedItems.includes(id)) {
          return newOpenedItems.filter((item) => item !== id);
        }
        newOpenedItems.push(id);

        return newOpenedItems;
      });

      setTableState((prevState) => ({
        ...prevState,
        data: questionsData[id] || [],
      }));
    }
  }

  if (showCard) {
    return (
              <QuestionnaireCard
                projectID={openedMeeting}
                setShowCard={setShowCard}
                refreshTable={() => setTableState({ ...tableState })}
              />
    );
  }

  return (
            <div className="table-container">
              <ServiceBody horizontal>
                <ServicePanel>
                  <Menu
                    data={menuData}
                    onItemClick={handleMenuItemClick}
                    activeItem={activeItem}
                    setActiveItem={setActiveItem}
                    openedItems={openedItems}
                    setOpenedItems={setOpenedItems}
                  />
                </ServicePanel>
                <PaginatedTable
                  id={`questionnaire_${projectID}`}
                  headers={headers}
                  state={tableState}
                  setState={setTableState}
                  total={total}
                >
                  {(tableState.data || []).map((question, index) => (
                    <TRow key={index}>
                      {headers.map((column) => {
                        const { id, field } = column;
                        const value = question?.[field];
                        return (
                          <TData key={id}>
                            {value ?? '-'}
                          </TData>
                        );
                      })}
                      <TData onClick={() => openCard(question.id)} loading={isLoading}>
                        <img src="../../icons/file-text.svg" alt="file" />
                      </TData>
                    </TRow>
                  ))}
                </PaginatedTable>
              </ServiceBody>
            </div>
  );
}

export default QuestionnaireSheet;
