import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { catchHandler } from '../../../../utils/error_handling/error_handling';
import { clientSendData } from '../../../../utils/functions/requests';
import { alertFunction } from '../../../../utils/functions/alertFunction';
// import { convertTicketId } from '../../../common';
import SingleFileUpload from '../../../../components/UI/FileUpload/SingleFileUpload';
import TextArea from '../../../../components/UI/TextArea';
import Modal from '../../../../components/UI/Modal';
import Table from '../../../../components/Table/Table';
import TBody from '../../../../components/Table/TBody';
import TRow from '../../../../components/Table/TRow';
import TData from '../../../../components/Table/TData';

/**
  * @component ХелпДеск - заявки - карточка заявки - вкладка "Работа по заявке" - форма отправки email
  * @prop {questionID} number - id заявкиы
  * @prop {questionInfo} object - данные процесса по заявке
  * @prop {getTicketProcess} function - получение данных по переписке
*/
function SendForm(props) {
  const {
    ticketID, ticketInfo, falsePerformer,
  } = props;

  const operInfo = useSelector((state) => state.oper_info); // оперативная информация
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [newMail, setNewMail] = useState({}); // новое письмо
  const [displayModal, setDisplayModal] = useState(false); // состояние отображения модалки
  const [standartSolutions, setStandartSolutions] = useState([]); // стандартные решения
  const [acceptFileExtension, setAcceptFileExtension] = useState([]); // разрешения файлов

  function sendEmail() {
    console.log('Отправить сообщение');
  }

  function solveTicket() {
    console.log('Закрыть вопрос');
  }

  // Обработка прикрепленного вложения и запись в объект нового email
  function attachmentHandler(file) {
    console.log(file);
    const reader = new FileReader(); // API чтения файлов
    reader.onloadend = (e) => {
      setNewMail((prev) => ({
        ...prev, // развернуть существующее состояние
        attachment: {
          file: e.target.result, // строка в формате DataURL для записи в БД
          name: file.name, // имя файла
        },
      }));
    };
    reader.readAsDataURL(file); // Прочитать как DataURL ("data:image/png;base64,iVBORw...")
  }

  function chooseSolution(content) {
    setNewMail((prev) => ({ ...prev, text: content }));
    setDisplayModal(false);
  }

  return (
    <div className="ticket-send__wrapper">
      <div className="ticket-send">
        <TextArea
          placeholder="Введите сообщение..."
          value={newMail?.text || ''}
          onChange={(e) => setNewMail((prev) => ({ ...prev, text: e.target.value }))}
          disabled={isLoading}
        />
        <SingleFileUpload options={{ handler: attachmentHandler, size: 5000000, accept: acceptFileExtension.length > 0 ? acceptFileExtension[0].value : '' }}>
          <img className="ticket-send__attach" src="../../icons/button/paperclip.svg" alt="paperclip" title="Добавить вложение" />
        </SingleFileUpload>

       {falsePerformer && <div className="ticket-send__button ticket-send__send" onClick={() => sendEmail('send')} title="Отправить комментарий инициатору">
          <img className="ticket-send__mail" src="../../icons/button/mail.svg" alt="mail" />
                          </div>}
       {falsePerformer && <div className="ticket-send__button ticket-send__solve" onClick={() => solveTicket()} title="Закрыть вопрос">
          <img className="ticket-send__solve" src="../../icons/check.svg" alt="checkmark" />
                          </div>}

        <div className="ticket-send__button ticket-send__comment" onClick={() => sendEmail('comment')} title="Написать личный комментарий">
          <img className="ticket-send__comment" src="../../icons/button/comment.svg" alt="comment" />
        </div>
      </div>
      {newMail.attachment && (
      <div className="ticket-send__attachment">
        <p className="ticket-send__attachment-name">{newMail.attachment?.name}</p>
        <div className="ticket-send__attachment-img">
          <img onClick={() => setNewMail((prev) => ({ ...prev, attachment: null }))} src="../../icons/cancel.svg" alt="cancel" />
        </div>
      </div>)}

      <Modal active={displayModal} setActive={setDisplayModal}>
        <Table id="helpdesk__standart-solutions">
          <TBody>
            {standartSolutions.map((solution) => {
              const { id, title, content } = solution;
              return (
                <TRow key={id} onClick={() => chooseSolution(content)} button>
                  <TData>
                    <p>{title}</p>
                  </TData>
                </TRow>
              );
            })}
          </TBody>
        </Table>
      </Modal>
    </div>
  );
}

export default SendForm;
