import React from 'react';

/**
* @component HTML элемент input с готовыми стилями
* @prop все переданные свойства записываются в атрибуты input
* @prop {ref} object ref-ссылка на DOM элемент
* @prop {type} string атрибут type инпута
*/

const Input = React.forwardRef(({ children, ...props }, ref) => {
  const { type = 'text' } = props;
  return (
    <input
      ref={ref}
      {...props}
      type={type}
      className="input"
    />
  );
});

export default Input;
