import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { catchHandler } from '../../../utils/error_handling/error_handling';
import { clientSendData } from '../../../utils/functions/requests';
import { log, tableToExcel } from '../../../utils/functions/others';
import ServiceBody from '../../../components/Service/ServiceBody';
import Table from '../../../components/Table/Table';
import TBody from '../../../components/Table/TBody';
import TRow from '../../../components/Table/TRow';
import TData from '../../../components/Table/TData';
import TFoot from '../../../components/Table/TFoot';
import THead from '../../../components/Table/THead';
import Loader from '../../../components/UI/Loader';
import AddEmployee from './AddEmployee';
import Employee from './Employee/Employee';

/**
* @component Система контроля РП - Система контроля испытательных сроков - Таблица
*/
function ProbationControl() {
  const [searchParams, setSearchParams] = useSearchParams(); // параметры поиска url
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [showCard, setShowCard] = useState(false); // состояние видимости карточки
  const [showAddCard, setShowAddCard] = useState(false); // отображение карточки добавления
  const [users, setUsers] = useState([]); // сотрудники-стажёры
  const headers = [
    {
      id: 1, field: 'displayname', title: 'Сотрудник', align: 'left',
    },
    {
      id: 2, field: 'title', title: 'Должность', align: 'left',
    },
    {
      id: 3, field: 'department', title: 'Отдел', align: 'left',
    },
    {
      id: 4, field: 'extensionattribute11', title: 'Грейд', align: 'right',
    },
    {
      id: 5, field: 'mentor', title: 'Наставник', align: 'left',
    },
    {
      id: 6, field: 'partner', title: 'Руководитель', align: 'left',
    },
    {
      id: 7, field: 'date_release', title: 'Начало ИС',
    },
    {
      id: 8, field: 'date_probation_end', title: 'Конец ИС',
    },
    {
      id: 9, field: 'status', title: 'Статус', align: 'left',
    },
  ];

  useEffect(() => {
    getProbationUsers();
    checkSearchParams();
  }, []);

  // Проверка наличия параметров поиска в url
  function checkSearchParams() {
    try {
      // если url содержит параметр поиска "collaborator" открывается карточка стажёра
      if (searchParams.get('collaborator')) setShowCard(true);
      else return;
    } catch (error) {
      catchHandler(error, 'checkSearchParams');
    }
  }

  // Получение массива стажёров
  async function getProbationUsers() {
    try {
      const reqData = { type: 'getProbationUsers' };
      setIsLoading(true); // состояние - загружается
      const result = await clientSendData('POST', '/get_probation_users', reqData); // запрос в БД
      if (result) setUsers(result);
      setIsLoading(false); // загрузка завершена
    } catch (error) {
      catchHandler(error, 'getProbationUsers'); // обработчик ошибок
      setIsLoading(false); // загрузка завершена
    }
  }

  // Показ карточки стажёра
  function showEmployeeCard(employee) {
    log({ message: `Пользователь выбрал карточку сотрудника ${employee.displayname}`, eventtype: 'info' });
    setSearchParams({ collaborator: employee.id }); // установить параметры поиска
    setShowCard(true);
  }

  return ( // Показывается таблица
    <ServiceBody>
      {isLoading ? <Loader /> : (
        <Table id="probation__table">
          <THead
            title="Система контроля испытательных сроков"
            headers={headers}
            array={users}
            setArray={setUsers}
            button_place="row"
          />

          <TBody>
            {users.map((user) => (
              <TRow key={user.id} loading={isLoading}>
                {headers.map((item) => (
                  <TData key={item.id} align={item?.align}>
                    {user[item.field]}
                  </TData>))}
                <TData onClick={() => showEmployeeCard(user)}>
                  <img src="../../icons/button/settings.svg" alt="setting" />
                </TData>
              </TRow>
            ))}
          </TBody>

          <TFoot>
            <TRow>
              <TData onClick={() => setShowAddCard(true)}>Добавить сотрудника</TData>
              <TData onClick={() => tableToExcel('probation__table', 'Система контроля ИС')}>
                Скачать в Excel
              </TData>
            </TRow>
          </TFoot>
        </Table>
      )}

      {showCard && (
      <Employee
        setShowCard={setShowCard}
        getProbationUsers={getProbationUsers}
      />
      )}

      {showAddCard && (
      <AddEmployee
        setShowAddUserCard={setShowAddCard}
        getProbationUsers={getProbationUsers}
      />
      )}
    </ServiceBody>
  );
}

export default ProbationControl;
