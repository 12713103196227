import React, { useState, useEffect } from 'react';
import ImageMapper from 'react-img-mapper';
import ServiceBody from '../../components/Service/ServiceBody';
import { clientSendData } from '../../utils/functions/requests';
import { catchHandler } from '../../utils/error_handling/error_handling';

function WorkZone(props) {
  const { clickPlace, zoneId, bookingDate } = props;
  const [zoneSettings, setZoneSettings] = useState({});
  const [scheme, setScheme] = useState(null);
  const [hoveredAreaTitle, setHoveredAreaTitle] = useState('');
  const [bookingIntervals, setBookingIntervals] = useState([]);
  const [fullDayWorkers, setFullDayWorkers] = useState([]);
  useEffect(() => {
    getPlaces(bookingDate);
  }, [bookingDate]);
  const MAP1 = {
    title: 'Рабочие места зона 1',
    map: {
      name: 'workzone_1',
      areas: [],
    },
  };

  const [workplaces, setWorkPlaces] = useState(MAP1);

  const enterArea = (place, index, event) => {
    const currentPlace = event.target;
    currentPlace.title = '';
    let outputString = '';

    if (place.preFillColor === 'rgba(240, 65, 65, 0.8)') {
      // Добавляем текст для забронированного на весь день места (красный)
      outputString = fullDayWorkers.find((element) => element.workplace_id === place.id).worker;
      currentPlace.title = `${outputString}`;
    } else if (place.preFillColor === 'rgba(239, 247, 143, 0.8)') {
      // Добавляем текст для частично-забронированного места (желтый)
      for (let i = 0; i < bookingIntervals.length; i++) {
        if (bookingIntervals[i].id === place.id) {
          for (let j = 0; j < bookingIntervals[i].freeBookingIntervals.length; j++) {
            // console.log(bookingIntervals[i].freeBookingIntervals[j].start.replace('T', ' ').substring(0, 19));
            outputString += `${bookingIntervals[i].freeBookingIntervals[j].start.replace('T', ' ').substring(10, 19)
            } - ${
              bookingIntervals[i].freeBookingIntervals[j].end.replace('T', ' ').substring(11, 19)
            }\n`;
          }
          currentPlace.title = `Свободное время:\n${outputString}`;
        }
      }
    }
    setHoveredAreaTitle(outputString);
  };

  const getTipPosition = (area) => {
    const coords = area.coords;
    const centerX = coords.filter((_, index) => index % 2 === 0).reduce((total, el) => total + el, 0) / (coords.length / 2);
    const centerY = coords.filter((_, index) => index % 2 !== 0).reduce((total, el) => total + el, 0) / (coords.length / 2);
    return { top: `${centerY}px`, left: `${centerX}px` };
  };

  async function getPlaces(day) {
    try {
      const reqData = {
        type: 'getPlaces',
        zone: zoneId,
        bookingDate: day,
      };
      const result = await clientSendData('POST', '/get_places', reqData);
      if (result) {
        const areasData = result.data.map((el) => el.json_build_object);
        setWorkPlaces((state) => ({ ...state, map: { name: 'test', areas: areasData } }));
        setZoneSettings(result.zone);
        setBookingIntervals(result.bookingIntervalArray);
        setFullDayWorkers(result.names);
        if (result.zone?.graphic_scheme) {
          let binary = '';
          const bytes = [].slice.call(new Uint8Array(result.zone.graphic_scheme.data));
          bytes.forEach((b) => {
            binary += String.fromCharCode(b);
          });
          setScheme(`data:application/octet-stream;base64,${btoa(binary)}`);
        }
      }
    } catch (error) {
      catchHandler(error, 'getPlaces'); // обработчик ошибок
    }
  }
  return (

    <ServiceBody>
         <div className="grid">
      <div className="presenter">
      <div className="placeMap" style={{ position: 'relative' }}>
      <ImageMapper
        width={zoneSettings?.settings?.width}
        height={zoneSettings?.settings?.height}
        src={scheme}
        map={workplaces.map}
        onClick={(place) => clickPlace(place)}
        stayHighlighted // параметр для подсвечивания выбранного места
        areaKeyName="id"
        onMouseEnter={enterArea}
        alt={hoveredAreaTitle}
      />
   {/*   <div className="user-picker__wrapper">
            <div id="user-picker" style={{ width: '300px', display: 'flex' }}>
          <InputSearch
            className="add-user"
            id="helpdesk__new-ticket-search-initiator"
            array={array}
          //  onSearch={(e) => searchWrapper(e)}
          //  onChoose={(user) => chooseUser(user)}
            placeholder="Выберите сотрудника"
           // defaultValue={booking.user_title}
           // loading={isSearching}
          //  disabled={!booking?.date_start}
          //  onChoose={(user) => setSelectUsers(user)}
          //  defaultValue={selectUsers?.user_title}
          />
           <div className="tags__button">
                        <img className="tags__img" src="../../icons/cancel.svg" alt="add-tag" />
           </div>
            </div>
            <div className="tags__list">
                    {array?.map((el) => <p className="tags__tag" key={el.id}>
                        <span className="upu__close">X</span>
                        {el.title}
                                        </p>)}
            </div> */}

         {workplaces.map.areas.map((el) => (

<span
  className="tooltip"
  key={el.id}
  style={{ ...getTipPosition(el) }}
>
  {el.title}
</span>
         ))
}
      </div>

      </div>

         </div>
    </ServiceBody>

  );
}

export default WorkZone;
