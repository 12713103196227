// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper {
  display: flex;
}
.wrapper > * {
  margin: 2px;
}`, "",{"version":3,"sources":["webpack://./src/components/Basic/basic.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AAAE;EACE,WAAA;AAEJ","sourcesContent":[".wrapper {\n  display: flex;\n  & > * {\n    margin: 2px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
