import React from 'react';
import CardSetting from '../../../../components/Card/CardSetting';
import Toggle from '../../../../components/UI/Toggle';

/**
 * @component Администрирование базы знаний - редактирование подразделений - карточка подразделения - вкладка Cхемы - карточка схемы - вкладка Настройки - переключатель настройки
 * @prop {field} string - ключ объекта схемы
 * @prop {setting} string - название настройки в значении переданного ключа (фактически подключ)
 * @prop {value} boolean - данные настройки
 * @prop {handler} function - обработчик изменения значения
 * @prop {validate} boolean - необходимость валидации значения
*/
function ToggleSetting(props) {
  const {
    field, setting, value, handler, children, validate,
  } = props;

  return (
    <CardSetting title={`${setting}:`} invalid={validate && !value}>
      <Toggle
        state={String(Boolean(value))}
        onClick={() => handler(field)}
      />
      {children}
    </CardSetting>
  );
}

export default ToggleSetting;
