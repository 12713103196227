import React from 'react';

/**
* @component Чекбокс
@prop {id} string (Обязательный) идентификатор DOM-элемента
@prop {value} boolean значение чекбокса
@prop {onChange} function (Обязательный) обработчик выбора
@prop {defaultChecked} boolean выбор по умолчанию
@prop {ismarked} string параметр визуальной маркировки (строковый bool - 'true')
*/
function CheckBox({ children, ...props }) {
  const {
    id, value, onChange, defaultChecked, ismarked,
  } = props;

  return (
    <div className="checkbox__wrapper">
      <input
        {...props}
        id={id}
        type="checkbox"
        value={value}
        onChange={onChange}
        className={ismarked === 'true' ? 'checkbox__input checkbox__input_marked' : 'checkbox__input'}
        defaultChecked={defaultChecked}
      />
      <div className="checkbox__mark" />
      {children && <label htmlFor={id} className="checkbox__label">{children}</label>}
    </div>
  );
}

export default CheckBox;
