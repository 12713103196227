import React, { useState, useEffect } from 'react';
import CardBody from '../../../components/Card/CardBody';
import Loader from '../../../components/UI/Loader';
import CardSetting from '../../../components/Card/CardSetting';
import CardFooter from '../../../components/Card/CardFooter';
import Button from '../../../components/UI/Button/Button';
import { clientSendData } from '../../../utils/functions/requests';
import { catchHandler } from '../../../utils/error_handling/error_handling';
import Input from '../../../components/UI/Input';
import TextArea from '../../../components/UI/TextArea';
import { alertFunction } from '../../../utils/functions/alertFunction';

function TempAccessMain(props) {
  const { closeHandler, refreshTable, accessCardId } = props;
  const [cardTaskData, setCardTaskData] = useState({});
  const [changeData, setChangeData] = useState({});
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [cardInfo, setCardInfo] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [verifyData, setVerifyData] = useState({});

  useEffect(() => {
    getTmpTasksCard();
  }, [accessCardId]);

  // Получить задачи
  async function getTmpTasksCard() {
    try {
      const reqData = {
        type: 'getTmpTasksCard',
        accessCardId,
      };
      setIsLoading(true);
      const result = await clientSendData('POST', '/get_tmp_tasks_card', reqData);
      if (result?.success) {
        setCardInfo(result.data); // Результат записывается в состояние
        setCardTaskData(result.data.task_data);
        setChangeData({ id: result.data.id, date_fulfilled: result.data.date_fulfilled, comment: result.data.task_data.comment });
        setVerifyData({ date_fulfilled: result.data.date_fulfilled, comment: result.data.task_data.comment });
      }
    } catch (error) {
      catchHandler(error, 'getTmpTasksCard');
    } finally {
      setIsLoading(false);
    }
  }

  async function saveTempAccessCard(action) {
    try {
      const reqData = {
        data: changeData,
        type: 'saveNewTempAccessCard',
        action,
        verifyData,
      };

      const result = await clientSendData('POST', '/save_new_temp_access_card', reqData);
      if (result) {
        await alertFunction('save_settings', 'client');
        refreshTable();
      }
    } catch (error) {
      catchHandler(error, 'saveNewTempAccessCard'); // обработчик ошибок
    }
  }

  if (isLoading) return <CardBody><Loader /></CardBody>;
  return (
    <>
    <CardBody>
        <CardSetting title="№ запроса:">{cardInfo?.ticket_number}</CardSetting>
        <CardSetting title="Инициатор:">{cardInfo?.initiator}</CardSetting>
        <CardSetting title="Тип задачи:">{cardInfo?.task_type}</CardSetting>
        <CardSetting title="Дата исполнения:">
          <Input
            type="date"
            value={changeData.date_fulfilled}
            onChange={(e) => {
              setChangeData((state) => ({ ...state, date_fulfilled: e.target.value }));
              setIsEditing(true);
            }}
            disabled={cardInfo.status_id === 4}
          />
        </CardSetting>
        <CardSetting title="Ресурс:">{cardTaskData?.resource}</CardSetting>
        <CardSetting title="Уведомить за (количество дней):">{cardTaskData?.notify_days}</CardSetting>
        <CardSetting title="Комментарий:">
          <TextArea
            defaultValue={changeData?.comment}
            onChange={(e) => {
              setChangeData((state) => ({ ...state, comment: e.target.value }));
              setIsEditing(true);
            }}
            disabled={cardInfo.status_id === 4}
          />
        </CardSetting>
    </CardBody>
    <CardFooter>
    {isEditing && <Button onClick={() => saveTempAccessCard('update')}>Сохранить</Button>}
    {cardInfo?.complete && cardInfo?.status_id !== 4 && <Button onClick={() => { saveTempAccessCard('complete'); closeHandler(); }}>Выполнено</Button>}
    <Button onClick={closeHandler}>Закрыть</Button>
    </CardFooter>
    </>
  );
}

export default TempAccessMain;
