import React, { useEffect, useState } from 'react';
import { catchHandler } from '../../../utils/error_handling/error_handling';
import { alertFunction } from '../../../utils/functions/alertFunction';
import { setOperInfo } from '../../../utils/functions/others';
import { clientSendData } from '../../../utils/functions/requests';
import ServiceBody from '../../../components/Service/ServiceBody';
import Table from '../../../components/Table/Table';
import THead from '../../../components/Table/THead';
import TBody from '../../../components/Table/TBody';
import TRow from '../../../components/Table/TRow';
import TData from '../../../components/Table/TData';
import Loader from '../../../components/UI/Loader';
import CheckBox from '../../../components/UI/CheckBox';
import BulkAccess from './BulkAccess';

/**
 * @component Управление массовым доступом
*/
function Services() {
  // const [usersId, setUsersId] = useState([])
  // const servicesAccess = useSelector(state => state.services.services_access)
  // const location = useLocation()
  const [isLoading, setIsLoading] = useState(false); // Состояние загрузки
  const [services, setServices] = useState([]); // Все сервисы
  const [showAccessCard, setShowAccesCard] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const headers = [
    { title: '👁', field: 'visibility' },
    { title: 'Сервис', field: 'title' },
    { title: 'Действие', field: 'title' },
  ];
  useEffect(() => { // При каждом рендере компонента выполняются функции, находящиеся внутри useEffect
    getAllServices();
    setOperInfo();
  }, []);

  // Получение информации о сервисах
  async function getAllServices() {
    try {
      const reqData = {
        type: 'getAllServices',
      };
      setIsLoading(true);
      const result = await clientSendData('POST', '/get_all_services', reqData);
      if (result === 'bad_request') alertFunction('bad_request', 'clientPost');
      else setServices(result);
      setIsLoading(false);
    } catch (error) {
      catchHandler(error, 'getAllServices');
    }
  }

  function configAccess(service) {
    setSelectedService(service);
    setShowAccesCard(true);
  }

  // // Получение информации о доступах
  // async function getServicesAccess () {
  //     try {
  //         const reqData = {
  //             type: "getServicesAccess",
  //         }
  //             setIsLoading(true)
  //             const result = await clientSendData("POST", "/get_services_access", reqData)
  //             createAction("SET_SERVICES_ACCESS", result)
  //             setIsLoading(false)
  //     } catch(error) {
  //         catchHandler(error, "getServicesAccess")
  //     }
  // }

  // // Получение id всех пользователей
  // async function getUsersId () {
  //     try {
  //         const reqData = {
  //             type: "getUsersShortData",
  //             req: "id"
  //         }
  //             setIsLoading(true)
  //             const result = await clientSendData("POST", "/get_users_short_data", reqData)
  //             setUsersId(result)
  //             setIsLoading(false)
  //     } catch(error) {
  //         catchHandler(error, "getUsersId")
  //     }
  // }

  // // Определение состояния доступа к конкретному сервису
  // function detectAccess (service) {
  //     try {
  //         // Доступы сервиса
  //         const serviceAccess = servicesAccess.filter((row) => row.upu_resources_id === service.id)
  //         // Id пользователей, которым доступен этот сервис
  //         const serviceAccessUsers = serviceAccess.map((row) => {
  //             if (row.access === 1) return row.upu_users_id // если доступ равен 1 вернет id пользователя
  //             else return false   // иначе вернет false
  //         })

  //         // Сравнение id всех пользователей с id тех кому доступен сервис
  //         const searchMatches = usersId.map((row) => {
  //             if (serviceAccessUsers.includes(row.id)) return true // если id пользователя присутствует в списке id тех кому доступен сервис
  //             else return false
  //         })
  //         // Интерпретация результатов
  //         if (!searchMatches.includes(false)) return "allTrue"
  //         if (!searchMatches.includes(true)) return "allFalse"
  //         else return "mix"
  //     } catch(error) {
  //         catchHandler(error, "detectAccess")
  //     }
  // }

  // // Предоставление / отмена доступа к конкретному сервису для всех пользователей
  // async function toggleAccess (service, access) {
  //     try {
  //         const reqData = {
  //             type: "toggleAccess",
  //             service_id: service.id,
  //             users: usersId,
  //             access: access
  //         }

  //             let confirm = window.confirm((Boolean(access) ? "Предоставить" : "Отменить") + " доступ для всех пользователей?")
  //             if (confirm) {
  //                 setLoadingElement(service.id)
  //                 const result = await clientSendData("POST", "/toggle_access", reqData)
  //                 if (result === "bad_request") alertFunction("bad_request", "clientPost") // Уведомление Некорректный запрос
  //                 else if (result === "success") alertFunction("save_settings") // Уведомление Настройки сохранены
  //                 await getServicesAccess()
  //                 setLoadingElement('')
  //             }
  //     } catch(error) {
  //         catchHandler(error, "toggleAccess")
  //     }
  // }

  return (
    <ServiceBody>
      {/* если загружается - показывается лоадер */}
      {isLoading ? <Loader /> : (
      <Table id="admin-panel__services-table">
        <THead
          title={`Сервисы (${services.length})`}
          headers={headers}
          array={services}
          setArray={setServices}
        />
        <TBody>
          {services.map((service) => (
            <TRow key={service.id}>
              <TData>
                <CheckBox id={`services__visibility_${service.id}`} checked={service.parameters.visibility} readOnly />
              </TData>
              <TData align="left">{service.title}</TData>
              <TData onClick={() => configAccess(service)}>
                Настроить доступ
              </TData>
            </TRow>
          ))}
        </TBody>
      </Table>)}
      {showAccessCard && (
      <BulkAccess
        service={selectedService}
        setShow={setShowAccesCard}
      />)}
    </ServiceBody>
  );
}

export default Services;
