import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { clientSendData } from '../../../../utils/functions/requests';
import { catchHandler } from '../../../../utils/error_handling/error_handling';
import { downloadDataUrl } from '../../../../utils/functions/others';
import CardSetting from '../../../../components/Card/CardSetting';
import ImgFrame from '../../../../components/UI/ImgFrame';
import QuestionnaireProcessMessage from './QuestionnaireProcessMessage';
import SendForm from './SendForm';

/**
  * @component ХелпДеск - заявки - карточка заявки - вкладка "Работа по заявке"
  * @prop {ticketID} number - id заявки
*/
function QuestionnaireProcess(props) {
  const { questionID } = props;
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [currentUser, setCurrentUser] = useState(true);
  const [imageSrc, setImageSrc] = useState(null);
  const [size, setSize] = useState('medium');

  const ticketData = {
    info: {
      initiator_id: 1,
      initiator: 'Иван Иванов',
      performer: 'Петр Петров',
      status_id: 2,
      date_created: '2024-09-01',
    },
    process: [
      {
        id: 101,
        date_created: '2024-09-01 10:00:00',
        title: 'Начало обработки заявки',
        sender_type: 'initiator',
        sender_name: 'Иван Иванов',
        content: 'Добрый день! Пожалуйста, данный вопрос необходимо решить к завтрашнему дню.',
        message_type: 'solve',
        files: [], // Нет вложений
      },
      {
        id: 102,
        date_created: '2024-09-01 10:15:00',
        title: 'Запрос дополнительной информации',
        sender_type: 'performer',
        sender_name: 'Петр Петров',
        content: 'Добрый день! Можете, пожалуйста, предоставить дополнительные документы?',
        message_type: 'received',
        files: ['document.pdf'], // Вложение
      },
      {
        id: 103,
        date_created: '2024-09-01 11:00:00',
        title: 'Ответ на запрос',
        sender_type: 'initiator',
        sender_name: 'Иван Иванов',
        content: 'Вот необходимые документы.',
        message_type: 'comment',
        files: ['invoice.jpg', 'report.docx'], // Несколько вложений
      },
    ],
  };

  const {
    initiator_id, initiator, performer = '', status_id,
  } = ticketData.info; // деструктуризация данных по заявке
  const messagesEndRef = useRef(null);

  useEffect(() => {
    scrollToBottom();
  }, [ticketData.process]);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="ticket-process">
      <CardSetting title="Общение по вопросу:" />

      <div className="ticket-process__content">
         <div className={`ticket-process__content_wrapper${isLoading ? ' upu__loading' : ''}`}>
          {/* <TicketProcessMessage
            date_created={date_created}
            subject={title}
            sender={initiator}
            content={content}
          /> */}

{
  ticketData.process?.map((item) => (
    <QuestionnaireProcessMessage
      key={item.id}
      date_created={item.date_created}
      subject={item.title}
      sender_type={item.sender_type}
      sender={item.sender_name}
      content={
        item.files && item.files.length > 0
          ? (
            <>
              {item.content}
              <img
                src="../../icons/button/paperclip.svg"
                alt="paperclip"
                title="Вложение"
              />
            </>
          )
          : item.content
      }
      message_type={item.message_type}
    />
  ))
}
<div ref={messagesEndRef} />
         </div>
      </div>

      <SendForm
        questionID={questionID}
        questionInfo={ticketData.info}
        // getTicketProcess={getTicketProcess}
        falsePerformer={currentUser}
      />

      <div className="ticket-process__inputs">
        <CardSetting title="Дата создания:">01.03.2024</CardSetting>
        <CardSetting title="Инициатор:">
          <Link className="clickable" to={`?user=${initiator_id}`}>Иванов И.И.</Link>
        </CardSetting>
        <CardSetting title="Участники:">Сидоров В.И., Петров А.Н., Федоров К.В.</CardSetting>
        <CardSetting title="Вложения к вопросу:" />
        {/* ВЛОЖЕНИЯ  */}

              <CardSetting>
                <span className="clickable">
              файл 1, файл 2, файл 3
                </span>
              </CardSetting>

      </div>

  <ImgFrame size={size} setSize={setSize} imageSrc={imageSrc} />

    </div>
  );
}

export default QuestionnaireProcess;
