/* eslint-disable no-console */
import { log } from '../functions/others';

// eslint-disable-next-line import/prefer-default-export
export async function catchHandler(error, source) {
  try {
    const message = `Источник: ${source},\nОшибка: ${error.name},\nСообщение: ${error.message}`;
    await log({ message, eventtype: 'error' }); // Записать ошибку в лог
    console.log(message);
    return true;
  } catch (caughtError) {
    console.log(caughtError);
    return false;
  }
}
