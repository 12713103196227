import React, { useEffect, useState } from 'react';
import Table from '../../components/Table/Table';
import THead from '../../components/Table/THead';
import TBody from '../../components/Table/TBody';
import TRow from '../../components/Table/TRow';
import { clientSendData } from '../../utils/functions/requests';
import { catchHandler } from '../../utils/error_handling/error_handling';
import { headers } from './common';
import TData from '../../components/Table/TData';
import ServiceBody from '../../components/Service/ServiceBody';
import Select from '../../components/UI/Select';
import { alertFunction } from '../../utils/functions/alertFunction';

function GroupCategories() {
  const [categories, setCategories] = useState([]);
  const [groups, setGroups] = useState([]);
  const [groupsCategories, setGroupsCategories] = useState([]);
  const [selCategory, setSelCategory] = useState({});
  const [changeData, setChangeData] = useState({});

  useEffect(() => {
    getCategories();
    getSdGroups();
    getSdGroupsCategories();
  }, []);

  async function editSdGroupsCategories(editAction, group_id, category) {
    try {
      const reqData = {
        gId: group_id,
        paramCategory: category,
        action: editAction,
        type: 'editSdGroupsCategories',
      };
      const result = await clientSendData('POST', '/edit_sd_groups_categories', reqData); // запрос в БД action: useState

      if (result) {
        selectCategory(group_id, { category_id: null, title: '' });
      }
    } catch (error) {
      catchHandler(error, 'editSdGroupsCategories'); // обработчик ошибок
    } finally { getSdGroupsCategories(); }
  }

  async function getSdGroupsCategories() {
    try {
      const reqData = {
        type: 'getSdGroupsCategories',
      };
      // setIsLoading(true); // загружается
      const result = await clientSendData('POST', '/get_sd_groups_categories', reqData); // запрос в БД action: useState
      if (result) {
        setGroupsCategories(result.data);
        // helpObj.map((group_id, category_arr) => (setGroupsCategories({ ...groupsCategories, group_id: category_arr })));
        // setGroupsCategories({ ...groupsCategories, ...helpObj }); // запись в переменную
      }
    } catch (error) {
      catchHandler(error, 'getSdGroupsCategories'); // обработчик ошибок
    } // finally { setIsLoading(false); }
  }

  async function getSdGroups() {
    try {
      const reqData = {
        type: 'getSdGroups',
      };
      // setIsLoading(true); // загружается
      const result = await clientSendData('POST', '/get_sd_groups', reqData); // запрос в БД action: useState
      if (result) {
        setGroups(result.data); // запись в переменную
      }
    } catch (error) {
      catchHandler(error, 'getDirections'); // обработчик ошибок
    } // finally { setIsLoading(false); }
  }

  async function getCategories() {
    try {
      const reqData = {
        type: 'getSDCategories',
      };
      const result = await clientSendData('POST', '/get_sd_categories', reqData); // запрос в БД
      if (result) {
        for (let i = 0; i < result.data.length; i++) {
          setCategories((current) => [...current, { id: result.data[i].id, title: result.data[i].title }]);
        }
        // запись в переменную

        // setUsers(result.users);
      }
    } catch (error) {
      catchHandler(error, 'getCategories'); // обработчик ошибок
    }
  }

  function selectCategory(groupId, category) {
    const helpSelCategory = {};
    helpSelCategory[groupId] = { category_id: category.id, title: category.title };
    setSelCategory((state) => ({ ...state, ...helpSelCategory }));
  }

  return (
   <ServiceBody>
    <Table id="groupCategories">
        <THead
          title="Сервисы по группам"
          headers={headers.serviceGroups}
        />
        <TBody>
            {groupsCategories.map((group) => (
                <TRow key={`${group.id}row`}>
                  <TData>{group.title}</TData>
                  <TData key={group.id}>
                  <div className="tags__input-block">
                              <Select
                                id={group.id}
                                array={categories}
                                className="tags__input"
                                onChoose={(category) => selectCategory(group.id, category)}
                                defaultValue={selCategory[group.id] !== undefined ? selCategory[group.id].title : ''}
                              />
                              <div onClick={() => editSdGroupsCategories('create', group.id, selCategory[group.id])} className="tags__button">
                                  <img className="tags__img" src="../../icons/cancel.svg" alt="add-tag" />
                              </div>
                  </div>
                  <div className="tags__list">
                  {(() => {
                    if (JSON.stringify(group.category_arr[0]) !== '{}') {
                      return (
                        group.category_arr.map((el) => <p className="tags__tag" key={el.id}>
                              <span onClick={() => editSdGroupsCategories('delete', group.id, el)} className="upu__close">X</span>
                              {el.parent ? `${el.parent}(${el.title})` : el.title}
                                                       </p>)
                      );
                    }
                    return null;
                  })()}
                  </div>
                  </TData>
                </TRow>
            ))}
        </TBody>
    </Table>
   </ServiceBody>
  );
}

export default GroupCategories;
