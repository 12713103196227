/* eslint-disable no-await-in-loop */
import React, { useEffect, useState } from 'react';
// Функции
import { catchHandler } from '../../utils/error_handling/error_handling';
import { clientSendData } from '../../utils/functions/requests';
import { alertFunction } from '../../utils/functions/alertFunction';
import { setOperInfo } from '../../utils/functions/others';
import { authorization } from '../../utils/functions/authenticate';
// Компоненты
import ServiceBody from '../../components/Service/ServiceBody';
import Cap from '../Cap/Cap';

// Стили
import './legalform.scss';
import Button from '../../components/UI/Button/Button';
import CardFooter from '../../components/Card/CardFooter';
import CardSetting from '../../components/Card/CardSetting';
import CardBody from '../../components/Card/CardBody';
import CardHeader from '../../components/Card/CardHeader';
import Card from '../../components/Card/Card';
import Input from '../../components/UI/Input';

/**
  @component Сервис генерации анкеты юридического лица
  @Описание в documents/API/CounterParty.md
*/
function LegalForm() {
  const [ddParametres, setDdParametres] = useState(null); // параметры подключения к api
  const [isAuthorized, setIsAuthorized] = useState(false); // Состояние авторизации
  const [inn, setInn] = useState('');
  const [show, setShow] = useState(true);

  useEffect(() => {
    awaitRequests();
    getDdParameters();
    setOperInfo();
  }, []);

  async function awaitRequests() {
    const checkAuthorization = await authorization(); // авторизация
    setIsAuthorized(checkAuthorization);
  }

  // Получение параметров подключения к DaData
  async function getDdParameters() {
    try {
      const reqData = { type: 'getDdParameters' };
      const result = await clientSendData('POST', '/get_dd_parameters', reqData);
      if (result) setDdParametres(result);
    } catch (error) {
      catchHandler(error, 'getDdParameters');
    }
  }

  // Сохранить в БД данные DaData-запроса
  async function saveDdQuery(query, suggestions) {
    try {
      const reqData = {
        type: 'saveDdQuery',
        query,
        query_result: suggestions,
      };
      const result = await clientSendData('POST', '/save_dd_query', reqData);
      setDdParametres({ ...ddParametres, count: result });
    } catch (error) {
      catchHandler(error, 'saveDdQuery');
    }
  }

  // Получить сохраненные в БД данные DaData-запроса
  async function getSavedDdQuery(query) {
    try {
      const reqData = {
        type: 'getSavedDdQuery',
        query,
        create_file: true,
      };
      const result = await clientSendData('POST', '/get_saved_dd_query', reqData);
      return result;
    } catch (error) {
      catchHandler(error, 'getSavedDdQuery');
      return null;
    }
  }

  // Получить сохраненные в БД данные DaData-запроса
  async function createLegalForm(query) {
    try {
      const reqData = {
        type: 'createLegalForm',
        query,
      };
      const result = await clientSendData('POST', '/create_legal_form', reqData);
      if (result.suggestions === null) {
        alertFunction('no_data', 'clientPost');
      } else {
        const company = result.suggestions[0].data.name.short;
        const uInt8Array = new Uint8Array(result.file.data);
        const blob = new Blob([uInt8Array], {
          type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        });
        const tempLink = document.createElement('a');
        tempLink.href = window.URL.createObjectURL(blob);
        tempLink.setAttribute('download', `anketa_${query}_${company}.docx`);
        document.body.appendChild(tempLink);
        tempLink.click();
      }
    } catch (error) {
      catchHandler(error, 'createLegalForm');
    }
  }

  // Получить данные DaData
  async function getDData(string) {
    try {
      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Token ${ddParametres.api}`,
        },
        body: JSON.stringify({ query: string }),
      };

      let response = await fetch(ddParametres.url, options);
      const result = await response.json();

      // Проверка наличия suggestions и их содержимого
      if (result?.suggestions && result.suggestions.length > 0) {
        // Если первый элемент suggestions содержит данные с '64.19'
        if (result.suggestions[0].data.okved === '64.19') {
          // Изменение URL
          const bankUrl = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/bank';
          // Повторный запрос с новым URL
          response = await fetch(bankUrl, options);
          result.bank = (await response.json()).suggestions;
        }
        // Сохранение результата запроса в БД
        await saveDdQuery(string, result);
      } else {
        // Если результат пустой, сохраняем пустой результат
        result.suggestions = [{ data: { empty_result: string } }];
        await saveDdQuery(string, result);
      }
    } catch (error) {
      catchHandler(error, 'getDData');
    }
  }

  // Валидация запроса
  async function handleButtonClick() {
    // Валидация значения через регулярное выражение из схемы
    if (/^[\d+]{10,12}$/.test(inn)) {
      const dataSearch = await getSavedDdQuery(inn);
      if (dataSearch.suggestions) {
        if (dataSearch.suggestions[0].data.okved !== '64.19' || dataSearch.bank) {
          await createLegalForm(inn);
        } else {
          await getDData(inn);
          await createLegalForm(inn);
        }
      } else {
        await getDData(inn);
        await createLegalForm(inn);
      }
    } else {
      alertFunction('check', 'clientPost', 'ИНН');
    }
  }

  // Обработчик изменения значения поля ввода ИНН
  const handleInnChange = (e) => {
    const innValue = e.target.value;
    setInn(innValue); // Обновляем состояние с ИНН
  };

  if (isAuthorized) {
    return (
      <ServiceBody>
        <div className="legal-form">
      {show
       && <Card setShow={setShow} id="legal-form__new-card">
         <CardHeader title="Анкета клиента юридического лица" />
         <CardBody>

          {/* ИНН */}
          <CardSetting title="ИНН:">
            <Input
              id="legal-form"
              onChange={handleInnChange}
              placeholder="введите ИНН"
            />
          </CardSetting>
         </CardBody>
         <CardFooter>
            <Button onClick={handleButtonClick}>Сформировать анкету</Button>
         </CardFooter>
          </Card>
      }
        </div>
      </ServiceBody>

    );
  } return <Cap />;
}

export default LegalForm;
