import React from 'react';
import { useSelector } from 'react-redux';
import TData from '../../../../components/Table/TData';
import TRow from '../../../../components/Table/TRow';
import THead from '../../../../components/Table/THead';
import TBody from '../../../../components/Table/TBody';
import Table from '../../../../components/Table/Table';

/**
* @component Система контроля РП - Система Выходного интервью - Таблица логов по сотруднику
*/
function Log() {
  const logs = useSelector((state) => state.probation.output_logs); // лог

  // если логов нет - не показывать таблицу
  if (logs.length === 0) return null;
  return (
    <Table id="dismissal-log">
      <THead title="История">
        <TRow>
          <TData>Время</TData>
          <TData>Исполнитель</TData>
          <TData>Источник</TData>
          <TData>Заголовок</TData>
          <TData>Сообщение</TData>
        </TRow>
      </THead>
      <TBody>
        {logs.map((row) => {
          const {
            id, timestamp, sn, initials, source, title, message,
          } = row;
          return (
            <TRow key={id}>
              <TData>{timestamp}</TData>
              <TData>{`${sn} ${initials}`}</TData>
              <TData>{source}</TData>
              <TData>{title}</TData>
              <td className="upu-table__table-data" dangerouslySetInnerHTML={{ __html: message }} />
            </TRow>
          );
        })}
      </TBody>
    </Table>
  );
}

export default Log;
