// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#directions {
  display: flex;
  flex-direction: column;
}
#directions__info-block {
  max-height: calc(100% - 32px);
}
#directions__table {
  max-width: 1000px;
}
#directions__table thead tr:not(:first-child) {
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
}
#directions__table tbody tr {
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
}
#directions__table .create-direction {
  flex-direction: row;
}
#directions__table .create-direction img {
  margin: 0 4px;
}
#directions__table tfoot td {
  font-weight: initial;
  flex-direction: row;
}
#directions__logs tr {
  grid-template-columns: 0.5fr 1fr 1fr 1fr 4fr;
}`, "",{"version":3,"sources":["webpack://./src/pages/Questionnaire/Directions/directions.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;AACE;EACE,6BAAA;AACJ;AAEE;EACE,iBAAA;AAAJ;AACI;EACE,0CAAA;AACN;AAEI;EACE,0CAAA;AAAN;AAEI;EACE,mBAAA;AAAN;AACM;EACE,aAAA;AACR;AAEI;EACE,oBAAA;EACA,mBAAA;AAAN;AAKI;EACE,4CAAA;AAHN","sourcesContent":["#directions {\n  display: flex;\n  flex-direction: column;\n\n  &__info-block {\n    max-height: calc(100% - 32px);\n  }\n\n  &__table {\n    max-width: 1000px;\n    & thead tr:not(:first-child) {\n      grid-template-columns: 2fr 1fr 1fr 1fr 1fr;\n    }\n\n    & tbody tr {\n      grid-template-columns: 2fr 1fr 1fr 1fr 1fr;\n    }\n    & .create-direction {\n      flex-direction: row;\n      & img {\n        margin: 0 4px;\n      }\n    }\n    & tfoot td {\n      font-weight: initial;\n      flex-direction: row;\n    }\n  }\n\n  &__logs {\n    & tr {\n      grid-template-columns: 0.5fr 1fr 1fr 1fr 4fr;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
