import React from 'react';

/**
* @component Хедер карточки
*/

function CardHeader(props) {
  const { children } = props;
  return <div className="upu-card__header">{children}</div>;
}

export default CardHeader;
