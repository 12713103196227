import React from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../../../components/UI/Button/Button';
import TextArea from '../../../../../components/UI/TextArea';
import { createAction } from '../../../../../utils/redux/store';
import Table from './Table';
import Answers from '../../Answers';
import { commentActivity, leaveComment, toggleFormMobile } from '../../functions';

// Табличное отображение - блок информации
function InfoBlock() {
  const userAnswers = useSelector((state) => state.interview.user_answers); // ответы пользователя
  const interview = useSelector((state) => state.interview.interview);
  const isWiki = useSelector((state) => state.interview.is_wiki);
  const isShowFormMobile = useSelector((state) => state.interview.is_show_form_mobile);
  const currentQuestion = useSelector((state) => state.interview.current_question);
  const isShowQuestion = useSelector((state) => state.interview.is_show_question);
  const isSearching = useSelector((state) => state.interview.is_searching);
  const { questionnaire, answers } = interview;

  const questionsFound = useSelector((state) => state.interview.questions_found);
  const currentComment = useSelector((state) => state.interview.comment); // текущий комментарий

  // Обработка выбора вопроса
  function chooseQuestion(q) {
    createAction('SET_IS_SHOW_QUESTION', true);
    createAction('SET_CURRENT_QUESTION', q); // установить выбранный вопрос
    const currentQuestionComment = userAnswers.find((ua) => ua.question_id === q.id); // найти комментарий к текущему вопросу
    createAction('SET_COMMENT', (currentQuestionComment ? currentQuestionComment.comment : '')); // добавить в textarea комментарий к текущему вопросу
  }

  // Выбрать из результатов поиска
  function chooseSearchResult(q) {
    createAction('SET_CURRENT_QUESTION', q); // установить выбранный вопрос
    createAction('SET_IS_SEARCHING', false); // выйти из поиска
  }

  return (
    <div id="interview-table__info-block" className="upu-service__info-block" onClick={() => createAction('SET_IS_SHOW_FORM_MOBILE', false)}>
        {/* Развернуть меню */}
        <img className="interview__chevron" src="../../icons/chevron.svg" alt="chevron" onClick={(e) => toggleFormMobile(e, isShowFormMobile)} />
        {/* Заголовок анкеты */}
        <h2 id="interview__title" className="upu-service__title">{questionnaire.title}</h2>

        {/* Если нужно показать вопрос и режим поиска ВЫКЛ, то показываем вопрос */}
        {isShowQuestion && !isSearching && (
        <div className="interview-table__wrapper">
            <div className="interview__question-answer">
                <Button id="interview-table__back-to-list" onClick={() => createAction('SET_IS_SHOW_QUESTION', false)}>Назад к списку</Button>

                {/* <div
                  className="interview__question-content"
                  dangerouslySetInnerHTML={{ __html: currentQuestion?.question}}
                /> */}

                {/* Содержание вопроса */}
                <div className="interview__question-content">
                    {currentQuestion?.question.split('\n') // разделить строку по переносам
                      .map((row, index) => ( // показать каждую в параграфе
                      <p key={index} className="interview__question-row">{row}</p>))}
                </div>

                {/* Ответы */}
                <Answers answers={answers} />

                {/* Поле комментария */}
                {!isWiki // если режим справочника выкл
                && currentQuestion // и выбран вопрос
                // включены комментарии или на вопрос можно ответить только комментарием
                && (questionnaire?.comments || currentQuestion.output_id === 4)
                && ( // показать поле комментария
                <div className="interview__comment-block">
                    <TextArea // показывается поле для ввода комментария
                      id={`interview__textarea_${currentQuestion.id}`}
                      placeholder="Комментарий"
                      value={currentComment} // содержание элемента контролируется переменной
                      disabled={commentActivity(userAnswers, currentQuestion)}
                      onChange={(e) => leaveComment(e, userAnswers, currentQuestion, questionnaire)}
                    />
                </div>
                )}
            </div>
        </div>
        )}

        {/* Если нужно показать вопрос и режим поиска ВКЛ, то показываем результаты поиска */}
        {isShowQuestion && isSearching && (
        <div className="interview-table__wrapper">
            <div className="interview__question-answer">
                {questionsFound.length === 0 ? (// если массив с результатами пустой - заголовок "Совпадений не найдено"
                <h2 id="interview__title" className="upu-service__title">Совпадений не найдено</h2>
                ) : ( // иначе результаты
                  questionsFound.map((q) => (
                <div key={q.id} className="interview__search-result" onClick={() => chooseSearchResult(q)}>
                    <p className="interview__search-result-title">{q.title}</p>
                    <p className="interview__question-content">{q.search_title}</p>
                </div>)))}
                <Button onClick={() => createAction('SET_IS_SHOW_QUESTION', false)}>Назад к списку</Button>
            </div>
        </div>
        )}
        {/* иначе показываем таблицу с вопросам */}

        {!isShowQuestion && <Table chooseQuestion={chooseQuestion} />}
    </div>
  );
}

export default InfoBlock;
