import React, { useEffect, useState } from 'react';
import { authorization } from '../../utils/functions/authenticate';
import { setOperInfo } from '../../utils/functions/others';
import Service from '../../components/Service/Service';
import AdminPsEmail from './AdminPsEmail';

function AdminPs() {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const adminPages = [
    { id: 0, title: 'Шаблон уведомления', component: <AdminPsEmail /> },

  ];
  const [pages, setPages] = useState(adminPages);

  useEffect(() => {
    setOperInfo();
    awaitRequests();
  }, []);

  async function awaitRequests() {
    const checkAuthorization = await authorization(); // авторизация
    setIsAuthorized(checkAuthorization);
  }

  if (isAuthorized) {
    return <Service id="admin-portal-state" pages={pages} horizontal />;
  }
}
export default AdminPs;
