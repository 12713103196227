import React, { useEffect, useState } from 'react';
import ServiceBody from '../../../components/Service/ServiceBody';
import CardBody from '../../../components/Card/CardBody';
import CardHeader from '../../../components/Card/CardHeader';
import Card from '../../../components/Card/Card';
import CardSetting from '../../../components/Card/CardSetting';
import Input from '../../../components/UI/Input';
import Select from '../../../components/UI/Select';
import InputSearch from '../../../components/UI/InputSearch';
import CardFooter from '../../../components/Card/CardFooter';
import Button from '../../../components/UI/Button/Button';
import { clientSendData } from '../../../utils/functions/requests';
import { catchHandler } from '../../../utils/error_handling/error_handling';
import { alertFunction } from '../../../utils/functions/alertFunction';
import Service from '../../../components/Service/Service';

function Consignment() {
  const array = [{ id: '0', title: 'нет' }];
  const [show, setShow] = useState(true);
  const [selectList, setSelectList] = useState('');
  const [assetsToAssign, setAssetsToAssign] = useState([]);
  const [isSearching, setIsSearching] = useState(false); // состояние загрузки при поиске
  const [formInfo, setFormInfo] = useState({});
  const [spareAssetsInventory, setSpareAssetsInventory] = useState([]);
  const [additionalInfo, setAdditionalInfo] = useState({ // дополнительная информация
    companies: [], // компании
    users: [], // найденные в поиске пользователи
    assets: [], // оборудование
    spareAssets: [],
    spareAssetsInventory: [],
    dept: '', // подразделение
  });

  useEffect(() => {
    getConsignmentData();
  }, []);

  // Получить данные для заполнения накладной
  async function getConsignmentData() {
    try {
      const reqData = {
        type: 'getConsignmentData',
      };
      const result = await clientSendData('POST', '/get_consignment_data', reqData);
      if (result.success) {
        setAdditionalInfo(result.info); // Записываем доп информацию (компании)
      }
    } catch (error) {
      catchHandler(error, 'getSdInterfaces');
    }
  }

  // Поиск пользователя по компании
  async function searchUserByCompany(event) {
    try {
      const reqData = {
        type: 'searchUserByCompany',
        dn: formInfo.company_dn,
        name: event.target.value,
      };
      setFormInfo((prev) => ({ ...prev, user: null, user_id: null }));
      setIsSearching(true);
      const result = await clientSendData('POST', '/search_user_by_company', reqData);
      if (result?.success) { // если запрос выполнен успешно
        setAdditionalInfo((prev) => ({ ...prev, users: result.users }));
      }
    } catch (error) {
      catchHandler(error, 'searchUserByCompany');
    } finally {
      setIsSearching(false);
    }
  }

  // Поиск подразделения по юзеру
  async function searchDeptByUser(user) {
    try {
      const reqData = {
        type: 'searchDeptByUser',
        userId: user,
      };
      setIsSearching(true);
      const result = await clientSendData('POST', '/search_dept_by_user', reqData);
      if (result?.success) { // если запрос выполнен успешно
        setFormInfo((prev) => ({ ...prev, dept: result.dept }));
      }
    } catch (error) {
      catchHandler(error, 'searchDeptByUser');
    } finally {
      setIsSearching(false);
    }
  }

  // Поиск склада по компании
  async function searchStorageByCompany(company, id) {
    try {
      const reqData = {
        type: 'searchStorageByCompany',
        companyDn: company,
        companyId: id,
      };
      setIsSearching(true);
      const result = await clientSendData('POST', '/search_storage_by_company', reqData);
      if (result?.success) { // если запрос выполнен успешно
        setFormInfo((prev) => ({ ...prev, storage: result.storage }));
      }
    } catch (error) {
      catchHandler(error, 'searchStorageByCompany');
    } finally {
      setIsSearching(false);
    }
  }

  // Поиск склада по компании
  async function getSpareAssets(assetType, assetUser, company) {
    try {
      const reqData = {
        type: 'getSpareAssets',
        assetType,
        assetUser,
        company,
      };
      setIsSearching(true);
      const result = await clientSendData('POST', '/get_spare_assets', reqData);
      if (result?.success) { // если запрос выполнен успешно
        setAdditionalInfo((prev) => ({
          ...prev,
          spareAssets: result.spareAssets,
          spareAssetsInventory: result.spareAssetsInventory,
        }));
        // setSpareAssetsInventory(result.spareAssetsInventory);
      }
    } catch (error) {
      catchHandler(error, 'getSpareAssets');
    } finally {
      setIsSearching(false);
    }
  }

  // Выбор компании
  function chooseCompany(company) {
    // Обновляем название и id компании
    setFormInfo((prev) => ({
      ...prev,
      company: company.title,
      company_id: company.id,
      company_dn: company.dn,
      user_name: null,
      user_id: null,
      dept: '',
      asset_id: '',
      asset_title: '',
      spare_asset_id: null,
      spare_asset_title: null,
      quantity: '1',
    }));
    searchStorageByCompany(company.dn, company.id);
  }

  // Выбор пользователя
  function chooseUser(user) {
    // Обновляем имя и id пользователя
    setFormInfo((prev) => ({ ...prev, user_name: user.title, user_id: user.id }));
    searchDeptByUser(user.id);
  }

  // Выбор оборудования
  function chooseAsset(asset) {
    getSpareAssets(asset.id, formInfo.storage, formInfo.company_id);
    // Обновляем название и id компании
    setFormInfo((prev) => ({
      ...prev,
      asset_type_id: asset.id,
      asset_title: asset.title,
      spare_asset_id: null,
      spare_asset_title: null,
      inventory_id: null,
      inventory_title: null,
      quantity: '1',
    }));
  }

  function chooseSpareAsset(spareAsset) {
    // Обновляем название и id компании
    setFormInfo((prev) => ({ ...prev, spare_asset_id: spareAsset.id, spare_asset_title: spareAsset.title }));
  }

  // Поиск инвентарного номера для выбора из доступных
  async function searchWrapper(e, assetId) {
    setIsSearching(true); // поиск выполняется
    if (e.target.value.length === 0) setSelectList((prev) => ({ ...prev }));
    try {
      const reqData = {
        type: 'searchInventory',
        query: e.target.value,
        assetId,
      };
      const result = await clientSendData('POST', '/search_inventory', reqData);
      if (result?.success) setSpareAssetsInventory(result.data);
      setIsSearching(false);
      return [];
    } catch (error) {
      catchHandler(error, 'searchInventory');
      setIsSearching(false);
      return [];
    }
  }

  function assignAsset(newAsset) {
  //  assetsToAssign.push(newAsset);
    setAssetsToAssign((state) => [...state, newAsset]);
  }

  // Сохранение ведомости
  async function saveConsignment(newAssetsArray, today) {
    try {
      const reqData = {
        type: 'saveConsignment',
        newAssetsArray,
        today,
      };
      setIsSearching(true);
      const result = await clientSendData('POST', '/save_consignment', reqData);
      if (result?.success) { // если запрос выполнен успешно
        const uInt8Array = new Uint8Array(result.file.data);
        const blob = new Blob([uInt8Array], { type: 'application/vnd.ms-excel' });
        const tempLink = document.createElement('a');
        tempLink.href = window.URL.createObjectURL(blob);
        tempLink.setAttribute('download', 'test.xlsx');
        document.body.appendChild(tempLink);
        tempLink.click();

        alertFunction('save_settings', 'clientPost');
        setFormInfo((prev) => ({
          ...prev,
          company: null,
          company_id: null,
          company_dn: null,
          user_name: null,
          user_id: null,
          dept: '',
          asset_id: '',
          asset_title: '',
          quantity: '1',
          asset_type_id: null,
          spare_asset_id: null,
          spare_asset_title: null,
          inventory_id: null,
          inventory_title: null,
        }));
      }
      setAssetsToAssign([]);
    } catch (error) {
      catchHandler(error, 'getSpareAssets');
    } finally {
      setIsSearching(false);
    }
  }

  // Удалить запись о планируемом к выдаче оборудовании из спика
  function deleteAsset(asset) {
    setAssetsToAssign(assetsToAssign.filter((el) => el.spare_asset_id !== asset));
  }

  const currentDate = new Date();
  const today = currentDate.getDate() <= 9
    ? `${String(currentDate.getDate()).padStart(2, '0')}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${currentDate.getFullYear()}`
    : `${currentDate.getDate()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${currentDate.getFullYear()}`;

  return (
    <ServiceBody>
      {show && (
        <div className="consignment">
          <Card setShow={setShow}>
            <CardHeader title="Накладная" />
            <CardBody>
              {/* ДАТА */}
              <CardSetting title="Дата:">
                <p style={{ fontWeight: 'bold' }}>{today}</p>
              </CardSetting>

              {/* КОМПАНИЯ */}
              <CardSetting title="Компания:" invalid={!formInfo.company_id}>
                <Select
                  id="company"
                  array={additionalInfo.companies}
                  onChoose={chooseCompany}
                  defaultValue={formInfo.company || 'Выберите компанию'}
                  loading={isSearching}
                />
              </CardSetting>

              {/* СОТРУДНИК */}
              <CardSetting title="Сотрудник:" invalid={!formInfo.user_id}>
                <InputSearch
                  id="user-search"
                  array={additionalInfo.users}
                  onChoose={chooseUser}
                  onSearch={searchUserByCompany}
                  defaultValue={formInfo?.user_name}
                  placeholder="Выберите пользователя"
                  loading={isSearching}
                  disabled={!formInfo.company_dn}
                />
              </CardSetting>

              {/* ПОДРАЗДЕЛЕНИЕ */}
              <CardSetting title="Подразделение:">
                {formInfo.dept && formInfo.dept.length <= 1 ? (
                  <Select
                    id="dept"
                    array={additionalInfo.mvz_numbers}
                    onChoose={(e) => setFormInfo((prev) => ({ ...prev, dept: e.title }))}
                    defaultValue={formInfo?.dept}
                    search
                  />
                ) : (
                  <Input disabled defaultValue={formInfo?.dept} />
                )}
              </CardSetting>

              {/* ВСЕ ТИПЫ ОБОРУДОВАНИЯ */}
              <CardSetting title="Оборудование (наименование мат. ценности):">
                <Select
                  id="assets"
                  array={additionalInfo.assets}
                  onChoose={chooseAsset}
                  defaultValue={formInfo.asset_title || 'Выберите оборудование'}
                />
              </CardSetting>

              {/* ДОСТПУНОЕ ОБОРУДОВАНИЕ */}
              {formInfo.asset_type_id && (
                <CardSetting title="Доступные:" invalid={!formInfo.asset_title}>
                  <Select
                    id="spare_assets"
                    array={additionalInfo.spareAssets}
                    onChoose={chooseSpareAsset}
                    defaultValue={
                      formInfo.spare_asset_title || 'Выберите из доступных'
                    }
                  />
                </CardSetting>
              )}

              {/* ИНВЕНТАРНЫЙ № */}
              {[1, 2, 3, 12].includes(formInfo.asset_type_id)
                && additionalInfo.spareAssetsInventory && (
                  <CardSetting title="Инвентарный №:">
                    <Select
                      array={additionalInfo.spareAssetsInventory}
                      // onSearch={(e) => searchWrapper(e, formInfo.spare_asset_id)}
                      onChoose={(a) => setFormInfo({
                        ...formInfo,
                        inventory_id: a.id,
                        inventory_title: a.title,
                        spare_asset_title: a.asset_name,
                        spare_asset_id: a.id,
                      })
                      }
                      defaultValue={
                        formInfo.inventory_title || 'Введите инв. номер'
                      }
                      search
                    />
                  </CardSetting>
              )}

              {/* КОЛИЧЕСТВО ШТ */}
              {![1, 2, 3, 12].includes(formInfo.asset_type_id)
                && formInfo.spare_asset_id && (
                  <CardSetting title="Количество (шт):">
                    <Input
                      type="number"
                      placeholder="Кол-во штук"
                      onChange={(e) => setFormInfo({ ...formInfo, quantity: e.target.value })
                      }
                    />
                  </CardSetting>
              )}

              {formInfo.asset_type_id && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: '5px',
                  }}
                >
                  <Button onClick={() => assignAsset(formInfo)}>
                    Добавить
                  </Button>
                </div>
              )}

              <div className="tags__list">
                {assetsToAssign?.map((a) => (
                  <p className="tags__tag" key={a.spare_asset_id}>
                    <span
                      onClick={() => deleteAsset(a.spare_asset_id)}
                      className="upu__close"
                    >
                      X
                    </span>

                    {a.asset_title}
                    {', '}
                    {a.spare_asset_title}
                    {' ( '}
                    {`${a.quantity} шт.`}
                    {' )'}
                  </p>
                ))}
              </div>
            </CardBody>
            <CardFooter>
              <Button onClick={() => saveConsignment(assetsToAssign, today)}>
                Сохранить
              </Button>
            </CardFooter>
          </Card>
        </div>
      )}
    </ServiceBody>
  );
}

export default Consignment;
