import React from 'react';
import TData from '../../../components/Table/TData';
import TextArea from '../../../components/UI/TextArea';

/**
 * @component Пользователи и права - Таблица сессий - редактируемая ячейка таблицы
 * @prop {editingSession} string - id редактируемой сессии
 * @prop {id} string - id сессии, к которой относится ячейка
 * @prop {data} string - значение редактируемое в инпуте
 * @prop {field} string - наименование поля в объекте сессии
 * @prop {changeSetting} function - изменение состояния объекта редактируемой сессии
 * @prop {ext_data} string - значение отображаемое в ячейке
 * @prop {mobile_hide} boolean - скрытие ячейки при мобильном отображении
 *
*/
function SessionTableData(props) {
  const {
    editingSession, id, data, field, changeSetting, ext_data, mobile_hide,
  } = props;
  return (
    <TData mobileHide={mobile_hide}>
      {editingSession === id ? (
      <TextArea
        defaultValue={data}
        onChange={(e) => changeSetting({ [field]: typeof (data) === 'number' ? +e.target.value : e.target.value })}
      />) : (
        ext_data || data)}
    </TData>
  );
}

export default SessionTableData;
