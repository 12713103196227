// Заголовки первой части таблицы оценки (общая информация)
export const firstHeaders = [
  { id: 1, title: 'Проект', field: 'project_title' },
  { id: 2, title: 'Клиент', field: 'client_title' },
  { id: 3, title: 'Объем проекта (час.)', field: '' },
  { id: 4, title: 'Участие сотрудника в проекте (час.)', field: 'workload' },
];

// Заголовки второй части таблицы оценки (сама оценка)
export const secondHeaders = [
  { id: 1, title: 'Задачи на проекте', field: '' },
  { id: 2, title: 'Оценка достижения результатов (1-10)', field: '' },
  { id: 3, title: 'Оценка достижения результатов (А,В,С,D)', field: '' },
  { id: 4, title: 'Комментарии (обоснование оценки)', field: '' },
  { id: 5, title: 'Для руководителя (комментарии)', field: '' },
];

export const mainInfoHeaders = [
  { id: 1, title: 'Проект', field: 'project_title' },
  { id: 2, title: 'Клиент', field: 'client_title' },
  { id: 3, title: 'Объем проекта (час.)', field: '' },
  { id: 4, title: 'Участие сотрудника в проекте (час.)', field: 'workload' },
  { id: 5, title: 'Роль в проекте', field: '-' },
  { id: 6, title: 'Руководитель', field: '-' },
];
// Значение числовой оценки если задача не применима
export const integerNoValue = 'Не применимо';
// Варианты числовых оценок
export const integerOptions = [
  { id: 11, title: integerNoValue },
  { id: 10, title: 10 },
  { id: 9, title: 9 },
  { id: 8, title: 8 },
  { id: 7, title: 7 },
  { id: 6, title: 6 },
  { id: 5, title: 5 },
  { id: 4, title: 4 },
  { id: 3, title: 3 },
  { id: 2, title: 2 },
  { id: 1, title: 1 },
];

// Значение буквенной оценки если задача не применима
export const characterNoValue = '-';
// Варианты буквенных оценок
export const characterOptions = [
  { id: 5, title: characterNoValue },
  { id: 1, title: 'A' },
  { id: 2, title: 'B' },
  { id: 3, title: 'C' },
  { id: 4, title: 'D' },
];

// Цвета для подсветки ячеек на основании оценки

export const colourByValue = {
  0: '',
  1: '#FF493F',
  2: '#FF7742',
  3: '#FFA445',
  4: '#FFD248',
  5: '#FFFF4B',
  6: '#FFFF4B',
  7: '#D6FF4B',
  8: '#ACFF4B',
  9: '#83FF4A',
  10: '#59FF4A',
};

export const incidentCharacterOptions = [
  { id: 0, title: 'Нарушения не выявлены' },
  { id: 1, title: 'Нарушения выявлены' },
];

export const incidentIntegerOptions = [
  { id: 0, title: '0' },
  { id: 1, title: -0.5 },
];

export const finishedTasksIntegerOptions = [
  { id: 0, title: '0' },
  { id: 1, title: '0' },
  { id: 2, title: -1 },
  { id: 3, title: -2 },
];

export const finishedTasksCharacterOptions = [
  { id: 0, title: 'A' },
  { id: 1, title: 'B' },
  { id: 2, title: 'C' },
  { id: 3, title: 'D' },
];
