import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { createAction } from '../../../utils/redux/store';
import { catchHandler } from '../../../utils/error_handling/error_handling';
import { alertFunction } from '../../../utils/functions/alertFunction';
import { clientSendData } from '../../../utils/functions/requests';
import { setSearchState, sortFieldsByScheme } from './functions';
import { log } from '../../../utils/functions/others';
import Loader from '../../../components/UI/Loader';
import THead from '../../../components/Table/THead';
import Table from '../../../components/Table/Table';
import TBody from '../../../components/Table/TBody';
import TFoot from '../../../components/Table/TFoot';
import TData from '../../../components/Table/TData';
import TRow from '../../../components/Table/TRow';
import Pagination from '../../../components/Table/Pagination';
import SearchForm from './SearchForm/SearchForm';

/**
* @component База знаний - Вопросы - Таблица вопросов
* @prop {range} array - диапазон id вопросов
* @prop {chooseQuestion} function - выбор вопроса
* @prop {openQuestion} function - открыть вопрос
* @prop {refresh} string - Перемычка для обновления таблицы вопросов после изменения
- Если в props содержится range (диапазон id вопросов) значит таблица отображается в пользовательском интерфейсе
*/
function QuestionsTable(props) {
  const {
    range,
    chooseQuestion,
    openQuestion,
    refresh,
  } = props;
  const [isLoading, setIsLoading] = useState(false); // Состояние загрузки
  const [isUpdating, setIsUpdating] = useState(false); // Состояние обновления
  const [currentQuestionId, setCurrentQuestionId] = useState(null); // выбранный вопрос
  const [questionsId, setQuestionsId] = useState([]); // массив id вопросов в выбранном подразделении
  const [questions, setQuestions] = useState([]); // массив данных вопросов в выбранном подразделении
  const [showSearch, setShowSearch] = useState(false); // состояние отображения форсы поиска
  const currentDivision = useSelector((state) => state.kb.current_division); // текущее подразделение
  const questionScheme = useSelector((state) => state.kb.active_scheme.question); // схема вопроса
  const { scheme, id } = questionScheme; // активная схема и её id
  const limit = currentDivision.limit_view; // лимит отображения строк в таблице
  // Определение местонахождения компонента (range передается только из пользовательского интерфейса)
  const isUserInterface = !!range;
  // Массив полей которые необходимо показывать в таблице
  let fields = Object.keys(scheme).filter((item) => scheme[item]?.show_in_table);
  // Если таблица вызывается из пользовательского интерфейса - убрать user_hide поля
  fields = isUserInterface ? fields.filter((item) => !scheme[item]?.user_hide) : fields;
  // Сортировка полей в зависимости от параметра show_in_table.order
  fields = sortFieldsByScheme({
    scheme,
    key: 'show_in_table',
    sub_key: 'order',
    fields,
  });

  // Создание заголовков - пройти по ключам схемы - вернуть заголовок поля и ключ
  const headers = fields.map((field) => ({ title: scheme[field].name, field }));

  useEffect(() => {
    awaitRequests();
    // Перезапросить данные при изменении подразделения или диапазона id (при смене темы)
  }, [
    // Обновить данные, если изменилось состояние:
    questionScheme, // - схемы вопроса
    range, // - диапазона id
    refresh, // - внешнего параметра (см. KnowledgeBase/Manager/Questions/Questions)
  ]);

  async function awaitRequests() {
    setIsLoading(true); // состояние - загружается
    setQuestionsId(range); // обновить общий список id входящий в компонент массивом (для пагинации)
    await getQuestionsKb(range);
    setSearchState(); // очистить состояние поиска при рендере таблицы
    setIsLoading(false); // загрузка завершена
  }

  // Получить вопросы
  // Если передан диапазон id функция запрашивает вопросы из этого диапазона
  // Если диапазон пустой - вызвать функцию заново без диапазона
  // Если он отсутствует - запросить id всех вопросов
  // Если количество id превышает лимит - запросить вопросы для первой страницы
  // Иначе запросить все вопросы
  async function getQuestionsKb(range) {
    try {
      // ключ схемы вопроса, содержащий в себе id тем
      const themeKey = Object.keys(scheme).find((key) => scheme[key].link_kb_themes);
      const reqData = {
        type: 'getQuestionsKB',
        division: currentDivision,
        location: window.location.pathname,
        is_user_interface: isUserInterface,
        theme_key: themeKey,
        scheme,

      };
      setIsUpdating(true);
      if (range) { // если указан диапазон id запрашиваемых вопросов
        if (range.length > 0) { // если диапазон не пустой
          let checkedRange = range; // сохранить range в изменяемую переменную
          // Если range превышает лимит - обрезаем с начала до лимита (для 1й страницы)
          if (limit && range.length > limit) checkedRange = checkedRange.slice(0, limit);
          const addReqData = { // он добавляется к запросу
            ...reqData,
            range: checkedRange,
            fields,
          };
          const result = await clientSendData('POST', '/get_questions_kb', addReqData);
          if (result) setQuestions(result); // Обновить массив вопросов
        } else getQuestionsKb(); // если диапазон пустой - запросить количество вопросов
      } else {
        const result = await clientSendData('POST', '/get_questions_kb', reqData);
        if (result.length > 0) { // если найдены какие то вопросы
          // оставить только id в массиве
          let arrayOfId = result.map((item) => item.id);
          // обновляем состояние локального массива id (для пересчёта пагинации)
          setQuestionsId(arrayOfId);
          // если кол-во id вопросов превышает лимит, обрезаем с начала до лимита (для 1й страницы)
          if (limit && result.length > limit) arrayOfId = arrayOfId.slice(0, limit);
          // и запрашиваем вопросы по полученному массиву id
          await getQuestionsKb(arrayOfId);

          // Иначе вопросы не найдены - сбрасываем состояние
        } else resetQuestions();
      }
      setIsUpdating(false);
    } catch (error) {
      catchHandler(error, 'getQuestionsKB');
    }
  }

  // Сбросить вопросы
  function resetQuestions() {
    setQuestionsId([]); // сбросить локальный массив id (для выключения пагинации)
    setQuestions([]); // сбросить массив вопросов
  }

  // Открыть карточку вопроса
  function openHandler(e, id) {
    e.stopPropagation();
    setCurrentQuestionId(id); // id выбранного вопроса
    openQuestion(id); // открыть вопрос
  }

  // Выбрать карточку вопроса
  function chooseHandler(id) {
    setCurrentQuestionId(id); // id выбранного вопроса
    log({ message: `Пользователь выбрал вопрос id: ${id}`, eventtype: 'info' });
    if (chooseQuestion) chooseQuestion(id); // выполнить функцию выбора вопроса если она есть
  }

  // Функция выбора страницы
  async function choosePage(number) {
    const fromIndex = number * limit - limit; // номер страницы * лимит отображения -  лимит отображения
    const toIndex = number * limit; // номер страницы * лимит отображения
    const range = questionsId.slice(fromIndex, toIndex); // диапазон id вопросов
    log({ message: `Пользователь в таблице вопросов перешел на страницу ${number}`, eventtype: 'info' });
    await getQuestionsKb(range); // запросить вопросы
  }

  // Сбросить данные таблицы
  function resetTableToInitial() {
    setSearchState(); // очистить состояние поиска
    getQuestionsKb(range); // сбросить вопросы
    createAction('SET_FOUNDED_KB_QUESTIONS', null); // Сбросить foundedQuestions в хранилище
  }

  // Обработчик поиска по всем полям
  async function searchQuestions(searchData, type) {
    try {
      const reqData = {
        type: 'kbSearch',
        searchObj: {
          type,
          scheme_id: id,
          searchArray: searchData, // массив данных для поиска [{field: "field", value: "value"}, ...]
          hardSearch: false,
          table: 'kb_questions',
          column: 'essence',
          division_id: currentDivision.id,
          is_user_interface: isUserInterface,
        },
      };

      const result = await clientSendData('POST', '/kb_search', reqData);
      if (result === 'bad_request') alertFunction('bad_request', 'clientPost');
      else if (result.length > 0) { // если найдены какие то ответы
        // копия массива для последующего изменения
        let arrayOfId = result.map((item) => item);
        // отсортировать массив по возрастанию
        arrayOfId.sort((a, b) => a - b);
        // оставить только id которые есть с исходном представлении
        // (range передается только из пользовательского интерфейса когда выбрана тема)
        if (isUserInterface && range.length > 0) arrayOfId = result.filter((item) => range.includes(item));
        // обновить состояние локального массива id (для пересчёта пагинации)
        setQuestionsId(arrayOfId);
        // если кол-во id вопросов превышает лимит, обрезаем с начала до лимита (для 1й страницы)
        // if (arrayOfId.length > limit) arrayOfId = arrayOfId.slice(0, limit) // ***
        // и запрашиваем вопросы по полученному массиву id
        // getQuestionsKb(arrayOfId)  ***

        // Фича----------------------------------------------
        // записать в хранилище массив id для перестроения иерархии
        createAction('SET_FOUNDED_KB_QUESTIONS', arrayOfId);

        // Если поиск из пользовательского интерфейса - записать в LS текущие параметры
        if (isUserInterface) setSearchState(type, searchData);

        // Иначе вопросы не найдены - сбрасываем состояние
      } else setQuestions([]); // сбросить массив вопросов // ***
      // resetQuestions() // ***
    } catch (error) {
      catchHandler(error, 'searchQuestions');
    }
  }

  // Обработчик сортировки полей
  async function sortQuestions(field, direction) {
    try {
      const reqData = {
        type: 'kbSort',
        range: questionsId,
        table: 'kb_questions',
        scheme,
        field,
        direction,
      };
      const result = await clientSendData('POST', '/kb_sort', reqData);
      if (result) {
        setQuestionsId(result); // ***
        // await getQuestionsKb(result)  ***
      }
    } catch (error) {
      catchHandler(error, 'sortQuestions');
    }
  }

  if (isLoading) return <Loader />;
  return (
    <Table id="knowledge-base-questions__table" short_last>
      {/* Хедер таблицы */}
      <THead
        title={`Вопросы (${questionsId.length})`}
        headers={headers}
        array={questions}
        setArray={setQuestions}
        setDefault={resetTableToInitial}
        button_place="row"
        ext_button="Общий поиск"
        ext_handler={() => setShowSearch(true)}
        ext_sorting={sortQuestions}
        search_by_all={(data) => searchQuestions(data, 'fast')}
        search_by_one={(data) => searchQuestions(data, 'string')}
      />

      {/* Тело таблицы */}
      <TBody>
        {questions.map((q) => (// пройти по вопросам для вывода строк
          <TRow key={q.id} onClick={() => chooseHandler(q.id)} active={currentQuestionId === q.id}>
            {fields.map((field) => { // пройти по полям для вывода ячеек строки
              let value = q[field]; // значение поля в объекте вопроса
              if (scheme[field]?.switch) value = q[`switch_${field}`];
              // Показать ячейку с подготовленными данными
              return <TData loading={isUpdating} key={field}>{value}</TData>;
            })}
            <TData onClick={(e) => openHandler(e, q.id)} loading={isUpdating}>
              {isUserInterface ? (
              <Link to={`?question=${q.id}`}><img src="../../icons/file-text.svg" alt="file" /></Link>) : (
              <img src="../../icons/file-text.svg" alt="file" />)}
            </TData>
          </TRow>
        ))}
        {showSearch && (
        <TRow>
          <TData>
            <SearchForm // Форма поиска (покажется, если showSearch = true)
              setShow={setShowSearch}
              getQuestionsKb={getQuestionsKb}
              setQuestions={setQuestions}
              isUserInterface={isUserInterface}
            />
          </TData>
        </TRow>)}
      </TBody>

      {/* Футер таблицы */}
      <TFoot>
        {questions.length === 0 && (
        <TRow>
          <TData loading={isUpdating || isLoading}>Данные отсутствуют</TData>
        </TRow>)}

        {/* Кнопка только для интерфейса администратора */}
        {!isUserInterface && (
        <TRow>
          <TData onClick={() => openQuestion('new')} loading={isUpdating}>Создать вопрос</TData>
        </TRow>)}

        {questionsId.length > 0 && ( // условие добавлено 12.07.23 для исправления бага цикличных запросов на get_questions при отсутствии вопросов
        <Pagination
          array={questionsId}
          limit={limit}
          handler={choosePage}
        />)}
      </TFoot>
    </Table>
  );
}

export default QuestionsTable;
