import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { backlightHandler } from '../../../utils/functions/others';
import { clientSendData } from '../../../utils/functions/requests';
import { createAction } from '../../../utils/redux/store';
import { catchHandler } from '../../../utils/error_handling/error_handling';
import { alertFunction } from '../../../utils/functions/alertFunction';
import InputSearch from '../../../components/UI/InputSearch';
import Button from '../../../components/UI/Button/Button';
import Close from '../../../components/UI/Close';

/**
* @component Система контроля РП - Система контроля испытательных сроков - Карточка сотрудника - форма добавления сотрудника
* @prop {setShowAddUserCard} function - Обновление состояния отображения карточки
* @prop {getProbationUsers} function - Обновление данных таблицы
*/
function AddEmployee(props) {
  const { setShowAddUserCard, getProbationUsers } = props;

  const [newUser, setNewUser] = useState(); // состояние подсветки
  const [backlight, setBacklight] = useState(); // состояние подсветки
  const mentors = useSelector((state) => state.probation.mentors); // массив наставников

  // Поиск пользователя и выбор пользователя
  async function searchUser(e) {
    try {
      // При поиске сотрудника, его id сбрасывается и устанавливается только при выборе
      // это необходимо для валидации
      if (e.target.value.length <= 1 || !e.target.value) {
        // При открытии формы e.target.value отсутствует, но имя пользователя отображается
        // поэтому поле подсвечивается зелёным
        if (e.target.value) setBacklight('green');
        // createAction("SET_MENTORS", []) // сброс массива
        backlightHandler([], setBacklight); // Обновление подсветки поля выбора пользователя
      } else {
        const reqData = {
          type: 'searchMentor',
          query: e.target.value,
        };
        const result = await clientSendData('POST', '/search_mentor', reqData); // Запрос в БД
        if (result === 'bad_request') alertFunction('bad_request', 'clientPost'); // если некорректный запрос
        else {
          backlightHandler(result, setBacklight); // Обновление подсветки
          createAction('SET_MENTORS', result); // Запись пользователей в хранилище
        }
      }
    } catch (error) {
      catchHandler(error, 'searchUser');
    }
  }

  // Обработка выбора пользователя
  function chooseUser(user) {
    setBacklight('green'); // Установка цвета поля
    setNewUser(user);
  }

  async function addUser() {
    if (newUser) {
      const reqData = {
        type: 'addNewUser',
        user: newUser.id,
      };
      const result = await clientSendData('POST', '/add_new_user', reqData); // Запрос в БД
      if (result === 'bad_request') alertFunction('bad_request', 'clientPost'); // если некорректный запрос
      else {
        backlightHandler([null], setBacklight); // Обновление подсветки
        if (result) {
          alertFunction('user_probation_add', 'clientPost');
          setShowAddUserCard(false);
          getProbationUsers();
        } else alertFunction('user_probation_exist', 'clientPost');
      }
    } else {
      alertFunction('enter_name', 'clientPost');
    }
  }

  return (
    <div className="employee__background">
      <div className="employee-add">
        {/* Закрыть карточку */}
        <Close onClick={() => setShowAddUserCard(false)} />
        <h2 className="employee__title">Добавление сотрудника</h2>

        <div className="employee-add__form-wrapper">
          <InputSearch
            id="employee-card-form__mentors"
            array={mentors}
            onChoose={(user) => chooseUser(user)}
            onSearch={searchUser}
            backlight={backlight}
            placeholder="Введите имя сотрудника"
          />
          <Button type="submit" onClick={() => addUser()}>Добавить</Button>
        </div>
      </div>
    </div>
  );
}

export default AddEmployee;
