import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';
import { catchHandler } from '../../../utils/error_handling/error_handling';
import { clientSendData } from '../../../utils/functions/requests';
import Input from '../../../components/UI/Input';
import Loader from '../../../components/UI/Loader';
import Toggle from '../../../components/UI/Toggle';
import CheckMark from '../../../components/UI/CheckMark';
import Cancel from '../../../components/UI/Cancel';
import SchemeCard from './SchemeCard/SchemeCard';
import THead from '../../../components/Table/THead';
import TBody from '../../../components/Table/TBody';
import TFoot from '../../../components/Table/TFoot';
import Table from '../../../components/Table/Table';
import TRow from '../../../components/Table/TRow';
import TData from '../../../components/Table/TData';

/**
 * @component Администрирование базы знаний - редактирование подразделений - карточка подразделения - вкладка Схемы
 * @prop {openedDivision} object - объект текущего направления открытого в карточке
*/
function Schemes(props) {
  const { openedDivision } = props;

  const schemeTypes = useSelector((state) => state.kb.scheme_types); // типы схем
  const [schemes, setSchemes] = useState([]); // все схемы подразделения
  const [showCard, setShowCard] = useState(false); // состояние отображения карточки схемы
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [isCreating, setIsCreating] = useState(false); // состояние создания схемы
  const [schemeName, setSchemeName] = useState(null); // заголовок новой схемы
  const [choosedScheme, setChoosedScheme] = useState(null); // Выбранная строка таблицы
  const divisionsTableNode = document.getElementById('divisions'); // DOM элемент с таблицей подразделений

  useEffect(() => {
    getSchemes(); // Получить информацию о схемах
  }, []); // перезапросить схемы при закрытии карточки

  // Получить информацию о направлениях
  async function getSchemes() {
    try {
      const reqData = {
        type: 'getSchemes',
        division_id: openedDivision.id,
      };

      setIsLoading(true);
      const result = await clientSendData('POST', '/get_schemes', reqData); // запрос в БД
      if (result) setSchemes(result); // запись в состояние
    } catch (error) {
      catchHandler(error, 'getSchemes'); // обработчик ошибок
    } finally {
      setIsLoading(false);
    }
  }

  // (Де)активировать схему
  async function toggleScheme(e, id) {
    e.stopPropagation();
    try {
      const toggledScheme = schemes.find((item) => item.id === id);
      const reqData = {
        type: 'editScheme',
        action: 'activate',
        scheme: toggledScheme,
      };

      setIsLoading(true);
      const result = await clientSendData('POST', '/edit_scheme', reqData); // запрос в БД
      if (result === 'success') await getSchemes();
    } catch (error) {
      catchHandler(error, 'createScheme'); // обработчик ошибок
    } finally {
      setIsLoading(false);
    }
  }

  // Создать схему
  async function createScheme() {
    try {
      const reqData = {
        type: 'editScheme',
        action: 'create',
        scheme: {
          division_id: openedDivision.id,
          title: schemeName,
          type: 4, // тип по умолчанию - Тема
          scheme: {
            key: {
              name: 'Заголовок',
              type: 'string',
              order: null,
              switch: null,
              required: false,
              searchable: false,
              show_in_table: false,
            },
          },
        },
      };

      setIsLoading(true);
      const result = await clientSendData('POST', '/edit_scheme', reqData); // запрос в БД
      if (result === 'success') {
        await getSchemes();
        switchOffCreating();
      }
    } catch (error) {
      catchHandler(error, 'createScheme'); // обработчик ошибок
    } finally {
      setIsLoading(false);
    }
  }

  // Выключить создание
  function switchOffCreating() {
    setIsCreating(false);
  }

  // Открыть схему
  function openScheme(id) {
    setChoosedScheme(id);
    setShowCard(true);
  }

  if (isLoading) return <Loader />; // если загружается - показывается лоадер
  return (
    <div className="upu-card__block">
      <Table id="divisions__schemes" short_last>
        {/* Хедер таблицы */}
        <THead>
          <TRow>
            <TData>Название</TData>
            <TData>Версия</TData>
            <TData>Тип</TData>
            <TData>Статус</TData>
            <TData />
          </TRow>
        </THead>
        {/* Тело таблицы */}
        <TBody>
          {schemes.map((scheme) => {
            const {
              id, title, version, active, type,
            } = scheme;
            const typeTitle = schemeTypes.find((item) => item.id === type)?.title;
            return (
              <TRow key={id} onClick={() => setChoosedScheme(id)} active={choosedScheme === id}>
                <TData>{title}</TData>
                <TData>{version}</TData>
                <TData>{typeTitle}</TData>
                <TData>
                  <Toggle state={String(active)} onClick={(e) => toggleScheme(e, id)} />
                </TData>
                <TData onClick={() => openScheme(id)}>
                  <img src="../../icons/file-text.svg" alt="file" />
                </TData>
              </TRow>
            );
          })}
        </TBody>
        {/* Футер таблицы */}
        <TFoot>
          {schemes.length === 0 && (
          <TRow>
            <TData>В выбранном подразделении отсутствуют схемы</TData>
          </TRow>)}
          {isCreating ? (// если создается новая схема показывается форма
          <TRow>
            <TData>
              <Input placeholder="Введите имя" onChange={(e) => setSchemeName(e.target.value)} />
              <CheckMark onClick={createScheme} />
              <Cancel onClick={switchOffCreating} />
            </TData>
          </TRow>) : ( // иначе кнопка "Добавить новую"
          <TRow>
            <TData onClick={() => setIsCreating(true)}>Создать схему</TData>
          </TRow>)}
        </TFoot>
      </Table>

      {/* Отображение карточки через компонент Divisions */}
      {showCard && (
        createPortal(
          <SchemeCard
            id={choosedScheme}
            setShowCard={setShowCard}
            getSchemes={getSchemes}
          />,
          divisionsTableNode,
        )
      )}
    </div>
  );
}

export default Schemes;
