// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#categories {
  display: flex;
  flex-direction: column;
}
#categories__info-block {
  max-height: calc(100% - 32px);
}
#categories__table {
  max-width: 1000px;
}
#categories__table thead tr:not(:first-child) {
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
}
#categories__table tbody tr {
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
}
#categories__logs tr {
  grid-template-columns: 0.5fr 1fr 1fr 1fr 4fr;
}
#categories__two-choice {
  margin: 0 4px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Questionnaire/Categories/categories.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;AACE;EACE,6BAAA;AACJ;AAEE;EACE,iBAAA;AAAJ;AACI;EACE,0CAAA;AACN;AAEI;EACE,0CAAA;AAAN;AAKI;EACE,4CAAA;AAHN;AAME;EACE,aAAA;AAJJ","sourcesContent":["#categories {\n  display: flex;\n  flex-direction: column;\n\n  &__info-block {\n    max-height: calc(100% - 32px);\n  }\n\n  &__table {\n    max-width: 1000px;\n    & thead tr:not(:first-child) {\n      grid-template-columns: 2fr 1fr 1fr 1fr 1fr;\n    }\n\n    & tbody tr {\n      grid-template-columns: 2fr 1fr 1fr 1fr 1fr;\n    }\n  }\n\n  &__logs {\n    & tr {\n      grid-template-columns: 0.5fr 1fr 1fr 1fr 4fr;\n    }\n  }\n  &__two-choice {\n    margin: 0 4px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
