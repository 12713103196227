import React from 'react';
import { alertFunction } from '../../../utils/functions/alertFunction';
import Card from '../../../components/Card/Card';
import CardHeader from '../../../components/Card/CardHeader';
import CardBody from '../../../components/Card/CardBody';
import CardFooter from '../../../components/Card/CardFooter';
import CardSetting from '../../../components/Card/CardSetting';
import IconButton from '../../../components/UI/Button/IconButton';
import Input from '../../../components/UI/Input';
import Button from '../../../components/UI/Button/Button';

/**
* @component Состояние портала - интерфейс администратора - Сервисы - карточка сервиса
*/
function ServiceCard(props) {
  const {
    service, setService, handler, setShow,
  } = props;
  const isNewService = service.id === 'new';

  // Сохранить объект сервиса
  async function saveControl() {
    if (service?.title) { // Если указан заголовок
      const action = isNewService ? 'create' : 'edit'; // Определяем действие на основании id сервиса (создание/изменение)
      await handler(action); // Дожидаемся обработки запроса в БД
      setShow(false); // Закрываем карточку
    } else await alertFunction('required_fields', 'clientPost'); // Если не указан заголовок - уведомление
  }

  // Удалить сервис
  async function deleteControl() {
    const confirm = window.confirm('Удалить?'); // Запросить подтверждение
    if (confirm) { // Если подтверждено - удалить из БД
      await handler('delete'); // Дожидаемся обработки запроса в БД
      setShow(false); // Закрываем карточку
    }
  }

  return (
    <Card id="portal-state__service-card" setShow={setShow}>
      <CardHeader />
      <CardBody>
        {/* Наименование сервиса */}
        <CardSetting title="Наименование" invalid={!service?.title}>
          <Input
            placeholder="Наименование"
            value={service?.title || ''}
            onChange={(e) => setService((prev) => ({ ...prev, title: e.target.value }))}
          />
        </CardSetting>
        {/* Описание сервиса */}
        <CardSetting title="Описание">
          <Input
            placeholder="Описание"
            value={service?.description || ''}
            onChange={(e) => setService((prev) => ({ ...prev, description: e.target.value }))}
          />
        </CardSetting>
        {/* Удаление сервиса */}
        {!isNewService && (
        <CardSetting title="Удаление">
          <IconButton onClick={deleteControl} icon="delete" theme="red" />
        </CardSetting>)}
      </CardBody>
      <CardFooter>
        <Button onClick={saveControl}>Сохранить</Button>
        <Button onClick={() => setShow(false)}>Отменить</Button>
      </CardFooter>
    </Card>
  );
}

export default ServiceCard;
