import React from 'react';
import InfoBlock from './InfoBlock/InfoBlock';

// Одностраничное отображение
function SequencePageOutput() {
  document.cookie = 'current_in_sequence=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  return (
    <div id="interview-sequence-page" className="upu-service">
        <InfoBlock />
    </div>
  );
}

export default SequencePageOutput;
