import React, { useState } from 'react';
import { log } from '../../../../utils/functions/others';
import ServiceBody from '../../../../components/Service/ServiceBody';
import QuestionsTable from '../../Common/QuestionsTable';
import QuestionCard from './QuestionCard/QuestionCard';

/**
* @component Управление Базой знаний - Вопросы
*/
function Questions() {
  const [showCard, setShowCard] = useState(false); // состояние отображения карточки вопроса
  const [currentQuestionId, setCurrentQuestionId] = useState(null); // выбранный вопрос
  /**
   *  Перемычка для обновления таблицы вопросов после изменения
   *  Передется в компоненты QuestionsTable и QuestionCard
   *  Пример - в QuestionCard нажали сохранить/удалить/создать и обновили состояние refreshQuestions
   *  Обновление refreshQuestions вызовет useEffect в QuestionsTable и таблица обновится */
  const [refreshQuestions, setRefreshQuestions] = useState(null);

  // Открыть карточку вопроса
  function openQuestion(id) {
    setCurrentQuestionId(id); // id выбранного вопроса
    setShowCard(true); // показать карточку
    log({ message: `Пользователь открыл карточку ${id === 'new' ? 'создания нового вопроса' : `вопроса id: ${id}`}` });
  }

  return (
    <ServiceBody id="knowledge-base__questions">
      <QuestionsTable
        openQuestion={openQuestion}
        refresh={refreshQuestions}
      />

      {showCard && (
      <QuestionCard
        id={currentQuestionId}
        setShowCard={setShowCard}
        setRefreshQuestions={setRefreshQuestions}
      />)}
    </ServiceBody>
  );
}

export default Questions;
