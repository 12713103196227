import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { catchHandler } from '../../../utils/error_handling/error_handling';
import { alertFunction } from '../../../utils/functions/alertFunction';
import { defineData, downloadDataUrl } from '../../../utils/functions/others';
import { clientSendData } from '../../../utils/functions/requests';
import Period from '../../../components/Forms/Period/Period';
import Service from '../../../components/Service/Service';
import ServiceBody from '../../../components/Service/ServiceBody';
import ServicePanel from '../../../components/Service/ServicePanel';
import Pagination from '../../../components/Table/Pagination';
import Table from '../../../components/Table/Table';
import TBody from '../../../components/Table/TBody';
import TData from '../../../components/Table/TData';
import TFoot from '../../../components/Table/TFoot';
import THead from '../../../components/Table/THead';
import TRow from '../../../components/Table/TRow';
import Button from '../../../components/UI/Button/Button';
import Select from '../../../components/UI/Select';
import EntryCard from './EntryCard';
import {
  headersDefault, limits, periodDefault, tables,
} from './defaultData';

/**
 * @component Логи портала
*/
function Logs() {
  const aliases = useSelector((state) => state.services.aliases); // список сервисов
  const [logs, setLogs] = useState([]); // массив логов
  const [logsIDs, setLogsIDs] = useState([]); // массив id записей логов
  const [period, setPeriod] = useState(periodDefault); // выбранный период
  const [choosedEntry, setChoosedEntry] = useState(null); // выбранный период
  const [settings, setSettings] = useState({ table: tables[0].table }); // выбранный источник
  const [limit, setLimit] = useState(limits[0].title); // количество строк на транице
  const [headers, setHeaders] = useState(headersDefault); // заголовки таблиц
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [isShowCard, setIsShowCard] = useState(false); // выбранный период
  const [isFullDisplay, setIsFullDisplay] = useState(false);

  // Валидация данных перед отправкой запроса
  async function validation() {
    // Уведомления, если:
    if (!settings?.table) { // Не выбрана таблица
      await alertFunction('choose_table', 'clientPost');
      return false;
    // } if (!settings?.source_id) { // Не выбран источник
    //   await alertFunction('choose_service', 'clientPost');
    //   return false;
    } if (!period?.from || !period?.to) { // Не выбрана дата от или до
      await alertFunction('wrong_date', 'clientPost');
      return false;
    } return true; // Иначе валидация пройдена
  }

  // Получить ID записей
  async function getLogsIDs() {
    try {
      if (await validation()) {
        const reqData = {
          type: 'getLogs',
          table: settings?.table,
          source_id: settings?.source_id,
          period,
          location: window.location.pathname,
        };

        setIsLoading(true);
        const result = await clientSendData('POST', '/get_logs', reqData);
        if (result) setLogsIDs(result);
      }
    } catch (error) {
      catchHandler(error, 'getLogsIDs');
    } finally {
      setIsLoading(false);
    }
  }

  // Получить информацию по логам
  // range - диапазон id записей
  async function getLogs(range) {
    try {
      const reqData = {
        type: 'getLogs',
        table: settings?.table,
        location: window.location.pathname,
        range,
      };
      setIsLoading(true);
      const result = await clientSendData('POST', '/get_logs', reqData);
      if (result) setLogs(result);
    } catch (error) {
      catchHandler(error, 'getLogs');
    } finally {
      setIsLoading(false);
    }
  }

  // Поиск логов
  async function searchLogs(data) {
    try {
      if (validation()) {
        const reqData = {
          type: 'searchLogs',
          table: settings?.table,
          source_id: settings?.source_id,
          period,
          search_data: data,
        };
        setIsLoading(true);
        const result = await clientSendData('POST', '/search_logs', reqData);
        if (result) setLogsIDs(result);
      }
    } catch (error) {
      catchHandler(error, 'searchLogs');
    } finally {
      setIsLoading(false);
    }
  }

  // Функция выбора страницы
  async function choosePage(number) {
    const fromIndex = number * limit - limit; // номер страницы * лимит отображения -  лимит отображения
    const toIndex = number * limit; // номер страницы * лимит отображения
    const range = logsIDs.slice(fromIndex, toIndex); // диапазон id записей
    if (range.length > 0) {
      setIsLoading(true);
      await getLogs(range); // запросить логи
      setIsLoading(false);
    } else setLogs([]); // иначе сбросить логи
  }

  // Выбрать строку
  function chooseEntry(object) {
    setChoosedEntry(object); // записать объект в состояние
    setIsShowCard(true); // открыть карточку
  }

  // Обработка выбора сервиса
  function chooseService(service) {
    setSettings({
      ...settings,
      source_id: service.id,
      source_title: service.title,
    });
    setIsFullDisplay(false); // сбросить режим отображения
    setHeaders(headersDefault); // сбросить заголовки
  }

  // Обновить заголовки
  function refreshHeaders() {
    if (!isFullDisplay) { // Если показывваются основные поля
      const firstItem = logs[0]?.data;
      // Если есть записи и в первом объекте есть ключ "data"
      if (logs.length > 0 && firstItem) {
        // Пройти по ключам "data" и создать заголовки для таблицы
        const result = Object.keys(firstItem).map((key, index) => ({
          id: index,
          title: key,
          field: `data.${key}`,
          align: typeof firstItem[key] === 'string' ? 'left' : 'right',
        }));

        setHeaders(result); // Обновить заголовки
        setIsFullDisplay(true); // переключить режим отображения

        // Иначе сбросить заголовки
      } else setHeaders(headersDefault);
    } else { // Если показывваются поля jsonb объекта
      setIsFullDisplay(false); // переключить режим отображения
      setHeaders(headersDefault); // сбросить заголовки
    }
  }

  // С
  async function downLoadErrorLogTxt() {
    try {
      const reqData = { type: 'getFileLog' };
      setIsLoading(true);
      const result = await clientSendData('POST', '/get_file_log', reqData);
      // Если инструкция есть - скачать ее
      if (result.success) {
        if (!result.file) await alertFunction('file_not_found');
        else if (result.file.data.length > 0) {
          const reader = new FileReader(); // API чтения файлов
          const resBlob = new Blob([new Uint8Array(result.file.data)]);
          reader.readAsDataURL(resBlob);
          reader.onloadend = ((e) => downloadDataUrl(e.target.result, 'log.txt'));
        } else await alertFunction('empty_file');
      }
    } catch (error) {
      catchHandler(error, 'downLoadErrorLogTxt');
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Service id="logs" vertical>
      <ServicePanel id="logs__panel">
        <Select
          id="logs__choose-source"
          array={tables}
          onChoose={(item) => setSettings({ ...settings, table: item.table })}
          defaultValue={settings?.table}
        />
        <Select
          id="logs__choose-service"
          array={aliases}
          onChoose={chooseService}
          defaultValue={settings?.source_title}
        />

        <Period
          period={period}
          setPeriod={setPeriod}
        />
        Выводить по:
        <Select
          id="logs__choose-limit"
          array={limits}
          onChoose={(item) => setLimit(item.title)}
          defaultValue={limit || 'Выводить по:'}
        />

        <Button onClick={getLogsIDs}>Показать</Button>
        <Button onClick={downLoadErrorLogTxt}>Скачать logs.txt</Button>

      </ServicePanel>

      <ServiceBody>
        <Table id={`logs__table${!isFullDisplay ? '_short' : ''}`}>
          <THead
            title={logsIDs.length > 0 ? `Логи - ${logsIDs.length}` : 'Нет данных для отображения'}
            headers={headers}
            array={logs}
            setArray={setLogs}
            setDefault={() => setLogsIDs(logsIDs)}
            search_by_all={searchLogs}
            button_place="row"
            ext_button={logs.length > 0 ? (isFullDisplay ? 'Основные поля' : 'JSONB поля') : null}
            ext_handler={refreshHeaders}
          />
          <TBody>
            {logs.map((row) => (
              <TRow key={row.id} loading={isLoading}>
                {headers.map((item) => (
                  <TData key={item.id} loading={isLoading} align={item?.align}>
                    {defineData(row, item.field)}
                  </TData>
                ))}
                <TData onClick={() => chooseEntry(row.data)} loading={isLoading}>
                  <img src="../../icons/file-text.svg" alt="file" />
                </TData>
              </TRow>
            ))}
            {/* {logs.length === 0 && (
              <TRow>
                <TData loading={isLoading}>
                  {isLoading ? 'Загрузка данных' : 'Нет данных для отображения'}
                </TData>
              </TRow>
            )} */}
          </TBody>
          <TFoot>
            <Pagination
              array={logsIDs}
              limit={limit}
              handler={choosePage}
            />
          </TFoot>
        </Table>
        {isShowCard && (
          <EntryCard
            object={choosedEntry}
            setShow={setIsShowCard}
          />
        )}
      </ServiceBody>
    </Service>
  );
}

export default Logs;
