import React, { useState, useEffect, useRef } from 'react';
import { clientSendData } from '../../utils/functions/requests';
import { catchHandler } from '../../utils/error_handling/error_handling';
import TData from '../../components/Table/TData';
import Select from '../../components/UI/Select';
import CardBody from '../../components/Card/CardBody';
import TextProcessor from '../../components/TextProcessor/TextProcessor';
import Input from '../../components/UI/Input';
import { alertFunction } from '../../utils/functions/alertFunction';
import ServiceBody from '../../components/Service/ServiceBody';

function NotificationEditor() {
  const [emails, setEmails] = useState([]);
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [editorContent, setEditorContent] = useState({ id: null, title: '', content: '' });
  const [inputValue, setInputValue] = useState('');
  const [displayContent, setDisplayContent] = useState({ content: '' });

  useEffect(() => {
    getEmailTemplate();
  }, []);

  function openEditor(email) {
    setIsEditorOpen(true);
    setEditorContent((state) => ({
      ...state, id: email.id, title: email.title, content: email.content,
    }));
    setInputValue('');
  }

  function closeEditor() {
    setIsEditorOpen(false);
  }

  async function editEmailTemplate(email_id, newTitle, editedText) {
    try {
      const reqNewTitle = newTitle === '' ? editorContent.title : newTitle;
      const reqData = {
        emailId: email_id,
        nTitle: reqNewTitle,
        content: editedText,
        type: 'editEmailTemplate',
      };
      const result = await clientSendData('POST', '/edit_email_template', reqData); // запрос в БД action: useState

      if (result) {
        await alertFunction('save_settings', 'client');
      }
    } catch (error) {
      catchHandler(error, 'editEmailTemplate'); // обработчик ошибок
    } finally { getEmailTemplate(); }
  }

  async function getEmailTemplate() {
    try {
      const reqData = {
        type: 'getEmailTemplate',
      };
      // setIsLoading(true); // загружается
      const result = await clientSendData('POST', '/get_email_template', reqData); // запрос в БД action: useState

      if (result) {
        for (let i = 0; i < result.data.length; i++) {
          setEmails((current) => [...current, { id: result.data[i].id, title: result.data[i].title, content: result.data[i].content }]);
        }
      }
    } catch (error) {
      catchHandler(error, 'getEmailTemplate'); // обработчик ошибок
    } // finally { setIsLoading(false); }
  }

  return (
    <ServiceBody>
        <Select
          defaultValue={editorContent.title}
          array={emails}
          onChoose={(email) => openEditor(email)}
        />

        {isEditorOpen
        && <ServiceBody>
            <CardBody>
            <Input
              value={inputValue === '' ? editorContent.title : inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
        <div key={editorContent.content}>
          <TextProcessor
            content={editorContent.content}
            cancel={closeEditor}
            handler={(e) => editEmailTemplate(editorContent.id, inputValue, e)}
          />
        </div>
            </CardBody>
           </ServiceBody>}
    </ServiceBody>
  );
}

export default NotificationEditor;

/* <ServiceBody>
        <Table id="notifications">
            <THead title="Уведомления" />
            <TBody>
                {emails.map((email) => (
                <TRow key={email.id}>
                <TData>{email.title}</TData>

                </TRow>))}

            </TBody>
                </Table>
     </ServiceBody> */
