// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#maintenanceDepartment__new-card {
  max-width: 800px;
  min-height: 300px;
}`, "",{"version":3,"sources":["webpack://./src/pages/MaintenanceDepartment/maintenanceDepartment.scss"],"names":[],"mappings":"AACA;EACI,gBAAA;EAEA,iBAAA;AADJ","sourcesContent":["#maintenanceDepartment {\n&__new-card {\n    max-width: 800px;\n    //height: fit-content;\n    min-height: 300px;\n    \n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
