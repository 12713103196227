import React, { useState } from 'react';
import FilterRow from './FilterRow';
import SortRow from './SortRow';

/**
* @component Хедер таблицы
@prop {title} string (Обязательный) Название таблицы
@prop {headers} array (Обязательный) Массив заголовков
@prop {array} array (Обязательный) Массив данных отображаемый в таблице
@prop {setArray} function (Обязательный) Обновление состояния массива отображаемого в таблице
@prop {setDefault} function Функция сброса массива

@prop {button_place} string Расположение кнопки включения фильтрации ("header"/"row")
@prop {ext_button} string Текст дополнительной кнопки
@prop {ext_handler} function Обработчик дополнительной кнопки

@prop {ext_search} function Внешний обработчик фильтрации
@prop {ext_sorting} function Внешний обработчик сортировки
@prop {search_by_all} function Внешний обработчик поиска по всем полям
@prop {search_by_one} function Внешний обработчик поиска в одной строке
*/

function THead(props) {
  const {
    title, headers, array, setArray, setDefault, button_place, ext_button, ext_handler, ext_search, ext_sorting, search_by_all, search_by_one, children,
  } = props;

  const filterInitialState = button_place?.includes('enabled') ? 'fields' : null;
  const [sortField, setSortField] = useState(''); // сортируемое поле
  const [isFilter, setIsFilter] = useState(filterInitialState); // состояние видимости строки фильтрации
  const [defaultArray, setDefaultArray] = useState(array); // массив по умолчанию
  const [filteredArray, setFilteredArray] = useState(array); // отфильтрованный массив
  const [filterData, setFilterData] = useState([]); // данные фильтрации массив объектов - [{field: field, value: e.target.value}, ...]
  const [filterString, setFilterString] = useState(''); // данные фильтрации строка - 'ывс12в2усу'
  const noArray = !array || !Array.isArray(array); // показатель отсутствия массива данных
  const noHeaders = !headers || !Array.isArray(headers) || headers.length <= 0; // показатель отсутствия массива заголовков
  const filterHeaders = !noHeaders ? headers.filter((item) => item) : []; // убрать из заголовков false значения

  // Переключение режимов фильтрации
  function toggleFilter() {
    if (search_by_one) { // если есть функция поиска по одному полю
      if (button_place === 'row enabled' || button_place === 'header enabled') {
        switch (isFilter) { // меняется логика переключения фильтрации
          case 'fields': setIsFilter('string'); // установить тип фильтрации string
            break;
          case 'string': setIsFilter('fields'); // выкл фильтрацию
            break;
          default: setIsFilter('fields');
        }
      } else {
        switch (isFilter) { // меняется логика переключения фильтрации
          case null: setIsFilter('fields'); // установить тип фильтрации fields
            break;
          case 'fields': setIsFilter('string'); // установить тип фильтрации string
            break;
          case 'string': setIsFilter(null); // выкл фильтрацию
            break;
          default: setIsFilter(null); // выкл фильтрацию
        }
      }
    } else setIsFilter(!isFilter); // показать/спрятать строку фильтрации

    setSortField(''); // сбросить поле сортировки
    setFilterData([]); // сбросить данные поиска
    setFilterString(''); // сбросить данные поиска
    if (isFilter === true || isFilter === 'string') resetArray(); // Сбросить массив
  }

  // Сбросить массив
  function resetArray() {
    if (setDefault) setDefault();
    else setArray(defaultArray); // сбросить фильтры
  }

  if (children) {
    return (
      <thead className="upu-table__table-head">
        {title && (
        <tr className="upu-table__table-row">
          <td className="upu-table__header">{title}</td>
        </tr>
        )}
        {children}
      </thead>
    );
  }
  if (noHeaders) { // если отсутствуют заголовки
    return (
      <thead className="upu-table__table-head">
        <tr className="upu-table__table-row">
          <td className="upu-table__header">{title || ''}</td>
        </tr>
      </thead>
    );
  }
  if (noArray) { // если отсутствует массив данных для вывода
    return (
      <thead className="upu-table__table-head">
        <tr className="upu-table__table-row">
          <td className="upu-table__header">{title || ''}</td>
        </tr>
        <tr className="upu-table__table-row">
          {filterHeaders.map((item) => {
            const { title = '', mobile_hide = false } = item;
            return <td key={title} className={`upu-table__table-data ${mobile_hide ? 'mobile_hide' : ''}`}>{title}</td>;
          })}
        </tr>
      </thead>
    );
  }
  return (
    <thead className="upu-table__table-head">
      {title && (
        <tr className="upu-table__table-row">
          <td className="upu-table__header">

            {/* Дополнительная кнопка */}
            {ext_button && (
            <span className="upu-table__ext-button" onClick={ext_handler}>
              {ext_button}
            </span>
            )}

            {/* Заголовок таблицы */}
            {title}

            {/* Кнопка включения/выключения режима фильтрации */}
            {(button_place?.includes('header') || !button_place) && (
            <img
              className={`upu-table__search-button ${isFilter ? ' upu-table__search-button_active' : ''}`}
              src="../../icons/button/search.svg"
              onClick={toggleFilter}
              alt="search"
            />
            )}
          </td>
        </tr>
      )}

      <SortRow
        headers={filterHeaders} // массив заголовков
        array={array} // массив данных отображаемый в таблице
        setArray={setArray} // обновление состояния этого массива
        sortField={sortField} // сортируемое поле
        setSortField={setSortField} // обновление состояния сортируемого поля
        filteredArray={filteredArray} // отфильтрованный массив
        button_place={button_place} // расположение кнопки включения фильтрации
        isFilter={isFilter} // состояние видимости строки фильтрации
        toggleFilter={toggleFilter} // переключение режима фильтрации
        ext_sorting={ext_sorting}
      />
      <FilterRow
        isFilter={isFilter} // состояние видимости строки фильтрации
        headers={filterHeaders} // массив заголовков
        setArray={setArray} // обновление состояния массива, отображаемого в таблице
        setSortField={setSortField} // обновление состояния сортируемого поля
        defaultArray={defaultArray} // исходный массив данных отображаемый в таблице
        setFilteredArray={setFilteredArray} // обновление состояние отфильтрованного массива
        button_place={button_place} // расположение кнопки включения фильтрации
        ext_search={ext_search} // внешний обработчик фильтрации
        search_by_all={search_by_all} // внешний обработчик поиска по всем полям
        search_by_one={search_by_one} // внешний обработчик поиска в одной строке
        filterData={filterData} // данные фильтрации массив объектов - [{field: field, value: e.target.value}, ...]
        setFilterData={setFilterData} // обновление данных фильтрации
        filterString={filterString} // данные фильтрации строка - 'ывс12в2усу'
        setFilterString={setFilterString}
      />
    </thead>
  );
}

export default THead;
