import React, { useState } from 'react';
import FilterAllCell from './FilterAllCell';
import FilterCell from './FilterCell';

/**
* @component Хедер таблицы - строка фильтрации
@prop {isFilter} boolean Состояние видимости строки фильтрации
@prop {headers} array Массив заголовков
@prop {setArray} function Обновление состояния массива отображаемого в таблице
@prop {setSortField} function Обновление сортируемого поля
@prop {defaultArray} array Исходный массив данных отображаемый в таблице
@prop {setFilteredArray} function обновление состояния отфильтрованного массива
@prop {button_place} string Расположение кнопки включения фильтрации
@prop {ext_search} function Внешний обработчик фильтрации
@prop {search_by_all} function Внешний обработчик поиска по всем полям
@prop {search_by_one} function Внешний обработчик поиска в одной строке
@prop {filterData} array Массив данных фильтрации/поиска [{field: field, value: e.target.value}, ...]
@prop {setFilterData} function Обновление массива данных фильтрации/поиска
@prop {filterString} string Данные фильтрации строка - 'ывс12в2усу'
@prop {setFilterString} function Обновление данных фильтрации
*/

function FilterRow(props) {
  const {
    isFilter, headers, setArray, setSortField, defaultArray, setFilteredArray, button_place, ext_search, search_by_all, search_by_one, filterData, setFilterData, filterString, setFilterString,
  } = props;

  const [filterField, setFilterField] = useState(''); // фильтруемое поле
  const [isLoading, setIsLoading] = useState(false);

  // Обработчик нажатия "Enter" в инпуте или кнопки "Найти"
  async function findButtonHandler() {
    if (isFilter === 'string' && filterString) { // если тип поиска - строка и есть данные для поиска
      setIsLoading(true);
      await search_by_one(filterString); // вызывается внешний обработчик поиска
      setIsLoading(false);
    } else if (filterData.length > 0) { // если есть данные для фильтрации
      setIsLoading(true);
      await search_by_all(filterData); // вызывается внешний обработчик
      setIsLoading(false);
    }
  }

  // Выбор обработчика string поиска
  async function inputHandler(e) {
    if (isFilter === 'string') setFilterString(e.target.value); // обновить состояние
    if (e.type === 'keyup' && e.key === 'Enter' && filterString) { // Если пользователь нажал Enter
      setIsLoading(true);
      await search_by_one(e.target.value); // вызвать внешний обработчик
      setIsLoading(false);
    }
  }

  return (
    <tr className={`upu-table__table-row upu-table__filter-block${isFilter ? '_active' : ''}`}>
        {/* Ячейки с поиском */}
        {isFilter !== 'string' ? (
          headers.map((item, index) => {
            const { field = '', mobile_hide = false } = item;

            // если присутствует обработчик поиска по всем полям - отображаются ячейки с общим поиском
            if (search_by_all) {
              return (
                <FilterAllCell
                  key={field + index}
                  field={field} // наименование поля
                  setSortField={setSortField} // обновление сортируемого поля
                  setFilterField={setFilterField} // обновление фильтруемого поля
                  searchByAllHandler={findButtonHandler} // Обработчик нажатия "Enter" в инпуте или кнопки "Найти"
                  filterData={filterData} // массив данных фильтрации/поиска
                  setFilterData={setFilterData} // обновление массива данных фильтрации/поиска
                  disable_search={item?.disable_search}
                />
              );
            }

            // иначе отображаются ячейки с фильтрацией по текущему массиву
            return (
                <FilterCell
                  key={field + index}
                  isFilter={isFilter} // состояние видимости блока фильтрации
                  type={item.type} // тип поля
                  field={field} // наименование поля
                  mobile_hide={mobile_hide} // параметр скрытия при мобильном отображениии
                  setArray={setArray} // обновление состояния массива, отображаемого в таблице
                  setSortField={setSortField} // обновление сортируемого поля
                  filterField={filterField} // фильтруемое поле
                  setFilterField={setFilterField} // обновление фильтруемого поля
                  defaultArray={defaultArray} // исходный массив данных отображаемый в таблице
                  setFilteredArray={setFilteredArray} // обновление состояние отфильтрованного массива
                  ext_search={ext_search} // внешний обработчик фильтрации
                  disable_search={item?.disable_search}
                />
            );
          })
        ) : (
        <td className="upu-table__table-data">
            <input
              type="text"
              value={filterString}
              className="input search-input"
              placeholder=" "
              onChange={inputHandler}
              onKeyUp={inputHandler}
            />
        </td>)}

        {button_place === 'row' && !search_by_all && <td className="upu-table__table-data" />}

        {search_by_all && (
        <td className={`upu-table__table-button ${isLoading ? 'upu__loading' : ''}`} onClick={findButtonHandler}>
            Найти
        </td>)}
    </tr>
  );
}

export default FilterRow;
