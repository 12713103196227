// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#admin-panel .service__body {
  height: calc(100vh - 100px);
  justify-items: normal;
  padding-top: 80px;
}
#admin-panel__table {
  max-width: 1000px;
}
#admin-panel__table thead tr:not(:first-child) {
  grid-template-columns: 1fr 2fr 300px;
}
#admin-panel__table tbody tr {
  grid-template-columns: 1fr 2fr 300px;
}
#admin-panel__scheduler-table {
  max-width: 1200px;
}
#admin-panel__scheduler-table thead tr:not(:first-child) {
  grid-template-columns: 1fr 300px 1fr 1fr 0.5fr;
}
#admin-panel__scheduler-table tbody tr {
  grid-template-columns: 1fr 300px 1fr 1fr 0.5fr;
}

#logs__table_short thead tr:not(:first-child) {
  grid-template-columns: 1fr 0.5fr 0.5fr 4fr 40px;
}
#logs__table_short tbody tr {
  grid-template-columns: 1fr 0.5fr 0.5fr 4fr 40px;
}
#logs__table thead tr:not(:first-child) {
  grid-template-columns: 1fr 1fr 1fr 4fr 1fr 1fr 1fr 1fr 3fr 1fr 40px;
}
#logs__table tbody tr {
  grid-template-columns: 1fr 1fr 1fr 4fr 1fr 1fr 1fr 1fr 3fr 1fr 40px;
}
#logs__entry-card {
  max-width: 600px;
}`, "",{"version":3,"sources":["webpack://./src/pages/AdminPanel/adminPanel.scss"],"names":[],"mappings":"AAEE;EACE,2BAAA;EACA,qBAAA;EACA,iBAAA;AADJ;AAGE;EACE,iBAAA;AADJ;AAGM;EACE,oCAAA;AADR;AAKM;EACE,oCAAA;AAHR;AAQE;EACE,iBAAA;AANJ;AAQM;EACE,8CAAA;AANR;AAUM;EACE,8CAAA;AARR;;AAkBQ;EACE,+CAAA;AAfV;AAmBQ;EACE,+CAAA;AAjBV;AAsBM;EACE,mEAAA;AApBR;AAwBM;EACE,mEAAA;AAtBR;AA0BE;EACE,gBAAA;AAxBJ","sourcesContent":["\n#admin-panel {\n  .service__body {\n    height: calc(100vh - 100px);\n    justify-items: normal;\n    padding-top: 80px;\n  }\n  &__table {\n    max-width: 1000px;\n    & thead {\n      & tr:not(:first-child) {\n        grid-template-columns: 1fr 2fr 300px;\n      }\n    }\n    & tbody {\n      & tr {\n        grid-template-columns: 1fr 2fr 300px;\n      }\n    }\n  }\n\n  &__scheduler-table {\n    max-width: 1200px;\n    & thead {\n      & tr:not(:first-child) {\n        grid-template-columns: 1fr 300px 1fr 1fr 0.5fr;\n      }\n    }\n    & tbody {\n      & tr {\n        grid-template-columns: 1fr 300px 1fr 1fr 0.5fr;\n      }\n    }\n  }\n}\n\n#logs {\n  &__table {\n    &_short {\n      & thead {\n        & tr:not(:first-child) {\n          grid-template-columns: 1fr .5fr .5fr 4fr 40px;\n        }\n      }\n      & tbody {\n        & tr {\n          grid-template-columns: 1fr .5fr .5fr 4fr 40px;\n        }\n      }\n    }\n    & thead {\n      & tr:not(:first-child) {\n        grid-template-columns: 1fr 1fr 1fr 4fr 1fr 1fr 1fr 1fr 3fr 1fr 40px;\n      }\n    }\n    & tbody {\n      & tr {\n        grid-template-columns: 1fr 1fr 1fr 4fr 1fr 1fr 1fr 1fr 3fr 1fr 40px;\n      }\n    }\n  }\n  &__entry-card {\n    max-width: 600px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
