import React from 'react';
import Table from '../Table/Table';
import THead from '../Table/THead';
import TRow from '../Table/TRow';
import TData from '../Table/TData';
import TBody from '../Table/TBody';

/**
* @component Таблица для отображения массива объектов
@prop {id} string (Обязательный) Идентификатор DOM-элемента
@prop {data} array (Обязательный) массив объектов для отображения
*/

function TableComponent(props) {
  const { id, data } = props;

  const keys = Object?.keys(data[0]) || []; // массив ключей первого объекта
  return (
    <Table id={id}>
        <THead>
            <TRow>
                {keys.map((key) => <TData key={key}>{key}</TData>)}
            </TRow>
        </THead>
        <TBody>
            {data.map((row, index) => (
                <TRow key={row?.id || index}>
                    {keys.map((key, idx) => <TData key={idx}>{row[key]}</TData>)}
                </TRow>
            ))}
        </TBody>
    </Table>
  );
}

export default TableComponent;
