import React, { useEffect, useState } from 'react';
import { clientSendData } from '../../../../utils/functions/requests';
import { catchHandler } from '../../../../utils/error_handling/error_handling';
import Loader from '../../../../components/UI/Loader';
import Table from '../../../../components/Table/Table';
import THead from '../../../../components/Table/THead';
import TBody from '../../../../components/Table/TBody';
import TRow from '../../../../components/Table/TRow';
import TData from '../../../../components/Table/TData';
import CardBody from '../../../../components/Card/CardBody';

/**
 * @component Бронирование - Интерфейс администратора - карточка места - вкладка "История"
 * @prop {placeId} number - id рабочего места
 */
function History(props) {
  const { placeId } = props;

  const [history, setHistory] = useState([]); // история субъекта
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const columns = [
    { title: 'Сотрудник', field: 'user', align: 'left' },
    { title: 'Начало', field: 'date_start', align: 'right' },
    { title: 'Окончание', field: 'date_end', align: 'right' },
    { title: 'Статус', field: 'status', align: 'left' },
  ];
  useEffect(() => {
    if (placeId) getWorkplaceHistory();
  }, [placeId]);

  // Получить заявки
  async function getWorkplaceHistory() {
    try {
      const reqData = {
        type: 'getWorkplaceHistory',
        placeId,
      };
      setIsLoading(true);
      const result = await clientSendData('POST', '/get_workplace_history', reqData);
      if (result?.success) setHistory(result.data); // Результат записывается в состояние
    } catch (error) {
      catchHandler(error, 'getWorkplaceHistory');
    } finally {
      setIsLoading(false);
    }
  }

  if (isLoading) return <CardBody><Loader /></CardBody>;
  if (history.length === 0) return <CardBody><h2 className="upu-service__title">История отсутствует</h2></CardBody>;
  return (
    <CardBody>
      <Table id="booking__history">
        <THead
          headers={columns}
          array={history}
          setArray={setHistory}
        />
        <TBody>
          {history.map((row) => (
            <TRow key={row.id}>
              {columns.map((column) => (
              <TData key={column?.field} align={column?.align}>
                {row[column?.field]}
              </TData>))}
            </TRow>
          ))}
        </TBody>
      </Table>
    </CardBody>
  );
}

export default History;
