import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
/**
* @component Карточки разделов портала
*/
function ServiceCards() {
  const userServices = useSelector((state) => state.services.user_services);

  return (
    <div className="main__service-cards">
      {userServices.map((service) => {
        const {
          id, title, parameters, link, area,
        } = service;
        const { description = null, visibility } = parameters;
        if (visibility) {
          return (
          <Link key={id} to={link} className={`main__service-card${area === 1 ? ' main__service-card_admin' : ''}`}>
            <h3>{title}</h3>
            <p>{description}</p>
          </Link>
          );
        }
        return null;
      })}
    </div>
  );
}

export default ServiceCards;
