import React, { useEffect, useState } from 'react';
import { authorization } from '../../utils/functions/authenticate';
import { setOperInfo } from '../../utils/functions/others';
import Service from '../../components/Service/Service';
import Sessions from './Sessions/Sessions';
import Users from './Users/Users';
import Services from './Services/Services';
import Cap from '../Cap/Cap';
import './adminPage.scss';

/**
 * @component Пользователи и права
*/
function AdminPage() {
  const [isAuthorized, setIsAuthorized] = useState(false); // Состояние авторизации
  const pages = [
    { id: 1, title: 'Пользователи', component: <Users /> },
    { id: 2, title: 'Сессии', component: <Sessions /> },
    { id: 3, title: 'Сервисы', component: <Services /> },
  ];

  useEffect(() => {
    setOperInfo();
    awaitRequests();
  }, []);

  async function awaitRequests() {
    const checkAuthorization = await authorization(); // авторизация
    setIsAuthorized(checkAuthorization);
  }

  if (isAuthorized) return <Service id="admin-panel" pages={pages} horizontal />;
  return <Cap />;
}

export default AdminPage;
