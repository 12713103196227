/* eslint-disable consistent-return */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-lonely-if */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { catchHandler } from '../../../utils/error_handling/error_handling';
import { alertFunction } from '../../../utils/functions/alertFunction';
import { clientSendData } from '../../../utils/functions/requests';
import { createAction } from '../../../utils/redux/store';
import { chooseDivision, getActiveScheme, sortObjectKeys } from '../Common/functions';
import { setUnique, log } from '../../../utils/functions/others';
import { stringMatch } from '../../../utils/functions/stringHandling';
import ServicePanel from '../../../components/Service/ServicePanel';
import InputSearch from '../../../components/UI/InputSearch';
import Loader from '../../../components/UI/Loader';
import Button from '../../../components/UI/Button/Button';
import classes from '../../../components/UI/Button/button.module.scss';
import Select from '../../../components/UI/Select';
import ThemeHierarchyLine from './ThemeHierarchyLine';

/**
* @component База знаний - Панель - Иерархическая структура тем
* @prop {getThemesQuestions} function - Получение вопросов по выбранной теме
* @prop {getLastQuestions} function - Получение последних вопросов
* @prop {numberRecentQuestions} function - Количество последних вопросов для показа
* @prop {setQuestionsId} function - обновление состояния массива id вопросов, относящихся к выбранным темам
*/
function ThemeHierarchy(props) {
  const {
    getThemesQuestions, getLastQuestions, numberRecentQuestions, setQuestionsId,
  } = props;
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [hierarchy, setHierarchy] = useState({}); // иерархия
  const [initialData, setInitialData] = useState([]); // исходные данные
  const [openedEssences, setOpenedEssences] = useState([]); // массив открытых сущностей
  const [foundedEssences, setFoundedEssences] = useState([]); // массив найденных сущностей
  const [highlighted, setHighlighted] = useState([]); // выделенные сущности (при поиске)
  const [activeTheme, setActiveTheme] = useState(null); // активная (выбранная тема)
  const currentDivision = useSelector((state) => state.kb.current_division); // текущее подразделение
  const userDivisions = useSelector((state) => state.kb.user_divisions); // подразделения доступные пользователю
  const scheme = useSelector((state) => state.kb.active_scheme?.theme?.scheme); // данные активной схемы темы
  const questionScheme = useSelector((state) => state.kb.active_scheme?.question?.scheme); // данные активной схемы темы
  // Фича--------------------------
  const foundedQuestions = useSelector((state) => state.kb.founded_questions); // id найденных вопросов

  // ключ для создания иерархической структуры (если есть схема)
  const parentKey = scheme ? Object.keys(scheme).filter((key) => scheme[key]?.parent)[0] : null;
  // поле для получения заголовка отображаемого в структуре
  const titleKey = scheme?.[parentKey]?.switch?.switch_column;
  // Сортировка тем иерархии
  const themesSorting = 'asc';

  useEffect(() => {
    getThemesData();
    // Сбросить foundedQuestions в хранилище (если до этого искали в админке)
    createAction('SET_FOUNDED_KB_QUESTIONS', null);
  }, [scheme, foundedQuestions]);

  // ------------------Получение данных и создание структуры----------

  // Получить данные для отображения
  async function getThemesData() {
    try {
      // Ключ в схеме вопроса ссылающийся на темы
      const questionThemeKey = Object.keys(questionScheme).filter((key) => questionScheme[key]?.link_kb_themes);
      const reqData = {
        type: 'getThemesData',
        parent_key: parentKey,
        title_key: titleKey,
        division: currentDivision,
        location: window.location.pathname,
        question_theme_key: questionThemeKey,
        question_ids: foundedQuestions,
      };
      setIsLoading(true);

      const result = await clientSendData('POST', '/get_themes_data', reqData); // Запрос в БД
      if (result === 'bad_request') {
        alertFunction('bad_request', 'clientPost'); // если некорректный запрос
        setIsLoading(false);
      } else if (result) {
        setInitialData(result); // запись в состояние исходных данных
        createStructure(result); // создание структуры
        setIsLoading(false);
      } else {
        createStructure([]); // сброс структуры
        setInitialData([]); // сброс исходных данных
        setIsLoading(false);
      }
    } catch (error) {
      catchHandler(error, 'getThemesData');
      setIsLoading(false);
    }
  }

  // Создать структуру
  function createStructure(array) {
    const copy = array.map((item) => item); // копия массива для внесения изменений
    const structure = {}; // создаём структуру
    addRemove(copy, parentKey, structure); // запускаем ее наполнение
    setHierarchy(structure); // записываем созданную структуру в состояние компонента

    // Наполнение структуры
    // arr - массив объектов
    // parent_key - наименования ключа по которому ищем родителя
    // structure - объект структуры для наполнения
    function addRemove(arr, parent_key, structure) {
      const to_delete = []; // Пустой массив под удаление

      arr.forEach((item) => { // проходим по каждому элементу
        // Фича--------------------------
        const parent = array.find((i) => i.id === Number(item?.[parent_key]));
        // ------------------------------
        if (item?.[parent_key] === null || !parent) { // если родительский ключ равен null
          structure[item?.id] = item; // элемент записывается в структуру со своим id вместо ключа
          to_delete.push(item.id); // Записываю элемент для удаления из массива исходных данных
        } else { // Иначе запускаем функцию поиска родителя
          if (addObjectChildren(structure, item[parent_key], item)) { // Если вернулось true значит в структуру успешно добавили
            to_delete.push(item.id); // Можно удалять
          }
        }
      });

      if (to_delete.length > 0) { // Смотрим если объекты для удаления есть
        to_delete.forEach((id) => { // удаляем объекты из исходного массива
          arr = arr.filter((item) => item.id !== id);
        });
        addRemove(arr, parent_key, structure); // Перезапускаем процедуру добавления в структуру и удаления
      }
    }

    // Добавить детей к объекту
    function addObjectChildren(object, key, toadd) {
      // Если объект содержит key (фактически ключ в виде id)
      if (object.hasOwnProperty(key)) {
        // Если у объекта по ключу key отсутствует ключ children - добавляем его
        if (!object[key].hasOwnProperty('children')) object[key].children = {};
        // Затем добавляем в него объект для добавления с ключом одноименным с его id
        object[key].children[toadd.id] = toadd;
        return true;
      }
      // Если объект не содержит key - проходим по его ключам
      for (let j = 0; j < Object.keys(object).length; j++) {
        const element = object[Object.keys(object)[j]];
        // console.log("Беру елемент", element);
        if (Object.keys(element).find((i) => i === 'children')) {
          // console.log("Есть ключ children ищу внутри");
          if (addObjectChildren(element.children, key, toadd)) {
            return true; // Этот return выводит из рекурсии
          }
        }
      }
      // Object.keys(object).forEach((item) => {
      //     // Если есть children - проверяем их и записываем туда объект для добавления
      //     if (object[item].hasOwnProperty("children")) {
      //         if (addObjectChildren(object[item].children, key, toadd)) return true;
      //         else return
      //     }
      // })
    }
  }

  // --------------------Обработка отображения------------------------

  // // Сущность открыта?
  // function isOpened(id) {
  //   if (openedEssences.includes(id)) return true; // если есть - возвращает true
  //   return false; // иначе false
  // }

  // // Сущность выделена?
  // function isHighlighted(id) {
  //   if (highlighted.includes(id)) return true; // если есть - возвращает true
  //   return false; // иначе false
  // }

  // // Определить класс
  // function defineClass(object) {
  //   if (!object?.children) return 'hierarchy__expand hierarchy__expand_hide';
  //   if (isOpened(object?.id)) return 'hierarchy__expand hierarchy__expand_active';
  //   return 'hierarchy__expand';
  // }

  function resetHighlight() {
    setHighlighted([]);
  }

  // --------------------Обработка действий------------------------

  // // Выбрать сущность
  // function chooseEssence(object) {
  //   setActiveTheme(object); // активировать тему
  //   const children = defineChildren(object); // найти детей
  //   getThemesQuestions([object.id, ...children]); // передать id тем для получения связанных вопросов
  // }

  // // Открыть/закрыть конкретную сущность
  // function toggleEssence(event, id) {
  //   event.stopPropagation();
  //   if (openedEssences.includes(id)) closeEssences([id]); // если найдена среди активных - сущность закрывается
  //   else openEssences([id]); // если нет - сущность открывается
  // }

  // Открыть сущности
  function openEssences(array) {
    let newArray = [...openedEssences, ...array]; // добавить в открытые сущности новые данные
    newArray = newArray.filter(setUnique); // оставить уникальные
    setOpenedEssences(newArray);
  }

  // // Закрыть сущности
  // function closeEssences(array) {
  //   // оставить только те, которых нет в array
  //   const newArray = openedEssences.filter((item) => !array.includes(item));
  //   setOpenedEssences(newArray);
  // }

  // Свернуть все сущности
  function closeAllEssences(e) {
    e.stopPropagation();
    setOpenedEssences([]); // сбросить массив открытых сущностей
    resetHighlight(); // сбросить выделение найденных элементов
    log({ message: 'Пользователь нажал кнопку "Свернуть все" в дереве тем вопросов', eventtype: 'info' });
  }

  // Сбросить данные тем и вопросов
  function resetData(e) {
    e.stopPropagation();
    setActiveTheme(null); // сбросить выбранную тему
    setQuestionsId([]); // сбросить id вопросов, относящихся к выбранным темам
    setHighlighted([]); // сбросить выделенные при поиске сущности (если есть)
    setOpenedEssences([]); // сбросить массив открытых сущностей
    setFoundedEssences([]); // сбросить массив найденных сущностей
    createAction('SET_FOUNDED_KB_QUESTIONS', null); // Сбросить foundedQuestions в хранилище
    getActiveScheme(currentDivision.id); // Перезапросить схемы и useEffect обновит темы и вопросы
    log({ message: 'Пользователь нажал кнопку "Сбросить" в дереве тем вопросов', eventtype: 'info' });
  }

  // Определить генеалогическое древо элементов в иерархии
  function defineParents(id, array) {
    // Если входящий элемент - массив, оставить его, иначе создать массив из одного элемента
    const arrayOfId = Array.isArray(id) ? id : [id];
    let parents = []; // массив для наполнения

    // Функция поиска родителя
    function findParent(id) {
      const object = array.find((item) => item.id === id); // дочерний объект
      const parent = object?.[parentKey]; // родитель
      if (parent) { // если есть родитель
        parents.push(+parent); // добавить id в массив
        findParent(+parent); // запустить поиск родителя родителя
      } // если родителя нет выйти из функции
    }

    arrayOfId.forEach((id) => findParent(id)); // Найти родителей
    parents = parents.filter(setUnique); // оставить только уникальные значения

    return parents;
  }

  // // Определить id дочерних элементов объекта в иерархии
  // function defineChildren(parent) {
  //   const result = []; // массив для записи id детей

  //   // Функция добавления детей в массив
  //   function addChild(object) {
  //     if (object?.children) { // если объект содержит children
  //       const { children } = object;
  //       Object.keys(children).forEach((key) => { // пройти по ключам children
  //         result.push(children[key].id); // и запись id в массив
  //         // если ключ содержит детей вызвать функцию заново передав ей дочерний элемент
  //         if (children[key]?.children) addChild(children[key]);
  //         // иначе выйти из рекурсии
  //       });
  //     } // иначе выйти из рекурсии
  //   }

  //   addChild(parent); // записать id дочерних элеменов

  //   return result; // вернуть массив
  // }

  // --------------------Обработка поиска------------------------

  // Обработчик поиска
  function searchEssence(event) {
    try {
      let string = event.target.value; // искомая строка
      string = string.replace(/[.?*+^$[\]\\(){}|-]/g, '\\$&'); // экранирование спецсимволов

      if (string.length > 1) {
        // Результаты фильтрации
        let searchResult = initialData.filter((item) => stringMatch(string, String(item[titleKey])));
        searchResult = searchResult.map((item) => ({ ...item, title: item[titleKey] })); // добавить title к объектам массива (для InputSearch)
        setFoundedEssences(searchResult); // обновить состояние сущностей
      } else setFoundedEssences([]); // иначе - сбросить сущностиы
    } catch (error) {
      catchHandler(error, 'searchEssence'); // обработка ошибок
    }
  }

  // Выбрать сущность в поиске
  function showFoundEssences(object) {
    // let parents;
    let identifiers;
    // если передаётся массив - оставляем в нем только id
    if (Array.isArray(object)) identifiers = object.map((item) => item.id);
    else identifiers = [object?.id]; // иначе передаётся объект

    const parents = defineParents(identifiers, initialData); // ищем родителей элементов
    openEssences(parents); // открыввем найденные сущности
    setHighlighted(identifiers);
  }

  // --------------------Отображение-----------------------------

  // // Компонент иерархии
  // // eslint-disable-next-line react/no-unstable-nested-components
  // function Line(props) {
  //   const { object } = props;
  //   const { id } = object;
  //   const titleClass = activeTheme?.id === id ? 'hierarchy__title hierarchy__title_active' : 'hierarchy__title';
  //   const titleContentClass = isHighlighted(id) ? 'hierarchy__title-content hierarchy__title-content_active' : 'hierarchy__title-content';
  //   return (
  //     <div className="hierarchy__line">
  //       <div className={titleClass} onClick={() => chooseEssence(object)}>
  //         {/* Свернуть/развернуть сущность */}
  //         <div className={defineClass(object)} onClick={(event) => toggleEssence(event, id)} />
  //         <span className={titleContentClass}>
  //           {object[titleKey]}
  //         </span>
  //       </div>
  //       {showChildrens(object)}
  //     </div>
  //   );
  // }

  // // Показать детей объекта
  // function showChildrens(object) {
  //   if (object?.children) {
  //     return (
  //       <div className={`hierarchy__children ${isOpened(object?.id) ? 'hierarchy__children_active' : ''}`}>
  //         {sortObjectKeys(object.children, titleKey, themesSorting).map((key) => {
  //           const child = object.children[key];
  //           return <Line key={key} object={child} id={key} />;
  //         })}
  //       </div>
  //     );
  //   }
  // }

  if (isLoading) return <ServicePanel id="interface__hierarchy"><Loader /></ServicePanel>;
  return (
    <ServicePanel id="interface__hierarchy">
      <div className="hierarchy__top" onClick={(e) => e.stopPropagation()}>
        {/* Выбор подразделения */}
        {userDivisions.length > 1 && (
        <Select
          id="knowledge-base__select-division"
          array={userDivisions}
          onChoose={chooseDivision}
          defaultValue={currentDivision?.title || 'Подразделение'}
        />)}

        {/* Поиск тем */}
        {initialData.length === 0 ? null : (
        <InputSearch
          id="knowledge-base__hierarchy-search"
          array={foundedEssences}
          onSearch={searchEssence}
          onChoose={showFoundEssences}
          onPressEnter={showFoundEssences}
          placeholder="Поиск..."
        />)}
      </div>

      {/* Отображение иерархии */}
      {sortObjectKeys(hierarchy, titleKey, themesSorting).map((key) => {
        const object = hierarchy[key];
        return (
          <ThemeHierarchyLine
            key={key}
            object={object}
            scheme={scheme}
            activeTheme={activeTheme}
            setActiveTheme={setActiveTheme}
            openedEssences={openedEssences}
            setOpenedEssences={setOpenedEssences}
            highlighted={highlighted}
            openEssences={openEssences}
            getThemesQuestions={getThemesQuestions}
          />
        );
      })}

      <div className="hierarchy__button-block">
        <Button className={`${classes.button} ${classes.small}`} onClick={resetData}>Сбросить</Button>
        {openedEssences.length > 0 && (
        <Button className={`${classes.button} ${classes.small}`} onClick={closeAllEssences}>Свернуть все</Button>)}
        <Button className={`${classes.button}`} onClick={getLastQuestions}>{`последние ${numberRecentQuestions} вопросов`}</Button>
      </div>
    </ServicePanel>
  );
}

export default ThemeHierarchy;
