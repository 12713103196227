import React, { useState, useEffect } from 'react';
import Table from '../../components/Table/Table';
import THead from '../../components/Table/THead';
import TBody from '../../components/Table/TBody';
import TRow from '../../components/Table/TRow';
import TData from '../../components/Table/TData';
import ServiceBody from '../../components/Service/ServiceBody';
import { catchHandler } from '../../utils/error_handling/error_handling';
import { clientSendData } from '../../utils/functions/requests';
import { stringMatch } from '../../utils/functions/stringHandling';
import TFoot from '../../components/Table/TFoot';
import { tableToExcel } from '../../utils/functions/others';

function MobileTable() {
  const headers = [
    { title: 'Дата создания', field: 'date_created' },
    { title: 'Наименование (номер)', field: 'phone' },
    { title: 'Статус', field: 'status' },
    { title: 'Сотрудник', field: 'user' },
    { title: 'Комментарий', field: 'comment' },
    { title: 'Компания', field: 'company' },
  ];

  const inputsStateDefault = headers.reduce((acc, current) => Object.assign(acc, { [current.field]: '' }), {});

  const [phoneNums, setPhoneNums] = useState([]);
  const [displayArr, setDisplayArr] = useState([]);
  const [filterField, setFilterField] = useState('');
  const [inputState, setInputState] = useState(inputsStateDefault);

  useEffect(() => {
    getPhonenumbers();
  }, []);

  async function getPhonenumbers() {
    try {
      const reqData = {
        type: 'getPhonenumbers',
      };
      const result = await clientSendData('POST', '/get_phonenumbers', reqData); // запрос в БД
      if (result) {
        setPhoneNums(result.data);
        setDisplayArr(result.data);
      }
    } catch (error) {
      catchHandler(error, 'getDirections'); // обработчик ошибок
    }
  }

  function searchHandler(e, field) {
    try {
      setInputState({ ...inputsStateDefault, [field]: e.target.value });
      setFilterField(field);
      const filterResult = phoneNums.filter((item) => stringMatch(e.target.value, String(item[field])));
      if (filterResult.length > 0) {
        setDisplayArr(filterResult);
      } else {
        setDisplayArr(phoneNums);
      }
    } catch (error) {
      catchHandler(error, 'searchHandler');
    }
  }

  function onClickHandler(field) {
    if (filterField !== field) {
      setInputState(inputsStateDefault);
      setFilterField(field);
      setDisplayArr(phoneNums);
    }
  }

  return (
        <ServiceBody>
            <Table id="mobile-connections">
              <THead title="мобильная связь">
                <TRow>
                  {headers.map((header) => <TData key={header.field}>{header.title}</TData>)}
                </TRow>
              </THead>
              <TBody>
                <TRow>
                  {headers.map((header) => {
                    const { field } = header;
                    return (
                    <TData key={field}>
                      <input
                        type="text"
                        placeholder=" "
                        className="input search-input"
                        value={inputState[field]}
                        onInput={(e) => searchHandler(e, field)}
                        onClick={() => onClickHandler(field)}
                      />
                    </TData>);
                  })}
                </TRow>
                {displayArr.map((phoneNum) => (
                  <TRow key={phoneNum.id}>
                    {headers.map((header) => <TData key={header.field}>{phoneNum[header.field]}</TData>)}
                  </TRow>
                ))}
              </TBody>
              <TFoot>
                <TRow>
                  <TData onClick={() => tableToExcel('mobile-connections', 'Телефонные номера')}>
                    Скачать в Excel
                  </TData>
                </TRow>
              </TFoot>
            </Table>
        </ServiceBody>
  );
}

export default MobileTable;
