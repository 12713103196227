import React, { useEffect, useState } from 'react';
import { catchHandler } from '../../../utils/error_handling/error_handling';
import { clientSendData } from '../../../utils/functions/requests';
import { alertFunction } from '../../../utils/functions/alertFunction';
import CardBody from '../../../components/Card/CardBody';
import CardFooter from '../../../components/Card/CardFooter';
import CardSetting from '../../../components/Card/CardSetting';
import IconButton from '../../../components/UI/Button/IconButton';
import Button from '../../../components/UI/Button/Button';
import Select from '../../../components/UI/Select';
import Input from '../../../components/UI/Input';
import Toggle from '../../../components/UI/Toggle';
import TextArea from '../../../components/UI/TextArea';
import InputSearch from '../../../components/UI/InputSearch';
import Loader from '../../../components/UI/Loader';

/**
* @component Состояние портала - интерфейс администратора - управление событиями контролей - карточка события
* @prop {event} object - событие, открытое в карточке
* @prop {setEvent} function - обновление состояния события, открытого в карточке
* @prop {handler} function - обработчик действий с событиями (создание/изменение/удаление)
* @prop {setShow} function - обновление состояния отображения карточки (открыть/закрыть)
* @prop {currentControl} object - объект выбранного контроля
* @prop {refreshTable} function - обновление данных таблицы
*/
function EventCardMain(props) {
  const {
    eventId, setShow, handler, closeHandler, refreshTable,
  } = props;

  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [services, setServices] = useState([]); // сервисы
  const [statuses, setStatuses] = useState([]); // статусы
  const [properties, setProperties] = useState({ users: [] }); // объект для записи свойств сервиса
  const [event, setEvent] = useState({});
  const [sendNow, setSendNow] = useState(false);
  const isNewEvent = eventId === 'new';
  const [isSearching, setIsSearching] = useState(false); // состояние загрузки при поиске
  const [selectUsers, setSelectUsers] = useState({});
  const [selectServices, setSelectServices] = useState([]); // сервисы
  const [selectServiceGroup, setSelectServiceGroup] = useState(''); // группа для отправки уведомлений
  const [users, setUsers] = useState([]);
  const [usersGroups, setUsersGroups] = useState([]); // пользователи и группы
  const [serviceGroups, setServiceGroups] = useState([]); // группы
  const [changeLog, setChangeLog] = useState({}); // объект изменений для истории
  let prevEvent;
  let prevProperties;

  useEffect(() => {
    getPsEventCard();
  }, []);

  // useEffect(() => {
  //   if (sendNow === true) {
  //     setProperties((state) => ({ ...state, send_now: true }));
  //   }
  // }, [sendNow]);

  // Получение данных для настройки уведомления
  async function getPsEventCard() {
    try {
      const reqData = {
        type: 'getPsEventCard',
        id: eventId, // id события
      };

      setIsLoading(true);
      const result = await clientSendData('POST', '/get_ps_event_card', reqData);
      if (result) {
        setEvent(result.event);
        // Object.assign(prevEvent, result.event);
        setProperties(result.event.properties);
        // Object.assign(prevProperties, result.event.properties);
        setUsersGroups(result.usersGroups);
        setServices(result.services);
        setSelectServices(result.selectServices);
        setServiceGroups(result.serviceGroups);
        setSelectServiceGroup(result.event.group_title);
        setSendNow(result.event.properties.send_now);
        console.log(result.event.properties.send_now);
        setStatuses(result.statuses);
        setChangeLog({ ...(JSON.parse(JSON.stringify({ ...result.event.properties, ...result.event }))) });
      }
      setIsLoading(false);
    } catch (error) {
      catchHandler(error, 'getPsEventCard');
    }
  }

  // Изменить объект уведомления
  async function changeSetting(setting) {
    setEvent({ ...event, ...setting });
  }

  function chooseData(dataObj, name) {
    const newTitle = {};
    newTitle[`${name}_title`] = dataObj.title;
    const newId = {};
    newId[`${name}_id`] = dataObj.id;
    setEvent((state) => ({ ...state, ...newTitle }));
    setProperties((state) => ({ ...state, ...newId }));
  }

  // Сохранение данных и отправка на маршрут
  async function editEvent(action) {
    try {
      setIsLoading(true);
      const reqData = {
        type: 'editPSEventCard', event, action, properties, changeLog,
      };
      const result = await clientSendData('POST', '/edit_ps_event_card', reqData);
      if (result?.success) {
        if (event.id === 'new') {
          await alertFunction('create_events', 'clientPost');
        } else if (action === 'delete') {
          await alertFunction('delete_event', 'clientPost');
        } else {
          await alertFunction('save_settings', 'clientPost');
        }
        handler();
      }
    } catch (error) {
      catchHandler(error, 'editPSEventCard');
    } finally {
      setIsLoading(false);
    }
  }

  async function deleteEvent() {
    const confirm = window.confirm('Удалить событие?');
    if (!confirm) {
      setIsLoading(false);
    } else {
      editEvent('delete');
      closeHandler();
    }
  }

  async function saveEvent(eventEdit, eventProperties) {
    const objectForVerified = { ...eventEdit, ...eventProperties };
    const requiredFields = ['timestamp_start', 'status_start_id', 'timestamp_end', 'content'].every((key) => objectForVerified[key]);
    if (requiredFields) {
    // Если дата начала равна или наступает после даты завершения - уведомление
      if (eventEdit.timestamp_start >= event.timestamp_end) {
        await alertFunction('wrong_period', 'clientPost');
      } else {
        editEvent('update');
        handler();
        closeHandler();
      }
    }
  }

  // Выбор сервиса
  function chooseServices(service) {
    if (Object.keys(service).length > 0) {
      if (selectServices.filter((el) => el.id === service.id).length === 0) {
        setSelectServices((state) => ([...state, service]));
        const newServices = properties.services;
        newServices.push(service.id);
        setProperties((state) => ({ ...state, services: newServices }));
      }
    }
  }

  // Выбор группы (для дальнейшей отправки писем)
  function chooseServiceGroup(group) {
    if (Object.keys(group).length > 0) {
      if (serviceGroups.filter((el) => el.id === group.id).length > 0) {
        setSelectServiceGroup(group.title);
        setProperties((state) => ({ ...state, sd_group_id: group.id }));
      }
      //  const newServiceGroup = properties.serviceGroups;
      //   newServiceGroup.push(group.id);
      // setProperties((state) => ({ ...state, serviceGroups: newServiceGroup }));
    }
  }

  // Добавление пользователей и группы
  async function addUsersAndGroups(usersAndGroups) {
    if (Object.keys(usersAndGroups).length > 0) {
      if (usersGroups.filter((el) => el.id === usersAndGroups.id).length === 0) {
        setUsersGroups((state) => [...state, usersAndGroups]);
        if (usersAndGroups.type === 'user') {
          const newUsers = properties.users;
          newUsers.push(usersAndGroups.elid);
          setProperties((state) => ({ ...state, users: newUsers }));
          setSelectUsers({});
        } else {
          const newGroups = properties.groups;
          newGroups.push(usersAndGroups.id);
          setProperties((state) => ({ ...state, groups: newGroups }));
          setSelectUsers({});
        }
      }
    }
  }

  // Удалить выбранного пользователя, группу
  async function deleteUserAndGroups(usersAndGoupsId) {
    // Запросить подтверждение
    // const confirm = window.confirm('Удалить?');
    // if (confirm) { // Если подтверждено - удалить из БД
    if (typeof (usersAndGoupsId) === 'number') {
      const filteredGroups = properties.groups.filter((el) => el !== usersAndGoupsId);
      setProperties((state) => ({ ...state, groups: filteredGroups }));
    } else {
      const userId = usersGroups.find((el) => el.id === usersAndGoupsId);
      const filteredUsers = properties.users.filter((el) => el !== userId.elid);
      setProperties((state) => ({ ...state, users: filteredUsers }));
    }
    setUsersGroups(usersGroups.filter((el) => el.id !== usersAndGoupsId));
    // }
  }

  // Удалить выбранный сервис
  async function deleteServices(serviceId) {
    // Запросить подтверждение
    const filteredServices = properties.services.filter((el) => el !== serviceId);
    setProperties((state) => ({ ...state, services: filteredServices }));
    setSelectServices(selectServices.filter((el) => el.id !== serviceId));
  }

  // Удалить выбранную сервис-группу
  async function deleteServiceGroup(groupId) {
    // Запросить подтверждение
    const filteredGroups = properties.sd_group_id !== groupId;
    setProperties((state) => ({ ...state, sd_group_id: filteredGroups }));
    setSelectServiceGroup(selectServiceGroup.filter((el) => el.id !== groupId));
  }

  async function searchWrapper(e) {
    setIsSearching(true); // поиск выполняется
    if (e.target.value.length === 0) setSelectUsers((prev) => ({ ...prev }));
    try {
      if (e.target.value.length > 2) {
        const reqData = {
          type: 'psEventSearchUser',
          query: e.target.value,
        };
        const result = await clientSendData('POST', '/ps_event_search_user', reqData);
        if (result?.success) setUsers(result.data);
        setIsSearching(false);
        return [];
      }
      return [];
    } catch (error) {
      catchHandler(error, 'psEventSearchUser');
      setIsSearching(false);
      return [];
    }
  }

  function addSendNow() {
    setSendNow((prevSendNow) => !prevSendNow);
    setProperties((state) => ({ ...state, send_now: !sendNow }));
  }

  console.log(properties);

  // <Card id="portal-state__event-card" setShow={setShow} loading={isLoading}>
  // <CardHeader />
  if (isLoading) return <CardBody><Loader /></CardBody>;
  return (
    <>

      <CardBody>

        {/* СЕРВИС */}
        <CardSetting title="Сервис" invalid={!selectServices}>
          <Select
            id="portal-state__event-select-service"
            array={services}
            onChoose={(service) => chooseServices(service)}
            defaultValue={event?.service_title}
          />
           <div className="tags__list">
               {selectServices?.map((el) => <p className="tags__tag" key={el.id}>
                 <span onClick={() => deleteServices(el.id)} className="upu__close">X</span>
                   {el.title}
                                            </p>)}
           </div>
        </CardSetting>

         {/* СЕРВИСНАЯ ГРУППА */}
         <CardSetting title="Группа">
          <Select
            id="portal-state__event-select-group"
            array={serviceGroups}
            onChoose={(group) => chooseServiceGroup(group)}
            defaultValue={selectServiceGroup}
          />
         </CardSetting>

        {/* ВРЕМЯ НАЧАЛА */}
        <CardSetting title="Время начала" invalid={!event?.timestamp_start}>
          <Input
            type="datetime-local"
            onChange={(e) => changeSetting({
              timestamp_start: e.target.value,
            })}
            defaultValue={event?.timestamp_start}
          />
        </CardSetting>

        {/* СТАТУС НАЧАЛА */}
        <CardSetting title="Cтатус начала" invalid={!properties?.status_start_id}>
          <Select
            id="portal-state__event-select-status_start"
            array={statuses}
            onChoose={(status) => chooseData(status, 'status_start')}
            defaultValue={event?.status_start_title}
          />
        </CardSetting>

        {/* ВРЕМЯ ЗАВЕРШЕНИЯ */}
        <CardSetting title="Время завершения" invalid={!event?.timestamp_end}>
          <Input
            type="datetime-local"
            onChange={(e) => changeSetting({
              timestamp_end: e.target.value,
            })}
            defaultValue={event?.timestamp_end}
          />
        </CardSetting>

        {/* СТАТУС ЗАВЕРШЕНИЯ */}
        {/* <CardSetting title="Cтатус завершения" invalid={!properties?.status_end_id}>
          <Select
            id="portal-state__event-select-status_end"
            array={statuses}
            onChoose={(status) => chooseData(status, 'status_end')}
            defaultValue={event?.status_end_title}
          />
        </CardSetting> */}

        {/* CООБЩЕНИЕ */}
        <CardSetting title="Сообщение" invalid={!properties?.content}>
          <TextArea
            defaultValue={properties?.content}
            onChange={(e) => setProperties((state) => ({ ...state, content: e.target.value }))}
            placeholder="Сообщение"
          />
        </CardSetting>

                <CardSetting title="Отправить уведомление прямо сейчас">
                  <Toggle
                    state={String(sendNow)}
                    onClick={() => addSendNow()}
                  />
                </CardSetting>

                  <CardSetting title="Отправить уведомление за N дней:">
                    <Input
                      type="text"
                      onChange={(e) => setProperties((state) => ({ ...state, days: e.target.value }))}
                      defaultValue={properties?.days}
                    />
                  </CardSetting>

            {/* ПОЛЬЗОВАТЕЛЬ/ГРУППА */}
           <CardSetting title="Пользователь/группа:" invalid={!usersGroups}>
             <div className="tags__input-block">
               <InputSearch
                 id="inputSearch"
                 placeholder="Введите пользователя/группу"
                 array={users}
                 onSearch={(e) => searchWrapper(e)}
                 onChoose={(user) => setSelectUsers(user)}
                 defaultValue={selectUsers?.title}
               />
               <div onClick={() => (addUsersAndGroups(selectUsers))} className="tags__button">
                 <img className="tags__img" src="../../icons/cancel.svg" alt="add-tag" />
               </div>
             </div>
            <div className="tags__list">
               {usersGroups?.map((el) => <p className="tags__tag" key={el.id}>
                 <span onClick={() => deleteUserAndGroups(el.id)} className="upu__close">X</span>
                   {el.title}
                                         </p>)}
            </div>
           </CardSetting>

        {/* УДАЛЕНИЕ */}
        {!isNewEvent && (
        <CardSetting title="Удаление">
          <IconButton onClick={() => deleteEvent()} icon="delete" theme="red" />
        </CardSetting>)}

      </CardBody>
      <CardFooter>
        <Button onClick={() => saveEvent(event, properties)}>Сохранить</Button>
        <Button onClick={closeHandler}>Отменить</Button>
      </CardFooter>
    </>
  // </Card>
  );
}

export default EventCardMain;
