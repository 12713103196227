// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#video-conference_table .service__body {
  height: calc(100vh - 100px);
}

#video-conference__card .form-control {
  width: 300px;
  max-width: 500px;
  min-height: 32px;
  height: 40px;
  padding: 4px 12px;
  border: none;
  border-radius: 4px;
  outline: none;
  box-shadow: 1px 1px 2px 0 #0f1955 inset;
  transition: 0.3s;
  word-break: break-word;
  font-size: 1.05rem;
  -webkit-user-select: none;
  user-select: none;
}
#video-conference__card .input {
  height: 40px;
}
#video-conference__card .tags {
  width: 100%;
  max-width: 500px;
}
#video-conference__card .tags__input-block {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  height: 40px;
  max-width: 500px;
}
#video-conference__card .custom-select {
  width: 300px;
  max-width: 550px;
}
#video-conference__card .custom-select .input {
  width: 100%;
  max-width: 500px;
  height: 40px;
}`, "",{"version":3,"sources":["webpack://./src/pages/VideoConferences/videoConference.scss"],"names":[],"mappings":"AAGI;EACI,2BAAA;AAFR;;AAOI;EACI,YAAA;EACA,gBAAA;EACA,gBAAA;EACA,YAAA;EACA,iBAAA;EACA,YAAA;EACA,kBAAA;EACA,aAAA;EACA,uCAAA;EACA,gBAAA;EACA,sBAAA;EACA,kBAAA;EACA,yBAAA;EACA,iBAAA;AAJR;AAMI;EACI,YAAA;AAJR;AAMI;EACI,WAAA;EACA,gBAAA;AAJR;AAMQ;EACI,aAAA;EACA,qBAAA;EACA,8BAAA;EACA,YAAA;EACA,gBAAA;AAJZ;AAQQ;EAEI,YAAA;EACA,gBAAA;AAPZ;AASY;EACI,WAAA;EACA,gBAAA;EACA,YAAA;AAPhB","sourcesContent":["@use \"../../variables\" as var;\n\n#video-conference_table {\n    .service__body {\n        height: calc(100vh - 100px);   \n    }\n}\n\n#video-conference__card {\n    .form-control{\n        width: 300px;\n        max-width: 500px;\n        min-height: 32px;\n        height: 40px;\n        padding: 4px 12px;\n        border: none;\n        border-radius: 4px;\n        outline: none;\n        box-shadow: 1px 1px 2px 0 #0f1955 inset;\n        transition: 0.3s;\n        word-break: break-word;\n        font-size: 1.05rem;\n        -webkit-user-select: none;\n        user-select: none;\n    }\n    .input {\n        height: 40px;\n    }\n    .tags {\n        width: 100%;\n        max-width: 500px;\n\n        &__input-block {\n            display: flex;\n            flex-flow: row nowrap;\n            justify-content: space-between;\n            height: 40px;\n            max-width: 500px;\n          }\n        }\n    \n        .custom-select {\n    \n            width: 300px;\n            max-width: 550px;\n\n            .input {\n                width: 100%;\n                max-width: 500px;\n                height: 40px;\n            }\n            \n        }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
