import React, { useEffect } from 'react';

/**
* @component Панель вкладок карточки
@prop {tabs} array (Обязательный) массив строк с наименованиями вкладок
@prop {choiceHandler} function (Обязательный) обработчик выбора
@prop {choice} string (Обязательный) текущий выбор
@prop {validator} function функция валидации значения на вкладке (подсветка красным если нет значения)
*/

function TabPanel(props) {
  const {
    tabs, choice, choiceHandler, validator,
  } = props;

  useEffect(() => {
    choiceHandler(tabs[0]); // установка выбора по умолчанию - первая вкладка
  }, []);

  // Определение класса стилей
  const defineClass = (tab) => {
    let result = 'tab-panel__tab';
    if (validator && !validator(tab)) result += ' tab-panel__tab_invalid';
    if (choice === tab) result += ' tab-panel__tab_active';
    return result;
  };

  return (
    <div className="tab-panel">
        {tabs.filter((tab) => tab).map((tab) => (
            <div key={tab} className={defineClass(tab)} onClick={() => choiceHandler(tab)}>
                {tab}
            </div>
        ))}
    </div>
  );
}

export default TabPanel;
