import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { authorization } from '../../utils/functions/authenticate';
import { createAction } from '../../utils/redux/store';
import { setOperInfo, log } from '../../utils/functions/others';
import {
  filterCategories,
  filterQuestionnairies,
  filterQuestions,
  getUserDirections,
  setCurrentDirection,
  userDirectionDefault,
} from './functions';
import ErrorBoundary from '../../utils/error_handling/ErrorBoundary';
import ServicePanel from '../../components/Service/ServicePanel';
import Questionnairies from './Questionnairies/Questionnairies';
import Questions from './Questions/Questions';
import Directions from './Directions/Directions';
import Categories from './Categories/Categories';
import Select from '../../components/UI/Select';
import Loader from '../../components/UI/Loader';
import Button from '../../components/UI/Button/Button';
import Cap from '../Cap/Cap';
import classes from '../../components/UI/Button/button.module.scss';
import './questionnaire.scss';

// Сервис анкетирования - главная страница
function Questionnaire() {
  const location = useLocation(); // адрес
  const history = useNavigate(); // история

  const [isLoading, setIsLoading] = useState(); // Состояние загрузки
  const [isAuthorized, setIsAuthorized] = useState(false); // Состояние авторизации
  const [choose, setChoose] = useState('questionnairies'); // Состояние выбора сервиса
  const currentServices = useSelector((state) => state.services.user_services); // сервисы, доступные пользователю
  const userDirections = useSelector((state) => state.questionnaire.user_directions); // направления доступные пользователю
  const currentDirection = useSelector((state) => state.questionnaire.current_direction); // текущее направление
  const questionnairies = useSelector((state) => state.questionnaire.questionnairies); // анкеты доступные пользователю
  const questions = useSelector((state) => state.questionnaire.questions); // вопросы
  const categories = useSelector((state) => state.questionnaire.categories); // категории

  const editDirectionAccess = currentServices.find((item) => item.link === '/questionnaire/edit_directions'); // доступ к редактированию групп
  const tabs = [
    { id: 2, title: 'Категории', tab: 'categories' },
    { id: 3, title: 'Вопросы', tab: 'questions' },
    { id: 4, title: 'Анкеты', tab: 'questionnairies' },
  ];
  if (editDirectionAccess) tabs.unshift({ id: 1, title: 'Направления', tab: 'directions' });

  useEffect(() => {
    setOperInfo();
    awaitRequests();
  }, []);

  // Ожидание выполнения асинхронных функций
  async function awaitRequests() {
    setIsLoading(true);
    const checkAuthorization = await authorization(); // авторизация
    setIsAuthorized(checkAuthorization);
    if (checkAuthorization) await getUserDirections(location.pathname); // получение направлений, доступных пользователю
    setIsLoading(false);
  }

  // Обработчик выбора вкладки
  function chooseHandler(tab) {
    setChoose(tab); // установить состояние выбора
    clearStore(tab); // очистить данные вкладок в хранилище
    log({ message: `Пользователь выбрал вкладку ${tab}`, eventtype: 'info' });
  }

  // Очистка данных хранилища
  function clearStore(tab) {
    if (choose !== tab) {
      createAction('SET_QUESTIONNAIRIES', []); // анкеты
      createAction('SET_DISPLAYED_QUESTIONNAIRIES', []); // отображаемые анкеты
      createAction('SET_CATEGORIES', []); // категории
      createAction('SET_DISPLAYED_CATEGORIES', []); // отображаемые категории
      createAction('SET_QUESTIONS', []); // вопросы
      createAction('SET_DISPLAYED_QUESTIONS', []); // отображаемые вопросы
    }
  }

  // Выбор направления
  function chooseDirection(direction) {
    // установить текущее направление и обновить связанные с ним имена
    setCurrentDirection(direction);

    if (direction.id === 'all') {
      createAction('SET_DISPLAYED_QUESTIONNAIRIES', questionnairies); // обновление состояние отображаемых анкет
      createAction('SET_DISPLAYED_QUESTIONS', questions); // обновление состояние отображаемых вопросов
      createAction('SET_DISPLAYED_CATEGORIES', categories); // обновление состояние отображаемых категорий
    } else {
      filterCategories(categories, direction); // отфильтровать категории
      filterQuestionnairies(questionnairies, direction); // отфильтровать анкеты
      filterQuestions(questions, direction); // отфильтровать вопросы
    }
    log({ message: `Пользователь выбрал направление "${direction.title}"`, eventtype: 'info' });
  }

  if (isLoading) return <Loader />;
  if (isAuthorized) { // если авторизован
    // если пользователю доступны какие либо направления
    // или доступных направлений нет, но есть доступ к их редактированию
    if (userDirections.length > 0 || (userDirections.length === 0 && editDirectionAccess === 1)) {
      return (
        <div id="questionnaire" className="service service-vertical">
          <ServicePanel id="questionnaire__panel">
            <Select
              id="questionnaire__main-select"
              array={[userDirectionDefault, ...userDirections]}
              onChoose={chooseDirection}
              defaultValue={currentDirection.title}
            />

            {tabs.map((item) => {
              const { id, title, tab } = item;
              const { button, button_ghost } = classes;
              const buttonClass = choose === tab ? button : button_ghost;
              return (
                <Button key={id} className={buttonClass} onClick={() => chooseHandler(tab)}>
                  {title}
                </Button>
              );
            })}
          </ServicePanel>
          {/* Отображение компонента в зависимости от выбранной вкладки */}
          { choose === 'directions' && editDirectionAccess && (
          <ErrorBoundary><Directions /></ErrorBoundary>)}
          { choose === 'categories' && (
          <ErrorBoundary><Categories /></ErrorBoundary>)}
          { choose === 'questions' && (
          <ErrorBoundary><Questions /></ErrorBoundary>)}
          { choose === 'questionnairies' && (
          <ErrorBoundary><Questionnairies /></ErrorBoundary>)}
        </div>
      );
    } return (
      <div className="questionnaire__cap">
        <h2 className="upu-service__title">Отсутствуют доступные направления </h2>
        <p className="clickable" onClick={() => history(-1)}>Вернуться назад</p>
      </div>
    );
  } return <Cap />; // иначе заглушка
}

export default Questionnaire;
