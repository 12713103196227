import React, { useState } from 'react';
import TabPanel from '../../../components/UI/TabPanel';
import { setOperInfo } from '../../../utils/functions/others';
import Logs from './Logs';
import Settings from './Settings';
import Card from '../../../components/Card/Card';
import CardHeader from '../../../components/Card/CardHeader';
import CardBody from '../../../components/Card/CardBody';

// Сервис редактирования направлений - карточка направления
function DirectionCard(props) {
  const {
    direction,
    setDirection,
    setShowCard,
    getDirections,
  } = props; // свойства передаваемые компоненту при вызове

  const [choice, setChoice] = useState(''); // состояние выбора вкладки
  const tabs = ['Настройки', 'История']; // вкладки

  // Отменить редактирование или создание и закрыть карточку
  function cancelAndClose() {
    setShowCard(false); // закрыть карточку
    setOperInfo({ subject_id: null }); // сбросить id направления в оперативной информации
  }

  return (
    <Card id="questionnaire__direction-card" setShow={cancelAndClose}>
      <CardHeader>
        <TabPanel tabs={tabs} choice={choice} choiceHandler={setChoice} />
      </CardHeader>
      <CardBody>
        {choice === 'Настройки' && (
        <Settings // Вкладка Настройки
          getDirections={getDirections}
          setShowCard={setShowCard}
          direction={direction}
          setDirection={setDirection}
        />)}

        {choice === 'История' && (
        <Logs // Вкладка Истории
          id={direction.id}
        />)}
      </CardBody>
    </Card>
  );
}

export default DirectionCard;
