import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { catchHandler } from '../../../../utils/error_handling/error_handling';
import { alertFunction } from '../../../../utils/functions/alertFunction';
import { clientSendData } from '../../../../utils/functions/requests';
import { clearHtmlString } from '../../../../utils/functions/stringHandling';
import TableWrapper from '../../Components/TableWrapper/TableWrapper';
import Loader from '../../../../components/UI/Loader';
import Canvas from '../../Components/Canvas/Canvas';

/**
* @component Сервис отчётов по СервисДеску - Количество запросов по компаниям и их выполнение со стороны исполнителя
*/
function AllCompanyTicketsSolved() {
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [rawReport, setRawReport] = useState({}); // сырые данные отчёта
  const [searchParams, setSearchParams] = useSearchParams(); // параметры ссылки
  const start_date = searchParams.get('start_date'); // дата начала
  const end_date = searchParams.get('end_date'); // дата окончания
  const period = !!(start_date && end_date); // наличие периода
  const isShowChart = useSelector((state) => state.reports.is_show_chart);// состояние видимости графика
  const title = 'Количество запросов по компаниям и их выполнение со стороны исполнителя';
  const [report, setReport] = useState([]); // готовые данные отчёта

  useEffect(() => {
    getReportAllCompanyTicketsSolved(); // получить отчёт запросов по компаниям и их выполнение со стороны исполнителя
  }, []);

  // получить отчёт по количеству запросов по компаниям и их выполнение со стороны исполнителя
  async function getReportAllCompanyTicketsSolved() {
    try {
      const reqData = {
        type: 'getSdAllCompanyTicketsAndSolved',
        period,
        start_date,
        end_date,
      };
      setIsLoading(true); // состояние - загружается
      const result = await clientSendData('POST', '/get_sd_all_company_tickets_and_solved', reqData);
      if (result === 'bad_request') { // если некорректный запрос
        alertFunction('bad_request', 'clientPost'); // уведомление
        setIsLoading(false); // загрузка завершена
      } else {
        setRawReport(result);
        createReport(result);
      }
    } catch (error) {
      catchHandler(error, 'getSdAllCompanyTicketsAndSolved');
      setIsLoading(false); // загрузка завершена
    }
  }

  // Создание отчёта на основе полученных данных
  function createReport(report) {
    setReport(report);
    setIsLoading(false); // загрузка завершена
  }

  if (isLoading) return <Loader />;
  if (isShowChart) {
    const sortedReport = report.slice().sort((a, b) => a.tickets - b.tickets);
    return (
      <Canvas
        id="reports-sd-all-company-tickets-solved__canvas"
        title={title}
        type="bar"
        label="Решенных"
        label2="Всего"
        labels={sortedReport.map((row) => clearHtmlString(row.company))}
        values={sortedReport.map((row) => row.solve_tickets)}
        values2={sortedReport.map((row) => row.tickets)}
      />
    );
  }
  return (
    <TableWrapper id="reports-sd-all-company-tickets-solved" title={title} back_button chart_button>
      <table id="reports-sd-all-company-tickets-solved" className="upu-table">
        <thead className="upu-table__table-head">
          <tr className="upu-table__table-row">
          <td className="upu-table__table-data">Компания</td>
            <td className="upu-table__table-data">Общее количество запросов</td>
            <td className="upu-table__table-data">Количество выполненных запросов</td>
          </tr>
        </thead>
        <tbody className="upu-table__table-body">
          {/* Проходим по отчёту */}
          {report.map((company) => (
              // Выводим строки
              <tr key={company.id} className="upu-table__table-row">
                {/* Первая ячейка - название компании */}
                <td className="upu-table__table-data">{company.company}</td>
                <td className="upu-table__table-data">{company.tickets}</td>
                <td className="upu-table__table-data">{company.solve_tickets}</td>
              </tr>
          ))}
            <tr className="upu-table__table-row">
            <td className="upu-table__table-data">Итого</td>
            <td className="upu-table__table-data">{report.reduce((total, value) => total + value.tickets, 0)}</td>
            <td className="upu-table__table-data">{report.reduce((total, value) => total + value.solve_tickets, 0)}</td>
            </tr>
        </tbody>
        <tfoot className="upu-table__table-foot" />
      </table>
    </TableWrapper>
  );
}

export default AllCompanyTicketsSolved;
