import React from 'react';

/**
* @component Тело таблицы
*/

function TBody(props) {
  const { children } = props;

  return <tbody className="upu-table__table-body">{children}</tbody>;
}

export default TBody;
