/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';
import { setOperInfo } from '../../utils/functions/others';
import { clientSendData } from '../../utils/functions/requests';
import { catchHandler } from '../../utils/error_handling/error_handling';
import { authorization } from '../../utils/functions/authenticate';
import Service from '../../components/Service/Service';
import Loader from '../../components/UI/Loader';
import ParamTable from './ParamTable';
import Scheduler from './Scheduler';
import Cap from '../Cap/Cap';
import './adminPanel.scss';

/**
 * @component Панель настроек портала и сервисов
*/
function AdminPanel() {
  const [isLoading, setIsLoading] = useState(false); // Cостояние загрузки
  const [isAuthorized, setIsAuthorized] = useState(false); // Состояние авторизации
  const [paramTables, setParamTables] = useState([]); // таблицы параметров
  const pages = paramTables.map((table) => {
    const { id, title, param_table } = table;
    return (param_table.includes('_param'))
      ? { id, title, component: <ParamTable title={title} table={param_table} /> }
      : { id, title, component: <Scheduler title={title} table={param_table} /> };
  });

  useEffect(() => {
    setOperInfo();
    awaitRequests();
  }, []);

  async function awaitRequests() {
    setIsLoading(true);
    const checkAuthorization = await authorization(); // авторизация
    setIsAuthorized(checkAuthorization);
    if (checkAuthorization) await getParamTables();
    setIsLoading(false);
  }

  // Получить таблицы с параметрами
  async function getParamTables() {
    try {
      const reqData = { type: 'getParamTables' };
      const result = await clientSendData('POST', '/get_param_tables', reqData); // запрос в БД
      if (result) setParamTables(result);
    } catch (error) {
      catchHandler(error, 'get_param_tables');
    }
  }

  // лоадер во время загрузки
  if (isLoading) return <Loader />;
  // если авторизован - показать сервис
  if (isAuthorized) return <Service id="admin-panel" pages={pages} horizontal />;
  // иначе показать заглушку
  return <Cap />;
}

export default AdminPanel;
