import React from 'react';
import { useSelector } from 'react-redux';
import InputButtonSearch from '../../../../../components/UI/InputButtonSearch';
import Categories from './Categories';
import { createAction } from '../../../../../utils/redux/store';
import { saveAll, saveCurrent, searchHandler } from '../../functions';
import FinishButton from '../../FinishButton';
import DownloadChoice from '../../../../../components/UI/DownloadChoice';

// Страница интервью - панель выбора вопросов
function Panel() {
  const interview = useSelector((state) => state.interview.interview);
  const isWiki = useSelector((state) => state.interview.is_wiki);
  const isShowFormMobile = useSelector((state) => state.interview.is_show_form_mobile);
  const currentQuestion = useSelector((state) => state.interview.current_question);
  const { questionnaire, questions, answers } = interview;

  const actions = [
    { title: 'Скачать текущий', handler: (format) => saveCurrent(format, answers, currentQuestion) },
    { title: 'Скачать всё', handler: (format) => saveAll(format, interview) },
  ];
  const options = ['doc'];

  return (
    <div /* Категории */
      id={isShowFormMobile ? 'interview__form_active' : 'interview__form'}
      className="upu-service__form"
      onClick={() => createAction('SET_IS_SHOW_FORM_MOBILE', false)}
    >
        {isWiki && (// Поле поиска
        <div className="interview__search">
        <InputButtonSearch searchHandler={(string) => searchHandler(string, questions)} />
        </div>)}

        {questionnaire.downloadable && (
        <DownloadChoice // Меню скачивания
          actions={actions}
          options={options}
        />)}

        {/* Категории */}
        <Categories />
        {/* Завершить опрос */}
        <FinishButton />
    </div>
  );
}

export default Panel;
