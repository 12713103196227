import React, { useEffect, useState } from 'react';
import '../table.scss';
import TData from '../TData';

/**
  * @component Строка пагинации
  * @prop {number} total Общее количество строк
  * @prop {TableState} state текущее состояние отображения таблицы
  * @prop {function} setState обновление состояния отображения таблицы
  * @prop {OptButton} [optButton] Опциональная кнопка слева в строке пагинации
  *
  * @typedef {object} OptButton опциональная кнопка
  * @property {string} title Название кнопки
  * @property {function} handler Обработчик нажатия кнопки
  * @example { title: 'Добавить', handler: () => {} }
  */
function PaginationRow(props) {
  const {
    total, state, setState, optButton,
  } = props;
  const { page = 1, limit = 100 } = state;

  const [pages, setPages] = useState([]); // массив страниц
  const [step, setStep] = useState(0); // текущий шаг (при большом количестве страниц)
  const numberOfpages = 10; // количество страниц для выбора

  // Обработка выбора страницы
  function chooseHandler(number) {
    setState((prev) => ({ ...prev, page: number }));
  }

  useEffect(() => {
    countPages(); // пересчёт страниц при изменении состояния таблицы
  }, [state, total]);

  // Создать массив из номеров страниц
  function countPages() {
    const ratio = total / limit; // отношение длины к лимиту
    const pagesAmount = Math.ceil(ratio); // необходимое количество страниц
    // если отношение больше 1 - записывается массив чисел длиной ratio округлённого в большую сторону
    if (ratio > 1) setPages([...Array(pagesAmount).keys()]);
    // иначе записывается пустой массив
    else setPages([]);
  }

  // Обработчик шагв
  function stepHandler(direction) {
    switch (direction) {
      case '<': {
        if (step * numberOfpages - numberOfpages < 0) return setStep(0);
        return setStep(step - 1);
      }
      case '<<': {
        if (step * numberOfpages - numberOfpages * 10 < 0) return setStep(0);
        return setStep(step - 10);
      }
      case '>': {
        if (step * numberOfpages + numberOfpages > pages.length) return setStep(step);
        return setStep(step + 1);
      }
      case '>>': {
        if (step * numberOfpages + numberOfpages * 10 > pages.length) return setStep(step);
        return setStep(step + 10);
      }
      default: return null;
    }
  }

  // Если страницы отсутствуют, компонент не покажется
  if ((!pages || pages.length === 0) && !optButton) return null;
  const lastPage = pages.length;
  const showFirst = step > 0;
  const showLast = step * numberOfpages + numberOfpages < lastPage;
  return (
    <tr className="upu-table__pagination-row">
        {showFirst && <TData onClick={() => stepHandler('<<')}>{'<<'}</TData>}
        {showFirst && <TData onClick={() => stepHandler('<')}>{'<'}</TData>}
        {showFirst && <TData onClick={() => chooseHandler(1)} active={page === 1}>1</TData>}
        {showFirst && <TData>...</TData>}

        {pages.slice(step * numberOfpages, step * numberOfpages + numberOfpages).map((index) => {
          const pageNumber = index + 1; // номер страницы
          return (
            <TData key={index} onClick={() => chooseHandler(pageNumber)} active={page === pageNumber}>
              {pageNumber}
            </TData>
          );
        })}

        {showLast && <TData>...</TData>}
        {showLast && <TData onClick={() => chooseHandler(lastPage)} active={page === lastPage}>{lastPage}</TData>}
        {showLast && <TData onClick={() => stepHandler('>')}>{'>'}</TData>}
        {showLast && <TData onClick={() => stepHandler('>>')}>{'>>'}</TData>}
        {/* ОПЦИОНАЛЬНАЯ КНОПКА */}
        {optButton && <td onClick={optButton.handler} className="upu-table__table-button pagination-opt-button">{optButton.title}</td>}
    </tr>
  );
}

export default PaginationRow;
