import React, { useEffect, useState } from 'react';
import Cap from '../Cap/Cap';
import {
  taskTypes,
  contractTypes,
  groups,
  cities,
  offices,
  officesAp,
} from './common';
import CardHeader from '../../components/Card/CardHeader';
import CardBody from '../../components/Card/CardBody';
import Card from '../../components/Card/Card';
import CardSetting from '../../components/Card/CardSetting';
import Input from '../../components/UI/Input';
import TextArea from '../../components/UI/TextArea';
import Select from '../../components/UI/Select';
import CardFooter from '../../components/Card/CardFooter';
import Button from '../../components/UI/Button/Button';
import Toggle from '../../components/UI/Toggle';
import { clientSendData } from '../../utils/functions/requests';
import { catchHandler } from '../../utils/error_handling/error_handling';
import { alertFunction } from '../../utils/functions/alertFunction';
import CheckBox from '../../components/UI/CheckBox';

/**
  * @component Заявка - карточка создания заявки на новое рабочее место
  * @prop {setShowCard} function - обновление состояния отображения карточки (открыть/закрыть)
*/

function NewWorkPlace() {
  const [newWorkPlaces, setNewWorkPlaces] = useState({}); // объект с данными заявки
  const [permission, setPermission] = useState(false); // проверка доступа к форме
  const [userData, setUserData] = useState({}); // объект с пользователем
  const [isActive, setIsActive] = useState(false);
  const [isActive1, setIsActive1] = useState(false);
  const [isActive2, setIsActive2] = useState(false);
  const clearFilelds = {
    fio: '',
    position: '',
    department: '',
    comment: '',
    date_start: '',
    date_end: '',
    mobile: '',
    date_end_access: '',
    group: '',
  };
  const nameFields = {
    taskType: 'Тип заявки',
    contractType: 'Договор',
    fio: 'Полное имя сотрудника',
    position: 'Должность',
    department: 'Подразделение',
    company: 'Компания',
    city: 'Город',
    date_start: 'Дата выхода сотрудника',
    group: 'Группа бронирования',
    mobile: 'Номер мобильного телефона',
    date_end: 'Дата завершения',
    office: 'Офис',
    software: 'Установить программное обеспечение',
    sim: 'Выдать сим-карту',
    permanent: 'Удаленное рабочее место на постоянной основе',
    temporary: 'Удаленное рабочее место до выдачи техники',
    date_end_access: 'Дата завершения действия доступа',
    comment: 'Комментарий',
    device: 'Выдать корпоративную технику',
    software_sap: 'Установить программное обеспечение',
    software_zoom: 'Установить программное обеспечение',
  };

  useEffect(() => {
    getDataForNewWorkPlace();
  }, []);

  async function getDataForNewWorkPlace() {
    try {
      const reqData = { type: 'getDataForNewWorkPlace' };
      const result = await clientSendData('POST', '/get_data_for_new_workplace', reqData);
      if (result?.success) {
        setUserData(result.data);
        const updatedCompany = result.data.company === 'Группа Юникон' ? `${result.data.company} АП` : result.data.company;
        setNewWorkPlaces({ company: updatedCompany });
        setPermission(result.permission);
      }
    } catch (error) {
      catchHandler(error, 'getDataForNewWorkPlace');
    }
  }

  async function createNewWorkPlace() {
    try {
      const validateTicket = ['fio', 'position', 'department', 'city', 'taskType', 'contractType'].every((key) => newWorkPlaces[key]);
      if (validateTicket) {
        const ticket = {
          initiator_id: userData.id, status_id: 1, title: `РМ_${newWorkPlaces.fio}_${newWorkPlaces.company}_${newWorkPlaces.date_start}`, source_type: 'web_new_work_place',
        };
        let content = '';
        Object.keys(newWorkPlaces).forEach((el) => {
          content += `${nameFields[el]}: ${newWorkPlaces[el]}\n`;
        });
        ticket.content = content;
        ticket.category_id = 163;
        const reqData = { type: 'createTicket', ticket };
        const result = await clientSendData('POST', '/create_ticket/manual', reqData);
        if (result?.success) {
          await alertFunction('ticket_created', 'clientPost');
          getDataForNewWorkPlace();
          setNewWorkPlaces(clearFilelds);
        }
      } else {
        await alertFunction('required_fields', 'clientPost');
      }
    } catch (error) {
      catchHandler(error, 'getCommonTicketData');
    }
  }

  if (permission) {
    return (
    <Card setShow={() => {}}>
    <CardHeader>
          <h3>Заявка на новое рабочее место</h3>
    </CardHeader>
    <CardBody>
      {/* -----------ИМЯ----------- */}
      <CardSetting title="Полное имя сотрудника:" invalid={!newWorkPlaces.fio}>
        <Input
          value={newWorkPlaces?.fio || ''}
          placeholder="Введите ФИО"
          onChange={(e) => setNewWorkPlaces((state) => ({ ...state, fio: e.target.value }))}
        />
      </CardSetting>
      {/* -----------ДОЛЖНОСТЬ----------- */}
      <CardSetting title="Должность:" invalid={!newWorkPlaces.position}>
        <Input
          value={newWorkPlaces?.position || ''}
          placeholder="Введите должность"
          onChange={(e) => setNewWorkPlaces((state) => ({ ...state, position: e.target.value }))}
        />
      </CardSetting>
      {/* -----------ПОДРАЗДЕЛЕНИЕ----------- */}
      <CardSetting title="Подразделение:" invalid={!newWorkPlaces.department}>
        <Input
          value={newWorkPlaces?.department || ''}
          placeholder="Введите подразделение"
          onChange={(e) => setNewWorkPlaces((state) => ({ ...state, department: e.target.value }))}
        />
      </CardSetting>
       {/* -----------ГОРОД----------- */}
       <CardSetting title="Город:" invalid={!newWorkPlaces.city}>
        <Select
          id="city"
          array={cities}
          onChoose={(city) => setNewWorkPlaces((state) => ({ ...state, city: city.title }))}
          defaultValue={newWorkPlaces.city || 'Выберите город'}
          search
        />
       </CardSetting>

      {/* -----------ТИП ЗАЯВКИ----------- */}
      <CardSetting title="Тип заявки:" invalid={!newWorkPlaces.taskType}>
      <Select
        id="request-type"
        array={taskTypes}
        onChoose={(taskType) => setNewWorkPlaces((state) => ({ ...state, taskType: taskType.title }))}
        defaultValue={newWorkPlaces.taskType || 'Выберите тип заявки'}
      />
      </CardSetting>
      {/* -----------ТИП ДОГОВОРА----------- */}
      <CardSetting title="Тип договора:" invalid={!newWorkPlaces.contractType}>
      <Select
        id="contract-type"
        array={contractTypes}
        onChoose={(contractType) => setNewWorkPlaces((state) => ({ ...state, contractType: contractType.title }))}
        defaultValue={newWorkPlaces.contractType || 'Выберите тип договора'}
      />
      </CardSetting>

      {/* -----------ДАТА ВЫХОДА СОТРУДНИКА----------- */}
        <CardSetting title="Дата выхода:">
        <Input
          type="date"
          value={newWorkPlaces?.date_start || ''}
          onChange={(e) => setNewWorkPlaces({ ...newWorkPlaces, date_start: e.target.value })}
        />
        </CardSetting>

{ newWorkPlaces.taskType === 'Создание рабочего места' && newWorkPlaces.contractType === 'Штатный сотрудник' && newWorkPlaces.city === 'Москва' && userData.company === 'Юникон Акционерное Общество' && (
       <>
      {/* -----------Группа бронирования рабочего места----------- */}
      <CardSetting title="Группа бронирования рабочего места:">
        <Select
          id="group"
          array={groups}
          onChoose={(group) => setNewWorkPlaces((state) => ({ ...state, group: group.title }))}
          defaultValue={newWorkPlaces.group || 'Выберите группу'}
        />
      </CardSetting>
       </>
)}
         { newWorkPlaces.taskType === 'Создание рабочего места' && newWorkPlaces.contractType === 'Договор подряда' && (
       <>
      {/* -----------МОБИЛЬНЫЙ ТЕЛЕФОН----------- */}
      <CardSetting title="Номер мобильного телефона:">
      <Input
        value={newWorkPlaces?.mobile || ''}
        placeholder="Введите номер мобильного телефона"
        onChange={(e) => setNewWorkPlaces({ ...newWorkPlaces, mobile: e.target.value })}
      />
      </CardSetting>
       </>
         )}

      {(newWorkPlaces.contractType === 'Договор подряда') && (
       <>
      {/* -----------ДАТА ЗАВЕРШЕНИЯ----------- */}
      <CardSetting title="Дата завершения:">
        <Input
          type="date"
          value={newWorkPlaces?.date_end || ''}
          onChange={(e) => setNewWorkPlaces({ ...newWorkPlaces, date_end: e.target.value })}
        />

      </CardSetting>
       </>
      )}
    {(newWorkPlaces.taskType !== 'Продление учетной записи' || newWorkPlaces.contractType === 'Договор подряда') && (
       <>
       {/* -----------КОРПОРАТИВНАЯ ТЕХНИКА----------- */}
       <CardSetting title="Выдать корпоративную технику">
       <Toggle
         id="device"
         onClick={() => {
           setIsActive(!isActive);
           setIsActive1(false);
           setNewWorkPlaces({ ...newWorkPlaces, device: 'Да' });
         }}
         state={isActive.toString()}
       />
       </CardSetting>

       {isActive && (
        <>
        {(userData.company === 'Юникон Акционерное Общество' || userData.company === 'Юникон Бизнес Солюшнс Акционерное Общество') && (
        <>
            {/* -----------ОФИС----------- */}
            <CardSetting title="Офис:">
        <Select
          id="office"
          array={offices}
          onChoose={(office) => setNewWorkPlaces((state) => ({ ...state, office: office.title }))}
          defaultValue={newWorkPlaces.office || 'Выберите офис'}
        />
            </CardSetting>
        </>
        )}

        {(userData.company === 'Группа Юникон') && (
          <>
              {/* -----------ОФИС АП----------- */}
              <CardSetting title="Офис:">
          <Select
            id="office"
            array={officesAp}
            onChoose={(office) => setNewWorkPlaces((state) => ({ ...state, office: office.title }))}
            defaultValue={newWorkPlaces.office || 'Выберите офис'}
          />
              </CardSetting>
          </>
        )}

      {/* ----------ПРОГРАММНОЕ ОБЕСПЕЧЕНИЕ----------- */}
      <CardSetting title="Установить следующее программное обеспечение:" />

      {/* -----------SAP----------- */}
      <CardSetting title="SAP">
      <CheckBox onClick={() => setNewWorkPlaces((state) => ({ ...state, software_sap: 'SAP' }))} />
      </CardSetting>

      {/* -----------ZOOM----------- */}
      <CardSetting title="ZOOM">
      <CheckBox onClick={() => setNewWorkPlaces((state) => ({ ...state, software_zoom: 'ZOOM' }))} />
      </CardSetting>
        </>
       )}
       {(userData.company === 'Юникон Бизнес Солюшнс Акционерное Общество') && (
       <>
      {/* -----------СИМ-КАРТА----------- только для сотрудников БСЛ АО */}
      <CardSetting title="Выдать сим-карту">
      <CheckBox onClick={() => setNewWorkPlaces((state) => ({ ...state, sim: 'Да' }))} />
      </CardSetting>
       </>
       )}
       </>
    )}

{(userData.company === 'Юникон Акционерное Общество') && (
       <>
      {/* -----------УРП ПОСТОЯННО----------- только для сотрудников Юникон АО */}
      <CardSetting title="Удаленное рабочее место на постоянной основе">
      <Toggle
        onClick={() => {
          setIsActive1(!isActive1);
          setIsActive(false);
          setNewWorkPlaces({ ...newWorkPlaces, permanent: 'Да' });
        }}
        state={isActive1.toString()}
      />
      </CardSetting>

      {/* -----------УРП ВРЕМЕННО----------- только для сотрудников Юникон АО */}
      <CardSetting title="Удаленное рабочее место до выдачи техники">
      <Toggle
        onClick={() => {
          setIsActive2(!isActive2);
          setNewWorkPlaces({ ...newWorkPlaces, temporary: 'Да' });
        }}
        state={isActive2.toString()}
      />
      </CardSetting>

      {isActive2 && (
        <>
      {/* -----------ДАТА ЗАВЕРШЕНИЯ УРП----------- */}
      <CardSetting title="Дата завершения действия доступа:">
        <Input
          type="date"
          value={newWorkPlaces?.date_end_access || ''}
          onChange={(e) => setNewWorkPlaces({ ...newWorkPlaces, date_end_access: e.target.value })}
        />
      </CardSetting>
        </>
      )}
       </>
)}
      {/* -----------СООБЩЕНИЕ----------- */}
      <CardSetting title="Комментарий:">
        <TextArea
          value={newWorkPlaces?.comment || ''}
          onChange={(e) => setNewWorkPlaces({ ...newWorkPlaces, comment: e.target.value })}
        />
      </CardSetting>
    </CardBody>
    <CardFooter>
      <Button onClick={createNewWorkPlace}>Создать</Button>
      <Button onClick={() => setNewWorkPlaces(clearFilelds)}>
Очистить

      </Button>
    </CardFooter>
    </Card>
    );
  } return <Cap />;
}

export default NewWorkPlace;
