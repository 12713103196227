import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { catchHandler } from '../../../utils/error_handling/error_handling';
import { alertFunction } from '../../../utils/functions/alertFunction';
import { setOperInfo } from '../../../utils/functions/others';
import { clientSendData } from '../../../utils/functions/requests';
import { createAction } from '../../../utils/redux/store';
import ServiceBody from '../../../components/Service/ServiceBody';
import Cancel from '../../../components/UI/Cancel';
import CheckMark from '../../../components/UI/CheckMark';
import Input from '../../../components/UI/Input';
import Loader from '../../../components/UI/Loader';
import Toggle from '../../../components/UI/Toggle';
import Table from '../../../components/Table/Table';
import THead from '../../../components/Table/THead';
import TBody from '../../../components/Table/TBody';
import TFoot from '../../../components/Table/TFoot';
import TRow from '../../../components/Table/TRow';
import TData from '../../../components/Table/TData';
import DivisionCard from './DivisionCard';

/**
 * @component Администрирование базы знаний - редактирование подразделений (создание, редактирование, удаление)
*/
function Divisions() {
  const divisions = useSelector((state) => state.kb.divisions); // все подразделения из хранилища
  const [isCreatingDivision, setIsCreatingDivision] = useState(false); // состояние создания нового подразделения
  const [newDivisionName, setNewDivisionName] = useState(''); // имя нового подразделения
  const [choosedRow, setChoosedRow] = useState(null); // Выбранная строка таблицы
  const [isLoading, setIsLoading] = useState(false); // Состояние загрузки
  const [showCard, setShowCard] = useState(false); // состояние карточки направления
  const [openedDivision, setOpenedDivision] = useState({}); // текущее направление открытое в карточке

  useEffect(() => {
    getDivisions(); // Получить информацию о группах
  }, []);

  // Получить информацию о направлениях
  async function getDivisions() {
    try {
      const reqData = {
        type: 'getDivisions',
      };
      const result = await clientSendData('POST', '/get_divisions', reqData); // запрос в БД
      if (result) createAction('SET_DIVISIONS', result); // запись в хранилище
    } catch (error) {
      catchHandler(error, 'getDivisions'); // обработчик ошибок
    }
  }

  // Показать карточку направления и передавать в нее информацию
  function openDivision(division) {
    setIsCreatingDivision(false); // закрыть форму создания если открыта
    setOpenedDivision(division); // обновить состояние текущего направления
    setOperInfo({ subject_id: division.id }); // записать id направления в оперативную информацию
    setShowCard(true);
  }

  // Начать создание
  function startCreating() {
    setIsCreatingDivision(true); // вкл создание
  }

  // Создать новое направление
  async function createDivision() {
    try {
      const reqData = {
        type: 'createDivision',
        name: newDivisionName,
      };

      if (!newDivisionName) await alertFunction('enter_name', 'clientPost'); // Если имя пустое - уведомление
      else { // иначе
        setIsLoading(true); // загружается
        const result = await clientSendData('POST', '/create_division', reqData); // запрос в БД
        if (result === 'success') { // если успех
          await getDivisions(); // обновление групп
          setIsCreatingDivision(false); // Создание выкл
        }
      }
    } catch (error) {
      catchHandler(error, 'createDivision');
    } finally {
      setIsLoading(false);
    }
  }

  // Отменить создание
  function cancelCreating(e) {
    e.preventDefault(); // предотвращение действий формы по умолчанию
    setNewDivisionName(''); // сброс имени нового направления
    setIsCreatingDivision(false); // изменение состояния создания группы
  }

  return (
    <ServiceBody id="divisions">
      {isLoading ? <Loader /> : (// если загружается - показывается лоадер, иначе таблица
      <Table id="divisions__main-table" short_last>
        {/* Хедер таблицы */}
        <THead title="Подразделения">
          <TRow>
            <TData>Название</TData>
            <TData>Почта</TData>
            <TData>Уведомления</TData>
            <TData>Ограничение просмотра</TData>
            <TData />
          </TRow>
        </THead>

        {/* Тело таблицы */}
        <TBody>
          {divisions.map((d) => (
            <TRow key={d.id} onClick={() => setChoosedRow(d.id)} active={choosedRow === d.id}>
              <TData>{d.name}</TData>
              <TData>{d.mail_notify}</TData>
              <TData>
                <Toggle state={d.notify.toString()} />
              </TData>
              <TData>{d.limit_view}</TData>
              <TData onClick={() => openDivision(d)}>
                <img src="../../icons/file-text.svg" alt="file" />
              </TData>
            </TRow>
          ))}
        </TBody>

        {/* Футер таблицы */}
        <TFoot>
          {isCreatingDivision ? ( // если создается новое подразделение показывается форма
          <TRow>
            <TData>
              <Input placeholder="Введите имя" onChange={(e) => setNewDivisionName(e.target.value)} />
              <CheckMark onClick={(e) => createDivision(e)} />
              <Cancel onClick={cancelCreating} />
            </TData>
          </TRow>) : ( // иначе кнопка "Создать"
          <TRow>
            <TData onClick={startCreating}>Создать подразделение</TData>
          </TRow>)}
        </TFoot>
      </Table>)}

      {showCard && (
      <DivisionCard // карточка подразделения
        setShowCard={setShowCard}
        getDivisions={getDivisions}
        openedDivision={openedDivision}
        setOpenedDivision={setOpenedDivision}
      />)}
    </ServiceBody>
  );
}

export default Divisions;
