import DOMPurify from 'dompurify';
import { catchHandler } from '../../utils/error_handling/error_handling';
import { clientSendData } from '../../utils/functions/requests';
import { createAction } from '../../utils/redux/store';

export const userDirectionDefault = { // Опция по умолчанию для выбора направлений
  id: 'all',
  title: 'Показать все',
};

// Получить информацию о направлениях
export async function getDirections() {
  try {
    const reqData = {
      type: 'getDirections',
    };
    const result = await clientSendData('POST', '/get_directions', reqData); // запрос в БД
    if (result) createAction('SET_DIRECTIONS', result); // запись в хранилище
  } catch (error) {
    catchHandler(error, 'getDirections'); // обработчик ошибок
  }
}

// Получить информацию о направлениях, доступных пользователю
export async function getUserDirections(location) {
  try {
    const reqData = {
      type: 'getUserDirections',
      location,
    };
    const result = await clientSendData('POST', '/get_user_directions', reqData); // запрос в БД

    if (result) {
      // если доступно всего одно направление - оно устанавливается в качетстве текущего
      if (result.length === 1) setCurrentDirection(result[0]);
      // иначе устанавливается направление по умолчанию
      else setCurrentDirection(userDirectionDefault);

      createAction('SET_USER_DIRECTIONS', result); // данные записываются в хранилище
    }
  } catch (error) {
    catchHandler(error, 'getUserDirections');
  }
}

// Получить информацию о вопросах
export async function getQuestions(location, direction) {
  try {
    const reqData = {
      type: 'getQuestions',
      location,
    };
    const result = await clientSendData('POST', '/get_questions', reqData);// запрос в БД

    if (result) {
      // запись вопросов в хранилище
      createAction('SET_QUESTIONS', result);
      // если есть текущее направление - отфильтровать вопросы
      if (direction) filterQuestions(result, direction);
      // если нет - показать все
      else createAction('SET_DISPLAYED_QUESTIONS', result);
    }
  } catch (error) {
    catchHandler(error, 'getQuestions');
  }
}

// Получить информацию об анкетах
export async function getQuestionnairies(location, direction) {
  try {
    const reqData = {
      type: 'getQuestionnairies',
      location,
    };
    const result = await clientSendData('POST', '/get_questionnairies', reqData); // запрос в БД
    if (result) {
      // запись анкет в хранилище
      createAction('SET_QUESTIONNAIRIES', result);
      // если есть текущее направление - отфильтровать анкеты
      if (direction) filterQuestionnairies(result, direction);
      // если нет - показать все
      else createAction('SET_DISPLAYED_QUESTIONNAIRIES', result);
    }
  } catch (error) {
    catchHandler(error, 'getQuestionnairies');
  }
}

// Получить информацию о категориях
export async function getCategories(location, direction) {
  try {
    const reqData = {
      type: 'getCategories',
      location,
    };
    const result = await clientSendData('POST', '/get_categories', reqData); // запрос в БД
    if (result) {
      // запись категорий в хранилище
      createAction('SET_CATEGORIES', result);
      // если есть текущее направление - отфильтровать категории
      if (direction) filterCategories(result, direction);
      // если нет - показать все
      else createAction('SET_DISPLAYED_CATEGORIES', result);
    }
  } catch (error) {
    catchHandler(error, 'getCategories');
  }
}

// Установить текущее направление и связанные с ним имена
export function setCurrentDirection(direction) {
  createAction('SET_CURRENT_DIRECTION', direction);
}

// Фильтрация категорий отображаемых в таблице
export function filterCategories(categories, direction) {
  // категории относящиеся к выбранному направлению
  const filteredCategories = categories.filter((c) => (direction.id === 'all' ? c : c.direction_id === direction.id));
  // обновление состояние отображаемых категорий
  createAction('SET_DISPLAYED_CATEGORIES', filteredCategories);
}

// Фильтрация анкет отображаемых в таблице
export function filterQuestionnairies(questionnairies, direction) {
  // анкеты относящиеся к выбранному направлению
  const filteredQuestionnairies = questionnairies.filter((q) => (direction.id === 'all' ? q : q.group_id === direction.id));
  // обновление состояние отображаемых анкет
  createAction('SET_DISPLAYED_QUESTIONNAIRIES', filteredQuestionnairies);
}

// Фильтрация вопросов отображаемых в таблице
export function filterQuestions(questions, direction) {
  // вопросы относящиеся к выбранному направлению
  const filteredQuestions = questions.filter((q) => (direction.id === 'all' ? q : q.group_id === direction.id));
  // обновление состояние отображаемых вопросов
  createAction('SET_DISPLAYED_QUESTIONS', filteredQuestions);
}

export async function getSubjectLog(subject, subject_id, setLogs) {
  try {
    const reqData = {
      type: 'getSubjectLog',
      subject,
      subject_id,
    };
    const result = await clientSendData('POST', '/get_subject_log', reqData); // запрос в БД
    if (result) setLogs(result); // запись в состояние переданной функции
  } catch (error) {
    catchHandler(error, 'getSubjectLog');
  }
}

// Проверка и удаление опасных элементов из html
// html - проверяемый html
// props - настройки очистки (DOMpurify)
export function sanitizeHtml(html, props) {
  const sanitizedHtml = DOMPurify.sanitize(html, props);
  return sanitizedHtml;
}

// Cкрыть всплывающее меню селекта
export function hideSelect(e) {
  if (e) e.stopPropagation(); // предотвратить погружение клика в родительские элементы
  createAction('TOGGLE_SELECT', false); // скрыть всплывающее меню селекта
}
