import React from 'react';

/**
  * @component Ячейка таблицы
  * @prop {onClick} function Обработчик клика на ячейку
  * @prop {onDoubleClick} function Обработчик двойного клика на ячейку
  * @prop {active} boolean Визуальное выделение, ячейка выглядит нажатой
  * @prop {loading} boolean Анимация фона ячейки при загрузке данных
  * @prop {red} boolean Подсветка красным текста внутри ячейки
  * @prop {mobileHide} boolean Скрытие ячейки при мобилном отображении
  * @prop {align} string Выравнивание в ячейке
*/

function TData(props) {
  const {
    children, onClick, onDoubleClick, active, loading, red, mobileHide, align,
  } = props;

  // Определения классов стилей
  const defineClass = () => {
    let result = 'upu-table__table-data';
    if (onClick) result = 'upu-table__table-button';
    if (active) result += ' upu-table__table-button_active';
    if (red) result += ' upu-table__table-data_red';
    if (loading) result += ' upu__loading';
    if (mobileHide) result += ' mobile_hide';
    if (align) result += ` upu-table__table-data_${align}`;
    return result;
  };

  // Обработка клика
  function clickHandler(e) {
    if (onClick) onClick(e);
  }

  // Обработка двойниго клика
  function doubleClickHandler(e) {
    if (onDoubleClick) onDoubleClick(e);
  }

  return <td onClick={clickHandler} onDoubleClick={doubleClickHandler} className={defineClass()}>{children}</td>;
}

export default TData;
