/* eslint-disable no-await-in-loop */
import React, { useEffect, useState } from 'react';
// Функции
import { catchHandler } from '../../utils/error_handling/error_handling';
import { clientSendData } from '../../utils/functions/requests';
import { alertFunction } from '../../utils/functions/alertFunction';
import { log, setOperInfo, tableToExcel } from '../../utils/functions/others';
import { authorization } from '../../utils/functions/authenticate';
// Компоненты
import SingleFileUpload from '../../components/UI/FileUpload/SingleFileUpload';
import ServiceBody from '../../components/Service/ServiceBody';
import Service from '../../components/Service/Service';
import Table from '../../components/Table/Table';
import THead from '../../components/Table/THead';
import TBody from '../../components/Table/TBody';
import TFoot from '../../components/Table/TFoot';
import TRow from '../../components/Table/TRow';
import TData from '../../components/Table/TData';
import Cap from '../Cap/Cap';
// Стили
import './counterparty.scss';

/**
  @component Сервис поиска информации о контрагенте
  @Описание в documents/API/CounterParty.md
*/
function Counterparty() {
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [ddParametres, setDdParametres] = useState(null); // параметры подключения к api
  const [searchResults, setSearchResults] = useState([]); // результаты поиска
  const [lastResults, setLastResults] = useState([]); // последние результаты поиска (дефолтное состояние для сортировки)
  const [isAuthorized, setIsAuthorized] = useState(false); // Состояние авторизации
  const scheme = ddParametres?.scheme ? JSON.parse(ddParametres.scheme) : {}; // схема отображения и поиска

  // Массив полей которые необходимо показывать
  const fields = Object.keys(scheme).filter((item) => scheme[item]?.show);

  // Создание заголовков
  const headers = fields.map((field) => (// пройти по ключам схемы
    { // вернуть заголовок поля, ключ, параметр поиска в нём
      title: scheme[field].name,
      field,
      disable_search: !scheme[field].search,
    }
  ));

  useEffect(() => {
    awaitRequests();
    getDdParameters();
    setOperInfo();
  }, []);

  async function awaitRequests() {
    const checkAuthorization = await authorization(); // авторизация
    setIsAuthorized(checkAuthorization);
  }

  // Получение параметров подключения к DaData
  async function getDdParameters() {
    try {
      setIsLoading(true);
      const reqData = { type: 'getDdParameters' };
      const result = await clientSendData('POST', '/get_dd_parameters', reqData);
      if (result) setDdParametres(result);
      setIsLoading(false);
    } catch (error) {
      catchHandler(error, 'getDdParameters');
      setIsLoading(false);
    }
  }

  // Сохранить в БД данные DaData-запроса
  async function saveDdQuery(query, suggestions) {
    try {
      const reqData = {
        type: 'saveDdQuery',
        query,
        query_result: suggestions,
      };
      const result = await clientSendData('POST', '/save_dd_query', reqData);
      setDdParametres({ ...ddParametres, count: result });
    } catch (error) {
      catchHandler(error, 'saveDdQuery');
    }
  }

  // Получить сохраненные в БД данные DaData-запроса
  async function getSavedDdQuery(query) {
    try {
      const reqData = {
        type: 'getSavedDdQuery',
        query,
      };
      const result = await clientSendData('POST', '/get_saved_dd_query', reqData);
      if (result) return result;
      return null;
    } catch (error) {
      catchHandler(error, 'getSavedDdQuery');
      return null;
    }
  }

  // Получить параметры подключения к API DaData
  async function searchCounterParty(queries) {
    try {
      let result = []; // массив для добавления результатов

      // Получить данные по запросам и добавить в массив результатов
      for (const query of queries) {
        if (query?.correct) {
          const dataFromDB = await getSavedDdQuery(query.string); // Ищем данные по запросу в БД
          const { count, suggestions } = dataFromDB;
          setDdParametres({ ...ddParametres, count });
          // Если такие данные есть - добавляем к результату
          if (suggestions) result = [...result, ...suggestions];
          // Если данных нет
          else if (count >= ddParametres.max) { // если номер запроса равен или превышает допустимое кол-во
            await alertFunction('queries_excess', 'clientPost'); // уведомление
            break; // остановить цикл
          } else {
            const data = await getDData(query.string); // получить данные с DaData
            result = [...result, ...data]; // Добавить к результату
          }
        } else result = [...result, { data: { incorrect_result: query.string } }]; // записать как некорректное значение
      }

      result = result.map((item) => item.data); // Извлечь данные из объектов результата
      setSearchResults(result); // записать данные в состояние
      setLastResults(result); // записать данные в последние результаты (дефолтное состояние для сортировки)
    } catch (error) {
      catchHandler(error, 'searchCounterParty');
    }
  }

  // Получить данные DaData
  async function getDData(string) {
    try {
      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Token ${ddParametres.api}`,
        },
        body: JSON.stringify({ query: string }),
      };
      const response = await fetch(ddParametres.url, options);
      const result = await response.json();
      if (result?.suggestions) { // если получен результат
        // Если нет предложений - добавить в предложения объект {empty_result: "искомая строка"}
        if (result.suggestions.length === 0) result.suggestions = [{ data: { empty_result: string } }];
        saveDdQuery(string, result); // записать результат запроса в БД
        return result.suggestions;
      } return [];
    } catch (error) {
      catchHandler(error, 'getDData');
      return [];
    }
  }

  // Валидация запроса
  async function queryChecking(data, type) {
    // Валидация значения через регулярное выражение из схемы
    function validateValue(column) {
      const { field, value } = column;
      if (scheme[field]?.regexp) { // если есть регулярка
        const regexp = new RegExp(scheme[field].regexp); // создаем объект RegExp
        const checkResult = regexp.exec(value);
        // Если значение прошло проверку - вернуть результат
        if (checkResult?.[0] === value) return checkResult[0];

        // иначе уведомление о необходимости проверки поля
        alertFunction('check', 'clientPost', scheme[field]?.name);
        return null; // вернуть null
      } return value;
    }

    let queries = []; // массив запросов

    // STRING поиск--------------------------
    if (type === 'string') { // если поиск в одном инпуте
      log({ message: `Пользователь ищет - ${data}` });
      let checked = data.replace(/[,.:;\n\t\r]/g, ' '); // заменяем все запятые, табуляции, переносы на пробелы
      checked = checked.split(' '); // разделяем строку на массив через пробелы
      checked = checked.filter((item) => item); // удаляем пустые значения
      checked = checked.map((item) => item?.trim()); // удаляем пробелы в каждом элементе
      checked = checked.map((string) => {
        const regexp = /^[\d+]{10,12}$/; // создаем объект RegExp (регулярка для ИНН)
        const checkResult = regexp.exec(string); // проверяем строку
        if (!string || Number.isNaN(+string)) return null;
        return {
          string,
          correct: checkResult?.[0] === string, // корректность значения
        };
      });
      queries = checked.filter((item) => item); // удалить null значения

      // // Если в массиве были найдены невалидные значения
      // if (result.includes(null)) {
      //     let invalidValues = []
      //     // определить их и записать в invalidValues
      //     result.forEach((item, index) => {
      //         if (!item) invalidValues.push(checked[index])
      //     })
      //     // Показать пользователю уведомление
      //     alertFunction("check", "clientPost", `${invalidValues.join(", ")}` )
      // }
      //  else
      // queries = [...checked]
    }

    // FAST поиск--------------------------
    if (type === 'fast') { // если поиск по колонкам
      // Обработка массива с запросом для записи в лог
      const logString = data.map((item) => `${item.field}: ${item.value}`).join(', ');
      log({ message: `Пользователь ищет - ${logString}` });

      const checked = data.map((column) => validateValue(column));
      // Если после проверки массив не содержит null (т.е все значения валидны)
      if (!checked.includes(null)) {
        queries = checked.join(' '); // соединяем в одну строку (для одного запроса)
        queries = [{
          string: queries,
          correct: true,
        }]; // добавляем объект в массив
      }
    }

    if (queries.length > 0) {
      setIsLoading(true); // загружается
      await searchCounterParty(queries);
      setIsLoading(false); // загрузка завершена
    }
  }

  // Определить данные объекта
  function defineData(data, field) {
    const keys = field.split('.'); // разделить ключ схемы через точку

    // Углубиться в объект и получить данные
    function deepIntoTheObject(data, deep) {
      // если нет данных - возвращаем null
      if (!data) return null;
      // Если глубина равна длине массива вложенности ключей
      if (deep === (keys.length)) {
        const type = scheme[field]?.type;
        switch (type) {
          // Если тип поля - число, привести к числу и вернуть
          case 'number': return data ? Number(data) : null;
          // Если тип поля - дата, создать объект даты и вернуть строковое значение
          case 'date': return data ? new Date(data).toLocaleDateString() : null;
          default: return data;
        }
      } else {
        // Иначе берем зачение текущей глубины и идем дальше
        return deepIntoTheObject(data?.[keys?.[deep]], deep + 1);
      }
    }

    // Вернуть значение
    return deepIntoTheObject(data, 0);
  }

  // Обработка файла
  function fileHandler(file) {
    try {
      const reader = new FileReader(); // API чтения файлов
      // При успешном завершении операции вызовется функция обработки контента
      reader.onloadend = (event) => {
        const content = new TextDecoder().decode(event.target.result);
        queryChecking(content, 'string');
      };
      reader.onerror = (error) => console.log(error);
      reader.readAsArrayBuffer(file); // Чтение данных
    } catch (error) {
      console.log(error);
    }
  }

  if (isAuthorized) {
    return (
    <Service id="counterparty">
      <ServiceBody>
        <Table id="counterparty__table" short_last>
          <THead
            title="Поиск контрагента"
            headers={headers}
            array={searchResults}
            setArray={setSearchResults}
            setDefault={() => setSearchResults(lastResults)}
            button_place="row enabled"
            search_by_all={(data) => queryChecking(data, 'fast')}
            search_by_one={(data) => queryChecking(data, 'string')}
            ext_button={searchResults.length > 0 ? 'скачать в Excel' : null}
            ext_handler={() => tableToExcel('counterparty__table', 'Поиск контрагента')}
          />
          <TBody>
              {searchResults.map((row, index) => {
                if (Object.keys(row).includes('empty_result')) {
                  return (
                    <TRow key={index}>
                      <TData red loading={isLoading}>
                        {`${row.empty_result} - нет результатов`}
                      </TData>
                    </TRow>
                  );
                }
                if (Object.keys(row).includes('incorrect_result')) {
                  return (
                    <TRow key={index}>
                      <TData red loading={isLoading}>
                        {`${row.incorrect_result} - некорректное значение`}
                      </TData>
                    </TRow>
                  );
                }
                return (
                  <TRow key={index}>
                    {fields.map((field) => (
                    <TData key={field} loading={isLoading}>
                      {defineData(row, field)}
                    </TData>))}
                    <TData />
                  </TRow>
                );
              })}
          </TBody>
          <TFoot>
            <TRow>
              <TData loading={isLoading}>
                <SingleFileUpload
                  options={{
                    handler: fileHandler,
                    accept: '.txt, .csv',
                    size: 5000000,
                    message: ddParametres?.file_instruction,
                  }}
                >
                  <span className="clickable" onClick={() => log({ message: 'Пользователь нажал на ссылку "Выбрать файл"' })}>
                    Выбрать файл
                  </span>
                </SingleFileUpload>
              </TData>
              {/* {(ddParametres?.count >= ddParametres?.max) ?
              <TData loading={isLoading}>Достигнут лимит проверок на сегодня</TData>:
              <TData loading={isLoading}>Осталось проверок  {`${ddParametres?.max - ddParametres?.count}`}</TData>} */}
            </TRow>
          </TFoot>
        </Table>
      </ServiceBody>
    </Service>
    );
  } return <Cap />;
}

export default Counterparty;
