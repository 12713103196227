/* eslint-disable consistent-return */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-lonely-if */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { catchHandler } from '../../../../utils/error_handling/error_handling';
import { clientSendData } from '../../../../utils/functions/requests';
import { setUnique } from '../../../../utils/functions/others';
import { stringMatch } from '../../../../utils/functions/stringHandling';
import Loader from '../../../../components/UI/Loader';
import Button from '../../../../components/UI/Button/Button';
import InputSearch from '../../../../components/UI/InputSearch';
import classes from '../../../../components/UI/Button/button.module.scss';
import HierarchyLine from './HierarchyLine';
// import { sortObjectKeys } from '../functions';
// import CheckBox from '../../../../components/UI/CheckBox';
// import RadioButton from '../../../../components/UI/RadioButton';
// import { alertFunction } from '../../../../utils/functions/alertFunction';

/**
* @component Управление Базой знаний - Сущность(вопрос или тема) - Карточка сущности - Форма выбора switch поля - иерархия
* @prop {scheme} object - схема сущности
* @prop {field} string - поле схемы
* @prop {value} string|array - значение поля в текущей сущности
* @prop {handler} function - обработчик изменения
* @prop {initialObject} object - исходный объект (для сброса)
* @prop {divisionId} number - id подразделения (при вызове из карточки подразделения)
*/
function Hierarchy(props) {
  const {
    scheme, // схема
    field, // поле схемы
    value, // значение поля в текущем объекте
    handler, // обработчик изменения
    initialObject, // исходный объект (для сброса)
    divisionId, // id подразделения (при вызове из карточки подразделения)
  } = props;

  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [hierarchy, setHierarchy] = useState({}); // иерархия
  const [initialData, setInitialData] = useState([]); // исходные данные
  const [openedEssences, setOpenedEssences] = useState([]); // массив открытых сущностей
  const [foundedEssences, setFoundedEssences] = useState([]); // массив найденных сущностей
  const [marked, setMarked] = useState([]); // условно выбранные сущности (если выбраны их дети)
  const [highlighted, setHighlighted] = useState([]); // выделенные сущности (при поиске)
  const currentDivision = useSelector((state) => state.kb.current_division); // текущее подразделение
  const switchData = scheme[field]?.switch; // switch данные поля
  const switchColumn = switchData?.switch_column; // switch колонка для отображения
  const parent_key = switchData?.parent_key; // ключ для создания иерархической структуры
  // const isSingleChoice = scheme[field]?.type === 'number'; // Одиночный выбор?
  // const isMultipleChoice = scheme[field]?.type === 'array'; // Множественный выбор?
  // const themesSorting = 'asc'; // Сортировка тем иерархии

  useEffect(() => {
    getData();
  }, []);

  // ------------------Получение данных и создание структуры----------

  // Получить данные для отображения
  async function getData() {
    try {
      const reqData = {
        type: 'getTableData',
        switch_data: switchData,
        division_id: currentDivision?.id || divisionId,
      };
      setIsLoading(true);

      const result = await clientSendData('POST', '/get_table_data', reqData); // Запрос в БД
      if (result) {
        setInitialData(result); // записать в состояние
        createStructure(result); // создание структуры
        markParents(value, result);
        setIsLoading(false);
      }
    } catch (error) {
      catchHandler(error, 'getTableData');
      setIsLoading(false);
    }
  }

  // Создать структуру
  function createStructure(array) {
    const copy = array.map((item) => item); // копия массива для внесения изменений
    const structure = {}; // создаём структуру
    addRemove(copy, parent_key, structure); // запускаем ее наполнение
    setHierarchy(structure); // записываем созданную структуру в состояние компонента
    // markParents(array) // Пометить родителей у которых есть выбранные дети

    // Наполнение структуры
    // arr - массив объектов
    // parent_key - наименования ключа по которому ищем родителя
    // structure - объект структуры для наполнения
    function addRemove(arr, parent_key, structure) {
      const to_delete = []; // Пустой массив под удаление

      arr.forEach((item) => { // проходим по каждому элементу
        if (item?.[parent_key] === null) { // если родительский ключ равен null
          structure[item?.id] = item; // элемент записывается в структуру со своим id вместо ключа
          to_delete.push(item.id); // Записываю элемент для удаления из массива исходных данных
        } else { // Иначе запускаем функцию поиска родителя
          if (addObjectChildren(structure, item[parent_key], item)) { // Если вернулось true значит в структуру успешно добавили
            to_delete.push(item.id); // Можно удалять
          }
        }
      });

      if (to_delete.length > 0) { // Смотрим если объекты для удаления есть
        to_delete.forEach((id) => { // удаляем объекты из исходного массива
          arr = arr.filter((item) => item.id !== id);
        });
        addRemove(arr, parent_key, structure); // Перезапускаем процедуру добавления в структуру и удаления
      }
    }

    // Добавить детей к объекту
    function addObjectChildren(object, key, toadd) {
      // console.log('start addObjectChildren')
      // Если объект содержит key (фактически ключ в виде id)
      if (object.hasOwnProperty(key)) {
        // Если у объекта по ключу key отсутствует ключ children - добавляем его
        if (!object[key].hasOwnProperty('children')) object[key].children = {};
        // Затем добавляем в него объект для добавления с ключом одноименным с его id
        object[key].children[toadd.id] = toadd;
        return true;
      }
      // Если объект не содержит key - проходим по его ключам
      for (let j = 0; j < Object.keys(object).length; j++) {
        const element = object[Object.keys(object)[j]];
        // console.log("Беру елемент", element);
        if (Object.keys(element).find((i) => i === 'children')) {
          // console.log("Есть ключ children ищу внутри");
          if (addObjectChildren(element.children, key, toadd)) {
            return true; // Этот return выводит из рекурсии
          }
        }
      }
      // Object.keys(object).forEach((item) => {
      //     // Если есть children - проверяем их и записываем туда объект для добавления
      //     if (object[item].hasOwnProperty("children")) {
      //         if (addObjectChildren(object[item].children, key, toadd)) return true;
      //         else return
      //     }
      // })
    }
  }

  // --------------------Обработка отображения------------------------

  // // Сущность открыта?
  // function isOpened(id) {
  //   if (openedEssences.includes(id)) return true; // если есть - возвращает true
  //   return false; // иначе false
  // }

  // // Сущность помечена?
  // function isMarked(id) {
  //   if (marked.includes(id)) return true; // если есть - возвращает true
  //   return false; // иначе false
  // }

  // // Сущность выделена?
  // function isHighlighted(id) {
  //   if (highlighted.includes(id)) return true; // если есть - возвращает true
  //   return false; // иначе false
  // }

  // // Проверить состояние выбора
  // function isChoosed(id) {
  //   // если массив выбора содержит переданный id - вернуть true, иначе false
  //   if (isSingleChoice) return value === id;
  //   // если массив выбора содержит переданный id - вернуть true,
  //   if (isMultipleChoice) return value?.includes(id);
  //   // иначе false
  //   return false;
  // }

  // // Определить класс
  // function defineClass(object) {
  //   if (!object?.children) return 'hierarchy__expand hierarchy__expand_hide';
  //   if (isOpened(object?.id)) return 'hierarchy__expand hierarchy__expand_active';
  //   return 'hierarchy__expand';
  // }

  function resetHighlight() {
    setHighlighted([]);
  }

  // --------------------Обработка действий------------------------

  // // Открыть/закрыть конкретную сущность
  // function toggleEssence(event, id) {
  //   event.stopPropagation();
  //   if (openedEssences.includes(id)) closeEssences([id]); // если найдена среди активных - сущность закрывается
  //   else openEssences([id]); // если нет - сущность открывается
  // }

  // Открыть сущности
  function openEssences(array) {
    let newArray = [...openedEssences, ...array]; // добавить в открытые сущности новые данные
    newArray = newArray.filter(setUnique); // оставить уникальные
    setOpenedEssences(newArray);
  }

  // // Закрыть сущности
  // function closeEssences(array) {
  //   // оставить только те, которых нет в array
  //   const newArray = openedEssences.filter((item) => !array.includes(item));
  //   setOpenedEssences(newArray);
  // }

  // Свернуть все сущности
  function closeAllEssences() {
    setOpenedEssences([]); // сбросить массив открытых сущностей
    resetHighlight(); // сбросить выделение найденных элементов
  }

  // // Выбрать/сбросить сущность
  // function toggleValue(object) {
  //   const { id } = object; // id объекта
  //   const switchFieldName = `switch_${field}`; // наименование ключа с именем для отображения

  //   let newValue = null;
  //   let newTitle = null;

  //   if (divisionId) { // если компонент вызывется из карточки подразделения
  //     const children = defineChildren(object); // определяем детей объекта
  //     if (value) { // если присутствует массив value
  //       // если массив выбора уже содержит переданный id - удалить из массива значений
  //       if (value.includes(id)) {
  //         newValue = value.filter(
  //           (item) => item !== id // id
  //           && !children.includes(item) // его детей
  //           && item !== +object[parent_key], // его родителя (чтобы отобразить родителя и подтему)
  //         );
  //       // иначе добавить в текущий массив переданный id, его детей и оставить уникальные значения
  //       } else newValue = [...value, id, ...children].filter(setUnique);
  //       // если value отсутствует - добавить выбранный id и его детей в массив
  //     } else newValue = [id, ...children];
  //   } else if (isSingleChoice) { // Если тип поля число - обработка единственного выбора
  //     if (value === id) { // если значение равно выбранному id - значит выбор деактивируется
  //       newValue = null; // сбросить значения
  //       newTitle = null; // сбросить значения
  //     } else { // иначе выбор активируется
  //       newValue = id; // id выбранного элемента
  //       newTitle = object[switchData?.switch_column]; // заголовок выбранного элемента
  //     }
  //   } else if (isMultipleChoice) { // Если тип поля массив - обработка множественного выбора
  //     // Создать заголовки на основании обновлённого массива id
  //     function createTitles(array) {
  //       const result = array.map((item) => {
  //         const findObject = initialData.find((row) => row.id === item);
  //         return findObject?.[switchData?.switch_column];
  //       });
  //       return result.join(', ');
  //     }

  //     if (value) { // если присутствует массив value
  //       // если массив выбора уже содержит переданный id -  удалить id из массива значений
  //       if (value.includes(id)) newValue = value.filter((item) => item !== id);
  //       // иначе добавить в текущий массив переданный id
  //       else newValue = [...value, id];
  //     } else newValue = [id]; // если value отсутствует - добавить выбранный id в массив

  //     newTitle = createTitles(newValue); // обновить заголовок на основании нового значения
  //   } else return;

  //   handler({ //
  //     [field]: newValue,
  //     [switchFieldName]: newTitle,
  //   });
  //   markParents(newValue, initialData); // пометить родителей элементов
  // }

  // Сбросить значение поля на исходное
  function reset() {
    const switchFieldName = `switch_${field}`; // наименование ключа с именем для отображения
    const initialValue = initialObject?.[field] || null;
    handler({
      [field]: initialValue,
      [switchFieldName]: initialObject?.[switchFieldName] || null,
    });

    markParents(initialValue, initialData); // Обновить метки на родителях
    resetHighlight(); // сбросить выделение найденных элементов
  }

  // // Определить id дочерних элементов объекта в иерархии
  // function defineChildren(parent) {
  //   const result = []; // массив для записи id детей

  //   // Функция добавления детей в массив
  //   function addChild(object) {
  //     if (object?.children) { // если объект содержит children
  //       const { children } = object;
  //       Object.keys(children).forEach((key) => { // пройти по ключам children
  //         result.push(children[key].id); // и запись id в массив
  //         // если ключ содержит детей вызвать функцию заново передав ей дочерний элемент
  //         if (children[key]?.children) addChild(children[key]);
  //         // иначе выйти из рекурсии
  //       });
  //     }// иначе выйти из рекурсии
  //   }

  //   addChild(parent); // записать id дочерних элеменов

  //   return result; // вернуть массив
  // }

  // Определить генеалогическое древо элементов в иерархии
  function defineParents(id, array) {
    // Если входящий элемент - массив, оставить его, иначе создать массив из одного элемента
    const arrayOfId = Array.isArray(id) ? id : [id];
    let parents = []; // массив для наполнения

    // Функция поиска родителя
    function findParent(id) {
      const object = array.find((item) => item.id === id); // дочерний объект
      const parent = object?.[parent_key]; // родитель
      if (parent) { // если есть родитель
        parents.push(+parent); // добавить id в массив
        findParent(+parent); // запустить поиск родителя родителя
      } // если родителя нет выйти из функции
    }

    arrayOfId.forEach((id) => findParent(id)); // Найти родителей
    parents = parents.filter(setUnique); // оставить только уникальные значения

    return parents;
  }

  // Пометить родителей выбранных элементов
  function markParents(value, array) {
    if (value) {
      const parents = defineParents(value, array);
      setMarked(parents);
    } else setMarked([]); // если значения нет - сбросить метки
  }

  // --------------------Обработка поиска------------------------

  // Обработчик поиска
  function searchEssence(event) {
    try {
      let string = event.target.value; // искомая строка
      string = string.replace(/[.?*+^$[\]\\(){}|-]/g, '\\$&'); // экранирование спецсимволов

      if (string.length > 1) {
        // Результаты фильтрации
        let searchResult = initialData.filter((item) => stringMatch(string, String(item[switchColumn])));
        // добавить title к объектам массива (для InputSearch)
        searchResult = searchResult.map((item) => ({ ...item, title: item[switchColumn] }));
        // обновить состояние сущностей
        setFoundedEssences(searchResult);
      } else setFoundedEssences([]); // иначе - сбросить сущностиы
    } catch (error) {
      catchHandler(error, 'searchEssence'); // обработка ошибок
    }
  }

  // Выбрать сущность в поиске
  function showFoundEssences(object) {
    // let parents;
    let identifiers;
    // если передаётся массив - оставляем в нем только id
    if (Array.isArray(object)) identifiers = object.map((item) => item.id);
    else identifiers = [object?.id]; // иначе передаётся объект

    const parents = defineParents(identifiers, initialData); // ищем родителей элементов
    openEssences(parents); // открыввем найденные сущности
    setHighlighted(identifiers);
  }

  // --------------------Отображение-----------------------------

  // // Компонент иерархии
  // function Line(props) {
  //   const { object } = props;
  //   const { id } = object;
  //   return (
  //           <div className="hierarchy__line">
  //               <div className="hierarchy__title">

  //               {/* Свернуть/развернуть сущность */}
  //               <div className={defineClass(object)} onClick={(event) => toggleEssence(event, id)} />

  //                   {/* Для единственного выбора отображается радиокнопка */}
  //                   {isSingleChoice
  //                   && <RadioButton
  //                     id={`hierarchy__radio_${id}`}
  //                     group="hierarchy__radio"
  //                     ismarked={String(isMarked(id))}
  //                     onChange={() => toggleValue(object)}
  //                     defaultChecked={isChoosed(id)}
  //                   />}

  //                   {/* Для множественного выбора отображается чекбокс */}
  //                   {isMultipleChoice
  //                   && <CheckBox
  //                     id={`hierarchy__checkbox_${id}`}
  //                     ismarked={String(isMarked(id))}
  //                     onChange={() => toggleValue(object)}
  //                     defaultChecked={isChoosed(id)}
  //                   />}
  //                   <span className={isHighlighted(id) ? 'hierarchy__title-content_active' : 'hierarchy__title-content'}>
  //                       {object[switchColumn]}
  //                   </span>
  //               </div>
  //               {showChildrens(object)}
  //           </div>
  //   );
  // }

  // // Показать детей объекта
  // function showChildrens(object) {
  //   if (object?.children) {
  //     return (
  //       <div className={`hierarchy__children ${isOpened(object?.id) ? 'hierarchy__children_active' : ''}`}>
  //           {sortObjectKeys(object.children, switchColumn, themesSorting).map((key) => {
  //             const child = object.children[key];
  //             return <Line key={key} object={child} id={key} />;
  //           })}
  //       </div>
  //     );
  //   } return null;
  // }

  if (isLoading) return <Loader />;
  return (
    <div className="upu-card__body" id="knowledge-base__hierarchy">
      {/* Заголовок подразделения */}
      <div className="upu-card__setting">
        <p className="upu-card__setting-title">Поиск по структуре</p>
        <InputSearch
          id="knowledge-base__hierarchy-search"
          array={foundedEssences}
          onSearch={searchEssence}
          onChoose={showFoundEssences}
          onPressEnter={showFoundEssences}
          placeholder="Поиск..."
        />
      </div>

      {Object.keys(hierarchy).map((key) => {
        const object = hierarchy[key];
        // return <Line key={key} object={object} />;
        return (
          <HierarchyLine
            key={key}
            object={object}
            scheme={scheme}
            field={field}
            value={value}
            initialData={initialData}
            handler={handler}
            markParents={markParents}
            marked={marked}
            highlighted={highlighted}
            openEssences={openEssences}
            openedEssences={openedEssences}
            setOpenedEssences={setOpenedEssences}
          />
        );
      })}
      <div className="upu-card__body-info">
        <Button className={`${classes.button_ghost} ${classes.small}`} onClick={reset}>Сбросить выбор</Button>
        <Button className={`${classes.button_ghost} ${classes.small}`} onClick={closeAllEssences}>Свернуть все</Button>
      </div>
    </div>
  );
}

export default Hierarchy;
