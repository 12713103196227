import React from 'react';
import ServiceBody from '../../../components/Service/ServiceBody';
import TableWrapper from '../Components/TableWrapper/TableWrapper';
import Table from '../../../components/Table/Table';
import THead from '../../../components/Table/THead';
import TBody from '../../../components/Table/TBody';
import TRow from '../../../components/Table/TRow';
import TData from '../../../components/Table/TData';

/**
* @component Сервис отчётов анкетирования - Базовый отчёт - Таблица отчёта
* @prop {currentReport} object Объект текущего отчёта
*/
function InfoBlock(props) {
  const { currentReport } = props;

  const questions = currentReport?.questions || []; // Вопросы по текущей анкете
  const report = currentReport?.answers || []; // ответы пользователей
  const questionnaire = currentReport?.questionnaire || {}; // информация об анкете
  const questionnaireName = questions[0]?.questionnaire_name || 'Без имени'; // имя анкеты
  const usersData = currentReport?.users_data || [];

  // Если данных нет - заглушка с заголовком
  if (!currentReport) return <ServiceBody><h2 className="upu-service__title">Выберите отчёт</h2></ServiceBody>;
  // Если отчёт пустой - заглушка с заголовком
  if (currentReport.answers.length === 0) return <ServiceBody><h2 className="upu-service__title">Информация отсутствует</h2></ServiceBody>;
  // Иначе
  return (
    <ServiceBody>
      <TableWrapper id="reports__table" title={questionnaireName} back_button={false}>
        <Table id="reports__table">
          <THead>
            <TRow>
              <TData>Сотрудник</TData>
              <TData>Грейд</TData>
              <TData>Подразделение</TData>
              <TData>Компания</TData>
              {/* Добавить названия вопросов в заголовки колонок таблицы */}
              {questions.map((el) => {
                const { id, title, output_id } = el;
                return (
                  <React.Fragment key={id}>
                    <TData>{title.length > 100 ? `${title.slice(0, 100)}...` : title}</TData>
                    {output_id === 5 && <TData>Отдел</TData>}
                    {output_id === 5 && <TData>Грейд</TData>}
                    {/* Скрытие колонки комментария, если они выключены в анкете */}
                    {(output_id !== 4 && questionnaire?.comments) && <TData>Комментарий</TData>}
                  </React.Fragment>
                );
              })}
            </TRow>
          </THead>
          <TBody>
            {report.map((row) => {
              const { user, answers, anonym } = row;
              const [fio, grade, department, company] = user.split('@');
              return (
                <TRow key={user}>
                  <TData>{!anonym ? fio : 'Анонимно'}</TData>
                  <TData>{grade}</TData>
                  <TData>{department}</TData>
                  <TData>{company}</TData>
                  {/* Добавить ответы на вопросы */}
                  {questions.map((element) => {
                    // Массив ответов (у всех 1 элемент в массиве,
                    // кроме множественного выбора (output_id = 2))
                    const answerArray = answers.filter((a) => a.question_id === element.id);
                    // Собрать ответы в одну строку
                    const answer = answerArray?.map((item) => item.answer).join(', ') || '';
                    // Сделать тоже самое с комментарием
                    const comment = answerArray?.map((item) => item.comment).join(' ') || '';
                    const outputId = element.output_id; // id типа вывода
                    // если это не "Выбор сотрудника"
                    if (outputId !== 5) {
                      return (
                        <React.Fragment key={element.id}>
                          {outputId !== 4 && ( // если не комментарий, то показать ответ
                          <TData>
                            <div className="interview__answer-content" dangerouslySetInnerHTML={{ __html: answer }} />
                          </TData>
                          )}
                          {/* Скрытие комментария, если они выключены в анкете */}
                          {(outputId === 4 || questionnaire?.comments) && (
                          <TData>{comment}</TData>)}
                        </React.Fragment>
                      );
                    }
                    // если это "Выбор сотрудника"
                    // Найти в массиве информации о пользователях нужные данные
                    const foundedUser = usersData.find((u) => u.id === answerArray[0]?.answer_id);
                    return (
                      <React.Fragment key={element.id}>
                        <TData>{foundedUser?.title}</TData>
                        <TData>{foundedUser?.department}</TData>
                        <TData>{foundedUser?.grade}</TData>
                        {/* Скрытие комментария, если они выключены в анкете */}
                        {(outputId === 4 || questionnaire?.comments) && (
                        <TData>{comment}</TData>)}
                      </React.Fragment>
                    );
                  })}
                </TRow>
              );
            })}
          </TBody>
        </Table>
      </TableWrapper>
    </ServiceBody>
  );
}

export default InfoBlock;
