import React, { useState } from 'react';
import Button from '../../components/UI/Button/Button';
import Input from '../../components/UI/Input';
import Select from '../../components/UI/Select';
import { clientSendData } from '../../utils/functions/requests';
import { catchHandler } from '../../utils/error_handling/error_handling';
import { alertFunction } from '../../utils/functions/alertFunction';
import Service from '../../components/Service/Service';
import ServiceBody from '../../components/Service/ServiceBody';

/**
* @component Отчёты Первой Формы
*/
function ReportsFF() {
  const [parameters, setParameters] = useState({});
  const [reportType, setReportType] = useState('Выберите тип');
  const reportTypes = [
    { id: 1, title: 'Факт' },
    { id: 2, title: 'План' },
  ];

  async function requestReport() {
    try {
      const reqData = {
        type: 'createReport',
        data: parameters,
      };
      if (parameters.start && parameters.end && parameters.type) {
        const result = await clientSendData('POST', '/create_report', reqData); // запрос в БД
        if (result === 'success') { // если успех
          alertFunction('ff_reports_success_request', 'clientPost'); // уведомление
        }
      } else {
        alertFunction('ff_reports_verify_inputs', 'clientPost'); // уведомление
      }
    } catch (error) {
      catchHandler(error, 'createReport');
    }
  }

  function chooseReportType(sel) {
    setParameters({ ...parameters, type: sel.id });
    setReportType(sel.title);
  }

  return (
    <Service id="ff-reports">
      <ServiceBody>
        <h2 className="upu-service__title">Отчеты &quot;Первая Форма&quot;</h2>
        <p>Укажите период</p>
        <Input type="date" placeholder="от" onChange={(e) => { setParameters({ ...parameters, start: e.target.value }); }} />
        <Input type="date" placeholder="до" onChange={(e) => { setParameters({ ...parameters, end: e.target.value }); }} />
        <p>Выберите тип</p>
        <Select
          id="first-form-reports__select_type"
          array={reportTypes}
          onChoose={chooseReportType}
          defaultValue={reportType}
        />
        <Button onClick={() => requestReport()}>Сформировать</Button>
      </ServiceBody>
    </Service>
  );
}

export default ReportsFF;
