import React, { useEffect, useState } from 'react';
import Loader from '../../../../components/UI/Loader';
import { catchHandler } from '../../../../utils/error_handling/error_handling';
import { clientSendData } from '../../../../utils/functions/requests';
import CardSetting from '../../../../components/Card/CardSetting';

// Система Оценок - интерфейс сотрудника - карточка заявления - вкладка Истории
function Logs(props) {
  const { id } = props;

  const [logs, setLogs] = useState([]); // история
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки

  useEffect(() => {
    getApplicationHistory(); // получить логи
  }, []);

  async function getApplicationHistory() {
    try {
      const reqData = {
        type: 'getApplicationHistory',
        application_id: id,
      };
      setIsLoading(true); // состояние - загружается
      const result = await clientSendData('POST', '/get_application_history', reqData);
      if (result) setLogs(result);
      setIsLoading(false); // загрузка завершена
    } catch (error) {
      catchHandler(error, 'getApplicationHistory');
      setIsLoading(false); // загрузка завершена
    }
  }

  // Показать объект
  function showObject(object) {
    if (typeof object === 'object' && object !== null) {
      if (Array.isArray(object)) {
        return <p className="upu__setting-title">{object.join(', ')}</p>;
      }
      return Object.keys(object).map((key) => {
        const keyData = object[key];
        return (
                        <CardSetting key={key} title={`${key}:`}>
                            {showObject(keyData)}
                        </CardSetting>
        );
      });
    } return <p className="upu__setting-title">{String(object)}</p>;
  }

  // если загружается - показывается лоадер
  if (isLoading) return <Loader />;
  // Если логи пустые - показывается заголовок об их отсутствии
  if (logs.length === 0) return <h2 className="upu-service__title">История отсутствует</h2>;
  // Иначе отображается история
  return (
    <div id="evaluation__application-history" className="upu-card__block">
        {logs.map((entry) => {
          const {
            date_created, application, performer,
          } = entry;
          return (
            <div key={entry.id} className="question-history">
                <p className="upu__setting-title">{date_created}</p>
                <p className="upu__setting-title">{performer}</p>
                {Object.keys(application).map((key) => {
                  const keyData = application[key];
                  return (
                    <CardSetting key={key} title={`${key}:`}>
                        {showObject(keyData)}
                    </CardSetting>
                  );
                })}
            </div>
          );
        })}
    </div>
  );
}

export default Logs;
