export const blocks = {
  b: {
    title: 'Блок Б',
    map: {
      name: 'my-map',
      areas: [
        {
          id: 1,
          title: 'Б-1',
          type: 'workplace',
          shape: 'poly',
          coords: [134, 391, 186, 441, 227, 401, 176, 350],
          preFillColor: 'rgba(0, 255, 0, 0.15)',
          fillColor: 'rgba(0, 255, 0, 0.2)',
          strokeColor: 'rgba(222, 33, 46)',
        },
        {
          id: 2,
          title: 'Б-2',
          type: 'workplace',
          shape: 'poly',
          // coords: [540, 123, 556, 4, 580, 6, 602, 12, 622, 19, 644, 27, 595, 138, 540, 124],
          coords: [176, 350, 227, 401, 296, 333, 245, 282],
          preFillColor: 'rgba(237, 168, 226, 0.4)',
          fillColor: 'rgba(227, 84, 203, 0.2)',
          strokeColor: 'rgba(232, 51, 214)',
        },
        {
          id: 11,
          title: 'Б-3',
          type: 'workplace',
          shape: 'poly',
          // coords: [540, 123, 556, 4, 580, 6, 602, 12, 622, 19, 644, 27, 595, 138, 540, 124],
          coords: [245, 282, 296, 333, 366, 264, 316, 213],
          preFillColor: 'rgba(245, 242, 159, 0.4)',
          fillColor: 'rgba(232, 226, 51, 0.2)',
          strokeColor: 'rgba(222, 33, 46)',
        },
        {
          id: 12,
          title: 'Б-4',
          type: 'workplace',
          shape: 'poly',
          coords: [316, 213, 366, 264, 437, 197, 392, 140, 372, 158],
          preFillColor: 'rgba(0, 255, 0, 0.15)',
          fillColor: 'rgba(0, 255, 0, 0.2)',
          strokeColor: 'rgba(222, 33, 46)',
        },
        {
          id: 13,
          title: 'Б-5',
          type: 'workplace',
          shape: 'poly',
          // coords: [540, 123, 556, 4, 580, 6, 602, 12, 622, 19, 644, 27, 595, 138, 540, 124],
          coords: [392, 140, 437, 197, 522, 146, 493, 78, 442, 104],
          preFillColor: 'rgba(237, 168, 226, 0.4)',
          fillColor: 'rgba(227, 84, 203, 0.2)',
          strokeColor: 'rgba(232, 51, 214)',
        },
        {
          id: 14,
          title: 'Б-6',
          type: 'workplace',
          shape: 'poly',
          // coords: [540, 123, 556, 4, 580, 6, 602, 12, 622, 19, 644, 27, 595, 138, 540, 124],
          coords: [493, 78, 522, 146, 617, 119, 606, 46, 549, 59],
          preFillColor: 'rgba(245, 242, 159, 0.4)',
          fillColor: 'rgba(232, 226, 51, 0.2)',
          strokeColor: 'rgba(222, 33, 46)',
        },
        {
          id: 15,
          title: 'Б-7',
          type: 'workplace',
          shape: 'poly',
          coords: [606, 46, 617, 119, 717, 120, 728, 46, 665, 44],
          preFillColor: 'rgba(0, 255, 0, 0.15)',
          fillColor: 'rgba(0, 255, 0, 0.2)',
          strokeColor: 'rgba(222, 33, 46) ',
        },
        {
          id: 16,
          title: 'Б-8',
          type: 'workplace',
          shape: 'poly',
          coords: [728, 46, 717, 120, 811, 147, 840, 80, 783, 60],
          preFillColor: 'rgba(237, 168, 226, 0.4)',
          fillColor: 'rgba(227, 84, 203, 0.2)',
          strokeColor: 'rgba(232, 51, 214)',
        },
        {
          id: 17,
          title: 'Б-9',
          type: 'workplace',
          shape: 'poly',
          coords: [840, 80, 811, 147, 854, 171, 895, 198, 942, 140, 893, 106],
          preFillColor: 'rgba(245, 242, 159, 0.4)',
          fillColor: 'rgba(232, 226, 51, 0.2)',
          strokeColor: 'rgba(222, 33, 46)',
        },
        {
          id: 18,
          title: 'Б-10',
          type: 'workplace',
          shape: 'poly',
          coords: [942, 140, 895, 198, 967, 269, 1019, 215, 982, 178, 962, 157],
          preFillColor: 'rgba(0, 255, 0, 0.15)',
          fillColor: 'rgba(0, 255, 0, 0.2)',
          strokeColor: 'rgba(222, 33, 46) ',
        },
        {
          id: 19,
          title: 'Б-11',
          type: 'workplace',
          shape: 'poly',
          // coords: [540, 123, 556, 4, 580, 6, 602, 12, 622, 19, 644, 27, 595, 138, 540, 124],
          coords: [1019, 215, 967, 269, 1032, 337, 1085, 284],
          preFillColor: 'rgba(245, 242, 159, 0.4)',
          fillColor: 'rgba(232, 226, 51, 0.2)',
          strokeColor: 'rgba(222, 33, 46)',
        },
        {
          id: 20,
          title: 'Б-12',
          type: 'workplace',
          shape: 'poly',
          // coords: [540, 123, 556, 4, 580, 6, 602, 12, 622, 19, 644, 27, 595, 138, 540, 124],
          coords: [1085, 284, 1032, 337, 1103, 410, 1156, 356],
          preFillColor: 'rgba(245, 242, 159, 0.4)',
          fillColor: 'rgba(232, 226, 51, 0.2)',
          strokeColor: 'rgba(222, 33, 46)',
        },
        {
          id: 21,
          title: 'Б-13',
          type: 'workplace',
          shape: 'poly',
          // coords: [540, 123, 556, 4, 580, 6, 602, 12, 622, 19, 644, 27, 595, 138, 540, 124],
          coords: [448, 212, 491, 262, 555, 222, 528, 161],
          preFillColor: 'rgba(237, 168, 226, 0.4)',
          fillColor: 'rgba(227, 84, 203, 0.2)',
          strokeColor: 'rgba(232, 51, 214)',
        },
        {
          id: 23,
          title: 'Б-14',
          type: 'workplace',
          shape: 'poly',
          // coords: [540, 123, 556, 4, 580, 6, 602, 12, 622, 19, 644, 27, 595, 138, 540, 124],
          coords: [528, 161, 555, 222, 628, 203, 616, 135],
          preFillColor: 'rgba(245, 242, 159, 0.4)',
          fillColor: 'rgba(232, 226, 51, 0.2)',
          strokeColor: 'rgba(222, 33, 46)',
        },
        {
          id: 24,
          title: 'Б-15',
          type: 'workplace',
          shape: 'poly',
          //  coords: [619, 142, 628, 203, 704, 204, 713, 142, 683, 136, 648, 136],
          coords: [616, 135, 628, 203, 704, 204, 714, 135],
          preFillColor: 'rgba(0, 255, 0, 0.15)',
          fillColor: 'rgba(0, 255, 0, 0.2)',
          strokeColor: 'rgba(222, 33, 46)',
        },
        {
          id: 25,
          title: 'Б-16',
          type: 'workplace',
          shape: 'poly',
          // coords: [540, 123, 556, 4, 580, 6, 602, 12, 622, 19, 644, 27, 595, 138, 540, 124],
          coords: [714, 135, 704, 204, 781, 226, 806, 163],
          preFillColor: 'rgba(237, 168, 226, 0.4)',
          fillColor: 'rgba(227, 84, 203, 0.2)',
          strokeColor: 'rgba(232, 51, 214)',
        },
        {
          id: 26,
          title: 'Б-17',
          type: 'workplace',
          shape: 'poly',
          // coords: [540, 123, 556, 4, 580, 6, 602, 12, 622, 19, 644, 27, 595, 138, 540, 124],
          coords: [806, 163, 781, 226, 840, 265, 883, 211],
          preFillColor: 'rgba(245, 242, 159, 0.4)',
          fillColor: 'rgba(232, 226, 51, 0.2)',
          strokeColor: 'rgba(222, 33, 46)',
        },
        {
          id: 27,
          title: 'Б-18',
          type: 'workplace',
          shape: 'poly',
          coords: [883, 211, 840, 265, 894, 313, 939, 267],
          preFillColor: 'rgba(0, 255, 0, 0.15)',
          fillColor: 'rgba(0, 255, 0, 0.2)',
          strokeColor: 'rgba(222, 33, 46)',
        },
      ],
    },
  },
  a: {
    title: 'Блок А',
    map: {
      name: 'my-map',
      areas: [
        /*
        {
          title: 'Рабочая зона А-1',
          type: 'workplace',
          shape: 'poly',
          coords: [193, 140, 150, 198, 238, 264, 280, 205],
          preFillColor: 'rgba(0, 255, 0, 0.15)',
          fillColor: 'rgba(0, 255, 0, 0.2)',
        },
        {
          title: 'Рабочая зона А-2',
          type: 'workplace',
          shape: 'poly',
          coords: [280, 205, 238, 264, 325, 327, 368, 270],
          preFillColor: 'rgba(255,0,0, 0.3)',
          fillColor: 'rgba(255,0,0, 0.4)',
        },
        {
          title: 'Рабочая зона А-3',
          type: 'workplace_2',
          shape: 'poly',
          coords: [368, 270, 325, 327, 406, 389, 450, 330],
          preFillColor: 'rgba(0, 255, 0, 0.15)',
          fillColor: 'rgba(0, 255, 0, 0.2)',
        },
        {
          title: 'Рабочая зона А-4',
          type: 'workplace',
          shape: 'poly',
          coords: [450, 330, 406, 389, 494, 454, 522, 472, 564, 406, 539, 391],
          preFillColor: 'rgba(255,0,0, 0.3)',
          fillColor: 'rgba(255,0,0, 0.4)',
        },
        {
          title: 'Рабочая зона А-5',
          type: 'workplace_2',
          shape: 'poly',
          coords: [564, 406, 522, 472, 561, 492, 596, 505, 622, 511, 636, 438],
          preFillColor: 'rgba(0, 255, 0, 0.15)',
          fillColor: 'rgba(0, 255, 0, 0.2)',
        },
        */
        {
          id: '3',
          title: 'А-1',
          type: 'workplace',
          shape: 'poly',
          coords: [636, 438, 622, 511, 664, 517, 691, 519, 726, 517, 761, 512, 744, 437, /* 789, 505, 763, 432, */ 720, 439, 685, 441],
          preFillColor: 'rgba(255,0,0, 0.3)',
          fillColor: 'rgba(255,0,0, 0.4)',
        },
        {
          id: '4',
          title: 'А-2',
          type: 'workplace_2',
          shape: 'poly',
          coords: [744, 437, 761, 512, 788, 505, 816, 494, 864, 470, 889, 452, 844, 392, 807, 414, 772, 430],
          preFillColor: 'rgba(245, 242, 159, 0.4)',
          fillColor: 'rgba(232, 226, 51, 0.2)',
          strokeColor: 'rgba(222, 33, 46)',
        },
        {
          id: '5',
          title: 'А-3',
          type: 'workplace_2',
          shape: 'poly',
          coords: [844, 392, 889, 452, 976, 387, 931, 327],
          preFillColor: 'rgba(0, 255, 0, 0.15)',
          fillColor: 'rgba(0, 255, 0, 0.2)',
        },
        {
          id: '6',
          title: 'А-4',
          type: 'workplace',
          shape: 'poly',
          coords: [931, 327, 976, 387, 1063, 323, 1018, 264],
          preFillColor: 'rgba(245, 242, 159, 0.4)',
          fillColor: 'rgba(232, 226, 51, 0.2)',
          strokeColor: 'rgba(222, 33, 46)',
        },
        {
          id: '7',
          title: 'А-5',
          type: 'workplace_2',
          shape: 'poly',
          coords: [1018, 264, 1063, 323, 1149, 259, 1106, 200],
          preFillColor: 'rgba(0, 255, 0, 0.15)',
          fillColor: 'rgba(0, 255, 0, 0.2)',
        },
        /* {
          id: '35',
          title: 'А-6',
          type: 'workplace_2',
          shape: 'poly',
          coords: [694, 357, 694, 426, 666, 425, 674, 355],
          preFillColor: 'rgba(245, 242, 159, 0.4)',
          fillColor: 'rgba(232, 226, 51, 0.2)',
          strokeColor: 'rgba(222, 33, 46)',
        }, */
        {
          id: '8',
          title: 'А-7',
          type: 'workplace_2',
          shape: 'poly',
          coords: [694, 357, 694, 426, 747, 421, 729, 353],
          preFillColor: 'rgba(0, 255, 0, 0.15)',
          fillColor: 'rgba(0, 255, 0, 0.2)',
        },
        {
          id: '9',
          title: 'А-8',
          type: 'workplace',
          shape: 'poly',
          coords: [729, 353, 747, 421, 777, 411, 799, 401, 815, 392, 839, 376, 798, 317],
          preFillColor: 'rgba(245, 242, 159, 0.4)',
          fillColor: 'rgba(232, 226, 51, 0.2)',
          strokeColor: 'rgba(222, 33, 46)',
        },
        {
          id: '10',
          title: 'А-9',
          type: 'workplace_2',
          shape: 'poly',
          coords: [798, 317, 839, 376, 884, 342, 841, 286],
          preFillColor: 'rgba(0, 255, 0, 0.15)',
          fillColor: 'rgba(0, 255, 0, 0.2)',
        },
      /*  {
          id: '34',
          title: 'А-10',
          type: 'workplace_2',
          shape: 'poly',
          coords: [916, 293, 969, 254, 980, 269, 926, 308],
          preFillColor: 'rgba(0, 255, 0, 0.15)',
          fillColor: 'rgba(0, 255, 0, 0.2)',
        }, */
      ],
    },
  },
};
