import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import Button from '../../../../components/UI/Button/Button';
import Input from '../../../../components/UI/Input';
import Select from '../../../../components/UI/Select';
import Toggle from '../../../../components/UI/Toggle';
import TextArea from '../../../../components/UI/TextArea';
import { catchHandler } from '../../../../utils/error_handling/error_handling';
import { alertFunction } from '../../../../utils/functions/alertFunction';
import { clientSendData } from '../../../../utils/functions/requests';
import classes from '../../../../components/UI/Button/button.module.scss';
import { setOperInfo, log } from '../../../../utils/functions/others';
import CardSetting from '../../../../components/Card/CardSetting';

// Сервис анкетирования - вкладка "Анкеты" - карточка анкеты - вкладка "Настройки"
function Settings(props) {
  const {
    getQuestionnairies,
    setShowCard,
    currentQuestionnaire, // текущая анкета, отображаемая в карточке
    setCurrentQuestionnaire,
    questionnairiesOutputs,

  } = props; // свойства передаваемые компоненту при вызове
  const location = useLocation(); // адрес
  const [isLoading, setIsLoading] = useState(false); // Состояние загрузки
  const currentDirection = useSelector((state) => state.questionnaire.current_direction); // текущее направление
  const userGroups = useSelector((state) => state.questionnaire.user_directions); // направления доступные пользователю
  const { questionnaire } = currentQuestionnaire;

  // Переключение параметра
  function setParameter(parametres) {
    setCurrentQuestionnaire({
      ...currentQuestionnaire,
      questionnaire: {
        ...questionnaire,
        ...parametres,
      },
    }); // запись в хранилище
  }

  // Выбор группы
  function chooseDirection(direction) {
    // обновление данных, связанных с изменением направления
    function changeGroup() {
      setCurrentQuestionnaire({
        ...currentQuestionnaire,
        questionnaire: { // Изменение данных в объекте анкеты
          ...currentQuestionnaire.questionnaire,
          group_id: direction.id, // обновление id направления
          group: direction.title, // обновление имени направления
        },
        questions: [], // сброс выбранных вопросов при смене направления
      }); // запись в хранилище
      log({ message: `Пользователь в карточке анкеты id: ${currentQuestionnaire.questionnaire.id} выбрал направление "${direction.title}"(id: ${direction.id})`, eventtype: 'info' });
    }

    if (currentQuestionnaire.questions.length > 0) { // если с анкетой связаны какие то вопросы
      // всплывает запрос на подтверждение
      const confirm = window.confirm('При смене группы выполнится сброс связанных вопросов!');
      if (confirm) {
        changeGroup(); // если пользователь подтверждает - группа меняется
        return true;
      } return false; // иначе функция возвращает false
      // это используется в компоненте селекта для предотвращения изменения выбора
      // т.е чтобы группа не изменялась визуально
    }
    changeGroup(); // если с анкетой не связано никаких вопросов то группа меняется
    return true;
  }

  // Копировать анкету
  async function copyQuestionnaire() {
    try {
      const confirm = window.confirm('Создать копию?'); // подтверждение
      if (confirm) { // если пользователь подтвердил
        const reqData = {
          type: 'copyQuestionnaire',
          questionnaire_id: currentQuestionnaire.questionnaire.id, // id анкеты
        };
        setIsLoading(true); // состояние - загружается
        const result = await clientSendData('POST', '/copy_questionnaire', reqData); // запрос в БД
        if (result === 'success') { // если успех
          alertFunction('save_settings', 'clientPost'); // уведомление "Настройки сохранены"
          await getQuestionnairies(location.pathname, currentDirection); // анкеты обновляются
          setShowCard(false); // карточка закрывается
          log({ message: `Пользователь в карточке анкеты id: ${questionnaire.id} нажал кнопку "Дублировать"`, eventtype: 'info' });
        }
        setIsLoading(false); // загрузка завершена
      }
    } catch (error) {
      catchHandler(error, 'copyQuestionnaire');
      setIsLoading(false); // загрузка завершена
    }
  }

  // Удалить анкету
  async function deleteQuestionnaire() {
    try {
      const confirm = window.confirm('Вы действительно хотите далить?'); // подтверждение
      if (confirm) { // если пользователь подтвердил
        const reqData = {
          type: 'deleteQuestionnaire',
          questionnaire_id: questionnaire.id, // id анкеты
        };
        setIsLoading(true); // состояние - загружается
        const result = await clientSendData('POST', '/delete_questionnaire', reqData); // запрос в БД
        if (result === 'bad_request') { // если некорректный запрос
          alertFunction('bad_request', 'clientPost'); // уведомление
          setIsLoading(false); // загрузка завершена
        } else if (result === 'success') { // если успех
          alertFunction('save_settings', 'clientPost'); // уведомление "Настройки сохранены"
          await getQuestionnairies(location.pathname, currentDirection); // анкеты обновляются
          setOperInfo({ subject_id: null }); // сброс id анкеты в оперативной информации
          setIsLoading(false); // загрузка завершена
          setShowCard(false); // карточка закрывается
          log({ message: `Пользователь в карточке анкеты id: ${questionnaire.id} нажал кнопку "Удалить"`, eventtype: 'info', subject_id: questionnaire.id });
        }
      } else return; // если пользователь не подтвердил - выход
    } catch (error) {
      catchHandler(error, 'deleteQuestionnaire');
      setIsLoading(false); // загрузка завершена
    }
  }

  // Обработчик выбора формата
  async function chooseOutputFormat(output) {
    if (output.id === 3) {
      setParameter({ // если формат одностраничный - нельзя скачать анкету в файл
        output_id: output.id, // обновление id отображения
        output_title: output.title, // обновление имени отображения
        downloadable: false,
      });
    } else if (output.id === 4) {
      const sequence = {};
      currentQuestionnaire.questions.forEach((el, index) => {
        sequence[el.id] = {
          break: el.break != null ? el.break : null,
          number: el.index != null ? el.index : index,
          dependence: el.dependence != null ? el.dependence : null,
          dependence_answer: el.dependence_answer != null ? el.dependence_answer : null,
        };
      });
      setParameter({
        output_id: output.id, // обновление id отображения
        output_title: output.title, // обновление имени отображения
        sequence: JSON.stringify(sequence, null, ' '),
      });
    } else {
      setParameter({
        output_id: output.id, // обновление id отображения
        output_title: output.title, // обновление имени отображения
      });
    }
    log({ message: `Пользователь в карточке анкеты id: ${currentQuestionnaire.questionnaire.id} выбрал тип отображения "${output.title}"(id: ${output.id})`, eventtype: 'info' });
  }

  return (
    <div className="questions-card__block">

      {/* Имя анкеты */}
      <CardSetting title="Имя" invalid={!questionnaire?.title}>
        <Input
          onChange={(e) => setParameter({ title: e.target.value })}
          placeholder="Введите имя*"
          defaultValue={questionnaire.title || ''}
        />
      </CardSetting>

      {/* Группа анкеты */}
      <CardSetting title="Направление" invalid={!questionnaire?.group_id}>
        <Select
          id="questionnaire-card__direction"
          array={userGroups}
          onChoose={chooseDirection}
          defaultValue={questionnaire.group}
        >
          Направление
        </Select>
      </CardSetting>

      {/* Формат отображения */}
      <CardSetting title="Формат отображения" invalid={!questionnaire?.output_id}>
        <Select
          id="questionnaire-card__output-type"
          array={questionnairiesOutputs}
          onChoose={chooseOutputFormat}
          defaultValue={questionnaire.output_title}
        />
      </CardSetting>

      {/* Последовательность вопросов */}
      {questionnaire.output_id === 4 && (
      <CardSetting title="Редактирование последовательности" invalid={!questionnaire?.sequence}>
        <TextArea
          onChange={(e) => setParameter({ sequence: e.target.value })}
          placeholder="Введите последовательность*"
          defaultValue={questionnaire.sequence ? questionnaire.sequence : ''}
        />
      </CardSetting>)}

      {/* Включена/Выключена */}
      <CardSetting title={questionnaire.enabled ? 'Включена' : 'Выключена'}>
        <Toggle
          state={String(questionnaire.enabled)}
          onClick={() => {
            setParameter({ enabled: !questionnaire.enabled });
            log({ message: `Пользователь в карточке анкеты id: ${questionnaire.id} ${!questionnaire.enabled ? ' включил её' : ' выключил её'}`, eventtype: 'info' });
          }}
        />
      </CardSetting>

      {/* Комментарии к вопросам */}
      <CardSetting title={`Комментарии к вопросам${questionnaire.wiki ? ' (недоступно в режиме справочника)' : ''}`}>
        <Toggle
          state={String(questionnaire?.comments)}
          onClick={() => {
            setParameter({ comments: (questionnaire.wiki ? false : !questionnaire?.comments) });
            log({ message: `Пользователь ${questionnaire.wiki ? 'попытался включить' : !questionnaire.comments ? 'включил' : 'выключил'} комментарии к вопросам в карточке анкеты (id: ${questionnaire.id})`, eventtype: 'info' });
          }}
        />
      </CardSetting>

      {/* Справочник */}
      <CardSetting title="Справочник">
        <Toggle
          state={String(questionnaire.wiki)}
          onClick={() => {
            setParameter({
              wiki: !questionnaire.wiki,
              downloadable: false,
              comments: false,
            });
            log({
              message: `Пользователь в карточке анкеты id: ${questionnaire.id} ${!questionnaire.wiki ? 'включил' : 'выключил'} тип анкеты справочник`,
              eventtype: 'info',
            });
          }}
        />
      </CardSetting>

      {questionnaire.wiki && (
      <CardSetting title="Импорт в файл">
        <Toggle
          state={String(questionnaire.downloadable)}
          onClick={() => {
            setParameter({ downloadable: !questionnaire.downloadable });
            log({
              message: `Пользователь в карточке анкеты id: ${questionnaire.id} ${!questionnaire.downloadable ? 'включил' : 'выключил'} параметр 'Импорт в файл'`,
              eventtype: 'info',
            });
          }}
        />
      </CardSetting>)}

      {/* Кнопка "Дублировать" только для редактируемой анкеты */}
      {questionnaire.id !== 'new' && (
      <CardSetting title="Создать копию">
          <Button className={classes.button_ghost} onClick={copyQuestionnaire}>Дублировать</Button>
      </CardSetting>)}

      {/* Кнопка "Удалить" только для редактируемой анкеты */}
      {questionnaire.id !== 'new' && (
      <CardSetting title="Удалить">
        <Button className={classes.button_ghost} onClick={deleteQuestionnaire}>Удалить</Button>
      </CardSetting>)}
    </div>
  );
}

export default Settings;
