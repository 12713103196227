/* eslint-disable consistent-return */
import { catchHandler } from '../error_handling/error_handling';

// //Функция хеширования
// export async function digestMessage(message, algo) {
//     try {
//         if (algo == null) {
//           algo = "SHA-256";
//         }

//         const msgUint8 = new TextEncoder().encode(message);                           // encode as (utf-8) Uint8Array
//         const hashBuffer = await crypto.subtle.digest(algo, msgUint8);                // hash the message
//         const hashArray = Array.from(new Uint8Array(hashBuffer));                     // convert buffer to byte array
//         const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
//         return hashHex;
//     } catch(error) {
//         catchHandler(error, "digestMessage")
//     }
//   }

// Функция шифрования
export function encode_sting_by_string(string, secret) {
  try {
    const encoded = new TextEncoder();
    const buffer_string = encoded.encode(string);
    const buffer_secret = encoded.encode(secret);
    let buffer = new Array(buffer_string.byteLength);
    let j = 0;
    for (let i = 0; i < buffer_string.byteLength; i++) {
      let dis = 0;
      if ((buffer_string[i] + buffer_secret[j]) > 255) {
        dis = (buffer_string[i] + buffer_secret[j]) - 255;
      } else {
        dis = buffer_string[i] + buffer_secret[j];
      }
      buffer[i] = dis;
      if ((j + 1) < buffer_secret.byteLength) {
        j += 1;
      } else {
        j = 0;
      }
    }
    buffer = buffer.join('\\');
    return buffer;
  } catch (error) {
    catchHandler(error, 'encode_sting_by_string');
  }
}

// Функция дешифрования
export function decode_sting_by_string(string, secret) {
  try {
    const encoded = new TextEncoder();
    const decoded = new TextDecoder();
    const buffer_string = string.split('\\');
    const buffer_secret = encoded.encode(secret);
    const decode_buffer = new Uint8Array(buffer_string.length);
    let decoded_string = '';
    let j = 0;
    for (let i = 0; i < buffer_string.length; i++) {
      let dis = 0;
      if (((buffer_string[i] * 1) - buffer_secret[j]) < 0) {
        dis = 255 - buffer_secret[j] + (buffer_string[i] * 1);
      } else {
        dis = (buffer_string[i] * 1) - buffer_secret[j];
      }
      decode_buffer[i] = dis;
      if ((j + 1) < buffer_secret.byteLength) {
        j += 1;
      } else {
        j = 0;
      }
    }
    decoded_string = decoded.decode(decode_buffer);
    return decoded_string;
  } catch (error) {
    catchHandler(error, 'decode_sting_by_string');
  }
}
