// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#instructions__table {
  max-width: 1200px;
}
#instructions__table tr {
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr 0.5fr 0.5fr;
}
#instructions__table thead tr:first-child, #instructions__table tfoot tr:first-child {
  grid-template-columns: repeat(auto-fit, minmax(20px, 1fr));
}
#instructions__card {
  max-width: 800px;
}

.instructions__upload-img {
  width: 100%;
  max-width: 20px;
  transition: 0.3s;
}
.instructions__upload-img:hover {
  cursor: pointer;
  transform: scale(1.1);
  transition: 0.3s;
  filter: brightness(1.2) drop-shadow(1px 1px 2px var(--primary_color));
}
.instructions__upload-img:active {
  transform: scale(1);
  transition: 0.3s;
}`, "",{"version":3,"sources":["webpack://./src/pages/Instructions/instructions.scss"],"names":[],"mappings":"AAGE;EACE,iBAAA;AAFJ;AAGI;EACE,0DAAA;AADN;AAKM;EACE,0DAAA;AAHR;AAQE;EACE,gBAAA;AANJ;;AAWE;EACE,WAAA;EACA,eAAA;EACA,gBAAA;AARJ;AASI;EACE,eAAA;EACA,qBAAA;EACA,gBAAA;EACA,qEAAA;AAPN;AASI;EACE,mBAAA;EACA,gBAAA;AAPN","sourcesContent":["@use \"../../variables.scss\" as var;\n\n#instructions {\n  &__table {\n    max-width: 1200px;\n    & tr {\n      grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr 0.5fr 0.5fr;\n    }\n    & thead,\n    & tfoot {\n      & tr:first-child {\n        grid-template-columns: repeat(auto-fit, minmax(20px, 1fr));\n      }\n    }\n  }\n\n  &__card {\n    max-width: 800px;\n  }\n}\n\n.instructions {\n  &__upload-img {\n    width: 100%;\n    max-width: 20px;\n    transition: 0.3s;\n    &:hover {\n      cursor: pointer;\n      transform: scale(1.1);\n      transition: 0.3s;\n      filter: brightness(1.2) drop-shadow(1px 1px 2px var.$primary_color);\n    }\n    &:active {\n      transform: scale(1);\n      transition: 0.3s;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
