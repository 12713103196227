import React, { useEffect, useState } from 'react';
import Loader from '../../../components/UI/Loader';
import { getKbHistory } from './functions';

/**
* @component База знаний - Сущность - карточка сущности - вкладка История
* @prop {id} number - id сущности в таблице БД
* @prop {type} number - id типа сущности
*/
function History(props) {
  const { id, type } = props;
  const [history, setHistory] = useState([]); // логи по направлению
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки

  useEffect(() => {
    getHistory(); // получить логи
  }, []);

  // Обёртка для функции получения логов для управления состоянием загрузки
  async function getHistory() {
    setIsLoading(true); // состояние - загружается
    const result = await getKbHistory(id, type); // унифицированная функция получения логов
    setHistory(result);
    setIsLoading(false); // загрузка завершена
  }

  // Показать объект
  function showObject(object) {
    // Проверяем, что входной параметр object является объектом и не null.
    if (typeof object === 'object' && object !== null) {
      // Если объект является массивом, отображаем его содержимое через запятую.
      if (Array.isArray(object)) {
        return <p className="upu__setting-title" dangerouslySetInnerHTML={{ __html: object.join(', ') }} />;
      }
      // Если объект не является массивом, отображаем его свойства и значения.
      return Object.keys(object).map((key) => {
        const keyData = object[key];
        return (
          <div className="upu-card__setting">
            <p className="upu__setting-title">{`${key}:`}</p>
            {showObject(keyData)}
          </div>
        );
      });

      // Если входной параметр не является объектом, отображаем его значение.
    } return <p className="upu__setting-title" dangerouslySetInnerHTML={{ __html: String(object) }} />;
  }

  // если загружается - показывается лоадер
  if (isLoading) return <Loader />;
  // Если логи пустые - показывается заголовок об их отсутствии
  if (history.length === 0) return <h2 className="upu-service__title">История отсутствует</h2>;
  // Иначе отображается история
  return (
    <div className="questions-card__block">
      {history.map((entry) => {
        const {
          id, object, version, datetime, performer,
        } = entry;
        return (
          <div key={id} className="question-history">
            <p className="upu__setting-title">{datetime}</p>
            <p className="upu__setting-title">{performer}</p>
            <p className="upu__setting-title">{`Версия: ${version}`}</p>
            {Object.keys(object).map((key) => {
              const keyData = object[key];
              return (
                <div key={key} className="upu-card__setting">
                  <p className="upu__setting-title"><b>{`${key}:`}</b></p>
                  {showObject(keyData)}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}

export default History;
