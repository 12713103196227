import React, { useEffect, useState } from 'react';
import Input from '../../../../components/UI/Input';
import { catchHandler } from '../../../../utils/error_handling/error_handling';
import { clientSendData } from '../../../../utils/functions/requests';
import Select from '../../../../components/UI/Select';
import InputSearch from '../../../../components/UI/InputSearch';
import CardSetting from '../../../../components/Card/CardSetting';
import IconButton from '../../../../components/UI/Button/IconButton';

function Settings(props) {
  const {
    object, setObject, deleteApplication, isFromProject,
  } = props;

  // Состояние результатов поиска по умолчанию
  const searchResultsDefault = {
    client: [], // массив клиентов
    project: [], // массив договоров
    approver: [], // массив руководителей
  };
  // Состояние данных поиска по умолчанию
  const searchDataDefault = {
    string: null, // искомая строка
    target: 'client', // цель поиска
  };

  const [loadingElement, setLoadingElement] = useState(''); // состояние загрузки
  const [searchResults, setSearchResults] = useState(searchResultsDefault); // результаты поиска
  const [searchData, setSearchData] = useState(searchDataDefault); // данные для поиска
  const [isMounted, setIsMounted] = useState(false); // параметр монтирования компонента (для отмены первого useEffecta)
  const isNewApp = object?.id === 'new'; // это новое заявление?
  const delay = 600; // задержка перед поиском

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isMounted) {
      const timeOutId = setTimeout(async () => { // запуск таймаута выполнения поиска
        const { string, target } = searchData;
        if (object) searchHandler(string, target);
      }, delay);
      return () => clearTimeout(timeOutId); // очистка таймаута
    } setIsMounted(true);
  }, [searchData]);

  // Обработчик поиска
  async function searchHandler(string, target) {
    try {
      // Если в объекте уже есть искомое поле - сбросить его значение
      // сброс id искомого поля в объекте новой ПО (для валидации)
      if (`${target}_id` in object) setObject({ ...object, [`${target}_id`]: null });

      // Если искомая строка пустая или меньше 2 символов
      if (!string || string.length <= 2) {
        setSearchResults({ ...searchResults, [target]: [] }); // сбросить результаты
      } else {
        const reqData = {
          type: 'searchApplicationData',
          target,
          string,
        };
        setLoadingElement(target); // состояние - загружается
        const result = await clientSendData('POST', '/search_application_data', reqData); // запрос в БД
        setLoadingElement(''); // загрузка завершена
        setSearchResults({ ...searchResults, [target]: result }); // записать результаты в состояние
      }
    } catch (error) {
      catchHandler(error, 'searchHandler'); // обработка ошибок
    }
  }

  // Выбор клиента
  async function chooseClient(client) {
    try {
      // При выборе клиента запрашиваем все договора с ним в разбивке по проектам
      const reqData = {
        type: 'searchApplicationData',
        target: 'contract',
        client_id: client.id,
      };
      setLoadingElement('contract'); // состояние - загружается
      const result = await clientSendData('POST', '/search_application_data', reqData); // запрос в БД
      setLoadingElement(''); // загрузка завершена
      setSearchResults({
        ...searchResults,
        contract: result,
      }); // записать результаты в состояние

      // Данные для новой ПО
      const newData = {
        project_id: null,
        client_id: client?.id,
        client_title: client?.title,
        contract_id: null,
        contract_title: null,
        approver_id: null,
        approver_title: null,
      };

      // Если договор всего 1 - заполнить остальные поля автоматически
      if (result.length === 1) {
        // newData.project_id = result[0]?.project_id
        newData.project_id = result[0]?.id;
        newData.contract_id = result[0]?.contract_id;
        newData.contract_title = result[0]?.title;
        // newData.approver_id = result[0]?.approver_id
        // newData.approver_title = result[0]?.approver_title
      }

      // Обновить объект ПО с учётом новых данных
      setObject({ ...object, ...newData });
    } catch (error) {
      catchHandler(error, 'chooseClient'); // обработка ошибок
    }
  }

  // Изменить значение поля
  function changeSetting(setting) {
    setObject({ ...object, ...setting });
  }

  // Проверить на наличие значение
  function checkValue(value) {
    if (value) return value;
    return '';
  }

  // if (isLoading) return <Loader/>
  if (!object) return <h2 className="upu-service__title">Создание/изменение недоступно</h2>;
  return (
    <div className="upu-card__block">

      {/* Дата начала */}
      <CardSetting title="Дата начала" invalid={!object?.date_start}>
        <Input
          type="date"
          placeholder="Дата начала"
          value={checkValue(object?.date_start)}
          onChange={(e) => changeSetting({ date_start: e.target.value })}
        />
      </CardSetting>

      {/* Дата завершения */}
      <CardSetting title="Дата завершения" invalid={!object.date_end}>
        <Input
          type="date"
          placeholder="Дата завершения"
          value={checkValue(object?.date_end)}
          onChange={(e) => changeSetting({ date_end: e.target.value })}
        />
      </CardSetting>

      {/* Поиск клиента */}
      {isNewApp && (
      <CardSetting title="Выбор клиента" invalid={!object?.client_id}>
        {isFromProject ? (
        <p className="upu-card__setting-title">{object?.client_title}</p>
        ) : (
        <InputSearch
          id="evaluation__search-client"
          array={searchResults.client}
          onSearch={(e) => setSearchData({ target: 'client', string: e.target.value })}
          onChoose={chooseClient}
          placeholder={object?.client_title || 'Клиент'}
          loading={loadingElement === 'client'}
          defaultValue={object?.client_title}
        />)}
      </CardSetting>)}

      {/* Выбор договора */}
      {isNewApp && (
      <CardSetting title="Договор" invalid={!object?.contract_id && !object?.project_id}>
        {isFromProject ? (
        <p className="upu-card__setting-title">{object?.contract_title}</p>
        ) : (
        <Select
          id="evaluation__choose-contract"
          array={searchResults.contract}
          onChoose={(contract) => changeSetting({
            // project_id: contract?.project_id,
            project_id: contract?.id,
            contract_id: contract?.contract_id,
            contract_title: contract?.title,
            // approver_id: contract?.approver_id,
            // approver_title: contract?.approver_title
          })}
          loading={loadingElement === 'contract'}
          defaultValue={object?.contract_title || 'Выберите договор'}
          disabled={!object?.client_id}
        />)}
      </CardSetting>)}

      {/* Выбор/поиск руководителя */}
      <CardSetting title="Руководитель проекта" invalid={!object?.approver_id}>
        <InputSearch
          id="evaluation__search-approver"
          array={searchResults.approver}
          onSearch={(e) => setSearchData({ target: 'approver', string: e.target.value })}
          onChoose={(approver) => changeSetting({
            approver_id: approver?.id,
            approver_title: approver?.title,
          })}
          placeholder="Выберите руководителя проекта"
          loading={loadingElement === 'approver'}
          defaultValue={object?.approver_title}
          disabled={isNewApp && !object?.client_id}
        />
      </CardSetting>

      {/* Удаление заявления */}
      {!isNewApp && (
      <CardSetting title="Удаление">
          <IconButton icon="delete" theme="red" onClick={deleteApplication} />
      </CardSetting>)}
    </div>
  );
}

export default Settings;
