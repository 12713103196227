import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { authorization } from '../../utils/functions/authenticate';
import { setOperInfo } from '../../utils/functions/others';
import Approver from './Approver/Approver';
import Employee from './Employee/Employee';
import Loader from '../../components/UI/Loader';
import Cap from '../Cap/Cap';
import Service from '../../components/Service/Service';
import ApplicationPage from './Common/Application/ApplicationPage';
import EvaluationCap from './Common/EvaluationCap';
import { clientSendData } from '../../utils/functions/requests';
import { catchHandler } from '../../utils/error_handling/error_handling';
import './Common/evaluation.scss';
import EmployeeInfo from './Employee/EmployeeInfo';
import ApproverInfo from './Approver/ApproverInfo';

// Сервис оценок - Основной компонент
function Evaluation() {
  // const applicationId = Number(useSearchParams()[0].get('app')); // id ПО из url
  const applicationId = useSearchParams()[0].get('app'); // id ПО из url
  const projectId = useSearchParams()[0].get('projectId'); // id проекта ПО из url
  const isAdmin = useSearchParams()[0].get('isAdmin'); //
  const [isAuthorized, setIsAuthorized] = useState(false); // Состояние авторизации
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [pages, setPages] = useState([]); // страницы доступные пользователю
  const components = { // Компоненты для отображения в сервисе
    employee: <Employee />, // Интерфейс сотрудника
    employee_info: <EmployeeInfo />, // Доп информация для сотрудника
    approver: <Approver />, // Интерфейс руководителя
    approver_info: <ApproverInfo />, // Доп информация для руководителя
  };

  useEffect(() => {
    firstRender();
  }, []);

  // Функция первого рендера компонента
  async function firstRender() {
    try {
      setIsLoading(true); // загрузка началась
      setOperInfo(); // запись в оперативную информацию
      const checkAuthorization = await authorization(); // авторизация
      setIsAuthorized(checkAuthorization); // обновление состояния авторизации
      if (checkAuthorization) await getUserInterfaces(); // получить интерфейсы пользователя
      setIsLoading(false); // загрузка завершена
    } catch (error) {
      catchHandler(error, 'firstRender');
    }
  }

  // Получить интерфейсы доступные пользователю
  async function getUserInterfaces() {
    try {
      const reqData = {
        type: 'getUserInterfaces',
        location: window.location.pathname,
      };
      const result = await clientSendData('POST', '/get_user_interfaces', reqData);
      const reqDataProjects = { type: 'findProjectEvs', is_approver: true };
      const resultProjects = await clientSendData('POST', '/find_project_evs', reqDataProjects); // запрос в БД
      if (result) {
        const userPages = []; // Пустой массив для страниц
        result?.forEach((item) => {
          const component = item?.component; // Имя компонента
          // Проверяем, есть ли такой компонент в объекте 'components'
          if (components?.[component]) {
            // Если компонент существует, добавляем две страницы
            userPages.push(
              { // Компонент с основной информацией
                id: component, // идентификатор страницы
                title: item.title, // заголовок страницы
                component: components[component], // соответствующий компонент
              },
            );
          }
        });
        if (resultProjects.length) {
          userPages.push({ // Компонент с дополнительной информацией
            id: 'approver_info',
            title: 'Моя команда',
            component: components.approver_info,
          });
        }
        setPages(userPages); // Результат записывается в состояние
      }
    } catch (error) {
      catchHandler(error, 'getUserInterfaces');
    }
  }

  // если идет загрузка - показать лоадер
  if (isLoading) return <Loader />;

  // если пользователь авторизован
  if (isAuthorized) {
    // если в параметрах url присутствует значение "app" показываем компонент заявления
  //  if (applicationId) return <ApplicationPage applicationId={applicationId} />;
    if (applicationId) return <ApplicationPage applicationId={applicationId} projectId={projectId} isAdmin={isAdmin} />;
    // если пользователю не доступен ни один интерфейс - заглушка
    if (pages.length === 0) return <EvaluationCap title="Доступные интерфейсы отсутствуют" />;
    // иначе показываем то, что доступно пользователю
    return <Service id="evaluation" pages={pages} horizontal />;
    // если пользователь не авторизован - показать заглушку
  } return <Cap />;
}

export default Evaluation;
