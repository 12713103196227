import React, { useEffect, useState } from 'react';
import InputSearch from '../UI/InputSearch';
import { catchHandler } from '../../utils/error_handling/error_handling';
import { clientSendData } from '../../utils/functions/requests';

/**
 * @component Поиск пользователей в upu_users с выбором вариантов
 * @prop {onChoose} function (Обязательный) обработчик выбора - получает на выход выбранный объект
 * @prop {onSearch} function обработчик поиска, выполняется вместе с поиском
 * @prop {field} string наименования поля в upu_users для поиска (по умолчанию cn)
 * @prop {defaultValue} string значение отображаемое в инпуте по умолчанию
 * @prop {placeholder} string атрибут инпута - placeholder
 * @prop {disabled} boolean атрибут инпута - disabled
*/

function SearchUpuUser(props) {
  const {
    onChoose, onSearch, field, defaultValue, placeholder, disabled,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]); // результаты поиска

  // Обработчик поиска
  async function searchHandler(string) {
    try {
      if (onSearch) onSearch();

      // Если искомая строка пустая или меньше 2 символов
      if (!string || string.length <= 2) {
        setSearchResults([]); // сбросить результаты
      } else {
        const reqData = {
          type: 'searchUpuUser',
          string,
          field,
        };
        setIsLoading(true); // состояние - загружается
        const result = await clientSendData('POST', '/search_upu_user', reqData); // запрос в БД
        setIsLoading(false); // загрузка завершена
        setSearchResults(result); // записать результаты в состояние
      }
    } catch (error) {
      setIsLoading(false); // загрузка завершена
      catchHandler(error, 'searchHandler'); // обработка ошибок
    }
  }

  return (
    <InputSearch
      id="forms__user-search"
      array={searchResults}
      onSearch={(e) => searchHandler(e.target.value)}
      onChoose={onChoose}
      placeholder={placeholder || ''}
      loading={isLoading}
      defaultValue={defaultValue || ''}
      disabled={disabled}
    />
  );
}

export default SearchUpuUser;
