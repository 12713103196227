import React, { useState } from 'react';
import CardBody from '../../../components/Card/CardBody';
import CardHeader from '../../../components/Card/CardHeader';
import Card from '../../../components/Card/Card';
import CardSetting from '../../../components/Card/CardSetting';
import Select from '../../../components/UI/Select';
import CardFooter from '../../../components/Card/CardFooter';
import Button from '../../../components/UI/Button/Button';
import QuestionsRequest from './QuestionsRequest';
import NewQuestionsRequest from './NewQuestionsRequest';

/**
  * @component Клиентский портал - новый запрос - карточка создания запроса проекта
  * @prop {setNewShowCard} function - обновление состояния отображения карточки (открыть/закрыть)
  * @prop {refreshTable} function - обновление данных таблицы
*/
function NewRequestCard(props) {
  const { setNewShowCard, refreshTable } = props;

  const [project, setProject] = useState({}); // объект с данными заявки по проекту
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [selectedCard, setSelectedCard] = useState(null); // состояние выбранной карточки

  const questionaries = [ // список опросников
    { title: 'Опросник 1', id: 1 },
    { title: 'Свой опросник', id: 3 },
  ];

  // Функция для создания карточки в зависимости от выбранного опросника
  function handleQuestionaryChange(questionary) {
    setProject((state) => ({
      ...state,
      questionary_id: questionary.id,
      questionary: questionary.title,
    }));

    switch (questionary.id) {
      case 1:
        setSelectedCard(<QuestionsRequest />);
        break;
      case 3:
        setSelectedCard(<NewQuestionsRequest />);
        break;
      default:
        setSelectedCard(null); // Если нет подходящего опросника, не показываем карточку
    }
  }

  // Функция для создания запроса
  function handleAddRequest() {
    // Логика для создания запроса
    // Например, отправка данных на сервер или обновление состояния
    console.log('Создание запроса с данными:', project);
    refreshTable(); // Обновление таблицы после создания запроса
  }

  // Закрыть карточку
  function closeHandler() {
    setNewShowCard(false); // Закрытие карточки
  }

  return (
    <Card id="clients_portal__new-project-card" setShow={closeHandler} loading={isLoading}>
      <CardHeader />
      <CardBody>
        {/* -----------Опросник----------- */}
        <CardSetting title="Опросник:" invalid={!project.questionary_id}>
          <Select
            id="clients_portal__new-project-questionary"
            array={questionaries}
            onChoose={handleQuestionaryChange}
            defaultValue={project.questionary || 'Выберите опросник'}
          />
        </CardSetting>
        {selectedCard}
{' '}
{/* Отображение выбранной карточки */}
      </CardBody>
      <CardFooter>
        <Button onClick={handleAddRequest}>Добавить</Button>
        <Button onClick={closeHandler}>Закрыть</Button>
      </CardFooter>
    </Card>
  );
}

export default NewRequestCard;
