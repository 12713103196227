import React, { useState, useEffect } from 'react';
import CardHeader from '../components/Card/CardHeader';
import Card from '../components/Card/Card';
import TabPanel from '../components/UI/TabPanel';
import Button from '../components/UI/Button/Button';
import TabUI from './TabUI';
import TabTable from './TabTable';
import Groups from './AdminHelpdesk/Groups';
import GroupCategories from './AdminHelpdesk/GroupCategories';
import NotificationEditor from './AdminHelpdesk/NotificationEditor';
import Estimation from './AdminHelpdesk/Estimation';
import NewBookingCard from './Workplaces/Booking/BookingCard/NewBookingCard';
import Workplaces from './Workplaces/Workplaces';
// import MobileConnection from './MobileConnection/MobileConnection';
// import InputSearch from '../components/UI/InputSearch';
import Assets from './HelpDesk/Assets/Assets';

function TestPage() {
  const [showCard, setShowCard] = useState(true);
  const [choice, setChoice] = useState(''); // состояние выбора вкладки
  const tabs = ['UI', 'Table']; // вкладки
  const content = { UI: <TabUI />, Table: <TabTable /> };
  function choiceHandler(tab) {
    setChoice(tab); // установить выбор
  }

  /* <Card setShow={setShowCard}>
      <CardHeader />
      <TabPanel tabs={tabs} choice={choice} choiceHandler={choiceHandler} />
      {content[choice]}

  </Card> */
  if (!showCard) return <Button onClick={() => setShowCard(true)}>Отобразить карточку</Button>;
  return (
  // <Workplaces />
  // <Assets />
  <iframe src="https://unigram.unicon.ru/" width="100%" title="Unigram" />
  );
}

export default TestPage;
