import React, { useEffect, useState } from 'react';
import {
  taskTypes,
} from './common';
import CardHeader from '../../components/Card/CardHeader';
import CardBody from '../../components/Card/CardBody';
import Card from '../../components/Card/Card';
import CardSetting from '../../components/Card/CardSetting';
import Input from '../../components/UI/Input';
import TextArea from '../../components/UI/TextArea';
import Select from '../../components/UI/Select';
import CardFooter from '../../components/Card/CardFooter';
import Button from '../../components/UI/Button/Button';
import { clientSendData } from '../../utils/functions/requests';
import { catchHandler } from '../../utils/error_handling/error_handling';
import { alertFunction } from '../../utils/functions/alertFunction';
import './maintenanceDepartment.scss';
/**
 * @component Заявка - карточка создания заявки на новое рабочее место
 * @prop {setShowCard} function - обновление состояния отображения карточки (открыть/закрыть)
 */

function MaintenanceDepartment() {
  const [newMDCard, setNewMDCard] = useState({ taskType: '', stationery: [] }); // объект с данными заявки
  const [selectData, setSelectData] = useState({
    id: 0, // или любое другое начальное значение id
    title: '',
    quantity: '',
  }); // объект с выбранными канцтоварами
  const [nextId, setNextId] = useState(1);
  const [newStationery, setNewStationery] = useState([]); // объект с выбранными канцтоварами
  const [stationery, setStationery] = useState([]); // объект с канцтоварами
  const [time, setTime] = useState([]); // объект с промежутками ремени
  const [blocks, setBlocks] = useState([]); // объект с блоками
  const [troubleShooting, setTroubleShooting] = useState([]); // объект с блоками
  const [number, setNumber] = useState(''); // количество
  const [title, setTitle] = useState(''); // наименование
  const [isActive, setIsActive] = useState(false);
  const clearFilelds = {};
  const nameFields = {
    taskType: 'Тип заявки',
    user: 'ФИО сотрудника',
    car: 'Автомобиль',
    car_brand: 'Марка автомобиля',
    car_model: 'Модель автомобиля',
    car_number: 'Гос. номер автомобиля',
    fio: 'ФИО гостя',
    fio_staff: 'ФИО сотрудников',
    comment: 'Примечание',
    time: 'Промежуток времени',
    stationery: 'Наименование и количество',
    troubleShooting: 'Тип неисправности',
    troubleShootingСomment: 'Описание неисправности',
    blocks: 'Блок',
    workPlace: 'Номер рабочего места',
    date_start: 'Дата',
    loaderDate: 'Дата перемещения',
    loaderPlace: 'Место перемещения',
    loaderTMC: 'Список переносимых ТМЦ',

  };

  useEffect(() => {
    getDataForMdForm();
  }, []);

  async function getDataForMdForm() {
    try {
      const reqData = { type: 'getDataForMdForm' };
      const result = await clientSendData('POST', '/get_data_for_md_form', reqData);
      if (result?.success) {
        const parsedValue = JSON.parse(result.data[0].value);
        setStationery(parsedValue.stationery);
        setBlocks(parsedValue.blocks);
        setTime(parsedValue.time);
        setTroubleShooting(parsedValue.troubleShooting);
      }
    } catch (error) {
      catchHandler(error, 'getDataForMdForm');
    }
  }

  async function createNewMDCardFile() {
    try {
      const mandatoryFields = {
        'Заявка на внос/вынос через дебаркайдер': ['taskType', 'time', 'date_start', 'fio_staff', 'stationery', 'car_brand', 'car_model', 'car_number'],
        'Заявка на внос/вынос через 3 этаж': ['taskType', 'date_start', 'fio_staff', 'stationery'],
        'Заказ гостевых пропусков': ['taskType', 'date_start', 'fio'],
        'Заказ канцтоваров': ['taskType', 'stationery'],
        'Заявка на устранение неисправностей в офисе': ['taskType', 'troubleShooting', 'blocks', 'workPlace', 'troubleShootingСomment'],
        'Грузчики (перенос ТМЦ)': ['taskType', 'loaderDate', 'loaderPlace', 'loaderTMC'],
      };

      const categoryMapping = {
        'Заявка на внос/вынос через дебаркайдер': 192,
        'Заявка на внос/вынос через 3 этаж': 192,
        'Заказ гостевых пропусков': 191,
        'Заказ канцтоваров': 193,
        'Заявка на устранение неисправностей в офисе': 190,
        'Грузчики (перенос ТМЦ)': 190,
      };

      const requiredFields = mandatoryFields[newMDCard.taskType];

      const allFieldsPresent = requiredFields.every((field) => newMDCard[field]);

      const category_id = categoryMapping[newMDCard.taskType] || 190;
      const validateTicket = allFieldsPresent;
      if (validateTicket) {
        const ticket = {
          status_id: 1,
          title: `Внутрихозяйственная деятельность (${newMDCard.taskType})`,
        };
        let content = '';
        Object.keys(newMDCard).forEach((el) => {
          if (Array.isArray(newMDCard[el]) && newMDCard[el].length > 0) {
            // Если значение - массив и он не пустой, перебираем его элементы
            const items = newMDCard[el].map((item) => `${item.title}: ${item.quantity}`).join(', ');
            content += `${nameFields[el]}: ${items}\n`;
          } else if (!Array.isArray(newMDCard[el]) && newMDCard[el] !== '') {
            // Если значение - обычный тип данных и не пустое, добавляем его в строку как обычно
            content += `${nameFields[el]}: ${newMDCard[el]}\n`;
          }
        });
        ticket.content = content;
        ticket.category_id = category_id;
        const reqData = { type: 'createMDTicketWithFile', newMDCard, ticket };
        const result = await clientSendData(
          'POST',
          '/create_md_ticket_with_file/manual',
          reqData,
        );
        if (result?.success) {
          await alertFunction('ticket_created', 'clientPost');
          setNewMDCard(clearFilelds);
          setSelectData(clearFilelds);
          setNewStationery([]);
        }
      } else {
        await alertFunction('required_fields', 'clientPost');
      }
    } catch (error) {
      catchHandler(error, 'getCommonTicketData');
    }
  }
  async function addStationery(newSt) {
    if (newStationery.some((s) => s.title === newSt.title)) {
      await alertFunction('sd_select_exist', 'clientPost');
    } else if (newSt.title && newSt.quantity) { // Проверяем, есть ли title и quantity
      // 1. Обновляем состояние `newStationery`
      setNewStationery((state) => [...state, newSt]);

      // 2. Обновляем состояние `newMDCard`
      setNewMDCard((state) => ({
        ...state,
        stationery: [...state.stationery, newSt], // Убираем проверку на существование state.stationery
      }));

      // 3. Обновляем состояние nextId
      setNextId((prevId) => prevId + 1);

      // 4. Обновляем состояние `selectData` (сбрасываем id)
      setSelectData({
        id: 0, // Сбрасываем id
        title: '',
        quantity: '',
      });
      setNumber('');
      setTitle('');
    } else {
      // Выводим сообщение, если title или quantity не заполнены
      await alertFunction('ff_reports_verify_inputs', 'clientPost');
    }
  }

  function deleteStationery(deleteStId) {
    // 1. Обновляем состояние `newStationery`
    setNewStationery((state) => state.filter((el) => el.id !== deleteStId));

    // 2. Обновляем состояние `newMDCard`
    setNewMDCard((state) => ({
      ...state,
      stationery: state.stationery.filter((el) => el.id !== deleteStId),
    }));
  }

  return (
    <Card setShow={() => {}} id="maintenanceDepartment__new-card">
      <CardHeader>
        <h3>Заявка в службу АХО</h3>
      </CardHeader>
      <CardBody>
        {/* -----------ТИП ЗАЯВКИ----------- */}
        <CardSetting title="Тип заявки:">
          <Select
            id="taskTypes"
            array={taskTypes}
            onChoose={(t) => setNewMDCard((state) => ({
              ...state,
              taskType: t.title,
            }))
            }
            defaultValue={newMDCard.taskType || 'Выберите тип заявки'}
          />
        </CardSetting>

        {/* -----------Заявка на внос/вынос через дебаркайдер----------- */}
        {newMDCard.taskType === 'Заявка на внос/вынос через дебаркайдер' && (

              <>
              <p style={{
                padding: '10px 8px', borderBottom: '1px solid #CAD0D4', display: 'flex', justifyContent: 'space-between',
              }}
              >
                <span>Ограничения:</span>
                <span style={{ fontStyle: 'italic' }}>Размер вносимых ТМЦ не должен превышать 600 мм * 600 мм * 600 мм.</span>
              </p>
                {/* -----------Марка автомобиля----------- */}
                <CardSetting
                  title="Марка автомобиля:"
                  invalid={!newMDCard.car_brand}
                >
                  <Input
                    value={newMDCard?.car_brand || ''}
                    placeholder="Введите марку автомобиля"
                    onChange={(e) => setNewMDCard((state) => ({
                      ...state,
                      car_brand: e.target.value,
                    }))
                    }
                  />
                </CardSetting>

                <CardSetting
                  title="Модель автомобиля:"
                  invalid={!newMDCard.car_model}
                >
                  <Input
                    value={newMDCard?.car_model || ''}
                    placeholder="Введите модель автомобиля"
                    onChange={(e) => setNewMDCard((state) => ({
                      ...state,
                      car_model: e.target.value,
                    }))
                    }
                  />
                </CardSetting>

                <CardSetting
                  title="Номер автомобиля:"
                  invalid={!newMDCard.car_number}
                >
                  <Input
                    value={newMDCard?.car_number || ''}
                    placeholder="Введите номер автомобиля"
                    onChange={(e) => setNewMDCard((state) => ({
                      ...state,
                      car_number: e.target.value,
                    }))
                    }
                  />
                </CardSetting>

            {/* -----------ДАТА----------- */}
            <CardSetting
              title="Дата прибытия:"
              invalid={!newMDCard.date_start}
            >
              <Input
                type="date"
                value={newMDCard?.date_start || ''}
                onChange={(e) => setNewMDCard({
                  ...newMDCard,
                  date_start: e.target.value,
                })
                }
              />
            </CardSetting>

            {/* -----------Блок----------- */}
            <CardSetting title="Блок:" invalid={!newMDCard.blocks}>
              <Select
                id="blocks"
                array={blocks}
                onChoose={(b) => setNewMDCard((state) => ({ ...state, blocks: b.title }))
                }
                defaultValue={
                  newMDCard.blocks || 'Выберите блок'
                }
              />
            </CardSetting>

            {/* -----------Время----------- */}
            <CardSetting title="Промежуток времени:" invalid={!newMDCard.time}>
              <Select
                id="time"
                array={time}
                onChoose={(t) => setNewMDCard((state) => ({ ...state, time: t.title }))
                }
                defaultValue={
                  newMDCard.time || 'Выберите промежуток времени'
                }
              />
            </CardSetting>

                       {/* -----------ФИО сотрудников----------- */}
           <CardSetting title="Сотрудники для транспортировки:" invalid={!newMDCard.fio_staff}>
              <Input
                placeholder="Введите ФИО сотрудников"
                onChange={(e) => setNewMDCard((state) => ({
                  ...state,
                  fio_staff: e.target.value,
                }))
                }
              />
           </CardSetting>

            {/* -----------Список ТМЦ ----------- */}
            <CardSetting title="Наименование имущества:" invalid={!newMDCard.stationery}>
              <Input
                placeholder="Введите наименование"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                  setSelectData((state) => ({
                    ...state,
                    id: nextId,
                    title: e.target.value,
                  }));
                }
                }
              />
            </CardSetting>

            <CardSetting title="Количество (шт):">
            <Input
              type="number"
              min="1"
              value={number}
              placeholder="Кол-во штук"
              onChange={(e) => {
                setNumber(e.target.value);
                setSelectData({ ...selectData, quantity: e.target.value });
              }}
            />
            </CardSetting>

            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '5px' }}>
                <Button onClick={() => addStationery(selectData)}>Добавить</Button>
            </div>

            <div className="tags__list">
                {newStationery?.map((a) => <p className="tags__tag" key={a.id}>
                                              <span className="upu__close" onClick={() => deleteStationery(a.id)}>X</span>
                                              {a.title}
                                              {': '}
                                              {`${a.quantity} шт.`}
                                           </p>)}
            </div>

            {/* -----------Примечание----------- */}
            <CardSetting
              title="Примечание:"
            >
              <TextArea
                value={newMDCard?.comment || ''}
                onChange={(e) => setNewMDCard({
                  ...newMDCard,
                  comment: e.target.value,
                })
                }
              />
            </CardSetting>

              </>
        )}

        {/* -----------Заявка на внос/вынос через дебаркайдер----------- */}
        {newMDCard.taskType === 'Заявка на внос/вынос через 3 этаж' && (
          <>
<p style={{
  padding: '10px 8px', borderBottom: '1px solid #CAD0D4', display: 'flex', justifyContent: 'space-between',
}}
>
  <span>Ограничения:</span>
  <span style={{ fontStyle: 'italic' }}>Размер вносимых ТМЦ не должен превышать 600 мм * 600 мм * 600 мм.</span>
</p>

            {/* -----------ДАТА----------- */}
            <CardSetting
              title="Дата прибытия:"
              invalid={!newMDCard.date_start}
            >
              <Input
                type="date"
                value={newMDCard?.date_start || ''}
                onChange={(e) => setNewMDCard({
                  ...newMDCard,
                  date_start: e.target.value,
                })
                }
              />
            </CardSetting>

            {/* -----------Блок----------- */}
            <CardSetting title="Блок:" invalid={!newMDCard.blocks}>
              <Select
                id="blocks"
                array={blocks}
                onChoose={(b) => setNewMDCard((state) => ({ ...state, blocks: b.title }))
                }
                defaultValue={
                  newMDCard.blocks || 'Выберите блок'
                }
              />
            </CardSetting>

                       {/* -----------ФИО сотрудников----------- */}
           <CardSetting title="Сотрудники для транспортировки:" invalid={!newMDCard.fio_staff}>
              <Input
                placeholder="Введите ФИО сотрудников"
                onChange={(e) => setNewMDCard((state) => ({
                  ...state,
                  fio_staff: e.target.value,
                }))
                }
              />
           </CardSetting>

            {/* -----------Список ТМЦ ----------- */}
            <CardSetting title="Наименование имущества:" invalid={!newMDCard.stationery}>
              <Input
                placeholder="Введите наименование"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                  setSelectData((state) => ({
                    ...state,
                    id: nextId,
                    title: e.target.value,
                  }));
                }
                }
              />
            </CardSetting>

            <CardSetting title="Количество (шт):">
            <Input
              type="number"
              min="1"
              value={number}
              placeholder="Кол-во штук"
              onChange={(e) => {
                setNumber(e.target.value);
                setSelectData({ ...selectData, quantity: e.target.value });
              }}
            />
            </CardSetting>

            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '5px' }}>
                <Button onClick={() => addStationery(selectData)}>Добавить</Button>
            </div>

            <div className="tags__list">
                {newStationery?.map((a) => <p className="tags__tag" key={a.id}>
                                              <span className="upu__close" onClick={() => deleteStationery(a.id)}>X</span>
                                              {a.title}
                                              {': '}
                                              {`${a.quantity} шт.`}
                                           </p>)}
            </div>

            {/* -----------Примечание----------- */}
            <CardSetting
              title="Примечание:"
            >
              <TextArea
                value={newMDCard?.comment || ''}
                onChange={(e) => setNewMDCard({
                  ...newMDCard,
                  comment: e.target.value,
                })
                }
              />
            </CardSetting>

          </>
        )}

        {/* -----------Заказ гостевых пропусков----------- */}
        {newMDCard.taskType === 'Заказ гостевых пропусков' && (
          <>
            {/* -----------ИМЯ ГОСТЯ----------- */}
            <CardSetting title="Полное имя гостя:" invalid={!newMDCard.fio}>
              <Input
                value={newMDCard?.fio || ''}
                placeholder="Введите ФИО гостя"
                onChange={(e) => setNewMDCard((state) => ({
                  ...state,
                  fio: e.target.value,
                }))
                }
              />
            </CardSetting>

            {/* -----------ДАТА----------- */}
            <CardSetting
              title="Дата прибытия:"
              invalid={!newMDCard.date_start}
            >
              <Input
                type="date"
                value={newMDCard?.date_start || ''}
                onChange={(e) => setNewMDCard({
                  ...newMDCard,
                  date_start: e.target.value,
                })
                }
              />
            </CardSetting>

          </>
        )}

        {/* -----------Заказ канцтоваров----------- */}
        {newMDCard.taskType === 'Заказ канцтоваров' && (
          <>
            {/* -----------Список ТМЦ ----------- */}
            <CardSetting title="Выберите канцтовары:" invalid={!newMDCard.stationery}>

                              <Select
                                id="stationery"
                                array={stationery}
                                onChoose={(s) => setSelectData((state) => ({
                                  ...state,
                                  id: nextId,
                                  title: s.title,
                                }))}
                                defaultValue={selectData?.title}
                              />

            </CardSetting>

            <CardSetting title="Количество (шт):">
                <Input
                  type="number"
                  min="1"
                  value={number}
                  placeholder="Кол-во штук"
                  onChange={(e) => {
                    setNumber(e.target.value);
                    setSelectData({ ...selectData, quantity: e.target.value });
                  }}
                />
            </CardSetting>

               <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '5px' }}>
              <Button onClick={() => addStationery(selectData)}>Добавить</Button>
               </div>

               <div className="tags__list">
                    {newStationery?.map((a) => <p className="tags__tag" key={a.id}>
                        <span className="upu__close" onClick={() => deleteStationery(a.id)}>X</span>
                       {a.title}
                      {': '}
                      {`${a.quantity} шт.`}
                                               </p>)}
               </div>

          </>
        )}

        {/* -----------Заявка на устранение неисправностей в офисе----------- */}
        {newMDCard.taskType
          === 'Заявка на устранение неисправностей в офисе' && (
          <>
            {/* -----------Типы неисправностей ----------- */}
            <CardSetting
              title="Тип неисправности:"
              invalid={!newMDCard.troubleShooting}
            >
              <Select
                id="troubleShooting"
                array={troubleShooting}
                onChoose={(t) => setNewMDCard((state) => ({
                  ...state,
                  troubleShooting: t.title,
                }))
                }
                defaultValue={
                  newMDCard.troubleShooting || 'Выберите тип неисправности'
                }
              />
            </CardSetting>
            {/* -----------Блок----------- */}
            <CardSetting title="Блок:" invalid={!newMDCard.blocks}>
              <Select
                id="blocks"
                array={blocks}
                onChoose={(b) => setNewMDCard((state) => ({ ...state, blocks: b.title }))
                }
                defaultValue={
                  newMDCard.blocks || 'Выберите блок'
                }
              />
            </CardSetting>

            {/* -----------Номер рабочего места----------- */}
            <CardSetting
              title="Номер рабочего места:"
              invalid={!newMDCard.workPlace}
            >
              <Input
                value={newMDCard?.workPlace || ''}
                placeholder="Введите номер рабочего места"
                onChange={(e) => setNewMDCard((state) => ({
                  ...state,
                  workPlace: e.target.value,
                }))
                }
              />
            </CardSetting>
            {/* -----------СООБЩЕНИЕ----------- */}
            <CardSetting
              title="Опишите неисправность:"
              invalid={!newMDCard.troubleShootingСomment}
            >
              <TextArea
                value={newMDCard?.troubleShootingСomment || ''}
                onChange={(e) => setNewMDCard({
                  ...newMDCard,
                  troubleShootingСomment: e.target.value,
                })
                }
              />
            </CardSetting>
          </>
        )}

        {/* -----------Грузчики (перенос ТМЦ)----------- */}
        {newMDCard.taskType === 'Грузчики (перенос ТМЦ)' && (
          <>
            {/* -----------ДАТА----------- */}
            <CardSetting title="Дата:" invalid={!newMDCard.loader_date_tmc}>
              <Input
                type="date"
                value={newMDCard?.loaderDate || ''}
                onChange={(e) => setNewMDCard({
                  ...newMDCard,
                  loaderDate: e.target.value,
                })
                }
              />
            </CardSetting>

            {/* -----------Список переносимых ТМЦ----------- */}
            <CardSetting
              title="Место перемещения (откуда-куда):"
              invalid={!newMDCard.loaderPlace}
            >
              <Input
                value={newMDCard?.loaderPlace || ''}
                placeholder="Место перемещения (откуда-куда)"
                onChange={(e) => setNewMDCard((state) => ({
                  ...state,
                  loaderPlace: e.target.value,
                }))
                }
              />
            </CardSetting>

            {/* -----------СООБЩЕНИЕ----------- */}
            <CardSetting
              title="Список переносимых ТМЦ, количество, упаковка:"
              invalid={!newMDCard.loaderTMC}
            >
              <TextArea
                value={newMDCard?.loaderTMC || ''}
                onChange={(e) => setNewMDCard({
                  ...newMDCard,
                  loaderTMC: e.target.value,
                })
                }
              />
            </CardSetting>
          </>
        )}
      </CardBody>
      <CardFooter>
        <Button onClick={createNewMDCardFile}>Создать</Button>
        <Button onClick={() => setNewMDCard(clearFilelds)}>Очистить</Button>
      </CardFooter>
    </Card>
  );
}

export default MaintenanceDepartment;
