import React, { useState } from 'react';
import { alertFunction } from '../../../../../utils/functions/alertFunction';
import { catchHandler } from '../../../../../utils/error_handling/error_handling';
import { taskStatus } from '../../../common';
import InputSearch from '../../../../../components/UI/InputSearch';
import CardSetting from '../../../../../components/Card/CardSetting';
import TextArea from '../../../../../components/UI/TextArea';
import Button from '../../../../../components/UI/Button/Button';
import classes from '../../../../../components/UI/Button/button.module.scss';
import Modal from '../../../../../components/UI/Modal';

/**
  * @component ХелпДеск - заявки - карточка заявки - вкладка "Наряды"
  * @prop {task} object - объект отображаемого наряда
  * @prop {tasks} task[] - массив всех нарядов
  * @prop {ticketTasksHandler} function - обработчик нарядов
  * @prop {getTasks} function - получение нарядов (для обновления данных)
  * @prop {editingTask} object - редактируемый наряд
  * @prop {setEditingTask} function - обновление состояния редактируемого наряда
  * @prop {additionalInfo} object - объект с доп. информацией (сотрудники, группы)
  * @prop {searchWrapper} function - обработчик поиска
  * @prop {choosePerformer} function - обработчик выбора исполнителя наряда
  * @prop {chooseGroup} function - обработчик выбора группы
  * @prop {isSearching} boolean - параметр выполнения поиска
*/
function Task(props) {
  const {
    task, tasks, ticketTasksHandler, getTasks, editingTask, setEditingTask, additionalInfo, searchWrapper, choosePerformer, chooseGroup, isSearching,
  } = props;
  const {
    id, title, status, status_id, comment = '', performer, group, date_created, creator, modifier, date_modified,
  } = task;
  const smallButtonClass = `${classes.button} ${classes.small}`; // классы стилей для маленькой кнопки
  const isEditing = editingTask?.id === id; // наряд редактируется?
  const [temporaryTitle, setTemporaryTitle] = useState(title); // редактирование заголовка
  const [displayModal, setDisplayModal] = useState(false);

  // Изменение наряда
  async function editTask() {
    try {
      const requiredFields = ['title', 'group_id', 'status_id'];
      // Если есть данные во всех необходимых полях
      if (requiredFields.every((field) => editingTask[field])) {
        const confirm = window.confirm('Сохранить изменения?'); // запросить подтверждение
        if (confirm) { // если подтверждено
          // Данные наряда до изменений
          const editingTaskDefault = tasks.find((task) => task.id === editingTask.id);
          const result = await ticketTasksHandler('edit', { ...editingTask, title: temporaryTitle }, editingTaskDefault); // ждем сохранение изменений наряда
          if (result?.success) { // если есть результат (значит запись прошла успешно, на бэке это обрабатывается)
            await getTasks('get'); // ждем обновление списка нарядов
            setEditingTask({}); // сбрасываем данные редактируемого наряда (чтобы скрыть инпуты в измененном наряде)
          } else await alertFunction('something_went_wrong', 'clientPost'); // иначе запись не прошла, показываем уведомление
        }
      } else await alertFunction('required_fields', 'clientPost');
    } catch (error) {
      catchHandler(error, 'editTask');
    }
  }

  // Выполнение наряда
  async function completeTask(task) {
    try {
      const { id, title, performer_id } = task;
      const confirm = window.confirm('Выполнить наряд?'); // запросить подтверждение
      if (confirm) { // если подтверждено
        const result = await ticketTasksHandler('complete', { id, title, performer_id }); // ждем сохранение изменений наряда
        if (result.success) { // если есть результат (значит запись прошла успешно, на бэке это обрабатывается)
          if (result.performer_mismatch) {
            await alertFunction('task_performer_mismatch', 'clientPost');
          } else {
            await alertFunction('save_settings', 'clientPost');
            await getTasks('get'); // ждем обновление списка нарядов
          }
        } else await alertFunction('something_went_wrong', 'clientPost'); // иначе запись не прошла, показываем уведомление
      }
    } catch (error) {
      catchHandler(error, 'completeTask');
    }
  }

  // Выполнение наряда
  async function takeTask(task) {
    try {
      const { id, title } = task;
      const confirm = window.confirm('Взять наряд в работу?'); // запросить подтверждение
      if (confirm) { // если подтверждено
        const result = await ticketTasksHandler('take', { id, title }, task); // ждем сохранение изменений наряда
        if (result.success) { // если есть результат (значит запись прошла успешно, на бэке это обрабатывается)
          await alertFunction('save_settings', 'clientPost');
          await getTasks('get'); // ждем обновление списка нарядов
        } else await alertFunction('something_went_wrong', 'clientPost'); // иначе запись не прошла, показываем уведомление
      }
    } catch (error) {
      catchHandler(error, 'completeTask');
    }
  }

  async function revertTask() {
    if (editingTask.commentAdd) {
      editingTask.comment += `\n-=-=-=-=-=-=-=-=-=-\n${editingTask.commentAdd}`;
      editingTask.status_id = 1;
      await editTask();
    } else {
      alertFunction('sd_comment_required', 'client');
      setDisplayModal(true);
    }
  }

  return (
    <>
      <CardSetting title="Статус:">{status}</CardSetting>
      <CardSetting title="Название:">
  {!isEditing ? title : (
    <InputSearch
      id={`helpdesk__ticket-search-title_${id}`}
      array={[]} // массив данных, если нужно выполнять поиск
      onSearch={(e) => setTemporaryTitle(e.target.value)}
      defaultValue={temporaryTitle}
      placeholder="Введите заголовок"
      loading={false} // true, если есть асинхронный поиск
    />
  )}
      </CardSetting>
      <CardSetting title="Группа:" invalid={isEditing && !editingTask?.group_id}>
        {!isEditing ? group : (
        <InputSearch
          id={`helpdesk__ticket-search-group_${id}`}
          array={additionalInfo.group}
          onSearch={(e) => searchWrapper(e, 'group')}
          onChoose={(data) => chooseGroup(data, 'editing')}
          defaultValue={editingTask?.group}
          placeholder="/ - показать все"
          loading={isSearching}
        />
        )}
      </CardSetting>
      <CardSetting title="Исполнитель:">
        {!isEditing ? performer : (
        <InputSearch
          id={`helpdesk__ticket-search-performer_${id}`}
          array={additionalInfo.performer}
          onSearch={(e) => searchWrapper(e, 'performer')}
          onChoose={(data) => choosePerformer(data, 'editing')}
          defaultValue={editingTask?.performer}
          placeholder="/ - показать все"
          loading={isSearching}
        />
        )}
      </CardSetting>
      <CardSetting title="Создание:">{`${creator} - ${date_created}`}</CardSetting>
      {date_modified && <CardSetting title="Последнее изменение:">{`${modifier} - ${date_modified}`}</CardSetting>}

      <CardSetting title="Комментарий к наряду:">
        {!isEditing ? (
    <span className="red-comment">{comment}</span>
        ) : (
    <TextArea
      onChange={(e) => setEditingTask((state) => ({ ...state, comment: e.target.value }))}
      placeholder="Комментарий к наряду"
      defaultValue={comment}
    />
        )}
      </CardSetting>

      {/* ВЫПОЛНИТЬ И ИЗМЕНИТЬ */}
      {status_id === taskStatus.new && !isEditing && (
        <CardSetting>
          {/* <Button className={smallButtonClass} onClick={() => ticketTasksHandler('delete', { id })}>Удалить</Button> */}
          {performer
            ? <Button className={smallButtonClass} onClick={() => completeTask(task)}>Выполнить</Button>
            : <Button className={smallButtonClass} onClick={() => takeTask(task)}>Взять наряд</Button>}
          <Button className={smallButtonClass} onClick={() => setEditingTask(task)}>Изменить</Button>
        </CardSetting>
      )}

      {status_id === taskStatus.done && <Button className={smallButtonClass} onClick={() => setDisplayModal(true)}>Вернуть в работу</Button>}

      {/* СОХРАНИТЬ И ОТМЕНИТЬ */}
      {isEditing && (
        <CardSetting>
          <Button className={smallButtonClass} onClick={editTask}>Сохранить</Button>
          <Button className={smallButtonClass} onClick={() => setEditingTask({})}>Отменить</Button>
        </CardSetting>
      )}

      <Modal active={displayModal} setActive={setDisplayModal} callback={revertTask}>
             <CardSetting title="Укажите комментарий:">
              <TextArea onChange={(e) => setEditingTask({ ...task, commentAdd: e.target.value })} />
             </CardSetting>
      </Modal>

    </>
  );
}

export default Task;
