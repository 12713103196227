import React, { useEffect, useState } from 'react';
import Table from '../../components/Table/Table';
import THead from '../../components/Table/THead';
import TBody from '../../components/Table/TBody';
import TRow from '../../components/Table/TRow';
import { clientSendData } from '../../utils/functions/requests';
import { catchHandler } from '../../utils/error_handling/error_handling';
import { headers } from './common';
import TData from '../../components/Table/TData';
import TFoot from '../../components/Table/TFoot';
import ServiceBody from '../../components/Service/ServiceBody';
import Input from '../../components/UI/Input';

import './adminmd.scss';

function AdminMd() {
  const [inputText, setInputText] = useState('');
  const [categories, setCategories] = useState([]);
  const [data, setData] = useState([]);
  const [groups, setGroups] = useState([]);
  const [groupsCategories, setGroupsCategories] = useState([]);
  const [selCategory, setSelCategory] = useState({});
  const [changeData, setChangeData] = useState({});
  const [stationery, setStationery] = useState([]); // объект с канцтоварами
  const [time, setTime] = useState([]); // объект с промежутками ремени
  const [blocks, setBlocks] = useState([]); // объект с блоками
  const [troubleShooting, setTroubleShooting] = useState([]); // объект с типами неисправностей

  useEffect(() => {
    getDataForMdForm();
  }, []);

  async function editMdFormCategories(newData) {
    try {
      const reqData = {
        data: newData,
        type: 'editMdForm',
      };
      const result = await clientSendData('POST', '/edit_md_form', reqData); // запрос в БД action: useState

      if (result) {
        setData(result);
      }
    } catch (error) {
      catchHandler(error, 'editMdForm'); // обработчик ошибок
    } finally { getDataForMdForm(); }
  }

  async function getDataForMdForm() {
    try {
      const reqData = {
        type: 'getDataForMdForm',
      };
      const result = await clientSendData('POST', '/get_data_for_md_form', reqData); // запрос в БД
      if (result) {
        const parsedValue = JSON.parse(result.data[0].value);
        setData(parsedValue);
        setStationery(parsedValue.stationery);
        setBlocks(parsedValue.blocks);
        setTime(parsedValue.time);
        setTroubleShooting(parsedValue.troubleShooting);
      }
    } catch (error) {
      catchHandler(error, 'getDataForMdForm'); // обработчик ошибок
    }
  }

  function handleRemoveItem(category, id) {
    if (category === 'time') {
      setTime((prevState) => {
        const newState = prevState.filter((item) => item.id !== id);
        updateChangeData('time', newState);
        return newState;
      });
    } else if (category === 'blocks') {
      setBlocks((prevState) => {
        const newState = prevState.filter((item) => item.id !== id);
        updateChangeData('blocks', newState);
        return newState;
      });
    } else if (category === 'stationery') {
      setStationery((prevState) => {
        const newState = prevState.filter((item) => item.id !== id);
        updateChangeData('stationery', newState);
        return newState;
      });
    } else if (category === 'troubleShooting') {
      setTroubleShooting((prevState) => {
        const newState = prevState.filter((item) => item.id !== id);
        updateChangeData('troubleShooting', newState);
        return newState;
      });
    }
  }

  function updateChangeData(category, newData) {
    setData((prevChangeData) => ({
      ...prevChangeData,
      [category]: newData,
    }));
  }

  function handleAddItem(category, text) {
    // Определите функцию обновления для каждой категории
    const updateCategory = {
      stationery: setStationery,
      blocks: setBlocks,
      time: setTime,
      troubleShooting: setTroubleShooting,
    };

    // Проверьте, существует ли функция обновления для данной категории
    if (updateCategory[category]) {
      updateCategory[category]((prevState) => {
        const newId = prevState.length > 0 ? String(Number(prevState[prevState.length - 1].id) + 1) : '1';
        const newState = [...prevState, { id: newId, title: text }];
        updateChangeData(category, newState);
        return newState;
      });
    }
  }

  console.log(data);

  return (
   <ServiceBody id="adminMd">
    <Table id="adminMd">
        <THead
          title="Настройки формы АХО"
          headers={headers.serviceGroups}
        />
        <TBody>
                <TRow>
                  <TData>Канцтовары</TData>
                  <TData key={stationery.id}>
                  <div className="tags__input-block">
                              <Input
                                className="tags__input"
                                onChange={(e) => setInputText(e.target.value)}
                              />
                              <div
                                onClick={() => handleAddItem('stationery', inputText)}
                                className="tags__button"
                              >
                                  <img className="tags__img" src="../../icons/cancel.svg" alt="add-tag" />
                              </div>
                  </div>
                  <div className="tags__list">
                {stationery.map((item) => (
                  <p className="tags__tag" key={item.id}>
                    <span onClick={() => handleRemoveItem('stationery', item.id)} className="upu__close">X</span>
                    {item.title}
                  </p>
                ))}
                  </div>

                  </TData>
                </TRow>

                <TRow>
                  <TData>Блоки</TData>
                  <TData key={blocks.id}>
                  <div className="tags__input-block">
                              <Input
                                className="tags__input"
                                onChange={(e) => setInputText(e.target.value)}
                              />
                              <div
                                onClick={() => handleAddItem('blocks', inputText)}
                                className="tags__button"
                              >
                                  <img className="tags__img" src="../../icons/cancel.svg" alt="add-tag" />
                              </div>
                  </div>
                  <div className="tags__list">
                  {blocks.map((item) => (
                  <p className="tags__tag" key={item.id}>
                    <span onClick={() => handleRemoveItem('blocks', item.id)} className="upu__close">X</span>
                    {item.title}
                  </p>
                  ))}
                  </div>
                  </TData>
                </TRow>

                <TRow>
                  <TData>Время</TData>
                  <TData key={time.id}>
                  <div className="tags__input-block">
                              <Input
                                className="tags__input"
                                onChange={(e) => setInputText(e.target.value)}
                              />
                              <div
                                onClick={() => handleAddItem('time', inputText)}
                                className="tags__button"
                              >
                                  <img className="tags__img" src="../../icons/cancel.svg" alt="add-tag" />
                              </div>
                  </div>
                  <div className="tags__list">
                  {time.map((item) => (
                  <p className="tags__tag" key={item.id}>
                    <span onClick={() => handleRemoveItem('time', item.id)} className="upu__close">X</span>
                    {item.title}
                  </p>
                  ))}
                  </div>
                  </TData>
                </TRow>

                <TRow>
                  <TData>Тип неисправности</TData>
                  <TData key={troubleShooting.id}>
                  <div className="tags__input-block">
                              <Input
                                className="tags__input"
                                onChange={(e) => setInputText(e.target.value)}
                              />
                              <div
                                onClick={() => handleAddItem('troubleShooting', inputText)}
                                className="tags__button"
                              >
                                  <img className="tags__img" src="../../icons/cancel.svg" alt="add-tag" />
                              </div>
                  </div>
                  <div className="tags__list">
                  {troubleShooting.map((item) => (
                  <p className="tags__tag" key={item.id}>
                    <span onClick={() => handleRemoveItem('troubleShooting', item.id)} className="upu__close">X</span>
                    {item.title}
                  </p>
                  ))}
                  </div>
                  </TData>
                </TRow>

        </TBody>
        <TFoot>
<TRow>
                    <TData onClick={() => editMdFormCategories(data)}>Сохранить изменения</TData>
</TRow>

        </TFoot>
    </Table>
   </ServiceBody>
  );
}

export default AdminMd;
