import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import Button from '../../../../components/UI/Button/Button';
import Loader from '../../../../components/UI/Loader';
import Select from '../../../../components/UI/Select';
import { catchHandler } from '../../../../utils/error_handling/error_handling';
import { alertFunction } from '../../../../utils/functions/alertFunction';
import { clientSendData } from '../../../../utils/functions/requests';
import { createAction } from '../../../../utils/redux/store';

/**
* @component Сервис отчётов анкетирования - Базовый отчёт - Панель выбора - Выбор расширенного отчёта
*/
function ExtPanel() {
  const location = useLocation(); // адрес
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [searchParams, setSearchParams] = useSearchParams(); // параметры поиска из адресной строки (id сотрудника)
  const extReportsList = useSelector((state) => state.reports.ext_reports_list); // список расширенных отчётов доступных пользователю
  const currentDirection = useSelector((state) => state.questionnaire.current_direction); // текущее выбранное направление
  const currentQuestionnaire = useSelector((state) => state.questionnaire.current_questionnaire); // текущая выбранная анкета
  const choosedExtReport = useSelector((state) => state.reports.choosed_ext_report); // текущий выбранный расширенный отчет
  const filteredExtReports = filterExtReports(); // отфильтрованные расширенные отчёты
  const reportPeriod = useSelector((state) => state.reports.report_periоd); // период отчёта

  useEffect(() => {
    getExtReportsList();
  }, []);

  // Получить список расширенных отчетов
  async function getExtReportsList() {
    try {
      const reqData = {
        type: 'getExtReportsList',
        location: location.pathname,
      };

      setIsLoading(true);
      const result = await clientSendData('POST', '/get_ext_reports_list', reqData); // запрос в БД
      if (result === 'bad_request') { // если некорректный запрос
        alertFunction('bad_request', 'clientPost'); // уведомление
        setIsLoading(false);
      } else {
        createAction('SET_EXT_REPORTS_LIST', result); // запись анкет в хранилище
        setIsLoading(false);
      }
    } catch (error) {
      catchHandler(error, 'getReportVertical');
    }
  }

  // Фильтрация списка расширенных отчётов на основании выбранного направления
  function filterExtReports() {
    if (currentDirection.id === 'all') { // если выбраны все направления
      if (currentQuestionnaire) { // если выбрана анкета показываются отчёты по этой анкете
        return extReportsList.filter((r) => r.questionnaire_id === currentQuestionnaire.id);
        // если выбраны все направления и не выбрана анкета
        // показываются все доступные расширенные отчёты
      } return extReportsList;
    } // иначе направление выбрано
    if (currentQuestionnaire) { // если выбрана анкета
      return extReportsList.filter((r) => {
        // показываются отчёты по выбранной анкете в выбранном направлении
        const isCurrentDirection = r.direction_id === currentDirection.id;
        const isCurrentQuestionnare = r.questionnaire_id === currentQuestionnaire.id;
        return (isCurrentDirection && isCurrentQuestionnare);
      });
    } // иначе анкета не выбрана показываются все отчёты в выбранном направлении
    return extReportsList.filter((r) => r.direction_id === currentDirection.id);
  }

  // Показать расширенный отчёт
  function showExtReport() {
    const { period, start_date, end_date } = reportPeriod; // настройки периода
    // Если выбран период, но не выбрана дата начала или дата окончания - уведомление
    if ((period && !start_date) || (period && !end_date)) alertFunction('wrong_period', 'clientPost');
    // Если выбран период, но дата начала больше даты окончания - уведомление
    else if (period && (start_date > end_date)) alertFunction('wrong_period', 'clientPost');
    // иначе
    else {
      if (reportPeriod.period) { // Если выбран период
        setSearchParams({ // в параметры добавляются даты
          report: choosedExtReport.link,
          start_date: reportPeriod.start_date,
          end_date: reportPeriod.end_date,
        }); // установить параметры поиска
      } else { // иначе в параметрах только ссылка
        setSearchParams({ report: choosedExtReport.link }); // установить параметры поиска
      }
      createAction('SET_CURRENT_COMPONENT', 'ext'); // обновить отображаемый компонент
    }
  }

  // Если в выбранном направлении отсутствуют расширенные отчёты - форма не покажется
  if (filteredExtReports.length === 0) return <div className="reports__ext-form">Расширенные отчеты отсутствуют</div>;
  return (
    <div className="reports__ext-form" onClick={(e) => e.stopPropagation()}>
      {isLoading ? <Loader /> : (
        <Select
          id="reports__choose-ext-form"
          array={filteredExtReports}
          onChoose={(report) => createAction('SET_CHOOSED_EXT_REPORT', report)}
          defaultValue={choosedExtReport?.title || 'Расширенный отчёт'}
        />
      )}
      {/* если выбран расширенный отчёт - показать кнопку */}
      {choosedExtReport && <Button onClick={showExtReport}>Расширенный отчёт</Button>}
    </div>
  );
}

export default ExtPanel;
