import { catchHandler } from '../error_handling/error_handling';
import { createAction } from '../redux/store';
import { sendData } from './requests';

/**
 * @function Функция вывода системных сообщений и уведомлений
 * @prop {keyword} string - (Обязателный) Название системного сообщения в таблице БД
 * @prop {type} string - Тип запроса (клиентский или серверный)
 * @prop {extra} string - Дополнительная информация (дата, имя и т.д)
*/
export async function alertFunction(keyword, type, extra) {
  try {
    const reqData = {
      type: 'getSystemMessage',
      app_side: type === 'clientPost' ? 'client' : 'server',
      keyword,
    };

    const result = await sendData('POST', '/get_system_message', reqData); // запрос в БД с ключевым словом

    let message = result?.message; // Текст сообщения
    let messageType = result?.type; // Тип сообщения
    if (result instanceof Error) {
      message = 'Ошибка отбражения уведомления'; // если это ошибка - замени текст ошибки на кастомный
      messageType = 'error';
    }
    // если присутствует extra - основное сообщение + доп информация
    if (extra) message = (`${message} ${extra}`);
    createAction('SHOW_NOTICE', { message, type: messageType });
    setTimeout(() => createAction('HIDE_NOTICE'), 7000);
  } catch (error) {
    catchHandler(error, 'alertFunction');
  }
}

/**
 * @function Функция вывода системных сообщений и уведомлений при отсутствии соединения с БД
 * @prop {message} string - сообщение для отображения
*/
export async function alertFunctionLocal(message) {
  try {
    createAction('SHOW_NOTICE', { message, type: 'error' });
    setTimeout(() => createAction('HIDE_NOTICE'), 7000);
  } catch (error) {
    catchHandler(error, 'alertFunctionLocal');
  }
}
