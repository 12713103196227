import React, { useEffect, useState } from 'react';
import Cap from '../Cap/Cap';
import Divisions from './Divisions/Divisions';
import { authorization } from '../../utils/functions/authenticate';
import { setOperInfo } from '../../utils/functions/others';
import './admin_kb.scss';
import Service from '../../components/Service/Service';

/**
 * @component Администрирование базы знаний
*/
function AdminKB() {
  const [isAuthorized, setIsAuthorized] = useState(false); // Состояние авторизации

  useEffect(() => {
    setOperInfo();
    awaitRequests();
  }, []);

  async function awaitRequests() {
    const checkAuthorization = await authorization(); // авторизация
    setIsAuthorized(checkAuthorization);
  }

  if (isAuthorized) { // если авторизован
    return (
      <Service id="admin-kb">
        <Divisions />
      </Service>
    );
  } return <Cap />;
}
export default AdminKB;
