import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { createAction } from '../../../utils/redux/store';
import { getQuestions } from '../functions';
import { log } from '../../../utils/functions/others';
import ServiceBody from '../../../components/Service/ServiceBody';
import QuestionCard from './QuestionCard/QuestionCard';
import Loader from '../../../components/UI/Loader';
import Table from '../../../components/Table/Table';
import THead from '../../../components/Table/THead';
import TRow from '../../../components/Table/TRow';
import TFoot from '../../../components/Table/TFoot';
import TBody from '../../../components/Table/TBody';
import TData from '../../../components/Table/TData';
import './questions.scss';

/* Сервис анкетирования - вкладка Вопросы
  При открытии карточки в хранилище помещается информация о текущем вопросе,
  будь то новый или существующий
  Пользователь может редактировать эту информацию, при нажатии кнопки "Сохранить"
  измененные или созданные данные записываются в БД
*/
function Questions() {
  const location = useLocation(); // адрес
  const [isLoading, setIsLoading] = useState(false); // Состояние загрузки
  const [showCard, setShowCard] = useState(false); // состояние отображения карточки вопроса
  const [currentQuestionId, setCurrentQuestionId] = useState(null); // выбранный вопрос
  const userDirections = useSelector((state) => state.questionnaire.user_directions); // направления доступные пользователю
  const displayedQuestions = useSelector((state) => state.questionnaire.displayed_questions); // отображаемые в таблице вопросы
  const currentDirection = useSelector((state) => state.questionnaire.current_direction); // текущее направление
  // const questions = useSelector(state => state.questionnaire.questions) // все вопросы
  const headers = [ // заголовки таблицы
    { id: 1, title: 'Вопрос', field: 'title' },
    { id: 2, title: 'Направление', field: 'group' },
    { id: 3, title: 'Категория', field: 'category' },
    { id: 4, title: 'Создал(а)', field: 'user' },
    { id: 5, title: 'Создан', field: 'date_created' },
  ];

  useEffect(() => {
    awaitRequests();
  }, []);

  // Ожидание выполненияя необходимых запросов
  async function awaitRequests() {
    setIsLoading(true); // состояние - загружается
    await getQuestions(location.pathname, currentDirection); // получить вопросы
    setIsLoading(false); // загрузка завершена
  }

  // Поулчить информацию об анкете
  function openQuestion(id) {
    setCurrentQuestionId(id); // id выбранной анкета
    setShowCard(true); // показать карточку
    if (id === 'new') {
      log({ message: 'Пользователь нажал кнопку создания нового вопроса', eventtype: 'info' });
    } else {
      log({ message: `Пользователь выбрал вопрос id: ${id}`, eventtype: 'info' });
    }
  }
  // если загружается - показывается лоадер
  if (isLoading) return <ServiceBody><Loader /></ServiceBody>;
  return (
    <ServiceBody>
      {userDirections.length > 0 ? (
      <Table id="questionnaire-questions__table">
        {/* Хедер таблицы */}
        <THead
          title="Вопросы"
          headers={headers}
          array={displayedQuestions}
          setArray={(array) => createAction('SET_DISPLAYED_QUESTIONS', array)}
        />

        <TBody>
          {displayedQuestions.map((q) => (
            // Данные вопроса
            <TRow key={q.id} onClick={() => openQuestion(q.id)} button>
              {headers.map((item) => {
                if (item) return <TData key={item.id}>{q[item.field]}</TData>;
                return null;
              })}
            </TRow>
          ))}
        </TBody>
        <TFoot>
          <TRow>
            <TData onClick={() => openQuestion('new')}>Создать вопрос</TData>
          </TRow>
        </TFoot>
      </Table>
      ) : (
      <h2> Доступные направления отсутствуют</h2>)}

      {showCard && (
      <QuestionCard
        id={currentQuestionId}
        setShowCard={setShowCard}
      />)}
    </ServiceBody>
  );
}

export default Questions;
