import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { catchHandler } from '../../../../utils/error_handling/error_handling';
import { clientSendData } from '../../../../utils/functions/requests';
import TableWrapper from '../../Components/TableWrapper/TableWrapper';
import Loader from '../../../../components/UI/Loader';
import Table from '../../../../components/Table/Table';
import THead from '../../../../components/Table/THead';
import TBody from '../../../../components/Table/TBody';
import TFoot from '../../../../components/Table/TFoot';
import TRow from '../../../../components/Table/TRow';
import TData from '../../../../components/Table/TData';

/**
* @component Сервис отчётов анкетирования - Расширенный отчёт - Основной
*/
function ReportMain() {
  const [searchParams, setSearchParams] = useSearchParams(); // параметры ссылки
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [report, setReport] = useState([]); // отчёт
  const [questions, setQuestions] = useState([]); // вопросы отчёта
  const start_date = searchParams.get('start_date'); // дата начала
  const end_date = searchParams.get('end_date'); // дата окончания
  const period = !!(start_date && end_date); // наличие периода

  useEffect(() => {
    getOutputInterviewReport(); // получить отчёт по выходному интервью
  }, []);

  // получить отчёт по выходному интервью
  async function getOutputInterviewReport() {
    try {
      const reqData = {
        type: 'getReportMainNew',
        period,
        start_date,
        end_date,
      };

      setIsLoading(true); // состояние - загружается
      const result = await clientSendData('POST', '/get_oi_report_main_new', reqData);
      if (result?.report?.length > 0) { // если есть данные
        setReport(result.report); // записать отчёт в переменную
        setQuestions(result.questions); // вопросы выходного интервью
        setIsLoading(false); // загрузка завершена
      } else {
        setIsLoading(false); // загрузка завершена
      }
    } catch (error) {
      catchHandler(error, 'getOutputInterviewReport');
      setIsLoading(false); // загрузка завершена
    }
  }

  if (isLoading) return <Loader />;
  return (
    <TableWrapper id="reports-oi-main" title="Выходное интервью" back_button>
      <Table id="reports-oi-main">
        <THead>
          <TRow>
            <TData key="fio">ФИО</TData>
            <TData key="greid">Грейд</TData>
            <TData key="otdel">Отдел</TData>
            <TData key="company">Компания</TData>
            {questions.map((q) => {
              const isComment = q.output_id === 4; // Тип ответа на вопрос - комментарий?
              return (
                <React.Fragment key={q.id}>
                  <td className="upu-table__table-data" dangerouslySetInnerHTML={{ __html: q.title }} />
                  {/* Если тип ответа - комментарий, то поле комментария не отображается */}
                  {!isComment && <TData key={`${q.id}comment`}>Комментарий</TData>}
                </React.Fragment>
              );
            })}
          </TRow>
        </THead>
        <TBody>
          {report.map((r) => (
            <TRow key={r.id}>
              {r.answers.map((answer, index) => (
                <TData key={`${r.id}-${index}`}>{answer}</TData>
              ))}
            </TRow>
          ))}
        </TBody>
        <TFoot />
      </Table>
    </TableWrapper>
  );
}

export default ReportMain;
