import React from 'react';

/**
* @component Футер таблицы
*/

function TFoot(props) {
  const { children } = props;

  return <tfoot className="upu-table__table-foot">{children}</tfoot>;
}

export default TFoot;
