import React, { useState, useEffect } from 'react';
import Button from '../../../components/UI/Button/Button';

// Function to check if a year is a leap year
const isLeapYear = (year) => (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);

function ProdactionCalendar({ calendarData, onSave }) {
  const [data, setData] = useState(calendarData);

  useEffect(() => {
    setData(calendarData);
  }, [calendarData]);

  const handleDayClick = (index) => {
    const newData = [...data];
    newData[index] = newData[index] === 0 ? 1 : 0;
    setData(newData);
  };

  const handleSave = () => {
    const year = new Date().getFullYear();
    const selectedDates = [];

    let currentIndex = 0; // Track the index for data array

    for (let month = 0; month < 12; month++) {
      const daysInMonth = new Date(year, month + 1, 0).getDate();

      for (let day = 1; day <= daysInMonth; day++) {
        if (data[currentIndex] === 1) { // Assume '1' represents a holiday
          selectedDates.push(new Date(year, month, day));
        }
        currentIndex++;
      }
    }

    const formattedDates = selectedDates.map((date) => `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`);

    onSave(formattedDates);
  };

  const daysOfWeek = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];

  const renderDays = (startIndex, daysInMonth) => Array.from({ length: daysInMonth }).map((_, i) => {
    const day = i + 1;
    const dayType = data[startIndex + i] === 0 ? 'workday' : 'holiday';
    return (
      <div
        key={`day-${startIndex + i}`}
        className={`day ${dayType}`}
        onClick={() => handleDayClick(startIndex + i)}
      >
        {day}
      </div>
    );
  });

  const generateYearCalendar = () => {
    const year = new Date().getFullYear();
    const rows = [];
    let currentIndex = 0; // Track the index for data array

    for (let month = 0; month < 12; month++) {
      const startDate = new Date(year, month, 1);
      const daysInMonth = new Date(year, month + 1, 0).getDate();
      const firstDay = startDate.getDay();
      const offset = (firstDay + 6) % 7; // Adjust so Monday is 0, etc.

      // Month header
      rows.push(
        <div key={`month-header-${month}`} className="month">
          <div className="month-header">
            {startDate.toLocaleString('ru-RU', { month: 'long' })}
          </div>
          <div className="week-days">
            {daysOfWeek.map((day, index) => (
              <div key={`day-of-week-${index}`} className="day-of-week">
                {day}
              </div>
            ))}
          </div>
          <div className="days-container">
            {/* Empty days before the first day of the month */}
            {Array.from({ length: offset }).map((_, i) => (
              <div key={`empty-${month}-${i}`} className="day empty" />
            ))}

            {/* Actual days of the month */}
            {renderDays(currentIndex, daysInMonth)}
          </div>
        </div>,
      );

      // Update index after processing the month
      currentIndex += daysInMonth;
    }

    return rows;
  };

  return (
    <div>
      <p className="asap_title">Производственный календарь</p>
      <div className="year-calendar">
        {generateYearCalendar()}
      </div>
      <Button onClick={handleSave}>Подтвердить</Button>
    </div>
  );
}

export default ProdactionCalendar;
