import React, { useEffect, useState } from 'react';
import { clientSendData, clientSendFile } from '../../utils/functions/requests';
import { catchHandler } from '../../utils/error_handling/error_handling';
import { headers } from './common';
import ServiceBody from '../../components/Service/ServiceBody';
import Table from '../../components/Table/Table';
import THead from '../../components/Table/THead';
import TBody from '../../components/Table/TBody';
import TRow from '../../components/Table/TRow';
import TData from '../../components/Table/TData';
import Wrapper from '../../components/Basic/Wrapper';
import CheckMark from '../../components/UI/CheckMark';
import EditorButton from '../../components/UI/Button/EditorButton';
import Input from '../../components/UI/Input';
import TFoot from '../../components/Table/TFoot';
import Cancel from '../../components/UI/Cancel';
import SingleFileUpload from '../../components/UI/FileUpload/SingleFileUpload';
import Button from '../../components/UI/Button/Button';
import { alertFunction } from '../../utils/functions/alertFunction';
import TextArea from '../../components/UI/TextArea';
import Loader from '../../components/UI/Loader';
import Select from '../../components/UI/Select';

function Zones() {
  const [zones, setZones] = useState([]);
  const [activeZone, setActiveZone] = useState(null);
  const [createZone, setCreateZone] = useState(false);
  const [editingRow, setEditingRow] = useState(null);
  const [changeData, setChangeData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [hardwares, setHardwares] = useState([]);

  function makeGroup() {
    setCreateZone(true);
    setChangeData({ settings: '{"width": null, "height": null}', change: true });
    setEditingRow(null);
  }

  function cancelCreating(e) {
    e.preventDefault(); // предотвращение действий формы по умолчанию
    setCreateZone(false); // изменение состояния создания группы
    setChangeData({});
  }

  useEffect(() => {
    getZones();
  }, []);

  async function getZones() {
    try {
      const reqData = {
        type: 'getAdmWorkplaceZones',
      };
      setIsLoading(true); // загружается
      const result = await clientSendData('POST', '/get_adm_workplace_zones', reqData); // запрос в БД action: useState

      if (result) {
        const zonesWithPictures = result.data.map((el) => {
          if (el.graphic_scheme) {
            let binary = '';
            el.graphic_scheme.data.forEach((b) => {
              binary += String.fromCharCode(b);
            });
            return { ...el, picture: binary ? `data:application/octet-stream;base64,${btoa(binary)}` : null };
          } return el;
        });
        setZones(zonesWithPictures); // запись в переменную
        setHardwares(result.hardwares);
        setIsLoading(false);
      }
    } catch (error) {
      catchHandler(error, 'getAdmWorkplaceZones'); // обработчик ошибок
    } finally { setIsLoading(false); }
  }

  async function editZone(editAction) {
    try {
      if (changeData.change || editAction === 'delete') {
        const validate = () => Object.keys(changeData).every((el) => Boolean(changeData[el]));
        const settings = JSON.parse(changeData.settings);
        if (validate || editAction === 'delete') {
          setChangeData((state) => ({ ...state, settings }));
          const reqData = {
            type: 'editAdmWorkplaceZone',
            action: editAction,
            data: changeData,
          };
          const result = await clientSendData('POST', '/edit_adm_workplace_zone', reqData); // запрос в БД
          if (result.success) {
            alertFunction('save_settings', 'client');
            getZones();
          }
        }
      }
    } catch (error) {
      alertFunction('something_went_wrong', 'client');
      catchHandler(error, 'editAdmWorkplaceZone'); // обработчик ошибок
    } finally {
      setEditingRow(null);
      setCreateZone(false);
    }
  }

  function changeZone(zone) {
    setEditingRow(zone.id);
    setChangeData(zone);
  }

  async function fileHandler(file) {
    try {
      const reader = new FileReader(); // API чтения файлов
      reader.onloadend = uploadFile; // При успешном завершении операции чтения вызывается функция отправки данных в БД
      reader.readAsArrayBuffer(file); // Чтение данных

      async function uploadFile(e) { // функция отправки данных
        const binaryArray = new Uint8Array(e.target.result); // создание бинарного массива из прочтенного файла
        const blob = new Blob([binaryArray]); // создание Blob из бинарного массива
        const formData = new FormData(); // FormData для отправки blob
        // добавление данных к запросу
        formData.append('type', 'uploadZoneScheme');
        formData.append('content', blob);
        formData.append('zone_id', activeZone);
        const result = await clientSendFile('POST', '/upload_zone_scheme', formData);
        if (result.success === true) {
          alertFunction('save_settings', 'client');
          await getZones();
        } else alertFunction('bad_request');
      }
    } catch (error) {
      catchHandler(error, 'fileHandler');
    }
  }

  function chooseHardware(hardware) {
    setChangeData((state) => ({
      ...state, hardware_id: hardware.id, hardware_title: hardware.title, change: true,
    }));
  }

  return (
      <ServiceBody>
      {isLoading ? <Loader />
        : <Table id="zones">
              <THead headers={headers.zones} title="Рабочие зоны" />
              { /* Тело таблицы */}
              <TBody>
                  {zones.map((zone) => (
                   <TRow key={zone.id}>
                    {editingRow === zone.id ? (
                      <>
                        <TData>
                          <Input
                            defaultValue={changeData.block}
                            onChange={(e) => setChangeData((state) => ({ ...state, block: e.target.value, change: true }))}
                          />
                        </TData>
                        <TData>
                          <Wrapper>
                          <Input
                            defaultValue={changeData.title}
                            onChange={(e) => setChangeData((state) => ({ ...state, title: e.target.value, change: true }))}
                          />
                          <CheckMark onClick={() => editZone('update')} />
                          <EditorButton onClick={() => editZone('delete')} icon="delete" />
                          </Wrapper>
                        </TData>
                        <TData>
                          <TextArea
                            defaultValue={changeData.settings}
                            onChange={(e) => setChangeData((state) => ({ ...state, settings: e.target.value, change: true }))}
                          />
                        </TData>
                        <TData>
                          <Select
                            id="select-hardware"
                            array={hardwares}
                            onChoose={(hardware) => chooseHardware(hardware)}
                            defaultValue={changeData.hardware_title}
                          />
                        </TData>
                      </>
                    )
                      : (
                      <>
                        <TData>{zone.block}</TData>
                        <TData>
                          <span onClick={() => changeZone(zone)} className="clickable">{zone.title}</span>
                        </TData>
                        <TData>{zone.settings}</TData>
                        <TData>{zone.hardware_title}</TData>
                      </>
                      )}
                    <TData>
                      {zone.picture ? (
                        <img className="zone-scheme" src={zone.picture} alt={zone.title} />
                      ) : (
                        <span>Схема не загружена</span>
                      )
                    }
                    <SingleFileUpload
                      options={{
                        handler: fileHandler,
                        accept: '.jpg, .jpeg, .png, .gif',
                        size: 1024000,
                      }}
                    >
                        <Button onClick={() => setActiveZone(zone.id)}>Загрузить</Button>
                    </SingleFileUpload>
                    </TData>
                   </TRow>
                  ))}
              </TBody>
            { /* Футер */ }
            <TFoot>
              {createZone ? (
                  <TRow>
                      <TData>
                      <Wrapper>
                          <Input placeholder="Введите название блока" onChange={(e) => setChangeData((state) => ({ ...state, block: e.target.value }))} />
                          <Input placeholder="Введите название зоны" onChange={(e) => setChangeData((state) => ({ ...state, title: e.target.value }))} />
                          <Input placeholder="Введите параметры" value={changeData.settings} onChange={(e) => setChangeData((state) => ({ ...state, title: e.target.value }))} />
                          <Select
                            id="select-hardware"
                            array={hardwares}
                            onChoose={(hardware) => chooseHardware(hardware)}
                            defaultValue={changeData.hardware_title}
                          />
                          <CheckMark onClick={() => editZone('create')} />
                          <Cancel onClick={cancelCreating} />
                      </Wrapper>
                      </TData>
                  </TRow>
              ) : (
                  <TRow>
                      <TData onClick={makeGroup}>Создать рабочую зону</TData>
                  </TRow>
              )}
            </TFoot>
          </Table>}
      </ServiceBody>

  );
}

export default Zones;
