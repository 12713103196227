import React, { useState } from 'react';
import CardSetting from '../../../components/Card/CardSetting';
import Button from '../../../components/UI/Button/Button';
import '../asap.scss';
import TextArea from '../../../components/UI/TextArea';
import { clientSendData } from '../../../utils/functions/requests';
import { alertFunction } from '../../../utils/functions/alertFunction';
import { catchHandler } from '../../../utils/error_handling/error_handling';
import ProdactionCalendar from './ProdactionCalendar';

/**
  * @component ASAP- повторное тестирование
  * @prop {setShowCard} function - обновление состояния отображения карточки (открыть/закрыть)
  * @prop {refreshTable} function - обновление данных таблицы
*/
function RequestCard({ meetingId, calendarData, onBackToResults }) {
  const [openCards, setOpenCards] = useState({});
  const [removeTransactions, setRemoveTransactions] = useState(false);
  const [checkbox2, setCheckbox2] = useState(false);
  const [checkbox3, setCheckbox3] = useState(false);
  const [showInputs2, setShowInputs2] = useState(false);
  const [exceptions, setExceptions] = useState([]); // Состояние для хранения исключений
  const [accountDt, setAccountDt] = useState(''); // Состояние для Счет Дт
  const [accountKt, setAccountKt] = useState(''); // Состояние для Счет Кт
  const [reason, setReason] = useState(''); // Состояние для Причина
  const [exceptionsL, setExceptionsL] = useState([]); // Состояние для хранения исключений
  const [accountDtL, setAccountDtL] = useState(''); // Состояние для Счет Дт
  const [accountKtL, setAccountKtL] = useState(''); // Состояние для Счет Кт
  const [reasonL, setReasonL] = useState(''); // Состояние для Причина
  const [exceptionReason, setExceptionReason] = useState(''); // Состояние для причины исключения
  const [limitG, setLimitG] = useState(''); // Состояние для лимита по сумме Г
  const [limitK, setLimitK] = useState(''); // Состояние для лимита по сумме К
  const [limitI, setLimitI] = useState(''); // Состояние для лимита по сумме И
  const [limitZ, setLimitZ] = useState(''); // Состояние для лимита по сумме З
  const [limitD, setLimitD] = useState(''); // Состояние для лимита по сумме Д
  const [limitE, setLimitE] = useState(''); // Состояние для лимита по сумме Д
  const [thresholdNumber, setThresholdNumber] = useState(''); // Состояние для числа порога Ж
  const [words, setWords] = useState(['подар', 'дарен', 'спонс', 'ошиб', 'откат', 'взятк', 'ммвмы']); // Переменная для хранения слов
  const [newWord, setNewWord] = useState(''); // Переменная для нового слова
  const [dopTestsData, setDopTestsData] = useState([]); // Состояние для хранения выбранных карточек
  const [showCalendar, setShowCalendar] = useState(false); // Состояние для управления видимостью календаря
  const [calendDate, setCalendDate] = useState(null);

  const toggleCalendar = () => {
    setShowCalendar(!showCalendar); // Переключение состояния видимости календаря
  };

  // запись данных для передачи на бэк
  async function secondTest() {
    const data = {};

    if (removeTransactions || checkbox2 || exceptions || checkbox3 || exceptionReason) {
      data.All = { RemoveTransactions: removeTransactions, exceptions, exceptionReason };
    }

    if (limitG) {
      data.G = { limitG }; // Записываем значение без ключа
    }

    if (limitD || words) {
      data.D = { limitD, words }; // Записываем значения без ключей
    }

    if (limitE && calendDate) {
      data.E = { limitE, calendDate }; // Записываем значение без ключа
    }

    if (thresholdNumber) {
      data.J = { thresholdNumber }; // Записываем значение без ключа
    }

    if (limitZ) {
      data.Z = { limitZ }; // Записываем значение без ключа
    }

    if (limitI) {
      data.I = { limitI }; // Записываем значение без ключа
    }

    if (limitK) {
      data.K = { limitK }; // Записываем значение без ключа
    }

    if (exceptionsL) {
      data.L = { exceptionsL }; // Записываем значение без ключа
    }

    // Добавляем данные только если объект не пустой
    if (Object.keys(data).length > 0) {
      setDopTestsData((prevData) => [...prevData, data]);
      console.log(data);
      await updateDopTestsData(data);
    } else {
      console.log('Данные не добавлены, так как ни один чекбокс не был выбран');
    }
  }

  async function updateDopTestsData(dataDopTests) {
    console.log(dataDopTests);
    try {
      const reqData = {
        dataDopTests,
        meetingId,
        type: 'updateAsapDataDopTests', // Устанавливаем тип запроса
      };

      const result = await clientSendData('POST', '/update_asap_data_dop_tests', reqData);

      if (result) {
        await alertFunction('save_settings', 'client'); // Оповещаем пользователя об успешном сохранении
      }
    } catch (error) {
      catchHandler(error, 'updateDopTestsData'); // Обрабатываем ошибки
    }
  }

  // вторичное тестирование
  const toggleCard = (id) => {
    setOpenCards((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  // Функции для обработки изменения состояния чекбоксов
  const handleCheckbox1Change = () => setRemoveTransactions((prev) => !prev);

  const handleCheckbox2Change = () => {
    setCheckbox2((prev) => !prev);
    setShowInputs2((prev) => !prev); // Переключаем видимость инпутов
  };

  const handleAddException = () => {
    // Добавление нового исключения в массив
    setExceptions([...exceptions, { accountDt, accountKt, reason }]);
    // Сброс значений инпутов
    setAccountDt('');
    setAccountKt('');
    setReason('');
  };

  const handleAddExceptionL = () => {
    // Добавление нового исключения в массив
    setExceptionsL([...exceptionsL, { accountDtL, accountKtL, reasonL }]);
    // Сброс значений инпутов
    setAccountDtL('');
    setAccountKtL('');
    setReasonL('');
  };

  const handleDeleteExceptionL = (index) => {
    setExceptionsL((prevExceptionsL) => prevExceptionsL.filter((_, i) => i !== index));
  };

  console.log(exceptions);

  const handleCheckbox3Change = () => setCheckbox3((prev) => !prev);

  const handleDeleteException = (index) => {
    setExceptions((prevExceptions) => prevExceptions.filter((_, i) => i !== index));
  };

  const handleAddWord = () => {
    if (newWord.trim() && !words.includes(newWord.trim())) {
      setWords([...words, newWord.trim()]); // Добавление нового слова
      setNewWord(''); // Очистка инпута
    }
  };

  const handleRemoveWord = (wordToRemove) => {
    setWords(words.filter((word) => word !== wordToRemove)); // Удаление слова
  };

  // скачивание файла
  function downloadFile() {
    console.log('Скачать файл');
  }

  const handleSave = (data) => {
    setCalendDate(data); // Обновляем состояние calendDate
    console.log('Saved data:', data); // Логируем переданные данные
  };

  return (

<div className="all-content">
<Button onClick={onBackToResults} class="text-button">
<span className="arrow">←</span>
Вернуться к результатам
</Button>

<h1>Просмотр результатов</h1>
<div className="asap_block">
    <h2>Результаты теста</h2>

    <p className="asap_text2">
Обязательное тестирование:
    </p>
<Button onClick={downloadFile}>Скачать</Button>
<p className="asap_text2">
Обязательное и дополнительное тестирование:
</p>
<Button onClick={downloadFile}>Скачать</Button>
<p className="asap_text2">
Бенфорд:
</p>
<Button onClick={downloadFile}>Скачать</Button>

</div>
<h2>Вторичное тестирование</h2>
<div className="scrollable-content">
    {[
      { id: 'All', title: 'Убрать типовые проводки' },
      { id: 'G', title: 'Г_редкие - Проверка на наличие несвязанных, необычных или редких проводок' },
      { id: 'D', title: 'Д_описание - Выявление проводок с подозрительным описанием или его отсутствием' },
      { id: 'E', title: 'Е_внерабочие - Выявление проводок, сделанных во внерабочее время (ночью / в праздники / в выходные дни)' },
      { id: 'J', title: 'Ж_выше_порога - Выявление проводок выше указанного порога' },
      { id: 'Z', title: 'З_отриц - Поиск проводок с отрицательными значениями' },
      { id: 'I', title: 'И_дубли - Выявление потенциально дублированных проводок' },
      { id: 'K', title: 'К_спец_сумм - Поиск проводок со специфическими суммами' },
      { id: 'L', title: 'Л_доп_анализ - Анализ распределения Бенфорда' },
    ].map((card) => (
      <CardSetting key={card.id} title={<span className="bold-title">{card.title}</span>}>
        <input
          type="checkbox"
          checked={openCards[card.id]} // Используем состояние для управления видимостью
          onChange={() => toggleCard(card.id)} // Переключаем состояние при изменении чекбокса
        />
        {openCards[card.id] && (
          <>
            {card.id === 'All' && (
            <div>
                <p className="asap_description">Опция позволяет убрать проводки и не учитывать их в тестах. Согласно заданному шаблону и/или личному шаблону счета Дт и счета Кт</p>
                <p className="asap_text">
                    <input
                      type="checkbox"
                      checked={removeTransactions}
                      onChange={handleCheckbox1Change}
                      value="Убрать типовые проводки по шаблону"
                    />
                    Убрать типовые проводки по шаблону
                    {removeTransactions && (
                        <div>
                            <p className="asap_description">Опция позволяет убрать проводки и не учитывать их в тестах. Согласно заданному шаблону и/или личному шаблону счета Дт и счета Кт:</p>
                        </div>
                    )}
                </p>
                <div className="asap_text">
    <input
      type="checkbox"
      checked={checkbox2}
      onChange={handleCheckbox2Change}
      value="Ввести свои исключения"
    />
    Ввести свои исключения
    {showInputs2 && (
        <div>
            <input
              className="input-small"
              type="text"
              placeholder="Счет Дт"
              value={accountDt}
              onChange={(e) => setAccountDt(e.target.value)} // Обновление состояния
            />
            <input
              className="input-small"
              type="text"
              placeholder="Счет Кт"
              value={accountKt}
              onChange={(e) => setAccountKt(e.target.value)} // Обновление состояния
            />
            <input
              className="input-long"
              type="text"
              placeholder="Экономическое содержание проводок/Причина"
              value={reason}
              onChange={(e) => setReason(e.target.value)} // Обновление состояния
            />
            <Button onClick={handleAddException}>Добавить исключение</Button>
        </div>
    )}

    {exceptions.length > 0 && (
        <table className="exceptions-table">
            <thead>
                <tr>
                    <th>Счет Дт</th>
                    <th>Счет Кт</th>
                    <th>Экономическое содержание проводок / Причина</th>
                    <th>Действие</th>
{' '}
{/* Новая ячейка для кнопки удаления */}
                </tr>
            </thead>
            <tbody>
                {exceptions.map((exception, index) => (
                    <tr key={index}>
                        <td>{exception.accountDt}</td>
                        <td>{exception.accountKt}</td>
                        <td>{exception.reason}</td>
                        <td>
                            <Button onClick={() => handleDeleteException(index)}>Удалить</Button>
                            {/* Кнопка удаления */}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    )}
                </div>

                <p className="asap_text">
    <input
      type="checkbox"
      checked={checkbox3}
      onChange={handleCheckbox3Change}
      value="Исключить внутренние обороты"
    />
    Исключить внутренние обороты
    {checkbox3 && (
        <div>
             <p className="asap_description">Не учитывать в отмеченных тестах проводки, где счет Кт = счет Дт</p>
            <TextArea
              placeholder="Введите причину исключения"
              value={exceptionReason}
              onChange={(e) => setExceptionReason(e.target.value)} // Обновление состояния
            />

        </div>
    )}
                </p>

            </div>
            )}
{card.id === 'G' && (
    <div>
        <p className="asap_description">Проверяется статистика по частоте возникновения проводок. По всем корреспонденциям с общими суммами за аудируемый период.</p>
        <input
          className="input-medium"
          type="text"
          placeholder="Лимит по сумме"
          value={limitG} // Переменная для хранения значения лимита
          onChange={(e) => setLimitG(e.target.value)} // Обновление состояния
        />
    </div>
)}

{card.id === 'D' && (
    <div>
        <p className="asap_description">
            Выводится в отчёт все проводки без описания/с минимальным описанием, например, менее 3-х символов/ подозрительным описанием, заданных пользователем и предустановленными условиями.
        </p>
        <input
          className="input-medium"
          type="text"
          placeholder="Лимит по сумме"
          value={limitD} // Переменная для хранения значения лимита
          onChange={(e) => setLimitD(e.target.value)} // Обновление состояния
        />

        <div style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '5px',
          border: '1px solid #CAD0D4',
          padding: '10px',
          borderRadius: '5px', // Добавлен радиус границы
          margin: '5px', // Внешний отступ
        }}
        >
            {words.map((word, index) => (
                <Button
                  key={index}
                  onClick={() => handleRemoveWord(word)}
                  style={{
                    backgroundColor: '#28B47D',
                    color: 'white',
                    minHeight: '20px', // Высота кнопки
                    width: '100px', // Ширина кнопки
                  }}
                >
                    {word}
{' '}
✖
                </Button>
            ))}
        </div>
        <div>
{' '}
{/* Новый div для второго инпута */}
            <input
              className="input-medium"
              type="text"
              placeholder="Введите слово или его корень"
              value={newWord} // Переменная для хранения нового слова
              onChange={(e) => setNewWord(e.target.value)} // Обновление состояния
            />
            <Button onClick={handleAddWord}>Добавить исключение</Button>
        </div>

    </div>
)}
{card.id === 'E' && (
    <div className="calendar_block">
        <p className="asap_description">
        Выводятся проводки сделанные во внерабочее время. Нерабочие дни подгружаются автоматически из произвоственного календаря.
        Временный диапазон рабочего дня задается пользователем. В зависимости от особенностей клиента можно варьировать временной интервал
        - для некоторых компаний неожиданными будут проводки после 19:00-20:00,- а для некоторых после 23:00-00:00.

        </p>
        <input
          className="input-medium"
          type="text"
          placeholder="Лимит по сумме"
          value={limitE} // Переменная для хранения значениялимита
          onChange={(e) => setLimitE(e.target.value)} // Обновление состояния
        />
        <div className="asap_description">
      <Button onClick={toggleCalendar}>
        {showCalendar ? 'Скрыть данные календаря' : 'Указать выходные дни'}
      </Button>
        </div>
      {showCalendar && <ProdactionCalendar calendarData={calendarData} onSave={handleSave} />}

    </div>
)}

{card.id === 'J' && (
    <div>
        <p className="asap_description">
            Выявляются проводки выше порога, установленного Аудиторской командой.
        </p>
        <input
          className="input-medium"
          type="text"
          placeholder="Число порога для проводок"
          value={thresholdNumber} // Переменная для хранения значения лимита
          onChange={(e) => setThresholdNumber(e.target.value)} // Обновление состояния
        />
    </div>
)}

{card.id === 'Z' && (
    <div>
        <p className="asap_description">
        Вывляются все проводок с отрицательными значениями
        </p>
        <input
          className="input-medium"
          type="text"
          placeholder="Лимит по сумме"
          value={limitZ} // Переменная для хранения значения лимита
          onChange={(e) => setLimitZ(e.target.value)} // Обновление состояния
        />
    </div>
)}

{card.id === 'I' && (
    <div>
        <p className="asap_description">
        Выводятся в отчёт все проводки, у которых совпадают следующие поля: счёт по дебету, счёт по кредиту, дата, сумма проводки.
        </p>
        <input
          className="input-medium"
          type="text"
          placeholder="Лимит по сумме"
          value={limitI} // Переменная для хранения значения лимита
          onChange={(e) => setLimitI(e.target.value)} // Обновление состояния
        />
    </div>
)}

{card.id === 'K' && (
 <div>
        <p className="asap_description">
            Позволяет выявить нестандартные элементы в больших совокупностях финансовой информации.
        </p>
        <input
          className="input-medium"
          type="text"
          placeholder="Лимит по сумме"
          value={limitK} // Переменная для хранения значения лимита
          onChange={(e) => setLimitK(e.target.value)} // Обновление состояния
        />
 </div>
)}

{card.id === 'L' && (
    <div>
        <p className="asap_description">
            Позволяет выявить нестандартные элементы в больших совокупностях финансовой информации.
        </p>
        <div style={{ display: 'flex', gap: '1em' }}>
{' '}
{/* Используем flexbox для выравнивания */}
            <input
              className="input-small"
              type="text"
              placeholder="Счет Дт"
              value={accountDtL}
              onChange={(e) => setAccountDtL(e.target.value)} // Обновление состояния
            />
            <input
              className="input-small"
              type="text"
              placeholder="Счет Кт"
              value={accountKtL}
              onChange={(e) => setAccountKtL(e.target.value)} // Обновление состояния
            />
            <input
              className="input-long"
              type="text"
              placeholder="Экономическое содержание проводок/Причина"
              value={reasonL}
              onChange={(e) => setReasonL(e.target.value)} // Обновление состояния
            />
            <Button onClick={handleAddExceptionL}>Добавить исключение</Button>
        </div>

        {exceptionsL.length > 0 && (
            <div>
                <table className="exceptions-table">
                    <thead>
                        <tr>
                            <th>Счет Дт</th>
                            <th>Счет Кт</th>
                            <th>Экономическое содержание проводок / Причина</th>
                            <th>Действие</th>
                        </tr>
                    </thead>
                    <tbody>
                        {exceptionsL.map((exceptionL, index) => (
                            <tr key={index}>
                                <td>{exceptionL.accountDtL}</td>
                                <td>{exceptionL.accountKtL}</td>
                                <td>{exceptionL.reasonL}</td>
                                <td>
                                    <Button onClick={() => handleDeleteExceptionL(index)}>Удалить</Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        )}
    </div>
)}

            {/* Здесь можно добавить содержимое для каждого CardSetting */}
          </>
        )}
      </CardSetting>

    ))}
</div>
    <div style={{ marginTop: '20px', textAlign: 'right' }} />
<Button onClick={secondTest}>Запланировать вторичное тестирование</Button>

</div>

  );
}

export default RequestCard;
