import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { log } from '../../../utils/functions/others';
import ServiceBody from '../../../components/Service/ServiceBody';
import QuestionsTable from '../Common/QuestionsTable';
// import QuestionInfo from './QuestionInfo';

/**
* @component База знаний - Вопросы
* @prop {range} array - диапазон id вопросов
* @prop {setShowCard} function - обновление отображения вопроса
*/
function Questions(props) {
  const { range, setShowCard } = props;

  const [currentQuestionId, setCurrentQuestionId] = useState(null); // выбранный вопрос
  const [searchParams, setSearchParams] = useSearchParams(); // параметры поиска url

  function openQuestion(id) {
    // setCurrentQuestionId(id) // обновить состояние активного вопроса
    setSearchParams({ question: id }); // установить параметры поиска
    setShowCard(true);
    log({ message: `Пользователь открыл карточку вопроса id: ${id}` });
  }

  function chooseQuestion(id) {
    setCurrentQuestionId(id); // обновить состояние активного вопроса
    log({ message: `Пользователь выделил вопрос id: ${id}` });
  }

  return (
    <ServiceBody id="interface__questions">
      <QuestionsTable
        range={range}
        chooseQuestion={chooseQuestion}
        openQuestion={openQuestion}
      />
    {/* {currentQuestionId && <QuestionInfo id={currentQuestionId}/>} */}
    </ServiceBody>
  );
}

export default Questions;
