import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { catchHandler } from '../../../utils/error_handling/error_handling';
import { alertFunction } from '../../../utils/functions/alertFunction';
import { clientSendData, sendData } from '../../../utils/functions/requests';
import { createAction } from '../../../utils/redux/store';
import Loader from '../../UI/Loader';
import Toggle from '../../UI/Toggle';
import SubAccessForm from '../SubAccessForm/SubAccessForm';
import './accessForm.scss';

/**
 * @component Форма настройки доступа к сервисам
  @prop {user_id} number (Обязательный) id пользователя, которому редактируется доступ
  @prop {user} string (Обязательный) samaccountname пользователя
  @prop {objectsid} string (Обязательный) objectSID пользователя
  @prop {isAdmin} boolean (Обязательный) источник перехода - страница администрирования?
*/

// Форма настройки доступа
function AccessForm(props) {
  // props - свойства, передающиеся компоненту при его вызове
  const {
    user_id, user, objectsid, isAdmin,
  } = props; // Деструктуризация свойств

  const [loadingElement, setLoadingElement] = useState('');
  // Массив данных о сервисах из хранилища
  const userServices = useSelector((state) => state.services.user_services);
  // Массив данных о сервисах из хранилища
  const allServices = useSelector((state) => state.services.services);
  // Админу показываются все сервисы, пользоватлю, только доступные
  const services = isAdmin ? allServices : userServices;
  // Данные формы настройки доступа из хранилища
  const accessFormData = useSelector((state) => state.users.access);

  // Концепция:
  // - При рендере формы отправляется запрос в БД с id пользователя для получения текущих доступов
  // - Полученные данные записываются в хранилище
  // - При изменении значения чекбоксов изменение сохраняется в БД

  // При каждом рендере компонента AccessForm выполняются функции, находящиеся внутри useEffect
  useEffect(() => {
    if (isAdmin) {
      getServices();
      getAccessData();
    }
  }, []);

  // Получение всех сервисов из БД
  async function getServices() {
    try {
      const reqData = {
        type: 'getAllServices',
      };
      const result = await clientSendData('POST', '/get_all_services', reqData);
      if (result) createAction('SET_SERVICES', result); // Результат записывается в Redux хранилище
    } catch (error) {
      catchHandler(error, 'getServices');
    }
  }

  // Получить данные о доступе
  async function getAccessData() {
    try {
      // Получение настроек доступа из БД и запись в хранилище
      const reqData = {
        type: 'getAccessData',
        objectsid,
      };
      const result = await sendData('POST', '/get_access_data', reqData);
      if (result) createAction('SET_USER_ACCESS', { user, data: result });
    } catch (error) {
      catchHandler(error, 'getAccessData');
    }
  }

  // Функция, которая проверяет значение чекбокса в хранилище
  // и устанавливает атрибут defaultChecked чекбокса
  // В качестве аргумента принимает id сервиса
  function isActive(serviceId) {
    try {
      // Если в хранилище присутствуют данные доступа выбранного пользователя
      if (accessFormData[user]) {
        // Выполняется поиск объекта, в котором:
        // - id пользователя равен id пользователя из свойств компонента AccessForm,
        // - id сервиса равен id сервиса из аргумента
        const inputDefault = accessFormData[user].find((row) => (
          (row.upu_users_objectsid === objectsid) && (row.upu_resources_id === serviceId)
        ));

        // Если такой объект найден - возвращается значение доступа из этого объекта
        if (inputDefault) return Boolean(inputDefault.access);
        return false;
      } return false;
    } catch (error) {
      catchHandler(error, 'isActive');
      return false;
    }
  }
  // Обработчик изменений в форме
  async function toggleHandler(service) {
    try {
      // поиск редактируемого параметра в текущих настройках доступа пользователя
      const findAccess = accessFormData[user].find((row) => (
        (row.upu_users_objectsid === objectsid) && (row.upu_resources_id === service.id)
      ));
      const subServicesAccessTable = service.parameters?.access_table || ''; // пример: qa_directions_access
      const reqData = {
        type: 'toggleAccess',
        upu_users_objectsid: objectsid,
        upu_resources_id: service.id,
        sub_access: service.sub_access,
        sub_access_table: subServicesAccessTable,
        access: findAccess?.access || null, // если запись есть - передается значение, иначе null
      };

      setLoadingElement(service.id);
      const result = await clientSendData('POST', '/toggle_access', reqData);
      if (result === 'bad_request') {
        alertFunction('bad_request', 'clientPost'); // Уведомление Некорректный запрос
        setLoadingElement('');
      } else if (result === 'success') {
        setLoadingElement('');
        getAccessData();
        alertFunction('save_settings'); // Уведомление Настройки сохранены
      }
    } catch (error) {
      catchHandler(error, 'toggleHandler');
      setLoadingElement('');
    }
  }

  // Проверка наличия субдоступа
  function checkSubAccess(service_id) {
    if (isAdmin) {
      /* Если объект с доступами имеет запись по текущему пользователю
      (проверка ключа добавлена во избежание ошибок)
      Выполняется поиск записи о доступе */
      const findAccess = accessFormData?.[user]?.find((row) => (
        (row.upu_users_objectsid === objectsid) && (row.upu_resources_id === service_id)
      ));
      // если запись найдена и доступ рарешен - функция возвращает true
      if (findAccess && Boolean(findAccess.access)) return true;
      return false; // Иначе false
    } return true;
  }

  return (
    <form className="access-form">
      {services.map((service) => {
        const {
          id, title, sub_access, parameters,
        } = service;
        return (
          <div key={id} className="access-form__row">
            <span className="access-form__toggle-label">{title}</span>

            {loadingElement === id ? <Loader /> : (
              isAdmin ? ( // если смотрит админ - показать управляемый тоггл
            <Toggle state={String(isActive(id))} onClick={() => toggleHandler(service)} />) : (
            <Toggle state="true" disabled />) // иначе disabled тоггл
            )}

            {sub_access && ( // Если сервис имеет дополнительные настройки
            <SubAccessForm // добавляется настройка субдоступов
              user_id={user_id}
              service_id={id}
              parameters={parameters}
              // параметр видимости настройки субдоступов передается в компонент
              show={checkSubAccess(id)}
              isAdmin={isAdmin}
            />)}
          </div>
        );
      })}
    </form>
  );
}

export default AccessForm;
