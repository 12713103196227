import React, { useState } from 'react';
import { alertFunctionLocal } from '../../../../utils/functions/alertFunction';
import Card from '../../../Card/Card';
import CardHeader from '../../../Card/CardHeader';
import CardBody from '../../../Card/CardBody';
import CardSetting from '../../../Card/CardSetting';
import CardFooter from '../../../Card/CardFooter';
import IconButton from '../../Button/IconButton';
import Button from '../../Button/Button';
import Input from '../../Input';
import Tags from '../Tags/Tags';
import Division from '../../Division';
import classes from '../../Button/button.module.scss';

/**
* @component Компонент управления объектом схемы
@prop {object} object (Обязательный) Объект схемы,
@prop {onChange} function (Обязательный) обработчик изменений объекта (принимает измененый объект в json формате)
*/

function Scheme(props) {
  const { object, onChange } = props;

  const [showScheme, setShowScheme] = useState(false); // отображение карточки
  const [scheme, setScheme] = useState(JSON.parse(object)); // объект схемы
  const [editingField, setEditingField] = useState(null); // поле у которого редактируется имя
  const [addSetting, setAddSetting] = useState(null); // поле у которого добавляется новая настройка
  const [newSetting, setNewSetting] = useState({}); // данные нового поля

  // Показатель изменения схемы
  function isChanged() {
    const firstOperand = object.replace(/\s/g, '');
    const secondOperand = JSON.stringify(scheme).replace(/\s/g, '');
    return firstOperand !== secondOperand;
  }

  // Сохранение изменений схемы
  function saveChanges() {
    const confirm = window.confirm('Сохранить изменения?');
    if (confirm) {
      onChange(JSON.stringify(scheme));
      setShowScheme(false);
    }
  }

  // ----------------Изменение полей------------------
  // Создание поля
  function createField() {
    let fieldName = `new_field_${Object.keys(scheme).length + 1}`; // new_field_(номер поля по порядку)
    // Найти в схеме поле с именем fieldName
    const isKeyAlreadyExist = Object.keys(scheme).find((row) => row === fieldName);
    // если такое поле существует - добавить к имени _copy
    if (isKeyAlreadyExist) fieldName += '_copy';
    // Добавить в схему новое поле со значениями по умолчанию
    setScheme({ ...scheme, [fieldName]: {} });
  }

  // Изменение имени поля
  function changeField(prevName, newName) {
    // Найти в схеме поле с таким же именем как и вводит пользователь
    const isKeyAlreadyExist = Object.keys(scheme).find((row) => row === newName);
    if (isKeyAlreadyExist) { // если такое поле существует
      alertFunctionLocal('Такое поле уже существует!'); // уведомление
      // ! В этом случае у пользователя отсутствует возможность
      // ввести наиенования поля, уже присутствуещего в схеме
    } else { // если полей с таким наименованием нет
      const copy = Object.fromEntries( // создать объект из вхождений
        Object.entries(scheme).map(([key, value]) => { // пройти по вхождениям объекта scheme
          // если ключ равен редактируемому полю - заменить ключ на вводимое имя
          if (key === prevName) return [newName, value];
          return [key, value]; // иначе вернуть вхождение без изменений
        }),
      );
      setScheme(copy); // обновить схему
      setEditingField(newName);
    }
  }

  // Удаление поля
  function deleteField(field) {
    const confirm = window.confirm('Удалить ключ?');
    if (confirm) { // Если пользователь подтвердил удаление
      // все поля за исключением удаляемого
      const restFields = Object.keys(scheme).filter((row) => row !== field);
      const newScheme = restFields.reduce((acc, key) => (
        // создать из оставшихся полей новый объект
        Object.assign(acc, { [key]: scheme[key] || null })
      ), {});

      setScheme(newScheme);
    } // иначе выход их функции
  }

  // ----------------Изменение настроек поля------------------
  // Создание настройки
  function createSetting(field) {
    const obj = scheme[field]; // объект поля схемы
    const fieldName = newSetting?.key;
    // Найти в объекте поле с именем fieldName
    const isKeyAlreadyExist = Object.keys(obj).find((row) => row === fieldName);
    // если такое поле существует - добавить к имени _copy
    if (isKeyAlreadyExist) alertFunctionLocal('Такое поле уже существует!'); // уведомление
    else {
      obj[fieldName] = newSetting?.value; // добавление ключа поля
      setScheme({ ...scheme, [field]: { ...obj } }); // обновление схемы
      setNewSetting(null);
      setAddSetting(null);
    }
  }

  // Изменение настройки
  function changeSetting(e, field, key) {
    const obj = scheme[field]; // объект поля схемы
    obj[key] = e.target.value; // добавление ключа поля
    setScheme({ ...scheme, [field]: { ...obj } }); // обновление схемы
  }

  // Удаление настройки
  function deleteSetting(field, sub_field) {
    const confirm = window.confirm(`Удалить ${field}.${sub_field}?`);
    if (confirm) { // Если пользователь подтвердил удаление
      const obj = scheme[field]; // объект поля схемы
      delete obj[sub_field]; // удаление ключа поля
      setScheme({ ...scheme, [field]: { ...obj } }); // обновление схемы
    } // иначе выход их функции
  }

  // ----------------Отображение схемы------------------
  // Показать объект
  function showObject(obj, field) {
    const data = obj[field];
    if (typeof data === 'object' && data !== null) {
      if (Array.isArray(data)) {
        return <Tags array={data} onChange={changeSetting} />;
      }
      return Object.keys(data).map((key) => (
        <CardSetting key={key} title={key}>
                {showObject(data, key)}
            <div className="upu-card__setting-wrapper">
                <Input value={data[key]} onChange={(e) => changeSetting(e, field, key)} />
                <IconButton icon="delete" onClick={() => deleteSetting(field, key)} />
            </div>
        </CardSetting>
      ));
    } return null;
  }

  return (
    <div className="scheme">
      <Button className={`${classes.button} ${classes.small}`} onClick={() => setShowScheme(true)}>
        Открыть схему
      </Button>

      {showScheme && (
      <Card setShow={() => setShowScheme(false)}>
        <CardHeader />
        <CardBody>
          {Object.keys(scheme)?.map((field) => (
            <CardSetting key={field}>
              {/* Имя ключа */}
              <CardSetting title={`${field}:`}>
                {editingField === field ? (
                <Input
                  autoFocus
                  onBlur={() => setEditingField(null)}
                  placeholder="Наименование ключа"
                  value={field}
                  onChange={(e) => changeField(field, e.target.value)}
                />
                ) : (
                <div className="upu-card__setting-wrapper">
                    <IconButton icon="edit" onClick={() => setEditingField(field)} />
                    <IconButton icon="delete" onClick={() => deleteField(field)} />
                </div>)}
              </CardSetting>

              {/* Настройки ключа */}
              {showObject(scheme, field)}

              {/* Создание новой настройки */}
              {addSetting === field ? (
              <div className="upu-card__setting">
                <Input onChange={(e) => setNewSetting({ ...newSetting, key: e.target.value })} placeholder="Имя ключа" />
                <div className="upu-card__setting-wrapper">
                  <Input onChange={(e) => setNewSetting({ ...newSetting, value: e.target.value })} placeholder="Значение ключа" />
                  <IconButton icon="save" onClick={() => createSetting(field)} />
                  <IconButton icon="cancel" onClick={() => { setAddSetting(null); setNewSetting(null); }} />
                </div>
              </div>
              ) : (
              <CardSetting title="Добавить настройку">
                <IconButton icon="add" onClick={() => setAddSetting(field)} />
              </CardSetting>)}
              <Division />
            </CardSetting>
          ))}
        </CardBody>
        <CardFooter>
          <Button onClick={createField}>Добавить поле</Button>
          {isChanged() && <Button onClick={saveChanges}>Сохранить</Button>}
          {isChanged() && <Button onClick={() => setShowScheme(false)}>Отменить</Button>}
        </CardFooter>
      </Card>)}
    </div>
  );
}

export default Scheme;
