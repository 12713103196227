// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#admin-panel__users-table {
  max-width: 800px;
}
#admin-panel__users-table thead tr:not(:first-child) {
  grid-template-columns: 2fr 1fr 1fr;
}
#admin-panel__users-table tbody tr {
  grid-template-columns: 2fr 1fr 1fr;
}
#admin-panel__services-table {
  max-width: 800px;
}
#admin-panel__services-table thead tr:not(:first-child) {
  grid-template-columns: 40px 1fr 1fr;
}
#admin-panel__services-table tbody tr {
  grid-template-columns: 40px 1fr 1fr;
}
#admin-panel__bulk-access {
  max-width: 800px;
  max-height: 600px;
}`, "",{"version":3,"sources":["webpack://./src/pages/AdminPage/adminPage.scss"],"names":[],"mappings":"AAGE;EACE,gBAAA;AAFJ;AAIM;EACE,kCAAA;AAFR;AAMM;EACE,kCAAA;AAJR;AASE;EACE,gBAAA;AAPJ;AASM;EACE,mCAAA;AAPR;AAWM;EACE,mCAAA;AATR;AAcE;EACE,gBAAA;EACA,iBAAA;AAZJ","sourcesContent":["@use \"../../variables.scss\" as var;\n\n#admin-panel {\n  &__users-table {\n    max-width: 800px;\n    & thead {\n      & tr:not(:first-child) {\n        grid-template-columns: 2fr 1fr 1fr;\n      }\n    }\n    & tbody {\n      & tr {\n        grid-template-columns: 2fr 1fr 1fr;\n      }\n    }\n  }\n\n  &__services-table {\n    max-width: 800px;\n    & thead {\n      & tr:not(:first-child) {\n        grid-template-columns: 40px 1fr 1fr;\n      }\n    }\n    & tbody {\n      & tr {\n        grid-template-columns: 40px 1fr 1fr;\n      }\n    }\n  }\n\n  &__bulk-access {\n    max-width: 800px;\n    max-height: 600px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
