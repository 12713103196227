import React from 'react';
import { useSelector } from 'react-redux';

function Table(props) {
  const { chooseQuestion } = props;
  const currentCategory = useSelector((state) => state.interview.current_category);
  const displayedQuestions = useSelector((state) => state.interview.displayed_questions);

  return (
        <table id="interview-table__table" className="upu-table">
            <thead className="upu-table__table-head">
                <tr className="upu-table__header">
                    <td className="pu-table__table-data">{currentCategory ? currentCategory.title : ''}</td>
                </tr>
                {displayedQuestions.length > 0 ? (
                <tr className="upu-table__table-row">
                    <td className="upu-table__table-data">Краткое содержание</td>
                </tr>
                ) : (
                <tr className="upu-table__table-row">
                    <td className="upu-table__table-data">Вопросы отсутствуют</td>
                </tr>)}

            </thead>
            {/* <div> */}
            <tbody className="upu-table__table-body">
                {displayedQuestions.map((q) => (
                    <tr key={q.id} className="upu-table__table-row">
                        <td className="upu-table__table-data">
                            <span onClick={() => chooseQuestion(q)} className="clickable">
                                {q.title}
                            </span>
                        </td>
                    </tr>
                ))}
            </tbody>
            {/* </div> */}
        </table>
  );
}

export default Table;
