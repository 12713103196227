import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { log, setOperInfo } from '../../../../utils/functions/others';
import { createAction } from '../../../../utils/redux/store';
import { clientSendData } from '../../../../utils/functions/requests';
import { alertFunction } from '../../../../utils/functions/alertFunction';
import { catchHandler } from '../../../../utils/error_handling/error_handling';
import EmployeeForm from './EmployeeForm';
import EmployeeLog from './EmployeeLog';
import Button from '../../../../components/UI/Button/Button';
import Close from '../../../../components/UI/Close';
import Loader from '../../../../components/UI/Loader';
import classes from '../../../../components/UI/Button/button.module.scss';
import './employee.scss';

/**
* @component Система контроля РП - Система контроля испытательных сроков - Карточка сотрудника
* @prop {setShowCard} function - Обновление состояния отображения карточки
* @prop {getProbationUsers} function - Обновление данных таблицы пользователей
*/
function Employee(props) {
  const { setShowCard, getProbationUsers } = props;

  const isEditing = useSelector((state) => state.probation.editing); // состояние редактирования
  const employeeInfo = useSelector((state) => state.probation.employee); // информация о сотруднике
  const editEmployeeInfo = useSelector((state) => state.probation.edit_employee); // редактируемая информация о сотруднике
  const editEmployeeSourceInfo = useSelector((state) => state.probation.edit_employee_source); // исходная информация о сотруднике при редактировании
  const [searchParams, setSearchParams] = useSearchParams(); // параметры поиска из адресной строки (id сотрудника)
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [backlight, setBacklight] = useState(''); // состояние подсветки
  // const users = useSelector((state) => state.probation.users); // все сотрудники

  useEffect(() => {
    getCollaborator(); // Получение данных о стажёре
  }, []);

  // Деструктуризация объекта с информацией о сотруднике
  const {
    id, displayname, fired, no_probation, status_id, probation_complete, form_2, original_recieved, mentor_id, title,
  } = employeeInfo;

  // Получение данных о стажёре
  async function getCollaborator() {
    try {
      setOperInfo({ subject_id: employeeInfo.id }); // запись id сотрудника в оперативную информацию
      const reqData = {
        type: 'getСollaborator',
        employee_id: +searchParams.get('collaborator'), // id стажёра из url
      };

      setIsLoading(true); // состояние - загружается
      const result = await clientSendData('POST', '/get_collaborator', reqData); // запрос в БД

      if (result) { // иначе
        createAction('SET_EMPLOYEE_INFO', result.user); // запись в хранилище информации о сотруднике
        createAction('SET_EMPLOYEE_LOG', result.log); // запись в хранилище лога по сотруднику
        setIsLoading(false); // загрузка завершена
      }
    } catch (error) {
      catchHandler(error, 'getCollaborator'); // обработчик ошибок
      setIsLoading(false); // загрузка завершена
    }
  }

  // Получение данных о партнёрах
  async function getPartners() {
    try {
      const reqData = {
        type: 'getPartners',
      };
      const result = await clientSendData('POST', '/get_partners', reqData); // запрос в БД
      if (result) createAction('SET_PARTNERS', result); // запись в хранилище массива партнёров
    } catch (error) {
      catchHandler(error, 'getPartners'); // обработчик ошибок
    }
  }

  // Функция создания выходного интервью
  async function createOutputInterview(log) {
    try {
      const date = new Date().toISOString();
      // Создание объекта для записи
      const reqData = {
        type: 'saveOutputInterview',
        data: {
          dismissal_user: id,
          dismissal_type: 3,
          dismissal_date: date,
          questionnaire: null,
          access: {},
          mentor_id,
          no_dismiss: false,
        },
        log,
      };
      await clientSendData('POST', '/save_output_interview', reqData); // запрос в БД
    } catch (error) {
      catchHandler(error, 'save_output_interview');
    }
  }

  // Проверка, создано ли выходное интервью на пользователя
  async function verifyOutputInterview(user_id) {
    try {
      // Создание объекта для записи
      const reqData = {
        type: 'verifyOutputInterview',
        user_id,
      };
      const result = await clientSendData('POST', '/verify_output_interview', reqData); // запрос в БД
      return result;
    } catch (error) {
      catchHandler(error, 'save_output_interview');
      return null;
    }
  }

  // Удаление выходного интервью
  async function delOutputInterview(id) {
    try {
      // Создание объекта для записи
      const reqData = {
        type: 'delOutputInterview',
        output_interview_id: id,
      };
      await clientSendData('POST', '/del_output_interview', reqData); // запрос в БД
    } catch (error) {
      catchHandler(error, 'save_output_interview');
    }
  }

  // Сохранение выходного интервью
  async function cancelDismiss(user_id) {
    try {
      const logObj = {
        title: 'Изменение',
        output_interview_id: null,
        message: 'Увольнение сотрудника отменено ',
      };
      const newAccess = {};

      // Создание объекта для записи
      const reqData = {
        type: 'saveOutputInterview',
        data: {
          dismissal_user: user_id,
          dismissal_type: 5,
          dismissal_date: null,
          questionnaire: null,
          access: newAccess,
          no_dismiss: true,
        },
        log: { ...logObj },
      };

      const result = await clientSendData('POST', '/save_output_interview', reqData); // запрос в БД
      if (result) {
        alertFunction('save_settings', 'clientPost'); // уведомление о сохранении
        createAction('SET_OUTPUT_INTERVIEW', []); // Очищение хранилища выходного интервью
      }
    } catch (error) {
      catchHandler(error, 'save_output_interview');
    }
  }

  // Переключение параметра
  async function toggleParameter(key, value) {
    try {
      const reqData = {
        type: 'toggleParameter',
        parameter: key, // наименование параметра
        value, // значение
        user_id: id, // user_id из таблицы upu_users
        status_id, // id текущего статуса
      };

      setIsLoading(true); // состояние - загружается
      const result = await clientSendData('POST', '/toggle_parameter', reqData); // запрос в БД
      if (result === 'success') {
        if (key === 'fired') { // Если название ключа уволен
          if (value) { // Если значение true, создаем выходное интервью
            await createOutputInterview({
              title: 'Создание',
              message: 'Сотрудник уволен по причине увольнения до окончания Испытательного срока',
              output_interview_id: null,
            });
          } else {
            // Если значение false, проверяем есть ли выходное интервью
            const interview = await verifyOutputInterview(id);
            // Если есть, удаляем
            if (interview) await cancelDismiss(id);
          }
        } else if (key === 'probation_complete') {
          if (!value) {
            const log = {
              title: 'Создание',
              message: 'Сотрудник уволен по причине не прохождения Испытательного срока',
              output_interview_id: null,
            };
            await createOutputInterview(log);
          } else {
            const interview = await verifyOutputInterview(id);
            if (interview) await cancelDismiss(id);
          }
        }
        getCollaborator(); // обновление карточки
        setIsLoading(false); // загрузка завершена
      }
    } catch (error) {
      catchHandler(error, 'toggleParameter'); // обработчик ошибок
      setIsLoading(false); // загрузка завершена
    }
  }

  // Режим редактирования информации
  function editInfo() {
    log({ message: 'Пользователь нажал кнопку "Редактировать"', eventtype: 'info' });
    getPartners(); // получить массив партнёров
    createAction('EDIT_EMPLOYEE_INFO', employeeInfo); // записать данные сотрудника в отдельный объект для редактирования
    createAction('SET_EDITING', true); // включить режим редактирования информации
  }

  // Сохранение изменений
  async function saveChanges() {
    try {
      log({ message: 'Пользователь нажал кнопку "Сохранить"', eventtype: 'info' });
      const reqData = {
        type: 'editCollaborator',
        edited_info: editEmployeeInfo, // изменённые данные
        edited_info_source: editEmployeeSourceInfo, // исходные данные
      };
      // Деструктуризация объекта измененных данных
      const {
        date_release, date_probation_end, mentor_id, partner_id,
      } = editEmployeeInfo;

      // Валидация полей
      if (!date_release) alertFunction('no_date_release', 'clientPost'); // если не указана дата выхода - уведомление
      else if (!date_probation_end && !no_probation) alertFunction('no_date_probation_end', 'clientPost'); // если не указана дата завершения стажировки - уведомление
      else if (date_release > date_probation_end) alertFunction('wrong_date', 'clientPost'); // если дата выхода больше даты завершения стажировки - уведомление
      else if (!mentor_id) alertFunction('no_mentor', 'clientPost'); // если не выбран наставник - уведомление
      else if (!partner_id) alertFunction('no_partner', 'clientPost'); // если не выбран партнёр - уведомление
      else {
        // Если все поля заполнены корректно - данные сохраняются в БД
        setIsLoading(true); // состояние - загружается
        const result = await clientSendData('POST', '/edit_collaborator', reqData); // запрос в БД

        if (result === 'success') {
          getCollaborator(); // обновление информации в карточке стажёра
          getProbationUsers(); // обновление таблицы
          createAction('SET_EDITING', false); // закрытие формы редактирования
          setBacklight(''); // сброс подсветки
          setIsLoading(false); // загрузка завершена
        }
      }
    } catch (error) {
      catchHandler(error, 'saveChanges'); // обработчик ошибок
      setIsLoading(false); // загрузка завершена
    }
  }

  // Отмена редактирования
  function cancelEditing() {
    log({ message: 'Пользователь нажал кнопку "Отмена"', eventtype: 'info' });
    createAction('SET_EDITING', false); // режим редактирования выкл
    createAction('EDIT_EMPLOYEE_INFO', {}); // сброс редактируемой информации
    setBacklight(''); // сброс подсветки
  }

  // Закрытие карточки
  function closeCard(e) {
    log({ message: 'Пользователь закрыл карточку сотрудника ', eventtype: 'info' });
    setOperInfo({ subject_id: null }); // сброс id сотрудника в оперативной информации
    e.stopPropagation(); // предотвращение погружения события в родительские компоненты
    setShowCard(false);
    createAction('SET_EDITING', false); // режим редактирования выкл (если был вкл)
    setSearchParams(''); // сброс параметров поиска в url
  }

  // Проверка статуса
  // operator - оператор сравнения
  // operand - id статуса с которым сравнивается текущий id статуса
  function checkStatus(operator, operand) {
    if (status_id > 1) { // если id статуса больше 1
      // выполнятся сравнение в зависимости от переданного оператора
      switch (operator) {
        case '<': return status_id < operand;
        case '>': return status_id > operand;
        case '=': return status_id === operand;
        case '<=': return status_id <= operand;
        case '>=': return status_id >= operand;
        case '!=': return status_id !== operand;
        default: return false;
      }
    } else return false;
  }

  return (
    <div className="employee__background" onClick={closeCard}>
      <div className="employee" onClick={(e) => e.stopPropagation()}>
        {/* Закрыть карточку */}
        <Close onClick={closeCard} />
        <h2 className="employee__title">{isLoading ? <Loader /> : `${displayname} ${title ? `(${title})` : ''}` }</h2>

        <div className="employee__form-wrapper">
          {/* Данные сотрудника */}
          <EmployeeForm
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            checkStatus={checkStatus}
            getCollaborator={getCollaborator}
            backlight={backlight}
            setBacklight={setBacklight}
         //   noProbation={no_probation}
            statusId={status_id}
          />

          <div className="employee__button-block">

              {/* Обновить (отображается если статус 1,11,15,16) */}
              {(status_id === 1 || checkStatus('=', 11) || checkStatus('=', 15) || checkStatus('=', 16)) && (
              <Button
                className={classes.button_ghost}
                onClick={() => {
                  getCollaborator();
                  log({ message: 'Пользователь нажал кнопку "Обновить"' });
                }}
              >
                Обновить
              </Button>)}

              {/* Уволить/вернуть (отображается если 1 < статус < 10) */}
              {checkStatus('<', 10) && (
              <Button
                onClick={() => {
                  toggleParameter('fired', !fired);
                  log({ message: `Пользователь нажал кнопку "${fired ? 'Отменить увольнение' : 'Уволить до завершения ИС'}"` });
                }}
              >
                {fired ? 'Отменить увольнение' : 'Уволить до завершения ИС'}
              </Button>)}

              {/* Требуется/не требуется ИС (отображается если 1 < статус < 4) */}
              {(checkStatus('<', 4) || checkStatus('=', 18)) && (
              <Button
                onClick={() => {
                  toggleParameter('no_probation', !no_probation);
                  log({ message: `Пользователь нажал кнопку "${no_probation ? 'Требуется ИС' : 'ИС не требуется'}"` });
                }}
              >
                {no_probation ? 'Требуется ИС' : 'ИС не требуется'}
              </Button>
              )}

              {/* ИС пройден/не пройден */}
              {/* отображается если статус > 8, нет данных о завершении стажировки и получена вторая анкета */}
              {checkStatus('>', 8) && probation_complete === null && form_2 && (
              <>
                <Button
                  onClick={() => {
                    toggleParameter('probation_complete', true);
                    log({ message: 'Пользователь нажал кнопку "ИС пройден"' });
                  }}
                >
                  ИС пройден
                </Button>

                <Button
                  onClick={() => {
                    toggleParameter('probation_complete', false);
                    log({ message: 'Пользователь нажал кнопку "ИС не пройден"' });
                  }}
                >
                  ИС не пройден
                </Button>
              </>)}

              {/* Оригиналы получены */}
              {/* отображается если 10 < статус < 13, нет данных о завершении стажировки */}
              {checkStatus('>', 10) && checkStatus('<', 14) && (
              <Button
                onClick={() => {
                  toggleParameter('original_recieved', !original_recieved);
                  log({ message: `Пользователь нажал кнопку "${original_recieved ? 'Оригиналы не получены' : 'Оригиналы получены'}"`, eventtype: 'info' });
                }}
              >
                {original_recieved ? 'Оригиналы не получены' : 'Оригиналы получены'}
              </Button>)}

              {/* Отмена редактирования */}
              {isEditing && (
              <Button onClick={cancelEditing}>Отмена</Button>)}

              {/* Редактировать/Сохранить (отображается если 1 < статус < 9) */}
              {(checkStatus('<', 9) || checkStatus('=', 18)) && (
                isEditing
                  ? <Button onClick={saveChanges}>Сохранить</Button>
                  : <Button onClick={editInfo}>Редактировать</Button>
              )}
          </div>
        </div>
        {/* Лог по сотруднику */}
        <EmployeeLog />
      </div>
    </div>
  );
}

export default Employee;
