import React, { useEffect, useState } from 'react';
import { alertFunction } from '../../../../utils/functions/alertFunction';
import { clientSendData } from '../../../../utils/functions/requests';
import { catchHandler } from '../../../../utils/error_handling/error_handling';
import { bookingStatuses } from '../../common';
import CardSetting from '../../../../components/Card/CardSetting';
import Loader from '../../../../components/UI/Loader';
import Button from '../../../../components/UI/Button/Button';
import classes from '../../../../components/UI/Button/button.module.scss';
import CardBody from '../../../../components/Card/CardBody';
import CardFooter from '../../../../components/Card/CardFooter';
import Input from '../../../../components/UI/Input';
import InputSearch from '../../../../components/UI/InputSearch';
import Select from '../../../../components/UI/Select';

/**
* @component  Карточка с данными бронирования
*/
function Main(props) {
  const { closeHandler, refreshTable, bookingId } = props;
  const [booking, setBooking] = useState({});
  const [changeBooking, setChangeBooking] = useState({ });
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [isEditing, setIsEditing] = useState(false); // состояние изменения бронирования
  const [isSearching, setIsSearching] = useState(false); // состояние изменения бронирования
  const [changeObj, setChangeObj] = useState({});
  const smallButton = `${classes.button} + ${classes.small}`;
  const currentDate = new Date();
  const today = currentDate.getDate() <= 9
    ? `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`
    : `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${currentDate.getDate()}`;

  useEffect(() => {
    getBookingCard();
  }, []);

  // Получить заявки
  async function getBookingCard() {
    try {
      const reqData = {
        type: 'getBookingCard',
        bookingId,
      };
      setIsLoading(true);
      const result = await clientSendData('POST', '/get_booking_card', reqData);
      if (result?.success) {
        setBooking(result?.data); // Результат записывается в состояние
      }
    } catch (error) {
      catchHandler(error, 'getBookingCard');
    } finally {
      setIsLoading(false);
    }
  }

  async function cancelBooking() {
    await editHandler({ status_id: 2 });
    await alertFunction('booking_canceled', 'clientPost');
    closeHandler();
  }

  async function editHandler(changeData) {
    try {
      const reqData = {
        type: 'editBookingCard',
        bookingId,
        changeBooking: changeData,
      };

      if (Object.keys(changeData).length > 0) {
        if (new Date(changeData?.date_start || booking?.date_start) <= new Date(changeData?.date_end || booking?.date_end)
            && new Date(changeData?.date_end || booking?.date_end) - new Date(changeData?.date_start || booking?.date_start) >= 3600000) {
          setIsLoading(true);
          const result = await clientSendData('POST', '/edit_booking_card', reqData);
          if (result?.success) {
            await refreshTable();
            setIsEditing(false);
            await getBookingCard();
          }
        } else {
          await alertFunction('booking_dates_error', 'client');
        }
      } else {
        setIsEditing(false);
      }
    } catch (error) {
      catchHandler(error, 'editBookingCard');
    } finally {
      setIsLoading(false);
    }
  }

  function changeHandler() {
    setIsEditing((state) => !state);
  }

  function chooseDates(dateObj) {
    setBooking((state) => ({ ...state, ...dateObj }));
    setChangeBooking((state) => ({ ...state, ...dateObj }));
    let new_date_start = new Date(`${booking.day_start} ${booking.time_start}`).toUTCString();
    let new_date_end = new Date(`${booking.day_end} ${booking.time_end}`).toUTCString();
    if (Object.keys(dateObj).includes('day_start')) { new_date_start = new Date(`${dateObj.day_start} ${booking.time_start}`).toUTCString(); } else if (Object.keys(dateObj).includes('time_start')) { new_date_start = new Date(`${booking.day_start} ${dateObj.time_start}`).toUTCString(); } else if (Object.keys(dateObj).includes('day_end')) { new_date_end = new Date(`${dateObj.day_end} ${booking.time_end}`).toUTCString(); } else if (Object.keys(dateObj).includes('time_end')) { new_date_end = new Date(`${booking.day_end} ${dateObj.time_end}`).toUTCString(); }
    setChangeObj((state) => ({
      ...state,
      user_id: changeBooking.user_id,
      date_start: new_date_start,
      date_end: new_date_end,
    }));
  }
  function chooseUser(user) {
    setChangeBooking((state) => ({ ...state, user_id: user.id }));
  }

  async function saveHandler() {
    await editHandler(changeObj);
  }

  async function searchWrapper(e) {
    setIsSearching(true); // поиск выполняется
    if (e.target.value.length === 0) setBooking((prev) => ({ ...prev }));
    try {
      const reqData = {
        type: 'searchUser',
        query: e.target.value,
      };
      const result = await clientSendData('POST', '/search_user', reqData);
      if (result?.success) setBooking((state) => ({ ...state, users: result.data }));
      setIsSearching(false);
      return [];
    } catch (error) {
      catchHandler(error, 'searchUser');
      setIsSearching(false);
      return [];
    }
  }

  if (isLoading) return <CardBody><Loader /></CardBody>;
  return (
    <>
      <CardBody>
        <CardSetting title="№:">{booking?.id}</CardSetting>
        <CardSetting title="Место:">{booking?.workplace}</CardSetting>
        <CardSetting title="Статус:">{booking?.status}</CardSetting>
        <CardSetting title="Сотрудник:">
          {isEditing
            ? <InputSearch
                id="helpdesk__new-ticket-search-initiator"
                array={booking.users}
                onSearch={(e) => searchWrapper(e)}
                onChoose={(user) => chooseUser(user)}
                placeholder="Выберите сотрудника"
                defaultValue={booking.user}
                loading={isSearching}
                disabled={!booking?.day_start}
            />
            : booking?.user}
        </CardSetting>
        <CardSetting title="Дата начала:">
          {isEditing
            /* <Input
                type="datetime-local"
                onChange={(event) => chooseDates({ date_start: event.target.value.replace('T', ' ') })}
                defaultValue={booking?.date_start}
            /> */
            ? <div className="adminCard__edit">
             <Input
               type="date"
               min={today}
               onChange={(event) => chooseDates({ day_start: event.target.value })}
               defaultValue={booking?.day_start}
             />
              <Select
                key="start"
                id="start_time"
                array={booking?.time}
                onChoose={(event) => chooseDates({ time_start: event.title })}
                defaultValue={booking?.time_start}
              />
              </div>
            : booking?.date_start}
        </CardSetting>
        <CardSetting title="Дата окончания:">
          {isEditing
            ? <div className="adminCard__edit">
            <Input
              type="date"
              min={today}
              onChange={(event) => chooseDates({ day_end: event.target.value })}
              defaultValue={booking?.day_end}
            />
            <Select
              key="end"
              id="end_time"
              array={booking?.time}
              onChoose={(event) => chooseDates({ time_end: event.title })}
              defaultValue={booking?.time_end}
            />
              </div>
            /* <Input
                type="datetime-local"
                onChange={(event) => chooseDates({ date_end: event.target.value.replace('T', ' ') })}
                defaultValue={booking?.date_end}
            /> */
            : booking?.date_end}
        </CardSetting>
        {booking?.status_id === bookingStatuses.reserved && !isEditing && (
        <CardSetting title="Отмена бронирования:">
           <Button className={smallButton} onClick={cancelBooking}>Отменить</Button>
        </CardSetting>)}
      </CardBody>
      <CardFooter>
        {booking?.status_id === bookingStatuses.reserved && !isEditing && (<Button onClick={changeHandler}>Редактировать</Button>)}
        {isEditing && (
          <>
          <Button onClick={saveHandler}>Сохранить</Button>
          <Button onClick={changeHandler}>Отмена</Button>
          </>
        )}
        {!isEditing && (<Button onClick={closeHandler}>Закрыть</Button>)}
      </CardFooter>
    </>

  );
}

export default Main;
