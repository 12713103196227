import React, { useRef } from 'react';

/**
  * @component Инпут для выбора файлов через окно ОС
  * @prop {string} id Уникальный идентификатор DOM элемента
  * @prop {string} [accept = '*'] Разрешённые к выбору файлы
  * @prop {function} onChange Обработчик файла (получает на вход файл/массив файлов)
  * @prop {boolean} multiple Параметр множественного выбора файлов
  */
function InputFile(props) {
  const {
    id,
    onChange,
    accept = '*',
    multiple = false,
    children,
  } = props;

  const inputRef = useRef(null);

  const handleChildClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  return (
    <div onClick={handleChildClick} className="input-file__wrapper">
      {children}
      <input
        ref={inputRef}
        id={id}
        type="file"
        name="file"
        accept={accept}
        onChange={onChange}
        className="input-file"
        multiple={multiple}
      />
    </div>
  );
}
export default InputFile;
