import React from 'react';

/**
* @component Кнопка "Подтвердить" (зелёная галочка)
@prop {onClick} function (Обязательный) Обработчик клика
@prop {disabled} boolean сделать кнопку неактивной
*/

function CheckMark(props) {
  const { disabled, onClick } = props;

  // Обработка клика
  function clickHandler(e) {
    if (!disabled) onClick(e);
  }

  // Определение класса
  const defineClass = () => {
    let result = 'upu__checkmark';
    if (disabled) result += '_disabled';
    return result;
  };

  return (
    <img onClick={clickHandler} className={defineClass()} src="../../icons/check.svg" alt="checkmark" />
  );
}

export default CheckMark;
