import React, { useEffect, useState } from 'react';
import { clientSendData } from '../../utils/functions/requests';
import { catchHandler } from '../../utils/error_handling/error_handling';
import { headers } from './common';
import ServiceBody from '../../components/Service/ServiceBody';
import Table from '../../components/Table/Table';
import THead from '../../components/Table/THead';
import TBody from '../../components/Table/TBody';
import TRow from '../../components/Table/TRow';
import TData from '../../components/Table/TData';
import Wrapper from '../../components/Basic/Wrapper';
import CheckMark from '../../components/UI/CheckMark';
import EditorButton from '../../components/UI/Button/EditorButton';
import Input from '../../components/UI/Input';
import TFoot from '../../components/Table/TFoot';
import Cancel from '../../components/UI/Cancel';
import { alertFunction } from '../../utils/functions/alertFunction';
import TextArea from '../../components/UI/TextArea';
import Loader from '../../components/UI/Loader';

function Hardwares() {
  const [createHardware, setCreateHardware] = useState(false);
  const [editingRow, setEditingRow] = useState(null);
  const [changeData, setChangeData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [hardwares, setHardwares] = useState([]);

  function createNewHardware() {
    setCreateHardware(true);
    setChangeData({ title: null, parameters: '{"host":"","username": "", "password": ""}', change: true });
    setEditingRow(null);
  }

  function cancelCreating(e) {
    e.preventDefault(); // предотвращение действий формы по умолчанию
    setCreateHardware(false); // изменение состояния создания группы
    setChangeData({});
  }

  useEffect(() => {
    getHardwares();
  }, []);

  async function getHardwares() {
    try {
      const reqData = {
        type: 'admGetHardwares',
      };
      setIsLoading(true); // загружается
      const result = await clientSendData('POST', '/adm_get_hardwares', reqData); // запрос в БД action: useState
      if (result.data.length > 0) {
        setHardwares(result.data);
        setIsLoading(false);
      }
    } catch (error) {
      catchHandler(error, 'admGetHardwares'); // обработчик ошибок
    } finally { setIsLoading(false); }
  }

  async function editHardware(editAction) {
    try {
      if (changeData.change || editAction === 'delete') {
        const validate = Object.keys(changeData).every((el) => Boolean(changeData[el]));
        const parameters = JSON.parse(changeData.parameters);
        if (validate || editAction === 'delete') {
          setChangeData((state) => ({ ...state, parameters }));
          const reqData = {
            type: 'editAdmHardwares',
            action: editAction,
            data: changeData,
          };
          const result = await clientSendData('POST', '/edit_adm_hardwares', reqData); // запрос в БД
          if (result.success) {
            alertFunction('save_settings', 'client');
            getHardwares();
          }
        } else {
          alertFunction('something_went_wrong', 'client');
        }
      }
    } catch (error) {
      alertFunction('something_went_wrong', 'client');
      catchHandler(error, 'editAdmHardwares'); // обработчик ошибок
    } finally {
      setEditingRow(null);
      setCreateHardware(false);
    }
  }

  function changeHardware(hardware) {
    setEditingRow(hardware.id);
    setChangeData(hardware);
  }

  return (
      <ServiceBody>
      {isLoading ? <Loader />
        : <Table id="hardwares">
              <THead headers={headers.hardwares} title="Оборудование" />
              { /* Тело таблицы */}
              <TBody>
                  {hardwares.map((hardware) => (
                   <TRow key={hardware.id}>
                    {editingRow === hardware.id ? (
                      <>
                        <TData>
                          <Wrapper>
                          <Input
                            defaultValue={changeData.title}
                            onChange={(e) => setChangeData((state) => ({ ...state, title: e.target.value, change: true }))}
                          />
                          <CheckMark onClick={() => editHardware('update')} />
                          <EditorButton onClick={() => editHardware('delete')} icon="delete" />
                          </Wrapper>
                        </TData>
                        <TData>
                          <TextArea
                            defaultValue={changeData.parameters}
                            onChange={(e) => setChangeData((state) => ({ ...state, parameters: e.target.value, change: true }))}
                          />
                        </TData>
                      </>
                    )
                      : (
                      <>
                        <TData onClick={() => changeHardware(hardware)}>{hardware.title}</TData>
                        <TData>{hardware.parameters}</TData>
                      </>
                      )}
                   </TRow>
                  ))}
              </TBody>
            { /* Футер */ }
            <TFoot>
              {createHardware ? (
                  <TRow>
                      <TData>
                      <Wrapper>
                          <Input placeholder="Введите название" onChange={(e) => setChangeData((state) => ({ ...state, title: e.target.value }))} />
                          <TextArea
                            placeholder="Введите параметры"
                            defaultValue={changeData.parameters}
                            onChange={(e) => setChangeData((state) => ({ ...state, parameters: e.target.value }))}
                          />
                          <CheckMark onClick={() => editHardware('create')} />
                          <Cancel onClick={cancelCreating} />
                      </Wrapper>
                      </TData>
                  </TRow>
              ) : (
                  <TRow>
                      <TData onClick={createNewHardware}>Добавить оборудование</TData>
                  </TRow>
              )}
            </TFoot>
          </Table>}
      </ServiceBody>

  );
}

export default Hardwares;
