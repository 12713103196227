import React, { useEffect, useState } from 'react';
import Loader from '../../../components/UI/Loader';
import { catchHandler } from '../../../utils/error_handling/error_handling';
import { alertFunction, alertFunctionLocal } from '../../../utils/functions/alertFunction';
import { getCookie } from '../../../utils/functions/cookies';
import { clientSendData } from '../../../utils/functions/requests';
import SessionTableData from './SessionTableData';
import CheckBox from '../../../components/UI/CheckBox';
import ServiceBody from '../../../components/Service/ServiceBody';
import THead from '../../../components/Table/THead';
import Table from '../../../components/Table/Table';
import TBody from '../../../components/Table/TBody';
import TFoot from '../../../components/Table/TFoot';
import TRow from '../../../components/Table/TRow';
import TData from '../../../components/Table/TData';
import TextArea from '../../../components/UI/TextArea';

/**
 * @component Пользователи и права - Таблица сессий
*/
function Sessions() {
  const [activeButton, setActiveButton] = useState('current'); // активная кнопка
  const [isLoading, setIsLoading] = useState(false); // Состояние загрузки
  const [loadingElement, setLoadingElement] = useState(''); // Состояние загрузки отдельного элемента
  const [showMore, setShowMore] = useState(''); // Состояние кнопки дополнительного меню
  const [sessions, setSessions] = useState([]); // массив для отображения в зависимости от выбранной кнопки
  const [editingSession, setEditingSession] = useState(''); // id редактируемой сессия
  const [editingSessionData, serEditingSessionData] = useState(''); // редактируемая сессия

  useEffect(() => {
    getSessions('current');
  }, []);

  // Функция получения информации и сессиях
  async function getSessions(parameter) {
    try {
      const reqData = {
        type: 'getSessions',
        parameter,
      };
      setIsLoading(true);
      const result = await clientSendData('POST', '/get_sessions', reqData);
      if (result) setSessions(result);
    } catch (error) {
      catchHandler(error, 'getSessions');
    } finally {
      setIsLoading(false);
    }
  }

  // Функция переключения блокировки сессии
  async function toggleBlock(id, block) {
    try {
      const reqData = {
        type: 'toggleBlockSession',
        id,
        blocked: block,
      };
      setLoadingElement(id);
      const newExpDate = await clientSendData('POST', '/toggle_block_session', reqData);
      // локальное внесение изменений
      const newSessions = sessions.map((session) => {
        if (session.id === id) {
          return {
            ...session,
            date_experation: newExpDate,
            blocked: Number(!block),
          };
        } return session;
      });
      setSessions(newSessions);
    } catch (error) {
      catchHandler(error, 'toggleBlock');
    } finally {
      setLoadingElement('');
    }
  }

  // Функция переключения параметра очистки куки
  async function toggleClearCookies(e, id) {
    try {
      const reqData = {
        type: 'toggleClearCookies',
        id,
        value: e.target.checked,
      };

      setLoadingElement(id);
      const result = await clientSendData('POST', '/toggle_clear_cookies', reqData);
      const newSessions = sessions.map((session) => {
        if (session.id === id) return { ...session, clear_cookie: result };
        return session;
      });
      setSessions(newSessions);
    } catch (error) {
      catchHandler(error, 'toggleClearCookies');
    } finally {
      setLoadingElement('');
    }
  }

  // Функция переключения блокировки всех активных сессий
  async function toggleBlockAll(block) {
    try {
      const reqData = {
        type: 'toggleBlockActive',
        blocked: block,
        session_id: getCookie('session'),
      };
      setLoadingElement(block ? 'blockAll' : 'unBlockAll');
      const result = await clientSendData('POST', '/toggle_block_active', reqData);
      setSessions(result);
    } catch (error) {
      catchHandler(error, 'toggleBlockAll');
    } finally {
      setLoadingElement('');
    }
  }

  // Функция удаления сессии
  async function deleteSession(id) {
    try {
      const confirm = window.confirm('Вы действительно хотите удалить сессию?');
      if (confirm) {
        const reqData = {
          type: 'deleteSession',
          id,
        };
        setShowMore('');
        const result = await clientSendData('POST', '/delete_session', reqData);
        if (result) {
          const newSessions = sessions.filter((session) => session.id !== id);
          setSessions(newSessions);
        } else alertFunction('something_went_wrong', 'clientPost');
      } else return;
    } catch (error) {
      catchHandler(error, 'deleteSession');
    }
  }

  // Изменение объекиа редактируемой сессии
  function changeSetting(object) {
    serEditingSessionData((state) => ({ ...state, ...object }));
  }

  // Функция редактирования сессии
  function showEditSession(session) {
    setEditingSession(session.id);
    setShowMore('');
    serEditingSessionData(session);
  }

  // Функция скрывающая форму редактирования
  function hideEditSession() {
    setEditingSession('');
    setShowMore('');
    serEditingSessionData({});
  }

  // Функиция охранения и отправки измененных данных в бд
  async function saveChanges() {
    try {
      const reqData = {
        type: 'editSession',
        edited_session: editingSessionData,
      };
      const result = await clientSendData('POST', '/edit_session', reqData);
      if (result === 'success') {
        const newSessions = sessions.map((session) => {
          if (session.id === editingSessionData.id) return editingSessionData;
          return session;
        });
        setSessions(newSessions);
        alertFunction('save_settings', 'clientPost');
        setEditingSession('');
      } else if (result !== 'success') alertFunctionLocal(JSON.stringify(result));
      else alertFunction('something_went_wrong', 'clientPost');
    } catch (error) {
      catchHandler(error, 'saveChanges');
    }
  }

  // Функция переключения состояния видимости доп.меню
  function toggleMore(id) {
    if (showMore !== id) setShowMore(id);
    else setShowMore('');
  }

  // Функция выбора вкладки таблицы
  function chooseTab(tab) {
    setActiveButton(tab);
    getSessions(tab);
    setEditingSession('');
  }

  return (
    <ServiceBody>
      {isLoading ? <Loader /> : ( // если загружается - показывается лоадер
      <Table id="admin-panel__sessions-table">
        <THead title={`Сессии (${sessions.length})`}>
          <TRow>
            {/* Кнопка активных сессий */}
            <TData active={activeButton === 'current'} onClick={() => chooseTab('current')}>
              Активные сессии
            </TData>
            {/* Кнопка всех сессий */}
            <TData active={activeButton === 'all'} onClick={() => chooseTab('all')}>
              Все сессии
            </TData>
          </TRow>
          {/* Заголовки колонок */}
          <TRow>
            <TData>id</TData>
            <TData mobileHide>ip</TData>
            <TData mobileHide>secret</TData>
            <TData>user</TData>
            <TData mobileHide>auth</TData>
            <TData>expire</TData>
            <TData mobileHide>created</TData>
            <TData mobileHide>browser</TData>
            <TData mobileHide>clear cookie</TData>
            <TData>block</TData>
          </TRow>
        </THead>
        <TBody>
          {sessions.map((session) => (
            <React.Fragment key={session.id}>
              {/* Данные сессии */}
              <TRow>
                <SessionTableData
                  editingSession={editingSession}
                  id={session.id}
                  data={session.id_session}
                  changeSetting={changeSetting}
                  field="id_session"
                />
                <SessionTableData
                  editingSession={editingSession}
                  id={session.id}
                  data={session.ip_session}
                  changeSetting={changeSetting}
                  field="ip_session"
                  mobile_hide="mobile_hide"
                />
                <SessionTableData
                  editingSession={editingSession}
                  id={session.id}
                  data={session.secret}
                  changeSetting={changeSetting}
                  field="secret"
                  mobile_hide="mobile_hide"
                />
                <SessionTableData
                  editingSession={editingSession}
                  id={session.id}
                  data={session.upu_users_objectsid}
                  changeSetting={changeSetting}
                  ext_data={session.samaccountname}
                  field="upu_users_objectsid"
                />
                <SessionTableData
                  editingSession={editingSession}
                  id={session.id}
                  data={session.auth}
                  changeSetting={changeSetting}
                  field="auth"
                  mobile_hide="mobile_hide"
                />
                <TData>
                  {editingSession === session.id
                    ? <TextArea
                        defaultValue={session.date_experation}
                        onChange={(e) => changeSetting({ date_experation: e.target.value })}
                    />
                    : loadingElement === session.id
                      ? <Loader />
                      : session.date_experation}
                </TData>
                <TData mobileHide>{session.date_created}</TData>
                <SessionTableData
                  editingSession={editingSession}
                  id={session.id}
                  data={session.user_agent}
                  changeSetting={changeSetting}
                  field="user_agent"
                  mobile_hide="mobile_hide"
                />
                <TData mobileHide>
                  {editingSession === session.id // Если активна форма редактирования показывается textarea
                    ? <CheckBox defaultChecked={session.clear_cookie} onChange={(e) => changeSetting({ clear_cookie: e.target.checked })} />
                    : activeButton === 'all' // если активна кнопка "Все сессии"
                      ? String(session.clear_cookie) // значение блокировки
                      : loadingElement === session.id // иначе проверяется состояние загрузки текущей кнопки
                        ? <Loader /> // Если загружается - показывается лоадер
                        : <CheckBox defaultChecked={session.clear_cookie} onChange={(e) => toggleClearCookies(e, session.id)} />}
                </TData>

                <TData>
                  {/* Кнопка дополнительного меню */}
                  {editingSession !== session.id && <img className="upu-service__more-button" onClick={() => toggleMore(session.id)} src="../../../icons/more.svg" alt="more" />}
                  {editingSession === session.id // Если активна форма редактирования показывается textarea
                    ? <TextArea
                        defaultValue={session.blocked}
                        onChange={(e) => changeSetting({ blocked: e.target.value })}
                    />
                    : activeButton === 'all' // если активна кнопка "Все сессии"
                      ? session.blocked // значение блокировки
                      : loadingElement === session.id // иначе проверяется состояние загрузки текущей кнопки
                        ? <Loader /> // Если загружается - показывается лоадер
                        : session.blocked // Если все словия выше не выполняются показывается значок в зависимости от состояния блокировки
                          ? <img className="upu-service__lock-button" onClick={() => toggleBlock(session.id, session.blocked)} src="../../../icons/locked.svg" alt="locked" />
                          : <img className="upu-service__lock-button" onClick={() => toggleBlock(session.id, session.blocked)} src="../../../icons/unlocked.svg" alt="unlocked" />}
                </TData>
              </TRow>

              {/* Сохранить или закрыть форму */}
              {editingSession === session.id && (
              <TRow>
                <TData>
                  <span className="clickable" onClick={saveChanges}>Сохранить изменения</span>
                </TData>
                <TData>
                  <span className="clickable" onClick={hideEditSession}>Закрыть форму</span>
                </TData>
              </TRow>)}

              {/* Удалить или редактировать сессию */}
              {showMore === session.id && (
              <TRow>
                <TData>
                  <span className="clickable" onClick={() => deleteSession(session.id)}>Удалить сессию</span>
                </TData>
                <TData>
                  <span className="clickable" onClick={() => showEditSession(session)}>Редактировать информацию</span>
                </TData>
              </TRow>)}
            </React.Fragment>
          ))}
        </TBody>
        <TFoot>
          {/* Футер */}
          <TRow>
            <TData onClick={() => { setLoadingElement('blockAll'); toggleBlockAll(1); }}>
              {loadingElement === 'blockAll' ? <Loader /> : 'Заблокировать все активные сессии'}
            </TData>
            <TData onClick={() => { setLoadingElement('unBlockAll'); toggleBlockAll(0); }}>
              {loadingElement === 'unBlockAll' ? <Loader /> : 'Разблокировать все активные сессии'}
            </TData>
          </TRow>
        </TFoot>
      </Table>)}
    </ServiceBody>
  );
}

export default Sessions;
