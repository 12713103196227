import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createAction } from '../../../utils/redux/store';
import { authorization } from '../../../utils/functions/authenticate';
import { setOperInfo, log } from '../../../utils/functions/others';
import { chooseDivision, getUserDivisions } from '../Common/functions';
import ErrorBoundary from '../../../utils/error_handling/ErrorBoundary';
import ServicePanel from '../../../components/Service/ServicePanel';
import Loader from '../../../components/UI/Loader';
import Button from '../../../components/UI/Button/Button';
import classes from '../../../components/UI/Button/button.module.scss';
import Select from '../../../components/UI/Select';
import Questions from './Questions/Questions';
import Themes from './Themes/Themes';
import CapKB from '../Common/CapKB';
import Cap from '../../Cap/Cap';
import './manager.scss';
import Service from '../../../components/Service/Service';

/**
* @component Управление Базой знаний (основной компонент)
*/
function Manager() {
  const [isAuthorized, setIsAuthorized] = useState(false); // Состояние авторизации
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const activeThemeScheme = useSelector((state) => state.kb.active_scheme.theme); // данные активной схемы темы
  const activeQuestionScheme = useSelector((state) => state.kb.active_scheme.question); // данные активной схемы вопроса
  const choose = useSelector((state) => state.kb.choose); // Состояние выбора
  const userDivisions = useSelector((state) => state.kb.user_divisions); // подразделения доступные пользователю
  const currentDivision = useSelector((state) => state.kb.current_division); // выбранное подразделение
  const areThereAvailableDivisions = userDivisions.length > 0;
  const buttons = ['Темы', 'Вопросы'];

  useEffect(() => {
    setOperInfo();
    awaitRequests();
  }, []);

  async function awaitRequests() {
    setIsLoading(true);
    const checkAuthorization = await authorization(); // авторизация
    setIsAuthorized(checkAuthorization); // обновление состояния авторизации

    await getUserDivisions(); // получить подразделения пользователя
    chooseHandler('Вопросы');
    setIsLoading(false);
  }

  // Обработчик выбора вкладки
  function chooseHandler(tab) {
    createAction('SET_CHOOSED_COMPONENT', tab); // установить состояние выбора
    log({ message: `Пользователь выбрал вкладку ${tab}` });
  }

  if (isLoading) return <Loader />;
  if (isAuthorized) { // если авторизован
    // если пользователю не доступно ни одно подразделение - заглушка
    if (!areThereAvailableDivisions) return <CapKB message="Доступные подразделения отсутствуют" />; // заглушка
    return (
      <Service id="knowledge-base" vertical>
        <ServicePanel id="knowledge-base__aside">
          {/* Выбор подразделения */}
          {userDivisions.length > 1 && (
          <Select
            id="knowledge-base__select-division"
            array={userDivisions}
            onChoose={chooseDivision}
            defaultValue={currentDivision ? currentDivision.title : 'Подразделение'}
          />)}

          {/* Кнопки */}
          {buttons.map((item) => {
            const { button, button_ghost } = classes;
            const buttonClass = choose === item ? button : button_ghost;
            return (
              <Button key={item} onClick={() => chooseHandler(item)} className={buttonClass}>
                {item}
              </Button>
            );
          })}
        </ServicePanel>

        {/* если не выбрано подразделение - заглушка */}
        {!currentDivision && (
        <CapKB message="Выберите подразделение" />)}

        {/* если подразделение выбрано, но отсутствует хотя бы одна из активных схем - заглушка */}
        {currentDivision && (!activeThemeScheme || !activeQuestionScheme) && (
        <CapKB message="В выбранном подразделении отсутствуют активные схемы" />)}

        {/* если подразделение выбрано и присутствуют активные схемы */}
        {currentDivision && activeThemeScheme && activeQuestionScheme && (
        // <div id="knowledge-base__wrapper" className="upu-service">
        <>
          { choose === 'Темы' && <ErrorBoundary><Themes /></ErrorBoundary>}
          { choose === 'Вопросы' && <ErrorBoundary><Questions /></ErrorBoundary>}
        </>
        // </div>
        )}
      </Service>
    );
  } return <Cap />; // если не авторизован - заглушка
}

export default Manager;
