import React, { useEffect, useState } from 'react';
import ServiceBody from '../../components/Service/ServiceBody';
import { catchHandler } from '../../utils/error_handling/error_handling';
import { clientSendData } from '../../utils/functions/requests';
import Table from '../../components/Table/Table';
import THead from '../../components/Table/THead';
import TBody from '../../components/Table/TBody';
import TRow from '../../components/Table/TRow';
import TData from '../../components/Table/TData';
import { headers, rowNames } from './common';
import Select from '../../components/UI/Select';
import { alertFunction } from '../../utils/functions/alertFunction';

function Permissions() {
  const [permissions, setPermissions] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [allGroups, setAllGroups] = useState([]);
  const [selectData, setSelectData] = useState({});
  const [changeData, setChangeData] = useState({});
  useEffect(() => {
    getPermissions();
  }, []);

  async function getPermissions() {
    try {
      const reqData = {
        type: 'getPermissions',
      };
      const result = await clientSendData('POST', '/get_permissions', reqData); // запрос в БД
      if (result) {
        setPermissions(result.data);
        setAllGroups(result.allGroups);
        setAllUsers(result.allUsers);
      }
    } catch (error) {
      catchHandler(error, 'getPermissions'); // обработчик ошибок
    }
  }

  async function addUserGroup() {
    setSelectData({});
    await editPermissions(changeData);
  }
  async function editPermissions(obj) {
    try {
      if (obj.id && obj.value && obj.action) {
        const reqData = {
          type: 'editPermissions',
          ...obj,
        };
        const result = await clientSendData('POST', '/edit_permissions', reqData); // запрос в БД
        if (result?.success) { // если запрос выполнен успешно
          await alertFunction('save_settings', 'client');
        } else {
          await alertFunction('bad_request', 'client');
        }
        setChangeData({});
        await getPermissions();
      }
    } catch (error) { // если ошибка
      catchHandler(error, 'editPermissions'); // обрабатываем ошибку
    }
  }
  async function deletePermission(data, key) {
    const prevData = (permissions.filter((el) => el.id === key))[0].value;
    const newData = prevData.filter((el) => el !== data.toString());
    const obj = { id: key, value: newData, action: 'delete' };
    await editPermissions(obj);
  }
  function chooseData(data, key) {
    const selData = {};
    selData[key] = data.title;
    setSelectData({ ...selData });
    const prevData = (permissions.filter((el) => el.id === key))[0].value;
    const newData = prevData.slice(0);
    if (newData.includes(data.id.toString())) {
      alertFunction('sd_select_exist', 'client');
    } else {
      newData.push(data.id.toString());
      setChangeData({ id: key, value: newData, action: 'add' });
    }
  }
  return (
    <ServiceBody>
        <Table id="">
            <THead
              title="Доступы"
              headers={headers.permissions}
            />
            <TBody>
              {permissions.map((d) => (
                <TRow key={`${d.id}row`}>
                      <TData>{rowNames[d.name]}</TData>
                      <TData key={d.id}>
                          <div className="tags__input-block">
                              <Select
                                id={d.id}
                                array={d.name === 'asset_editing_groups' ? allGroups : allUsers}
                                className="tags__input"
                                onChoose={(data) => chooseData(data, d.id)}
                                defaultValue={selectData[d.id]}
                                search
                              />
                              <div onClick={addUserGroup} className="tags__button">
                                  <img className="tags__img" src="../../icons/cancel.svg" alt="add-tag" />
                              </div>
                          </div>
                          <div className="tags__list">
                              {d.values.map((el) => <p className="tags__tag" key={el.id}>
                                                      <span onClick={() => deletePermission(el.id, d.id)} className="upu__close">X</span>
                                                      {el.title}
                                                    </p>)}
                          </div>
                      </TData>
                </TRow>
              ))}

            </TBody>
        </Table>
    </ServiceBody>
  );
}

export default Permissions;
