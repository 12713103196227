import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { catchHandler } from '../../../../utils/error_handling/error_handling';
import { clientSendData } from '../../../../utils/functions/requests';
import {
  checkLength,
  createPeriod,
  getMonthName,
  getPeriodByInterval,
} from '../../functions';
import Canvas from '../../Components/Canvas/Canvas';
import Table from '../../../../components/Table/Table';
import THead from '../../../../components/Table/THead';
import TRow from '../../../../components/Table/TRow';
import TData from '../../../../components/Table/TData';
import TBody from '../../../../components/Table/TBody';
import TFoot from '../../../../components/Table/TFoot';
import Loader from '../../../../components/UI/Loader';
import TableWrapper from '../../Components/TableWrapper/TableWrapper';

/**
* @component Сервис отчётов анкетирования - Расширенный отчёт
* 1 - Распределение увольняющихся по грейдам
*/
function ReportGrades() {
  const isShowChart = useSelector((state) => state.reports.is_show_chart);// состояние видимости графика
  const [months, setMonths] = useState([]); // период по месяцам
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [rawReport, setRawReport] = useState([]); // сырые данные отчёта
  const [report, setReport] = useState([]); // готовые данные отчёта
  const [searchParams, setSearchParams] = useSearchParams(); // параметры ссылки
  const start_date = searchParams.get('start_date'); // дата начала
  const end_date = searchParams.get('end_date'); // дата кончания
  const period = !!(start_date && end_date); // наличие периода
  const title = 'Распределение увольняющихся по грейдам';

  useEffect(() => {
    getReportGrades(); // получить отчёт по выходному интервью
  }, []);

  // получить отчёт по выходному интервью
  async function getReportGrades() {
    try {
      const reqData = {
        type: 'getReportGrades',
        period,
        start_date,
        end_date,
      };
      setIsLoading(true); // состояние - загружется
      const result = await clientSendData('POST', '/get_oi_report_grades', reqData);
      if (result?.length > 0) {
        // если нет периода - создать на основании дат результата
        if (!period) setMonths(createPeriod(result, 'dismissal_date'));
        // Иначе - создать на основании дат периода
        else setMonths(getPeriodByInterval('month', start_date, end_date));

        setRawReport(result);
        createReport(result);
      } else setIsLoading(false); // загрузка завершена
    } catch (error) {
      catchHandler(error, 'getReportGrades');
      setIsLoading(false); // загрузка завершена
    }
  }
  // Создание отчёта на основе полученных данных
  function createReport(report) {
    // Фильтрация по грейдам
    const gradesNo = report.filter((row) => row.grade === 'НГ');
    const gradesA0_1 = report.filter((row) => row.grade === 'А1' || row.grade === 'А0' || row.grade === 'С1');
    const gradesA2_4 = report.filter((row) => row.grade === 'А2' || row.grade === 'А3' || row.grade === 'А4' || row.grade === 'С2' || row.grade === 'С3' || row.grade === 'С4');
    const gradesA5_7 = report.filter((row) => row.grade === 'А5' || row.grade === 'А6' || row.grade === 'А7');
    const gradesF1 = report.filter((row) => row.grade === 'Ф1');
    const gradesF2 = report.filter((row) => row.grade === 'Ф2');
    const gradesK0_1 = report.filter((row) => row.grade === 'К0' || row.grade === 'К1');
    const gradesK2_4 = report.filter((row) => row.grade === 'К2' || row.grade === 'К3' || row.grade === 'К4');
    const gradesK5_7 = report.filter((row) => row.grade === 'К5' || row.grade === 'К6' || row.grade === 'К7');
    const gradesI = report.filter((row) => row.grade === 'И0' || row.grade === 'И1');

    setReport([
      { title: 'НГ', value: gradesNo },
      { title: 'А0-А1, С1', value: gradesA0_1 },
      { title: 'А2-А4, С2-С4', value: gradesA2_4 },
      { title: 'А5-А7', value: gradesA5_7 },
      { title: 'Ф1', value: gradesF1 },
      { title: 'Ф2', value: gradesF2 },
      { title: 'К0-К1', value: gradesK0_1 },
      { title: 'К2-К4', value: gradesK2_4 },
      { title: 'К5-К7', value: gradesK5_7 },
      { title: 'И0-И1', value: gradesI },
    ]);
    setIsLoading(false); // загрузка завершена
  }

  if (isLoading) return <Loader />;
  if (isShowChart) {
    return (
      <Canvas
        id="reports-oi-grades__canvas"
        title={title}
        label="Количество, %"
        labels={report.map((row) => row.title)}
        values={report.map((row) => (row.value.length / rawReport.length) * 100)}
      />
    );
  }
  return (
    <TableWrapper id="reports-oi-grades" title={title} back_button chart_button>
      <Table id="reports-oi-grades">
        <THead>
          <TRow>
            <TData>Период</TData>
            {months.map((month) => <TData key={month}>{getMonthName(month)}</TData>)}
            <TData>Итого</TData>
          </TRow>
        </THead>
        <TBody>
          {/* Проходим по отчёту */}
          {report.map((row) => (
            // Выводим строки
            <TRow key={row.title}>

              {/* Первая ячейка - названия грейдов */}
              <TData><b>{row.title}</b></TData>

              {/* Далее проходим по месяцам */}
              {months.map((month) => {
                // Находим уволившихся в текущем месяце
                const filterValues = row.value.filter((value) => {
                  const dismissalDate = new Date(value.dismissal_date).getMonth(); // месяц увольнения
                  const fieldDate = new Date(month).getMonth(); // месяц в колонке
                  return dismissalDate === fieldDate; // вернуть совпадения месяцев
                });
                // Выводим ячейки (Длина массива уволившихся в текущем месяце)
                return <TData key={row.title + month}>{checkLength(filterValues)}</TData>;
              })}

              {/* Последняя ячейка - общее количество */}
              <TData>{row.value.length}</TData>
            </TRow>
          ))}
        </TBody>
        <TFoot>
          {/* Последняя строка - итого за месяц */}
          <TRow>
            {/* Первая ячейка - Итого */}
            <TData>Итого</TData>
            {/* Далее проходим по месяцам */}
            {months.map((month, index) => {
              // Находим уволившихся в текущем месяце
              const filterValues = rawReport.filter((value) => {
                const dismissalDate = new Date(value.dismissal_date).getMonth(); // месяц увольнения
                const fieldDate = new Date(month).getMonth(); // месяц в колонке
                return dismissalDate === fieldDate; // вернуть совпадения месяцев
              });
              // Выводим ячейки (Длина массива уволившихся в текущем месяце)
              return <TData key={month + index}>{filterValues.length}</TData>;
            })}
            {/* Последняя ячейка - общее количество за все даты */}
            <TData>{rawReport.length}</TData>
          </TRow>
        </TFoot>
      </Table>
    </TableWrapper>
  );
}

export default ReportGrades;
