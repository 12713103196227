/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { catchHandler } from '../../../../utils/error_handling/error_handling';
import { alertFunction } from '../../../../utils/functions/alertFunction';
import { clientSendData } from '../../../../utils/functions/requests';
import TableWrapper from '../../Components/TableWrapper/TableWrapper';
import Loader from '../../../../components/UI/Loader';
import Canvas from '../../Components/Canvas/Canvas';

/**
* @component Сервис отчётов по СервисДеск - Отчет по оценкам
*/
function PerformerEvaluation() {
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [rawReport, setRawReport] = useState({}); // сырые данные отчёта
  const [searchParams, setSearchParams] = useSearchParams(); // параметры ссылки
  const start_date = searchParams.get('start_date'); // дата начала
  const end_date = searchParams.get('end_date'); // дата окончания
  const period = !!(start_date && end_date); // наличие периода
  const isShowChart = useSelector((state) => state.reports.is_show_chart);// состояние видимости графика
  const title = 'Отчет по оценкам';
  const [report, setReport] = useState([]); // готовые данные отчёта

  useEffect(() => {
    getPerformerEstimation(); // получить отчёт по количеству выполненных запросов по исполнителям
  }, []);

  // получить отчёт по количеству выполненных запросов по исполнителям
  async function getPerformerEstimation() {
    try {
      const reqData = {
        type: 'getPerformerEstimation',
        period,
        start_date,
        end_date,
      };
      setIsLoading(true); // состояние - загружается
      const result = await clientSendData('POST', '/get_performer_estimation', reqData);
      if (result === 'bad_request') { // если некорректный запрос
        alertFunction('bad_request', 'clientPost'); // уведомление
        setIsLoading(false); // загрузка завершена
      } else {
        setRawReport(result);
        createReport(result);
      }
    } catch (error) {
      catchHandler(error, 'getPerformerEstimation');
      setIsLoading(false); // загрузка завершена
    }
  }

  // Создание отчёта на основе полученных данных
  function createReport(reportData) {
    setReport(reportData);
    setIsLoading(false); // загрузка завершена
  }

  const groupedData = report.reduce((acc, curr) => {
    if (acc[curr.performer]) {
      acc[curr.performer].push(curr.estimation);
    } else {
      acc[curr.performer] = [curr.estimation];
    }
    return acc;
  }, {});

  const performers = Object.keys(groupedData).map((el) => {
    const personalEstimation = groupedData[el].reduce((acc, curr) => acc + curr, 0) / groupedData[el].length;
    return `${personalEstimation.toFixed(2)} ${el}`;
  });
  const avgEstimations = Object.keys(groupedData).map((performer) => {
    const estimations = groupedData[performer];
    const average = estimations.reduce((acc, curr) => acc + curr, 0) / estimations.length;
    return average.toFixed(2);
  });

  if (isLoading) return <Loader />;
  if (isShowChart) {
    return (
      <Canvas
        id="reports-sd-performer_evaluation__canvas"
        title={title}
        type="bar"
        label="Средняя оценка"
        labels={performers}
        values={avgEstimations}

      />
    );
  }

  return (
    <TableWrapper id="reports-sd-performer-evaluation" title={title} back_button chart_button>
      <table id="reports-sd-performer-evaluation" className="upu-table">
        <thead className="upu-table__table-head">
          <tr className="upu-table__table-row">
            <td className="upu-table__table-data">Номер запроса</td>
            <td className="upu-table__table-data">Исполнитель</td>
            <td className="upu-table__table-data">Оценка</td>
            <td className="upu-table__table-data">Комментарий</td>
            <td className="upu-table__table-data">Инициатор</td>
          </tr>
        </thead>
        <tbody className="upu-table__table-body">
          {/* Проходим по отчёту */}
          {report.map((estimation) => (
              // Выводим строки
              <tr key={estimation.id} className="upu-table__table-row">
                {/* Первая ячейка - ФИО исполнителя */}
                <td className="upu-table__table-data">{estimation.ticket_number}</td>
                <td className="upu-table__table-data">{estimation.performer}</td>
                <td className="upu-table__table-data">{estimation.estimation}</td>
                <td className="upu-table__table-data">{estimation.comment}</td>
                <td className="upu-table__table-data">{estimation.initiator}</td>
              </tr>
          ))}
        </tbody>
        <tfoot className="upu-table__table-foot" />
      </table>
    </TableWrapper>
  );
}

export default PerformerEvaluation;
