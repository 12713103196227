import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Card from '../../../components/Card/Card';
import CardHeader from '../../../components/Card/CardHeader';
import TabPanel from '../../../components/UI/TabPanel';
import TempAccessMain from './TempAccessMain';
import TempAccessHistory from './TempAccessHistory';
import { setOperInfo } from '../../../utils/functions/others';

function TempAccessCard(props) {
  const { setShowCard, refreshTable } = props;
  const [accessCardId, setAccessCardId] = useState(null);
  const tabs = ['Главная', 'История']; // вкладки
  const [searchParams, setSearchParams] = useSearchParams(); // параметры поиска из url
  const [choice, setChoice] = useState(); // состояние выбора вкладки
  const content = {
    Главная: <TempAccessMain refreshTable={refreshTable} closeHandler={closeHandler} accessCardId={accessCardId} />,
    История: <TempAccessHistory accessCardId={accessCardId} type="1" />,
  };

  useEffect(() => {
    const taskFromUrl = searchParams?.get('task'); // взять id заявки из url
    if (taskFromUrl) { // если id есть
      setAccessCardId(taskFromUrl); // запросить информацию по заявке
      setOperInfo({ subject_id: accessCardId }); // записываем id заявки в оперативную информацию
    } else closeHandler();
  }, [searchParams]);

  // закрыть карточку
  function closeHandler() {
    setOperInfo({ subject_id: null }); // сброс id категории в оперативной информацию
    setSearchParams(''); // сброс параметром url
    setShowCard(false); // закрытие карточки
  }

  // обработка выбора вкладки
  function choiceHandler(tab) {
    setChoice(tab); // установить выбор
  }

  return (
    <Card setShow={closeHandler} id="workplaces__booking-card">
      <CardHeader>
        <TabPanel tabs={tabs} choice={choice} choiceHandler={choiceHandler} />
      </CardHeader>
      {content[choice]}
    </Card>
  );
}

export default TempAccessCard;
