import React from 'react';

/**
* @component Поиск с выбором вариантов
@prop {id} string (Обязательный) идентификатор DOM-элемента
@prop {value} string значение
@prop {group} string (Обязательный) наименование группы радиокнопок
@prop {onChange} function обработчик выбора
@prop {defaultChecked} string выбор по умолчанию
@prop {ismarked} string параметр визуальной маркировки (строковый bool - 'true')
*/

function RadioButton(props) {
  const {
    id, value, group, onChange, defaultChecked, ismarked, children,
  } = props;

  return (
    <div className="radio-button__wrapper">
        <input
          id={id}
          type="radio"
          value={value}
          name={group}
          onChange={onChange}
          defaultChecked={defaultChecked}
          className={ismarked === 'true' ? 'radio-button__input radio-button__input_marked' : 'radio-button__input'}
        />
        <div className="radio-button__mark" />
        <label htmlFor={id} className="radio-button__label">{children}</label>
    </div>
  );
}

export default RadioButton;
