import React, { useState } from 'react';

/**
 * @component Выбор из двух
@prop {id} string (Обязательный) идентификатор DOM элемента
@prop {handler} function (Обязательный) внешний обработчик выбора
@prop {img_1} HTMLelement (Обязательный) изображение в случае выбора 1
@prop {img_2} HTMLelement (Обязательный) изображение в случае выбора 2
*/

function TwoChoice(props) {
  const {
    id, handler, img_1, img_2,
  } = props;

  const [currentChoice, setCurrentChoice] = useState(false); // состояние текущего выбора

  function clickHandler() { // Внутренний обработчик выбора
    handler(!currentChoice); // передать обратное значение текущего выбора во внешний обработчик
    setCurrentChoice((prevChoice) => !prevChoice); // Обновить состояние текущего выбора на противиположное
  }

  const defineClass = () => {
    let result = 'two-choice';
    if (!currentChoice) result += ' two-choice_change';
    return result;
  };

  return (
    <div id={id} onClick={clickHandler} className={defineClass()}>
      <div className="two-choice__circle">
        {currentChoice ? img_1 : img_2}
      </div>
    </div>
  );
}

export default TwoChoice;
