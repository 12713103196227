import React from 'react';
import { createPortal } from 'react-dom';
import Cancel from './Cancel';
import Button from './Button/Button';
import classes from './Button/button.module.scss';

/**
 * @component Модальное окно
 * @prop {active} boolean состояние отображения модального окна
 * @prop {message} string сообщение для отображения
 * @prop {setActive} function функция обновления состояния отображения
 * @prop {callback} function функция которая вызывается при подтверждении действия
*/
function Modal(props) {
  const {
    active, setActive, message, callback, children,
  } = props;

  const smallButtonClass = `${classes.button} ${classes.small}`; // имя класса стилей кнопок
  const appNode = document.querySelector('.App'); // DOM элемент главного контейнера приложения
  const closeModal = () => setActive(false); // закрыть модальное окна
  const confirmHandler = () => { // подтвердить действие и вызвать внешний обработчик
    setActive(false);
    callback();
  };

  return (
    createPortal( // создать портал в верхний div приложения и оторазить модальное окно
      <div className={`modal__background${active ? ' modal__background_active' : ''}`} onClick={closeModal}>
          <div className={`modal${active ? ' modal_active' : ''}`} onClick={(e) => e.stopPropagation()}>
            <div className="modal__body">
              {message
                ? <p className="modal__message">{message}</p>
                : children}
            </div>
            <Button onClick={closeModal} className={smallButtonClass}>Отмена</Button>
            {callback && <Button onClick={confirmHandler} className={smallButtonClass}>Подтвердить</Button>}
          </div>
      </div>,
      appNode,
    )
  );
}

export default Modal;
