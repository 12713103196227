import React from 'react';
import CardSetting from '../../../../../components/Card/CardSetting';
import InputFit from '../../../../../components/UI/InputFit/InputFit';
import Button from '../../../../../components/UI/Button/Button';
import classes from '../../../../../components/UI/Button/button.module.scss';

/**
  * @component ХелпДеск - активы - карточка актива - вкладка "Главная" - строка настройки свойства актива
  * @prop {object} object - объект данных актива
  * @prop {setObject} function - обновление состояния актива
  * @prop {scheme} object - схема актива
  * @prop {onChange} function - обработчик изменения данных в поле
  * @prop {disabled} boolean - параметр активности поля (можно редактировать или нет)
*/
function AssetProperties(props) {
  const {
    object, setAsset, scheme = {}, onChange, disabled,
  } = props;

  // Сортировка ключей схемы, чтобы сначала отображались поля без вложенностей
  const sortedSchemeKeys = Object.keys(scheme).sort((a, b) => {
    if (scheme[a].child_scheme && !scheme[b].child_scheme) return 1;
    if (!scheme[a].child_scheme && scheme[b].child_scheme) return -1;
    return 0;
  });

  // Добавить объект в массив
  function addChildObject(field) {
    // текущее значение основного свойства (если отсутствует - создаем)
    const currentProperty = object?.[field] || [];
    // Новое значение основного свойства (добавляем новый объект со следующим id)
    const newProperty = [...currentProperty, { id: currentProperty.length + 1 }];
    // Обновление состояния актива
    setAsset((prev) => ({
      ...prev,
      object: {
        ...prev.object,
        [field]: newProperty,
      },
    }));
  }

  // Удалить объект из массива
  function deleteChildObject(field, subPropertyId) {
    // текущее значение основного свойства, object.ram
    const newProperty = (object[field]).filter((item) => (item.id !== subPropertyId));
    // Обновление состояния актива
    setAsset((prev) => ({
      ...prev,
      object: {
        ...prev.object,
        [field]: newProperty,
      },
    }));
  }

  return (
    sortedSchemeKeys.map((scheme_key) => {
      const {
        type, title, required, child_scheme,
      } = scheme[scheme_key];

      // Если есть вложенная схема - отобразить ее рекурсивно в дополнительной строке
      if (child_scheme) {
        const array = object?.[scheme_key] || []; // массив объектов, хранящийся в этом ключе

        // Валидация обязательных полей
        const isInvalid = () => {
          const requiredKeys = Object.keys(child_scheme).filter((subkey) => child_scheme[subkey]?.required);// обязательные ключи объектов
          const requiredKeysValid = requiredKeys.length > 0 // если есть обязательные поля
            // проверяем что каждый элемент массива содержит значение в обязательном ключе
            ? requiredKeys.every((subkey) => array.every((item) => item[subkey]))
            : true; // иначе обязательных ключей нет - значит они валидны

          /**  Если поле обязательное - проверяем объекты массива
           * поле будет невалидно будт пройдена если выполняет любое из условий:
           * - хотя бы один элемент массива не содержит значения по каждому ключу дочерней схемы
           * - хотя бы один элемент массива не содержит значения по обязательным ключам дочерней схемы (если такие есть)
          */
          if (required) {
            // Если массив пустой - поле невалидное
            if (array.length === 0) return true;
            // Найти в массиве хотя бы один объект, в котором все ключи дочерней схемы содержат значения
            const atLeastOneValid = array.some((item) => Object.keys(child_scheme).every((subkey) => item[subkey]));
            // Хотя бы в одном объекте не заполнены все ключи, или в каждом объекте не заполнены обязательные ключи
            return !atLeastOneValid || !requiredKeysValid;
          }

          // Значит сам ключ необязательный,
          // возвращаем результат проверки обязательных ключей дочернией схемы
          return !requiredKeysValid;
        };

        return (
          <CardSetting title={title} invalid={isInvalid()} key={scheme_key}>

            {!disabled && (
            <Button className={`${classes.button} ${classes.small}`} onClick={() => addChildObject(scheme_key)}>
              Добавить
            </Button>)}

            {array.map((childObject) => {
              const { id } = childObject;
              return (
                <CardSetting key={id}>

                  {!disabled && (
                  <span className="clickable" onClick={() => deleteChildObject(scheme_key, id)}>
                    Удалить
                  </span>)}

                  <AssetProperties
                    object={childObject}
                    setAsset={setAsset}
                    scheme={child_scheme}
                    onChange={(data) => onChange({
                      ...data, // Добавить к полученным данным родительский ключ и id объека в массиве
                      parent_scheme_key: scheme_key, //
                      child_object_id: id,
                    })}
                    disabled={disabled}
                  />
                </CardSetting>
              );
            })}

          </CardSetting>
        );
      }

      // Иначе вложенной схемы нет - показываем универсальный инпут
      return (
        <CardSetting title={title} invalid={required && !object?.[scheme_key]} key={scheme_key}>
          {!disabled ? ( // если можно редактировать - показываем инпут
          <InputFit
            type={type}
            value={object?.[scheme_key]}
            onChange={(value) => onChange({ scheme_key, value })}
            placeholder={title}
          />) : (
            // если нельзя редактировать - показываем данные
            object[scheme_key]
          )}
        </CardSetting>
      );
    })
  );
}

export default AssetProperties;
