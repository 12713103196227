import React from 'react';
import SortCell from './SortCell';

/**
* @component Хедер таблицы - строка сортировки
@prop {headers} array Массив заголовков
@prop {array} array Массив данных отображаемый в таблице
@prop {setArray} function Обновление состояния массива отображаемого в таблице
@prop {sortField} string Сортируемое поле
@prop {setSortField} function Обновление сортируемого поля
@prop {button_place} string Расположение кнопки включения фильтрации
@prop {isFilter} boolean Состояние видимости строки фильтрации
@prop {toggleFilter} function Переключение режима фильтрации
@prop {ext_sorting} function Внешний обработчик сортировки
*/

function SortRow(props) {
  const {
    headers, array, setArray, sortField, setSortField, button_place, isFilter, toggleFilter, ext_sorting,
  } = props;

  return (
    <tr className="upu-table__table-row">
        {headers.map((item, index) => {
          const { title = '', field = '', mobile_hide = false } = item;
          return (
            <SortCell
              key={field + title + index}
              field={field} // наименование поля
              title={title} // заголовок поля
              mobile_hide={mobile_hide} // параметр скрытия при мобильном отображениии
              array={array} // массив данных отображаемый в таблице
              setArray={setArray} // обновление состояния этого массива
              sortField={sortField} // сортируемое поле
              setSortField={setSortField} // обновление сортируемого поля
              ext_sorting={ext_sorting} // внешний обработчик сортировки
            />
          );
        })}
        {button_place?.includes('row') && (
        <td onClick={toggleFilter} className={`upu-table__table-button ${isFilter ? 'upu-table__table-button_active' : ''}`}>
            <img src="../../icons/button/search.svg" alt="search" />
        </td>
        )}
    </tr>
  );
}

export default SortRow;
