import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import Button from '../../../components/UI/Button/Button';
import Input from '../../../components/UI/Input';
import { catchHandler } from '../../../utils/error_handling/error_handling';
import { alertFunction } from '../../../utils/functions/alertFunction';
import { clientSendData } from '../../../utils/functions/requests';
import classes from '../../../components/UI/Button/button.module.scss';
import { getCategories, getQuestions, getUserDirections } from '../functions';
import CheckBox from '../../../components/UI/CheckBox';
import { compareObjectsValues, setOperInfo } from '../../../utils/functions/others';
import CardFooter from '../../../components/Card/CardFooter';
import { emailValidate } from '../../../utils/functions/stringHandling';
import CardSetting from '../../../components/Card/CardSetting';

// Сервис редактирования направлений - карточка направления - вкладка Настройки
function Settings(props) {
  const {
    direction, setDirection, getDirections, setShowCard,
  } = props;
  const currentDirection = useSelector((state) => state.questionnaire.current_direction); // текущее направление
  const location = useLocation(); // адрес
  const [isLoading, setIsLoading] = useState(false); // Состояние загрузки
  const [settings, setSettings] = useState(direction); // имя набора вопросов
  const isEdited = !compareObjectsValues(direction, settings); // показатель изменения исходного объекта

  // Редактировать направление
  async function editDirection(e) {
    try {
      const reqData = {
        type: 'editDirection',
        settings, // настройки
      };

      if (validateDirection()) { // если валидация пройдена
        setIsLoading(true); // загружается
        const result = await clientSendData('POST', '/edit_direction', reqData); // запрос в БД
        if (result === 'success') { // если успех
          await getDirections();
          setDirection(settings); // обновить состояние текущего направления
          setOperInfo({ subject_id: settings.id }); // запись id направления в оперативную информацию
        }
        setIsLoading(false); // загрузка окончена
      }
    } catch (error) {
      catchHandler(error, 'editDirection'); // обработчик ошибок
      setIsLoading(false); // состояние - не загружается
    }
  }

  // Валидация данных перед отправкой
  async function validateDirection() {
    if (!settings.title) {
      await alertFunction('enter_name', 'clientPost');
      return false;
    }
    if (settings.change_notify && !emailValidate(settings.email_notify)) { // почта для уведомлений
      await alertFunction('incorrect_email', 'clientPost');
      return false;
    } return true;
  }

  // Удалить направление
  async function deleteDirection() {
    try {
      // подтверждение
      const confirm = window.confirm('Удаление направления приведёт к удалению всех связанных наборов и вопросов! Удалить?');
      if (confirm) { // если подтверждено
        const reqData = {
          type: 'deleteDirection',
          direction_id: direction.id, // id направления
        };
        setIsLoading(true); // загружается
        const result = await clientSendData('POST', '/delete_direction', reqData); // запрос в БД
        if (result === 'success') { // если успех
          await getUserDirections(location.pathname); // обновление групп доступных пользователю
          await getDirections(); // обновление групп
          getCategories(location.pathname, currentDirection); // обновить информацию о категориях
          getQuestions(location.pathname, currentDirection); // обновить информацию о вопросах
          setOperInfo({ subject_id: null }); // сбросить id направления в оперативной информации
          setShowCard(false); // закрыть карточку направления
        }
        setIsLoading(false); // загрузка окончена
      }
    } catch (error) {
      catchHandler(error, 'deleteDirection'); // обработчик ошибок
      setIsLoading(false); // состояние - не загружается
    }
  }

  // Отменить изменения
  function cancelEditing() {
    setSettings(direction);// сброс имён
  }

  // Изменить имя поля
  function changeSetting(field, value) {
    setSettings({ ...settings, [field]: value });
  }

  // Проверить на наличие значение
  function checkValue(value) {
    if (value) return value;
    return '';
  }

  return (
        <div className="questions-card__block">

            {/* Заголовок направления */}
            <CardSetting title="Заголовок" invalid={!settings.title}>
                <Input
                  placeholder="Введите имя"
                  value={checkValue(settings.title)}
                  onChange={(e) => changeSetting('title', e.target.value)}
                />
            </CardSetting>

            {/* Уведомление об изменениях */}
            <CardSetting title="Уведомление об изменениях" invalid={(settings.change_notify && !settings.email_notify)}>
                <div className="questions-card__setting-wrapper">
                    <CheckBox
                      checked={settings.change_notify}
                      onChange={(e) => changeSetting('change_notify', e.target.checked)}
                    />
                    <Input
                      placeholder="Адрес уведомления"
                      value={checkValue(settings.email_notify)}
                      disabled={!settings.change_notify}
                      onChange={(e) => changeSetting('email_notify', e.target.value)}
                    />
                </div>
            </CardSetting>

            {/* Удаление направления */}
            <CardSetting title="Удалить направление">
                <Button className={classes.button_ghost} onClick={deleteDirection}>Удалить</Button>
            </CardSetting>

            {/* Кнопки управления изменения */}
            {isEdited
            && <CardFooter>
                <Button onClick={cancelEditing}>Отменить</Button>
                <Button onClick={editDirection}>Сохранить</Button>
               </CardFooter>}
        </div>
  );
}

export default Settings;
