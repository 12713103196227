/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { hideSelect } from '../../../Questionnaire/functions';
import { catchHandler } from '../../../../utils/error_handling/error_handling';
import { alertFunction } from '../../../../utils/functions/alertFunction';
import { clientSendData } from '../../../../utils/functions/requests';
import { sortFieldsByScheme } from '../../Common/functions';
import { saveAsWord, setOperInfo, log } from '../../../../utils/functions/others';
import IconButton from '../../../../components/UI/Button/IconButton';
import Division from '../../../../components/UI/Division';
import Cancel from '../../../../components/UI/Cancel';
import Loader from '../../../../components/UI/Loader';
import Arrow from '../../../../components/UI/Arrow';
import Cap from '../../../Cap/Cap';

/**
* @component База знаний - Вопросы - карточка вопроса
* @prop {setShowCard} function - обновление состояния отображения карточки (открыть/закрыть)
*/
function QuestionCard(props) {
  const { setShowCard } = props; // свойства передаваемые компоненту при вызове

  const scheme = useSelector((state) => state.kb.active_scheme.question?.scheme); // активная схема вопроса
  const [searchParams, setSearchParams] = useSearchParams(); // параметры поиска url
  const [question, setQuestion] = useState(null); // текущий вопрос
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const lSKey = useSelector((state) => state.kb.ls_key); // ключ в LS содержащий параметры поиска
  const searchState = JSON.parse(localStorage.getItem(lSKey));

  // убрать user_hide поля
  let fields = Object.keys(scheme).filter((item) => !scheme[item]?.user_hide);
  // Отсортировать поля схемы в зависимости от параметра order
  fields = sortFieldsByScheme({
    scheme,
    key: 'order',
    fields,
  });
  // все кроме типа "html"
  const shortFields = fields.filter((field) => scheme[field]?.type !== 'html' && scheme[field]?.type !== 'json');
  // только "html" поля (показываются после остальных)
  const longFields = fields.filter((field) => scheme[field]?.type === 'html' || scheme[field]?.type === 'json');

  useEffect(() => {
    awaitRequests();
  }, [searchParams]);

  // закрыть карточку
  function closeHandler() {
    setOperInfo({ subject_id: null }); // сброс id категории в оперативной информацию
    setSearchParams('');
    setShowCard(false);
    showPanelAndTable();
  }

  // Ожидание выполнения необходимых запросов
  async function awaitRequests() {
    try {
      const id = Number(searchParams?.get('question'));
      if (id) {
        setIsLoading(true); // состояние - загружается
        hidePanelAndTable(); // спрятать панель и таблицу, чтобы корректно выполнялся поиск на странице
        setOperInfo({ subject_id: id }); // запись id вопроса в оперативную информацию
        await getQuestionKb(id); // получить данные вопроса
        setIsLoading(false); // загрузка завершена
      } else closeHandler();
    } catch (error) {
      setIsLoading(false); // загрузка завершена
      catchHandler(error, 'awaitRequests');
    }
  }

  // Получить данные вопроса
  async function getQuestionKb(id) {
    try {
      const reqData = {
        type: 'getQuestionKb',
        question_id: id,
        scheme, // Текущая схема
        location: window.location.pathname,
      };

      setIsLoading(true); // состояние - загружается
      const result = await clientSendData('POST', '/get_question_kb', reqData);

      if (result === 'bad_request') { // если некорректный запрос
        alertFunction('bad_request', 'clientPost'); // уведомление
        setIsLoading(false); // загрузка завершена
      } else {
        setQuestion(result);
        setIsLoading(false); // загрузка завершена
      }
    } catch (error) {
      catchHandler(error, 'getQuestionKb');
      setIsLoading(false);
    }
  }

  // // Скрыть из пользовательского интерфейса панель тем и таблицу вопросов
  function hidePanelAndTable() {
    const interfaceDiv = document.querySelector('#interface');
    // Пройти по каждому дочернему элементу #interface и спрятать его
    for (const child of interfaceDiv.children) child.style.display = 'none';
    interfaceDiv.style.display = 'flex'; // flex для центрирования анимации загрузки (исходно grid)
  }

  function showPanelAndTable() {
    const interfaceDiv = document.querySelector('#interface');
    // Пройти по каждому дочернему элементу #interface и удалить инлайн стили
    for (const child of interfaceDiv.children) child.removeAttribute('style');
    interfaceDiv.removeAttribute('style'); // удалить инлайн стили для #interface
  }

  // Определить значение
  function definevalue(scheme, field) {
    let value = question?.[field]; // значение ключа в объекте вопроса

    // если ключ схемы имеет switch - использовать switch значение
    if (scheme[field]?.switch) value = question?.[`switch_${field}`];
    // Если тип ключа Boolean - использовать да или нет в зависимости от значения
    if (scheme[field]?.type === 'bool') value = value ? 'Да' : 'Нет';
    // // если значения нет - использовать пустую строку
    // if (!value) value = "-"

    return value;
  }

  // Сохранить вопрос в Word
  function exportToWord() {
    try {
      log({ message: `Пользователь нажал кнопку сохранить в word в вопросе id: ${question.id}`, eventtype: 'info' });
      // Находим поле вопроса, которое будет использоваться в качестве имени скачиваемого файла.
      const questionNameField = Object.keys(scheme).find(((item) => scheme[item]?.download_name));
      // Если такого поля нет, используется id вопроса.
      const fileName = question?.[questionNameField] || (`Вопрос_${question.id}`);
      const result = document.createElement('div');

      // Функция добавляет элементы в результат экспорта.
      function addFields(array) {
        array.forEach((key) => {
          // Определяем значение поля
          const value = definevalue(scheme, key);
          if (question[key]) {
            // Добавляем элемент в результат.
            result.innerHTML += `<b>${scheme[key]?.name}</b>: ${value}<br><br>`;
          }
        });
      }

      // Добавляем короткие поля и длинные поля.
      addFields(shortFields);
      addFields(longFields);

      // Сохраняем результат в файл Word формата.
      saveAsWord(result.innerHTML, fileName);
    } catch (error) {
      // Обрабатываем ошибку и выводим сообщение в консоль.
      catchHandler(error, 'exportToWord');
    }
  }

  if (isLoading) return <Loader />;
  if (question) {
    return (
      <div className="kb-question" onClick={hideSelect}>
        <Division />
        {isLoading ? <Loader /> : (
        // Создаем блок заголовка вопроса, если данные уже загружены
        <div className="kb-question__header">
          {/* Добавляем кнопки закрытия. */}
          <Arrow onClick={closeHandler} />
          <Cancel onClick={closeHandler} />
          {/* Используем короткие поля для отображения информации. */}
          {shortFields?.map((field) => {
            const { name } = scheme[field];
            const value = definevalue(scheme, field);
            if (value) {
              // Отображаем имя поля и его значение.
              return (
                <div className="kb-question__block" key={field}>
                  <p className="kb-question__subtitle kb-question__subtitle_bold">
                    {`${name.toUpperCase()}:`}
                  </p>
                  <p className="kb-question__subtitle">{value}</p>
                </div>
              );
            } return null;
          })}
        </div>)}
        <Division />
        {isLoading ? <Loader /> : (
        <div className="kb-question__body">
          {longFields?.map((field) => {
            const { name } = scheme[field];
            let value = definevalue(scheme, field);
            if (value) {
              if (searchState) {
                searchState.forEach((string) => {
                  const regexp = new RegExp(string, 'ig');
                  value = value.replaceAll(regexp, `<span style='display:inline; color: red'>${string}</span>`);
                });
              }
              return (
                <div className="kb-question__block" key={field}>
                  <p className="kb-question__subtitle_center kb-question__subtitle_bold">{name.toUpperCase()}</p>
                  <div className="kb-question__html" dangerouslySetInnerHTML={{ __html: value }} />
                  <Division />
                </div>
              );
            } return null;
          })}
        </div>)}

        {/* Сохранить в Word */}
        <IconButton icon="save" onClick={exportToWord} />
      </div>
    );
  }
  return <Cap />;
}

export default QuestionCard;
