import React from 'react';

/**
* @component Кнопка панели тектового процессора
@prop все переданные свойства записываются в атрибуты input
@prop {onClick} function (Обязательный) Обработчик клика
@prop {icon} string имя иконки кнопки из доступных в /icons/editor
*/

function EditorButton(props) {
  const { icon, onClick } = props;

  return (
    <div className="editor-button" onClick={onClick}>
      <img className="editor-button__img" src={`../../icons/editor/${icon}.svg`} alt={icon} />
    </div>
  );
}

export default EditorButton;
