import React from 'react';
import CardSetting from './CardSetting';

/**
* @component Отображение объекта с учетом вложенностей
  @prop {object} object -(Обязательный) Объект для отображения
*/

const ObjectDisplay = (props) => {
  const { object = {} } = props;

  // Показать объект
  const showObject = (obj) => {
    if (typeof obj === 'object' && obj !== null) {
      if (Array.isArray(obj)) return obj.map((item) => showObject(item));
      return Object.keys(obj).map((key) => (
        <CardSetting key={key} title={`${key}:`}>
          {showObject(obj[key])}
        </CardSetting>
      ));
    } return <p className="upu__setting-title">{String(obj)}</p>;
  };

  return (
    Object.keys(object).map((key) => (
      <CardSetting key={key} title={`${key}:`}>
        {showObject(object[key])}
      </CardSetting>
    ))
  );
};

export default ObjectDisplay;
