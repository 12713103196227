import React from 'react';
import { useSelector } from 'react-redux';
import { createAction } from '../../utils/redux/store';
import NavButton from './NavButton';
import Close from '../UI/Close';
import './navPanel.scss';

/**
 * @component - Панель навигации портала
*/

function NavPanel() {
  const isShowMenu = useSelector((state) => state.visibility.menu); // отображение бокового меню
  const areas = useSelector((state) => state.services.areas); // области отображения сервисов

  const isOpen = useSelector((state) => state.visibility.help);

  function toogleHelp(e) {
    e.stopPropagation();
    createAction('TOGGLE_HELP', !isOpen);
  }

  return (
    <nav className={`nav ${isShowMenu ? 'nav_active' : ''}`}>
      <Close onClick={() => createAction('TOGGLE_MENU', false)} />
      <p className="nav__title">Категории</p>
      {areas.map((area) => <NavButton key={area.id} area={area} />)}
    </nav>
  );
}

export default NavPanel;
