import React, { useEffect, useState } from 'react';
import { clientSendData } from '../../../utils/functions/requests';
import { catchHandler } from '../../../utils/error_handling/error_handling';
import {
  defaultTableState, headers,
} from '../common';
import ServiceBody from '../../../components/Service/ServiceBody';
import PaginatedTable from '../../../components/Table/PaginatedTable/PaginatedTable';
import TRow from '../../../components/Table/TRow';
import TData from '../../../components/Table/TData';
import NewBookingCard from './BookingCard/NewBookingCard';
import BookingCard from './BookingCard/BookingCard';
/**
* @component  Интерфейс пользователя
*/
function Booking() {
  const tableId = 'workplaces_booking_user';
  const [additionalInfo, setAdditionalInfo] = useState({ total: 0, reserved: false });
  const [bookings, setBookings] = useState([]); // отображаемые бронирования
  const [tableState, setTableState] = useState(JSON.parse(localStorage.getItem(tableId)) || defaultTableState.booking);
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [bookingId, setBookingId] = useState(false); //
  const [showCreateCard, setShowCreateCard] = useState(false); // состояние отображения карточки создания
  const [showCard, setShowCard] = useState(false); // состояние отображения карточки бронирования
  const bookButton = additionalInfo.reserved ? {
    title: 'Забронировать место',
    handler: () => setShowCreateCard(true),
  } : null;
  useEffect(() => {
    getBookings();
  }, [tableState]);

  // Получить заявки
  async function getBookings() {
    try {
      const reqData = {
        type: 'getBookingUser',
        tableState,
        total: additionalInfo.total,
      };
      setIsLoading(true);
      const result = await clientSendData('POST', '/get_booking_user', reqData);
      if (result?.success) {
        setBookings(result.data); // Результат записывается в состояние
        setAdditionalInfo((state) => ({
          ...state,
          total: result?.total,
          reserved: result?.reserved,
        }));
      }
    } catch (error) {
      catchHandler(error, 'getBookingUser');
    } finally {
      setIsLoading(false);
    }
  }

  function openBookingCard(id) {
    setShowCard(true);
    setBookingId(id);
  }

  return (
    <ServiceBody>

      <PaginatedTable
        id={tableId}
        state={tableState}
        setState={setTableState}
        headers={headers.booking}
        total={additionalInfo.total}
        optButton={bookButton}
      >
        {bookings.map((booking) => (
          <TRow key={booking.id}>
            {headers.booking.map((column) => {
              const { field, align, mobile_hide } = column;
              return (
              <TData key={field} align={align} loading={isLoading} mobileHide={mobile_hide}>
                {booking[field]}
              </TData>);
            })}
            <TData loading={isLoading} onClick={() => openBookingCard(booking.id)}>
              <img src="../../icons/file-text.svg" alt="file" />
            </TData>
          </TRow>
        ))}
      </PaginatedTable>

      {showCreateCard && (
      <NewBookingCard
        setShowCard={setShowCreateCard}
        refreshTable={() => setTableState({ ...tableState })}
      />
      )}

{showCard && (
      <BookingCard
        bookingId={bookingId}
        setShowCard={setShowCard}
        refreshTable={() => setTableState({ ...tableState })}
      />
)}
    </ServiceBody>
  );
}

export default Booking;
