import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { downloadDataUrl, setOperInfo } from '../../utils/functions/others';
import { clientSendData } from '../../utils/functions/requests';
import { catchHandler } from '../../utils/error_handling/error_handling';
import ServiceBody from '../../components/Service/ServiceBody';
import Service from '../../components/Service/Service';
import Loader from '../../components/UI/Loader';

/**
* @component Страница скачивания инструкции
*/
function InstructionPage() {
  const { link } = useParams(); // параметр ссылки
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [result, setResult] = useState(''); // результат запроса

  useEffect(() => {
    setOperInfo();
    downloadInstruction();
  }, []);

  // Скачать инструкцию
  async function downloadInstruction() {
    try {
      const reqData = {
        type: 'downloadInstruction',
        link,
      };
      setIsLoading(true); // загрузка началась
      const instruction = await clientSendData('POST', '/download_instruction', reqData);
      setResult(instruction?.result); // записать результат в состояние

      // Если инструкция есть - скачать ее
      if (instruction?.result === 'success') {
        const reader = new FileReader(); // API чтения файлов
        const resBlob = new Blob([new Uint8Array(instruction.instruction.data)]);
        reader.readAsDataURL(resBlob);
        reader.onloadend = ((e) => downloadDataUrl(e.target.result, `Инструкция ${instruction.file_name}`));
      }

      setIsLoading(false); // загрузка завершена
    } catch (error) {
      catchHandler(error, 'uploadInstruction');
    }
  }

  // Сообщение для отображения пользователю
  const message = (data) => {
    if (data === 'success') return 'Инструкция найдена!';
    if (data === 'no_access') return 'К сожалению, доступ к сервису отсутствует';
    return 'Ссылка отсутствует или устарела';
  };

  if (isLoading) return <Loader />;
  return (
    <Service>
      <ServiceBody>
        {message(result)}
      </ServiceBody>
    </Service>
  );
}

export default InstructionPage;
