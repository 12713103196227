import React, { useEffect, useState } from 'react';
import { authorization } from '../../utils/functions/authenticate';
import { setOperInfo } from '../../utils/functions/others';
import Service from '../../components/Service/Service';
import Cap from '../Cap/Cap';
import './admin_workplaces.scss';
import Zones from './Zones';
import Workplaces from './Workplaces';
import Hardwares from './Hardwares';

function AdminWorkplaces() {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const pages = [
    { id: 1, title: 'Рабочие зоны', component: <Zones /> },
    { id: 2, title: 'Рабочие места', component: <Workplaces /> },
    { id: 3, title: 'Оборудование', component: <Hardwares /> },
  ];

  useEffect(() => {
    setOperInfo();
    awaitRequests();
  }, []);

  async function awaitRequests() {
    const checkAuthorization = await authorization(); // авторизация
    setIsAuthorized(checkAuthorization);
  }

  if (isAuthorized) {
    return <Service id="admin-workplaces" pages={pages} horizontal />;
  } return <Cap />;
}

export default AdminWorkplaces;
