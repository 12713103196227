import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { downloadDataUrl, setOperInfo } from '../../../../utils/functions/others';
import { catchHandler } from '../../../../utils/error_handling/error_handling';
import { clientSendData } from '../../../../utils/functions/requests';
import CardHeader from '../../../../components/Card/CardHeader';
import CardBody from '../../../../components/Card/CardBody';
import Card from '../../../../components/Card/Card';
import CardSetting from '../../../../components/Card/CardSetting';
import TicketProcessMessage from './TicketProcessMessage';
import ImgFrame from '../../../../components/UI/ImgFrame';
import { activeTicketStatuses } from '../../common';
import SendForm from '../SendForm';

/**
  * @component ХелпДеск - заявки - карточка заявки
  * @prop {setShowCard} function - обновление состояния отображения карточки (открыть/закрыть)
  * @prop {refreshTable} function - обновление данных таблицы
*/
function TicketCard(props) {
  const { setShowCard, ticketId } = props;
  const [searchParams, setSearchParams] = useSearchParams(); // параметры поиска из url
  const [isLoading, setIsLoading] = useState(false); // состояние выбора вкладки
  const [ticketData, setTicketData] = useState({});
  const [ticketMessages, setTicketMessages] = useState([]);
  const [imageSrc, setImageSrc] = useState(null);
  const [size, setSize] = useState('medium');

  useEffect(() => {
    getUserTicketData(ticketId);
  }, [searchParams]);

  // Проверка наличия активных нарядов на сотруднике техподдержки или его группе
  async function getUserTicketData(ticket_id) {
    try {
      const reqData = { type: 'getUserTicketData', ticket_id };
      setIsLoading(true);
      const result = await clientSendData('POST', '/get_user_ticket_data', reqData);
      // Если есть активные наряды - выбрать вкладку нарядов
      if (result?.success) {
        // console.log(result.data.status_id);
        setTicketData((state) => ({ ...state, ...result.data }));
        setTicketMessages(() => ([...result.messages]));
      }
    } catch (error) {
      catchHandler(error, 'getUserTicketData');
    } finally {
      setIsLoading(false);
    }
  }

  // Скачать вложение
  // id - id записи из sd_ticket_chat
  // fileName - имя файла
  async function downloadAttachment(id, fileName) {
    try {
      const reqData = { type: 'getAttachment' };
      setIsLoading(true);
      const result = await clientSendData('POST', `/get_attachment/${id}?name=${fileName}`, reqData);
      if (result?.success) {
        const fileExtension = fileName.split('.').pop().toLowerCase(); // получаем расширение файла
        const imageFileTypes = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg']; // типы файлов, которые мы считаем изображениями

        // Если расширение файла присутствует в массиве типов файлов-изображений
        if (imageFileTypes.includes(fileExtension)) {
          setImageSrc(result.data);
          setSize('active');
        } else {
          downloadDataUrl(result.data, fileName);
        }
      }
    } catch (error) {
      catchHandler(error, 'downloadAttachment');
    } finally {
      setIsLoading(false);
    }
  }

  // закрыть карточку
  function closeHandler() {
    setOperInfo({ subject_id: null }); // сброс id категории в оперативной информацию
    setSearchParams(''); // сброс параметром url
    setShowCard(false); // закрытие карточки
  }

  return (

    <Card id="helpdesk__ticket-user-card" setShow={closeHandler}>
      <CardHeader />
      <CardBody>
      <CardSetting title="Общение по запросу:" />
        <div className="ticket-user-card__process">
          <div className={`ticket-process__content_wrapper${isLoading ? ' upu__loading' : ''}`}>
            {ticketMessages?.map((item) => (
              <TicketProcessMessage
                key={item.id}
                date_created={item.date_created}
                subject={item.title}
                sender_type={item.sender_type}
                sender={item.sender_name}
                content={
                  item.files && item.files.length > 0
                    ? (
                      <>
                        {item.content}
                        <img
                          src="../../icons/button/paperclip.svg"
                          alt="paperclip"
                          title="Вложение"
                        />
                      </>
                    )
                    : item.content
                }
                message_type={item.message_type}
              />
            ))}
          </div>
        </div>

        {/* ФОРМА ОТПРАВКИ ТОЛЬКО ДЛЯ РАБОЧЕЙ ЗАЯВКИ */}
        { activeTicketStatuses.includes(ticketData.status_id) && (
        <SendForm
          ticketID={ticketId}
          ticketInfo={ticketData}
          getTicketProcess={getUserTicketData}
        />)}
        <div className="ticket-user-card__info">
          <CardSetting title="Исполнитель">{ticketData.performer}</CardSetting>
          <CardSetting title="Статус">{ticketData.status}</CardSetting>
          <CardSetting title="Дата создания">{ticketData.date_created}</CardSetting>
          <CardSetting title="Дата решения">{ticketData.date_solved}</CardSetting>
          <CardSetting title="Дата закрытия">{ticketData.date_closed}</CardSetting>
          <CardSetting title="Вложения к запросу:" />
          {/* ВЛОЖЕНИЯ  */}
          {ticketMessages
            .filter((message) => message.files)
            .map((message) => {
              const { id, files, date_created } = message; //
              return files.map((fileName) => (
                <CardSetting key={id + fileName} title={date_created} loading={isLoading}>
                  <span className="clickable" onClick={() => downloadAttachment(id, fileName)}>
                    {fileName}
                  </span>
                </CardSetting>
              ));
            })
          }
  <ImgFrame size={size} setSize={setSize} imageSrc={imageSrc} />
        </div>
      </CardBody>

    </Card>
  );
}

export default TicketCard;
