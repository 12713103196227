/* eslint-disable no-prototype-builtins */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import Service from '../../../../components/Service/Service';
import ServiceBody from '../../../../components/Service/ServiceBody';
import ServicePanel from '../../../../components/Service/ServicePanel';
import Button from '../../../../components/UI/Button/Button';
import Loader from '../../../../components/UI/Loader';
import { catchHandler } from '../../../../utils/error_handling/error_handling';
import { clientSendData } from '../../../../utils/functions/requests';
import EvaluationCap from '../EvaluationCap';
import Instruction from './Instruction';
import TableEvaluation from './TableEvaluation/TableEvaluation';
import { deepObjectsComparing } from '../../../../utils/functions/others';

// Сервис оценок - страница заявления - заявление на оценку
function Application(props) {
  const { id, projectId, isAdmin } = props;

  const operInfo = useSelector((state) => state.oper_info); // оперативная информация
  const [searchParams, setSearchParams] = useSearchParams(); // параметры url
  const [applicationData, setApplicationData] = useState(null); // данные для таблицы заявления
  const [isLoading, setIsLoading] = useState(false); // параметры поиска url
  const application = applicationData?.general?.application; // объект заявления
  const defaultAppraisal = application?.appraisal; // исходное состояние заявки
  const [appraisal, setAppraisal] = useState(defaultAppraisal); // состояние заявки
  const [extTasks, setExtTasks] = useState(application?.ext_tasks); // дополнительные задачи
  const [projectMasters, setProjectMasters] = useState([]); // список возможных руководителей проекта
  const [isTableVisible, setIsTableVisible] = useState(false);// отображение таблицы с вопросами для заполнения

  const user_id = operInfo?.user_id; // id текущего пользователя
  const isMainApprover = application?.main_approver_id === user_id; // является ли текущий пользователь утверждающим
  const isApprover = application?.approver_id === user_id; // является ли текущий пользователь согласующим
  const isUser = application?.user_id === user_id; // является ли текущий пользователь
  const isMonitoringUser = application?.monitoring; // является ли текущий пользователь
  const isChanged = !deepObjectsComparing(defaultAppraisal, appraisal); // показатель изменения исходного объекта
  const status = application?.status_id; // статус ПО
  const familirizationStatuses = [6, 7]; // статусы для кнопки "Ознакомлен"
  // const isMonitoringUser = applicationData.monitoringUsers.includes(user_id) === user_id;
  useEffect(() => {
    getApplicationData();
    getProjectMasters();
  }, []);

  // Определение возможности редактирования данных
  const defineIsEditable = () => {
    // Cотрудник может редактировать на статусах 1,8,9 (на этих статусах draft = true)
    if (isUser && [1, 8, 9].includes(status)) return true;
    // Согласующий может редактировать на статусах 2,3
    if (isApprover && [2, 3].includes(status)) return true;
    // Утверждающий может редактировать на статусах 4,5
    if (isMainApprover && [4, 5].includes(status)) return true;
    // Сотрудник может редактировать, если он администратор
    if (isAdmin) return true;

    // Иначе нельзя
    return false;
  };

  // Получение руководителей проекта для выбора
  async function getProjectMasters() {
    try {
      const reqData = {
        type: 'getProjectMasters',
      };
      const result = await clientSendData('POST', '/get_project_masters', reqData); // запрос в БД
      if (result) {
        setProjectMasters(result);
      }
    } catch (error) {
      catchHandler(error, 'getProjectMasters'); // обработка ошибок
    }
  }

  // Проверка доступа к заявлению
  async function getApplicationData() {
    try {
      const reqData = {
        type: 'getApplicationData',
        application_id: id,
        projectId,
        isAdmin,
      };
      setIsLoading(true);
      const result = await clientSendData('POST', '/get_application_data', reqData);
      if (result) {
        // оценка
        const appraisalData = result?.general?.application?.appraisal;
        const extTasksData = result?.general?.application?.ext_tasks;
        setApplicationData(result);
        // Если есть данные оценки - записать отдельно в состояние
        if (appraisalData) setAppraisal(appraisalData);
        if (extTasksData) setExtTasks(extTasksData);
      }
      setIsLoading(false);
    } catch (error) {
      catchHandler(error, 'getApplicationData');
    }
  }

  async function getNewApplication(newId) {
    try {
      const reqData = {
        type: 'getApplicationData',
        application_id: newId,
      };
      setIsLoading(true);
      const result = await clientSendData('POST', '/get_application_data', reqData);
      if (result) {
        // оценка
        const appraisalData = result?.general?.application?.appraisal;
        const extTasksData = result?.general?.application?.ext_tasks;
        setApplicationData(result);
        // Если есть данные оценки - записать отдельно в состояние
        if (appraisalData) setAppraisal(appraisalData);
        if (extTasksData) setExtTasks(extTasksData);
      }
      setIsLoading(false);
      setIsTableVisible(true);
    } catch (error) {
      catchHandler(error, 'getApplicationData');
    }
  }

  // Сохранение заявления
  async function saveApplication() {
    try {
      const reqData = {
        type: 'saveApplication',
        application_id: id,
        application: {
          ...applicationData?.general?.application,
          appraisal,
          ext_tasks: extTasks,
        },
      };
      await clientSendData('POST', '/save_application', reqData);
    } catch (error) {
      catchHandler(error, 'saveApplication');
    }
  }

  // Проверка на наличие комментариев к оценкам, отличным от В
  function checkMarksAndComments(entries) {
    if (!isApprover) {
      for (const obj of entries) {
        if (obj.value_char !== 'B' && (!obj.hasOwnProperty('comment') || obj.comment === '')) { return false; }
      }
    }
    return true;
  }

  // Проверить, если комментарий от админа к измененной оценке
  function checkAdminMarksAndComments(entries1, entries2) {
    if (entries1 !== undefined && entries2 !== undefined) {
      for (let count = 0; count < entries1.length; count++) {
        if (entries1[count].value_int !== entries2[count].value_int || entries1[count].value_char !== entries2[count].value_char) {
          if (!entries2[count].hasOwnProperty('approver_comment') || entries2[count].approver_comment === '') { return false; }
          return true;
        }
      }
    }
    return true;
  }

  async function saveAsDraft() {
    try {
      const confirm = window.confirm('Сохранить изменения?');
      if (confirm) {
        const applicationChange = applicationData?.general?.application;
        applicationChange.status_id = 10;
        setIsLoading(true);
        await saveApplication();
        await getApplicationData();
        setIsLoading(false);
        setSearchParams('');
      }
    } catch (error) {
      catchHandler(error, 'saveAsDraft');
    }
  }

  // Отправить на согласование
  async function sendForApproval() {
    try {
      let message = 'Вы уверены, что готовы отправить?';
      if (isChanged) message = 'Перед отправкой будет выполнено сохранение изменений. Отправить на утверждение?';
      // Запросить подтверждение у пользователя
      const confirm = window.confirm(message);
      const values = Object.values(appraisal);
      const entries = Object.values(values[0]);

      if (confirm) { // Если готов отправить
        setIsLoading(true);
        if (isChanged && checkMarksAndComments(entries)) {
          await saveApplication(); // Сохранить изменения если они имеются
          const reqData = {
            type: 'sendForApproval',
            application_id: id,
            status_id: status,
            url: operInfo?.url,
          };

          await clientSendData('POST', '/send_for_approval', reqData);
          setIsLoading(false);
          setSearchParams(''); // сбросить параметры поиска для возвращения в интерфейс сотрудника
        } else { alert('Пожалуйста, заполните все необходимые комментарии'); }
        setIsLoading(false);
      }
    } catch (error) {
      catchHandler(error, 'sendForApproval');
    }
  }

  // Согласовать заявление (1 этап)
  async function firstApproveApp() {
    try {
      let message = 'Согласовать?';
      if (isChanged) message = 'Внесенные изменения не сохранятся, согласовать без корректировок?';

      // Запросить подтверждение у руководителя
      const confirm = window.confirm(message);
      if (confirm) { // Если готов отправить
        // const comment = window.prompt("Оставьте комментарий (необязательно)")
        const reqData = {
          type: 'firstApproveApplication',
          application_id: id,
          url: operInfo?.url,
          // comment: comment
        };

        setIsLoading(true);
        await clientSendData('POST', '/first_approve_application', reqData);
        setIsLoading(false);
        setSearchParams(''); // сбросить параметры поиска для возвращения в интерфейс руководителя
      }
    } catch (error) {
      catchHandler(error, 'firstApproveApp');
    }
  }

  // Сохранить и согласовать заявление (1 этап)
  async function firstApproveModifiedApp() {
    try {
      // Запросить подтверждение у руководителя
      const confirm = window.confirm('Согласовать c учётом внесённых изменений?');
      if (confirm) { // Если готов отправить
        // const comment = window.prompt("Оставьте комментарий (необязательно)")
        const reqData = {
          type: 'firstApproveApplication',
          application_id: id,
          url: operInfo?.url,
          // comment: comment,
          modified: true,
        };

        setIsLoading(true);
        await saveApplication(); // Сохранить изменения
        await clientSendData('POST', '/first_approve_application', reqData);
        setIsLoading(false);
        setSearchParams(''); // сбросить параметры поиска для возвращения в интерфейс руководителя
      }
    } catch (error) {
      catchHandler(error, 'firstApproveModifiedApp');
    }
  }

  // Утвердить заявление (2 этап)
  async function secondApproveApp() {
    try {
      let message = 'Утвердить?';
      if (isChanged) message = 'Внесенные изменения не сохранятся, утвердить без корректировок?';

      // Запросить подтверждение у руководителя
      const confirm = window.confirm(message);
      if (confirm) { // Если готов отправить
        const comment = window.prompt('Оставьте комментарий (необязательно)');
        const reqData = {
          type: 'secondApproveApplication',
          application_id: id,
          url: operInfo?.url,
          comment,
        };

        setIsLoading(true);
        await clientSendData('POST', '/second_approve_application', reqData);
        setIsLoading(false);
        setSearchParams(''); // сбросить параметры поиска для возвращения в интерфейс руководителя
      }
    } catch (error) {
      catchHandler(error, 'secondApproveApp');
    }
  }

  // Сохранить и утвердить заявление (2 этап)
  async function secondApproveModifiedApp() {
    const oldValues = Object.values(defaultAppraisal);
    const entries1 = Object.values(oldValues[0]);

    const newValues = Object.values(appraisal);
    const entries2 = Object.values(newValues[0]);

    try {
      // Запросить подтверждение у руководителя
      const confirm = window.confirm('Утвердить c учётом внесённых изменений?');
      if (confirm) { // Если готов отправить
        const comment = window.prompt('Оставьте комментарий (необязательно)');
        const reqData = {
          type: 'secondApproveApplication',
          application_id: id,
          url: operInfo?.url,
          comment,
          modified: true,
        };
        setIsLoading(true);
        if (isChanged && checkAdminMarksAndComments(entries1, entries2)) {
          await saveApplication(); // Сохранить изменения
          await clientSendData('POST', '/second_approve_application', reqData);
          setIsLoading(false);
          setSearchParams(''); // сбросить параметры поиска для возвращения в интерфейс руководителя
        } else { alert('Пожалуйста, заполните комменатрии к измененным оценкам'); }
        setIsLoading(false);
      }
    } catch (error) {
      catchHandler(error, 'secondApproveModifiedApp');
    }
  }

  // Вернуть на доработку
  async function familiarizeResult() {
    try {
      // Запросить подтверждение у сотрудника
      const confirm = window.confirm('Ознакомиться?');
      if (confirm) { // Если ознакомился
        const reqData = {
          type: 'familiarizeResult',
          application_id: id,
          alias: operInfo?.alias,
        };

        setIsLoading(true);
        await clientSendData('POST', '/familiarize_result', reqData);
        setIsLoading(false);
        setSearchParams(''); // сбросить параметры поиска для возвращения в интерфейс сотрудника
      }
    } catch (error) {
      catchHandler(error, 'familiarizeResult');
    }
  }

  // Вернуть на доработку
  async function returnForRevision() {
    try {
      let message = 'Вернуть на доработку?';
      if (isChanged) message = 'Внесенные Вами изменения сохранятся. Вернуть на доработку?';
      // Запросить подтверждение у руководителя
      const confirm = window.confirm(message);
      if (confirm) { // Если подтвердил
        const reqData = {
          type: 'returnForRevision',
          application_id: id,
          url: operInfo?.url,
          modified: isChanged,

        };
        setIsLoading(true);
        await saveApplication(); // Сохранить изменения
        await clientSendData('POST', '/return_for_revision', reqData);
        setIsLoading(false);
        setSearchParams(''); // сбросить параметры поиска для возвращения в интерфейс сотрудника
      }
    } catch (error) {
      catchHandler(error, 'returnForRevision');
    }
  }

  // Отменить изменения
  function callChangesOff() {
    try {
      // Запросить подтверждения
      const confirm = window.confirm('Отменить внесенные изменения?');
      // Если подтвердил - сбросить объект оценки
      if (confirm) setAppraisal(defaultAppraisal);
    } catch (error) {
      catchHandler(error, 'callChangesOff');
    }
  }

  // Если загрузка - показать лоадер
  if (isLoading) return <Loader />;
  // Если нет данных об участи аудитора в указанном проекте
  if (!applicationData?.general) return <EvaluationCap title="Сведения об участии сотрудника не найдены" />;

  return (
      <Service id="evaluation__application" vertical>

        {/* Панель */}
        <ServicePanel>
            {/* Инструкция */}
            <Instruction />

            {isUser && ( // Кнопки для сотрудника
            <>
                {/* {isChanged && <Button onClick={saveAsDraft}>Сохранить как черновик</Button>} */}
                {defineIsEditable() && <Button onClick={sendForApproval}>Отправить</Button>}
                {familirizationStatuses.includes(status) && (
                <Button onClick={familiarizeResult}>Ознакомлен</Button>)}
            </>)}

            {/* {isApprover && defineIsEditable() && ( // Кнопки для первого согласования
            <>
                {isChanged && (
                <Button onClick={firstApproveModifiedApp}>Сохранить и согласовать</Button>)}
                <Button onClick={firstApproveApp}>Согласовать</Button>
                <Button onClick={returnForRevision}>Вернуть на доработку</Button>
            </>)} */}

            {(isMainApprover || isAdmin) && defineIsEditable() && ( // Кнопки для второго согласования
            <>
                {isChanged && (
                <Button onClick={secondApproveModifiedApp}>Сохранить и утвердить</Button>)}
                <Button onClick={secondApproveApp}>Утвердить</Button>
                <Button onClick={returnForRevision}>Вернуть на доработку</Button>
            </>)}
          {isMonitoringUser && isChanged && <Button onClick={saveAsDraft}>Сохранить</Button>}
          {/* Общая кнопка */}
          {isChanged && <Button onClick={callChangesOff}>Отменить изменения</Button>}
        </ServicePanel>

        {/* Блок основного контента */}
        <ServiceBody>
          {applicationData && (
          <TableEvaluation
            applicationData={applicationData}
            setAppraisal={setAppraisal}
            appraisal={appraisal}
            loading={isLoading}
            extTasks={extTasks}
            setExtTasks={setExtTasks}
            isEditable={defineIsEditable()}
            getApplicationData={getApplicationData}
            projectMasters={projectMasters}
            getNewApplication={getNewApplication}
            isTableVisible={isTableVisible}
            status={status}
            isAdmin={isAdmin}
          />
          )}
        </ServiceBody>
      </Service>
  );
}

export default Application;
