import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { clientSendData } from '../../../../../utils/functions/requests';
import { catchHandler } from '../../../../../utils/error_handling/error_handling';
import { alertFunction } from '../../../../../utils/functions/alertFunction';
import CardBody from '../../../../../components/Card/CardBody';
import CardSetting from '../../../../../components/Card/CardSetting';
import Select from '../../../../../components/UI/Select';
import CardFooter from '../../../../../components/Card/CardFooter';
import Button from '../../../../../components/UI/Button/Button';
import Loader from '../../../../../components/UI/Loader';
import InputSearch from '../../../../../components/UI/InputSearch';
import AssetProperties from './AssetProperties';
import TextArea from '../../../../../components/UI/TextArea';

/**
  * @component ХелпДеск - активы - карточка актива - вкладка "Главная"
  * @prop {assetID} number|string - обновление состояния отображения карточки (открыть/закрыть)
  * @prop {refreshTable} function - обновление данных таблицы
  * @prop {closeHandler} function - закрытие карточки

*/
function Main(props) {
  const { assetID, refreshTable, closeHandler } = props;

  const sdParametres = useSelector((state) => state.helpdesk.parameters); // настройки хелпдеска из sd_parameters
  const [searchParams, setSearchParams] = useSearchParams(); // параметры поиска в url
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [isSearching, setIsSearching] = useState(false); // состояние загрузки при поиске
  const [asset, setAsset] = useState({}); // объект с данными актива
  // const [assetDefault, setAssetDefault] = useState({}); // объект с данными актива до внесения изменений
  const [additionalInfo, setAdditionalInfo] = useState({ // дополнительная информация
    companies: [], // компании
    types: [], // типы активов
    statuses: [], // статусы
    userGroup: null, // id группы пользователя
    users: [], // найденные в поиске пользователи
  });
  // Возможность редактировать данные
  // const isEditable = sdParametres?.asset_editing_groups.includes(additionalInfo.userGroup) || additionalInfo.permissionEdit;
  const isEditable = (additionalInfo.userGroup ? additionalInfo.userGroup.some((el) => sdParametres?.asset_editing_groups.includes(el)) : false) || additionalInfo.permissionEdit;
  // const isEditableAdmin = sdParametres?.asset_editing_groups.includes(additionalInfo.userGroup);
  const isEditableAdmin = additionalInfo.userGroup ? additionalInfo.userGroup.some((el) => sdParametres?.asset_editing_groups.includes(el)) : false;
  useEffect(() => {
    if (assetID) getAssetData(); // запросить информацию
  }, [assetID]); // при каждом изменении id актива

  // Получение данных для отображения актива
  async function getAssetData() {
    try {
      const reqData = { type: 'getAssetData' };
      setIsLoading(true);
      const result = await clientSendData('POST', `/get_asset_data/${assetID}`, reqData);
      if (result?.success) { // если запрос выполнен успешно
        setAsset(result.data); // записываем данные в состояние актива
        // setAssetDefault(result.data); // записываем данные в состояние актива по умолчанию
        setAdditionalInfo((prev) => ({ ...prev, ...result.info })); // Записываем доп информацию (компании/типы/статусы)
      } else closeHandler(); // закрыть карточку
    } catch (error) {
      catchHandler(error, 'getAssetData');
    } finally {
      setIsLoading(false);
    }
  }

  // Сохранение изменений актива
  async function saveAsset() {
    try {
      if (validateAsset()) {
        const {
          object, type_id, status_id, company_id, user_id = null, comment,
        } = asset;
        const reqData = {
          type: 'saveAsset',
          asset: {
            object, type_id, status_id, company_id, user_id, comment,
          },
        };
        setIsLoading(true);
        const result = await clientSendData('POST', `/save_asset/${assetID}`, reqData);
        if (result?.success) { // если запрос выполнен успешно
          await refreshTable();
          await alertFunction('save_settings', 'clientPost');
          setSearchParams({ asset: result.id });
        }
      } else await alertFunction('required_fields', 'clientPost');
    } catch (error) {
      catchHandler(error, 'saveAsset');
    } finally {
      setIsLoading(false);
    }
  }

  // Валидация полей актива
  const validateAsset = () => {
    const scheme = asset?.scheme || {};
    // Проверка - проходим циклом по ключам схемы

    const checkingObject = Object.keys(scheme).map((key) => {
      // Если объект имеет схему для дочерних элементов
      if (scheme[key]?.child_scheme) {
        const childScheme = scheme[key].child_scheme; // схема дочерних элементов
        const array = asset.object?.[key] || []; // массив объектов, хранящийся в этом ключе
        const requiredKeys = Object.keys(childScheme).filter((subkey) => childScheme[subkey]?.required);// обязательные ключи объектов
        const requiredKeysValid = requiredKeys.length > 0 // если есть обязательные поля
        // проверяем что каждый элемент массива содержит значение в обязательном ключе
          ? requiredKeys.every((subkey) => array.every((item) => item[subkey]))
          : true; // иначе обязательных ключей нет - валидация пройдена

        /**  Если поле обязательное - проверяем объекты массива
         * Валидация будет пройдена если:
         * - хотя бы один элемент массива содержит значения по каждому ключу дочерней схемы
         * - а также все элементы массива содержат значения по обязательным ключам дочерней схемы (если такие есть)
        */
        if (scheme[key]?.required) {
          // Если массив пустой - валидация не пройдена
          if (array.length === 0) return false;
          // Найти в массиве хотя бы один объект, в котором все ключи дочерней схемы содержат значения
          const atLeastOneValid = array.some((item) => Object.keys(childScheme).every((subkey) => item[subkey]));
          // Хотя бы в одном объекте заполнены все ключи, и в каждом объекте заполнены обязательные ключи
          return atLeastOneValid && requiredKeysValid;
        }

        // в каждом объекте заполнены обязательные ключи
        return requiredKeysValid;
      }

      // Иначе объект не имеет вложенной схемы
      // проверяем обязательное ли поле - возвращаем булевое значение ключа из объекта актива
      if (scheme[key]?.required) return Boolean(asset.object[key]);

      // В ином случае ключ не обязательный к заполнению - валидация пройдена
      return true;
    });

    const {
      company_id = null, type_id = null, status_id = null, user_id = null,
    } = asset;
    const result = company_id && type_id && status_id && user_id && checkingObject.every((item) => item);
    return result;
  };

  // Удаление актива
  async function deleteAsset() {
    try {
      const confirm = window.confirm('Удалить актив?');
      if (confirm) {
        const reqData = { type: 'saveAsset' };
        setIsLoading(true);
        const result = await clientSendData('POST', `/save_asset/${assetID}?remove=1`, reqData);
        if (result?.success) { // если запрос выполнен успешно
          await refreshTable();
          closeHandler();
          setSearchParams('');
        }
      }
    } catch (error) {
      catchHandler(error, 'deleteAsset');
    } finally {
      setIsLoading(false);
    }
  }

  // Поиск сотрудника в выбранной компании
  async function searchUserByCompany(event) {
    try {
      const reqData = {
        type: 'searchUserByCompany',
        dn: asset.company_dn,
        name: event.target.value,
      };
      setAsset((prev) => ({ ...prev, user: null, user_id: null }));
      setIsSearching(true);
      const result = await clientSendData('POST', '/search_user_by_company', reqData);
      if (result?.success) { // если запрос выполнен успешно
        setAdditionalInfo((prev) => ({ ...prev, users: result.users }));
      }
    } catch (error) {
      catchHandler(error, 'searchUserByCompany');
    } finally {
      setIsSearching(false);
    }
  }

  // Получение схемы актива
  async function getAssetScheme(type_id) {
    try {
      const reqData = { type: 'getAssetScheme' };
      const result = await clientSendData('POST', `/get_asset_scheme/${type_id}`, reqData);
      if (result?.success) return result.scheme;
      return null;
    } catch (error) {
      catchHandler(error, 'getAssetScheme');
      return null;
    }
  }

  // Выбор компании
  function chooseCompany(company) {
    // Обновляем название и id компании
    const changeAsset = {
      company: company.title,
      company_id: company.id,
      company_dn: company.dn,
    };
    if (!asset.user || asset?.user.toLowerCase().includes('склад ')) {
      changeAsset.user = null;
      changeAsset.user_id = null;
    }
    setAsset((prev) => ({
      ...prev,
      ...changeAsset,
    }));
  }

  // Выбор типа актива
  async function chooseType(type) {
    setIsSearching(true);
    // Запрашиваем схему для выбранного типа
    const scheme = await getAssetScheme(type.id);
    setIsSearching(false);
    setAsset((prev) => ({
      ...prev,
      scheme, // Обновляем схему,
      type: type.title, // название типа
      type_id: type.id, // id типа
      object: JSON.stringify(asset.scheme) === JSON.stringify(scheme) ? prev.object : {},
    }));
  }

  // Выбор пользователя
  function chooseUser(user) {
  // Обновляем имя и id пользователя
    setAsset((prev) => ({ ...prev, user: user.title, user_id: user.id }));
  }

  // Выбор статуса
  function chooseStatus(status) {
    // Обновляем название и id статуса
    setAsset((prev) => ({ ...prev, status: status.title, status_id: status.id }));
  }

  // Обработка изменений данных поля
  // scheme_key - название поля(ключа) в котором нужно заменить значение
  // parent_scheme_key -  название родительского поля(ключа), если редактируем объект массива
  // child_object_id - id объекта массива
  // value - значение которое нужно записать
  function changeSchemeField(options) {
    const {
      scheme_key, value, parent_scheme_key, child_object_id,
    } = options;

    if (parent_scheme_key) {
      // Если есть значение родительского ключа - значит обновляется элемент из массива
      setAsset((prev) => ({
        ...prev, // оставить без изменений все ключи
        object: { // object заменить
          ...prev.object, // все имеющиеся ключи кроме parent_scheme_key
          [parent_scheme_key]: prev.object[parent_scheme_key].map((item) => {
            // ищем в массиве объект по переданному id и изменяем в нем необходимое поле
            if (item.id === child_object_id) {
              return { ...item, [scheme_key]: value };
            }
            return item;
          }),
        },
      }));
    } else {
      // Если родительского значения ключа нет - значит обновляется не массив - просто меняем значение
      setAsset((prev) => ({
        ...prev,
        object: {
          ...prev.object,
          [scheme_key]: value,
        },
      }));
    }
  }

  if (isLoading) return <CardBody><Loader /></CardBody>;
  return (
    <>
      <CardBody>

        {/* КОМПАНИЯ */}
        <CardSetting title="Компания" invalid={!asset.company_id}>
          <Select
            id="asset-card__company-select"
            array={additionalInfo.companies}
            onChoose={chooseCompany}
            defaultValue={asset.company || 'Выберите компанию'}
            loading={isSearching}
            disabled={!isEditable}
          />
        </CardSetting>

        {/* ТИП */}
        <CardSetting title="Тип актива" invalid={!asset.type_id}>
          <Select
            id="asset-card__asset-type-select"
            array={additionalInfo.types}
            onChoose={chooseType}
            defaultValue={asset.type || 'Выберите тип'}
            loading={isSearching}
            disabled={!isEditable}
          />
        </CardSetting>

        {/* СТАТУС */}
        <CardSetting title="Статус актива" invalid={!asset.status_id}>
          <Select
            id="asset-card__asset-status-select"
            array={additionalInfo.statuses}
            onChoose={chooseStatus}
            defaultValue={asset.status || 'Выберите статус'}
            loading={isSearching}
            disabled={!isEditable}
          />
        </CardSetting>

        {/* ПОИСК СОТРУДНИКА */}
        <CardSetting title="Сотрудник" invalid={!asset.user_id}>
          <InputSearch
            id="asset-card__user-search"
            array={additionalInfo.users}
            onChoose={chooseUser}
            onSearch={searchUserByCompany}
            defaultValue={asset?.user}
            placeholder="Выберите пользователя"
            loading={isSearching}
            disabled={!isEditable}
          />
        </CardSetting>

        {/* КОММЕНТАРИЙ */}
        <CardSetting title="Комментарий">
          <TextArea
            id="asset-card__comment"
            defaultValue={asset.comment}
            placeholder="Введите комментарий"
            disabled={!isEditable}
            onChange={(e) => setAsset({ ...asset, comment: e.target.value })}
          />
        </CardSetting>

        {/* СВОЙСТВА АКТИВА ПО СХЕМЕ */}
        <AssetProperties
          object={asset?.object}
          setAsset={setAsset}
          scheme={asset?.scheme}
          onChange={changeSchemeField}
          disabled={!isEditable}
        />

        {/* УДАЛЕНИЕ */}
        {/* {isEditable && assetID !== 'new' && (
        <CardSetting title="Удалить">
          <IconButton icon="delete" theme="red" onClick={deleteAsset} />
        </CardSetting>)} */}
      </CardBody>

      {/* СОХРАНЕНИЕ ИЗМЕНЕНИЙ */}
      {isEditable && (
      <CardFooter>
        <Button onClick={saveAsset}>Сохранить</Button>
        {isEditableAdmin && <Button onClick={deleteAsset}>Удалить</Button>}
      </CardFooter>)}
    </>
  );
}

export default Main;
