import React, { useEffect, useState } from 'react';
import { authorization } from '../../utils/functions/authenticate';
import { setOperInfo } from '../../utils/functions/others';
import ProbationOutputInterview from './ProbationOutputInterview/ProbationOutputInterview';
import ProbationControl from './ProbationControl/ProbationControl';
import Service from '../../components/Service/Service';
import Cap from '../Cap/Cap';
import './probation.scss';
import Loader from '../../components/UI/Loader';

/**
* @component Система контроля Ресурсного планирования - главная страница.
*
* Включает в себя:
* - Система контроля испытательных сроков
* - Система контроля Выходного интервью
*/
function Probation() {
  const [isAuthorized, setIsAuthorized] = useState(false); // Состояние авторизации
  const [isLoading, setIsLoading] = useState(false); // Cостояние загрузки

  const pages = [
    { id: 1, title: 'Система контроля ИС', component: <ProbationControl /> },
    { id: 2, title: 'Выходное интервью', component: <ProbationOutputInterview /> },
  ];

  useEffect(() => {
    setOperInfo();
    awaitRequests();
  }, []);

  async function awaitRequests() {
    setIsLoading(true);
    const checkAuthorization = await authorization(); // авторизация
    setIsAuthorized(checkAuthorization);
    setIsLoading(false);
  }

  if (isLoading) return <Loader />;
  // если авторизован - показать сервис
  if (isAuthorized) return <Service id="probation" pages={pages} horizontal />;
  // иначе показать заглушку
  return <Cap />;
}
export default Probation;
