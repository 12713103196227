import React, { useEffect, useState } from 'react';
import { alertFunction, alertFunctionLocal } from '../../utils/functions/alertFunction';
import { clientSendData, clientSendFile } from '../../utils/functions/requests';
import { downloadDataUrl, setOperInfo } from '../../utils/functions/others';
import { authorization } from '../../utils/functions/authenticate';
import { catchHandler } from '../../utils/error_handling/error_handling';
import SingleFileUpload from '../../components/UI/FileUpload/SingleFileUpload';
import ServiceBody from '../../components/Service/ServiceBody';
import Service from '../../components/Service/Service';
import Table from '../../components/Table/Table';
import THead from '../../components/Table/THead';
import TBody from '../../components/Table/TBody';
import TRow from '../../components/Table/TRow';
import TData from '../../components/Table/TData';
import Cap from '../Cap/Cap';
import InstructionCard from './InstructionCard';
import './instructions.scss';

/**
* @component Инструкции
*/
function Instructions() {
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [isAuthorized, setIsAuthorized] = useState(false); // Состояние авторизации
  const [instructions, setInstructions] = useState([]); // Все сервисы
  const [showCard, setShowCard] = useState(false); // Отображение карточки
  const [currentInstruction, setCurrentInstruction] = useState(null); // ID отображаемой карточки

  const headers = [
    { id: 1, title: 'Сервис', field: 'title' },
    { id: 2, title: 'Изменил', field: 'modified_by' },
    { id: 3, title: 'Изменена', field: 'modified_at' },
    { id: 4, title: 'Создал', field: 'created_by' },
    { id: 5, title: 'Создана', field: 'created_at' },
    { id: 6, title: 'Инструкция', field: 'link' },
  ];

  useEffect(() => {
    firstRender();
  }, []);

  // Функция первого рендера компонента
  async function firstRender() {
    try {
      setIsLoading(true); // загрузка началась
      setOperInfo(); // запись в оперативную информацию
      const checkAuthorization = await authorization(); // авторизация
      setIsAuthorized(checkAuthorization); // обновление состояния авторизации
      if (checkAuthorization) await getInstructions(); // если авторизован - запрос инструкции
      setIsLoading(false); // загрузка завершена
    } catch (error) {
      catchHandler(error, 'firstRender');
    }
  }

  // Получение информации о сервисах
  async function getInstructions() {
    try {
      const reqData = { type: 'getInstructions' };
      const result = await clientSendData('POST', '/get_instructions', reqData);
      if (result) setInstructions(result);
    } catch (error) {
      catchHandler(error, 'getInstructions');
    }
  }

  // Загрузить инструкцию
  async function uploadInstruction(file, id, instruction_id) {
    try {
      setIsLoading(true);
      const reader = new FileReader(); // API чтения файлов
      // При успешном завершении операции чтения вызывается функция отправки данных в БД
      reader.onloadend = uploadFile;
      reader.readAsArrayBuffer(file); // Чтение данных

      async function uploadFile(e) { // функция отправки данных
        // создание бинарного массива из прочтенного файла
        const binaryArray = new Uint8Array(e.target.result);
        const blob = new Blob([binaryArray]); // создание Blob из бинарного массива

        const formData = new FormData(); // FormData для отправки blob
        // добавление данных к запросу
        formData.append('type', 'uploadInstruction');
        formData.append('content', blob);
        formData.append('resource_id', id);
        formData.append('instruction_id', instruction_id);

        const result = await clientSendFile('POST', '/upload_instruction', formData);
        if (result?.error) alertFunctionLocal(`Ошибка при загрузке!${JSON.stringify(result.error)}`);
        if (result === 'success') {
          await getInstructions();
          await alertFunction('save_settings', 'clientPost');
        }
        setIsLoading(false);
      }
    } catch (error) {
      catchHandler(error, 'uploadInstruction');
    }
  }

  // Скачать инструкцию
  async function downloadInstruction(instruction_id) {
    try {
      const reqData = {
        type: 'downloadInstruction',
        instruction_id,
        source: 'admin',
      };
      setIsLoading(true); // загрузка началась
      const result = await clientSendData('POST', '/download_instruction', reqData);
      if (result?.result === 'success') {
        const reader = new FileReader(); // API чтения файлов
        const resBlob = new Blob([new Uint8Array(result.instruction.data)]);
        reader.onloadend = ((e) => downloadDataUrl(e.target.result, `Инструкция ${result.file_name}`));
        reader.readAsDataURL(resBlob);
      }
      setIsLoading(false); // загрузка завершена
    } catch (error) {
      catchHandler(error, 'uploadInstruction');
    }
  }

  function openCard(id) {
    if (id) {
      setCurrentInstruction(id);
      setShowCard(true);
    }
  }

  if (isAuthorized) {
    return (
      <Service id="instructions">
        <ServiceBody>
          <Table id="instructions__table">
            <THead title="Инструкции">
              <TRow>
                {headers.map((item) => <TData key={item.id}>{item.title}</TData>)}
                <TData />
                <TData />
              </TRow>
            </THead>
            <TBody>
              {instructions.map((row) => {
                const { id, instruction_id } = row;
                return (
                  <TRow key={id}>
                    {headers.map((item) => {
                      const { field } = item;
                      const value = row[field];
                      if (field !== 'link') return <TData key={item.id} loading={isLoading}>{value}</TData>;
                      return (
                        <TData key={item.id} loading={isLoading}>
                          <span className="clickable" onClick={() => downloadInstruction(instruction_id)}>
                            {value}
                          </span>
                        </TData>
                      );
                    })}
                    <TData loading={isLoading}>
                      <SingleFileUpload
                        options={{
                          handler: (file) => uploadInstruction(file, id, instruction_id),
                          accept: '.pdf',
                          size: 5000000,
                        }}
                      >
                        <img
                          className="instructions__upload-img"
                          src="../../../icons/upload.png"
                          alt="upload"
                          title="Загрузить"
                        />
                      </SingleFileUpload>
                    </TData>
                    <TData loading={isLoading} onClick={() => openCard(instruction_id)}>
                      <img src="../../../icons/file.png" className="instructions__upload-img" alt="file" title="Настройки" />
                    </TData>
                  </TRow>
                );
              })}
            </TBody>
          </Table>
          {showCard && (
          <InstructionCard
            id={currentInstruction}
            setShow={setShowCard}
            getInstructions={getInstructions}
          />
          )}
        </ServiceBody>
      </Service>
    );
  } return <Cap />;
}

export default Instructions;
