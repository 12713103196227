import React from 'react';

/**
 * @component - Тело сервиса
 * @prop {id} string - уникальный идентификатор DOM элемента
 * @prop {horizontal} boolean - изменяет display:flex блока на display:grid "панель, контент"
*/

function ServiceBody(props) {
  const { id, horizontal, children } = props;

  const defineClass = () => {
    let result = 'service__body';
    if (horizontal) result += ' service__body_horizontal';
    return result;
  };

  if (id) return <div id={id} className={defineClass()}>{children}</div>;
  return <div className={defineClass()}>{children}</div>;
}

export default ServiceBody;
