import { catchHandler } from '../error_handling/error_handling';

/**
 * @function Функция добавления данных в куки
 * @prop {name} string - имя параметра
 * @prop {value} string - значение параметра
 * @prop {opt} object - дополнительные опции в виде объекта
*/
export function setCookie(name, value, opt = {}) {
  try {
    const options = { path: '/', ...opt };
    let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;
    Object.keys(options).forEach((key) => {
      updatedCookie += `; ${key}`;
      const optionValue = options[key];
      if (optionValue !== true) updatedCookie += `=${optionValue}`;
    });
    document.cookie = updatedCookie;
  } catch (error) {
    catchHandler(error, 'setCookie');
  }
}

/**
 * @function Функция получения данных из куки
 * @prop {name} string - имя запрашиваемого параметра
*/
export function getCookie(name) {
  try {
    // matches - результат совпадения при поиске в куки запрашиваемого параметра
    // поиск осуществляется при помощи регулярного выражения
    // eslint-disable-next-line no-useless-escape
    const clearName = name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1');
    const matches = document.cookie.match(new RegExp(`(?:^|; )${clearName}=([^;]*)`));
    if (matches) { return decodeURIComponent(matches[1]); }
    return null;
  } catch (error) {
    catchHandler(error, 'getCookie');
    return error;
  }
}

/**
 * @function Функция удаления куки
*/
export function deleteCookie() {
  try {
    const cookieArray = document.cookie.split(';');
    cookieArray.forEach((cookie) => {
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`;
    });
  } catch (error) {
    catchHandler(error, 'deleteCookie');
  }
}
