// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#priorities {
  width: 1000px;
}

#admin-helpdesk .input {
  max-width: 400px;
  width: 100%;
}
#admin-helpdesk .custom-select {
  max-width: 300px;
  width: 300px;
}
#admin-helpdesk .editor-button {
  height: -moz-fit-content;
  height: fit-content;
}`, "",{"version":3,"sources":["webpack://./src/pages/AdminHelpdesk/admin_hlpdsk.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;;AAGG;EAEK,gBAAA;EACA,WAAA;AADR;AAKG;EACG,gBAAA;EACA,YAAA;AAHN;AAMI;EACE,wBAAA;EAAA,mBAAA;AAJN","sourcesContent":["#priorities {\n    width: 1000px;\n  }\n\n  #admin-helpdesk {\n   & .input {\n    \n        max-width: 400px;\n        width: 100%;\n        // padding-left: 12px;\n    }\n\n   & .custom-select {\n      max-width: 300px;\n      width: 300px;\n    }\n\n    & .editor-button {\n      height: fit-content;\n    }\n  }\n  \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
