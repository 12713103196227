import { catchHandler } from '../../utils/error_handling/error_handling';
import { clientSendData } from '../../utils/functions/requests';

export async function getKbHistory(id, type) {
  try {
    const reqData = {
      type: 'getKbHistory',
      type_id: type,
      object_id: id,
    };
    const result = await clientSendData('POST', '/get_kb_history', reqData); // запрос в БД
    if (result) return result;
    return [];
  } catch (error) {
    catchHandler(error, 'getKbHistory');
    return [];
  }
}
