import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Table from '../../../components/Table/Table';
import THead from '../../../components/Table/THead';
import TRow from '../../../components/Table/TRow';
import TData from '../../../components/Table/TData';
import TBody from '../../../components/Table/TBody';
import { clientSendData } from '../../../utils/functions/requests';
import { catchHandler } from '../../../utils/error_handling/error_handling';
import Loader from '../../../components/UI/Loader';

function DefaultTable(props) {
  const { period, isAdmin } = props;
  const [searchParams, setSearchParams] = useSearchParams(); // параметры поиска url
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [applications, setApplications] = useState([]); // найденные заявки
  // const isSearchAllowed = !(!period?.from || !period?.to || (new Date(period?.from) > new Date(period?.to)));

  const headers = [ // заголовик
    { id: 1, title: 'Клиент', field: 'client_title' },
    { id: 2, title: 'Проект', field: 'project_title' },
    { id: 3, title: 'Сотрудник', field: 'employee_title' },
    { id: 4, title: 'Руководитель', field: 'approver_title' },
    { id: 5, title: 'Партнер', field: 'partner_title' },
    { id: 6, title: 'Директор', field: 'director_title' },
    { id: 7, title: 'Менеджер', field: 'manager_title' },
    { id: 8, title: 'Кол-во часов', field: 'hours_amount' },
    { id: 9, title: 'Статус', field: 'status_title' },
    { id: 10, title: 'Действие', field: 'action' },
  ];

  useEffect(() => {
    findApplications();
  }, [period]);

  // Поиск заявлений на выбранные даты
  async function findApplications() {
    try {
      const reqData = {
        type: 'findProjectEvs',
        is_admin: true,
        period,
      };
      setIsLoading(true);
      const result = await clientSendData('POST', '/find_project_evs', reqData); // запрос в БД
      if (result) {
        setApplications(result);
        setIsLoading(false);
      }
    } catch (error) {
      catchHandler(error, 'findProjectEvs'); // обработка ошибок
    }
  }

  console.log(applications);
  // Открыть заявление
  function openApplication(id, isAdmin) {
    setSearchParams({ app: id, isAdmin });
  }

  if (isLoading) return <Loader />;
  return (
    <Table id="evaluation__approver-table" loading={isLoading}>
      <THead
        headers={headers}
        array={applications}
        setArray={setApplications}
        button_place="row"
      />
      <TBody>
        {/* Если нет результатов */}
        {applications.length === 0 ? (
        <TRow>
          <TData>Результаты отсутствуют</TData>
          <TData />
          <TData />
          <TData />
          <TData />
          <TData />
          <TData />
          <TData />
          <TData />
          <TData />
          <TData />
        </TRow>) : (
        // Если результаты есть
          applications.map((row) => (
          <TRow key={row.id}>
            {headers.map((item) => {
              if (item.field !== 'action') return <TData key={item.id}>{row[item.field] ? row[item.field] : '-'}</TData>;
              return <TData key={item.id} onClick={() => openApplication(row.id, isAdmin)}>Посмотреть</TData>;
            })}
            <TData />
          </TRow>
          )))}
      </TBody>
    </Table>
  );
}

export default DefaultTable;
