import React, { useEffect, useState } from 'react';
import { catchHandler } from '../../../../utils/error_handling/error_handling';
import { clientSendData } from '../../../../utils/functions/requests';
import CardBody from '../../../../components/Card/CardBody';
import CardSetting from '../../../../components/Card/CardSetting';
import Loader from '../../../../components/UI/Loader';

/**
  * @component ХелпДеск - активы - по сотрудникам - карточка пользователя - вкладка "Главная"
  * @prop {userID} object - id пользователя
  * @prop {closeHandler} function - закрытие карточки

*/
function Main(props) {
  const { userID, closeHandler } = props;
  const [userInfo, setUserInfo] = useState({});
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки

  useEffect(() => {
    if (userID) getUserData('main'); // запросить информацию по заявке
  }, [userID]);
  async function getUserData(data_type) {
    try {
      const reqData = { type: 'getAssetUserData' };
      setIsLoading(true);
      const result = await clientSendData('POST', `/get_asset_user_data/${userID}?type=${data_type}`, reqData);
      if (result?.success) { // если запрос выполнен успешно
        const { data } = result;
        setUserInfo((state) => ({ state, ...data }));
      } else closeHandler();
    } catch (error) {
      catchHandler(error, 'getAssetUserData');
    } finally {
      setIsLoading(false);
    }
  }
  if (isLoading) return <Loader />;
  return (
    <CardBody>
      <CardSetting title="ФИО">{userInfo.cn}</CardSetting>
      <CardSetting title="Логин">{userInfo.samaccountname}</CardSetting>
      <CardSetting title="Почта">{userInfo.mail}</CardSetting>
      <CardSetting title="Должность">{userInfo.title}</CardSetting>
      <CardSetting title="Компания">{userInfo.company2}</CardSetting>
      <CardSetting title="Вн. телефон">{userInfo.telephonenumber}</CardSetting>
      <CardSetting title="Мобильный телефон">{userInfo.mobile}</CardSetting>
    </CardBody>
  );
}

export default Main;
