import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { catchHandler } from '../../../utils/error_handling/error_handling';
import { clientSendData } from '../../../utils/functions/requests';
import ServiceBody from '../../../components/Service/ServiceBody';
import Table from '../../../components/Table/Table';
import THead from '../../../components/Table/THead';
import TBody from '../../../components/Table/TBody';
import TRow from '../../../components/Table/TRow';
import TData from '../../../components/Table/TData';
import Loader from '../../../components/UI/Loader';

/**
 * @component Пользователи и права - Таблица пользователей
*/
function Users() {
  const limit = 200; // лимит отображения строк в таблице
  const [users, setUsers] = useState([]); // отображаемые пользователи
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const headers = [ // Заголовки полей с наименованиями
    { title: 'ФИО', field: 'displayname' },
    { title: 'Логин', field: 'samaccountname' },
    { title: 'Дата истечения последней сессии', field: 'last_session' },
  ];

  useEffect(() => {
    getUsersShortData();
  }, []);

  // Получение краткой информации о пользователях
  async function getUsersShortData() {
    try {
      const reqData = {
        type: 'getUsersShortData',
      };
      setIsLoading(true);
      const result = await clientSendData('POST', '/get_users_short_data', reqData);
      if (result) setUsers(result); // Результат записывается в состояние
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      catchHandler(error, 'getUsersShortData');
    }
  }

  return (
    <ServiceBody>
      {isLoading ? <Loader /> : (
        <Table id="admin-panel__users-table">
          <THead
            title={`Пользователи (${users.length})`}
            headers={headers}
            array={users}
            setArray={setUsers}
          />
          <TBody>
            {users.slice(0, limit).map((user) => {
              const { id, samaccountname, last_session } = user;
              return (
                <TRow key={id}>
                  <TData align="left">
                    <Link
                      to={`/user/${samaccountname}`}
                      state={{ source: window.location.pathname }}
                      className="clickable"
                    >
                      {user.displayname}
                    </Link>
                  </TData>
                  <TData align="left">{samaccountname}</TData>
                  <TData align="right">{last_session}</TData>
                </TRow>
              );
            })}
          </TBody>
        </Table>
      )}
    </ServiceBody>
  );
}

export default Users;
