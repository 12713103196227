import React, { useState } from 'react';
import CheckBox from '../../../../components/UI/CheckBox';
import { catchHandler } from '../../../../utils/error_handling/error_handling';
import { alertFunction } from '../../../../utils/functions/alertFunction';
import { clientSendData } from '../../../../utils/functions/requests';
import { log } from '../../../../utils/functions/others';
import TextArea from '../../../../components/UI/TextArea';
import IconButton from '../../../../components/UI/Button/IconButton';

// Сервис анкетирования - вкладка "Вопросы" - карточка вопроса - вкладка Ответы
function Answers(props) {
  const {
    getQuestion,
    currentQuestion,
    refreshNumbers,
    currentAnswers, // ответы к текущему вопросу
    setCurentAnswers,
  } = props; // свойства компонента передаваемые при вызове

  const [newAnswer, setNewAnswer] = useState({}); // новый ответ (объект)
  const [editingAnswer, setEditingAnswer] = useState({}); // редактируемый ответ (объект)
  const [loadingElement, setLoadingElement] = useState(null); // загружающийся элемент

  // Создание ответа
  function addAnswer() {
    if (newAnswer?.answer?.length > 0) {
      setCurentAnswers((state) => [...state, newAnswer]); // запись в состояние
      log({ message: `Пользователь в карточке вопроса id: ${currentQuestion.id} создал новый ответ номер ${newAnswer.number}`, eventtype: 'info' });
    }
    setNewAnswer({}); // Сбросить новый ответ
  }

  // Начать создавать ответ
  function startAnswerCreating() {
    setNewAnswer({ // создание объекта нового вопроса
      id: `answer_${+new Date()}`, // уникальный id
      question_id: currentQuestion.id || 'new', // id вопроса к которому относится ответ (если вопрос также создается, то id = new)
      number: currentAnswers.length + 1, // порядковый номер ответа (текущая длина +1)
      answer: '', // содержание редактируется на следующем шаге
      correct: false, // правильный ответ - нет
    });
    log({ message: `Пользователь в карточке вопроса id: ${currentQuestion.id} нажал кнопку добавления нового ответа`, eventtype: 'info' });
  }

  // Сохранить изменения ответа
  async function saveAnswerChanges() {
    if (editingAnswer?.answer?.length > 0) {
      // Поиск редактируемого ответа и обновление содержимого
      setCurentAnswers((state) => state.map((answer) => {
        if (answer.id === editingAnswer.id) return { ...answer, answer: editingAnswer.answer }; // обновляется answer
        return answer; // иначе остается без изменений
      }));
      log({ message: `Пользователь в карточке вопроса id: ${currentQuestion.id} сохранил ответ id: ${editingAnswer?.id || editingAnswer?.number}`, eventtype: 'info' });
    }
    setEditingAnswer({}); // Сбросить редактируемый ответ
  }

  // Удалить ответ
  async function deleteAnswer(id) {
    try {
      const confirm = window.confirm('Удалить ответ?'); // подтверждение удаления
      if (confirm) { // если пользователь подтвердил
        const filtered = currentAnswers.filter((a) => a.id !== id);
        if (typeof id === 'number') { // если id числовой, значит есть в БД (новые ответы со строковым id)
          const reqData = {
            type: 'deleteAnswer',
            answer_id: id,
            answers: refreshNumbers(filtered),
            question_id: currentQuestion.id,
          };
          setLoadingElement(id);
          const result = await clientSendData('POST', '/delete_answer', reqData);
          if (result === 'success') { // если успех
            alertFunction('save_settings', 'clientPost'); // уведомление "Настройки сохранены"
            await getQuestion(); // обновление вопроса (и ответов к нему)
            log({ message: `Пользователь в карточке вопроса id: ${currentQuestion.id} удалил ответ id: ${editingAnswer.id}`, eventtype: 'info' });
          }
          setLoadingElement(null);
        }

        // Ответ удаляется локально
        setCurentAnswers(refreshNumbers(filtered)); // обновление в cостоянии
      }
    } catch (error) {
      catchHandler(error, 'deleteAnswer');
    }
  }

  // Обработка выбора правильного ответа
  function chooseRightAnswer(e, a) {
    // поиск ответа по id и изменение значения параметра "correct"
    setCurentAnswers(currentAnswers.map((answer) => {
      if (answer.id === a.id && answer.number === a.number) {
        return { ...answer, correct: e.target.checked };
      } return answer;
    }));
    log({ message: `Пользователь в карточке вопроса id: ${currentQuestion.id} ${e.target.checked ? `указал ответ ${a.id ? `id: ${a.id}` : `номер ${a.number}`} правильным` : `снял отметку о правильном ответе ${a.id ? `id: ${a.id}` : `номер ${a.number}`}`}`, eventtype: 'info' });
  }

  return (
    <div className="questions-card__block">
      {/* Cписок ответов */}
      {currentAnswers.map((item, index) => {
        const { id, answer, correct } = item;
        const isEditing = editingAnswer?.id === id;
        if (loadingElement !== id) {
          return (
            <div className="upu-card__setting" key={id}>
              {isEditing && (
              <TextArea
                placeholder="Введите содержание"
                value={editingAnswer.answer}
                onChange={(e) => setEditingAnswer((state) => ({ ...state, answer: e.target.value }))}
                onBlur={saveAnswerChanges}
              />)}

              {!isEditing && (
              <p onClick={() => setEditingAnswer(item)} className="upu-card__setting-title clickable">
                {answer.trim() || 'Введите содержимое или удалите ответ!'}
              </p>)}

              {!isEditing && (
              <div className="questions-card__setting-wrapper">
                <CheckBox
                  id={`questions-card__li-answer_${index}`}
                  onChange={(e) => chooseRightAnswer(e, item)} // обработчик выбора чекбокса
                  defaultChecked={correct}
                >
                  Верно
                </CheckBox>
                <IconButton icon="delete" theme="red" onClick={() => deleteAnswer(id)} />
              </div>)}
            </div>
          );
        }
        return (
          <div className="upu-card__setting upu__loading" key={id}>
            <p className="upu-card__setting-title">{answer}</p>
          </div>
        );
      })}

      {/* Добавить вариант ответа */}
      <div className="upu-card__setting">
        {newAnswer?.id && (
        <TextArea
          placeholder="Введите содержание"
          value={newAnswer.answer}
          onChange={(e) => setNewAnswer((state) => ({ ...state, answer: e.target.value }))}
          onBlur={addAnswer}
          autoFocus
        />)}

        {!newAnswer?.id && (
        <p className="upu-card__setting-title clickable" onClick={startAnswerCreating}>Добавить ответ</p>)}
      </div>
    </div>
  );
}

export default Answers;
