import React, { useState, useEffect } from 'react';
import CardBody from '../../components/Card/CardBody';
import Table from '../../components/Table/Table';
import THead from '../../components/Table/THead';
import TRow from '../../components/Table/TRow';
import TData from '../../components/Table/TData';
import TBody from '../../components/Table/TBody';
import { catchHandler } from '../../utils/error_handling/error_handling';
import Input from '../../components/UI/Input';
import EditorButton from '../../components/UI/Button/EditorButton';
import IconButton from '../../components/UI/Button/IconButton';
import TFoot from '../../components/Table/TFoot';
import CheckMark from '../../components/UI/CheckMark';
import Cancel from '../../components/UI/Cancel';
import { clientSendData } from '../../utils/functions/requests';
import Wrapper from '../../components/Basic/Wrapper';
import ServiceBody from '../../components/Service/ServiceBody';

function Groups() {
  const [groups, setGroups] = useState([]);
  const [inputShown, setInputShown] = useState(false);
  const [inputText, setInputText] = useState('');
  const [createGroup, setCreateGroup] = useState(false);
  const [newGroupName, setNewGroupName] = useState('');
  const [editingRow, setEditingRow] = useState(null);

  const headers = [
    { title: 'Группа', key: 'title' },
    // { title: 'Редактирование', key: 'edit' },
    { title: 'Дата создания', key: 'date' },
  ];

  function makeGroup() {
    setCreateGroup(true);
    setEditingRow(null);
  }

  function cancelCreating(e) {
    e.preventDefault(); // предотвращение действий формы по умолчанию
    setNewGroupName(''); // сброс имени нового направления
    setCreateGroup(false); // изменение состояния создания группы
  }

  useEffect(() => {
    getSdGroups();
  }, []);

  async function getSdGroups() {
    try {
      const reqData = {
        type: 'getSdGroups',
      };
      // setIsLoading(true); // загружается
      const result = await clientSendData('POST', '/get_sd_groups', reqData); // запрос в БД action: useState

      if (result) {
        setGroups(result.data); // запись в переменную
      }
    } catch (error) {
      catchHandler(error, 'getDirections'); // обработчик ошибок
    } // finally { setIsLoading(false); }
  }

  async function editSdGroups(editAction, editId = null, inputString = null) {
    try {
      const reqData = {
        id: editId,
        action: editAction,
        title: inputString,
        type: 'editSdGroups',
      };
      // setIsLoading(true); // загружается
      if (inputString !== '') {
        const result = await clientSendData('POST', '/edit_sd_groups', reqData); // запрос в БД
      }
    } catch (error) {
      catchHandler(error, 'getDirections'); // обработчик ошибок
    } finally {
      setEditingRow(null);
      setCreateGroup(false);
      setInputText('');
      getSdGroups();
      // setIsLoading(false);
    }
  }

  return (
    <ServiceBody>
    <Table id="groups">
            <THead headers={headers} title="Группы">
        { /*   <TRow>
             {headers.map((header) => <TData key={header.title}>{header.title}</TData>)}
            </TRow> */ }
            </THead>

            { /* Тело таблицы */}
         <TBody>
                {groups.map((group) => (
                 <TRow key={group.id}>
                  <TData>
                       {editingRow === group.id ? (
                        <Wrapper>
                        <Input
                          defaultValue={group.title}
                          onChange={(e) => setInputText(e.target.value)}
                        />
                        <CheckMark onClick={() => editSdGroups('update', group.id, inputText)} />
                        <EditorButton onClick={() => editSdGroups('delete', group.id, null)} icon="delete" />
                        </Wrapper>
                       )
                         : <span onClick={() => setEditingRow(group.id)} className="clickable">{group.title}</span> }
                  </TData>

                    <TData>{group.date_created}</TData>
                 </TRow>
                ))}
         </TBody>

          { /* Футер */ }
          <TFoot>
            {createGroup ? (

                <TRow>
                    <TData>
                    <Wrapper>
                        <Input placeholder="Введите группу" onChange={(e) => setInputText(e.target.value)} />
                        <CheckMark onClick={() => editSdGroups('create', null, inputText)} />
                        <Cancel onClick={cancelCreating} />
                    </Wrapper>
                    </TData>
                </TRow>

            ) : (
                <TRow>
                    <TData onClick={makeGroup}>Создать группу</TData>
                </TRow>
            )}
          </TFoot>
    </Table>
    </ServiceBody>

  );
}

export default Groups;
