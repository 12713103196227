import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { catchHandler } from '../../../../utils/error_handling/error_handling';
import { clientSendData } from '../../../../utils/functions/requests';
import { defaultTableState, headers } from '../../common';
import Loader from '../../../../components/UI/Loader';
import TData from '../../../../components/Table/TData';
import TRow from '../../../../components/Table/TRow';
import CardBody from '../../../../components/Card/CardBody';
import PaginatedTable from '../../../../components/Table/PaginatedTable/PaginatedTable';

/**
  * @component ХелпДеск - активы - по сотрудникам - карточка пользователя - вкладка "Активы"
  * @prop {userID} object - id пользователя
  * @prop {refreshTable} function - обновление заявок в таблице
*/

function Assets(props) {
  const { userID } = props;
  const [userAssets, setUserAssets] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [tableState, setTableState] = useState(defaultTableState.assets.user_assets);
  const [total, setTotal] = useState(0);
  const columns = headers.assets.user_assets;

  useEffect(() => {
    if (userID) getUserData('assets'); // запросить информацию по заявке
  }, [tableState]);
  async function getUserData(data_type) {
    try {
      const reqData = {
        type: 'getAssetUserData',
        tableState,
        total,
      };
      setIsLoading(true);
      const result = await clientSendData('POST', `/get_asset_user_data/${userID}?type=${data_type}`, reqData);
      if (result?.success) { // если запрос выполнен успешно
        const { data } = result;
        setTotal(result.total);
        setUserAssets(data);
      }
    } catch (error) {
      catchHandler(error, 'getAssetUserData');
    } finally {
      setIsLoading(false);
    }
  }
  return (
    <CardBody>
      <PaginatedTable
        id="card__assets"
        headers={columns}
        state={tableState}
        setState={setTableState}
        total={userAssets.length}
      >
        {userAssets.length === 0 && <TRow><TData>Активы отсутствуют</TData></TRow>}
        {userAssets.map((asset) => (
          <TRow key={asset.id}>
            {columns.map((column) => {
              const {
                field, align, mobile_hide,
              } = column;
              return (
              <TData key={field} align={align} loading={isLoading} mobileHide={mobile_hide}>
                {(() => {
                  switch (field) {
                    case 'title': return (
                      <Link className="clickable" to={`?asset=${asset.id}`}>{asset[field]}</Link>
                    );
                    default: return asset[field];
                  }
                })()}
              </TData>);
            })}
          </TRow>
        ))}
      </PaginatedTable>
    </CardBody>
  );
}
export default Assets;
