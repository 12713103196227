import React from 'react';
import Service from '../../components/Service/Service';
import ServiceBody from '../../components/Service/ServiceBody';
import { catchHandler } from './error_handling';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false, // наличие ошибки
      errorData: '', // информация об ошибке
      logError: false, // ошибка записи в лог
    };
  }

  static getDerivedStateFromError(error) {
    // Обновить состояние, чтобы следующий рендер показал запасной UI.
    return { hasError: true };
  }

  // Логирование ошибки
  async componentDidCatch(error, errorInfo) {
    // Сообщение с информацией об ошибке
    const message = `
    Источник: ${errorInfo.componentStack};
    Ошибка: ${error.name};
    Сообщение: ${error.message}.`;
    // обновление информации об ошибке в состоянии компонента
    this.setState((prevState) => ({ ...prevState, errorData: message }));
    // Передача ошибки в обработчик ошибок (при успешной записи вернет true, иначе false)
    const addToLog = await catchHandler(error, errorInfo.componentStack);
    // Если при записи в лог произошла ошибка,
    // обновить сотояние компонента для отображения ссылки на файл с ошибкой
    if (!addToLog) this.setState((prevState) => ({ ...prevState, logError: true }));
  }

  render() { // Рендер запасного UI
    const { hasError, logError, errorData } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <Service>
          <ServiceBody>
            <h3 className="main__title">Что-то пошло не так...</h3>
            <p className="main__title">Обновите страницу, если ситуация повторяется - обратитесь в службу поддержки.</p>
            {logError && ( // Ссылка на скачивание лога отобразится в случае ошибки записи в лог
            <p className="main__title">
              <a href={`data:txt;charset=utf-8,${JSON.stringify(errorData)}`} className="clickable" download="error_log.txt" id="file-link">
                Скачать лог
              </a>
            </p>)}
          </ServiceBody>
        </Service>
      );
    } return children;
  }
}

export default ErrorBoundary;
