import React, { useEffect } from 'react';
import { checkConnectionStatus } from './utils/functions/requests';
import { createAction } from './utils/redux/store';
import AppRouter from './utils/router/AppRouter';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import './App.scss';
import './font/stylesheet.css';
import './components/UI/ui.scss';
import './components/UI/tooltip.scss';

function App() {
  const defaultStyles = [ // Стили по умолчанию (для последующего управления из js)
    ['--primary_color', '#647D91'], // основной цвет
    ['--secondary_color_1', '#0F1955'], // дополнительный цвет 1
    ['--secondary_color_2', '#28B47D'], // дополнительный цвет 2
    ['--secondary_color_3', '#E7E7E7'], // дополнительный цвет 3
    ['--font_color_dark', 'black'], // темный цвет шрифта
    ['--font_color_light', '#E7E7E7'], // светлый цвет шрифта
    ['--font_size', '14px'], // размер шрифта
  ];
  // Функция записи пременных стилей в html
  function changeCssProperty(variable, value) {
    document.documentElement.style.setProperty(variable, value);
  }

  useEffect(() => { // checkConnectionStatus выполнится при первом рендере
    checkConnectionStatus(); // Проверка соединения с сервером и БД
    defaultStyles.forEach((style) => changeCssProperty(style[0], style[1])); // записать переменные стиля
  }, []);

  // Обработка кликов на приложение для скрытия всплывающих элементов
  function appClickHandler() {
    createAction('TOGGLE_HELP', false); // скрыть окно помощи
    createAction('TOGGLE_MENU', false); // скрыть всплывающую панель кнопок в сервисах
    createAction('HIDE_NOTICE'); // скрыть уведомление
    createAction('TOGGLE_SELECT', false); // скрыть выпадающее меню селекта
  }
  return (
    <div className="App" onClick={appClickHandler}>
      <Header />
      <AppRouter />
      <Footer />
    </div>
  );
}

export default App;
