import React, { useEffect, useRef, useState } from 'react';
import Input from '../Input';
import Select from '../Select';
import TextArea from '../TextArea';
import Toggle from '../Toggle';
import Scheme from './Scheme/Scheme';
import SqlCount from './SqlCount/SqlCount';
import Tags from './Tags/Tags';

/**
* @component Универсальный инпут
@prop {type} string (Обязательный) тип данных,
@prop {value} any (Обязательный) значение по умолчанию,
@prop {onChange} function (Обязательный) обработчик изменений,
@prop {placeholder} string плейсхолдер для строковых инпутов,
@prop {pattern} string регулярное выражение для типа array
- Типы
  - string
  - big_number
  - date_time
  - number
  - date
  - time
  - color
  - json
  - bool
  - array
  - scheme
  - sql_count
  - day_of_week
*/

function InputFit(props) {
  const {
    type, value, onChange, placeholder, pattern,
  } = props;

  const [day, setDay] = useState(null); // состояние выбора дня
  const [toggle, setToggle] = useState(value); // состояние тоггла
  const inputRef = useRef(null); // DOM элемент инпута для обновления {current: null}

  useEffect(() => { // при каждом изменении value - обновлять значение инпута
    if (inputRef?.current) inputRef.current.value = (value || '');
  }, [value]);

  const day_of_week = [
    { id: 1, title: 'Понедельник' },
    { id: 2, title: 'Вторник' },
    { id: 3, title: 'Среда' },
    { id: 4, title: 'Четверг' },
    { id: 5, title: 'Пятница' },
    { id: 6, title: 'Суббота' },
    { id: 7, title: 'Воскресенье' },
  ];

  // обработчик изменения ввода
  function handler(event) {
    switch (type) {
      case 'string':
      case 'big_number':
      case 'date_time':
      case 'number':
      case 'date':
      case 'time':
      case 'color':
      case 'json': // при перечисленных выше типах
        onChange(event.target.value); // в обработчик передаётся event.target.value
        break;

      case 'bool': // при типе bool
      case 'array': // array
      case 'scheme': // и scheme
      case 'sql_count': // и sql_count
        onChange(event); // в обработчик передаётся значение из дочернего компонента
        break;

      case 'day_of_week': // при типе day_of_week
        setDay(event.title); // обновляется состояние селекта
        onChange(event.id); // в обработчик передаётся значение из селекта
        break;

      default: // при отсутствии типа (соответственно рендере обычного input "text")
        onChange(event.target.value); // в обработчик передаётся event.target.value
    }
  }

  switch (type) {
    case 'string':
      return <Input placeholder={placeholder} defaultValue={value} onChange={handler} ref={inputRef} />;

    case 'big_number': case 'number':
      return <Input placeholder={placeholder} type="number" defaultValue={value} onChange={handler} ref={inputRef} />;

    case 'date_time':
      return <Input type="datetime-local" defaultValue={value} onChange={handler} ref={inputRef} />;

    case 'date': case 'time': case 'color':
      return <Input type={type} defaultValue={value} onChange={handler} ref={inputRef} />;

    case 'json':
      return <TextArea defaultValue={value} onChange={handler} ref={inputRef} />;

    case 'bool':
      return (
      <Toggle state={String(toggle)} onClick={() => { setToggle(!toggle); handler(!toggle); }} />);

    case 'day_of_week':
      return (
        <Select
          id={+new Date()}
          array={day_of_week}
          // выбранный день или поиск в неделе id дня по переданному значение
          defaultValue={day || day_of_week.find((item) => String(item.id) === String(value))?.title}
          onChoose={handler}
        />);

    case 'array':
      return <Tags array={value} onChange={handler} pattern={pattern} />;

    case 'scheme':
      return <Scheme object={value} onChange={handler} />;

    case 'sql_count':
      return <SqlCount sql={value} onChange={handler} />;

    default:
      return <Input placeholder={placeholder} defaultValue={value} onChange={handler} ref={inputRef} />;
  }
}

export default InputFit;
