import React, { useEffect, useState } from 'react';
import Select from '../../UI/Select';
import CheckBox from '../../UI/CheckBox';
import './paginatedthead.scss';

/**
  * @component Заголовок таблицы с пагинацией
  * @prop {string} id Идентификатор DOM элемента таблицы
  * @prop {TableState} state Состояние таблицы
  * @prop {function} setState Обновление состояния таблицы
  * @prop {Array<Header>} headers Массив заголовков колонок таблицы
  * @prop {number} total Общее количество строк (для расчёта кол-ва страниц)
  */
function PaginatedTHead(props) {
  const {
    id, // Идентификатор DOM элемента таблицы
    total, // Общее количество строк (для расчёта кол-ва страниц)
    state, // Состояние таблицы
    setState, // Обновление состояния таблицы
    headers = [], // Массив заголовков колонок таблицы
    filterFields = [],
    filterFieldsFunc = null,
  } = props;

  const {
    title, // Название таблицы
    sort = null, // параметры сортировки
    search = null, // параметры поиска
    switch_data = null, // информация для смены данных таблицы
    button_place = 'row', // расположение кнопки включение фильтрации
  } = state;

  const [searchData, setSearchData] = useState(search); // данные для поиска
  const [isMounted, setIsMounted] = useState(false); // параметр монтирования компонента (для отмены первого useEffect)

  const headersSelect = {};
  headers.forEach((element) => {
    headersSelect[element.field] = false;
  });
  const [isActive, setIsActive] = useState(headersSelect);
  const delay = 500; // задержка перед поиском
  const switchOptions = switch_data?.titles ? Object.keys(switch_data.titles).map((key, index) => ({
    id: index,
    title: switch_data.titles[key],
    preset: key,
  })) : [];
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isMounted) {
      const timeOutId = setTimeout(async () => { // запуск таймаута выполнения поиска
        setState((prev) => ({
          ...prev,
          page: 1, // сброс страницы при поиске
          search: searchData,
        }));
      }, delay);
      return () => clearTimeout(timeOutId); // очистка таймаута при размонтировании компонента
    } setIsMounted(true);
  }, [searchData]);

  // Сортировка по колонке
  function sortByField(field) {
    setState((prev) => ({
      ...prev,
      sort: {
        field,
        direction: prev.sort?.direction === 'asc' ? 'desc' : 'asc',
      },
    }));
  }

  // Значок сортировки
  const sortBadge = (field) => {
    let result = '↑↓'; // состояние по умолчанию
    if (sort?.field === field) { // Если сортируемое поле равно текущему
      if (sort?.direction === 'asc') result = '↓'; // если состояние сортировки asc - стрелка вниз
      if (sort?.direction === 'desc') result = '↑'; // если состояние сортировки desc - стрелка вверх
    }
    return result;
  };

  // Включение/выключение фильтрации
  function toggleFilter() {
    setState((prev) => ({
      ...prev,
      /**
       * Если есть данные поиска(фильтра), сбросить их (тогда строка фильтрации скроется)
       * Иначе поиск(фильтрация) был выключен, нужно показать его:
       * При выключении search, данные поиска останутся в состоянии searchData - записываем их
       * Иначе записываем в search пустой объект чтобы отобразить строку фильтрации
       */
      search: search ? null : (searchData || {}),
    }));
    // if (Object.keys(filterFields).length > 0) {
    //   const emptyFilters = {};
    //   Object.keys(filterFields).forEach((el) => {
    //     const filters = filterFields[el].map((element) => ({ ...element, value: false }));
    //     emptyFilters[el] = filters;
    //     console.log({ emptyFilters });
    //   });
    //   filterFieldsFunc({ ...emptyFilters });
    //   setState((prev) => ({ //
    //     ...prev,
    //     filter: { ...emptyFilters },
    //   }));
    // }
  }

  // Определение классов стилей
  const sortCellClass = (header) => {
    let result = 'upu-table__table-button';
    if (sort?.field === header.field) result += ' upu-table__table-button_active';
    if (header.mobile_hide) result += ' mobile_hide';
    return result;
  };

  // Определение классов стилей
  const searchButtonClass = () => {
    let result = 'upu-table__table-button';
    if (search) result += ' upu-table__table-button_active';
    return result;
  };

  // Обработчик поиска по колонке
  function onChangeHandler(event, field) {
    setSearchData((prev) => ({
      ...prev,
      /**
       * Если строка event.target.value пустая, записываем undefined чтобы удалить ключ из объекта
       * Так как пустое значение добавляется в запрос при поиске, например LIKE LOWER('%%')
      */
      [field]: event.target.value || undefined,
    }));
  }

  // Обработчик переключения данных таблицы
  function switchDataHandler(option) {
    setSearchData('');
    setState((prev) => ({ //
      ...prev,
      switch_data: {
        ...prev.switch_data,
        current: option.preset,
      },
      page: 1, // сбросить страницу
      sort: undefined, // сбросить сортировку
      search: undefined, // сбросить поиск
    }));
  }

  // Определение классов стилей
  const filterCellClass = (header) => {
    let result = 'upu-table__table-data';
    if (header.mobile_hide) result += ' mobile_hide';
    return result;
  };

  // Определение классов стилей
  const filterRowClass = () => {
    let result = 'upu-table__table-row upu-table__filter-block';
    if (search) result += '_active';
    return result;
  };

  const filtered = (field, data) => {
    const filt = filterFields[field].map((el) => {
      if (el.title === data) {
        return { ...el, value: !el.value };
      }
      return el;
    });
    filterFieldsFunc((prev_filter) => ({ ...prev_filter, [field]: filt }));
    setState((prev) => ({ //
      ...prev,
      page: 1,
      filter: { ...filterFields, [field]: filt },
    }));
  };
  return (
    <thead className="upu-table__table-head">
      {title && (
        <tr className="upu-table__table-row">
          <td className="upu-table__header">

            {/* Выбор пресета */}
            {switch_data && (
              <Select
                id={`${id}_switch-select`}
                array={switchOptions}
                onChoose={switchDataHandler}
                defaultValue={switchOptions.find((opt) => opt.preset === switch_data?.current)?.title}
              />
            // <span className="upu-table__ext-button" onClick={switchDataHandler}>
            //   {switch_data?.titles?.[switch_data?.current]}
            // </span>
            )}

            {/* Заголовок таблицы */}
            {`${title} - ${total}`}

            {/* Кнопка включения/выключения режима фильтрации */}
            {button_place === 'header' && (
            <img
              className={`upu-table__search-button ${search ? ' upu-table__search-button_active' : ''}`}
              src="../../icons/button/search.svg"
              onClick={toggleFilter}
              alt="search"
            />
            )}
          </td>
        </tr>
      )}

      <tr className="upu-table__table-row">
        {headers.map((item) => {
          const { title: header, field } = item;
          return (
            <td key={field} className={sortCellClass(item)} onClick={() => sortByField(field)}>
              {header}
              <span className="upu-table__sort-bage">{sortBadge(field)}</span>
            </td>
          );
        })}

        {button_place === 'row' && (
        <td onClick={toggleFilter} className={searchButtonClass()}>
            <img src="../../icons/button/search.svg" alt="search" />
        </td>
        )}
      </tr>

      <tr className={filterRowClass()}>
        {/* Ячейки с поиском */}
          {headers.map((header) => {
            const { field } = header;
            return (
              <td key={field} className={filterCellClass(header)}>
                <input
                  type="text"
                  className="input search-input"
                  placeholder=" "
                  onChange={(event) => onChangeHandler(event, field)}
                  value={searchData?.[field] || ''}
                />
                {filterFields[field]
                && <>
                  <img
                    className="custom-select__chevron"
                    src="../../icons/chevron.svg"
                    alt="chevron"
                    onClick={() => setIsActive(() => ({ [field]: !isActive[header.field] }))}
                  />
                  <div className={isActive[field] ? 'custom-select__dropdown custom-select__dropdown_active custom-select__filter' : 'custom-select__dropdown'}>
                          {filterFields[field].sort((a, b) => a.value < b.value).map((row) => (
                            <span key={row.id + field} className="custom-select__option">
                              <CheckBox
                                defaultChecked={filterFields[field].filter((el) => el.title === row.title)[0].value}
                                onClick={() => filtered(header.field, row.title)}
                              />
                              {row.title}
                            </span>
                          ))}
                  </div>
                   </>}
              </td>
            );
          })}
        {button_place === 'row' && <td className="upu-table__table-data" />}
      </tr>

    </thead>
  );
}

export default PaginatedTHead;
