import React, { useEffect, useState } from 'react';
import ServiceBody from '../../components/Service/ServiceBody';
import { catchHandler } from '../../utils/error_handling/error_handling';
import { clientSendData } from '../../utils/functions/requests';
import Table from '../../components/Table/Table';
import THead from '../../components/Table/THead';
import TBody from '../../components/Table/TBody';
import TRow from '../../components/Table/TRow';
import TData from '../../components/Table/TData';
import { headers } from './common';
import Select from '../../components/UI/Select';
import { alertFunction, alertFunctionLocal } from '../../utils/functions/alertFunction';
import TFoot from '../../components/Table/TFoot';
import { authorization } from '../../utils/functions/authenticate';
import Cap from '../Cap/Cap';

function AdminQr() {
  const [qrData, setQrData] = useState({ companies: [], users: [] });
  const [allUsers, setAllUsers] = useState([]);
  const [selectUser, setSelectUser] = useState({});
  const [allCompanies, setAllCompanies] = useState([]);
  const [selectCompany, setSelectCompany] = useState({});
  const [isAuthorized, setIsAuthorized] = useState(false); // Состояние авторизации

  useEffect(() => {
    awaitRequests();
    getPermissions();
  }, []);

  async function getPermissions() {
    try {
      const reqData = {
        type: 'getPermissionsQr',
      };
      const result = await clientSendData('POST', '/get_permissions_qr', reqData); // запрос в БД
      if (result) {
        setQrData(result.data);
        setAllCompanies(() => result.allCompanies);
        setAllUsers(() => result.allUsers);
      }
    } catch (error) {
      catchHandler(error, 'getPermissionsQr'); // обработчик ошибок
    }
  }

  async function awaitRequests() {
    const checkAuthorization = await authorization(); // авторизация
    setIsAuthorized(checkAuthorization);
  }

  async function editPermissionsQr() {
    try {
      const reqData = {
        type: 'editPermissionsQr',
        data: qrData,
      };
      const result = await clientSendData('POST', '/edit_permissions_qr', reqData); // запрос в БД
      if (result.success) {
        alertFunction('save_settings', 'client');
        setQrData(((state) => ({ ...state, change: false })));
      }
    } catch (error) {
      catchHandler(error, 'getPermissionsQr'); // обработчик ошибок
    }
  }

  async function deletePermission(data, type) {
    const prevData = qrData[type];
    const newData = prevData.filter((el) => el.id !== data);
    if (type === 'companies') {
      setQrData((state) => ({ ...state, companies: newData }));
      setSelectCompany({});
    } else {
      setQrData((state) => ({ ...state, users: newData }));
      setSelectUser({});
    }
    setQrData((state) => ({ ...state, change: true }));
  }

  async function chooseData(data, type) {
    if (Object.keys(data).length > 0) {
      const prevData = qrData[type];
      const findData = prevData.filter((el) => el.title === data.title);
      if (findData.length >= 1) await alertFunctionLocal('Пользователь уже выбран');
      else {
        prevData.push({ id: data.id, title: data.title });
        if (type === 'companies') {
          setQrData((state) => ({ ...state, companies: prevData }));
          setSelectCompany({});
        } else {
          setQrData((state) => ({ ...state, users: prevData }));
          setSelectUser({});
        }
        setQrData((state) => ({ ...state, change: true }));
      }
    } else alertFunctionLocal('Ничего не выбрано');
  }

  if (isAuthorized) {
    return (
      <ServiceBody>
          <Table id="">
              <THead
                title="Доступы"
                headers={headers.permissions}
              />
              <TBody>
              <TRow key="companies">
                        <TData>Компании</TData>
                        <TData>
                            <div className="tags__input-block">
                                <Select
                                  id="companies"
                                  array={allCompanies}
                                  className="tags__input"
                                  onChoose={(data) => setSelectCompany({ id: data.id, title: data.title })}
                                  defaultValue={selectCompany?.title}
                                  search
                                />
                                <div onClick={() => chooseData(selectCompany, 'companies')} className="tags__button">
                                    <img className="tags__img" src="../../icons/cancel.svg" alt="add-tag" />
                                </div>
                            </div>
                            <div className="tags__list">
                                {qrData.companies.map((el) => (<p className="tags__tag" key={el.id}>
                                                        <span onClick={() => deletePermission(el.id, 'companies')} className="upu__close">X</span>
                                                        {el.title}
                                                               </p>))}
                            </div>
                        </TData>
              </TRow>
                  <TRow key="users">
                        <TData>Пользователи</TData>
                        <TData>
                            <div className="tags__input-block">
                                <Select
                                  id="users"
                                  array={allUsers}
                                  className="tags__input"
                                  onChoose={(data) => setSelectUser({ id: data.id, title: data.title })}
                                  defaultValue={selectUser?.title}
                                  search
                                />
                                <div onClick={() => chooseData(selectUser, 'users')} className="tags__button">
                                    <img className="tags__img" src="../../icons/cancel.svg" alt="add-tag" />
                                </div>
                            </div>
                            <div className="tags__list">
                                {qrData.users.map((el) => (<p className="tags__tag" key={el.id}>
                                                        <span onClick={() => deletePermission(el.id, 'users')} className="upu__close">X</span>
                                                        {el.title}
                                                           </p>))}
                            </div>
                        </TData>
                  </TRow>

              </TBody>
              {qrData.change
                && <TFoot>
                    <TRow>
                        <TData onClick={editPermissionsQr}>Сохранить</TData>
                    </TRow>
                   </TFoot>
              }
          </Table>
      </ServiceBody>
    );
  } return <Cap />;
}

export default AdminQr;
