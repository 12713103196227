import React, { useEffect, useState } from 'react';
import { alertFunction } from '../../../../../utils/functions/alertFunction';
import { clientSendData } from '../../../../../utils/functions/requests';
import { catchHandler } from '../../../../../utils/error_handling/error_handling';
import { activeTicketStatuses, searchTicketData, taskStatus } from '../../../common';
import InputSearch from '../../../../../components/UI/InputSearch';
import CardSetting from '../../../../../components/Card/CardSetting';
import TextArea from '../../../../../components/UI/TextArea';
import Button from '../../../../../components/UI/Button/Button';
import Input from '../../../../../components/UI/Input';
import Loader from '../../../../../components/UI/Loader';
import classes from '../../../../../components/UI/Button/button.module.scss';
import Task from './Task';

/**
  * @component ХелпДеск - заявки - карточка заявки - вкладка "Наряды"
  * @prop {ticketID} number - id заявки
*/
function Tasks(props) {
  const { ticketID } = props;

  const newTaskDefault = { id: 'new', ticket_id: ticketID, status_id: taskStatus.new }; // объект нового наряда по умолчанию
  const [newTask, setNewTask] = useState(newTaskDefault); // объект нового наряда
  const [tasks, setTasks] = useState([]); // все наряды по заявке
  const [ticketStatusId, setTicketStatuId] = useState(); // id статуса заявки
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [isSearching, setIsSearching] = useState(false); // состояние загрузки при поиске
  const [editingTask, setEditingTask] = useState({}); // объект редактируемого наряда
  const [openedTasks, setOpenedTasks] = useState([]); // развернутые наряды
  const [additionalInfo, setAdditionalInfo] = useState({ // дополнительная информация
    group: [], // группы исполнителей (при поиске)
    performer: [], // исполнители (при поиске)
  });
  const isActiveTask = (id) => openedTasks.includes(id); // определение развернута ли строка наряда
  const isCreating = isActiveTask('new'); // определение развернута ли строка создания наряда
  const smallButtonClass = `${classes.button} ${classes.small}`; // классы стилей для маленькой кнопки

  useEffect(() => {
    getTasks(); // запросить информацию по нарядам при каждом изменении ticketID
  }, [ticketID]);

  // Обработчик запросов в БД для получения/изменения нарядов
  async function ticketTasksHandler(action, task, extData) {
    try {
      const reqData = {
        type: 'ticketTasksHandler',
        ticketID, // id заявки
        task, // объект наряда с необходимыми данными согласно action
        extData, // дополнительные данные (используется в 'edit' для передачи наряда до изменений)
      };

      setIsLoading(true); // загружается
      const result = await clientSendData('POST', `/ticket_tasks_handler/${action}`, reqData); // запрос в БД
      if (result?.success) return result; // если запрос выполнен успешно - возвращаем результат
      return null; // возвращаем null для последующей обработки
    } catch (error) { // если ошибка
      catchHandler(error, 'ticketTasksHandler'); // обрабатываем ошибку
      return null; // возвращаем null для последующей обработки
    } finally {
      setIsLoading(false); // загрузка завершена
    }
  }

  // Получение нарядов
  async function getTasks() {
    try {
      const result = await ticketTasksHandler('get'); // запросить информацию по нарядам
      const tickeStatus = await clientSendData('POST', `/get_ticket_data/${ticketID}?type=status`, { type: 'getTicketData' });
      if (result?.success) {
        setTasks(result.data); // возвращаем результат
        const myTaskIDs = result.data // активные наряды на группу пользователя
          .filter((task) => (task.in_my_group && task.status_id === taskStatus.new))
          .map((task) => task.id);
        setOpenedTasks(myTaskIDs); // развернуть их
      }
      if (tickeStatus) setTicketStatuId(tickeStatus.data[0].status_id);
    } catch (error) {
      catchHandler(error, 'getTasks');
    }
  }

  // Создание наряда
  async function createTask() {
    try {
      const requiredFields = ['title', 'group_id'];
      // Если есть данные во всех необходимых полях
      if (requiredFields.every((field) => newTask[field])) {
        const confirm = window.confirm('Создать новый наряд?'); // запросить подтверждение
        if (confirm) { // если подтверждено
          const result = await ticketTasksHandler('create', newTask); // ждем создание наряда
          if (result.success) { // если есть результат (значит запись прошла успешно, на бэке это обрабатывается)
            await getTasks('get'); // ждем обновление списка нарядов
            toggleTask('new'); // закрываем форму создания
            setNewTask(newTaskDefault); // сбрасываем данные нового наряда (чтобы при повторном открытии не было старых данных)
          } else await alertFunction('something_went_wrong', 'clientPost'); // иначе запись не прошла, показываем уведомление
        }
      } else await alertFunction('required_fields', 'clientPost');
    } catch (error) {
      catchHandler(error, 'createTask');
    }
  }

  // Поиск данных и запись в состояние
  // e - event
  // field - какие данные ищем
  async function searchWrapper(e, field) {
    try {
      setIsSearching(true); // поиск выполняется
      setNewTask((state) => ({ ...state, [`${field}_id`]: null })); // сбросить id при поиске (чтобы подсветить строку красным)
      const result = await searchTicketData(e.target.value, field); // получение данных
      setAdditionalInfo((state) => ({ ...state, [field]: result })); // обновление состояния по переданному ключу
    } catch (error) {
      catchHandler(error, 'searchWrapper');
    } finally {
      setIsSearching(false);
    }
  }

  // Выбор исполнителя
  function choosePerformer(performer, task) {
    const {
      id, title, group_id, group_title,
    } = performer;
    const newData = {
      performer: title,
      performer_id: id,
      group: group_title,
      group_id,
    };
    // Записываются данные исполнителя, в т.ч. заменяется группа к которой он принадлежит
    if (task === 'new') setNewTask((state) => ({ ...state, ...newData }));
    if (task === 'editing') setEditingTask((state) => ({ ...state, ...newData }));
  }

  // Выбор группы
  function chooseGroup(group, task) {
    const { id, title } = group;
    const newData = { group: title, group_id: id };
    if (task === 'new') setNewTask((state) => ({ ...state, ...newData }));
    if (task === 'editing') setEditingTask((state) => ({ ...state, ...newData }));
  }

  // Свернуть/развернуть наряд
  function toggleTask(id) {
    if (openedTasks.includes(id)) { // если id наряда присутствует в списке развернутых
      setOpenedTasks((state) => [...state.filter((item) => item !== id)]); // убрать его оттуда
      if (id === editingTask?.id) setEditingTask({}); // если наряд редактировался - сбросить
    } else setOpenedTasks((state) => [...state, id]); // иначе id наряда нет - добавляем
  }

  if (isLoading) return <Loader />;
  return (
    <div className="ticket-task">

      {/* --------------СОЗДАНИЕ НАРЯДА--------------*/}
      {activeTicketStatuses.includes(ticketStatusId) && (
      <CardSetting title="Новый наряд" active={isCreating}>
        {isCreating && (
        <>
        <CardSetting title="Название" invalid={!newTask?.title}>
          <Input
            placeholder="Введите текст"
            defaultValue={newTask?.title}
            onChange={(e) => setNewTask((state) => ({ ...state, title: e.target.value }))}
          />
        </CardSetting>
        <CardSetting title="Группа" invalid={!newTask?.group_id}>
          <InputSearch
            id="helpdesk__ticket-search-group"
            array={additionalInfo.group}
            onSearch={(e) => searchWrapper(e, 'group')}
            onChoose={(data) => chooseGroup(data, 'new')}
            defaultValue={newTask?.group}
            placeholder="/ - показать все"
            loading={isSearching}
          />
        </CardSetting>

        <CardSetting title="Исполнитель">
          <InputSearch
            id="helpdesk__ticket-search-performer"
            array={additionalInfo.performer}
            onSearch={(e) => searchWrapper(e, 'performer')}
            onChoose={(data) => choosePerformer(data, 'new')}
            defaultValue={newTask?.performer}
            placeholder="/ - показать все"
            loading={isSearching}
          />
        </CardSetting>

        <CardSetting title="Комментарий к наряду">
          <TextArea
            onChange={(e) => setNewTask((state) => ({ ...state, comment: e.target.value }))}
            defaultValue={newTask?.comment}
            placeholder="Введите текст"
          />
        </CardSetting>
        <CardSetting>
          <span>{' '}</span>
          <Button className={smallButtonClass} onClick={createTask}>Создать</Button>
          {/* <Button className={smallButtonClass} onClick={() => toggleTask('new')}>Отменить</Button> */}
        </CardSetting>
        </>)}
        <img
          className={`ticket-task__chevron ${isActiveTask('new') ? 'ticket-task__chevron_active' : ''}`}
          onClick={() => toggleTask('new')}
          src="../../../../../../icons/chevron.svg"
          alt="chevron"
        />

      </CardSetting>)}

      {/* --------------СУЩЕСТВУЮЩИЕ НАРЯДЫ---------------*/}
      {tasks.map((task, index) => {
        const {
          id, title, status, status_id, comment = '', performer, group, date_created, creator, modifier, date_modified, in_my_group,
        } = task;
        const isActive = isActiveTask(id); // наряд развернут?
        return (
          <CardSetting key={id} title={`${index + 1}. ${title} (${status})`} active={isActive}>
            {in_my_group && <span className={`helpdesk__task-badge${status_id === taskStatus.new ? '_active' : ''}`}>⬤</span>}
            <img
              className={`ticket-task__chevron ${isActive ? 'ticket-task__chevron_active' : ''}`}
              onClick={() => toggleTask(id)}
              src="../../../../../../icons/chevron.svg"
              alt="chevron"
            />

            {/* --------------РАЗВЕРНУТЫЙ НАРЯД---------------*/}
            {isActive && (
            <Task
              task={task}
              tasks={tasks}
              ticketTasksHandler={ticketTasksHandler}
              getTasks={getTasks}
              editingTask={editingTask}
              setEditingTask={setEditingTask}
              additionalInfo={additionalInfo}
              searchWrapper={searchWrapper}
              choosePerformer={choosePerformer}
              chooseGroup={chooseGroup}
              isSearching={isSearching}
            />)}
          </CardSetting>
        );
      })}

      {tasks.length === 0 && !activeTicketStatuses.includes(ticketStatusId) && (
        <h2 className="upu-service__title">Наряды отсутствуют</h2>
      )}

    </div>
  );
}

export default Tasks;
