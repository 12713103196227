import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Canvas from '../Reports/Components/Canvas/Canvas';
import Input from '../../components/UI/Input';
import ServiceBody from '../../components/Service/ServiceBody';
import { clientSendData } from '../../utils/functions/requests';
import { alertFunction } from '../../utils/functions/alertFunction';
import { catchHandler } from '../../utils/error_handling/error_handling';
import Button from '../../components/UI/Button/Button';
import CheckBox from '../../components/UI/CheckBox';
import TableWrapper from '../Reports/Components/TableWrapper/TableWrapper';
import Table from '../../components/Table/Table';
import THead from '../../components/Table/THead';
import TBody from '../../components/Table/TBody';
import TRow from '../../components/Table/TRow';
import TData from '../../components/Table/TData';

function BookingReport(props) {
  const { today } = props;

  const sevenDaysAgo = new Date(new Date(today) - 7 * 24 * 60 * 60 * 1000);
  const weekAgo = `${sevenDaysAgo.getFullYear()}-${String(sevenDaysAgo.getMonth() + 1).padStart(2, '0')}-${sevenDaysAgo.getDate()}`;

  const title = 'Бронирования пользователей';
  // const currentDate = new Date();
  // const today = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${currentDate.getDate()}`;
  const [reportDate, setReportDate] = useState(today);
  const [periodReportDate, setPeriodReportDay] = useState({ date_start: weekAgo, date_end: today });
  const [renderPieChart, setRenderPieChart] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  // const [isShowChart, setIsShowChart] = useState(true);
  const isShowChart = useSelector((state) => state.reports.is_show_chart);// состояние видимости графика
  const [rawReport, setRawReport] = useState({}); // сырые данные отчёта
  const [thisDayOnly, setThisDayOnly] = useState(false);
  const labels = [
    { name: 'Полный день', data: 0 },
    { name: 'Неполный день', data: 0 },
    { name: 'Свободные места', data: 0 },
  ];
  const [report, setReport] = useState(labels);
  const [reportDetails, setReportDetails] = useState({
    often: [],
    rarely: [],
    workersTop: [],
    workersAllTime: [],
  });

  const headers = [
    { title: 'Место' },
    { title: 'Количество' },
  ];

  useEffect(() => {
    getBookingReport();
    getBookingReportDetails();
  }, []);

  // получить отчёт по бронированиям
  async function getBookingReport() {
    try {
      const reqData = {
        type: 'getBookingReport',
        reportDate,
      };
      setIsLoading(true); // состояние - загружется
      const result = await clientSendData('POST', '/get_booking_report', reqData);
      if (result === 'bad_request') { // если некорректный запрос
        alertFunction('bad_request', 'clientPost'); // уведомление
        setIsLoading(false); // загрузка завершена
      } else {
        // const rawData = result.full_booking;
        setReport(([{ name: 'Полный день', data: Number(result.fullBooking[0].full_booking) },
          { name: 'Неполный день', data: Number(result.partBooking[0].part_booking) },
          { name: 'Свободные места', data: Number(result.noBooking[0].count) },
        ]));
        setRenderPieChart(!renderPieChart);
      }
    } catch (error) {
      catchHandler(error, 'getBookingReport');
      setIsLoading(false); // загрузка завершена
    }
  }

  async function getBookingReportDetails() {
    try {
      const reqData = {
        type: 'getBookingReportDetails',
        periodReportDate,
      };
      setIsLoading(true); // состояние - загружется
      const result = await clientSendData('POST', '/get_booking_report_details', reqData);
      if (result === 'bad_request') { // если некорректный запрос
        alertFunction('bad_request', 'clientPost'); // уведомление
        setIsLoading(false); // загрузка завершена
      } else {
        setReportDetails(result);
        setRenderPieChart(renderPieChart);
      }
    } catch (error) {
      catchHandler(error, 'getBookingReportDetails');
      setIsLoading(false); // загрузка завершена
    }
  }

  function startDateHandler(e) {
    setPeriodReportDay((state) => ({
      ...state,
      date_start: e.target.value,
    }));
    setReportDate(periodReportDate.date_start);
  }

  function handleCheckbox(e) {
    setThisDayOnly(!thisDayOnly);
    setPeriodReportDay((state) => ({
      ...state,
      date_end: null,
    }));
  }

  function reportGetter() {
    getBookingReport();
    getBookingReportDetails();
  }

  if (isShowChart) {
    return (
        <ServiceBody>

    <div style={{
      display: 'flex', flexDirection: 'column', width: '100%', overflowY: 'auto', alignItems: 'center', height: '100vh',
    }}
    >
    <div style={{
      width: '100%', display: 'flex', flexDirection: 'row', minHeight: '250px',
    }}
    >

{!renderPieChart && <Canvas
  id="report_booking-data"
  title={title}
  label="Количество мест"
  labels={report.map((label) => label.name)}
  values={report.map((label) => label.data)}
  legend_position="top"
/>}
    {renderPieChart && <Canvas
      id="report_booking-data"
      title={title}
      label="Количество мест"
      labels={report.map((label) => label.name)}
      values={report.map((label) => label.data)}
      legend_position="top"
    />}

{!renderPieChart && <Canvas
  id="report2"
  title="Бронируют чаще всего"
  type="bar"
  label="Количество мест"
  labels={reportDetails?.workersTop.map((label) => label.user)}
  values={reportDetails?.workersTop.map((label) => label.quantity)}
  legend_position="top"
/>}
    {renderPieChart && <Canvas
      id="report2"
      title="Бронируют чаще всего"
      type="bar"
      label="Количество мест"
      labels={reportDetails?.workersTop.map((label) => label.user)}
      values={reportDetails?.workersTop.map((label) => label.quantity)}
      legend_position="top"
    />}
    </div>

    <div style={{
      width: '100%', display: 'flex', flexDirection: 'row', minHeight: '250px',
    }}
    >
   {!renderPieChart && <Canvas
     id="report"
     title="Наиболее часто бронируемые места"
     type="bar"
     label="Количество мест"
     labels={reportDetails?.often.map((label) => label.workplace)}
     values={reportDetails?.often.map((label) => label.quantity)}
     legend_position="top"
   />}
    {renderPieChart && <Canvas
      id="report"
      title="Наиболее часто бронируемые места"
      type="bar"
      label="Количество мест"
      labels={reportDetails?.often.map((label) => label.workplace)}
      values={reportDetails?.often.map((label) => label.quantity)}
      legend_position="top"
    />}

    {!renderPieChart && <Canvas
      id="report1"
      title="Наименее часто бронируемые места"
      type="bar"
      label="Количество мест"
      labels={reportDetails?.rarely.map((label) => label.workplace)}
      values={reportDetails?.rarely.map((label) => label.quantity)}
      legend_position="top"
    />}
    {renderPieChart && <Canvas
      id="report1"
      title="Наименее часто бронируемые места"
      type="bar"
      label="Количество мест"
      labels={reportDetails?.rarely.map((label) => label.workplace)}
      values={reportDetails?.rarely.map((label) => label.quantity)}
      legend_position="top"
    />}

    </div>
    <div className="booking-report">
    <Input
      type="date"
      onChange={startDateHandler}
      defaultValue={periodReportDate.date_start}
    />
    <Input
      type="date"
      onChange={(e) => setPeriodReportDay((state) => ({
        ...state,
        date_end: e.target.value,
      }))}
      defaultValue={periodReportDate.date_end}
      disabled={thisDayOnly}
    />
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
    <CheckBox
      onChange={handleCheckbox}
      checked={thisDayOnly}
    />
     <p>Только за этот день</p>

    </div>
    </div>
    <Button onClick={reportGetter}>Получить отчет</Button>
    </div>
        </ServiceBody>
    );
  }

  if (!isShowChart) {
    return (
<ServiceBody>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          overflowY: 'auto',
          alignItems: 'center',
          height: '100vh',
          gap: '10px',
        }}
        >
    <div style={{
      width: '100%', display: 'flex', flexDirection: 'row', minHeight: '250px', columnGap: '10px',
    }}
    >
        <Table id="often_booking">
              <THead headers={headers} title="Наиболее часто бронируемые места" />
              <TBody>
                {reportDetails.often.map((place) => (
                  <TRow key={place.workplace_id}>
                  <TData>{place.workplace}</TData>
                  <TData>{place.quantity}</TData>
                  </TRow>
                ))}
              </TBody>
        </Table>

            <Table id="rarely_booking">
              <THead headers={headers} title="Наименее часто бронируемые места" />
              <TBody>
                {reportDetails.rarely.map((place) => (
                  <TRow key={place.workplace_id}>
                  <TData>{place.workplace}</TData>
                  <TData>{place.quantity}</TData>
                  </TRow>
                ))}
              </TBody>
            </Table>
    </div>

            <div style={{
              width: '100%', display: 'flex', flexDirection: 'row', minHeight: '250px', columnGap: '10px',
            }}
            >
            <Table id="often_booking_workers">
              <THead headers={[{ title: 'Сотрудник' }, { title: 'Количество бронирований' }]} title="Бронируют чаще всего" />
              <TBody>
                {reportDetails.workersAllTime.map((worker) => (
                  <TRow key={worker.user_id}>
                  <TData>{worker.user}</TData>
                  <TData>{worker.total}</TData>
                  </TRow>
                ))}
              </TBody>
            </Table>

            <Table id="places_statistics">
              <THead title="Количество мест" />
              <TBody>
                {report.map((row) => (
                  <TRow key={row.name}>
                  <TData>{row.name}</TData>
                  <TData>{row.data}</TData>
                  </TRow>
                ))}
              </TBody>
            </Table>
            </div>
        </div>
</ServiceBody>
    );
  }
}

export default BookingReport;
