import React, { useEffect, useState } from 'react';
import { catchHandler } from '../../../utils/error_handling/error_handling';
import { alertFunction } from '../../../utils/functions/alertFunction';
import { clientSendData } from '../../../utils/functions/requests';
import Loader from '../../UI/Loader';
import Toggle from '../../UI/Toggle';

/**
 * @component Форма настройки дополнительного доступа
  @prop {user_id} number (Обязательный) id пользователя, которому редактируется доступ
  @prop {parameters} object (Обязательный) параметр для SubAccessForm из upu_resources.parameters
  @prop {show} boolean (Обязательный) состояние отображения формы дополнительного доступа
  @prop {service_id} number (Обязательный) id родительского сервиса
  @prop {isAdmin} boolean (Обязательный) источник перехода - страница администрирования?
*/

function SubAccessForm(props) {
  const {
    user_id, parameters, show, service_id, isAdmin,
  } = props; // Деструктуризация свойств

  // таблица дочерних сервисов к которым предоставляется доступ
  const subServicesTable = parameters?.table; // пример: qa_directions
  // таблица доступов к дочерним сервисам
  const subServicesAccessTable = parameters?.access_table; // пример: qa_directions_access
  // наименование поля с id дочернего сервиса в таблице доступов
  const subServiceField = parameters?.field; // пример: direction_id
  // наименование уникального ключа в subServicesAccessTable
  const subServicesAccessUnique = parameters?.access_unique; // пример: qa_directions_access_unique

  // При каждом рендере компонента SubAccessForm выполняются функции, находящиеся внутри useEffect
  useEffect(() => {
    getSubServices();
    if (show) getSubAccessData();
    // если форма видна - обновляются данные доступа
  }, [show]); // show - параметр видимости формы дополнительного доступа

  const [loadingElement, setLoadingElement] = useState('');
  const [subServices, setSubServices] = useState([]); // список подсервисов
  const [accessData, setAccessData] = useState([]); // данные доступа текущего пользователя

  // Получить список под_сервисов
  async function getSubServices() {
    try {
      const reqData = {
        type: 'getSubServices',
        table: subServicesTable,
      };
      const result = await clientSendData('POST', '/get_sub_services', reqData); // запрос в БД
      if (result) setSubServices(result); // запись в состояние
    } catch (error) {
      catchHandler(error, 'getSubServices'); // обработчик ошибок
    }
  }

  // Получить данные доступа к под_сервисам
  async function getSubAccessData() {
    try {
      // Получение настроек доступа из БД и запись в хранилище
      const reqData = {
        type: 'getSubAccessData',
        table: subServicesAccessTable, // название таблицы с доступами
        user_id, // id пользователя
        service_id, // id сервиса
      };
      const result = await clientSendData('POST', '/get_sub_access_data', reqData);
      if (result) setAccessData(result);
    } catch (error) {
      catchHandler(error, 'getSubAccessData');
    }
  }

  // Функция, которая проверяет значение тоггла
  // В качестве аргумента принимает id под_сервиса
  const isAccess = (subServiceId) => {
    try {
      // Если массив данных доступа не пустой
      if (accessData.length > 0) {
        // Выполняется поиск объекта, в котором:
        const findAccess = accessData.find((ad) => (
          // - id пользователя равен id пользователя переданному в компонент SubAccessForm,
          // - id сервиса равен id сервиса из аргумента
          (ad.user_id === user_id) && (ad[subServiceField] === subServiceId)
        ));

        // Если такой объект найден  - возвращается значение доступа из этого объекта
        if (findAccess) return Boolean(findAccess?.access);
        return false;
      }
      return false;
    } catch (error) {
      catchHandler(error, 'isAccess');
      return false;
    }
  };

  // Обработчик изменений в форме
  async function toggleHandler(subService) {
    try {
      // поиск редактируемого параметра в текущих настройках доступа пользователя
      const findAccess = accessData.find((row) => (
        (row.user_id === user_id) && (row[subServiceField] === subService.id)
      ));

      const reqData = {
        type: 'toggleSubAccess',
        user_id, // id пользователя, которому редактируется доступ
        table: subServicesAccessTable, // таблица доступов к дочерним сервисам
        sub_service_id: subService.id, // id дочернего сервиса
        sub_service_field: subServiceField, // наименование поля с id дочернего сервиса в таблице доступов
        sub_service_unique: subServicesAccessUnique, // наименование уникального ключа в таблице доступов
        resource_id: service_id, // id родительского сервиса
        access: findAccess ? findAccess.access : null, // значение доступа
      };

      setLoadingElement(subService.id);
      const result = await clientSendData('POST', '/toggle_sub_access', reqData); // зпрос в БД
      if (result === 'bad_request') {
        alertFunction('bad_request', 'clientPost'); // Уведомление Некорректный запрос
        setLoadingElement('');
      } else if (result === 'success') {
        setLoadingElement('');
        getSubAccessData();
        alertFunction('save_settings', 'clientPost'); // Уведомление Настройки сохранены
      }
    } catch (error) {
      catchHandler(error, 'toggleHandler');
      setLoadingElement('');
    }
  }

  return (
    <div className={`access-form access-form__sub-form_${show ? 'enabled' : 'disabled'}`}>
      {subServices.map((ss) => {
        const { id, title, name } = ss;
        if (isAdmin) { // если смотрит админ - показать строку с управляемым тогглом
          return (
            <div key={id} className="access-form__row">
              <span className="access-form__toggle-label">{title || name}</span>
              {loadingElement === id ? <Loader /> : (
              <Toggle state={String(isAccess(id))} onClick={() => toggleHandler(ss)} />)}
            </div>
          );
        } // иначе показать строку с disabled тогглом если есть доступ
        if (isAccess(id)) {
          return (
            <div key={id} className="access-form__row">
              <span className="access-form__toggle-label">{title || name}</span>
              <Toggle state="true" disabled />
            </div>
          );
        } return null;
      })}
    </div>
  );
}

export default SubAccessForm;
