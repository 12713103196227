import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { clientSendData } from '../../../utils/functions/requests';
import { catchHandler } from '../../../utils/error_handling/error_handling';
import ServiceBody from '../../../components/Service/ServiceBody';
import Button from '../../../components/UI/Button/Button';
import Table from '../../../components/Table/Table';
import THead from '../../../components/Table/THead';
import TBody from '../../../components/Table/TBody';
import TFoot from '../../../components/Table/TFoot';
import TRow from '../../../components/Table/TRow';
import TData from '../../../components/Table/TData';
import Loader from '../../../components/UI/Loader';
import ServicePanel from '../../../components/Service/ServicePanel';
import PaginatedTable from '../../../components/Table/PaginatedTable/PaginatedTable';

// Сервис оценок - Интерфейс заполнения проектов
function ApproverInfo() {
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [projects, setProjects] = useState(null); // проекты связанные с сотрудником
  const [searchParams, setSearchParams] = useSearchParams(); // параметры поиска url

  const headers = [ // заголовки
    { id: 1, title: 'Клиент', field: 'client_title' },
    { id: 2, title: 'Проект', field: 'project_title' },
    { id: 3, title: 'Сотрудник', field: 'employee_title' },
    { id: 4, title: 'Руководитель', field: 'approver_title' },
    { id: 5, title: 'Партнер', field: 'partner_title' },
    { id: 6, title: 'Директор', field: 'director_title' },
    { id: 7, title: 'Менеджер', field: 'manager_title' },
    { id: 8, title: 'Кол-во часов', field: 'hours_amount' },
    { id: 9, title: 'Статус', field: 'status_title' },
    { id: 10, title: 'Действие', field: 'action' },
  ];

  const defaultTableState = {
    title: 'Мои проекты',
    page: 1,
    limit: 50, // количество строк в таблице
    switch_data: {
      current: 'active',
      titles: {
        all: 'Все',
        active: 'Активные',
      },
    },
  };
  const [tableState, setTableState] = useState(defaultTableState);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    findmyProjsApplications();
  }, [tableState]);

  async function findmyProjsApplications() {
    try {
      const reqData = {
        type: 'findmyProjsApplications',
        is_approver: true,
        tableState,
      };
      const result = await clientSendData('POST', '/find_myProjs_applications', reqData); // запрос в БД
      setProjects(result);
      setTotal(result.length);
    } catch (error) {
      catchHandler(error, 'findApplications'); // обработка ошибок
    }
  }

  // Поиск заявлений
  async function getApproverAddInfo() {
    try {
      setIsLoading(true);
      const reqData = { type: 'findProjectEvs', is_approver: true, tableState };
      const result = await clientSendData('POST', '/find_project_evs', reqData); // запрос в БД
      if (result) setProjects(result);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      catchHandler(error, 'findProjectEvs'); // обработка ошибок
    }
  }

  // Открыть заявление
  function openApplication(id) {
    setSearchParams({ app: id });
  }

  // Определение отображения на основе исходного значения
  const defineValue = (field, value) => {
    if (field === 'type') {
      switch (value) {
        case 'leadPartn': return 'Главный партнёр';
        case 'execPartn': return 'Исполняющий партнёр';
        case 'director': return 'Директор';
        case 'manager': return 'Менеджер';
        default: return 'Не определено';
      }
    } else if (field === 'is_closed') {
      return value ? 'В работе' : 'Завершён';
    } else return value;
  };

  return (
    <ServiceBody>
      {/* <div>Инфо о сотруднике</div> */}
      {isLoading ? <Loader /> : (

        <PaginatedTable
          id="evaluation__employee-table"
          headers={headers}
          setState={setTableState}
          state={tableState}
          button_place="row"
          total={total}
        >
          {/* Если нет результатов */}
          {projects?.length === 0 ? (
          <TRow>
            {headers.map((item, index) => (
              <TData key={item.id}>
                {index === 0 ? 'Результаты отсутствуют' : ''}
              </TData>
            ))}
            <TData />
          </TRow>) : (
            projects?.map((row) => ( // Если результаты есть
            <TRow key={row.id}>
              {headers.map((item) => {
                const { field } = item;
                if (item.field !== 'action') return <TData key={item.id}>{row[item.field] ? row[item.field] : '-'}</TData>;
                return <>
                <TData key={item.id} onClick={() => openApplication(row.id)}>Посмотреть</TData>
                <TData />
                       </>;
              })}
            </TRow>
            )))}
        </PaginatedTable>)}
    </ServiceBody>
  );
}

export default ApproverInfo;
