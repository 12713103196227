import React, { useState } from 'react';
import { catchHandler } from '../../utils/error_handling/error_handling.js';
import { clientSendData } from '../../utils/functions/requests.js';
import { alertFunction } from '../../utils/functions/alertFunction.js';
import Button from '../UI/Button/Button.jsx';
import InputFile from '../UI/FileUpload/InputFile.jsx';
import { createAction } from '../../utils/redux/store.js';
import { log } from '../../utils/functions/others.js';

/**
 * @component - Окно справочной информации - создание заявки
*/

function TicketCreating() {
  const ticketDefault = {
    title: '',
    content: '',
    attachments: [],
    source_type: 'web_popup',
    status_id: 1,
  };
  const [isLoading, setIsLoading] = useState(false);
  const [ticket, setTicket] = useState(ticketDefault);

  async function createTicket() {
    try {
      log({ message: 'HELP - создание заявки - нажатие кнопки "Отправить"' });
      if (ticket.title && ticket.content) {
        const reqData = {
          type: 'createTicket',
          ticket,
        };
        setIsLoading(true);
        const result = await clientSendData('POST', '/create_ticket/manual', reqData);
        if (result?.success) {
          await alertFunction('message_sended', 'clientPost');
          createAction('TOGGLE_HELP', false);
          setTicket(ticketDefault);
        }
      } else await alertFunction('required_fields', 'clientPost');
    } catch (error) {
      catchHandler(error, 'createTicket');
    } finally {
      setIsLoading(false);
    }
  }

  // Обработка прикрепленного вложения и запись в объект
  async function attachmentHandler(event) {
    try {
      log({ message: 'HELP - создание заявки - нажатие кнопки прикрепления вложений' });
      const files = event.target.files; // файлы
      const readPromises = []; // массив для промисов
      // Пройти по каждому файлу, создать промис и добавить его в массив
      for (const file of files) { readPromises.push(readFileAsDataURL(file)); }
      setIsLoading(true);
      const result = await Promise.all(readPromises); // Дождаться выполнения чтения всех файлов
      setIsLoading(false);
      setTicket((prev) => ({ ...prev, attachments: result })); // Обновить состояние заявки
    } catch (error) {
      catchHandler(error, 'attachmentHandler');
    }
  }

  // Создать промис для асинхронного чтения файла
  function readFileAsDataURL(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const dataURL = event.target.result;
        resolve({
          path: dataURL,
          filename: file.name,
        });
      };
      reader.onerror = (event) => reject(event.target.error);
      reader.readAsDataURL(file);
    });
  }

  function resetAttachment() {
    log({ message: 'HELP - создание заявки - нажатие кнопки сброса выбранных вложений' });
    setTicket((prev) => ({ ...prev, attachments: [] }));
  }

  return (
    <div className="create-ticket">
      <input
        type="text"
        value={ticket?.title || ''}
        onChange={(e) => setTicket((prev) => ({ ...prev, title: e.target.value }))}
        placeholder="Тема обращения"
        className={`input${!ticket.title ? ' input_invalid' : ''}`}
      />
      <textarea
        value={ticket?.content || ''}
        onChange={(e) => setTicket((prev) => ({ ...prev, content: e.target.value }))}
        placeholder="Написать в техподдержку"
        className={`input textarea${!ticket.content ? ' textarea_invalid' : ''}`}
      />
      {ticket.attachments.length > 0 ? (
      <div onClick={resetAttachment} tooltip={ticket.attachments.map((i) => i.filename).join(', ')} className="create-ticket__attachment-button">
        <img src="../../icons/button/cancel.svg" alt="cancel" />
        <span>{ticket.attachments.length}</span>
      </div>) : (
      <InputFile onChange={attachmentHandler} id="create-ticket__attachment" multiple value={ticket?.attachments || '[]'}>
        <div className="create-ticket__attachment-button">
          <img src="../../icons/button/paperclip.svg" alt="paperclip" />
        </div>
      </InputFile>)}

      <Button onClick={createTicket} disabled={isLoading}>Отправить</Button>
    </div>
  );
}

export default TicketCreating;
