import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { catchHandler } from '../../../../../utils/error_handling/error_handling';
import { alertFunction } from '../../../../../utils/functions/alertFunction';
import { setOperInfo, log } from '../../../../../utils/functions/others';
import { clientSendData } from '../../../../../utils/functions/requests';
import { createAction } from '../../../../../utils/redux/store';
import {
  autoReplaceHandler, sortFieldsByScheme, validateObject, сreateNewObject,
} from '../../../Common/functions';
import Button from '../../../../../components/UI/Button/Button';
import TabPanel from '../../../../../components/UI/TabPanel';
import Card from '../../../../../components/Card/Card';
import CardHeader from '../../../../../components/Card/CardHeader';
import CardBody from '../../../../../components/Card/CardBody';
import CardFooter from '../../../../../components/Card/CardFooter';
import TextProcessorTab from './TextProcessorTab';
import History from '../../../Common/History';
import Settings from './Settings';

/**
* @component Управление Базой знаний - Темы - Карточка вопроса
* @prop {id} function - id выбранного вопроса
* @prop {setShowCard} function - Обновление состояния отображения карточки (открыть/закрыть)
* @prop {parentTheme} object - Объект  темы (при вызове карточки вопроса из карточки темы)
* @prop {setRefreshQuestions} function - Обновление состояния параметра, запускающего обновление вопросов (перемычка)
*/

function QuestionCard(props) {
  const {
    id,
    setShowCard,
    parentTheme,
    setRefreshQuestions,
  } = props; // свойства передаваемые компоненту при вызове

  const currentDivision = useSelector((state) => state.kb.current_division); // выбранное подразделение
  const scheme = useSelector((state) => state.kb.active_scheme.question?.scheme); // активная схема вопроса
  const isTPOpen = useSelector((state) => state.kb.is_tp_open); // состояние активности текстового процессора
  const questionTypeId = useSelector((state) => state.kb.essence_types_id.question); // id типа сущности вопроса
  const [questionInitial, setQuestionInitial] = useState(null); // текущий вопрос (исходные данные)
  const [question, setQuestion] = useState(null); // текущий вопрос
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [choice, setChoice] = useState(''); // состояние выбора вкладки
  const isNewQuestion = id === 'new'; // вопрос создаётся

  // Массив наименований полей в которых необходимо показывать текстовый процессор
  let textProcessorFields = Object.keys(scheme).filter((item) => scheme[item].type === 'html');

  // Сортировка полей в зависимости от параметра order
  textProcessorFields = sortFieldsByScheme({
    scheme,
    key: 'order',
    fields: textProcessorFields,
  });
  // Массив заголовков полей в которых необходимо показывать текстовый процессор
  const textProcessorTabs = textProcessorFields.map((item) => scheme[item].name);
  const tabs = ['Настройки', ...textProcessorTabs, !isNewQuestion && 'История']; // вкладки

  // Отменить редактирование или создание и закрыть карточку
  function closeHandler() {
    log({ message: `Пользователь закрыл карточку вопроса id: ${id}`, eventtype: 'info' });
    setOperInfo({ subject_id: null }); // сброс id категории в оперативной информацию
    setShowCard(false);
  }

  // Обработчик выбора вкладки
  function choiceHandler(tab) {
    // если открыт текстовый редактор всплывает запрос на подтверждение
    if (isTPOpen) {
      const confirm = window.confirm('Все несохранённые данные будут утеряны! Всё равно перейти?');
      if (confirm) { // если пользователь подтвержает
        setChoice(tab); // меняется состояние выбора
        createAction('SET_IS_TP_OPEN', false); // выкл состояние активности текстового процессора
      } // если нажал "Отмена" - выход из функции
    } else {
      setChoice(tab); // если редактор закрыт - то просто переключается вкладка
      log({ message: `Пользователь в карточке вопроса id: ${id} перешел на вкладку ${tab}`, eventtype: 'info' });
    }
  }

  useEffect(() => {
    awaitRequests();
  }, []);

  // Ожидание выполнения необходимых запросов
  async function awaitRequests() {
    try {
      setOperInfo({ subject_id: id }); // запись id вопроса в оперативную информацию
      setIsLoading(true); // состояние - загружается
      if (id === 'new') { // если вопрос создаётся
        let newQuestion = { id: 'new' };
        // если в карточку передана родительская тема
        if (parentTheme) newQuestion = сreateNewObject(parentTheme, 'theme', 'question');
        setQuestion(newQuestion);
        setIsLoading(false); // загрузка завершена
      } else {
        await getQuestionKb(); // получить данные вопроса
        setIsLoading(false); // загрузка завершена
      }
    } catch (error) {
      setIsLoading(false); // загрузка завершена
      catchHandler(error, 'awaitRequests');
    }
  }

  // Получить данные вопроса
  async function getQuestionKb() {
    try {
      const reqData = {
        type: 'getQuestionKb',
        question_id: id,
        scheme, // Текущая схема
        location: window.location.pathname,
      };

      setIsLoading(true); // состояние - загружается
      const result = await clientSendData('POST', '/get_question_kb', reqData);

      if (result === 'bad_request') { // если некорректный запрос
        alertFunction('bad_request', 'clientPost'); // уведомление
        setIsLoading(false); // загрузка завершена
      } else {
        setQuestion(result);
        setQuestionInitial(result);
        setIsLoading(false); // загрузка завершена
      }
    } catch (error) {
      catchHandler(error, 'getQuestionKb');
      setIsLoading(false);
    }
  }

  // Обрабочик кнопки "Сохранить"
  function saveHandler() {
    const action = question.id === 'new' ? 'create' : 'edit'; // action  определяется исходя из id темы
    changeQuestion(action);
  }

  // Подготовка темы перед отправкой в бд
  function prepareQuestion(questionObject) {
    let copy = { ...questionObject };

    // 1) обработка авто замен
    copy = autoReplaceHandler(scheme, copy);

    // 2) Экранировать одинарные кавычки
    Object.keys(scheme).forEach((key) => { // пройти по ключам схемы
      const keyData = scheme[key]; // настройки ключа
      if (keyData?.type === 'html') { // если тип данных html
        // Если html поле содержит данные - экранировать одинарные кавычки, иначе вернуть null
        const jsonData = copy[key] ? copy[key]?.replaceAll("'", "''") : null;
        copy[key] = jsonData; // обновить данные объекта
      }
    });

    return copy;
  }

  // Сохранить изменения вопроса
  // action - create/edit/delete
  async function changeQuestion(action) {
    try {
      // Если не пройдена валидация на основании действия - уведомление
      if (!validateObject(action, scheme, question)) alertFunction('required_fields', 'clientPost');
      else {
        let message = '';
        if (action === 'create') message += 'Создать вопрос?';
        if (action === 'edit') message += 'Сохранить изменения?';
        if (action === 'delete') message += 'Удалить вопрос?';
        const confirm = window.confirm(message); // подтверждение

        if (confirm) {
          const reqData = {
            type: 'editQuestionKb',
            question: prepareQuestion(question),
            action,
            division_id: currentDivision.id,
            scheme,
          };
          setIsLoading(true); // состояние - загружается
          const result = await clientSendData('POST', '/edit_question_kb', reqData);

          if (result === 'success') { // если успех
            alertFunction('save_settings', 'clientPost'); // уведомление - настройки сохранены
            if (action === 'edit') await getQuestionKb(); // обновить информацию о вопросе
            setRefreshQuestions(new Date()); // обновить состояние параметра, запускающего обновление вопросов
            if (action === 'create' || action === 'delete') setShowCard(false); // если тема создается или удаляется - закрыть карточку
            log({ message: `Пользователь ${action === 'delete' ? `удалил вопрос id: ${id}` : `сохранил  ${action === 'edit' ? `изменения в карточке вопроса id: ${id}` : 'новый вопрос'}`}`, eventtype: 'info' });
          }
          setIsLoading(false); // загрузка завершена
        }
      }
    } catch (error) {
      catchHandler(error, 'changeQuestion');
      setIsLoading(false);
    }
  }

  // Визуальная валидация вкладок с текстовым процессором
  const validateTab = (tab) => {
    const field = Object.keys(scheme).find((key) => scheme[key]?.name === tab);
    if (scheme[field]?.required && !question?.[field]) return false; // "tab-panel__tab tab-panel__tab_invalid"
    return true; // "tab-panel__tab"
  };

  return (
    <Card
      id="knowledge-base-questions__question-card"
      setShow={setShowCard}
      loading={isLoading}
      hideCloseButton
    >
      <CardHeader>
        <TabPanel
          tabs={tabs}
          choice={choice}
          choiceHandler={choiceHandler}
          validator={validateTab}
        />
      </CardHeader>

      <CardBody>
        {/* Вкладка Настройки */}
        {choice === 'Настройки' && (
        <Settings
          question={question}
          initialObject={questionInitial}
          setQuestion={setQuestion}
          changeQuestion={changeQuestion}
        />)}

        {/* Вкладки с текстовым процессором */}
        {textProcessorTabs.map((tab) => {
          if (choice === tab) { // если выбрана вкладка
            // Найти наименование ключа схемы по значению дочернего ключа
            const field = Object.keys(scheme).find((item) => scheme[item].name === tab);
            return ( // отображается вкладка с текстовым процессором
              <TextProcessorTab
                key={tab}
                field={field}
                question={question}
                setQuestion={setQuestion}
              />
            );
          } return null;
        })}

        {/* Вкладка Истории */}
        {choice === 'История' && (
        <History
          id={id}
          type={questionTypeId}
        />)}
      </CardBody>

      {!isTPOpen && (
      <CardFooter>
        <Button onClick={closeHandler}>Закрыть</Button>
        <Button onClick={saveHandler}>Сохранить</Button>
      </CardFooter>)}
    </Card>
  );
}

export default QuestionCard;
