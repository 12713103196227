import React, { useEffect, useState } from 'react';
import { stringMatch } from '../../../utils/functions/stringHandling';
import { clientSendData } from '../../../utils/functions/requests';
import { catchHandler } from '../../../utils/error_handling/error_handling';
import { alertFunction } from '../../../utils/functions/alertFunction';
import Card from '../../../components/Card/Card';
import CardHeader from '../../../components/Card/CardHeader';
import CardBody from '../../../components/Card/CardBody';
import CardFooter from '../../../components/Card/CardFooter';
import Button from '../../../components/UI/Button/Button';
import TagsList from '../../../components/UI/InputFit/Tags/TagsList';
import InputSearch from '../../../components/UI/InputSearch';
import CardSetting from '../../../components/Card/CardSetting';
import Toggle from '../../../components/UI/Toggle';
import classes from '../../../components/UI/Button/button.module.scss';
import Loader from '../../../components/UI/Loader';

/**
 * @component Управление массовым доступом - карточка настройки доступа
 * @prop {service} number - id сервиса из upu_resources
 * @prop {setShow} function - функция обновления состояния отображения карточки (открыть/закрыть)
*/
function BulkAccess(props) {
  const {
    service, // id сервиса
    setShow, // закрыть форму
  } = props;

  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [users, setUsers] = useState([]); // все пользователи
  const [foundedUsers, setFoundedUsers] = useState([]); // найденные пользователи
  const [selectedUsers, sedSelectedUsers] = useState([]); // выбранные пользователи
  const [isAllSelected, setIsAllSelected] = useState(false); // выбраны все?
  const fields = ['id', 'cn', 'objectsid']; // поля для запроса данных о пользователях

  useEffect(() => {
    getUsersData();
  }, []);

  async function getUsersData() {
    try {
      const reqData = {
        type: 'getUsersShortData',
        fields,
      };
      const result = await clientSendData('POST', '/get_users_short_data', reqData);
      if (result) setUsers(result);
    } catch (error) {
      catchHandler(error, 'getUsersData');
    }
  }

  // Дать доступ пользователям к сервису
  async function giveBulkAccess(serviceId, users, access) {
    try {
      const reqData = {
        type: 'giveBulkAccess',
        service_id: serviceId,
        users,
        access,
      };
      const result = await clientSendData('POST', '/give_bulk_access', reqData);
      if (result === 'success') alertFunction('save_settings', 'clientPost');
    } catch (error) {
      catchHandler(error, 'giveBulkAccess');
    }
  }

  // Добавить выбранного пользователя
  function addUser(user) {
    // убрать пользователя из существующего массива (если есть)
    let newArray = selectedUsers.filter((item) => item.id !== user.id);
    newArray = [...newArray, user]; // добавить его в конец
    sedSelectedUsers(newArray); // обновить состояние массива
  }

  // Переключить доступ выбранным пользователям (или всем)
  async function toggleAccess(access) {
    // Если пользователи не выбраны - уведомление
    if (!isAllSelected && selectedUsers.length === 0) alertFunction('choose_users', 'clientPost');
    else {
      // Тексты подтверждений
      const enableMessage = `Предоставить доступ ${isAllSelected ? 'всем' : 'выбранным'} пользователям?`;
      const disableMessage = `Отозвать доступ у ${isAllSelected ? 'всех' : 'выбранных'} пользователей?`;
      const message = access ? enableMessage : disableMessage;
      const confirm = window.confirm(message);
      if (confirm) { // если админ подтверждает операцию
        // передаем в функцию переключения доступа массив пользователей (все или выбранные)
        const userList = isAllSelected ? users : selectedUsers;
        setIsLoading(true);
        await giveBulkAccess(service.id, userList, access);
        setIsLoading(false);
        setShow(false);
      }
    }
  }

  // Добавить найденных пользователей
  function addFoundedUsers(users) {
    // Отфильтровать массив добавленных пользователей - оставить только тех, кто не найден в users
    let newArray = selectedUsers.filter((item) => !users.find((u) => u.id === item.id));
    newArray = [...newArray, ...users]; // Добавить найденных пользователей в конец
    sedSelectedUsers(newArray); // обновить состояние массива
  }

  // Удалить найденного пользователя
  function removeFoundedUser(user) {
    sedSelectedUsers(selectedUsers.filter((item) => item.id !== user.id));
  }

  // Поиск пользователя
  function searchUser(e) {
    try {
      // Если не введено ничего массив обнуляется
      if (!e.target.value) setFoundedUsers([]);
      else {
        // Результат поиска - это отфильтрованный исходный массив, в котором искомая строка содержится в элементах массива
        let searchResult = users.filter((c) => stringMatch(e.target.value, c.cn)); // поиск
        // если есть результат - он показывается
        if (searchResult.length > 0) {
          // Добавить в объекты массива ключ title для корректоного отображения в InputSearch
          searchResult = searchResult.map((user) => ({
            id: user.id,
            title: user.cn,
            objectsid: user.objectsid,
          }));
          setFoundedUsers(searchResult);
          // иначе - сброс поиска
        } else setFoundedUsers([]);
      }
    } catch (error) {
      catchHandler(error, 'searchUser'); // обработка ошибок
    }
  }

  return (
    <Card id="admin-panel__bulk-access" setShow={setShow}>
      <CardHeader>
          <h3>{service.title}</h3>
      </CardHeader>

      <CardBody>
        <CardSetting title="Выбрать всех пользователей">
          <Toggle
            state={String(isAllSelected)}
            onClick={() => setIsAllSelected(!isAllSelected)}
          />
        </CardSetting>

        {!isAllSelected && (
        <CardSetting title="Выберите пользователей">
          <InputSearch
            id="services__search-users"
            array={foundedUsers}
            onSearch={searchUser}
            onChoose={addUser}
            onPressEnter={addFoundedUsers}
            placeholder="Поиск..."
          />
        </CardSetting>)}

        {!isAllSelected && selectedUsers.length > 0 && (
        <CardSetting title="Сбросить выбранных">
          <Button onClick={() => sedSelectedUsers([])} className={`${classes.button_ghost} ${classes.small}`}>
            Сбросить
          </Button>
        </CardSetting>)}

        {!isAllSelected && (
        <TagsList
          array={selectedUsers}
          onDelete={removeFoundedUser}
        />)}
      </CardBody>
      {isLoading ? <Loader /> : (
      <CardFooter>
        <Button onClick={() => toggleAccess(true)}>Предоставить</Button>
        <Button onClick={() => toggleAccess(false)}>Отозвать</Button>
      </CardFooter>)}
    </Card>
  );
}

export default BulkAccess;
