import React, { useEffect, useState } from 'react';
import CardBody from '../../components/Card/CardBody';
import { alertFunction } from '../../utils/functions/alertFunction';
import { catchHandler } from '../../utils/error_handling/error_handling';
import { clientSendData } from '../../utils/functions/requests';
import CardHeader from '../../components/Card/CardHeader';
import Card from '../../components/Card/Card';
import CardSetting from '../../components/Card/CardSetting';
import Input from '../../components/UI/Input';
import TextArea from '../../components/UI/TextArea';
import Select from '../../components/UI/Select';
import InputSearch from '../../components/UI/InputSearch';
import CardFooter from '../../components/Card/CardFooter';
import Button from '../../components/UI/Button/Button';

/**
  * @component Клиентский портал - новый запрос - карточка создания запроса проекта
  * @prop {setNewShowCard} function - обновление состояния отображения карточки (открыть/закрыть)
  * @prop {refreshTable} function - обновление данных таблицы
*/
function NewProjectCard(props) {
  const { setNewShowCard, refreshTable } = props;

  const [project, setProject] = useState({}); // объект с данными заявки по проекту
  const isNewMeeting = 'new';
  const [isLoading, setIsLoading] = useState(false); // состояние выбора вкладки
  const [selectUsers, setSelectUsers] = useState({});
  const [isSearching, setIsSearching] = useState(false); // состояние загрузки при поиске
  const [users, setUsers] = useState([]);
  const [data, setData] = useState({}); // объект с дополнительными данными
  const [participiants, setParticipiants] = useState([]); // участники
  // const [isSearching, setIsSearching] = useState(false); // состояние загрузки при поиске
  const projectNames = [
    { id: 1, title: 'Проект A' },
    { id: 2, title: 'Проект B' },
    { id: 3, title: 'Проект C' },
  ];

  const partners = [// список проектов
    { title: 'Ефремова', id: 1 },
    { title: 'Ефремов', id: 2 },
    { title: 'Хромова', id: 1 },
    { title: 'Тарадов', id: 2 },

  ];

  const questionaries = [// список опросников
    { title: 'Опросник 1', id: 1 },
    { title: 'Опросник 2', id: 2 },
    { title: 'Свой опросник', id: 3 },

  ];

  function createProjectCard() {
    console.log(project);
  }

  // закрыть карточку
  function closeHandler() {
    setNewShowCard(false); // закрытие карточки
  }

  // Добавление пользователей
  async function addParticipiants(participiant) {
    if (Object.keys(participiant).length > 0) {
      if (participiants.filter((el) => el.id === participiant.id).length === 0) {
        setParticipiants((state) => [...state, participiant]);
        const newUsers = Array.isArray(data.users) ? [...data.users] : [];
        newUsers.push(participiant.mail); // Безопасное добавление
        setData((state) => ({ ...state, users: newUsers }));
        setSelectUsers({});
      }
    }
  }

  async function deleteParticipiants(usersId) {
    const userEmail = participiants.find((el) => el.id === usersId);
    const filteredUsers = data.users.filter((el) => el !== userEmail.mail);
    setData((state) => ({ ...state, users: filteredUsers }));
    setParticipiants(participiants.filter((el) => el.id !== usersId));
  }

  async function searchWrapper(e) {
    setIsSearching(true); // поиск выполняется
    if (e.target.value.length === 0) setSelectUsers((prev) => ({ ...prev }));
    try {
      if (e.target.value.length > 2) {
        const reqData = {
          type: 'vcMeetingSearchUser',
          query: e.target.value,
        };
        const result = await clientSendData('POST', '/vc_meeting_search_user', reqData);
        setSelectUsers({ id: e.target.value, title: e.target.value, mail: e.target.value });
        if (result?.success) setUsers(result.data);
        setIsSearching(false);
        return [];
      }
      return [];
    } catch (error) {
      catchHandler(error, 'vcMeetingSearchUser');
      setIsSearching(false);
      return [];
    }
  }

  return (
    <Card id="clients_portal__new-project-card" setShow={closeHandler} loading={isLoading}>
      <CardHeader />
      <CardBody>
        {/* -----------Проект----------- */}
        <CardSetting title="Наименование проекта:" invalid={!project.projectName_id}>
          <Select
            id="clients_portal__new-project-name"
            array={projectNames}
            onChoose={(projectName) => setProject((state) => ({ ...state, projectName_id: projectName.id, projectName: projectName.title }))}
            defaultValue={project.projectName || 'Выберите проект'}
            // loading={isSearching}
          />
        </CardSetting>

                {/* -----------Партнер----------- */}
                <CardSetting title="Партнер:" invalid={!project.partner_id}>
          <Select
            id="clients_portal__new-project-partner"
            array={partners}
            onChoose={(partner) => setProject((state) => ({ ...state, partner_id: partner.id, partner: partner.title }))}
            defaultValue={project.partner || 'Выберите партнера'}
            // loading={isSearching}
          />
                </CardSetting>

        {/* -----------Срок датарума----------- */}
        <CardSetting title="Срок датарума:" invalid={!project.date_end}>
          <Input
            id="helpdesk__new-ticket-search-initiator"
            type="date"
            onChoose={(date) => setProject((state) => ({ ...state, date_end: date }))}
          />
        </CardSetting>

        {/* -----------Опросник----------- */}
        <CardSetting title="Опросник:" invalid={!project.questionary_id}>
          <Select
            id="clients_portal__new-project-questionary"
            array={questionaries}
            onChoose={(questionary) => setProject((state) => ({ ...state, questionary_id: questionary.id, questionary: questionary.title }))}
            defaultValue={project.questionary || 'Выберите опросник'}
            // loading={isSearching}
          />
        </CardSetting>
         {/* -----------Пользователи----------- */}
        <CardSetting title="Пользователи" invalid={!participiants.length}>
            {isNewMeeting ? (
                <>
                    <div className="tags__input-block">
                    <InputSearch
                      id="inputSearch"
                      placeholder="Введите пользователя Юникона или email внешнего пользователя"
                      array={users}
                      onSearch={(e) => searchWrapper(e)}
                      onChoose={(user) => setSelectUsers(user)}
                      defaultValue={selectUsers?.title}
                    />
                    <div onClick={() => (addParticipiants(selectUsers))} className="tags__button">
                        <img className="tags__img" src="../../icons/cancel.svg" alt="add-tag" />
                    </div>
                    </div>
                    <div className="tags__list">
                    {participiants?.map((el) => <p className="tags__tag" key={el.id}>
                        <span onClick={() => deleteParticipiants(el.id)} className="upu__close">X</span>
                        {el.title}
                                                </p>)}
                    </div>
                </>
            ) : (
              participiants?.map((el, index) => el.title + (index !== participiants.length - 1 ? ', ' : ''))
            )}
        </CardSetting>

      </CardBody>
      <CardFooter>
        <Button onClick={createProjectCard}>Создать</Button>
        <Button onClick={closeHandler}>Закрыть</Button>
      </CardFooter>
    </Card>
  );
}

export default NewProjectCard;
