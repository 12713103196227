import React from 'react';
import './basic.scss';

/**
* @component Универсальная обертка для элементов с управляемым flexFlow
  @prop {flexFlow} string необходимое значение CSS свойста flex-flow
*/

function Wrapper(props) {
  const { children, flexFlow } = props;
  return (
    <div className="wrapper" style={{ flexFlow }}>
      {children}
    </div>
  );
}

export default Wrapper;
