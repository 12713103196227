import React, { useEffect, useState } from 'react';
import { catchHandler } from '../../../utils/error_handling/error_handling';
import { clientSendData } from '../../../utils/functions/requests';
import ObjectDisplay from '../../../components/Card/ObjectDisplay';
import Loader from '../../../components/UI/Loader';

/**
* @component Система контроля задач - карточка задачи - вкладка "История"
* @prop {id} number - id задачи
*/
function Logs(props) {
  const { id } = props;
  const [logs, setLogs] = useState([]); // история
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки

  useEffect(() => {
    getTaskHistory(); // получить логи
  }, []);

  async function getTaskHistory() {
    try {
      const reqData = {
        type: 'getTaskHistory',
        task_id: id,
      };
      setIsLoading(true); // состояние - загружается
      const result = await clientSendData('POST', '/get_task_history', reqData);
      if (result) setLogs(result);
      setIsLoading(false); // загрузка завершена
    } catch (error) {
      catchHandler(error, 'getTaskHistory');
      setIsLoading(false); // загрузка завершена
    }
  }

  // если загружается - показывается лоадер
  if (isLoading) return <Loader />;
  // Если логи пустые - показывается заголовок об их отсутствии
  if (logs.length === 0) return <h2 className="upu-service__title">История отсутствует</h2>;
  // Иначе отображается история
  return (
    logs.map((entry) => (
      <div key={entry.id} className="question-history">
        <ObjectDisplay object={entry.object} />
      </div>
    ))
  );
}

export default Logs;
