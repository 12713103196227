import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { catchHandler } from '../../utils/error_handling/error_handling';
import { authorization } from '../../utils/functions/authenticate';
import { setOperInfo } from '../../utils/functions/others';
import { clientSendData } from '../../utils/functions/requests';
import { createAction } from '../../utils/redux/store';
import { getQuestionnairies, getUserDirections } from '../Questionnaire/functions';
import Service from '../../components/Service/Service';
import ServiceBody from '../../components/Service/ServiceBody';
import BasicReport from './BasicReport/BasicReport';
import ExtReport from './ExtReport/ExtReport';
import Loader from '../../components/UI/Loader';
import Cap from '../Cap/Cap';
import './reports.scss';

/**
* @component Сервис отчётов анкетирования - главная страница
* Просмотр отчётов по опросам из системы анкетирования
* Базовый отчёт доступен по любой анкете, расширенный только по Выходному интервью
*/
function Reports() {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false); // Состояние авторизации
  const [isAuthorized, setIsAuthorized] = useState(false); // Состояние авторизации
  const [searchParams, setSearchParams] = useSearchParams(); // параметры поиска из адресной строки (id сотрудника)
  const currentComponent = useSelector((state) => state.reports.current_component); // текущий отображаемый компонент

  useEffect(() => {
    setOperInfo();
    getServiceInfo(); // получение информации по сервису
    createAction('SET_SHOW_CHART', false);
  }, [location]);

  // Функция запроса информации по сервису
  async function getServiceInfo() {
    setIsLoading(true);
    const checkAuthorization = await authorization(); // авторизация
    setIsAuthorized(checkAuthorization); // обновление состояния авторизации
    if (checkAuthorization) {
      await getUserDirections(location.pathname); // получение информации о направлениях доступных пользователю
      await getQuestionnairies(location.pathname); // получить анкеты по доступным направлениям
      await checkReportAccess(); // проверка доступа к отчёту
    }
    setIsLoading(false);
  }

  // проверка доступа к отчёту
  async function checkReportAccess() {
    try {
      const reportId = searchParams.get('report');

      if (reportId) { // если есть id отчёта - проверяется доступ к нему
        const reqData = {
          type: 'checkReportAccess',
          report_id: reportId, // значение из параметров поиска
          location: location.pathname, // источник
        };
        const result = await clientSendData('POST', '/check_report_access', reqData);
        if (result) {
          const access = result.length > 0; // доступ к отчёту
          createAction('SET_EXT_REPORT_ACCESS', access); // обновить данные в хранилище
          const showComponent = access ? 'ext' : ''; // если есть доступ, покажется расширенный отчет, иначе предложение перейти к базовым
          createAction('SET_CURRENT_COMPONENT', showComponent); // обновить отображаемый компонент
        }
      } else { // иначе
        createAction('SET_CURRENT_COMPONENT', 'basic'); // показываем базовый отчёт
      }
    } catch (error) {
      catchHandler(error, 'checkReportAccess');
    }
  }

  // Перейти в базовые отчёты
  function goToBasic() {
    setSearchParams(''); // сбросить параметры поиска
    createAction('SET_CURRENT_COMPONENT', 'basic'); // обновить отображаемый компонент
  }

  if (isLoading) return <Loader />; // если загружается - показывается лоадер
  if (isAuthorized) { // если авторизован
    if (currentComponent === 'basic') return <BasicReport />; // если basic - показывается базовый отчет
    if (currentComponent === 'ext') return <ExtReport />; // если ext - показывается расширенный отчет
    return ( // иначе сообщение об отсутствии доступа и предложение перейти в базовые отчёты
      <Service id="reports__cap">
        <ServiceBody>
          <h2 className="upu-service__title">К сожалению, данный отчёт Вам недоступен</h2>
          <p className="upu-service__title">
            проверьте
            <span className="clickable" onClick={goToBasic}>{' базовые отчёты'}</span>
          </p>
        </ServiceBody>
      </Service>
    );
  } return <Cap />; // иначе заглушка
}

export default Reports;
