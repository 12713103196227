import React, { useEffect, useState } from 'react';
import { setOperInfo } from '../../utils/functions/others';
import ServicePanel from './ServicePanel';
import Button from '../UI/Button/Button';
import Content from './Content';
import classes from '../UI/Button/button.module.scss';
import './service.scss';

/**
 * @component - Обертка для сервиса
 * @prop {id} string - идентификатор элемента HTML
 * @prop {Page[]} array - массив вкладок для отображения с неободимыми компонентами
 * @typedef Page
 * @property {number} id - id страницы
 * @property {string} title - название сервиса
 * @property {ReactComponent} component - компонент для рендера
 * @property {string} component_name - название комонента
 * @property {boolean} active - выбрать для рендера по умолчанию
*/

function Service(props) {
  const {
    id, pages, noChoice, vertical, horizontal, children,
  } = props;

  const stateView = () => { // состояние отображения
    // Ищем страницу, которую нужно показать по умолчанию
    const defaultPage = pages?.find((page) => page.active);
    // Если ее нет - возвращаем значение из localStorage
    return defaultPage?.title || localStorage.getItem(id);
  };
  const {
    button,
    button_ghost,
    curtain__button_active,
    curtain__button,
  } = classes; // классы стилей для кнопок
  const [choice, setChoice] = useState(stateView()); // Состояние выбора сервиса

  useEffect(() => {
    setOperInfo();
  }, []);

  useEffect(() => {
    setChoice(stateView());
  }, [pages]);

  // Обработчик клика на кнопку
  function onPageChoose(title) {
    setChoice(title); // обновить состояние компонента
    localStorage.setItem(id, title); // записать состояние отображения в localStorage
  }

  // Если нет массива страниц и дочерних элементов - не показывать ничего
  if (!pages && !children) return null;

  // Если страница всего одна - показать её
  if (pages?.length === 1) {
    return (
      <div id={id} className="service">
        {pages[0]?.component}
      </div>
    );
  }
  // Если есть дочерние элементы - показать их
  if (children) {
    return (
      <div id={id} className={vertical ? 'service service-vertical' : 'service'}>
        {children}
      </div>
    );
  }

  // Иначе страниц несколько - показать панель выбора
  // ВЕРТИКАЛЬНАЯ ПАНЕЛЬ
  if (vertical) {
    return (
      <div id={id} className="service service-vertical">
        {/* Панель выбора страниц */}
        <ServicePanel id={`${id}__panel`}>
          {/* Кнопки выбора страниц сервиса */}
          {pages.map((page) => {
            const { title } = page;
            return (
              <Button key={page.id} className={choice === title ? button : button_ghost} onClick={() => onPageChoose(title)}>
                {title}
              </Button>
            );
          })}
        </ServicePanel>
        <Content
          choice={choice}
          pages={pages}
          noChoice={noChoice}
        />
      </div>
    );
    // ГОРИЗОНТАЛЬНАЯ ПАНЕЛЬ
  } if (horizontal) {
    return (
      <div id={id} className="service service-horizontal">
        {/* Панель выбора страниц */}
        <div id={`${id}__panel`} className="service__panel service__panel_active">
          {pages.map((page) => {
            const { title } = page;
            return (
              <Button key={page.id} onClick={() => onPageChoose(title)} className={choice === title ? curtain__button_active : curtain__button}>
                {title}
              </Button>
            );
          })}
        </div>
        <Content
          choice={choice}
          pages={pages}
          noChoice={noChoice}
        />
      </div>
    );
  } return null;
}

export default Service;
