/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { createAction } from '../../utils/redux/store';
import { setOperInfo } from '../../utils/functions/others';
import { authorization } from '../../utils/functions/authenticate';
import { clientSendData } from '../../utils/functions/requests';
import { catchHandler } from '../../utils/error_handling/error_handling';
import Assets from './Assets/Assets';
import Support from './Support/Support';
import User from './User/User';
import Loader from '../../components/UI/Loader';
import Service from '../../components/Service/Service';
import Cap from '../Cap/Cap';
import './helpdesk.scss';
import Consignment from './Consignment/Consignment';

/**
* @component ХелпДеск - сервис службы поддержки
* Имеет 2 интерфейса, доступ к которым настраивается
*/
function HelpDesk() {
  const [isAuthorized, setIsAuthorized] = useState(false); // Состояние авторизации
  const [searchParams, setSearchParams] = useSearchParams(); // параметры поиска в url
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [pages, setPages] = useState([]); // страницы доступные пользователю
  const components = { // Компоненты для отображения в сервисе
    support: <Support />, // Интерфейс службы поддержки (заявки)
    assets: <Assets />, // Интерфейс активов (техники)
    user: <User />, // Интерфейс пользователя
    consignment: <Consignment />, // Требование-накладная
  };

  useEffect(() => {
    setOperInfo();
    awaitRequests();
  }, []);

  useEffect(() => {
    chooseRightComponent(); // Изменить активный компонент при изменении url параметров
  }, [searchParams]);

  async function awaitRequests() {
    setIsLoading(true);
    const checkAuthorization = await authorization(); // авторизация
    setIsAuthorized(checkAuthorization);
    if (checkAuthorization) await getSdInterfaces(); // получить интерфейсы пользователя
    setIsLoading(false);
  }

  // Выбрать компонент при изменении url параметров
  function chooseRightComponent() {
    // Получаем параметры url
    const urlParameters = Array.from(searchParams.keys(), (key) => key);
    // Обновляем массив страниц
    setPages((state) => state.map((page) => {
      // Если в параметрах есть 'ticket' - делаем активной страницу support
      if (['ticket'].includes(urlParameters[0]) && page.component_name === 'support') return { ...page, active: true };
      if (['userTicket'].includes(urlParameters[0]) && page.component_name === 'user') return { ...page, active: true };
      // Если в параметрах есть 'user' или 'asset' - делаем активной страницу assets
      if (['user', 'asset'].includes(urlParameters[0]) && page.component_name === 'assets') return { ...page, active: true };
      // остальные деактивируем
      return { ...page, active: false };
    }));
  }

  // Получить интерфейсы доступные пользователю
  async function getSdInterfaces() {
    try {
      const reqData = {
        type: 'getPsInterfaces',
        location: window.location.pathname,
      };
      const result = await clientSendData('POST', '/get_sd_interfaces', reqData);
      if (result.success) {
        const userPages = []; // Пустой массив для страниц
        result.data.forEach((item) => {
          const component = item?.component; // Имя компонента
          let active = false; // параметр
          // Проверка параметра url
          // Это нужно для того, чтобы при переходе по прямой ссылке на карточку заявки/актива/пользователя
          // автоматически выбирался компонент, которых рендерит эту карточку
          // Параметр active обрабатывается внутри компонента Service
          if ((searchParams?.get('asset') || searchParams?.get('user')) && item.component === 'consignment') active = true;
          if (searchParams?.get('userticket') && item.component === 'user') active = true;
          if (searchParams?.get('ticket') && item.component === 'support') active = true;
          if ((searchParams?.get('asset') || searchParams?.get('user')) && item.component === 'assets') active = true;
          if (components[component]) { // Проверяем, есть ли такой компонент в объекте 'components'
            // Если компонент существует, добавляем страницу с этим компонентом
            userPages.push({
              id: component, // идентификатор страницы
              title: item.title, // заголовок страницы
              component: components[component], // соответствующий компонент
              component_name: component, // имя компонента
              active, // выбор этой страницы по умолчанию
            });
          }
        });
        setPages(userPages); // Результат записывается в состояние
        // Если получены параметры, записать их в Redux
        if (result.parameters) createAction('SET_SD_PARAMETERS', result.parameters);
      }
    } catch (error) {
      catchHandler(error, 'getSdInterfaces');
    }
  }

  if (isLoading) return <Loader />; // если идет загрузка - показать загрузчик
  if (isAuthorized) { // если пользователь авторизован
    // если пользователю не доступен ни один интерфейс - заглушка
    if (pages.length === 0) {
      return (
        <div className="upu-service_padding-top">
          <h2 className="upu-service__title">Доступные интерфейсы отсутствуют</h2>
        </div>
      );
    }
    // иначе показываем то, что доступно пользователю
    return <Service id="helpdesk" pages={pages} horizontal />;
  } return <Cap />; // если пользователь не авторизован - показать заглушку
}

export default HelpDesk;
