import React, { useState } from 'react';
import Button from './Button/Button';
import Select from './Select';
import classes from './Button/button.module.scss';

/**
* @component Выбор вариантов скачивания
@prop {options} array (Обязательный) массив строк с вариантами для селекта
@prop {actions} array (Обязательный) массив объектов с обработчиками
- объект - { title: string, handler: function }
*/

function DownloadChoice(props) {
  const { actions, options } = props;

  // добавление данных для корректого отображения в селекте
  const modifyOptions = options.map((o, i) => ({ id: i, title: o }));

  const [choice, setChoice] = useState(''); // текущий выбор
  const [isActive, setIsActive] = useState(''); // видимость компонента

  return (
    <div className="download-choice">
      {/* Кнопка скрыть/показать */}
      <img onClick={() => setIsActive(!isActive)} className="download-choice__more" src="../../../../../icons/more.svg" alt="more" />
      <div className={`download-choice__wrapper ${isActive ? 'download-choice__wrapper_active' : ''}`}>
        <Select // Выбор опции
          id="download-choice-select"
          array={modifyOptions}
          onChoose={(choose) => setChoice(choose.title)}
          defaultValue="Выберите формат"
        />

        {actions.map((action) => { // Кнопки скачивания
          const { title, handler } = action;
          return (
            <Button key={title} className={classes.button_ghost} onClick={() => handler(choice)}>
              {title}
            </Button>
          );
        })}
      </div>
    </div>
  );
}

export default DownloadChoice;
