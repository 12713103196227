import React, { useState } from 'react';
import Input from '../../UI/Input';
import '../forms.scss';

/**
 * @component Форма выбора периода
  @prop {period} object (Обязательный) Объект периода {from: string, to: string}
  @prop {setPeriod} function (Обязательный) Функция обновления состояния периода
  @prop {vertical} boolean состояние отображения формы дополнительного доступа
*/

function Period(props) {
  const {
    period, setPeriod, children, vertical,
  } = props;

  const [inputTypeStart, setInputTypeStart] = useState('text'); // состояние активности выбора периода
  const [inputTypeEnd, setInputTypeEnd] = useState('text'); // состояние активности выбора периода

  // Форматирование даты
  function formatDate(string_date) {
    if (string_date) return new Date(string_date).toLocaleDateString();
    return '';
  }

  return (
    <div className={`forms__period${vertical ? '_vertical' : ''}`}>
      <Input
        value={inputTypeStart === 'text' ? formatDate(period?.from) : period?.from}
        type={inputTypeStart}
        onBlur={() => setInputTypeStart('text')}
        onFocus={() => setInputTypeStart('date')}
        onChange={(e) => setPeriod({ ...period, from: e.target.value })}
        placeholder={period?.from ? formatDate(period.from) : 'От'}
      />

      <Input
        value={inputTypeEnd === 'text' ? formatDate(period?.to) : period?.to}
        type={inputTypeEnd}
        onBlur={() => setInputTypeEnd('text')}
        onFocus={() => setInputTypeEnd('date')}
        onChange={(e) => setPeriod({ ...period, to: e.target.value })}
        placeholder={period?.to ? formatDate(period.to) : 'До'}
      />

      {children}
    </div>
  );
}

export default Period;
