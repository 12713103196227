/* eslint-disable no-return-assign */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { catchHandler } from '../../../utils/error_handling/error_handling';
import { alertFunction } from '../../../utils/functions/alertFunction';
import { clientSendData } from '../../../utils/functions/requests';
import CardSetting from '../../../components/Card/CardSetting';
import InputSearch from '../../../components/UI/InputSearch';
import Button from '../../../components/UI/Button/Button';
import Loader from '../../../components/UI/Loader';

/**
 * @component Клиентский портал - проекты - карточка проекта - вкладка "Проект"
 * @prop {projectID} object - id проекта
 * @prop {refreshTable} function - обновление проектов в таблице
 * @prop {closeHandler} function - закрытие карточки
 */
function Project(props) {
  const { projectID, refreshTable, closeHandler } = props;
  const operInfo = useSelector((state) => state.oper_info); // оперативная информация
  const currentUser = operInfo.user_id; // id текущего пользователя
  const sdParametres = useSelector((state) => state.helpdesk.parameters); // настройки хелпдеска из sd_parameters
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [isSearching, setIsSearching] = useState(false); // состояние загрузки при поиске
  const [ticket, setTicket] = useState({}); // объект с данными заявки
  const [modalState, setModalState] = useState({
    active: false,
    action: '',
    placeholder: '',
    message: '',
  });
  const isSuperUser = sdParametres?.admins?.includes(String(currentUser));
  const isCoordinator = sdParametres?.coordinators?.includes(String(currentUser));
  const project = {
    title: 'Проект 1', partner: 'Ефремова', users: 'Список пользователей', until: 'Срок действия', files: 'Скачать все файлы',
  };

  if (isLoading) return <Loader />;
  return (

    <div className="project__wrapper">

      <div className="project__info">

        <CardSetting title="Наименованиее проекта:">{project?.title}</CardSetting>
        <CardSetting title="Партнер">{project?.partner}</CardSetting>
        <CardSetting title="Список пользователей">{project?.users}</CardSetting>
        <CardSetting title="Файлы проекта">скачать архив</CardSetting>

      </div>

    {/* <div className="ticket__footer">
{isTicketChanged && (
<Button className={`${smallButtonClass} buttonGreen`} onClick={saveTicket}>Сохранить</Button>)}

    </div> */}
    </div>

  // </div>

  );
}

export default Project;
