export const headers = {
  userGroups: [
    { title: 'Группа', key: 'title' },
    { title: 'Пользователи', key: 'users' },
  ],
  permissions: [
    { title: 'Наименование', key: 'title' },
    { title: 'Доступы', key: 'values' },
  ],
  services: [
    { title: 'Сервис', key: 'title' },
    { title: 'Описание', key: 'description' },
  ],
  serviceGroups: [
    { title: 'Группа', key: 'title' },
    { title: 'Сервисы', key: 'ptitle' },
  ],
  permissionsAssets: [
    { title: 'Наименование', key: 'title' },
    { title: 'Доступы', key: 'values' },
  ],
};

export const rowNames = {
  asset_editing_groups: 'Доступ к редактированию активов',
  coordinators: 'Координаторы',
  admins: 'Администраторы',
};
