import React, { useState, useEffect } from 'react';
import Table from '../components/Table/Table';
import THead from '../components/Table/THead';
import TRow from '../components/Table/TRow';
import TData from '../components/Table/TData';
import TBody from '../components/Table/TBody';
import TFoot from '../components/Table/TFoot';
import CardBody from '../components/Card/CardBody';
import { catchHandler } from '../utils/error_handling/error_handling';
import { alertFunction } from '../utils/functions/alertFunction';
import { clientSendData } from '../utils/functions/requests';
import { stringMatch } from '../utils/functions/stringHandling';
import './testPage.scss';

function TabTable() {
  const headers = [
    { title: '№', field: 'id', align: 'right' },
    { title: 'Название сервиса', field: 'title' },
    { title: 'Ссылка на сервис', field: 'link' },
    { title: 'Таблица параметров', field: 'param_table' },
    { title: 'Alias', field: 'alias' },
  ];

  const inputsStateDefault = headers.reduce((acc, current) => Object.assign(acc, { [current.field]: '' }), {});

  /* const test = [1, 2, 3].reduce((acc, current) => acc += current, 0);
  console.log(test);
  const test2 = [1, 2, 3].reduce((acc, current) => acc += current, 10);
  console.log(test2); */
  const [inputState, setInputState] = useState(inputsStateDefault);
  const [sortDir, setSortDir] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [filterField, setFilterField] = useState('');
  const [defaultArr, setDefaultArr] = useState([]);
  const [displayArr, setDisplayArr] = useState([]);

  useEffect(() => {
    getAllServices();
  }, []);

  async function getAllServices() {
    try {
      const reqData = {
        type: 'getAllServices',
      };
      setIsLoading(true); // загружается
      const result = await clientSendData('POST', '/get_all_services', reqData); // запрос в БД
      if (result) {
        setDefaultArr(result);
        setDisplayArr(result); // запись в переменную
      }
    } catch (error) {
      catchHandler(error, 'getDirections'); // обработчик ошибок
    } finally { setIsLoading(false); }
  }

  const sortData = (field) => {
    const copyTable = defaultArr.concat();
    if (sortDir) {
      copyTable.sort((a, b) => {
        if (!a[field]) return 1;
        if (!b[field]) return -1;
        if (a[field] === b[field]) return 0;
        return a[field] < b[field] ? -1 : 1;
      });
    } else {
      copyTable.sort((a, b) => {
        if (!a[field]) return -1;
        if (!b[field]) return 1;
        if (a[field] === b[field]) return 0;
        return a[field] > b[field] ? -1 : 1;
      });
    }
    setSortDir(!sortDir);
    setDisplayArr(copyTable);
  };

  function searchHandler(e, field) {
    try {
      setInputState({ ...inputsStateDefault, [field]: e.target.value });
      setFilterField(field);
      const filterResult = defaultArr.filter((item) => stringMatch(e.target.value, String(item[field])));
      if (filterResult.length > 0) {
        setDisplayArr(filterResult);
      } else {
        setDisplayArr(defaultArr);
      }
    } catch (error) {
      catchHandler(error, 'searchHandler');
    }
  }

  function onClickHandler(field) {
    if (filterField !== field) {
      setInputState(inputsStateDefault);
      setFilterField(field);
      setDisplayArr(defaultArr);
    }
  }

  return (
    <CardBody>

    <Table id="testtable">
    <THead title="Опции">
          <TRow>
            {headers.map((header) => <TData key={header.field} onClick={() => sortData(header.field)}>{header.title}</TData>)}
          </TRow>
    </THead>

        {/* body */}
        <TBody>
         <TRow>
        {headers.map((header) => {
          const { field } = header;
          return (
        <TData key={field}>
          <input className="input" value={inputState[field]} onInput={(e) => searchHandler(e, field)} onClick={() => onClickHandler(field)} />
        </TData>);
        })}
        { /*    <TData><input field={headers[0].field} value={inputTextDict[headers[0].field]} onInput={(e) => searchHandler(e, headers[0].field)} onClick={onClickHandler} /></TData>
          <TData><input field={headers[1].field} value={inputTextDict[headers[1].field]} onInput={searchHandler} onClick={onClickHandler} /></TData>
          <TData><input field={headers[2].field} value={inputTextDict[headers[2].field]} onInput={searchHandler} onClick={onClickHandler} /></TData>
          <TData><input field={headers[3].field} value={inputTextDict[headers[3].field]} onInput={searchHandler} onClick={onClickHandler} /></TData> */}
         </TRow>
        {displayArr.map((d) => (
        <TRow key={d.id}>
             {headers.map((header) => <TData align={header.align} key={header.field}>{d[header.field]}</TData>)}

            { /* <TData key={d.id}>{ d.id }</TData>
              <TData key={d.title}>{d.title}</TData>
              <TData key={d.link}>{d.link}</TData>
        <TData key={d.param_table}>{d.param_table}</TData> */}
        </TRow>
        ))}
        </TBody>
       {/* footer */}
       <TFoot>
        <TRow />
       </TFoot>
    </Table>
    </CardBody>
  );
}

export default TabTable;
