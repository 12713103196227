import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { createAction } from '../../../utils/redux/store';
import { catchHandler } from '../../../utils/error_handling/error_handling';
import { alertFunction } from '../../../utils/functions/alertFunction';
import { authorization } from '../../../utils/functions/authenticate';
import { setOperInfo, log } from '../../../utils/functions/others';
import { clientSendData } from '../../../utils/functions/requests';
import { getDirections, getUserDirections } from '../functions';
import ServiceBody from '../../../components/Service/ServiceBody';
import CheckMark from '../../../components/UI/CheckMark';
import Cancel from '../../../components/UI/Cancel';
import Table from '../../../components/Table/Table';
import THead from '../../../components/Table/THead';
import TRow from '../../../components/Table/TRow';
import TData from '../../../components/Table/TData';
import TBody from '../../../components/Table/TBody';
import TFoot from '../../../components/Table/TFoot';
import Loader from '../../../components/UI/Loader';
import Input from '../../../components/UI/Input';
import Cap from '../../Cap/Cap';
import DirectionCard from './DirectionCard';
import './directions.scss';

// Сервис редактирования направлений (создание, редактирование, удаление)
function Directions() {
  const directions = useSelector((state) => state.questionnaire.directions); // все группы из хранилища
  const location = useLocation(); // адрес
  const [isLoading, setIsLoading] = useState(false); // Состояние загрузки
  const [isAuthorized, setIsAuthorized] = useState(false); // Состояние авторизации
  const [isCreating, setIsCreating] = useState(false); // Состояние создания нового направления
  const [openedDirection, setOpenedDirection] = useState({}); // Состояние создания нового направления
  const [showCard, setShowCard] = useState(false); // Состояние карточки направления
  const [newDirectionName, setNewDirectionName] = useState(''); // имя нового направления

  const headers = [
    { id: 1, title: 'Название', field: 'title' },
    { id: 2, title: 'Создал', field: 'creator' },
    { id: 3, title: 'Создано', field: 'date_created' },
    { id: 4, title: 'Изменил', field: 'modifier' },
    { id: 5, title: 'Изменено', field: 'date_modified' },
  ];

  useEffect(() => {
    awaitRequests();
  }, []);

  async function awaitRequests() {
    setIsLoading(true);
    const checkAuthorization = await authorization(); // авторизация
    setIsAuthorized(checkAuthorization);
    if (checkAuthorization) getDirections(); // Получить информацию о направлениях
    setIsLoading(false);
  }

  // Показать карточку направления и передавть в нее информацию
  function openDirection(direction) {
    setIsCreating(false); // закрыть форму создания если открыта
    setOpenedDirection(direction); // обновить состояние текущего направления
    setOperInfo({ subject_id: direction.id }); // записать id направления в оперативную информацию
    setShowCard(true);
    log({ message: `Пользователь выбрал направление "${direction.title}", id: ${direction.id}` });
  }

  // Начать создание
  function startCreating() {
    setIsCreating(true); // вкл создание
    log({ message: 'Пользователь нажал кнопку создания направления' });
  }

  // Создать новое направление
  async function createDirection() {
    try {
      const reqData = {
        type: 'createDirection',
        title: newDirectionName,
      };

      if (!newDirectionName) alertFunction('enter_name', 'clientPost'); // Если имя пустое - уведомление
      else { // иначе
        setIsLoading(true); // загружается
        const result = await clientSendData('POST', '/create_direction', reqData); // запрос в БД
        if (result === 'success') { // если успех
          // обновление направлений доступных пользователю
          await getUserDirections(location.pathname);
          await getDirections(); // обновление направлений
          setIsCreating(false); // Создание выкл
          log({ message: `Пользователь создал направление "${newDirectionName}"`, eventtype: 'info' });
        }
      }
    } catch (error) {
      catchHandler(error, 'createDirection');
    } finally {
      setIsLoading(false);
    }
  }

  // Отменить создание
  function cancelCreating(e) {
    e.preventDefault(); // предотвращение действий формы по умолчанию
    setNewDirectionName(''); // сброс имени нового направления
    setIsCreating(false); // изменение состояния создания направления
    log({ message: 'Пользователь отменил создание направления', eventtype: 'info' });
  }

  if (isAuthorized) { // если пользователь авторизован
    return (
      <ServiceBody>
        {isLoading ? <Loader /> : (// если загружается - показывается лоадер, иначе таблица
        <Table id="directions__table">
          <THead
            title="Направления"
            headers={headers}
            array={directions}
            setArray={(array) => createAction('SET_DIRECTIONS', array)}
          />
          <TBody>
            {directions.map((d) => (
              <TRow onClick={() => openDirection(d)} key={d.id} button>
                {headers.map((item) => {
                  const { id, field } = item;
                  return <TData key={id}>{d[field]?.trim() || '-'}</TData>;
                })}
              </TRow>
            ))}
          </TBody>

          {/* Футер таблицы */}
          <TFoot>
            {isCreating ? ( // если создается новая группа показывается форма
            <TRow>
              <TData>
                <Input placeholder="Введите имя" onChange={(e) => setNewDirectionName(e.target.value)} />
                <CheckMark onClick={createDirection} />
                <Cancel onClick={cancelCreating} />
              </TData>
            </TRow>
            ) : ( // иначе кнопка "Добавить новую"
            <TRow>
              <TData onClick={startCreating}>Создать направление</TData>
            </TRow>)}
          </TFoot>
        </Table>)}

        {showCard && (
        <DirectionCard // карточка направления
          setShowCard={setShowCard}
          getDirections={getDirections}
          direction={openedDirection}
          setDirection={setOpenedDirection}
        />)}
      </ServiceBody>
    );
  } return <Cap />; // иначе покажется заглушка
}

export default Directions;
