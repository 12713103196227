import React, { useState } from 'react';
import { alertFunction } from '../../../../utils/functions/alertFunction';
import TagsList from './TagsList';

/**
* @component Список тегов
- Компонент обрабатывает массив элементов
- Создает из них графические блоки элементов, которые можно добавлять и удалять
- Возвращает строку разделенную запятыми
@prop {array} array (Обязательный) массив тегов,
@prop {onChange} function (Обязательный) обработчик изменений
@prop {placeholder} string плейсхолдер,
@prop {pattern} string регулярное выражение для типа array
*/

function Tags(props) {
  // Получаем параметры, сам массив и функцию передачи строки в хранилище redux
  const {
    array = [], onChange, pattern, placeholder,
  } = props;

  const tagsDefault = typeof array === 'string' ? JSON.parse(array) : array; // если массив пришел в виде строки - распарсить
  const [tags, setTags] = useState(tagsDefault); // Состояние элементов массива
  const [inputTag, setInputTag] = useState(''); // Состояние поля ввода

  // Функция добавления элементов
  function addTag() {
    if (inputTag.trim()) { // если есть значение в поле ввода
      if (pattern && RegExp(pattern).test(inputTag)) { // если есть паттерн, и есть совпадение
        alertFunction('verify_input_tags', 'clientPost'); // уведомление
      } else { // иначе
        setTags([...tags, inputTag]); // Добавляем в массив новый элемент
        setInputTag(''); // Очищаем поле ввода
        onChange([...tags, inputTag]); // отправить обновлённый массив во внешний обработчик
      }
    }
  }

  // Обработчик инпута
  function inputHandler(e) {
    setInputTag(e.target.value); // обновить состояние поля ввода
    if (e?.key === 'Enter') addTag(); // если нажата клавиша Enter - добавить тег
  }

  // Функция удаления элементов
  function removeTag(object, objectIndex) {
    // удалить элемент с переданным индексом
    const filtered = tags.filter((item, index) => index !== objectIndex);
    setTags(filtered); // обновить элементы
    onChange(filtered); // отправить обновлённый массив во внешний обработчик
  }

  return (
    <div className="tags">
      <div className="tags__input-block">
        <input
          value={inputTag}
          onChange={inputHandler}
          onKeyUp={inputHandler}
          placeholder={placeholder}
          type="text"
          className="tags__input"
        />
        <div onClick={addTag} className="tags__button">
          <img className="tags__img" src="../../icons/cancel.svg" alt="add-tag" />
        </div>
      </div>
      <TagsList array={tags} onDelete={removeTag} />
    </div>
  );
}

export default Tags;
