import React from 'react';

// Сервис оценок - Заглушка с текстом
function EvaluationCap(props) {
  const { title } = props;

  return (
    <div className="upu-service_padding-top">
      <h2 className="upu-service__title">{title}</h2>
    </div>
  );
}

export default EvaluationCap;
