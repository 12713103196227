import React, { useEffect, useState } from 'react';
import Table from '../../components/Table/Table';
import THead from '../../components/Table/THead';
import TBody from '../../components/Table/TBody';
import TRow from '../../components/Table/TRow';
import { clientSendData } from '../../utils/functions/requests';
import { catchHandler } from '../../utils/error_handling/error_handling';
import { headers } from './common';
import TData from '../../components/Table/TData';
import ServiceBody from '../../components/Service/ServiceBody';
import Select from '../../components/UI/Select';
import { alertFunction } from '../../utils/functions/alertFunction';

function PermissionsAssets() {
  const [assetTypesUserRights, setAssetTypesUserRights] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [selectData, setSelectData] = useState({});
  const [changeData, setChangeData] = useState({});
  useEffect(() => {
    getAssetTypesUserRights();
  }, []);

  async function getAssetTypesUserRights() {
    try {
      const reqData = {
        type: 'getAssetTypesUserRights',
      };
      const result = await clientSendData('POST', '/get_asset_user_types_rights', reqData); // запрос в БД
      if (result) {
        setAssetTypesUserRights(result.data);
        setAllUsers(result.users);
      }
    } catch (error) {
      catchHandler(error, 'getAssetTypesUserRights'); // обработчик ошибок
    }
  }

  async function editAssetTypesUserRights(array) {
    try {
      const usersRights = {};
      array.forEach((element) => {
        usersRights[element.id] = element.users.map((el) => el.id);
      });
      const reqData = {
        type: 'editAssetTypesUserRights',
        data: usersRights,
      };
      const result = await clientSendData('POST', '/edit_asset_types_user_rights', reqData); // запрос в БД
      if (result?.success) { // если запрос выполнен успешно
        await alertFunction('save_settings', 'client');
      } else {
        await alertFunction('bad_request', 'client');
      }
      await getAssetTypesUserRights();
    } catch (error) { // если ошибка
      catchHandler(error, 'editAssetTypesUserRights'); // обрабатываем ошибку
    }
  }
  async function deleteAssetTypesUserRights(user_id, type_id) {
    const usersObj = assetTypesUserRights.filter((el) => el.id === type_id)[0];
    const usersArray = assetTypesUserRights.filter((el) => el.id !== type_id);
    usersObj.users = usersObj.users.filter((el) => el.id !== user_id);
    usersArray.push(usersObj);
    setAssetTypesUserRights(usersArray);
    await editAssetTypesUserRights(usersArray);
  }

  async function chooseData(key) {
    const { data, id } = selectData[key];
    const usersObj = assetTypesUserRights.find((el) => el.id === id);

    // Проверяем, если пользователь уже находится в массиве 'usersObj.users'
    if (!usersObj.users.some((el) => el.id === data.id)) {
      // Если нет, то добавляем пользователя
      usersObj.users.push(data);

      // Фильтруем объект с совпадающим 'id' из 'assetTypesUserRights'
      const usersArray = assetTypesUserRights.map((el) => (el.id === id ? { ...el, users: usersObj.users } : el));

      // Обновляем состояние 'assetTypesUserRights' с модифицированным 'usersArray'
      setAssetTypesUserRights(usersArray);

      // Асинхронно вызываем функцию 'editAssetTypesUserRights' с обновленным 'usersArray'
      await editAssetTypesUserRights(usersArray);
    } else {
      // Если пользователь уже добавлен, выводим сообщение или выполняем другие действия по вашему выбору
      alertFunction('sd_select_exist', 'client');
    }
  }

  return (
    <ServiceBody>
        <Table id="">
            <THead
              title="Доступы к редактированию активов"
              headers={headers.permissionsAssets}
            />
            <TBody>
              {assetTypesUserRights.sort((a, b) => a.id - b.id).map((d) => (
                <TRow key={`${d.id}row`}>
                      <TData>{d.title}</TData>
                      <TData key={d.id}>
                          <div className="tags__input-block">
                              <Select
                                id={d.id}
                                array={allUsers}
                                className="tags__input"
                                onChoose={(data) => setSelectData({ [d.id]: { data, id: d.id } })}
                                defaultValue={selectData?.[d.id]?.data?.title}
                                search
                              />
                              <div onClick={() => chooseData(d.id)} className="tags__button">
                                  <img className="tags__img" src="../../icons/cancel.svg" alt="add-tag" />
                              </div>
                          </div>
                          <div className="tags__list">
                              {d.users.map((el) => <p className="tags__tag" key={el.id}>
                                                      <span onClick={() => deleteAssetTypesUserRights(el.id, d.id)} className="upu__close">X</span>
                                                      {el.title}
                                                   </p>)}
                          </div>
                      </TData>
                </TRow>
              ))}

            </TBody>
        </Table>
    </ServiceBody>
  );
}

export default PermissionsAssets;
