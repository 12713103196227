import React, { useState, useEffect } from 'react';
import ServiceBody from '../../components/Service/ServiceBody';
import Table from '../../components/Table/Table';
import THead from '../../components/Table/THead';
import TRow from '../../components/Table/TRow';
import TData from '../../components/Table/TData';
import TBody from '../../components/Table/TBody';
import { catchHandler } from '../../utils/error_handling/error_handling';
import EditorButton from '../../components/UI/Button/EditorButton';
import TFoot from '../../components/Table/TFoot';
import Select from '../../components/UI/Select';
import CheckMark from '../../components/UI/CheckMark';
import Cancel from '../../components/UI/Cancel';
import InputSearch from '../../components/UI/InputSearch';
import { clientSendData } from '../../utils/functions/requests';
import Wrapper from '../../components/Basic/Wrapper';

function UserPriorities() {
  const [users, setUsers] = useState([]);
  const [priorities, setPriorities] = useState([]);
  const [userPriorities, setUserPriorities] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [createNewPriority, setCreateNewPriority] = useState(false);
  const [editingRow, setEditingRow] = useState(null);
  const [selectList, setSelectList] = useState('');
  const [selectValue, setSelectValue] = useState({ id: '', title: '' });
  const [createUserVal, setCreateUserVal] = useState({});
  const [createValue, setCreateValue] = useState({});

  function makePriority() {
    setCreateNewPriority(true);
  }

  useEffect(() => {
    getUpuUsers();
    getPriorities();
    getUserPriorities();
  }, []);

  async function editSdPriorities(editAction, priorityId, userPriorityId = null, newUserId = null) {
    try {
      const reqData = {
        pId: priorityId,
        upId: userPriorityId,
        nUserId: newUserId,
        action: editAction,
        type: 'editSdPriorities',
      };
      // setIsLoading(true); // загружается
      const result = await clientSendData('POST', '/edit_sd_priorities', reqData); // запрос в БД action: useState

      setEditingRow(null);
      setCreateValue({});
      setCreateNewPriority(false);
      getUserPriorities();
    } catch (error) {
      catchHandler(error, 'editSdPriorities'); // обработчик ошибок
    } // finally { setIsLoading(false); }
  }

  async function getUserPriorities() {
    try {
      const reqData = {
        type: 'getUserPriorities',
      };
      // setIsLoading(true); // загружается
      const result = await clientSendData('POST', '/get_user_priorities', reqData);

      if (result) {
        setUserPriorities(result.data); // запись в переменную
      }
    } catch (error) {
      catchHandler(error, 'getPriorities'); // обработчик ошибок
    } // finally { setIsLoading(false); }
  }

  async function getPriorities() {
    try {
      const reqData = {
        type: 'getPriorities',
      };
      // setIsLoading(true); // загружается
      const result = await clientSendData('POST', '/get_priorities', reqData);

      if (result) {
        setPriorities(result.data); // запись в переменную
      }
    } catch (error) {
      catchHandler(error, 'getPriorities'); // обработчик ошибок
    } // finally { setIsLoading(false); }
  }

  async function getUpuUsers() {
    try {
      const reqData = {
        type: 'getUpuUsers',
      };
      // setIsLoading(true); // загружается
      const result = await clientSendData('POST', '/get_upu_users', reqData); // запрос в БД action: useState

      if (result) {
        setUsers(result.data); // запись в переменную
      }
    } catch (error) {
      catchHandler(error, 'getUpuUsers'); // обработчик ошибок
    } // finally { setIsLoading(false); }
  }

  async function searchWrapper(e) {
    setIsSearching(true); // поиск выполняется
    if (e.target.value.length === 0) setSelectList((prev) => ({ ...prev }));
    try {
      const reqData = {
        type: 'searchUser',
        query: e.target.value,
      };
      const result = await clientSendData('POST', '/search_user', reqData);
      if (result?.success) setUsers(result.data);
      setIsSearching(false);
      return [];
    } catch (error) {
      catchHandler(error, 'searchUser');
      setIsSearching(false);
      return [];
    }
  }

  return (
    <ServiceBody>
        <Table id="priorities">
            <THead title="Приоритеты пользователей" />

           { /* Тело таблицы */ }
            <TBody>

                {userPriorities.map((priority) => (

                <TRow key={priority.id}>
                    <TData>
                       {priority.name}
                    </TData>

                    <TData>
                    { editingRow === priority.id ? (
                        <div className="tags__input-block">
                    <Select
                      array={priorities}
                      id="select1"
                      onChoose={(e) => setSelectValue({ ...selectValue, id: e.id, title: e.title })} // надо записать айди, сделать объектом
                      defaultValue={selectValue.title}
                    />
                        <CheckMark onClick={() => editSdPriorities('update', selectValue.id, priority.id, null)} />
                        </div>

                    ) : <span onClick={() => setEditingRow(priority.id)} className="clickable">{priority.priority}</span>}
                    </TData>
                    <TData>
                    <Cancel onClick={() => editSdPriorities('delete', null, priority.id, null)} />
                    </TData>

                </TRow>
                ))}

            </TBody>

            { /* Футер */ }
         <TFoot>
            {createNewPriority ? (<TRow>
                <TData>
                    <Wrapper>
                        <InputSearch
                          id="inputSearch"
                          placeholder="Введите пользователя"
                          array={users}
                          onSearch={(e) => searchWrapper(e)}
                          onChoose={(user) => (setCreateUserVal({ ...createUserVal, user_id: user.id, user: user.dn }))}
                          defaultValue={users.dn}
                        />
                        <Select
                          array={priorities}
                          id="select2"
                          onChoose={(e) => setCreateValue({ ...createValue, id: e.id, title: e.title })}
                          defaultValue={createValue.title}
                        />
                        <CheckMark onClick={() => editSdPriorities('create', createValue.id, null, createUserVal.user_id)} />
                    </Wrapper>
                </TData>
                                  </TRow>) : (
              <TRow>
              <TData onClick={makePriority}>Назначить приоритет</TData>
              </TRow>
            )}
         </TFoot>
        </Table>
    </ServiceBody>
  );
}

export default UserPriorities;
