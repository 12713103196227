import React, { useEffect, useState } from 'react';
import { clientSendData } from '../../../utils/functions/requests';
import { catchHandler } from '../../../utils/error_handling/error_handling';
import ServiceBody from '../../../components/Service/ServiceBody';
import Table from '../../../components/Table/Table';
import THead from '../../../components/Table/THead';
import TBody from '../../../components/Table/TBody';
import TRow from '../../../components/Table/TRow';
import TData from '../../../components/Table/TData';
import ApplicationCard from './ApplicationCard/ApplicationCard';
import Loader from '../../../components/UI/Loader';
import ServicePanel from '../../../components/Service/ServicePanel';
import CardSetting from '../../../components/Card/CardSetting';

// Сервис оценок - Интерфейс заполнения проектов
function EmployeeInfo() {
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [info, setInfo] = useState({}); // информация о сотруднике
  const [projects, setProjects] = useState(null); // проекты связанные с сотрудником
  const [showCard, setShowCard] = useState(false); // состояние отображения карточки заявления
  const [currentApplicationId, setCurrentApplicationId] = useState(null); // выбранное заявление
  const [currentProjectId, setCurrentProjectId] = useState(null); //

  const headers = [ // заголовик
    { id: 1, title: 'Проект', field: 'project_name' },
    { id: 2, title: 'Начало', field: 'created_time' },
    { id: 3, title: 'Завершение', field: 'end_time' },
    { id: 4, title: 'Статус ПО', field: 'status' },
    { id: 5, title: 'ПО', field: 'application_id' },
  ];

  useEffect(() => {
    getEmployeeAddInfo();
  }, []);

  // Поиск заявлений
  async function getEmployeeAddInfo() {
    try {
      setIsLoading(true);
      const reqData = { type: 'getEmployeeAddInfo' };
      const result = await clientSendData('POST', '/get_employee_add_info', reqData); // запрос в БД
      if (result) {
        setInfo(result?.info);
        setProjects(result?.projects);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      catchHandler(error, 'getEmployeeAddInfo'); // обработка ошибок
    }
  }

  // Открыть карточку заявления
  function openApplicationCard(app_id, project_id) {
    setCurrentApplicationId(app_id);
    setCurrentProjectId(project_id);
    setShowCard(true);
  }

  // Определить отображаемое значение
  const defineValue = (key) => {
    if (info[key]) {
      const data = info[key];
      if (key === 'experience') {
        return `${data?.years || 0}y ${data?.months || 0}m`;
      } return data;
    }
    return '-';
  };

  return (
    <ServiceBody horizontal>
        <ServicePanel id="evaluation__employee-info-panel">
            <CardSetting title="ФИО:">{defineValue('title')}</CardSetting>
            <CardSetting title="Грейд:">{defineValue('grade')}</CardSetting>
            <CardSetting title="Группа:">{defineValue('group')}</CardSetting>
            <CardSetting title="Стаж:">{defineValue('experience')}</CardSetting>
            <CardSetting title="Утилизация (ч):">{defineValue('total')}</CardSetting>
            <CardSetting title="Наставник:">{defineValue('mentor')}</CardSetting>
            <CardSetting title="Грейд наставника:">{defineValue('mentor_grade')}</CardSetting>
            <CardSetting title="Опыт в текущей должности:">{defineValue('position_experience')}</CardSetting>
            <CardSetting title="Норматив:">{defineValue('norm')}</CardSetting>
        </ServicePanel>

        {isLoading ? <Loader /> : (
        <Table id="evaluation__employee-info-table">
            <THead
              title="Мои проекты"
              headers={headers}
              array={projects}
              setArray={setProjects}
            />
            <TBody>
                {/* Если нет результатов */}
                {projects?.length === 0 ? (
                <TRow>
                    {headers.map((item, index) => (
                        <TData key={item.id}>
                            {index === 0 ? 'Результаты отсутствуют' : ''}
                        </TData>
                    ))}
                </TRow>)
                // Если результаты есть
                  : projects?.map((row) => {
                    const { project_id, application_id } = row;
                    return (
                    <TRow key={project_id}>
                        {headers.map((item) => {
                          if (item.field !== 'application_id') {
                            return (
                                <TData key={item.id}>{row[item.field]}</TData>
                            );
                          }
                          return (
                            <TData
                              key={item.id}
                              onClick={() => openApplicationCard((application_id || 'new'), project_id)}
                            >
                                {application_id ? 'Посмотреть' : 'Создать'}
                            </TData>
                          );
                        })}
                    </TRow>
                    );
                  })}
            </TBody>
        </Table>)}

        {showCard && (
        <ApplicationCard
          id={currentApplicationId}
          setShowCard={setShowCard}
          projectId={currentProjectId}
          findApplications={getEmployeeAddInfo}
        />)}
    </ServiceBody>
  );
}

export default EmployeeInfo;
