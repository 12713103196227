import React, { useEffect, useState } from 'react';
import { authorization } from '../../utils/functions/authenticate';
import { setOperInfo } from '../../utils/functions/others';
import Service from '../../components/Service/Service';
import Cap from '../Cap/Cap';
import Groups from './Groups';
import UserGroups from './UserGroups';
import UserPriorities from './UserPriorities';
import Services from './Services';
import Permissions from './Permissions';
import { clientSendData } from '../../utils/functions/requests';
import { catchHandler } from '../../utils/error_handling/error_handling';
import GroupCategories from './GroupCategories';
import NotificationEditor from './NotificationEditor';
import StandardSolutions from './StandardSolutions';
import PermissionsAssets from './PermissionsAssets';
import './admin_hlpdsk.scss';

function AdminHelpdesk() {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const adminPages = [
    { id: 1, title: 'Группы', component: <Groups /> },
    { id: 2, title: 'Пользователи в группах', component: <UserGroups /> },
    { id: 3, title: 'Приоритеты пользователей', component: <UserPriorities /> },
    { id: 4, title: 'Сервисы', component: <Services /> },
    { id: 5, title: 'Сервисы по группам', component: <GroupCategories /> },
    { id: 6, title: 'Редактирование уведомлений', component: <NotificationEditor /> },
    { id: 7, title: 'Стандартные решения', component: <StandardSolutions /> },
  ];
  const [pages, setPages] = useState(adminPages);

  useEffect(() => {
    setOperInfo();
    awaitRequests();
  }, []);

  async function awaitRequests() {
    await getUserPermissions();
    const checkAuthorization = await authorization(); // авторизация
    setIsAuthorized(checkAuthorization);
  }

  async function getUserPermissions() {
    try {
      const reqData = {
        type: 'getUserPermissions',
      };
      const result = await clientSendData('POST', '/get_user_permissions', reqData);
      if (result.success) {
        setPages((state) => ([...state, ...[{ id: 8, title: 'Доступы', component: <Permissions /> }, { id: 9, title: 'Доступы к активам', component: <PermissionsAssets /> }]]));
      }
    } catch (error) {
      catchHandler(error, 'getUserPermissions'); // обработчик ошибок
    }
  }

  if (isAuthorized) {
    return <Service id="admin-helpdesk" pages={pages} horizontal />;
  } return <Cap />;
}

export default AdminHelpdesk;
