import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { catchHandler } from '../../../../utils/error_handling/error_handling';
import { alertFunction } from '../../../../utils/functions/alertFunction';
import { clientSendData } from '../../../../utils/functions/requests';
import { sortFieldsByScheme } from '../../Common/functions';
import { log } from '../../../../utils/functions/others';
import ServiceBody from '../../../../components/Service/ServiceBody';
import Pagination from '../../../../components/Table/Pagination';
import ThemeCard from './ThemeCard/ThemeCard';
import THead from '../../../../components/Table/THead';
import Loader from '../../../../components/UI/Loader';
import Table from '../../../../components/Table/Table';
import TBody from '../../../../components/Table/TBody';
import TRow from '../../../../components/Table/TRow';
import TData from '../../../../components/Table/TData';
import TFoot from '../../../../components/Table/TFoot';

/**
* @component Управление Базой знаний - Темы
*/
function Themes() {
  const [isLoading, setIsLoading] = useState(false); // Состояние загрузки
  const [isUpdating, setIsUpdating] = useState(false); // Состояние обновления
  const [showCard, setShowCard] = useState(false); // состояние отображения карточки темы
  const [currentThemeId, setCurrentThemeId] = useState(null); // выбранная тема
  const [themesId, setThemesId] = useState([]); // массив id тем в выбранном подразделении
  const [themes, setThemes] = useState([]); // массив данных тем в выбранном подразделении
  const userDivisions = useSelector((state) => state.kb.user_divisions); // подразделения доступные пользователю
  const currentDivision = useSelector((state) => state.kb.current_division); // текущее подразделение
  const themeScheme = useSelector((state) => state.kb.active_scheme.theme); // активная схема темы
  const limit = currentDivision.limit_view; // лимит отображения строк в таблице
  const { scheme, id } = themeScheme; // активная схема и её id

  // Массив полей которые необходимо показывать в таблице
  let fields = Object.keys(scheme).filter((item) => scheme[item].show_in_table);
  // Сортировка полей в зависимости от параметра show_in_table.order
  fields = sortFieldsByScheme({
    scheme,
    key: 'show_in_table',
    sub_key: 'order',
    fields,
  });

  // Создание заголовков
  const headers = fields.map((field) => ( // пройти по ключам схемы
    { // вернуть заголовок поля и ключ
      title: scheme[field].name,
      field,
    }
  ));

  useEffect(() => {
    awaitRequests();
  }, [themeScheme]); //

  // Ожидание выполнения необходимых запросов
  async function awaitRequests() {
    setIsLoading(true); // состояние - загружается
    await getThemesKb(); // получить темы
    setIsLoading(false); // загрузка завершена
  }

  // Получить темы в выбранном подразделении
  async function getThemesKb(range) {
    try {
      const reqData = {
        type: 'getThemesKb',
        division_id: currentDivision.id,
        location: window.location.pathname,
        scheme,
      };
      setIsUpdating(true);
      if (range) { // если указан диапазон id запрашиваемых вопросов
        if (range.length > 0) { // если диапазон не пустой
          const addReqData = { // он добавляется к запросу
            ...reqData,
            range,
            fields,
          };
          const result = await clientSendData('POST', '/get_themes_kb', addReqData);
          if (result === 'bad_request') alertFunction('bad_request', 'clientPost');
          else setThemes(result); // Записать массив тем в состояние
        } else getThemesKb(); // если диапазон пустой - запросить количество тем
      } else {
        const result = await clientSendData('POST', '/get_themes_kb', reqData);
        if (result === 'bad_request') alertFunction('bad_request', 'clientPost');
        else if (result.length > 0) { // если найдены какие то темы
          // оставить только id в массиве
          let arrayOfId = result.map((item) => item.id);
          // обновляем состояние локального массива id (для пересчёта пагинации)
          setThemesId(arrayOfId);
          // если кол-во id тем превышает лимит, обрезаем с начала до лимита (для 1й страницы)
          if (limit && result.length > limit) arrayOfId = arrayOfId.slice(0, limit);
          // и запрашиваем темы по полученному массиву id
          await getThemesKb(arrayOfId); // запрашиваем все темы
        } else resetThemes();
      }
      setIsUpdating(false);
    } catch (error) {
      catchHandler(error, 'getThemesKb');
    }
  }

  // Сбросить темы
  function resetThemes() {
    setThemesId([]); // сбросить локальный массив id (для выключения пагинации)
    setThemes([]); // сбросить массив тем
  }

  // Открыть карточку темы
  function openTheme(id) {
    setCurrentThemeId(id); // id выбранной темы
    setShowCard(true); // показать карточку
    log({ message: `Пользователь открыл карточку ${id === 'new' ? 'создания новой темы' : `темы id: ${id}`}` });
  }

  // Функция выбора страницы
  async function choosePage(number) {
    const fromIndex = number * limit - limit; // номер страницы * лимит отображения -  лимит отображения
    const toIndex = number * limit; // номер страницы * лимит отображения
    const range = themesId.slice(fromIndex, toIndex); // диапазон id тем
    await getThemesKb(range); // запросить темы
    log({ message: `Пользователь на вкладке тем перешел на страницу ${number}` });
  }

  // Обработчик поиска по всем полям
  async function searchThemes(searchData, type) {
    try {
      const reqData = {
        type: 'kbSearch',
        searchObj: {
          type,
          scheme_id: id,
          searchArray: searchData, // массив данных для поиска [{field: "field", value: "value"}, ...]
          hardSearch: false,
          table: 'kb_themes',
          column: 'essence',
          division_id: currentDivision.id,
        },
      };
      log({ message: `Пользователь запустил поиск тип ${type}` });
      const result = await clientSendData('POST', '/kb_search', reqData);
      if (result === 'bad_request') alertFunction('bad_request', 'clientPost');
      else if (result.length > 0) { // если найдены какие то темы
        // копия массива для последующего изменения
        const arrayOfId = result.map((item) => item);
        // отсортировать массив по возрастанию
        arrayOfId.sort((a, b) => a - b);
        // обновить состояние локального массива id (для пересчёта пагинации)
        setThemesId(arrayOfId);
        // // если кол-во id тем превышает лимит, обрезаем с начала до лимита (для 1й страницы)
        // if (result.length > limit) arrayOfId = arrayOfId.slice(0, limit) // ***
        // // и запрашиваем темы по полученному массиву id
        // await getThemesKb(arrayOfId) // ***

        // Иначе темы не найдены - сбрасываем состояние
      } else setThemes([]); // сбросить массив тем
      // resetThemes() // ***
    } catch (error) {
      catchHandler(error, 'searchThemes');
    }
  }

  // Обработчик сортировки полей
  async function sortThemes(field, direction) {
    try {
      const reqData = {
        type: 'kbSort',
        range: themesId,
        table: 'kb_themes',
        scheme,
        field,
        direction,
      };
      const result = await clientSendData('POST', '/kb_sort', reqData);
      if (result) {
        setThemesId(result); // ***
        // await setThemesId(result)  ***
      }
    } catch (error) {
      catchHandler(error, 'sortKbThemes');
    }
  }

  // если загружается - показывается лоадер
  if (isLoading) return <ServiceBody id="knowledge-base__themes"><Loader /></ServiceBody>;
  return (
    <ServiceBody id="knowledge-base__themes">
      {userDivisions.length > 0 ? (
      <Table id="knowledge-base-themes__table" short_last>
        <THead
          title="Темы"
          headers={headers}
          array={themes}
          setArray={setThemes}
          setDefault={getThemesKb}
          ext_sorting={sortThemes}
          button_place="row"
          search_by_all={(data) => searchThemes(data, 'fast')}
          search_by_one={(data) => searchThemes(data, 'string')}
        />

        {/* Тело таблицы */}
        <TBody>
          {themes.map((theme) => (
            <TRow key={theme.id} onClick={() => setCurrentThemeId(theme.id)} active={currentThemeId === theme.id}>
              {fields.map((field) => {
                let value = theme[field]; // значение поля в объекте темы
                if (scheme[field]?.switch) value = theme[`switch_${field}`];
                return <TData key={field} loading={isUpdating}>{value}</TData>;
              })}
              <TData onClick={() => openTheme(theme.id)} loading={isUpdating}>
                <img src="../../icons/file-text.svg" alt="file" />
              </TData>
            </TRow>
          ))}
        </TBody>

        {/* Футер таблицы */}
        <TFoot>
          {themes.length === 0 && (
          <TRow><TData loading={isUpdating}>Данные отсутствуют</TData></TRow>)}

          <TRow><TData onClick={() => openTheme('new')} loading={isUpdating}>Создать тему</TData></TRow>

          {themesId.length > 0 && ( // условие добавлено 12.07.23 для исправления бага цикличных запросов при отсутствии тем
          <Pagination
            array={themesId}
            limit={currentDivision.limit_view}
            handler={choosePage}
          />)}
        </TFoot>
      </Table>) : (
      <h2>Доступные подразделения отсутствуют</h2>)}

      {showCard && (
      <ThemeCard // карточка темы
        id={currentThemeId}
        setShowCard={setShowCard}
        getThemesKb={getThemesKb}
      />)}
    </ServiceBody>
  );
}

export default Themes;
