import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { createAction } from '../../../../../utils/redux/store';

// Страница интервью - блок выбора вопросов - категории
function Categories() {
  const interview = useSelector((state) => state.interview.interview);
  const { questions, categories } = interview;
  const currentQuestion = useSelector((state) => state.interview.current_question);
  const userAnswers = useSelector((state) => state.interview.user_answers); // ответы пользователя
  const mainCategories = categories.filter((c) => !c.parent_id);
  const [activeCategories, setActiveCategories] = useState(categories.map((c) => c.id));

  // Открыть/закрыть категорию
  function toggleCategory(e, id) {
    e.stopPropagation();
    const findId = activeCategories.find((ac) => ac === id);
    if (findId) setActiveCategories(activeCategories.filter((ac) => ac !== id));
    else setActiveCategories([...activeCategories, id]);
  }

  // Категория активна?
  function isActive(id) {
    const findId = activeCategories.find((ac) => ac === id);
    if (findId) return true;
    return false;
  }

  // Обработка выбора вопроса
  function chooseQuestion(q) {
    // установить выбранный вопрос
    createAction('SET_CURRENT_QUESTION', q);
    // найти комментарий к текущему вопросу
    const currentQuestionComment = userAnswers.find((ua) => ua.question_id === q.id);
    // добавить в textarea комментарий к текущему вопросу
    createAction('SET_COMMENT', currentQuestionComment ? currentQuestionComment.comment : '');
  }

  // Функция отображения иерархии категорий
  function showCategoriesHierarchy(category) {
    const findQuestions = questions.filter((q) => q.category_id === category.id);

    return (
        <div className={isActive(category.id) ? (
          'interview-categories__category-block_active') : (
          'interview-categories__category-block')}
        >

            {/* Вопросы относящиеся к текущей категории */}
            {findQuestions.map((q) => {
              if (q.category_id === category.id) { // если категория вопроса равна текущей
                return (
                    <div
                      key={q.id}
                      onClick={() => chooseQuestion(q)} // Кнопка вопроса
                      className={currentQuestion.id === q.id ? (
                        'interview__question-title interview__question-title_active') : (
                        'interview__question-title')}
                    >
                        {/* Если вопрос обязательный, добавляется звёздочка */}
                        {q.title + (q.required ? '*' : '')}
                    </div>
                );
              } return null;
            })}

            {/* Подкатегории */}
            {categories.map((c) => {
              if (c.parent_id === category.id) {
                const findCategory = questions.find((q) => {
                  const isCategoryId = q.category_id === c.id;
                  const isCategoryParentId = q.category_parent_id === c.id;
                  return isCategoryId || isCategoryParentId;
                });
                if (findCategory) {
                  return (
                    <div key={c.id} className="interview-categories__category">
                        <div
                          className="interview-categories__category-title"
                          onClick={(e) => toggleCategory(e, c.id)}
                        >
                            <img
                              className={isActive(c.id) ? (
                                'interview-categories__category-chevron_active') : (
                                'interview-categories__category-chevron')}
                              src="../../../../icons/chevron.svg"
                              alt="chevron"
                            />
                            {c.title}
                        </div>
                        {showCategoriesHierarchy(c)}
                    </div>
                  );
                } return null;
              } return null;
            })}
        </div>

    );
  }

  return (
    <div className="interview-categories">
        {mainCategories.map((category) => {
          // Главная категория отобразится если она содержит вопросы
          const findCategory = questions.find((q) => {
            const isCategoryId = q.category_id === category.id;
            const isCategoryParentId = q.category_parent_id === category.id;
            return isCategoryId || isCategoryParentId;
          });
          if (findCategory) {
            return (
                <div key={category.id} className="interview-categories__category">
                    <div className="interview-categories__category-title" onClick={(e) => toggleCategory(e, category.id)}>
                        <img
                          className={isActive(category.id) ? (
                            'interview-categories__category-chevron_active') : (
                            'interview-categories__category-chevron')}
                          src="../../../../icons/chevron.svg"
                          alt="chevron"
                        />
                        {category.title}
                    </div>
                    {showCategoriesHierarchy(category)}
                </div>
            );
          }
          return null;
        })}
    </div>
  );
}

export default Categories;
