/* eslint-disable import/prefer-default-export */
import { catchHandler } from '../../utils/error_handling/error_handling';
import { clientSendData } from '../../utils/functions/requests';

/**
* @function Получение информации о задаче
* @prop {id} number - id задачи
* @prop {source} string - источник запроса ('user' или 'admin')
*/
export async function getTask(id, source) {
  try {
    const reqData = {
      type: 'getTask',
      task_id: id,
      source,
    };

    const result = await clientSendData('POST', '/get_task', reqData);
    if (result) return result; // вернуть результат
    return null;
  } catch (error) {
    catchHandler(error, 'getTask');
    return null;
  }
}
