import React from 'react';

/**
* @component Таблица (обертка для thead, tbody, tfoot)
@prop {id} string Идентификатор DOM элемента
@prop {short_last} boolean Узкая последняя ячейка строки (для кнопки поиска или открытия карточки),
@prop {loading} boolean Показатель загрузки
*/

function Table(props) {
  const {
    children, id, short_last, loading,
  } = props;

  // Определения классов стилей
  const defineClass = () => {
    let className = 'upu-table';
    if (short_last) className += ' upu-table_short-last';
    if (loading) className += ' upu-table_loading';
    return className;
  };

  return <table id={id} className={defineClass()}>{children}</table>;
}

export default Table;
