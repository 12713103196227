import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import CategoryCard from './CategoryCard/CategoryCard';
import Loader from '../../../components/UI/Loader';
import { getCategories } from '../functions';
import THead from '../../../components/Table/THead';
import { createAction } from '../../../utils/redux/store';
import { log } from '../../../utils/functions/others';
import Table from '../../../components/Table/Table';
import TBody from '../../../components/Table/TBody';
import TRow from '../../../components/Table/TRow';
import TFoot from '../../../components/Table/TFoot';
import TData from '../../../components/Table/TData';
import ServiceBody from '../../../components/Service/ServiceBody';
import './categories.scss';

// Сервис анкетирования - вкладка "Категории"
function Categories() {
  const location = useLocation(); // адрес
  const [showCard, setShowCard] = useState(false); // состояние отображения карточки вопроса
  const [isLoading, setIsLoading] = useState(false); // Состояние загрузки
  const [currentCategoryId, setCurrentCategoryId] = useState(null); // выбранный вопрос
  const currentDirection = useSelector((state) => state.questionnaire.current_direction); // текущее направление
  const userDirections = useSelector((state) => state.questionnaire.user_directions); // направления доступные пользователю
  const displayedCategories = useSelector((state) => state.questionnaire.displayed_categories); // категории отображаемые в таблице
  const headers = [ // Заголовки полей с наименованиями
    { id: 1, title: 'Имя', field: 'title' },
    { id: 2, title: 'Направление', field: 'direction' },
    { id: 3, title: 'Родитель', field: 'parent' },
    { id: 4, title: 'Создал(а)', field: 'creator' },
    { id: 5, title: 'Создан', field: 'date_created' },
  ];

  useEffect(() => {
    awaitRequests();
  }, []);

  // Ожидание выполненияя необходимых запросов
  async function awaitRequests() {
    setIsLoading(true); // состояние - загружается
    await getCategories(location.pathname, currentDirection); // получить категории
    setIsLoading(false); // загрузка завершена
  }

  // Открыть карточку категории
  function openCategory(id) {
    setCurrentCategoryId(id);
    setShowCard(true); // показать карточку категории
    if (id === 'new') {
      log({ message: 'Пользователь нажал кнопку создания новой категории', eventtype: 'info' });
    } else {
      log({ message: `Пользователь открыл карточку категории id: ${id}`, eventtype: 'info' });
    }
  }
  // если загружается - показывается лоадер
  if (isLoading) return <ServiceBody><Loader /></ServiceBody>;
  return (
    <ServiceBody>
      {userDirections.length > 0 ? (// таблица категорий
      <Table id="categories__table">
        <THead
          title="Категории"
          headers={headers}
          array={displayedCategories}
          setArray={(array) => createAction('SET_DISPLAYED_CATEGORIES', array)}
        />

        <TBody>
          {displayedCategories.map((c) => (
            <TRow key={c.id} onClick={() => openCategory(c.id)} button>
              {headers.map((item) => {
                const { id, field } = item;
                return (
                  <TData key={id}>{c[field] || '-'}</TData>
                );
              })}
            </TRow>
          ))}
        </TBody>

        <TFoot>
          <TRow>
              <TData onClick={() => openCategory('new')}>Создать</TData>
          </TRow>
        </TFoot>
      </Table>
      ) : (
        <h2> Доступные направления отсутствуют</h2>
      )}

      {/* карточка категории (покажется, если showCard = true) */}
      {showCard && (
      <CategoryCard
        setShowCard={setShowCard}
        id={currentCategoryId}
      />)}
    </ServiceBody>
  );
}

export default Categories;
