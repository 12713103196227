import React, { useState } from 'react';
import { alertFunction } from '../../../utils/functions/alertFunction.js';
import { catchHandler } from '../../../utils/error_handling/error_handling';
import classes from '../Button/button.module.scss';
import Button from '../Button/Button';
import Loader from '../Loader';
import './fileUpload.scss';

/**
@component UI компонент для загрузки файлов
@prop {options} object Объект с настройками, включает в себя:
- handler {function} (Обязательный) Функция обработки файлов вызывается при нажатии на кнопку "Загрузить файлы"
- accept {string} Типы разрешенных для загрузки файлов. Если accept отсутствует, то разрешены все
- size {nubmer} Максимально допустимы размер файла
- message {string} - доп. сообщение отображаемое в форме
*/
function SingleFileUpload(props) {
  const { options, children } = props; // свойства компонента
  const {
    handler, accept, size, message,
  } = options; // настройки компонента
  const { button, full_width } = classes; // классы кнопок(для стилей)

  const [isOpen, setIsOpen] = useState(false); // состояние отображения формы
  const [isUploading, setIsUploading] = useState(false); // состояние загрузки файла
  const [isDropped, setIsDropped] = useState(false); //
  const [isDrag, setIsDrag] = useState(false);
  const [file, setFile] = useState(null);

  function dragStartHandler(e) {
    e.preventDefault();
    setIsDrag(true);
  }

  function dragLeaveHandler(e) {
    e.preventDefault();
    setIsDrag(false);
  }

  // Обработка драг файлов
  // arrayOfFiles - массив файлов сброшенных в дроп область
  function handleDragFile(e, arrayOfFiles) {
    e.preventDefault();
    setIsDrag(false);
    setFile(arrayOfFiles[0]); // используется только первый файл массива
    setIsDropped(true);
  }

  // Обработка драг файлов
  // arrayOfFiles - массив файлов сброшенных в дроп область
  function handleChoosedFile(e) {
    e.preventDefault();
    setFile(e.target.files[0]);
  }

  // Проверка перед загрузкой
  async function checkBeforeUpload(e) {
    try {
      if (handler) { // если есть обработчик файлов
        if (file) { // и выбран файл для загрузки
          if (file.size <= size || size === 0) {
            if (!accept || accept.includes(`${file.name.split('.').pop()}`)) {
              setIsUploading(true);
              await handler(file); // выполняется обработка файла
              setIsUploading(false);
              closeForm();
            } else alertFunction('verify_file_format', 'clientPost', accept); // иначе уведомление
          } else alertFunction('verify_file_size', 'clientPost', `${size / 1000000}Мб`); // иначе уведомление
        } else alertFunction('choose_file', 'clientPost'); // иначе уведомление
      } else {
        console.log('no handler');
        reset();
      }
    } catch (error) {
      catchHandler(error, 'checkBeforeUpload');
    }
  }

  function reset() {
    setIsUploading(false);
    setIsDropped(false);
    setIsDrag(false);
    setFile(null);
  }

  function closeForm() {
    reset();
    setIsOpen(false);
  }

  if (isOpen) { // Если открыта форма - показать ее
    return (
      <div className="file-upload">
        <div className="file-upload__form">
          <span className="file-upload__close" onClick={closeForm}>×</span>
          <h2 className="file-upload__title">Загрузка файла</h2>
          <div
            className={isDrag ? 'file-upload__drop-area file-upload__drop-area_active' : 'file-upload__drop-area'}
            onDragStart={dragStartHandler}
            onDragOver={dragStartHandler}
            onDragLeave={dragLeaveHandler}
            onDrop={(e) => handleDragFile(e, [...e.dataTransfer.files])}
          >
            {isUploading ? <Loader /> : (
            <div>
              <img className="file-upload__cloud-img" src="../../../icons/upload.svg" alt="cloud" />
              {isDrag ? (
              <p className="file-upload__subtitle">Отпустите, чтобы добавить файл к загрузке</p>
              ) : (
              <div>
                <p className="file-upload__subtitle">
                  Перетащите или
                  <label className="file-upload__label clickable" htmlFor="file-upload">
                      выберите файл
                      <input
                        id="file-upload"
                        type="file"
                        name="file"
                        accept={accept || '*'}
                        onChange={handleChoosedFile}
                        className="file-upload__input-file"
                      />
                  </label>
                </p>
                {message && <p className="file-upload__subtitle">{message}</p>}
              </div>)}
            </div>)}
          </div>
          {file && (
          <div>
            <span className="file-upload__uploading">Файл для загрузки</span>
            <div className="file-upload__info">
              <div className="file-upload__status" key={file.lastModified}>
                <span className="file-upload__filename">{file.name}</span>
                <span className="file-upload__delete" onClick={() => setFile(null)}>×</span>
              </div>
            </div>
          </div>)}
          <Button className={`${button} ${full_width}`} onClick={checkBeforeUpload}>Загрузить файл</Button>
        </div>
      </div>
    );
    // Иначе форма закрыта - показать элемент который вызывает ее
  }
  // если передается строка или детей нет - обернуть в кликабельный span
  if (typeof children === 'string' || !children) {
    return <span className="clickable" onClick={() => setIsOpen(true)}>{children || 'Загрузить'}</span>;
    // Иначе это не строка, берем первый элемент и вешаем на него слушатель
  }
  const element = children.length > 1 ? children[0] : children;
  return React.cloneElement(element, {
    onClick: () => {
      if (element.props?.onClick) element.props.onClick();
      setIsOpen(true);
    },
  });
}

export default SingleFileUpload;
