export const headers = {
  zones: [
    { title: 'Блок', key: 'block' },
    { title: 'Зона', key: 'title' },
    { title: 'Параметры', key: 'settings' },
    { title: 'Оборудование', key: 'hardware' },
    { title: 'Графическая схема', key: 'picture' },
  ],
  workplaces: [
    { title: 'Пространство', key: 'place' },
    { title: 'Место', key: 'title' },
    { title: 'Тип', key: 'type' },
    { title: 'Параметры', key: 'data' },
    { title: 'Порт', key: 'port' },
    { title: 'Статус порта', key: 'enabled' },
  ],
  hardwares: [
    { title: 'Наименование', key: 'title' },
    { title: 'Параметры', key: 'parameters' },
  ],
};
