import React from 'react';

function Instruction() {
  return (
    <div className="evaluation__instruction">
      <h3>Шкала оценки (оценки, отличные от B, требуют комментариев):</h3>
      <ul>
        <li>
          <strong>А</strong>
          (10-9) – Плановые показатели перевыполнены,
          реальные результаты превышают запланированные.
          Полное соответствие должностным требованиям,
        </li>
        <li>
          <strong>В</strong>
          (8-6) – Выполнены все поставленные задачи,
          реальные результаты на уровне запланированных.
          Соответствие должностным требованиям,
        </li>
        <li>
          <strong>С</strong>
          (5-3) – Поставленные задачи выполнены частично,
          необходимо дополнительное развитие, чтобы выйти
          на уровень запланированных результатов.
          Неполное соответствие должностным требованиям
        </li>
        <li>
          <strong>D</strong>
          (2-1) – Большинство запланированных результатов не достигнуто.
          Несоответствие должностным требованиям
        </li>
        <li>Цветом выделены задачи обязательные для грейда сотрудника</li>
      </ul>
    </div>
  );
}

export default Instruction;
