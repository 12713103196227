import React from 'react';

/**
* @component HTML элемент textarea с готовыми стилями
@prop все переданные свойства записываются в атрибуты textarea
*/

function TextArea({ children, ...props }) {
  return <textarea {...props} className="input textarea" />;
}

export default TextArea;
