import React, { useEffect, useState } from 'react';
import { catchHandler } from '../../../../utils/error_handling/error_handling';
import { alertFunction } from '../../../../utils/functions/alertFunction';
import { setOperInfo } from '../../../../utils/functions/others';
import { clientSendData } from '../../../../utils/functions/requests';
import Button from '../../../../components/UI/Button/Button';
import TabPanel from '../../../../components/UI/TabPanel';
import Logs from './Logs';
import Settings from './Settings';
import CardBody from '../../../../components/Card/CardBody';
import CardHeader from '../../../../components/Card/CardHeader';
import Card from '../../../../components/Card/Card';
import CardFooter from '../../../../components/Card/CardFooter';

/**
 * @component Администрирование базы знаний - подразделения - карточка подразделения - вкладка Схемы - карточка схемы
 * @prop {id} number - id схемы из kb_essences_scheme
 * @prop {getSchemes} function - получение схем для обновления таблицы
 * @prop {setShowCard} function - обновление состояния отображения карточки (открыть/закрыть)
*/
function SchemeCard(props) {
  const { id, setShowCard, getSchemes } = props;

  const [choice, setChoice] = useState(''); // состояние выбора вкладки
  const [scheme, setScheme] = useState({}); // Данные схемы
  const [newKey, setNewKey] = useState(false); // имя создаваемого ключа (для автофокуса)
  const [schemeData, setSchemeData] = useState({}); // Данные схемы
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const tabs = ['Настройки', 'История']; // вкладки
  const schemeType = schemeData?.type; // тип схемы (вопрос или тема)
  const isQuestionScheme = schemeType === 5;

  useEffect(() => {
    awaitRequests(); // выполнение запросов для получения необходимой информации
  }, []);

  async function awaitRequests() {
    setIsLoading(true); // состояние - загружается
    await getScheme(); // получить данные схемы
    setIsLoading(false); // загрузка завершена
  }

  // ----------------------Запросы данных-----------------------------

  // Получить данные активной схемы выбранного подразделения
  async function getScheme() {
    try {
      const reqData = {
        type: 'getScheme',
        scheme_id: id,
      };
      // Выполняется запрос на получение активной схемы
      const result = await clientSendData('POST', '/get_scheme', reqData);
      // Если схема получена - запись в состояние компонента
      if (result) {
        setSchemeData(result); // данные схемы (подразделение, заголовок, статус)
        setScheme(result?.scheme); // сама объект схемы
      }
    } catch (error) {
      catchHandler(error, 'getScheme');
    }
  }

  // Сохранить изменения схемы
  async function editScheme() {
    try {
      if (validateScheme()) { // если пройдена валидация
        const confirm = window.confirm('Сохранить?');
        if (confirm) { // если пользователь подтвердил сохранение
          const newSchemeData = { ...schemeData, scheme: prepareScheme(scheme) };
          const reqData = {
            type: 'editScheme',
            scheme: newSchemeData,
            action: 'edit',
          };

          const result = await clientSendData('POST', '/edit_scheme', reqData);
          if (result === 'success') {
            await getScheme(); // обновить схему
            await alertFunction('save_settings', 'clientPost');
          }
        }
      } else alertFunction('required_fields', 'clientPost');
    } catch (error) {
      catchHandler(error, 'editScheme');
    }
  }

  // ----------------------Локальная обработка данных-----------------------------

  // Подготовить схему к записи
  function prepareScheme(object) {
    const copy = { ...object }; // создать копию объекта схемы

    // // 1) Отсортировать поля по order
    // const sortedFields = Object.keys(copy).sort((a,b) => {
    //     const aOrder = copy[a]?.order
    //     const bOrder = copy[b]?.order
    //     // Показать сначала те поля, у которых есть параметр order, затем остальные
    //     if (!aOrder) return 1;
    //     if (!bOrder) return -1;
    //     if (aOrder === bOrder) return 0;
    //     return aOrder < bOrder ? -1 : 1
    // })

    // copy = sortedFields.reduce((acc, key) => {
    //     acc[key] = copy[key]
    //     return acc
    // }, {})

    // 2) Экранировать одинарные кавычки в switch_сolumn
    Object.keys(copy).forEach((key) => { // пройти по ключам схемы
      const keyData = copy[key]; // настройки ключа
      if (keyData?.switch) {
        const switchData = keyData?.switch;
        // если в настройках активирован switch
        // Добавляем к каждой одинарной кавычке еще одну
        switchData.switch_column = (switchData?.switch_column)?.replaceAll("'", "''");
        switchData.parent_key = (switchData?.parent_key)?.replaceAll("'", "''");

        if (switchData?.switch_clause) {
          const switchClauseData = switchData.switch_clause;
          switchClauseData.clauses = switchClauseData.clauses.map((item) => item.replaceAll("'", "''"));
        }
      }
    });

    return copy;
  }

  // Валидация схемы
  function validateScheme() {
    // Проверка настроек полей схемы
    const checkFields = Object.keys(scheme).map((field) => {
      const setting = scheme?.[field];
      // если объект с настройками поля отсутствует
      if (!setting) return false;
      // если отсутствует тип или заголовок
      if (!setting?.type || !setting?.name) return false;
      // если поле нужно показывать в таблице, но не выбран порядок
      if (setting?.show_in_table && !setting?.show_in_table?.order) return false;
      // если есть условие обязательности заполнения поля, но не выбран ключ, у которого проверяется значение
      if (setting?.required_clause && !setting.required_clause?.key) return false;
      // если в поле включен switch,
      if (setting?.switch) {
        const switchData = setting?.switch; // switch данные ключа
        // если схема для вопроса, но не указаны таблица или колонка
        if (isQuestionScheme && (!switchData?.switch_table || !switchData?.switch_column)) return false;
        // если есть условие для подмены, но не указаны параметры условия
        if (switchData?.switch_clause && switchData.switch_clause?.clauses.length === 0) return false;
        return true; // в ином случае проверка поля прошла успешно
      } return true; // в ином случае проверка поля прошла успешно
    });

    // Если объект схемы пустой - валидация не пройдена
    if (Object.keys(scheme).length === 0) return false;
    // Если результат проверки полей содержит хотя бы одно falsey значение - валидация не пройдена
    if (checkFields.includes(false)) return false;
    // Иначе валидация пройдена
    return true;
  }

  // Добавить новое поле в схему
  function addNewField() {
    let fieldName = `new_field_${Object.keys(scheme).length + 1}`; // new_field_(номер поля по порядку)

    // Найти в схеме поле с именем fieldName
    const isKeyAlreadyExist = Object.keys(scheme).find((row) => row === fieldName);
    // если такое поле существует - добавить к имени _copy
    if (isKeyAlreadyExist) fieldName += '_copy';
    setNewKey(fieldName);
    setScheme({ // Добавить в схему новое поле со значениями по умолчанию
      ...scheme,
      [fieldName]: {
        name: 'Наименование колонки',
        type: 'string',
        searchable: false,
        show_in_table: false,
        required: false,
        switch: null,
      },
    });
  }

  // Отменить редактирование или создание и закрыть карточку
  function closeHandler() {
    setOperInfo({ subject_id: null }); // сброс id категории в оперативной информацию
    setShowCard(false);
  }

  // Обработчик выбора вкладки
  function choiceHandler(tab) {
    setChoice(tab); // переключение вкладки
  }

  return (
    <Card id="scheme__card" setShow={closeHandler} loading={isLoading}>
      {/* Хедер карточки */}
      <CardHeader>
        <TabPanel tabs={tabs} choice={choice} choiceHandler={choiceHandler} />
      </CardHeader>

      {/* Тело карточки */}
      <CardBody>
        {/* Вкладка настроек */}
        {choice === 'Настройки' && (
        <Settings
          scheme={scheme}
          setScheme={setScheme}
          schemeData={schemeData}
          setSchemeData={setSchemeData}
          setShowCard={setShowCard}
          getSchemes={getSchemes}
          newKey={newKey}
        />)}

        {/* Вкладка истории */}
        {choice === 'История' && (
        <Logs
          id={id}
          type={schemeType}
        />)}
      </CardBody>

      {/* Футер карточки */}
      {choice === 'Настройки' && (
      <CardFooter>
        <Button onClick={editScheme}>Сохранить</Button>
        <Button onClick={addNewField}>Добавить поле</Button>
      </CardFooter>)}
    </Card>
  );
}

export default SchemeCard;
