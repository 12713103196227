import React, { useEffect, useState } from 'react';
import Loader from '../../../../components/UI/Loader';
import TableComponent from '../../../../components/UI/TableComponent';
import { getSubjectLog } from '../../functions';
import '../questionnairies.scss';

// Сервис анкетирования - вкладка "Анкеты" - карточка анкеты - вкладка "Изменения"
function Logs(props) {
  const { id } = props;
  const [logs, setLogs] = useState([]); // логи по направлению
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки

  useEffect(() => {
    getLogs(); // получить логи
  }, []);

  // Обёртка для функции получения логов для управления состоянием загрузки
  async function getLogs() {
    setIsLoading(true); // состояние - загружается
    await getSubjectLog('questionnaire', id, setLogs); // унифицированная функция получения логов
    setIsLoading(false); // загрузка завершена
  }
  // если загружается - показывается лоадер
  if (isLoading) return <div className="questions-card__block"><Loader /></div>;
  return (
    <div className="questions-card__block">
      {/* Если логи не пустые - показывается таблица, иначе заголовок об их отсутствии */}
      {logs.length > 0 ? (
      <TableComponent id="questionnaire-questionnairies__logs" data={logs} />
      ) : (
      <h2 className="upu-service__title">История отсутствует</h2>)}
    </div>
  );
}

export default Logs;
