import React, { useEffect, useState } from 'react';
import { clientSendData } from '../../utils/functions/requests';
import { catchHandler } from '../../utils/error_handling/error_handling';
import ServiceBody from '../../components/Service/ServiceBody';
import Table from '../../components/Table/Table';
import THead from '../../components/Table/THead';
import TBody from '../../components/Table/TBody';
import TRow from '../../components/Table/TRow';
import TData from '../../components/Table/TData';
import { headers } from './common';
import CheckMark from '../../components/UI/CheckMark';
import Wrapper from '../../components/Basic/Wrapper';
import Cancel from '../../components/UI/Cancel';
import Select from '../../components/UI/Select';
import TextArea from '../../components/UI/TextArea';
import EditorButton from '../../components/UI/Button/EditorButton';
import Input from '../../components/UI/Input';
import TFoot from '../../components/Table/TFoot';
import CardSetting from '../../components/Card/CardSetting';

function Categories() {
  const [categories, setCategories] = useState([]);
  const [selCategories, setSelCategories] = useState([]);
  const [changeData, setChangeData] = useState({});
  const [isEditing, setIsEditing] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [createCategory, setCreateCategory] = useState(false);

  useEffect(() => {
    getSDServicesAdm();
  }, []);

  async function getSDServicesAdm() {
    try {
      const reqData = {
        type: 'getSDServicesAdm',
      };
      const result = await clientSendData('POST', '/get_sd_services_adm', reqData); // запрос в БД
      if (result) {
        setCategories(result.data); // запись в переменную
        setSelCategories(() => ([{ id: 0, title: 'нет' }, ...result.categories]));
      }
    } catch (error) {
      catchHandler(error, 'getSDServicesAdm'); // обработчик ошибок
    }
  }

  async function editSDServicesAdm(obj) {
    try {
      const reqData = {
        type: 'editSDServicesAdm',
        ...obj,
      };
      const result = await clientSendData('POST', '/edit_sd_services_adm', reqData); // запрос в БД
      if (result) {
        setChangeData({});
        setIsEditing(null);
        setCreateCategory(false);
        setSelectedCategory({});
        await getSDServicesAdm();
      }
    } catch (error) {
      catchHandler(error, 'editSDServicesAdm'); // обработчик ошибок
    }
  }

  function editing(el) {
    setIsEditing(el.id);
    setSelectedCategory({ title: el.parent_title });
    setChangeData({});
  }

  function changeName(title, id = null) {
    setChangeData((state) => ({ ...state, id, title }));
  }
  function changeDescription(description, id = null) {
    setChangeData((state) => ({ ...state, id, description }));
  }

  function cancelEditing() {
    setIsEditing(null);
    setChangeData({});
  }

  async function saveEditing() {
    if (Object.keys(changeData).length > 0) {
      const obj = {
        data: changeData,
        action: 'change',
      };
      await editSDServicesAdm(obj);
    } else {
      setIsEditing(null);
    }
  }
  function choose(category, id = 0) {
    setChangeData((state) => ({
      ...state,
      id,
      parent_id: category.id,
      parent_title: category.title,
    }));
    setSelectedCategory({ title: category.title });
  }
  async function createNewCategory() {
    const obj = {
      data: changeData,
      action: 'create',
    };
    await editSDServicesAdm(obj);
  }
  async function deleteCategory(id) {
    const obj = {
      data: { id },
      action: 'delete',
    };
    await editSDServicesAdm(obj);
  }

  return (
    <ServiceBody>
      <Table id="user-in-groups">
            <THead
              title="Сервисы"
              headers={headers.services}
            />
            <TBody>
              {categories.map((d) => (
                <TRow key={`${d.id}`}>
                  {isEditing === d.id
                    ? <>
                        <TData>
                          <Wrapper>
                          <TextArea
                            defaultValue={d.title}
                            onChange={(e) => changeName(e.target.value, d.id)}
                          />
                          <CheckMark onClick={saveEditing} />
                          <Cancel onClick={cancelEditing} />
                          <EditorButton onClick={() => deleteCategory(d.id)} icon="delete" />
                          </Wrapper>
                        </TData>
                        <TData>
                        <CardSetting title="Родительский сервис">
                          <Select
                            array={selCategories.filter((el) => el.id !== d.id)}
                            onChoose={(category) => choose(category, d.id)}
                            defaultValue={selectedCategory?.title}
                            search
                          />
                        </CardSetting>
                        <CardSetting title="Описание">
                          <TextArea
                            defaultValue={d.description}
                            placeholder="Введите описание"
                            onChange={(e) => changeDescription(e.target.value, d.id)}
                          />
                        </CardSetting>
                        </TData>
                      </>
                    : <>
                        <TData>
                          <span className="clickable" onClick={() => editing(d)}>
                            {d.parent_title ? `${d.parent_title} (${d.title})` : d.title}
                          </span>
                        </TData>
                        <TData>{d.description}</TData>
                      </>
                  }
                </TRow>
              ))}
            </TBody>
            { /* Футер */ }
          <TFoot>
            {createCategory ? (
                <TRow>
                    <TData>
                    <Wrapper>
                        <Input
                          placeholder="Введите название категории"
                          onChange={(e) => changeName(e.target.value)}
                        />
                        <Select
                          id="select-create-category"
                          array={selCategories}
                          onChoose={(category) => choose(category)}
                          defaultValue={selectedCategory?.title}
                          search
                        />
                        <TextArea
                          placeholder="Введите описание"
                          onChange={(e) => changeDescription(e.target.value)}
                        />
                        <CheckMark onClick={createNewCategory} />
                        <Cancel onClick={() => setCreateCategory(false)} />
                    </Wrapper>
                    </TData>
                </TRow>

            ) : (
                <TRow>
                    <TData onClick={() => setCreateCategory(true)}>Создать сервис</TData>
                </TRow>
            )}
          </TFoot>
      </Table>
    </ServiceBody>);
}

export default Categories;
