import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { json, useLocation } from 'react-router';
import { setOperInfo } from '../../../../utils/functions/others';
import { catchHandler } from '../../../../utils/error_handling/error_handling';
import { alertFunction } from '../../../../utils/functions/alertFunction';
import { clientSendData } from '../../../../utils/functions/requests';
import { getQuestionnairies } from '../../functions';
import QuestionnaireAccess from './QuestionnaireAccess';
import TabPanel from '../../../../components/UI/TabPanel';
import Button from '../../../../components/UI/Button/Button';
import CardFooter from '../../../../components/Card/CardFooter';
import CardHeader from '../../../../components/Card/CardHeader';
import CardBody from '../../../../components/Card/CardBody';
import Card from '../../../../components/Card/Card';
import Questions from './Questions';
import Settings from './Settings';
import Logs from './Logs';

// Сервис анкетирования - вкладка "Анкеты" - карточка анкеты
function QuestionnaireCard(props) {
  const { setShowCard, id } = props; // свойства передаваемые компоненту при вызове

  const location = useLocation(); // адрес
  const [choice, setChoice] = useState(''); // состояние выбора вкладки
  const [isLoading, setIsLoading] = useState(false); // Состояние загрузки
  const [currentQuestionnaire, setCurrentQuestionnaire] = useState(null); // текущая анкета, отображаемая в карточке
  const [questionnairiesOutputs, setQuestionnairiesOutputs] = useState([]); // типы отображения анкет
  const currentDirection = useSelector((state) => state.questionnaire.current_direction); // текущее направление
  const isAllDirections = currentDirection.id === 'all'; // Выбраны все направления?
  const isNewQuestionnaire = id === 'new'; // анкета создаётся?

  const tabs = ['Настройки', 'Вопросы', !isNewQuestionnaire && 'История', !isNewQuestionnaire && 'Доступы']; // вкладки

  useEffect(() => {
    awaitRequests();
  }, []);

  // Ожидание выполнения необходимых запросов
  async function awaitRequests() {
    try {
      setOperInfo({ subject_id: id }); // запись id анкеты в оперативную информацию
      setIsLoading(true); // состояние - загружается
      await getQuestionnaire(); // получить информацию об анкете
      if (id === 'new') { // если анкета создаётся
        setCurrentQuestionnaire({ // создать и поместить в хранилище объект новой анкеты
          questionnaire: {
            id: 'new',
            // если выбрано конкретное направление, подставляются его данные, иначе данные первого, доступного пользователю направления
            group: !isAllDirections ? currentDirection.title : null,
            group_id: !isAllDirections ? currentDirection.id : null,
          },
          questions: [], // пустой массив вопросов (сюда добавляются выбранные вопросы)
        }); // запись в хранилище информации об анкете
        setIsLoading(false); // загрузка завершена
      }
      setIsLoading(false); // загрузка завершена
    } catch (error) {
      catchHandler(error, 'awaitRequests');
    }
  }

  // Получить информацию об анкете
  async function getQuestionnaire() {
    try {
      const reqData = {
        type: 'getQuestionnaire',
        id, // id анкеты
      };

      const result = await clientSendData('POST', '/get_questionnaire', reqData); // запрос в БД
      if (result) {
        setQuestionnairiesOutputs(result.questionnaire_outputs);
        if (id !== 'new' && result.questions.length > 0 && Object.hasOwn(result.questionnaire, 'sequence') && result.questionnaire.sequence !== null) {
          const tempJsonSequenceObject = JSON.parse(result.questionnaire.sequence);
          const tempQuestions = [];
          for (const key in tempJsonSequenceObject) {
            if (Object.hasOwn(tempJsonSequenceObject, key)) {
              const tempEl = { id: Number(key) };
              for (const field in tempJsonSequenceObject[key]) {
                if (Object.hasOwn(tempJsonSequenceObject[key], field)) {
                  tempEl[field] = tempJsonSequenceObject[key][field];
                }
              }
              tempQuestions.push(tempEl);
            }
          }
          setCurrentQuestionnaire({
            questionnaire: result.questionnaire, // данные анкеты
            questions: tempQuestions, // данные связанных вопросов
          });
        } else {
          // ЗАКОММЕНТИРОВАНО, ПОСКОЛЬКУ ВОПРОСЫ БЕРУТСЯ ИЗ SEQUENCE
          setCurrentQuestionnaire({
            questionnaire: result.questionnaire, // данные анкеты
            questions: result.questions, // данные связанных вопросов
          }); // запись информации об анкете
        }
      }
    } catch (error) {
      catchHandler(error, 'getQuestionnaire');
    }
  }

  // Отменить редактирование или создание и закрыть карточку
  function cancelAndClose() {
    setOperInfo({ subject_id: null }); // сброс id анкеты в оперативной информации
    setShowCard(false);
  }

  function verifySequence(obj) {
    try {
      const questionnaire = { ...obj };
      const a = JSON.parse(questionnaire.sequence);
      questionnaire.sequence = a;
      return questionnaire;
    } catch (error) {
      catchHandler(error, 'verifySequence');
      return false;
    }
  }

  // Сохранить изменения в анкете
  async function saveQuestionnaire() {
    try {
      const sequence = {};
      currentQuestionnaire.questions.forEach((el, index) => {
        sequence[el.id] = {
          break: el.break != null ? el.break : null,
          number: el.index != null ? el.index : index,
          dependence: el.dependence != null ? el.dependence : null,
          dependence_answer: el.dependence_answer != null ? el.dependence_answer : null,
        };
      });
      setCurrentQuestionnaire({
        ...currentQuestionnaire,
        questionnaire: {
          ...currentQuestionnaire.questionnaire,
          sequence: JSON.stringify(sequence, null, ' '),
        },
      }); // запись в хранилище

      const { questionnaire, questions } = currentQuestionnaire; // деструктуризация объекта анкеты
      const reqData = {
        type: 'editQuestionnaire',
        questionnaire: questionnaire.output_id === 4 ? verifySequence(questionnaire) : questionnaire, // данные анкеты
        questions, // вопросы
      };
      // Если не задано имя анкеты - уведомление
      if (!questionnaire.title || !questionnaire.output_id) { // если не задано имя анкеты
        alertFunction('required_fields', 'clientPost'); // уведомление
      } else { // иначе
        // eslint-disable-next-line no-lonely-if
        if (reqData.questionnaire) {
          setIsLoading(true); // состояние - загружается
          const result = await clientSendData('POST', '/edit_questionnaire', reqData); // запрос в БД
          if (result === 'success') { // если успех
            alertFunction('save_settings', 'clientPost'); // уведомление "Настройки сохранены"
            await getQuestionnairies(location.pathname, currentDirection); // обновление данных об анкетах
            if (isNewQuestionnaire) setShowCard(false); // если анкета создаётся - карточка закрывается
          }
          setIsLoading(false); // загрузка завершена
        } else {
          alertFunction('sequence_fail', 'clientPost');
        }
      }
    } catch (error) {
      catchHandler(error, 'saveQuestionnaire');
      setIsLoading(false); // загрузка завершена
    }
  }

  return (
    <Card id="questionnaire__questionnaire-card" setShow={cancelAndClose} loading={isLoading}>
      <CardHeader>
          <TabPanel tabs={tabs} choice={choice} choiceHandler={setChoice} />
      </CardHeader>
      <CardBody>
        {/* Вкладка параметров */}
        {choice === 'Настройки' && (
        <Settings
          getQuestionnairies={getQuestionnairies}
          setShowCard={setShowCard}
          currentQuestionnaire={currentQuestionnaire}
          setCurrentQuestionnaire={setCurrentQuestionnaire}
          questionnairiesOutputs={questionnairiesOutputs}
        />)}

        {/* Вкладка вопросов */}
        {choice === 'Вопросы' && (
        <Questions
          currentQuestionnaire={currentQuestionnaire}
          setCurrentQuestionnaire={setCurrentQuestionnaire}
          saveQuestionnaire={saveQuestionnaire}
        />)}

        {/* Вкладка истории */}
        {choice === 'История' && (
        <Logs
          id={currentQuestionnaire.questionnaire.id}
        />)}

        {/* Вкладка доступов */}
        {choice === 'Доступы' && (
        <QuestionnaireAccess
          currentQuestionnaire={currentQuestionnaire}
        />)}
      </CardBody>

      {choice !== 'Доступы' && choice !== 'История' && (
      <CardFooter>
          <Button onClick={cancelAndClose}>Отменить</Button>
          <Button onClick={saveQuestionnaire}>Сохранить</Button>
      </CardFooter>)}
    </Card>
  );
}

export default QuestionnaireCard;
