import React, { useEffect, useState } from 'react';
import { catchHandler } from '../../../utils/error_handling/error_handling';
import { clientSendData } from '../../../utils/functions/requests';
import Table from '../../../components/Table/Table';
import THead from '../../../components/Table/THead';
import TBody from '../../../components/Table/TBody';
import TRow from '../../../components/Table/TRow';
import TData from '../../../components/Table/TData';
import StatusCard from './StatusCard';
import TFoot from '../../../components/Table/TFoot';

/**
* @component Состояние портала - интерфейс администратора - Статусы
*/
function Statuses() {
  const [statuses, setStatuses] = useState([]); // все статусы
  const [openedStatus, setOpenedStatus] = useState();
  const [showCard, setShowCard] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const headers = [
    { title: 'Цвет', field: 'color' },
    { title: 'Название', field: 'title', align: 'left' },
    { title: 'Сообщение', field: 'message', align: 'left' },
  ];

  useEffect(() => {
    handlePsStatuses('get');
  }, []);

  // Обработка действий со статусами
  async function handlePsStatuses(action) {
    try {
      const reqData = {
        type: 'handlePsStatuses',
        status: openedStatus,
        action,
      };

      setIsLoading(true);
      const result = await clientSendData('POST', '/handle_ps_statuses', reqData);
      if (result?.success) setStatuses(result.data);
    } catch (error) {
      catchHandler(error, 'handlePsStatuses');
    } finally {
      setIsLoading(false);
    }
  }

  function openStatus(status) {
    setOpenedStatus(status);
    setShowCard(true);
  }
  // Если параметр отображения карточки - true, показываем карточку
  if (showCard) {
    return (
      <StatusCard
        status={openedStatus}
        setStatus={setOpenedStatus}
        setShow={setShowCard}
        handler={handlePsStatuses}
      />
    );
  } return (
    <Table id="portal-state__statuses">
      <THead title="Статусы">
        <TRow>
          {headers.map((header) => {
            const { title, field, align } = header;
            return <TData align={align} key={field}>{title}</TData>;
          })}
        </TRow>
      </THead>
      <TBody>
        {statuses.map((status) => (
          <TRow key={status.id} onClick={() => openStatus(status)} button>
            {headers.map((header) => {
              const { field, align } = header;
              return (
                <TData align={align} key={field}>
                  {field !== 'color'
                    ? status[field]
                    : <div style={{
                      backgroundColor: status[field],
                      width: '28px',
                      height: '28px',
                      borderRadius: '50%',
                    }}
                    />}
                </TData>
              );
            })}
          </TRow>
        ))}
      </TBody>
      <TFoot>
        <TRow>
          <TData onClick={() => openStatus({ id: 'new' })} loading={isLoading}>Добавить статус</TData>
        </TRow>
      </TFoot>
    </Table>
  );
}

export default Statuses;
