import React, { useEffect, useState } from 'react';
import { clientSendData } from '../../../utils/functions/requests';
import { alertFunction } from '../../../utils/functions/alertFunction';
import { catchHandler } from '../../../utils/error_handling/error_handling';
import Table from '../../../components/Table/Table';
import THead from '../../../components/Table/THead';
import TBody from '../../../components/Table/TBody';
import TFoot from '../../../components/Table/TFoot';
import TRow from '../../../components/Table/TRow';
import TData from '../../../components/Table/TData';
import Toggle from '../../../components/UI/Toggle';
import ServiceCard from './ServiceCard';

/**
* @component Состояние портала - интерфейс администратора - Сервисы
*/
function Services() {
  const [services, setServices] = useState([]); // все сервисы
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [openedService, setOpenedService] = useState({}); // открытый в карточке сервис
  const [showCard, setShowCard] = useState(false); // состояние отображения карточки
  const headers = [ // Заголовки таблицы
    { title: 'Наименование', field: 'title', align: 'left' },
    { title: 'Описание', field: 'description', align: 'left' },
    { title: 'Статус', field: 'status_title', align: 'left' },
    { title: 'Состояние', field: 'enabled' },
  ];

  useEffect(() => {
    handlePsServices('get');
  }, []);

  // Обработка действий с сервисами
  async function handlePsServices(action) {
    try {
      const reqData = {
        type: 'handlePsServices',
        service: openedService, // объект сервиса
        action, // действие
      };

      setIsLoading(true);
      const result = await clientSendData('POST', '/handle_ps_services', reqData);
      // Независимо от action - с маршрута возвращается обновленный массив сервисов
      if (result?.success) setServices(result.data);
      setIsLoading(false);
    } catch (error) {
      catchHandler(error, 'handlePsServices');
    }
  }

  // Переключение состояния сервиса
  async function toggleServiceState(e, prevState, id) {
    e.stopPropagation();
    try {
      const reqData = {
        type: 'toggleServiceState',
        prev_state: prevState, // предыдущее состояние сервиса
        id, // id сервиса
      };

      setIsLoading(true);
      const result = await clientSendData('POST', '/toggle_service_state', reqData);
      if (result?.success) setServices(result.data);
    } catch (error) {
      catchHandler(error, 'toggleServiceState');
    } finally {
      setIsLoading(false);
    }
  }

  function openCard(object) {
    setOpenedService(object);
    setShowCard(true);
  }

  // Если параметр отображения карточки - true, показываем карточку
  if (showCard) {
    return (
      <ServiceCard
        service={openedService}
        setService={setOpenedService}
        setShow={setShowCard}
        handler={handlePsServices}
      />
    );
  }
  return (
  <Table id="portal-state__services">
    <THead title="Сервисы">
      <TRow>
        {headers.map((item) => <TData key={item.field} align={item.align}>{item.title}</TData>)}
      </TRow>
    </THead>
    <TBody>
      {services.map((row) => (
        <TRow key={row.id} onClick={() => openCard(row)} button>
          {headers.map((column) => {
            const { field, align } = column; // дектруктуризация объекта колонки
            const value = row?.[field]; // значение текущего ключа в объекте строки
            return (
              <TData key={field} loading={isLoading} align={align}>
                {/* Если это НЕ поле enabled, то показываем значение */}
                {field !== 'enabled' ? value : (
                // иначе это поле enabled, показываем тоггл
                <Toggle state={String(value)} onClick={(e) => toggleServiceState(e, value, row.id)} />)}
              </TData>
            );
          })}
        </TRow>
      ))}
    </TBody>
    <TFoot>
      <TRow>
        <TData onClick={() => openCard({ id: 'new' })} loading={isLoading}>Создать</TData>
      </TRow>
    </TFoot>
  </Table>
  );
}

export default Services;
