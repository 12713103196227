import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Button from '../../../components/UI/Button/Button';
import { setOperInfo } from '../../../utils/functions/others';
import Card from '../../../components/Card/Card';
import CardHeader from '../../../components/Card/CardHeader';
import CardBody from '../../../components/Card/CardBody';
import CardFooter from '../../../components/Card/CardFooter';
import CardSetting from '../../../components/Card/CardSetting';
import { getTask } from '../functions';

/**
* @component Задачи пользователя - карточка задачи
* @prop {setShow} function - Функция обновления состояния отображения карточки
*/
function UserTaskCard(props) {
  const { setShow } = props; // свойства передаваемые компоненту при вызове

  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [searchParams, setSearchParams] = useSearchParams(); // параметры поиска url
  const [task, setTask] = useState({}); // текущая задача
  const taskId = searchParams.get('task'); // параметры url

  useEffect(() => {
    awaitRequests();
    // eslint-disable-next-line
    }, [])

  // Ожидание выполнения необходимых запросов
  async function awaitRequests() {
    setIsLoading(true);
    setOperInfo({ subject_id: taskId });
    const result = await getTask(taskId, 'user'); // получаем данные задачи
    setIsLoading(false);
    if (result) setTask(result); // если есть результат - записать в состояние
    else cancelAndClose(); // иначе закрыть карточку
  }

  // Закрыть карточку
  function cancelAndClose() {
    setOperInfo({ subject_id: null });
    setSearchParams('');
    setShow(false);
  }

  return (
    <Card id="user-tasks__card" setShow={cancelAndClose} loading={isLoading}>
      <CardHeader />

      <CardBody>
        <CardSetting title="Наименование задачи:">{task.long_title}</CardSetting>
        <CardSetting title="Описание:">
          <span dangerouslySetInnerHTML={{ __html: task.long_text }} />
        </CardSetting>
        <CardSetting title="Статус:">{task.status_title}</CardSetting>
        <CardSetting title="Источник:">{task.source_title}</CardSetting>
        <CardSetting title="Менеджер:">{task.manager}</CardSetting>
        {task.date_start && <CardSetting title="Дата постановки задачи:">{task.date_start}</CardSetting>}
        {task.date_end && <CardSetting title="Выполнить до:">{task.date_end}</CardSetting>}
      </CardBody>

      <CardFooter>
        <Button onClick={cancelAndClose}>Закрыть</Button>
      </CardFooter>
    </Card>
  );
}

export default UserTaskCard;
