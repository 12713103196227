import React, { useEffect, useState } from 'react';
import CheckBox from '../../../../components/UI/CheckBox';
import Loader from '../../../../components/UI/Loader';
import { getSubjectLog } from '../../functions';

// Сервис анкетирования - вкладка "Вопросы" - карточка вопроса - вкладка История
function Logs(props) {
  const { id } = props;
  const [logs, setLogs] = useState([]); // логи по направлению
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки

  useEffect(() => {
    getLogs(); // получить логи
  }, []);

  // Обёртка функции получения логов для управления состоянием загрузки
  async function getLogs() {
    setIsLoading(true); // состояние - загружается
    await getSubjectLog('question', id, setLogs); // унифицированная функция получения логов
    setIsLoading(false); // загрузка завершена
  }

  if (isLoading) return <div className="questions-card__block"><Loader /></div>; // если загружается - показывается лоадер
  if (logs.length > 0) {
    return (
      <div className="questions-card__block">
        {logs.map((entry) => {
          const { date, performer, message } = entry;
          const {
            category,
            group,
            question,
            answers,
            title,
            output_title,
          } = message;

          return (
            <div key={entry.id} className="question-history">
              <div className="question-history__info">
                <div className="question-history__info-row">{date}</div>
                <div className="question-history__info-row">{`Направление: ${group}`}</div>
                <div className="question-history__info-row">{performer}</div>
                <div className="question-history__info-row">{`Категория: ${category}`}</div>
              </div>
              <p className="question-history__title">{title}</p>
              <div className="question-history__question">{question}</div>
              <div className="question-history__answers">
                <p className="question-history__title">{`Ответы: ${output_title || ''}`}</p>
                {answers.map((a) => {
                  const { answer, correct } = a;
                  return (
                    <div key={a.id} className="question-history__answer">
                      <div className="question-history__answer-content">{answer}</div>
                      <CheckBox disabled defaultChecked={correct} />
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
  return <div className="questions-card__block"><h2 className="upu-service__title">История отсутствует</h2></div>;
}

export default Logs;
