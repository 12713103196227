import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { setOperInfo } from '../../../utils/functions/others';
import { clientSendData } from '../../../utils/functions/requests';
import { catchHandler } from '../../../utils/error_handling/error_handling';
import Loader from '../../../components/UI/Loader';
import Service from '../../../components/Service/Service';
import ServiceBody from '../../../components/Service/ServiceBody';

/**
* @component Страница обработки подтверждения и отмены бронирования рабочего места
*/
function BookingPage() {
  const { action, id } = useParams(); // параметр ссылки
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [result, setResult] = useState(''); // результат запроса

  useEffect(() => {
    setOperInfo();
    if (!action) setResult('no action');
    else if (!id) setResult('no booking id');
    else performBookingAction();
  }, []);

  // Выполнить подтверждение/отмену бронирования
  async function performBookingAction() {
    try {
      const reqData = {
        type: 'performBookingAction',
        action,
        id,
      };
      setIsLoading(true); // загрузка началась
      const actionResult = await clientSendData('POST', '/perform_booking_action', reqData);
      if (actionResult?.success) {
        setResult(actionResult?.result); // записать результат в состояние
      }
    } catch (error) {
      catchHandler(error, 'performBookingAction');
    } finally {
      setIsLoading(false); // загрузка завершена
    }
  }

  // Сообщение для отображения пользователю
  const message = (keyword) => {
    switch (keyword) {
      case 'no action': return 'Действие не определено, проверьте ссылку';
      case 'no booking id': return 'Некорректный идентификатор бронирования, проверьте ссылку';
      case 'no booking': return 'Бронирование не найдено';
      case 'incorrect user cancel': return 'Вы не можете отменить это бронирование';
      case 'already canceled': return 'Бронирование уже отменено';
      case 'already completed': return 'Бронирование уже завершено';
      case 'canceled': return 'Бронирование успешно отменено';
      case 'incorrect user verify': return 'Вы не можете подтвердить это бронирование';
      case 'already verified': return 'Бронирование уже подтверждено';
      case 'cannot verify canceled': return 'Невозможно подтвердить отменённое бронирование';
      case 'cannot verify completed': return 'Невозможно подтвердить завершённое бронирование';
      case 'verified': return 'Бронирование успешно подтверждено';
      case 'time has not come': return 'Невозможно подтвердить бронирование заранее';
      case 'wrong ip': return 'Подтвердить бронирование можно только из офиса';
      default: return 'Ошибка обработки действия';
    }
  };

  if (isLoading) return <Loader />;
  return (
    <Service>
      <ServiceBody>
        {message(result)}
      </ServiceBody>
    </Service>
  );
}

export default BookingPage;
