import React, { useEffect } from 'react';
import Chart from 'chart.js/auto';
import { createAction } from '../../../../utils/redux/store';
import { catchHandler } from '../../../../utils/error_handling/error_handling';

/**
* @component Обёртка канваса с графиком
* @prop {id} string - (Обязательный) Идентификатор DOM элемента канваса
* @prop {labels} array - (Обязательный) Наименования областей графика
* @prop {values} array - (Обязательный) Значения областей
* @prop {values2} array - (Обязательный) Значения областей графика
* @prop {type} string - Кнопка "Вернуться назад"
* @prop {title} string - Заголовок канваса
* @prop {label1} string - Название графика (рендерится на канвасе)
* @prop {label2} string - Название графика (рендерится на канвасе)
* @prop {legend_position} string - Расположение легенды графика
*/
function Canvas(props) {
  const {
    id, labels, values, values2, type = 'pie', title = '', label, label2, legend_position = 'left',
  } = props;

  useEffect(() => {
    renderGraph();
  }, []);

  const colours = ['#0F1955', '#22409A', '#28B47D', '#00EE69', '#E7E7E7', '#D9E49E', '#A192B2', '#6678B1', '#A2B2C8', '#EFDBB2', '#E1B7A7', '#697C8F'];

  // Создать список цветов в зависимости от количества значений
  // length - длина массива
  function makeColoursList(length) {
    const result = [];
    for (let i = 0; i <= length; i++) {
      // Изначально добавляются по порядку все цвета в массиве
      if (i < colours.length) result.push(colours[i]);
      else {
        // Затем дополняются рандомно
        const index = Math.floor(Math.random() * colours.length);
        result.push(colours[index]);
      }
    }
    return result;
  }

  // Данные графика
  const data = {
    labels,
    datasets: [
      {
        label,
        data: values,
        backgroundColor: label2 ? '#28B47D' : makeColoursList(values.length),
        maxBarThickness: 100,
        minBarLength: 9,
      },
      ...(label2 ? [
        {
          label: label2,
          data: values2,
          backgroundColor: '#0F1955',
          maxBarThickness: 100,
          minBarLength: 9,
        },
      ] : []),
    ],
  };

  // Настройки графика
  const config = {
    type,
    data,
    options: {
      indexAxis: 'x',
      responsive: false,
      elements: {
        bar: {
          borderRadius: 8,
        },
      },
      layout: {
        padding: {
          top: 32,
          bottom: 24,
        },
      },
      plugins: {
        legend: {
          position: legend_position,
        },
        title: {
          display: false,
          text: title, // заголовок графика
        },
      },
    },
  };

  // Функция отрисовки изображения на канвасе
  async function renderGraph() {
    try {
      const canvasElement = document.getElementById(id); // элемент канваса
      const canvasParent = document.getElementById(`${id}-parent`); // родительский элемент канваса

      canvasElement.style.width = getComputedStyle(canvasParent).getPropertyValue('width'); // установить ширину канваса по ширине родителя
      canvasElement.style.height = getComputedStyle(canvasParent).getPropertyValue('height'); // установить высоту канваса по ширине родителя
      // eslint-disable-next-line no-new
      new Chart(canvasElement, config); // отрисовка графика
    } catch (error) {
      catchHandler(error, 'renderGraph');
    }
  }

  return (
    <div className="chart" id={`${id}-parent`}>
      <div className="chart__header">
        <p className="chart__title">{title}</p>
        <span className="clickable" onClick={() => createAction('SET_SHOW_CHART', false)}>← к таблице</span>
      </div>
      <canvas id={id} />
    </div>
  );
}

export default Canvas;
