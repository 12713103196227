import React, { useState, useEffect, useRef } from 'react';
import { clientSendData } from '../../utils/functions/requests';
import { catchHandler } from '../../utils/error_handling/error_handling';
import CardBody from '../../components/Card/CardBody';
import TextProcessor from '../../components/TextProcessor/TextProcessor';
import { alertFunction } from '../../utils/functions/alertFunction';
import ServiceBody from '../../components/Service/ServiceBody';
import Button from '../../components/UI/Button/Button';

function AdminPsEmail() {
  const [isEditorOpen, setIsEditorOpen] = useState(true);
  const [editorContent, setEditorContent] = useState({ id: 0, title: '', content: '' });
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    getPsEmailTemplate();
  }, []);

  function closeEditor() {
    setIsEditorOpen(false);
  }

  async function editPsEmailTemplate(email_id, newTitle, editedText) {
    try {
      const reqNewTitle = newTitle === '' ? editorContent.name : newTitle;
      const reqData = {
        emailId: email_id,
        nTitle: reqNewTitle,
        content: editedText,
        type: 'editPsEmailTemplate',
      };
      const result = await clientSendData('POST', '/edit_ps_email_template', reqData); // запрос в БД action: useState

      if (result) {
        await alertFunction('save_settings', 'client');
      }
    } catch (error) {
      catchHandler(error, 'editEmailTemplate'); // обработчик ошибок
    } finally { getPsEmailTemplate(); }
  }

  async function getPsEmailTemplate() {
    try {
      const reqData = {
        type: 'getPsEmailTemplate',
      };
      // setIsLoading(true); // загружается
      const result = await clientSendData('POST', '/get_ps_email_template', reqData); // запрос в БД action: useState
      if (result) {
        setEditorContent({ id: 0, title: result.data.name, content: result.data.value });
        setInputValue('');
      }
    } catch (error) {
      catchHandler(error, 'getPsEmailTemplate'); // обработчик ошибок
    } // finally { setIsLoading(false); }
  }

  console.log(editorContent);

  return (
        <ServiceBody>
            <CardBody>
                <div key={editorContent.content}>
                    <TextProcessor
                      content={editorContent.content}
                      cancel={closeEditor}
                      handler={(e) => editPsEmailTemplate(editorContent.id, inputValue, e)}
                    />
                </div>
            </CardBody>
        </ServiceBody>
  );
}
export default AdminPsEmail;
