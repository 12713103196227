import React, { useState } from 'react';
import { createPortal } from 'react-dom';

/**
* @component Рамка для показа изображения
@prop {size} string размер превью large/medium/small
*/

function ImgFrame(props) {
  const {
    size, imageSrc, setSize,
  } = props;
  const [isImageSrc, setImageSrc] = useState(imageSrc);
  const appNode = document.querySelector('.App'); // DOM элемент главного контейнера приложения

  const defineClass = () => {
    const classes = {
      active: 'img-frame img-frame_active',
      large: 'img-frame img-frame__large',
      medium: 'img-frame img-frame__medium',
      small: 'img-frame img-frame__small',
      default: 'img-frame img-frame__no-size',
    };

    if (isImageSrc) return classes.active;
    return classes[size] || classes.default;
  };
  if (imageSrc) {
    return (
      createPortal( // создать портал в верхний div приложения и оторазить модальное окно
      <div className={defineClass()} onClick={() => setSize('default')}>
          <img src={imageSrc} alt="Вложение к заявке" />
          <span
            className="img-frame__close"
            onClick={(event) => {
              event.stopPropagation();
              setImageSrc(null);
              setSize('default');
            }}
          >
  ×

          </span>
      </div>,
      appNode,
      )
    );
  }
  return (null);
}

export default ImgFrame;
