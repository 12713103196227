import React, { useState, useEffect } from 'react';
import './clientsportal.scss';
import TRow from '../../components/Table/TRow';
import TData from '../../components/Table/TData';
import ServiceBody from '../../components/Service/ServiceBody';
import PaginatedTable from '../../components/Table/PaginatedTable/PaginatedTable';
import ProjectCard from './ProjectCard/ProjectCard';
import NewProjectCard from './NewProjectCard';
import ClientsPortal from './ClientsPortal';
import Button from '../../components/UI/Button/Button';
import NewRequestCard from './RequestCard/NewRequestCard';
import RequestCard from './RequestCard/RequestCard';
import QuestionnaireSheet from './QuestionnaireSheet/QuestionnaireSheet';
import ServicePanel from '../../components/Service/ServicePanel';
import Menu from './Menu'; // Импортируем компонент меню

function ProjectRequests({
  projectID, openedItems, setOpenedItems, activeItem, setActiveItem,
}) {
  const tableId = 'client_portal';
  const [clientPortalData, setClientPortalData] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [openedMeeting, setOpenedMeeting] = useState();
  const [showCard, setShowCard] = useState(false);
  const [showNewCard, setNewShowCard] = useState(false);
  const [showClientsPortal, setShowClientsPortal] = useState(false);
  const [showQuestionnaireSheet, setShowQuestionnaireSheet] = useState(false);
  // const [activeItem, setActiveItem] = useState(null);
  // const [openedItems, setOpenedItems] = useState(['all-projects']);

  console.log(projectID);

  const menuData = [
    {
      id: 'all-projects',
      title: 'Все проекты',
      children: [
        {
          id: 'project-1',
          title: 'Проект №1',
          children: [
            { id: 'request-1-1', title: 'Запрос 1-1' },
            { id: 'request-1-2', title: 'Запрос 1-2' },
          ],
        },
        {
          id: 'project-2',
          title: 'Проект №2',
          children: [
            { id: 'request-2-1', title: 'Запрос 2-1' },
            { id: 'request-2-2', title: 'Запрос 2-2' },
          ],
        },
      ],
    },
  ];
  const defaultTableState = {
    title: 'Запросы по проекту',
    page: 1,
    limit: 50,
  };

  const [tableState, setTableState] = useState(
    JSON.parse(localStorage.getItem(tableId)) || defaultTableState,
  );

  useEffect(() => {
    if (projectID) {
      setTableState((prevState) => ({
        ...prevState,
        data: projectDataMap[projectID] || [],
      }));
    }
  }, [projectID]);

  const headers = [
    { id: 1, title: 'Запрос', field: 'request' },
    { id: 2, title: 'Дата создания', field: 'date_start' },
    { id: 3, title: 'Статус заполнения', field: 'status' },
  ];

  const projectDataMap = {
    'project-1': [
      {
        id: 'request-1-1', request: 'Запрос №1-1', status: 'Заполнено 9 из 10', date_start: '02.09.2024',
      },
      {
        id: 'request-1-2', request: 'Запрос №1-2', status: 'Заполнено 3 из 20', date_start: '10.11.2024',
      },
    ],
    'project-2': [
      {
        id: 'request-2-1', request: 'Запрос №2-1', status: 'Заполнено 5 из 10', date_start: '15.09.2024',
      },
      {
        id: 'request-2-2', request: 'Запрос №2-2', status: 'Заполнено 7 из 15', date_start: '22.10.2024',
      },
    ],
  };

  function openCard(id) {
    setOpenedMeeting(id);
    setShowCard(true);
  }

  function openNewCard(id) {
    setOpenedMeeting(id);
    setNewShowCard(true);
  }

  function openQuestionnaireSheet(id) {
    setOpenedMeeting(id);
    setShowQuestionnaireSheet(true);
    setOpenedMeeting(id);
    setOpenedItems((prev) => [...prev, id]); // Добавьте нужный id
    setActiveItem(id); // Установите активный элемент

    // Передайте свойство projectID компоненту QuestionnaireSheet
    return <QuestionnaireSheet projectID={id} setShowCard={setShowCard} refreshTable={() => setTableState({ ...tableState })} />;
  }

  function handleMenuItemClick(id) {
    setActiveItem(id);

    if (id === 'all-projects') {
      setShowClientsPortal(true);
    } else if (id.startsWith('project')) {
      setOpenedMeeting(id);
      setTableState((prevState) => ({
        ...prevState,
        data: projectDataMap[id] || [],
      }));
      setShowCard(false);
      setShowQuestionnaireSheet(false);
    } else if (id.startsWith('request')) {
      openQuestionnaireSheet(id);

      // Найти проект, которому принадлежит запрос
      const parentProject = menuData[0].children.find((project) => project.children.some((request) => request.id === id));

      // Обновить openedItems
      setOpenedItems((prevState) => {
        const newOpenedItems = [...prevState];

        // Добавить родительский проект, если он еще не открыт
        if (parentProject && !newOpenedItems.includes(parentProject.id)) {
          newOpenedItems.push(parentProject.id);
        }

        // Добавить сам запрос
        if (!newOpenedItems.includes(id)) {
          newOpenedItems.push(id);
        }

        return newOpenedItems;
      });
    }
  }

  if (showClientsPortal) {
    return <ClientsPortal
      projectID={openedMeeting}
      activeItem={activeItem}
      setOpenedItems={setOpenedItems}
      setActiveItem={setActiveItem}
      setShowCard={setShowCard}
      refreshTable={() => setTableState({ ...tableState })}
    />;
  }

  if (showCard) {
    return (
      <RequestCard
        projectID={openedMeeting}
        setShowCard={setShowCard}
        refreshTable={() => setTableState({ ...tableState })}
      />
    );
  }

  if (showQuestionnaireSheet) {
    return (
      <QuestionnaireSheet
        projectID={openedMeeting}
        openedItems={openedItems}
        setOpenedItems={setOpenedItems}
        activeItem={activeItem}
        setActiveItem={setActiveItem}
        setShowCard={setShowQuestionnaireSheet}
        refreshTable={() => setTableState({ ...tableState })}
      />
    );
  }

  if (showNewCard) {
    return (
      <NewRequestCard
        setNewShowCard={setNewShowCard}
        refreshTable={() => setTableState({ ...tableState })}
      />
    );
  }

  return (
    <div className="table-container">
      <ServiceBody horizontal>
        <ServicePanel>
          <Menu
            data={menuData}
            onItemClick={handleMenuItemClick}
            activeItem={activeItem}
            setActiveItem={setActiveItem}
            openedItems={openedItems}
            setOpenedItems={setOpenedItems}
          />
        </ServicePanel>
        <PaginatedTable
          id="clients_portal"
          headers={headers}
          state={tableState}
          setState={setTableState}
          total={total}
          optButton={{
            title: 'Добавить запрос',
            handler: () => openNewCard('new'),
          }}
        >
  {Array.isArray(tableState.data) && tableState.data.map((row) => (
    <TRow key={row.id}>
      {headers.map((column, columnIndex) => {
        const { id, field } = column;
        const value = row?.[field];

        if (columnIndex === 0) {
          return (
            <TData
              key={id}
              loading={isLoading}
              onClick={() => openQuestionnaireSheet(row.id)}
            >
              {typeof value === 'object' ? value.join(', ') : value ?? '-'}
            </TData>
          );
        }

        return (
          <TData key={id} loading={isLoading}>
            {typeof value === 'object' ? value.join(', ') : value ?? '-'}
          </TData>
        );
      })}
      <TData onClick={() => openCard(row.id)} loading={isLoading}>
        <img src="../../icons/file-text.svg" alt="file" />
      </TData>
    </TRow>
  ))}
        </PaginatedTable>

      </ServiceBody>
    </div>
  );
}

export default ProjectRequests;
