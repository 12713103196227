import React, { useState, useEffect } from 'react';
import './clientsportal.scss';
import TRow from '../../components/Table/TRow';
import TData from '../../components/Table/TData';
import ServiceBody from '../../components/Service/ServiceBody';
import PaginatedTable from '../../components/Table/PaginatedTable/PaginatedTable';
import ProjectCard from './ProjectCard/ProjectCard';
import NewProjectCard from './NewProjectCard';
import ProjectRequests from './ProjectRequests';
import ServicePanel from '../../components/Service/ServicePanel';
import Menu from './Menu';
import QuestionnaireSheet from './QuestionnaireSheet/QuestionnaireSheet';

function ClientsPortal({ projectID }) {
  const tableId = 'client_portal';
  const [openedMeeting, setOpenedMeeting] = useState();
  const [showCard, setShowCard] = useState(false);
  const [showNewCard, setNewShowCard] = useState(false);
  const [showRequest, setShowRequest] = useState(false);
  const [showRequestQuestionaries, setShowRequestQuestionaries] = useState(false);
  const [activeItem, setActiveItem] = useState('all-projects');
  const [openedItems, setOpenedItems] = useState(['all-projects']); // Список изначально открыт

  const questions = [
    { name: 'Вопрос №1', status: 'Заполнено' },
    { name: 'Вопрос №2', status: 'В процессе' },
    { name: 'Вопрос №3', status: 'Новый' },
  ];

  const menuData = [
    {
      id: 'all-projects',
      title: 'Все проекты',
      children: [
        {
          id: 'project-1',
          title: 'Проект №1',
          children: [
            { id: 'request-1-1', title: 'Запрос 1' },
            { id: 'request-1-2', title: 'Запрос 2' },
          ],
        },
        {
          id: 'project-2',
          title: 'Проект №2',
          children: [
            { id: 'request-2-1', title: 'Запрос 1' },
            { id: 'request-2-2', title: 'Запрос 2' },
          ],
        },
      ],
    },
  ];

  const defaultTableState = {
    title: 'Мои проекты',
    page: 1,
    limit: 50,
  };

  const [tableState, setTableState] = useState(
    JSON.parse(localStorage.getItem(tableId)) || defaultTableState,
  );

  useEffect(() => {
    if (projectID) {
      setTableState((prevState) => ({ ...prevState }));
    }
  }, [projectID]);

  const headers = [
    { id: 1, title: 'Проект', field: 'project' },
    { id: 2, title: 'Партнер', field: 'partner' },
    { id: 3, title: 'Дата завершения', field: 'date_end' },
  ];

  const data = [
    {
      id: 'project-1', project: 'Проект №1', partner: 'Тарадов', date_end: '02.09.2024',
    },
    {
      id: 'project-2', project: 'Проект №2', partner: 'Хромова', date_end: '10.11.2024',
    },
  ];

  function openCard(id) {
    setOpenedMeeting(id);
    setShowCard(true);
  }

  function openRequest(id) {
    setOpenedMeeting(id);
    setShowRequest(true);
    setOpenedItems((prev) => [...prev, id]); // Добавьте нужный id
    setActiveItem(id); // Установите активный элемент
  }

  function openRequestQuestionaries(id) {
    setOpenedMeeting(id);
    setShowRequestQuestionaries(true);
  }

  function openNewCard() {
    setNewShowCard(true);
  }

  function handleMenuItemClick(id) {
    if (id === 'all-projects') {
      setOpenedItems((prev) => {
        if (prev.includes(id)) {
          return prev.filter((item) => item !== id);
        }
        return [...prev, id];
      });
    } else if (id.startsWith('project')) {
      openRequest(id); // Открытие карточки проекта
    } else if (id.startsWith('request')) {
      openRequestQuestionaries(id);
    }
  }

  console.log(activeItem);

  if (showRequest) {
    return (
      <ProjectRequests
        projectID={openedMeeting}
        openedItems={openedItems}
        setOpenedItems={setOpenedItems}
        setShowRequest={setShowRequest}
        setActiveItem={setActiveItem}
        activeItem={activeItem}
        refreshTable={() => setTableState({ ...tableState })}
      />
    );
  }

  if (showRequestQuestionaries) {
    return (
      <QuestionnaireSheet
        projectID={openedMeeting}
        openedItems={openedItems}
        activeItem={activeItem}
        setOpenedItems={setOpenedItems}
        setShowRequest={setShowRequest}
        setActiveItem={setActiveItem}
        refreshTable={() => setTableState({ ...tableState })}
      />
    );
  }

  if (showCard) {
    return (
      <ProjectCard
        projectID={openedMeeting}
        setShowCard={setShowCard}
        refreshTable={() => setTableState({ ...tableState })}
      />
    );
  }

  if (showNewCard) {
    return (
      <NewProjectCard
        setNewShowCard={setNewShowCard}
        refreshTable={() => setTableState({ ...tableState })}
      />
    );
  }

  return (
    <div id="clients_portal_table">
      <ServiceBody horizontal>
        <ServicePanel>
          <Menu
            data={menuData}
            onItemClick={handleMenuItemClick}
            activeItem={activeItem}
            setActiveItem={setActiveItem}
            openedItems={openedItems}
            setOpenedItems={setOpenedItems}
          />
        </ServicePanel>
        <PaginatedTable
          id="clients_portal"
          headers={headers}
          state={tableState}
          setState={setTableState}
          total={data.length}
          optButton={{
            title: 'Создать',
            handler: openNewCard,
          }}
        >
          {data.map((row) => (
            <TRow key={row.id}>
              {headers.map((column) => {
                const { id, field } = column;
                const value = row?.[field];
                return (
                  <TData
                    key={id}
                    onClick={field === 'project' ? () => openRequest(row.id) : undefined}
                  >
                    {typeof value === 'object' ? value.join(', ') : value ?? '-'}
                  </TData>
                );
              })}
              <TData onClick={() => openCard(row.id)}>
                <img src="../../icons/file-text.svg" alt="file" />
              </TData>
            </TRow>
          ))}
        </PaginatedTable>
      </ServiceBody>
    </div>
  );
}

export default ClientsPortal;
