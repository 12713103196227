import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { deleteCookie, getCookie } from '../../utils/functions/cookies.js';
import { getUserAgent, log } from '../../utils/functions/others.js';
import { catchHandler } from '../../utils/error_handling/error_handling.js';
import { clientSendData } from '../../utils/functions/requests.js';
import { alertFunction } from '../../utils/functions/alertFunction.js';
import TicketCreating from './TicketCreating.jsx';
import Button from '../UI/Button/Button.jsx';

/**
 * @component - Окно справочной информации
*/

function Help() {
  const buttons = ['contact', 'info', 'menu', 'guides', 'clear_cookie'];
  const isOpen = useSelector((state) => state.visibility.help);
  const userServices = useSelector((state) => state.services.user_services); // сервисы пользователя
  const servicesToDisplay = userServices.filter((item) => item.parameters.visibility); // оставить только отображаемые сервисы
  const [currentButton, setCurrentButton] = useState(buttons[0]);
  const [instructions, setInstructions] = useState([]);

  useEffect(() => {
    if (currentButton === 'guides') getAvailableInstructions();
  }, [currentButton]);

  async function getAvailableInstructions() {
    try {
      const reqData = {
        type: 'getAvailableInstructions',
      };
      const result = await clientSendData('POST', '/get_available_instructions', reqData);
      if (result) setInstructions(result);
    } catch (error) {
      catchHandler(error, 'getAvailableInstructions');
    }
  }

  // Очистка local storage и уведомление
  async function clearLS() {
    localStorage.clear();
    await alertFunction('clear_ls', 'clientPost');
    log({ message: 'HELP - нажатие кнопки "Очистить LS"' });
  }

  // Очистка куки и уведомление
  async function clearCookie() {
    deleteCookie();
    await alertFunction('clear_cookie', 'clientPost');
    log({ message: 'HELP - нажатие кнопки "Очистить куки"' });
  }

  return (
    <div className={`help${isOpen ? ' help_active' : ''}`} onClick={(e) => e.stopPropagation()}>
      <div className="help__body">
        {(() => {
          switch (currentButton) {
            case 'info': return window.open('https://uniplace.unicon.ru/maintenance_department', '_blank', 'noopener,noreferrer');
            case 'contact': return <TicketCreating />;
            case 'guides': return (
              <ul className="help__user-info">
                {instructions.map((item) => {
                  const { id, link, title } = item;
                  return (
                    <li key={id}>
                      <Link
                        to={`instructions/${link}`}
                        className="clickable"
                        onClick={() => log({ message: `HELP - инструкции - переход по ссылке "instructions/${link}" для скачивания` })}
                        target="_blank"
                      >
                        {`Инструкция - ${title}`}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            );
            case 'menu': return (
              <ul className="help__user-info">
                {servicesToDisplay.map((service) => {
                  const { id, link, title } = service;
                  return (
                    <li key={id}>
                      <Link to={link} className="clickable">{title}</Link>
                    </li>
                  );
                })}
              </ul>
            );
            case 'clear_cookie': return (
              <div className="help__clear-buttons">
                <Button onClick={clearCookie}>Очистить куки</Button>
                <Button onClick={clearLS}>Очистить LS</Button>
              </div>
            );
            default: return null;
          }
        })()}
      </div>

      <div className="help__footer">
  {buttons.map((button) => {
    // Здесь вы можете определить подсказки для каждой кнопки
    const buttonTitles = {
      contact: 'Заявка в Службу поддержки',
      info: 'Заявка в службу АХО',
      menu: 'Меню сервисов',
      guides: 'Руководства пользователя',
      clear_cookie: 'Очистить cookies и local storage',
    };

    return (
      <img
        key={button}
        src={`../../icons/help/${button}.png`}
        alt={button}
        title={buttonTitles[button]} // Добавляем подсказку для каждой кнопки
        className={`help__img${button === currentButton ? ' help__img_active' : ''}`}
        onClick={() => {
          log({ message: `HELP - нажатие кнопки "${button}"` });
          setCurrentButton(button);
        }}
      />
    );
  })}
      </div>
    </div>
  );
}

export default Help;
