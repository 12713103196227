import React, { useEffect, useState } from 'react';
import { clientSendData } from '../../../../utils/functions/requests';
import { catchHandler } from '../../../../utils/error_handling/error_handling';
import CardSetting from '../../../../components/Card/CardSetting';
import Loader from '../../../../components/UI/Loader';
import Button from '../../../../components/UI/Button/Button';
import CardBody from '../../../../components/Card/CardBody';
import CardFooter from '../../../../components/Card/CardFooter';

/**
 * @component Бронирование - Интерфейс администратора - карточка места - вкладка "Главная"
 * @prop {placeId} number - id рабочего места
 */
function Main(props) {
  const { closeHandler, placeId } = props;
  const [workplace, setWorkplace] = useState({});
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки

  useEffect(() => {
    getWorkplaceCard();
  }, []);

  // Получить заявки
  async function getWorkplaceCard() {
    try {
      const reqData = {
        type: 'getWorkplaceCard',
        placeId,
      };
      setIsLoading(true);
      const result = await clientSendData('POST', '/get_workplace_card', reqData);
      if (result?.success) {
        setWorkplace(result); // Результат записывается в состояние
      }
    } catch (error) {
      catchHandler(error, 'getWorkplaceCard');
    } finally {
      setIsLoading(false);
    }
  }

  if (isLoading) return <CardBody><Loader /></CardBody>;
  return (
    <>
      <CardBody>
        <CardSetting title="Зона">{workplace?.data?.zone}</CardSetting>
        <CardSetting title="Тип места">{workplace?.data?.type}</CardSetting>
        <CardSetting title="Наименование места">{workplace?.data?.title}</CardSetting>
        <CardSetting title="Активные бронирования">
          {workplace.activeBookings?.length > 0
            ? workplace.activeBookings.map((booking) => {
              const { user, date_start, date_end } = booking;
              return <CardSetting title={user}>{`с ${date_start} до ${date_end}`}</CardSetting>;
            })
            : 'Отсутствуют'}
        </CardSetting>
      </CardBody>
      <CardFooter>
        <Button onClick={closeHandler}>Закрыть</Button>
      </CardFooter>
    </>

  );
}

export default Main;
