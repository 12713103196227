import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { catchHandler } from '../../../../utils/error_handling/error_handling';
import { alertFunction } from '../../../../utils/functions/alertFunction';
import { clientSendData } from '../../../../utils/functions/requests';
import { createAction } from '../../../../utils/redux/store';
import ServicePanel from '../../../../components/Service/ServicePanel';
import Button from '../../../../components/UI/Button/Button';
import Division from '../../../../components/UI/Division';
import Select from '../../../../components/UI/Select';
import Loader from '../../../../components/UI/Loader';
import ExtPanel from './ExtPanel';
import Period from './Period';

/**
* @component Сервис отчётов анкетирования - Базовый отчёт - Панель выбора
* @prop {setCurrentReport} function Обновление состояния текущего отчёта
*/
function Panel(props) {
  const { setCurrentReport } = props;

  const location = useLocation(); // адрес
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const userDirections = useSelector((state) => state.questionnaire.user_directions); // направления доступные пользователю
  const currentDirection = useSelector((state) => state.questionnaire.current_direction); // текущее выбранное направление
  const questionnairies = useSelector((state) => state.questionnaire.questionnairies); // все анкеты из доступных навравлений
  const currentQuestionnaire = useSelector((state) => state.reports.current_questionnaire); // все анкеты из доступных навравлений
  const reportSettings = useSelector((state) => state.reports.report_settings); // настроки отчёта
  const reportPeriod = useSelector((state) => state.reports.report_periоd); // период отчёта

  // Фильтрация анкет в зависимости от выбранного направления
  const filterQuestionnaries = () => {
    // Убрать анкеты с типом "Справочник"
    const withoutWiki = questionnairies.filter((q) => !q.wiki);
    // Если выбраны все направления - показываются все анкеты
    if (currentDirection.id === 'all') return withoutWiki;
    // иначе показываются анкеты в выбранном направлении
    return withoutWiki.filter((q) => q.group_id === currentDirection.id);
  };

  // Выбрать направление
  function chooseDirection(direction) {
    createAction('SET_CURRENT_DIRECTION', direction);
    changeSetting({
      direction_id: direction.id,
      questionnaire_id: null,
    });
    createAction('SET_CURRENT_QUESTIONNAIRE', null); // сброс выбранной анкеты
    createAction('SET_CHOOSED_EXT_REPORT', null); // сброс выбранного расширенного отчёта
  }

  // Выбрать анкету
  function chooseQuestionnaire(questionnaire) {
    createAction('SET_CURRENT_QUESTIONNAIRE', questionnaire);
    changeSetting({ questionnaire_id: questionnaire.id });
  }

  // Изменить значение по ключу
  function changeSetting(data) {
    createAction('SET_REPORT_SETTINGS', { ...reportSettings, ...data });
  }

  // Получить отчёт
  async function getReport() {
    try {
      const reqData = {
        type: 'getReportHorizontal',
        location,
        settings: reportSettings,
        period_settings: reportPeriod,
      };
      const { questionnaire_id } = reportSettings;
      const { period, start_date, end_date } = reportPeriod;
      if (!questionnaire_id) alertFunction('required_fields', 'clientPost');
      else if ((period && !start_date) || (period && !end_date)) alertFunction('wrong_period', 'clientPost');
      else if (period && (start_date > end_date)) alertFunction('wrong_period', 'clientPost');
      else {
        setIsLoading(true);
        const result = await clientSendData('POST', '/get_report_horizontal', reqData); // запрос в БД
        if (result) setCurrentReport(result); // запись отчёта в состояние
        setIsLoading(false);
      }
    } catch (error) {
      catchHandler(error, 'getReportVertical');
    }
  }

  return (
    <ServicePanel id="reports__panel">
      <Select
        id="reports__choose-direction"
        array={userDirections}
        onChoose={chooseDirection}
        defaultValue={currentDirection?.title || 'Направление'}
        loading={isLoading}
      />
      <Division />
      <Period />
      <Division />

      <div className="reports__basic-form" onClick={(e) => e.stopPropagation()}>
        <Select
          id="reports__choose-questionnaire"
          array={filterQuestionnaries()}
          onChoose={chooseQuestionnaire}
          defaultValue={currentQuestionnaire?.title || 'Анкеты'}
          loading={isLoading}
        />

        {currentQuestionnaire && <Button onClick={getReport}>Отчёт</Button>}
      </div>

      {/* Если есть доступные направления - показать панель расширенных отчётов */}
      {userDirections.length > 0 && <Division />}
      {userDirections.length > 0 && isLoading ? <Loader /> : <ExtPanel />}
    </ServicePanel>
  );
}

export default Panel;
