import React from 'react';

/**
* @component Кнопка "Закрыть"
@prop {onClick} function (Обязательный) Обработчик клика
@prop {theme} string параметр темы
*/

function Close(props) {
  const { theme, onClick } = props;

  const defineClass = () => {
    let result = 'upu__close';
    if (theme === 'light') result += ' upu__close_light';
    return result;
  };

  return <span onClick={onClick} className={defineClass()}>×</span>;
}

export default Close;
