import React, { useEffect } from 'react';
import { setOperInfo } from '../../utils/functions/others';
import UserTasks from '../Tasks/UserTasks';
import ServiceCards from './ServiceCards';

/**
* @component Главная страница портала
*/
function Main() {
  window.location.href = '/helpdesk';
  useEffect(() => {
    setOperInfo({ alias: 'MAIN' });
  }, []);

  return (
    <div className="main">
      {/* <h1 className="main__title">Добро пожаловать на корпоративную платформу -</h1>
      <h1 className="main__title">Unifying Platform Unicon!</h1> */}
      {/* <ServiceCards />
      <UserTasks /> */}
    </div>
  );
}

export default Main;
