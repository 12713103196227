import React, { useEffect, useState } from 'react';
import './questionnairies.scss';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import Loader from '../../../components/UI/Loader';
import QuestionnaireCard from './QuestionnaireCard/QuestionnaireCard';
import { getQuestionnairies } from '../functions';
import { log } from '../../../utils/functions/others';
import Table from '../../../components/Table/Table';
import THead from '../../../components/Table/THead';
import { createAction } from '../../../utils/redux/store';
import TBody from '../../../components/Table/TBody';
import TRow from '../../../components/Table/TRow';
import TData from '../../../components/Table/TData';
import TFoot from '../../../components/Table/TFoot';
import ServiceBody from '../../../components/Service/ServiceBody';

// Сервис анкетирования - вкладка "Анкеты"
// При открытии карточки в хранилище помещается информация о текущей анкете, будь то новая или существующая
// Пользователь может редактировать эту информацию, при нажатии кнопки "Сохранить" измененные или созданные данные записываются в БД
function Questionnairies() {
  const location = useLocation(); // адрес
  const userDirections = useSelector((state) => state.questionnaire.user_directions); // направления доступные пользователю
  const [isLoading, setIsLoading] = useState(false); // Состояние загрузки
  const [showCard, setShowCard] = useState(false); // состояние отображения карточки анкеты
  const [currentQuestionnaireId, setCurrentQuestionnaireId] = useState(null); // выбранная анкета
  const currentDirection = useSelector((state) => state.questionnaire.current_direction); // текущее направление
  const displayedQuestionnairies = useSelector((state) => state.questionnaire.displayed_questionnairies); // отображаемые анкеты
  const headers = [ // заголовки таблицы
    { id: 1, title: 'Имя', field: 'title' },
    { id: 2, title: 'Направление', field: 'group' },
    { id: 3, title: 'Создал(а)', field: 'creator' },
    { id: 4, title: 'Создан', field: 'date_created' },
  ];

  useEffect(() => {
    awaitRequests();
  }, []);

  // Ожидание выполнения необходимых запросов
  async function awaitRequests() {
    setIsLoading(true); // состояние - загружается
    await getQuestionnairies(location.pathname, currentDirection);
    setIsLoading(false); // загрузка завершена
  }

  // Поулчить информацию об анкете
  function openQuestionnaire(id) {
    setCurrentQuestionnaireId(id); // id выбранной анкета
    setShowCard(true); // показать карточку
    if (id === 'new') log({ message: 'Пользователь нажал кнопку "Добавить"(добавление новой анкеты)', eventtype: 'info' });
    else log({ message: `Пользователь открыл карточку анкеты id: ${id}`, eventtype: 'info', subject_id: id });
  }

  // если загружается - показывается лоадер
  if (isLoading) return <ServiceBody><Loader /></ServiceBody>;
  return (
    <ServiceBody>
      {userDirections.length > 0 ? (
      <Table id="questionnaire-questionnairies__table">
        <THead
          title="Анкеты"
          headers={headers}
          array={displayedQuestionnairies}
          setArray={(array) => createAction('SET_DISPLAYED_QUESTIONNAIRIES', array)}
        />
        <TBody className="upu-table__table-body">
          {displayedQuestionnairies.map((q) => ( // Данные анкеты
            <TRow key={q.id} onClick={() => openQuestionnaire(q.id)} button>
              {headers.map((item) => <TData key={item.id}>{q[item.field]}</TData>)}
            </TRow>
          ))}
        </TBody>

        <TFoot className="upu-table__table-foot">
          <TRow>
            <TData onClick={() => openQuestionnaire('new')}>Добавить</TData>
          </TRow>
        </TFoot>
      </Table>
      ) : (
      <h2> Доступные направления отсутствуют</h2>)}

      {/* Карточка анкеты */}
      {showCard && (
      <QuestionnaireCard
        id={currentQuestionnaireId}
        setShowCard={setShowCard}
      />)}
    </ServiceBody>
  );
}

export default Questionnairies;
