// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#legal-form__new-card {
  width: 500px;
  height: -moz-fit-content;
  height: fit-content;
}`, "",{"version":3,"sources":["webpack://./src/pages/LegalForm/legalform.scss"],"names":[],"mappings":"AACI;EACI,YAAA;EACA,wBAAA;EAAA,mBAAA;AAAR","sourcesContent":["#legal-form {\n    &__new-card {\n        width: 500px;\n        height: fit-content;\n        //height: 300px;\n        \n      }\n  \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
