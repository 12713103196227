import React from 'react';
import ErrorBoundary from '../../utils/error_handling/ErrorBoundary';
import ServiceBody from './ServiceBody';

/**
 * @component - Компонент для отображения
 * @prop {string} choice - выбранный сервис
 * @prop {Page[]} pages - массив вкладок для отображения с неободимыми компонентами
 * @prop {HtmlElement} noChoice, // сообщение для вывода при отсутствии выбора
 * @typedef Page
 * @property {number} id - id страницы
 * @property {string} title - название сервиса
 * @property {ReactComponent} component - компонент для рендера
 * @property {string} component_name - название комонента
 * @property {boolean} active - выбрать для рендера по умолчанию
*/

function Content(props) {
  const { choice, pages, noChoice = null } = props;
  if (choice) {
    const page = pages.find((item) => item.title === choice);
    if (page) return <ErrorBoundary>{page.component}</ErrorBoundary>;
    return null;
  }
  if (noChoice) return noChoice; // если есть заглушка при отсутствии выбора - показать ее
  return <ServiceBody><h2>Выберите элемент</h2></ServiceBody>; // иначе заглушку по умолчанию
}

export default Content;
