import { catchHandler } from '../../../utils/error_handling/error_handling';
import { alertFunction } from '../../../utils/functions/alertFunction';
import { saveAsWord } from '../../../utils/functions/others';
import { stringMatch } from '../../../utils/functions/stringHandling';
import { createAction } from '../../../utils/redux/store';

// Функция поиска по справочнику
export function searchHandler(searchString, questions) {
  try {
    createAction('SET_IS_SEARCHING', true); // режим поиска вкл
    createAction('SET_IS_SHOW_QUESTION', true); // режим показа вопроса

    function prepareString(string) {
      const deleteBraces = string.replace(/&nbsp;|&quot|\n/gm, ' '); // удалить из строки кавычки переносы строк
      const result = deleteBraces.replace(/<.*?>/gm, ''); // удалить из строки html теги
      return result;
    }

    // Результат поиска - массив вопросов, в которых есть совпадения введенной строки и значения ключа "question" вопроса
    const searchResult = questions.filter((q) => (
      stringMatch(searchString, q.title) // поиск в заголовке вопроса
            || stringMatch(searchString, prepareString(q.question)) // поиск в содержании вопроса
    ));

    // Добавить к вопросу искомую строку в контексте
    const addSearchTitle = searchResult.map((q) => {
      const text = prepareString(q.question);
      const arrayText = text.split(' '); // массив из строки
      const word = arrayText.find((w) => w.toLowerCase().includes(searchString.toLowerCase())); // слово полностью (ищем "фин", а слово "финансS")
      const searchIndex = arrayText.indexOf(word); // индекс найденного слова
      const slicedArray = arrayText.slice(
        searchIndex > 2 ? searchIndex - 2 : 0, // если индекс меньше или равен 2, массив обрезается сначала
        searchIndex + 2 > arrayText.length ? arrayText.length : searchIndex + 2, // если искомый индекс + 2 превышает длину массива, то массив обрезается до конца
      ); // обрезать массив по 2 элемента с каждой стороны от искомого слова
      const searchTitle = slicedArray.join(' '); // результат в строку
      return { ...q, search_title: `...${searchTitle}...` }; // добавить результат к вопросу
    });

    if (searchString.length <= 0) createAction('SET_QUESTIONS_FOUND', []); // если строка поиска пустая - результат пустой массив
    else createAction('SET_QUESTIONS_FOUND', addSearchTitle); // иначе результаты записывааются в хранилище
  } catch (error) {
    catchHandler(error, 'searchHandler');
  }
}

// Активность поля комментариев
export function commentActivity(userAnswers, currentQuestion) {
  // если пользователь выбрал хотя бы один ответ поле становится активным
  const findAnswer = userAnswers.find((a) => a.question_id === currentQuestion.id); // поиск ответов к выбранному вопросу в масииве ответов пользователя
  // если ответ найден или формат ответа - комментарий
  if (findAnswer || currentQuestion.output_id === 4) return false; // свойство disabled выключено
  return true; // иначе включено
}

// Показать/скрыть форму с заголовками вопросов
export function toggleFormMobile(e, isShowFormMobile) {
  e.stopPropagation(); // Предотвратить погружения события в родительский элемент
  createAction('SET_IS_SHOW_FORM_MOBILE', !isShowFormMobile);
}

// обработка комментария от пользователя
export function leaveComment(e, userAnswers, currentQuestion, questionnaire) {
  createAction('SET_COMMENT', e.target.value); // обновление состояния в поле ввода (контролируемый компонент)
  const firstAnswer = userAnswers.filter((a) => a.question_id === currentQuestion.id); // поиск ответов к выбранному вопросу в массиве ответов пользователя

  if (firstAnswer.length > 0) { // если есть ответы на текущий вопрос
    if (!firstAnswer[0].answer_id) { // Если отстутствует id ответа (т.е присутствует только комментарий)
      const onlyComment = userAnswers.map((a) => { // проходим по массиву
        if ((a.question_id === firstAnswer[0].question_id) && !a.answer_id) { // ищем запись с этим ответом
          return ({
            ...a,
            comment: e.target.value, // и меняем в ней комментарий
          });
        } return a; // остальные остаются без изменений
      });
      createAction('SET_USER_ANSWERS', onlyComment);
    } else { // иначе (если id ответа есть)
      const addComment = userAnswers.map((a) => { // проходим по массиву
        if (a.answer_id === firstAnswer[0].answer_id) { // ищем запись с этим ответом
          return ({
            ...firstAnswer[0], // и меняем в ней комментарий
            comment: e.target.value,
          });
        } return a; // остальные остаются без изменений
      });
      createAction('SET_USER_ANSWERS', addComment);
    }
  } else { // если ответ не найден
    const answer = { // создаем объект ответа
      questionnaire_id: questionnaire.id,
      question_id: currentQuestion.id,
      answer_id: null,
      comment: e.target.value,
    };
    createAction('SET_USER_ANSWERS', [...userAnswers, answer]); // и добавляем в массив
  }
}

// Сохранить текущий вопрос ответами
export function saveCurrent(format, answers, currentQuestion) {
  try {
    if (format) {
      const currentAnswers = answers.filter((a) => a.question_id === currentQuestion.id);
      const question = document.createElement('div');
      question.innerHTML += currentQuestion.question;
      // eslint-disable-next-line no-return-assign
      currentAnswers.forEach((a) => question.innerHTML += (`\n${a.answer}`));
      if (format === 'doc') saveAsWord(question.innerHTML, 'Текущий вопрос');
      else return;
    } else alertFunction('choose_format', 'clientPost'); // Уведомление Выберите формат
  } catch (error) {
    catchHandler(error, 'saveCurrent');
  }
}

// Сохранить все вопросы с ответами
export function saveAll(format, interview) {
  try {
    const { questions, answers } = interview;
    if (format) {
      const result = document.createElement('div');
      questions.forEach((q) => {
        result.innerHTML += `${q.question}\n`;
        answers.forEach((a) => {
          if (a.question_id === q.id) {
            result.innerHTML += `${a.answer}\n`;
          }
        });
      });

      if (format === 'doc') saveAsWord(result.innerHTML, 'Все вопросы');
      else return;
    } else alertFunction('choose_format', 'clientPost'); // Уведомление Выберите формат
  } catch (error) {
    catchHandler(error, 'saveAll');
  }
}
