/* eslint-disable no-param-reassign */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import { JitsiMeeting } from '@jitsi/react-sdk';
import { useParams } from 'react-router';
import { clientSendData } from '../../utils/functions/requests';
import { alertFunction } from '../../utils/functions/alertFunction';
import { catchHandler } from '../../utils/error_handling/error_handling';

function Conference() {
  const room = useParams()?.room;
  const [user, setUser] = useState('');
  const [server, setServer] = useState(null);

  useEffect(() => {
    getVCConfData();
  }, []);

  async function getVCConfData() {
    try {
      // Формируем данные для запроса
      const reqData = {
        type: 'getVCConfData',
        room,
      };
        // Отправляем POST запрос на сервер и ждем ответ
      const result = await clientSendData('POST', '/get_vc_conf_data', reqData);
      // Если запрос успешен, получаем типы временных доступов
      if (result?.success) {
        setUser(result.confUser);
        setServer(result.server);
      } else {
        await alertFunction('something_went_wrong', 'client');
      }
    } catch (error) {
      // Обрабатываем ошибку
      catchHandler(error, 'getTmpAccessProlongationData');
    }
  }

  if (server) {
    return (
    <JitsiMeeting
      domain={server}
      roomName={room}
      configOverwrite={{
        startWithAudioMuted: true,
        disableModeratorIndicator: true,
        startScreenSharing: true,
        enableEmailInStats: false,
      }}
      interfaceConfigOverwrite={{
        DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
      }}
      userInfo={{
        displayName: user,
      }}
      getIFrameRef={(iframeRef) => { iframeRef.style.height = '90%'; }}
      onApiReady={(api) => {
        api.addEventListener('participantRoleChanged', (event) => {
          if (event.role === 'moderator') {
            // api.executeCommand('password', '123456');
            // api.executeCommand('toggleLobby', true);\
          }
        });
      }}
    />
    );
  }
}

export default Conference;
