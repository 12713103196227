import React from 'react';

/**
* @component Футер карточки
*/

function CardFooter(props) {
  const { children } = props;
  return <div className="upu-card__footer">{children}</div>;
}

export default CardFooter;
