import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { catchHandler } from '../../../../utils/error_handling/error_handling';
import { clientSendData } from '../../../../utils/functions/requests';
import { countExperience, formatNumberToExcel } from '../../functions';
import TableWrapper from '../../Components/TableWrapper/TableWrapper';
import Loader from '../../../../components/UI/Loader';
import Table from '../../../../components/Table/Table';
import THead from '../../../../components/Table/THead';
import TBody from '../../../../components/Table/TBody';
import TFoot from '../../../../components/Table/TFoot';
import TRow from '../../../../components/Table/TRow';
import TData from '../../../../components/Table/TData';

/**
* @component Сервис отчётов анкетирования - Расширенный отчёт - Основной
*/
function ReportMain() {
  const [searchParams, setSearchParams] = useSearchParams(); // параметры ссылки
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [report, setReport] = useState([]); // отчёт
  const [questions, setQuestions] = useState([]); // вопросы отчёта
  const [answers, setAnswers] = useState([]); // ответы отчёта
  const start_date = searchParams.get('start_date'); // дата начала
  const end_date = searchParams.get('end_date'); // дата кончания
  const period = !!(start_date && end_date); // наличие периода

  useEffect(() => {
    getOutputInterviewReport(); // получить отчёт по выходному интервью
  }, []);

  // получить отчёт по выходному интервью
  async function getOutputInterviewReport() {
    try {
      const reqData = {
        type: 'getOutputInterviewReport',
        period,
        start_date,
        end_date,
      };

      setIsLoading(true); // состояние - загружается
      const result = await clientSendData('POST', '/get_output_interview_report', reqData);
      if (result?.report?.length > 0) { // если есть данные
        setReport(result.report); // записать отчёт в переменную
        setQuestions(result.questions); // вопросы выходного интервью
        setAnswers(result.answers); // ответы выходного интервью
        setIsLoading(false); // загрузка завершена
      } else {
        setIsLoading(false); // загрузка завершена
      }
    } catch (error) {
      catchHandler(error, 'getOutputInterviewReport');
      setIsLoading(false); // загрузка завершена
    }
  }

  if (isLoading) return <Loader />;
  return (
    <TableWrapper id="reports-oi-main" title="Выходное интервью" back_button>
      <Table id="reports-oi-main">
        <THead>
          <TRow>
            <TData>Отдел</TData>
            <TData>ФИО</TData>
            <TData>Должность</TData>
            <TData>Грейд</TData>
            <TData>Дата приема</TData>
            <TData>Дата увольнения</TData>
            <TData>Количество лет в компании</TData>
            <TData>Наставник</TData>
            <TData>Дата заполнения анкеты</TData>
            {questions.map((q) => {
              const isComment = q.output_id === 4; // Тип ответа на вопрос - комментарий?
              return (
                <React.Fragment key={q.id}>
                  <td className="upu-table__table-data" dangerouslySetInnerHTML={{ __html: q.title }} />
                  {/* Если тип ответа - комментарий, то поле комментария не отображается */}
                  {!isComment && <TData>Комментарий</TData>}
                </React.Fragment>
              );
            })}
          </TRow>
        </THead>
        <TBody>
          {report.map((row) => {
            const { questionnaire_access_id, user_id } = row;
            // Ответы пользователя по конкретному доступу
            const userAnswers = answers.filter((a) => a.user_id === user_id && a.questionnaire_access_id === questionnaire_access_id);
            const firstAnswer = userAnswers[0]; // первый ответ (в каждом содержится инфо о пользователе)
            const experience = countExperience(firstAnswer.extensionattribute14 || firstAnswer.when_created, firstAnswer.dismissal_date);
            const hiredDate = firstAnswer.extensionattribute14 || firstAnswer.when_created;
            return (
              <TRow key={questionnaire_access_id}>
                <TData>{firstAnswer.department}</TData>
                <TData>{firstAnswer.username}</TData>
                <TData>{firstAnswer.title}</TData>
                <TData>{firstAnswer.extensionattribute11}</TData>
                <TData>{hiredDate}</TData>
                <TData>{firstAnswer.dismissal_date}</TData>
                <TData>{formatNumberToExcel(experience)}</TData>
                <TData>{firstAnswer.mentor}</TData>
                <TData>{firstAnswer.date_created}</TData>

                {questions.map((q) => {
                  const questionAnswers = userAnswers.filter((a) => a.question_id === q.id); // поиск ответа на текущий вопрос
                  const isComment = q.output_id === 4; // Тип ответа на вопрос - комментарий?
                  const answer = questionAnswers.length > 0 ? questionAnswers.map((item) => item.answer).join(', ') : ''; // если ответ найден он отображается иначе пустая строка
                  const comment = questionAnswers.length > 0 ? questionAnswers[0].comment : ''; // если ответ найден отображается комментарий, иначе пустая строка
                  return (
                    <React.Fragment key={`${q.id}_${q.output_id}`}>
                      {/* Если тип ответа - комментарий, то сам ответ не отображается */}
                      {!isComment && <td className="upu-table__table-data" dangerouslySetInnerHTML={{ __html: answer }} />}
                      <TData>{comment}</TData>
                    </React.Fragment>
                  );
                })}
              </TRow>
            );
          })}
        </TBody>
        <TFoot />
      </Table>
    </TableWrapper>
  );
}

export default ReportMain;
