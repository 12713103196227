import React from 'react';

/**
* @component Хедер таблицы - строка фильтрации - ячейка фильтрации по всем полям
@prop {field} string Наименование поля
@prop {setFilterField} function Обновление фильтруемого поля
@prop {setSortField} function Обновление сортируемого поля
@prop {filterData} array Массив данных фильтрации/поиска
@prop {setFilterData} function Обновление массива данных фильтрации/поиска
@prop {searchByAllHandler} function Обработчик нажатия "Enter" в инпуте или кнопки "Найти"
@prop {disable_search} boolean Параметр выключения поиска в колонке
*/

function FilterAllCell(props) {
  const {
    field, setFilterField, setSortField, filterData, setFilterData, searchByAllHandler, disable_search,
  } = props; // свойства компонента

  // Обработчик ввода
  function inputHandler(e) {
    // если нажата клавиша 'Enter' - вызывается обработчик
    if (e?.key === 'Enter') searchByAllHandler();
    else { // иначе
      const value = e.target.value?.trim(); // введенное значение (обрезать пробелы по краям)
      if (value) { // если инпут не пустой
        const findObject = filterData.find((item) => item.field === field); // найти объект текущего поля
        if (findObject) {
          setFilterData(filterData.map((item) => { // обновить данные фильтрации
            if (item.field === field) { // найти объект текущего поля
              return { ...item, value: e.target.value }; // и обновить значение
            } return item;
          }));
        // если объект не найден - добавить его в массив
        } else setFilterData([...filterData, { field, value }]);
      } else { // иначе (инпут пустой)
        // обновить данные фильтрации оставить объекты всех полей кроме текущего (удалить объект текущего поля)
        setFilterData(filterData.filter((item) => item.field !== field));
      }
    }
  }

  // Обработчик выбора поля для поиска
  function onClickHandler() {
    setSortField(''); // сбросить сортируемое поле
    setFilterField(field); // установить поле для поиска
  }

  return (
    <td className="upu-table__table-data">
        <input
          type="text"
          className="input search-input"
          placeholder=" "
          onChange={inputHandler}
          onKeyUp={inputHandler}
          onClick={onClickHandler}
          disabled={disable_search}
        />
    </td>
  );
}

export default FilterAllCell;
