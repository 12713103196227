// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#questionnaire .upu-card {
  max-width: 1000px;
}
#questionnaire .checkbox__label {
  margin: 0 8px;
  width: max-content;
}
#questionnaire .toggle {
  margin-right: 6px;
}

.questionnaire__cap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Questionnaire/questionnaire.scss"],"names":[],"mappings":"AACE;EACE,iBAAA;AAAJ;AAGE;EACE,aAAA;EACA,kBAAA;AADJ;AAIE;EACE,iBAAA;AAFJ;;AAOE;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;AAJJ","sourcesContent":["#questionnaire {\n  & .upu-card {\n    max-width: 1000px;\n  }\n\n  .checkbox__label {\n    margin: 0 8px;\n    width: max-content;\n\t}\n  \n  .toggle {\n    margin-right: 6px;\n}\n\t\n}\n.questionnaire {\n  &__cap {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    padding: 16px;\n  }\n  \n \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
