import React from 'react';

/**
* @component Кнопка с иконкой
* @prop {onClick} function (Обязательный) Обработчик клика
* @prop {icon} (Обязательный) string имя иконки кнопки из доступных в /icons/button
* @prop {theme} string цветовая тема (light/red)
*/

function IconButton(props) {
  const {
    onClick, icon, theme, children,
  } = props;

  // Определение класса стилей
  const defineClass = () => {
    let result = 'icon-button';
    if (theme === 'light') result += ' icon-button_light';
    if (theme === 'red') result += ' icon-button_red';
    return result;
  };

  if (!onClick) return null;
  if (!children && !icon) return null;
  return (
    <div onClick={onClick} className={defineClass()} data-testid="icon-button">
      {children || (<img className="icon-button__img" src={`../../icons/button/${icon}.svg`} alt={icon} />)}
    </div>
  );
}

export default IconButton;
