import React from 'react';

/**
  * @component ХелпДеск - заявки - карточка заявки - вкладка "Работа по заявке" - блок сообщения в переписке
  * @prop {subject} string - тема письма
  * @prop {content} string - содержание письма
  * @prop {message_type} string - тип сообщения
  * @prop {sender} string - ФИО отправителя
  * @prop {sender_type} string - тип отправителя (инициатор/исполнитель)
  * @prop {date_created} string - дата записи письма в таблицу sd_ticket_chat
*/
function TicketProcessMessage(props) {
  const {
    date_created, sender_type, sender, content, subject, message_type,
  } = props;
  return (

  //   <div className={`ticket-process-content ticket-process-content_${message_type}`}>
  <div className={`ticket-process-content ${sender_type === 'performer' ? `ticket-process-content_${message_type}` : 'ticket-process-content_received'}`}>
      <p className="ticket-process-content__time">{date_created}</p>
      <p className="ticket-process-content__sender">{sender}</p>
      <p className="ticket-process-content__subject">{subject}</p>
      <p className="ticket-process-content__message">{content}</p>

  </div>
  );
}

export default TicketProcessMessage;
