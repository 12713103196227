import React, { useEffect, useState } from 'react';
import {
  Navigate, Route, Routes, useParams,
} from 'react-router';
import { useSelector } from 'react-redux';
import { catchHandler } from '../../../utils/error_handling/error_handling';
import { alertFunction } from '../../../utils/functions/alertFunction';
import { authorization } from '../../../utils/functions/authenticate';
import { setOperInfo, log } from '../../../utils/functions/others';
import { clientSendData } from '../../../utils/functions/requests';
import { createAction } from '../../../utils/redux/store';
import SequencePageOutput from './SequencePageOutput/SequencePageOutput';
import SinglePageOutput from './SinglePageOutput/SinglePageOutput';
import StandartOutput from './StandartOutput/StandartOutput';
import TableOutput from './TableOutput/TableOutput';
import Loader from '../../../components/UI/Loader';
import Cap from '../../Cap/Cap';
import './interview.scss';

/* Страница интервью
- Компонент получает параметр из url и отправляет запрос в БД.
- Если анкет с таким параметром не существует - с бэкенда вернётся null,
а пользователю покажется заглушка.
- Если анкета есть, вернётся массив вопросов и массив ответов к ним
*/
function Interview() {
  const { link } = useParams(); // параметр ссылка анкеты
  const [isLoading, setIsLoading] = useState(false); // Состояние загрузки
  const [isAuthorized, setIsAuthorized] = useState(false); // Состояние авторизации
  const [noInterview, setNoInterview] = useState(false); // Данные интервью отсутствуют (нет доступа, истек доступ, не активная анкета)
  const isFinish = useSelector((state) => state.interview.is_finish); // состояние завешения опроса
  const outputType = useSelector((state) => state.interview.output_type); // тип отображения
  // const sequence = useSelector((state) => state.interview.sequence);

  useEffect(() => {
    setOperInfo({ alias: 'INTRV' });
    getServiceInfo(); // Получить информацию по сервису
  }, []);

  // Получить информацию по сервису
  async function getServiceInfo() {
    try {
      setIsLoading(true); // состояние - загружается
      const route = `/${window.location.pathname.split('/')[1]}/`; // адрес сервиса без параметра (используется для авторизации)
      const checkAuthorization = await authorization(route); // авторизация
      setIsAuthorized(checkAuthorization);
      if (checkAuthorization) await getInterview(); // при запрос в БД при рендере страницы
      setIsLoading(false); // загрузка завершена
    } catch (error) {
      catchHandler(error, 'getServiceInfo');
    }
  }

  // Получить информацию об анкете
  async function getInterview() {
    try {
      const reqData = {
        type: 'getInterview',
        link,
      };
      const result = await clientSendData('POST', '/get_interview', reqData); // запрос в БД
      if (result) { // если есть результат
        if (result === 'repeat') {
          setNoInterview(true); // интервью отсутствует (показать заглушку)
          alertFunction('repeat_interview', 'clientPost');
        } else {
          log({ message: `Пользователь открыл анкету id: ${result.questionnaire.id} ${result.questionnaire.wiki ? ' для просмотра' : ' для ответов на вопросы'}` });
          createAction('SET_INTERVIEW', result); // запись результатов в хранилище
          createAction('SET_CURRENT_QUESTION', result.questions[0]); // выбрать 1 вопрос в качестве текущего
          createAction('SET_IS_WIKI', result.questionnaire.wiki); // установить параметр isWiki
          createAction('SET_SEQUENCE', result.questionnaire.sequence); // Храним последовательность
          createAction('SET_OUTPUT_TYPE', result.questionnaire.output_id); // установить параметр отображения
        }
      } else {
        setIsLoading(false); // загрузка завершена
        setNoInterview(true); // интервью отсутствует (показать заглушку)
      }
    } catch (error) {
      catchHandler(error, 'getInterview');
    }
  }

  if (isLoading) return <Loader />; // если страница загружается показывается лоадер
  if (isFinish) { // При завершении опроса перенаправление на главную страницу
    createAction('SET_IS_FINISH', false);
    return (
      <Routes>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    );
  }
  if (isAuthorized) { // если пользователь авторизован и есть данные интервью
    // Если нет данных - показывается заглушка "Данные отсутствуют"
    if (noInterview) return <Cap message="Данные отсутствуют" />; //
    switch (outputType) {
      case 1: return <StandartOutput />; // стандартный тип
      case 2: return <TableOutput />; // табличный тип
      case 3: return <SinglePageOutput />; // одностраничный тип
      case 4: return <SequencePageOutput />; // последовательный тип
      default: return null;
    }
  } return <Cap />; // иначе показывается заглушка "Доступ запрещен"
}

export default Interview;
