import React, { useEffect, useState } from 'react';
import { catchHandler } from '../../../../utils/error_handling/error_handling';
import { clientSendData } from '../../../../utils/functions/requests';
import Loader from '../../../../components/UI/Loader';
import CardBody from '../../../../components/Card/CardBody';
import CardSetting from '../../../../components/Card/CardSetting';
import Table from '../../../../components/Table/Table';
import THead from '../../../../components/Table/THead';
import TBody from '../../../../components/Table/TBody';
import TRow from '../../../../components/Table/TRow';
import TData from '../../../../components/Table/TData';

/**
  * @component ХелпДеск - заявки - карточка актива - вкладка "История"
  * @prop {type} number|string - тип сущности (1 - заявка, 2 - актив)
  * @prop {subject_id} number - id сущности
*/
function History(props) {
  const { type, subject_id } = props;
  const [history, setHistory] = useState([]); // история субъекта
  const [scheme, setScheme] = useState(null); // схема субъекта
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки

  const columns = [
    { title: 'Создано', field: 'date_created', align: 'right' },
    { title: 'Создал', field: 'creator', align: 'left' },
    { title: 'Изменения', field: 'message', align: 'left' },
  ];

  useEffect(() => {
    if (subject_id) getSDHistory();
  }, [subject_id]);

  // Получить заявки
  async function getSDHistory() {
    try {
      const reqData = { type: 'getSDHistory' };
      setIsLoading(true);
      const result = await clientSendData('POST', `/get_sd_history/${subject_id}?type=${type}`, reqData);
      if (result?.success && result.data.length) {
        //   console.log(result);
        // Обязательно выводим изначальную (самую первую по времени, т.е. последнюю из запроса бекенда) запись БД в полном формате для сравнения с последующими
        const historyArr = [result.data[result.data.length - 1]];
        // Осуществляем обход массива изменений, начиная с предпоследней записи,
        // ищем поля объекта, отличающиеся по значению от предыдущего элемента массива
        for (let i = result.data.length - 2; i >= 0; i--) {
          const tmpHistObj = {};
          // Обходим все ключи объекта. Из-за особенностей работы JS, сравнение полей-объектов в отдельном условии
          for (const key of Object.keys(result.data[i])) {
            if (key === 'creator') {
              tmpHistObj[key] = result.data[i][key];
            } else if (key === 'object' && JSON.stringify(result.data[i][key]) !== JSON.stringify(result.data[i + 1][key])) {
              tmpHistObj[key] = result.data[i][key];
            } else if (key !== 'object' && result.data[i][key] !== result.data[i + 1][key]) { tmpHistObj[key] = result.data[i][key]; }
          }
          // Число 3 в сравнении - две разные записи в БД обязательно различаются полями id и date_created,
          // также нам обязательно нужено значение creator.
          // Иногда встречаются дубликаты, у которых разные значения только в поле id
          // Проверка нужна, чтобы не отображать описанные выше дубликаты
          if (Object.keys(tmpHistObj).length > 3) { historyArr.push(tmpHistObj); }
        }
        setHistory(historyArr.reverse()); // Результат записывается в состояние
        await getAssetScheme(result.data[0]?.type_id); // запросить схему
      }
    } catch (error) {
      catchHandler(error, 'getSDHistory');
    } finally {
      setIsLoading(false);
    }
  }

  // Получение схемы актива
  async function getAssetScheme(type_id) {
    try {
      if (type_id) {
        const reqData = { type: 'getAssetScheme' };
        const result = await clientSendData('POST', `/get_asset_scheme/${type_id}`, reqData);
        if (result?.success) setScheme(result.scheme);
      }
    } catch (error) {
      catchHandler(error, 'getAssetScheme');
    }
  }

  if (isLoading) return <CardBody><Loader /></CardBody>;
  if (history.length === 0) return <CardBody><h2 className="upu-service__title">История отсутствует</h2></CardBody>;
  return (

          <Table>
            <THead
              headers={columns}
              array={history}
            />
            <TBody>

             {history.map((entry) => (
              <TRow key={entry.id}>
                <TData key="date_created" align="right">{entry.date_created}</TData>
                <TData key="creator" align="left">{entry.creator}</TData>
                <TData key="changes" align="left">
                  <React.Fragment key={entry.id}>
                    { entry.company !== undefined && (<p title="Компания:">{`Компания: ${entry.company}`}</p>)}
                    { entry.type !== undefined && (<p title="Тип актива:">{`Тип актива: ${entry.type}`}</p>) }
                    { entry.status !== undefined && (<p title="Статус:">{`Статус: ${entry.status}`}</p>) }
                    { entry.user !== undefined && (entry.user !== null ? (<p title="Сотрудник:">{`Сотрудник: ${entry.user}`}</p>) : (<p title="Сотрудник:">Сотрудник: Не назначен</p>)) }
                    {scheme && Object.keys(scheme)
                      .sort((a, b) => {
                        if (scheme[a].child_scheme && !scheme[b].child_scheme) return 1;
                        if (!scheme[a].child_scheme && scheme[b].child_scheme) return -1;
                        return 0;
                      }).map((key) => {
                        const { title, child_scheme } = scheme[key];
                        const assetData = entry.object?.[key];
                        if (!assetData || assetData.length === 0) return null;
                        return (
                <CardSetting title={title} key={key}>
                  {!child_scheme ? assetData : (
                    assetData.map((child_object) => {
                      const childObjectProperties = Object.keys(child_scheme)
                        // .filter((child_key) => child_object?.[child_key])
                        .map((child_key) => `${child_scheme[child_key].title}: ${child_object[child_key] || 'отсутствует'}`);
                      return <CardSetting key={child_object.id}>{childObjectProperties.join(', ')}</CardSetting>;
                    })
                  )}
                </CardSetting>
                        );
                      })}
                  </React.Fragment>

                </TData>

{/* {columns.map((column) => {
                  const { field, align } = column;

                  return <TData key={field} align={align}>{entry[field]}</TData>;
                })}
                */}
              </TRow>
             ))}
            </TBody>
          </Table>
  );
}
/* <CardBody>
      {history.map((entry) => {
        const {
          id, object, creator, date_created, message, status, company, type, user,
        } = entry;

        <React.Fragment key={id}>
          <h3 className="upu-service__title">{date_created}</h3>
          { entry.message !== undefined && (<CardSetting title={`${message}:`}>{creator}</CardSetting>)}
          {entry.company !== undefined && (<CardSetting title="Компания:">{company}</CardSetting>)}
          { entry.type !== undefined && (<CardSetting title="Тип актива:">{type}</CardSetting>) }
          { entry.status !== undefined && (<CardSetting title="Статус:">{status}</CardSetting>) }
          { entry.user !== undefined && (entry.user !== null ? (<CardSetting title="Сотрудник:">{user}</CardSetting>) : (<CardSetting title="Сотрудник:">Не назначен</CardSetting>)) }
          {scheme && Object.keys(scheme)
            .sort((a, b) => {
              if (scheme[a].child_scheme && !scheme[b].child_scheme) return 1;
              if (!scheme[a].child_scheme && scheme[b].child_scheme) return -1;
              return 0;
            }).map((key) => {
              const { title, child_scheme } = scheme[key];
              const assetData = object?.[key];
              if (!assetData || assetData.length === 0) return null;
              return (
                <CardSetting title={title} key={key}>
                  {!child_scheme ? assetData : (
                    assetData.map((child_object) => {
                      const childObjectProperties = Object.keys(child_scheme)
                        // .filter((child_key) => child_object?.[child_key])
                        .map((child_key) => `${child_scheme[child_key].title}: ${child_object[child_key] || 'отсутствует'}`);
                      return <CardSetting key={child_object.id}>{childObjectProperties.join(', ')}</CardSetting>;
                    })
                  )}
                </CardSetting>
              );
            })}
        </React.Fragment>
        );
      })}
    </CardBody> */

export default History;

/* <React.Fragment key={id}>
<CardSetting title={`${message}:`}>{creator}</CardSetting>)
<CardSetting title="Компания:">{company}</CardSetting>
          <CardSetting title="Тип актива:">{type}</CardSetting>
          <CardSetting title="Статус:">{status}</CardSetting>
          <CardSetting title="Сотрудник:">{user || 'Не назначен'}</CardSetting>

          {scheme && Object.keys(scheme)
            .sort((a, b) => {
              if (scheme[a].child_scheme && !scheme[b].child_scheme) return 1;
              if (!scheme[a].child_scheme && scheme[b].child_scheme) return -1;
              return 0;
            })
            .map((key) => {
              const { title, child_scheme } = scheme[key];
              const assetData = object?.[key];
              if (!assetData || assetData.length === 0) return null;
              return (
                <CardSetting title={title} key={key}>
                  {!child_scheme ? assetData : (
                    assetData.map((child_object) => {
                      const childObjectProperties = Object.keys(child_scheme)
                        // .filter((child_key) => child_object?.[child_key])
                        .map((child_key) => `${child_scheme[child_key].title}: ${child_object[child_key] || 'отсутствует'}`);
                      return <CardSetting key={child_object.id}>{childObjectProperties.join(', ')}</CardSetting>;
                    })
                  )}
                </CardSetting>
              );</React.Fragment>);
            })} */
