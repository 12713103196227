// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#knowledge-base {
  padding: 0;
}
#knowledge-base .service__body .upu-card__setting-wrapper > * {
  text-align: right;
  margin-left: 8px;
}`, "",{"version":3,"sources":["webpack://./src/pages/KnowledgeBase/Manager/manager.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;AACF;AAIQ;EACE,iBAAA;EACA,gBAAA;AAFV","sourcesContent":["#knowledge-base {\n  padding: 0;\n\n  & .service__body {\n    & .upu-card {\n      &__setting-wrapper {\n        & > * {\n          text-align: right;\n          margin-left: 8px;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
