import React from 'react';
import { useSearchParams } from 'react-router-dom';
import ReportAdvantages from './OIReports/ReportAdvantages';
import ReportDismissalReason from './OIReports/ReportDismissalReason';
import ReportEvaluation from './OIReports/ReportEvaluation';
import ReportExperience from './OIReports/ReportExperience';
import ReportFilials from './OIReports/ReportFilials';
import ReportGrades from './OIReports/ReportGrades';
import ReportMain from './OIReports/ReportMain';
import ReportMainNew from './OIReports/ReportMainNew';
import ReportMvz from './OIReports/ReportMvz';
import ReportNewJob from './OIReports/ReportNewJob';
import ReportRelations from './OIReports/ReportRelations';
import Service from '../../../components/Service/Service';
import ServiceBody from '../../../components/Service/ServiceBody';
import AllCompanyTickets from './SdReports/AllCompanyTickets';
import AllCompanyTicketsSolved from './SdReports/AllCompanyTicketsSolved';
import SolveTicketsByPerformer from './SdReports/SolveTicketsByPerformer';
import PerformerEvaluation from './SdReports/PerformerEvaluation';

/**
* @component Сервис отчётов анкетирования - Расширенный отчёт
*/
function ExtReport() {
  const [searchParams, setSearchParams] = useSearchParams(); // параметры ссылки
  const link = searchParams.get('report'); // значение параметра report
  // Выбрать компонент для отображения на основании параметра link
  const chooseComponent = () => {
    switch (link) {
      case 'oi_mvz': return <ReportMvz />;
      case 'oi_main': return <ReportMain />;
      case 'oi_main_new': return <ReportMainNew />;
      case 'oi_grades': return <ReportGrades />;
      case 'oi_new_job': return <ReportNewJob />;
      case 'oi_filials': return <ReportFilials />;
      case 'oi_relations': return <ReportRelations />;
      case 'oi_advantages': return <ReportAdvantages />;
      case 'oi_evaluation': return <ReportEvaluation />;
      case 'oi_experience': return <ReportExperience />;
      case 'oi_dismissal_reason': return <ReportDismissalReason />;
      case 'sd_all_company_tickets': return <AllCompanyTickets />;
      case 'sd_all_company_tickets_solved': return <AllCompanyTicketsSolved />;
      case 'sd_solve_tickets_by_performer': return <SolveTicketsByPerformer />;
      case 'sd_performer_evaluation': return <PerformerEvaluation />;
      default: return null;
    }
  };

  return (
    <Service id="reports-ext">
      <ServiceBody>
        {chooseComponent()}
      </ServiceBody>
    </Service>
  );
}

export default ExtReport;
