import React, { useState } from 'react';

/**
 * @component Поиск выполняющий обработку при нажатии на кнопку
@prop {searchHandler} function (Обязательный) обработчик поиска
@prop {placeholder} string атрибут placeholder для поля ввода
*/

function InputButtonSearch(props) {
  const { searchHandler, placeholder } = props;

  const [search, setSearch] = useState('');

  return (
    <div className="input-button-search">
        <input
          onClick={(e) => e.stopPropagation()} // предотвратить погружение события в родительские элементы
          onChange={(e) => setSearch(e.target.value)}
          placeholder={placeholder || 'Поиск...'}
          type="text"
          className="input-button-search__input"
        />
        <div onClick={() => searchHandler(search)} className="input-button-search__button">
            <img className="input-button-search__img" src="../../icons/search.svg" alt="search-button" />
        </div>
    </div>
  );
}

export default InputButtonSearch;
