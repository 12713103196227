import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';
import { catchHandler } from '../../../../../utils/error_handling/error_handling';
import { clientSendData } from '../../../../../utils/functions/requests';
import { log } from '../../../../../utils/functions/others';
import {
  defineHideValue, dependentKeysHandler, isFieldRequired, sortFieldsByScheme,
} from '../../../Common/functions';
import Button from '../../../../../components/UI/Button/Button';
import Loader from '../../../../../components/UI/Loader';
import classes from '../../../../../components/UI/Button/button.module.scss';
import InputFit from '../../../../../components/UI/InputFit/InputFit';
import IconButton from '../../../../../components/UI/Button/IconButton';
import ChooseForm from '../../../Common/ChooseForm/ChooseForm';
import QuestionCard from '../../Questions/QuestionCard/QuestionCard';
import ThemeCard from './ThemeCard';

/**
* @component Управление Базой знаний - Темы - Карточка темы - вкладка Настройки
* @prop {theme} object - Объект темы
* @prop {setTheme} function - Обновление состояния объекта темы
* @prop {changeTheme} function - Сохранение изменения темы (создание/изменение/удаление)
* @prop {initial_object} object - Исходный объект темы, до изменений (используется в chooseForm для сброса))
*/
function Settings(props) {
  const {
    theme, setTheme, changeTheme, initial_object,
  } = props; // свойства переданные компоненту при вызове

  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [showChooseForm, setShowChooseForm] = useState(null); // состояние видимости формы выбора
  const [isShowQuestionCard, setShowQuestionCard] = useState(false); // состояние видимости карточки нового вопроса
  const [isShowSubThemeCard, setShowSubThemeCard] = useState(false); // состояние видимости карточки новой карточки подтемы
  const currentDivision = useSelector((state) => state.kb.current_division); // текущее подразделение
  const scheme = useSelector((state) => state.kb.active_scheme.theme?.scheme); // активная схема темы
  const schemeQuestion = useSelector((state) => state.kb.active_scheme.question?.scheme); // активная схема темы
  const dependentKeys = Object.keys(scheme).filter((key) => scheme[key]?.dependence); // Зависимые ключи
  const trackingKeys = dependentKeys.map((key) => scheme[key].dependence?.key); // Отслеживаемые ключи
  const themesTableNode = document.getElementById('knowledge-base__themes'); // DOM элемент
  const isNewTheme = theme?.id === 'new'; // тема создаётся?

  let fields = Object.keys(scheme);
  fields = fields.filter((field) => scheme[field]?.type !== 'html'); // все кроме типа "html"(он показывается во вкладках)
  fields = sortFieldsByScheme({ // Сортировка полей в зависимости от параметра order
    scheme,
    key: 'order',
    fields,
  });

  // ---------------------- Изменение объекта темы ----------------------

  // Внести изменения в объект темы

  function changeSetting(setting) {
    const copy = { ...theme }; // копия объекта для внесения изменений

    // Проверка наличия изменяемых ключей в списке отслеживаемых ключей
    const checkChangingKeys = Object.keys(setting).map((key) => trackingKeys.includes(key));

    // Если изменения в объекте затрагивают отслеживаемые ключи
    if (checkChangingKeys.includes(true)) {
      const result = dependentKeysHandler(setting, scheme, copy); // обновленный объект с обработанными ключами
      setTheme(result); // обновить объект темы
      // Если изменения в объекте не затрагивают отслеживаемые ключи
    } else {
      setTheme({ // просто изменяем значения в объекте
        ...theme,
        ...setting,
      });
    }
  }

  // Удаление темы
  async function deleteTheme() {
    const relatedData = await checkBeforeDeleting(); // получаем связанные данные (кол-во тем и вопросов)

    let message = '';
    const numberOfThemes = relatedData?.themes; // количество связанных тем
    const numberOfQuestion = relatedData?.questions; // количество связанных вопросов

    // Если есть связанные темы и вопросы
    if (numberOfThemes && numberOfQuestion) message = `Количество связанных тем - ${numberOfThemes}, относящихся к ним вопросов - ${numberOfQuestion}. `;
    // Если есть связанные темы, но нет вопросов
    if (numberOfThemes && !numberOfQuestion) message = `Количество связанных тем - ${numberOfThemes}, относящиеся к ним вопросы отсутствуют. `;
    // Если нет связанных темы, но есть вопросы
    if (!numberOfThemes && numberOfQuestion) message = `Связанные темы отсутствуют, к удаляемой теме относится вопросов - ${numberOfQuestion}. `;
    // Если нет ни того, ни другого
    if (!numberOfThemes && !numberOfQuestion) message = 'Связанные темы и вопросы не найдены. ';

    await changeTheme('delete', message);
    log({ message: `Пользователь в карточке темы id: ${theme.id} нажал кнопку "Удалить"` });
  }

  // ---------------------- Получение данных ----------------------

  // Получить количество тем по параметру
  async function checkBeforeDeleting() {
    try {
      // ключ в объекте темы, который содержит id родительской темы
      const parameterTheme = Object.keys(scheme).filter((key) => scheme[key]?.parent)[0];
      // ключ в объекте вопроса, который содержит массив id тем, к которым привязан вопрос
      const parameterQuestion = Object.keys(schemeQuestion).filter((key) => schemeQuestion[key]?.link_kb_themes)[0];

      // Запрос выполнится только в том случае если есть оба ключа
      if (parameterTheme && parameterQuestion) {
        const reqData = {
          type: 'checkBeforeDeleting',
          parameter_theme: parameterTheme,
          parameter_question: parameterQuestion,
          division_id: currentDivision?.id,
          theme_id: theme?.id,
        };

        setIsLoading(true);
        const result = await clientSendData('POST', '/check_before_deleting', reqData); // запрос в БД
        setIsLoading(false);
        return result;
      } return null;
    } catch (error) {
      catchHandler(error, 'checkBeforeDeleting');
      setIsLoading(false);
      return null;
    }
  }

  // Подсветка обязательных полей
  function validableClass(field_data, value) {
    // определения обязательности заполнения поля
    const isRequired = isFieldRequired(field_data, theme);
    // определение наличия значения
    const isValueMissing = !value || value?.length === 0;
    // если поле обязательно для заполнения, но значение отсутствует установить невалидный класс
    if (isRequired && isValueMissing) return 'upu-card__setting upu-card__setting_invalid';
    // иначе установить валидный класс
    return 'upu-card__setting';
  }

  return (
    <div className="upu-card__block">
      {fields.map((field) => { // Проходим по ключам схемы
        const fieldData = scheme[field]; // настройки поля
        const { name, type } = fieldData; // наименование и тип текущего ключа в схеме
        const fieldSwitch = fieldData?.switch; // switch данные поля

        const value = theme?.[field]; // значение текущего ключа в объекте вопроса
        const switchValue = theme?.[`switch_${field}`]; // значение текущего ключа в объекте вопроса
        const isHide = defineHideValue(fieldData, theme);

        // Не показывать поле если нужно его скрыть
        if (isHide) return null;

        // Если поле switch - показать форму выбора
        if (fieldSwitch) {
          return (
            <div className={validableClass(fieldData, value)} key={field}>
              <p className="upu-card__setting-title">{name}</p>

              <div className="upu-card__setting-wrapper">
                <p className="upu-card__setting-title">{switchValue}</p>
                <IconButton
                  icon="edit"
                  onClick={() => {
                    setShowChooseForm(field);
                    log({ message: `Пользователь в карточке темы id: ${theme.id} нажал кнопку редактирования поля ${field}` });
                  }}
                />
              </div>
              {showChooseForm === field && (
              <ChooseForm
                key={field}
                scheme={scheme}
                field={field}
                handler={changeSetting}
                value={value}
                initial_object={initial_object}
                setShow={setShowChooseForm}
              />)}
            </div>
          );
        }
        return ( // Иначе показать универсальный инпут
          <div className={validableClass(fieldData, value)} key={field}>
            <p className="upu-card__setting-title">{name}</p>
            <InputFit
              type={type}
              value={value}
              onChange={(value) => changeSetting({ [field]: value })}
              placeholder={name}
            />
          </div>);
      })}

      {/* Показать карточку вопроса в компоненте списка тем через портал */}
      {isShowQuestionCard && (
        createPortal(<QuestionCard
          id="new"
          parentTheme={theme}
          setShowCard={setShowQuestionCard}
        />, themesTableNode)
      )}

      {/* Показать карточку темы в компоненте списка тем через портал */}
      {isShowSubThemeCard && (
        createPortal(<ThemeCard
          id="new"
          parentTheme={theme}
          setShowCard={setShowSubThemeCard}
        />, themesTableNode)
      )}

      {!isNewTheme && (
      <div className="upu-card__setting">
        <p className="upu-card__setting-title">Создать дочернюю тему</p>
        <Button
          className={classes.button_ghost}
          onClick={() => {
            setShowSubThemeCard(true);
            log({ message: `Пользователь в карточке темы id: ${theme.id} нажал кнопку "Создать"(Создать дочернюю тему)` });
          }}
        >
          Создать
        </Button>
      </div>)}

      {!isNewTheme && (
      <div className="upu-card__setting">
        <p className="upu-card__setting-title">Создать вопрос по теме</p>
        <Button
          className={classes.button_ghost}
          onClick={() => {
            setShowQuestionCard(true);
            log({ message: `Пользователь в карточке темы id: ${theme.id} нажал кнопку "Создать"(Создать вопрос по теме)` });
          }}
        >
          Создать
        </Button>
      </div>)}

      {/* Кнопка удалить только для редактируемой темы */}
      {!isNewTheme && (
      <div className="upu-card__setting">
        <p className="upu-card__setting-title">Удалить</p>
        <Button className={classes.button_ghost} onClick={deleteTheme}>
          {isLoading ? <Loader /> : 'Удалить'}
        </Button>
      </div>)}
    </div>
  );
}

export default Settings;
