import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { catchHandler } from '../../../../utils/error_handling/error_handling';
import { alertFunction } from '../../../../utils/functions/alertFunction';
import { clientSendData } from '../../../../utils/functions/requests';
import { clearHtmlString } from '../../../../utils/functions/stringHandling';
import { checkLength } from '../../functions';
import TableWrapper from '../../Components/TableWrapper/TableWrapper';
import Loader from '../../../../components/UI/Loader';
import Canvas from '../../Components/Canvas/Canvas';

/**
* @component Сервис отчётов анкетирования - Расширенный отчёт
* 7 - Причины увольнения (в целом по компании)
* 8 - Причины увольнения по подразделениям
*/
function ReportDismissalReason() {
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [rawReport, setRawReport] = useState({}); // сырые данные отчёта
  const [searchParams, setSearchParams] = useSearchParams(); // параметры ссылки
  const start_date = searchParams.get('start_date'); // дата начала
  const end_date = searchParams.get('end_date'); // дата кончания
  const period = !!(start_date && end_date); // наличие периода
  const isShowChart = useSelector((state) => state.reports.is_show_chart);// состояние видимости графика
  const title = 'Причина увольнения';
  const [report, setReport] = useState({
    filials: [],
    answers: [],
    report: [],
  }); // готовые данные отчёта

  useEffect(() => {
    getReportDismissalReason(); // получить отчёт по выходному интервью
  }, []);

  // получить отчёт по выходному интервью
  async function getReportDismissalReason() {
    try {
      const reqData = {
        type: 'getReportDismissalReason',
        period,
        start_date,
        end_date,
      };
      setIsLoading(true); // состояние - загружется
      const result = await clientSendData('POST', '/get_oi_report_dismissal_reason', reqData);
      if (result === 'bad_request') { // если некорректный запрос
        alertFunction('bad_request', 'clientPost'); // уведомление
        setIsLoading(false); // загрузка завершена
      } else {
        setRawReport(result);
        createReport(result);
      }
    } catch (error) {
      catchHandler(error, 'getReportDismissalReason');
      setIsLoading(false); // загрузка завершена
    }
  }

  // Создание отчёта на основе полученных данных
  function createReport(report) {
    setReport(report);
    setIsLoading(false); // загрузка завершена
  }

  if (isLoading) return <Loader />;
  if (isShowChart) {
    return (
      <Canvas
        id="reports-oi-new-job__canvas"
        title={title}
        label="Количество, %"
        labels={report.answers.map((row) => clearHtmlString(row.answer))}
        values={report.answers.map((row) => {
          // Текущий вариант ответа по всем подразделениям
          const currentAnswerTotal = report.report.filter((a) => row.id === a.answer_id);
          return (currentAnswerTotal.length / report.report.length) * 100;
        })}
      />
    );
  }
  return (
    <TableWrapper id="reports-oi-dismissal-reason" title={title} back_button chart_button>
      <table id="reports-oi-dismissal-reason" className="upu-table">
        <thead className="upu-table__table-head">
          <tr className="upu-table__table-row">
            <td className="upu-table__table-data" />
            {report.filials.map((f) => <td key={f.department} className="upu-table__table-data">{f.department}</td>)}
            <td className="upu-table__table-data">Итого</td>
          </tr>
        </thead>
        <tbody className="upu-table__table-body">
          {/* Проходим по отчёту */}
          {report.answers.map((answer) => {
            // Текущий вариант ответа по всем подразделениям
            // (среди тех, у кого указано подразделение)
            const currentAnswerTotal = report.report.filter((a) => answer.id === a.answer_id && a.department);
            return (
              // Выводим строки
              <tr key={answer.id} className="upu-table__table-row">
                {/* Первая ячейка - содержание ответа */}
                <td className="upu-table__table-data" dangerouslySetInnerHTML={{ __html: answer.answer }} />
                {/* Далее проходим по подразделениям */}
                {report.filials.map((f) => {
                  // Текущий вариант ответа в текущем подразделении
                  const filterValues = report.report.filter((a) => (a.department === f.department && answer.id === a.answer_id));
                  // Выводим ячейки (Сколько раз выбирали текущий ответа в текущем подразделении)
                  return <td key={f.department} className="upu-table__table-data">{checkLength(filterValues)}</td>;
                })}
                {/* Последняя ячейка - текущий ответ по всем подразделениям */}
                <td className="upu-table__table-data">{checkLength(currentAnswerTotal)}</td>
              </tr>
            );
          })}
        </tbody>
        <tfoot className="upu-table__table-foot" />
      </table>
    </TableWrapper>
  );
}

export default ReportDismissalReason;
