import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { setOperInfo } from '../../../../utils/functions/others';
import Card from '../../../../components/Card/Card';
import CardHeader from '../../../../components/Card/CardHeader';
import CardBody from '../../../../components/Card/CardBody';
import TabPanel from '../../../../components/UI/TabPanel';
import Main from './Main';
import Tickets from './Tickets';
import Assets from './Assets';

/**
  * @component ХелпДеск - активы - карточка пользователя
  * @prop {setShowCard} function - обновление состояния отображения карточки (открыть/закрыть)
  * @prop {refreshTable} function - обновление данных таблицы
*/
function UserCard(props) {
  const { setShowCard, refreshTable } = props;

  const tabs = ['Главная', 'Запросы', 'Активы']; // вкладки
  const [searchParams, setSearchParams] = useSearchParams(); // параметры поиска из url
  const [userID, setUserID] = useState(null); // id открытой карточки
  const [choice, setChoice] = useState(tabs[0]); // состояние выбора вкладки
  const content = {
    Главная: <Main userID={userID} closeHandler={closeHandler} />,
    Запросы: <Tickets userID={userID} />,
    Активы: <Assets userID={userID} />,
  };
  useEffect(() => {
    const userId = searchParams?.get('user'); // взять id заявки из url
    if (userId) { // если id есть
      if (Number(userId)) {
        setUserID(userId); // обновить состояние текущего ID пользователя
        setOperInfo({ subject_id: userId }); // записываем ID пользователя в оперативную информацию
      } else closeHandler();
    }
  }, [searchParams]);

  // закрыть карточку
  function closeHandler() {
    setOperInfo({ subject_id: null }); // сброс id категории в оперативной информацию
    setSearchParams(''); // сброс параметром url
    setShowCard(false); // закрытие карточки
  }

  // обработка выбора вкладки
  function choiceHandler(tab) {
    setChoice(tab); // установить выбор
  }
  return (
    <Card id="helpdesk__user-card" setShow={closeHandler}>
      <CardHeader>
        <TabPanel tabs={tabs} choice={choice} choiceHandler={choiceHandler} />
      </CardHeader>
      {content?.[choice]}
    </Card>
  );
}

export default UserCard;
