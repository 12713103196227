import React, { useEffect, useState } from 'react';
import Table from '../../../components/Table/Table';
import THead from '../../../components/Table/THead';
import TBody from '../../../components/Table/TBody';
import TRow from '../../../components/Table/TRow';
import TData from '../../../components/Table/TData';
import { clientSendData } from '../../../utils/functions/requests';
import { catchHandler } from '../../../utils/error_handling/error_handling';
import TFoot from '../../../components/Table/TFoot';
import EventCard from './EventCard';
import PaginatedTable from '../../../components/Table/PaginatedTable/PaginatedTable';
import ServiceBody from '../../../components/Service/ServiceBody';

/**
* @component Состояние портала - интерфейс администратора - управление событиями контролей
* @prop {event} object - событие, открытое в карточке
*/
function Events() {
  const tableId = 'portal-state__events';
  const [events, setEvents] = useState([]); // все события
  // const [tableState, setTableState] = useState(JSON.parse(localStorage.getItem(tableId)));
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [total, setTotal] = useState(0);
  const [openedEvent, setOpenedEvent] = useState(); // открытое в карточке событие
  const [showCard, setShowCard] = useState(false); // состояние отображения карточки
  const headers = [ // Заголовки таблицы
    { id: 1, title: 'Сервис', field: 'services' },
    { id: 2, title: 'Время начала', field: 'timestamp_start' },
    { id: 3, title: 'Статус начала', field: 'status_start_title' },
    { id: 4, title: 'Время завершения', field: 'timestamp_end' },
    { id: 5, title: 'Создал', field: 'created_by' },
    { id: 6, title: 'Создан', field: 'created_at' },
  ];

  const defaultTableState = {
    title: 'События',
    page: 1,
    limit: 50, // количество строк в таблице
    switch_data: {
      current: 'active',
      titles: {
        all: 'Все',
        active: 'Активные',
      },
    },
  };

  const [tableState, setTableState] = useState(
    JSON.parse(localStorage.getItem(tableId)) || defaultTableState,
  );

  useEffect(() => {
    handlePsEvents();
  }, [tableState]);

  // Обработка действий с событиями
  // Получение данных о
  async function handlePsEvents() {
    try {
      const reqData = {
        type: 'handlePsEvents',
        tableState,
      };
      setIsLoading(true);
      const result = await clientSendData('POST', '/handle_ps_events', reqData);
      if (result) {
        setEvents(result.data);
        setTotal(result.total);
      }
      setIsLoading(false);
    } catch (error) {
      catchHandler(error, 'handlePsEvents');
    }
  }

  function openCard(id) {
    setOpenedEvent(id);
    setShowCard(true);
  }
  // Если параметр отображения карточки - true, показываем карточку
  if (showCard) {
    return (
      <EventCard
        eventId={openedEvent}
        handler={handlePsEvents}
        setShow={setShowCard}
        refreshTable={() => setTableState({ ...tableState })}
      />
    );
  }
  // Иначе показываем таблицу
  return (
<div id="portal-state__event">
    <ServiceBody id="portal-state">
<PaginatedTable
  id="portal-state__event"
  headers={headers}
  state={tableState}
  setState={setTableState}
  total={total}
  optButton={{
    title: 'Создать',
    handler: () => openCard('new'),
  }}
>
{events.map((row) => (
      <TRow key={row.id}>
        {headers.map((column) => {
          const { id, field } = column; // дектруктуризация объекта колонки
          const value = row?.[field]; // значение текущего ключа в объекте строки
          return (<TData key={id} loading={isLoading}>
            <div className="helpdesk__ticket-priority">
            {typeof (value) === 'object' ? value.join(', ') : value ?? '-'}
            {row.status_start_title && field === 'status_start_title'
                    && <div style={{ background: `${row?.status_start_color}`, opacity: '0.2' }} />}
            </div>
                  </TData>);
        })}

<TData onClick={() => openCard(row.id)} loading={isLoading}>
          <img src="../../icons/file-text.svg" alt="file" />
</TData>
      </TRow>
))}

</PaginatedTable>
    </ServiceBody>
</div>
  );
}

export default Events;
