import React, { useEffect, useState } from 'react';
import { catchHandler } from '../../../../utils/error_handling/error_handling';
import { clientSendData } from '../../../../utils/functions/requests';

/**
* @component Запрос количества строк
// Компонент универсального инпута
// Выполняет запрос в БД для получения количества определённых строк
@prop {sql} string (Обязательный) sql запрос,
@prop {onChange} function (Обязательный) обработчик изменений sql запроса
*/

function SqlCount(props) {
  const { sql, onChange } = props;

  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [isEditing, setIsEditing] = useState(false); // состояние редактирования
  const [query, setQuery] = useState(sql); // текст запроса
  const [requestResult, setRequestResult] = useState(''); // результат запроса

  useEffect(() => {
    handleRequest();
  }, []);

  async function handleRequest() {
    try {
      const reqData = {
        type: 'executeQuery',
        query,
        location: window.location.pathname,
      };
      setIsLoading(true);
      const result = await clientSendData('POST', '/execute_query', reqData);
      setRequestResult(result);
      setIsLoading(false);
    } catch (error) {
      catchHandler(error, 'handleRequest');
      setIsLoading(false);
    }
  }
  // Редактировать/сохранить запрос
  function editRequest(e) {
    // Если тип события клик или события нажатия на кнопку Enter
    if (e.type === 'click' || (e.type === 'keyup' && e.key === 'Enter')) {
      if (isEditing) {
        setIsEditing(false);
        onChange(query);
      } else {
        setIsEditing(true);
      }
    }
  }

  return (
    <div className="sql-count">
      {isEditing ? (
      <input
        className="sql-count__input"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        onKeyUp={editRequest}
        placeholder="Введите sql запрос"
        type="text"
      />
      ) : (
      <input
        className={`sql-count__input sql-count__input_disabled ${isLoading ? ' upu__loader' : ''}`}
        value={requestResult}
        type="text"
        disabled
      />)}

      <div onClick={editRequest} className="sql-count__button">
        <img className="sql-count__img" src={`../../icons/button/${isEditing ? 'save' : 'edit'}.svg`} alt={isEditing ? 'save' : 'edit'} />
      </div>
    </div>
  );
}

export default SqlCount;
