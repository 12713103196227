import React, { useEffect, useState, useRef } from 'react';
import Button from '../../../components/UI/Button/Button';
import Input from '../../../components/UI/Input';
import Loader from '../../../components/UI/Loader';
import Toggle from '../../../components/UI/Toggle';
import { log } from '../../../utils/functions/others';
import { stringMatch } from '../../../utils/functions/stringHandling';

function NewQuestionsRequest() {
  const [isLoading, setIsLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [currentQuestionnaire, setCurrentQuestionnaire] = useState({ questions: [] });
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [isAllAdded, setIsAllAdded] = useState(false);
  const [newQuestion, setNewQuestion] = useState('');

  useEffect(() => {
    const allAdded = questions.every((q) => currentQuestionnaire.questions.some((question) => question.id === q.id));
    setIsAllAdded(allAdded);
  }, [questions, currentQuestionnaire]);

  function toggleDefaultHandler(q) {
    const result = currentQuestionnaire.questions.find((question) => question.id === q.id);
    return !!result;
  }

  function toggleQuestion(q) {
    const isQuestionInCurrent = toggleDefaultHandler(q);
    if (!isQuestionInCurrent) {
      setCurrentQuestionnaire({
        ...currentQuestionnaire,
        questions: [...currentQuestionnaire.questions, q],
      });
      log({ message: `Пользователь добавил в анкету вопрос id: ${q.id}`, eventtype: 'info' });
    } else {
      const updatedQuestions = currentQuestionnaire.questions.filter((question) => question.id !== q.id);
      setCurrentQuestionnaire({
        ...currentQuestionnaire,
        questions: updatedQuestions,
      });
      log({ message: `Пользователь убрал из анкеты вопрос id: ${q.id}`, eventtype: 'info' });
    }
  }

  function toggleRequired(e, q) {
    const addRequired = { ...q, required: e.target.checked };

    if (e.target.checked && !toggleDefaultHandler(q)) {
      setCurrentQuestionnaire({
        ...currentQuestionnaire,
        questions: [...currentQuestionnaire.questions, addRequired],
      });
      log({ message: `Пользователь сделал вопрос id: ${q.id} обязательным`, eventtype: 'info' });
    } else {
      setCurrentQuestionnaire({
        ...currentQuestionnaire,
        questions: currentQuestionnaire.questions.map((question) => {
          if (question.id === q.id) {
            log({ message: `Пользователь сделал вопрос id: ${q.id} ${e.target.checked ? ' обязательным' : ' необязательным'}`, eventtype: 'info' });
            return addRequired;
          }
          return question;
        }),
      });
    }
  }

  function searchQuestion(e) {
    const string = e.target.value.toLowerCase();
    const searchResult = questions.filter((q) => stringMatch(string, q.title));

    if (string.length === 0) setFilteredQuestions(questions);
    else setFilteredQuestions(searchResult);
  }

  function toggleAllQuestions() {
    if (!isAllAdded) {
      setCurrentQuestionnaire({
        ...currentQuestionnaire,
        questions,
      });
    } else {
      setCurrentQuestionnaire({
        ...currentQuestionnaire,
        questions: [],
      });
    }
    setFilteredQuestions(questions);
    log({ message: `Пользователь нажал кнопку ${isAllAdded ? 'Сбросить все' : 'Добавить все'}`, eventtype: 'info' });
  }

  function addNewQuestion() {
    if (newQuestion.trim() === '') return;

    const newQuestionObj = {
      id: questions.length + 1,
      title: newQuestion,
      isCustom: true,
    };

    setQuestions([...questions, newQuestionObj]);
    setFilteredQuestions([...filteredQuestions, newQuestionObj]);

    // Добавление нового вопроса в текущую анкету сразу с активным тумблером
    setCurrentQuestionnaire({
      ...currentQuestionnaire,
      questions: [...currentQuestionnaire.questions, newQuestionObj],
    });

    setNewQuestion('');

    log({ message: `Пользователь добавил новый вопрос: ${newQuestion}`, eventtype: 'info' });
  }

  function removeCustomQuestion(id) {
    setQuestions(questions.filter((q) => q.id !== id));
    setFilteredQuestions(filteredQuestions.filter((q) => q.id !== id));
    setCurrentQuestionnaire({
      ...currentQuestionnaire,
      questions: currentQuestionnaire.questions.filter((q) => q.id !== id),
    });
    log({ message: `Пользователь удалил вопрос id: ${id}`, eventtype: 'info' });
  }

  const dragQuestion = useRef(null);
  const draggedOverQuestion = useRef(null);

  function handleSort() {
    if (dragQuestion.current !== null && draggedOverQuestion.current !== null) {
      const questionsClone = [...filteredQuestions];
      const dragIndex = parseInt(dragQuestion.current, 10);
      const dropIndex = parseInt(draggedOverQuestion.current, 10);

      const temp = questionsClone[dragIndex];
      questionsClone[dragIndex] = questionsClone[dropIndex];
      questionsClone[dropIndex] = temp;

      setFilteredQuestions(questionsClone);
      setCurrentQuestionnaire({
        ...currentQuestionnaire,
        questions: questionsClone,
      });
    }
  }

  if (isLoading) return <Loader />;
  return (
    <div className="questions-card__block">
      <div className="questions-card__block_space-between">
        <Input placeholder="Поиск вопроса" onChange={searchQuestion} />
        <Button onClick={toggleAllQuestions}>{isAllAdded ? 'Сбросить все' : 'Добавить все'}</Button>
      </div>

      {filteredQuestions.map((q, index) => (
        <div key={q.id} className="questions-card__block_align-left">
          <div
            id={index}
            className="questions-card__question-choose-panel"
            draggable
            onDragStart={(e) => { dragQuestion.current = e.target.id; }}
            onDragEnter={(e) => { draggedOverQuestion.current = e.target.id; }}
            onDragEnd={handleSort}
            onDragOver={(e) => e.preventDefault()}
          >
            <Toggle state={String(toggleDefaultHandler(q))} onClick={() => toggleQuestion(q)} />
            <span id={index} className="clickable">
              {q.title}
            </span>
            {q.isCustom && (
              <span onClick={() => removeCustomQuestion(q.id)} className="delete-question">X</span>
            )}
          </div>
        </div>
      ))}

      <div className="questions-card__block_add_new">
        <div className="questions-card__block_align-left">
          <div className="questions-card__question-choose-panel">
            <Input
              id="question-input"
              className="input-field"
              placeholder="Добавить свой вопрос"
              value={newQuestion}
              onChange={(e) => setNewQuestion(e.target.value)}
            />
            <Button onClick={addNewQuestion}>Добавить</Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewQuestionsRequest;
