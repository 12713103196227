import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import ServiceBody from '../../../components/Service/ServiceBody';
import PaginatedTable from '../../../components/Table/PaginatedTable/PaginatedTable';
import TRow from '../../../components/Table/TRow';
import TData from '../../../components/Table/TData';
import TicketCard from './TicketCard/TicketCard';
import { convertTicketId, defaultTableState, headers } from '../common';
import { clientSendData } from '../../../utils/functions/requests';
import { catchHandler } from '../../../utils/error_handling/error_handling';
import { store } from '../../../utils/redux/store';

/**
* @component ХелпДеск - Интерфейс пользователя
*/
function User() {
  const tableId = 'helpdesk__user-tickets';
  // const [searchParams, setSearchParams] = useSearchParams(); // параметры поиска в url
  const [total, setTotal] = useState(0);
  const [tickets, setTickets] = useState([]); // отображаемые заявки
  const [tableState, setTableState] = useState(JSON.parse(localStorage.getItem(tableId)) || defaultTableState.user.main);
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [showCard, setShowCard] = useState(false); // состояние отображения карточки
  const [ticketId, setTicketId] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams(); // параметры поиска в url

  useEffect(() => {
    localStorage.setItem(tableId, JSON.stringify(tableState));
    getTickets();
  }, [tableState]);

  useEffect(() => {
    // проверка параметров url при их изменении (для открытия карточки)
    if (searchParams.size > 0) { // если есть какие то параметры
      // если url содержит параметр поиска "ticket" открывается карточка вопроса
      if (searchParams?.get('userTicket')) {
        setTicketId(searchParams?.get('userTicket'));
        setShowCard(true);
      }
      // else setSearchParams(''); // иначе это другие параметры - сбрасываем их
    }
  }, [searchParams]);

  // useEffect(() => {
  //   // проверка параметров url при их изменении (для открытия карточки)
  //   if (searchParams.size > 0) { // если есть какие то параметры
  //     // если url содержит параметр поиска "ticket" открывается карточка вопроса
  //     if (searchParams?.get('ticket')) setShowCard(true);
  //     // else setSearchParams(''); // иначе это другие параметры - сбрасываем их
  //   }
  // }, [searchParams]);

  // Получить заявки
  async function getTickets() {
    try {
      const reqData = {
        type: 'getTickets',
        tableState,
        total,
        initiator: true,
      };
      setIsLoading(true);
      const result = await clientSendData('POST', '/get_tickets', reqData);
      if (result?.success) {
        setTickets(result.tickets); // Результат записывается в состояние
        setTotal(result.total);
      }
    } catch (error) {
      catchHandler(error, 'getTickets');
    } finally {
      setIsLoading(false);
    }
  }

  function openTicketCard(id) {
    setShowCard(true);
    setTicketId(id);
  }

  return (
    <ServiceBody>
      <PaginatedTable
        id={tableId}
        state={tableState}
        setState={setTableState}
        headers={headers.user.main}
        total={total}
      >
        {tickets.map((ticket) => (
          <TRow key={ticket.id}>
            {headers.user.main.map((column) => {
              const { field, align, mobile_hide } = column;
              return (
              <TData key={field} align={align} loading={isLoading} mobileHide={mobile_hide}>
                {(() => {
                  switch (field) {
                    case 'id': return `#${ticket.ticket_number}`;
                    // case 'title': return <Link className="clickable" to={`?ticket=${convertTicketId(ticket.id)}`}>{ticket[field]}</Link>;
                      //  case 'title': return <span className="clickable" onClick={() => openTicketCard(ticket.id)}>{ticket[field]}</span>;
                    case 'title': return <Link className="clickable" to={`?userTicket=${ticket.id}`}>{ticket[field]}</Link>;
                    default: return ticket[field];
                  }
                })()}
              </TData>);
            })}
          </TRow>
        ))}
      </PaginatedTable>

      {showCard && (
      <TicketCard
        ticketId={ticketId}
        setShowCard={setShowCard}
        refreshTable={() => setTableState({ ...tableState })}
      />
      )}
    </ServiceBody>
  );
}

export default User;
