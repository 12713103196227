import React from 'react';
import { useSelector } from 'react-redux';
import { createAction } from '../../utils/redux/store';
import Close from './Close';

/**
 * @component Уведомление
 Компонент лежит в хэдере приложения, управляется через redux хранилище
*/

function Notice() {
  const isShowNotice = useSelector((state) => state.notice.show);
  const message = useSelector((state) => state.notice.message); // текст сообщения
  const type = useSelector((state) => state.notice.type); // тип сообщения (error/info)

  return (
    <div className={`notice notice_${isShowNotice ? type : 'hide'}`}>
      <img src={`../../icons/notifications/${type}.svg`} alt={type} className="notice__img" />
      <p className="notice__message">{message}</p>
      <Close onClick={() => createAction('HIDE_NOTICE')} />
    </div>
  );
}

export default Notice;
