// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  position: absolute;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 8px 16px;
  height: 40px;
}
.footer__logo {
  max-height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/footer.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,SAAA;EACA,UAAA;EAEA,aAAA;EACA,mBAAA;EACA,uBAAA;EAEA,WAAA;EACA,iBAAA;EACA,YAAA;AAHF;AAME;EACE,gBAAA;AAJJ","sourcesContent":["@use \"../../variables\" as var;\n\n.footer {\n  position: absolute;\n  bottom: 0;\n  z-index: 1;\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  width: 100%;\n  padding: 8px 16px;\n  height: 40px;\n // background-color: var.$secondary_color_3;\n\n  &__logo {\n    max-height: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
