/* eslint-disable no-shadow */
import React, { useState } from 'react';
import { catchHandler } from '../../../utils/error_handling/error_handling';
import { alertFunction } from '../../../utils/functions/alertFunction';
import { clientSendData } from '../../../utils/functions/requests';
import { emailValidate, loginValidate } from '../../../utils/functions/stringHandling';
import Input from '../../UI/Input';
import CheckBox from '../../UI/CheckBox';
import Button from '../../UI/Button/Button';
import classes from '../../UI/Button/button.module.scss';
import './editUserForm.scss';

/**
 * @component Форма редактирования информации о пользователе
  @prop {userInfo} object (Обязательный) Объект с информацией о пользователе
  @prop {setUserInfo} function (Обязательный) Функция обновления информациии о пользователе
  @prop {setShow} function (Обязательный) Функция обновления состояния отображения формы
*/

function EditUserForm(props) {
  const { userInfo, setUserInfo, setShow } = props;

  const {
    sn,
    dn,
    cn,
    mail,
    fired,
    enabled,
    company,
    initials,
    givenname,
    displayname,
    description,
    samaccountname,
    userprincipalname,
    extensionattribute11,
  } = userInfo; // деструктуризация информации о пользователе

  const [editingUser, setEditingUser] = useState(userInfo);

  // Функция скрытия формы редактирования
  function hideEditUserForm() {
    setShow(false);
  }

  // Обработчик формы редактирования
  async function editUserFormHandler(event) {
    try {
      event.preventDefault();
      const {
        cn,
        dn,
        mail,
        objectsid,
        displayname,
        samaccountname,
        userprincipalname,
      } = editingUser; // деструктуризация информации о пользователе
      // Отредактированные данные пользователя для отправки в БД
      const reqData = {
        type: 'editUser',
        user: editingUser,
      };

      // Функция отправки результатов в БД
      async function sendResults() {
        try {
          const result = await clientSendData('POST', '/edit_user', reqData); // Отправка данных
          if (result === 'success') { // Если кол-во затронутых строк больше 0
            await alertFunction('data_changed', 'clientPost'); // уведомление Информация о пользователе изменена!
            setUserInfo(editingUser); // обновление данных в хранилище
            hideEditUserForm(); // форма скрывается
          }
        } catch (error) {
          catchHandler(error, 'sendResults');
        }
      }

      // Сравнение логина и почты с уже существующими в БД
      async function checkMatches() {
        try {
          const reqData2 = {
            type: 'checkMatchesEdit',
            login: samaccountname,
            email: mail,
            objectsid,
          };
          const result = await clientSendData('POST', '/check_matches_edit', reqData2); // Отправка данных
          if (result) return result;
          return null;
        } catch (error) {
          catchHandler(error, 'checkMatches');
          return null;
        }
      }

      // // Валидация полей
      if (samaccountname && dn && userprincipalname && mail && cn && displayname) { // Если данные поля не пустые
        if (loginValidate(samaccountname) && emailValidate(mail)) { // Если логин и почта прошли валидацию
          const match = await checkMatches();
          if (!match?.login_match && !match?.email_match) sendResults(); // Если логин и пароль прошли проверку данные отправляются
          else if (match.login_match) alertFunction('login_match', 'clientPost'); // Если есть совпадения логина - уведомление
          else if (match.email_match) alertFunction('email_match', 'clientPost'); // Если есть совпадения почты - уведомление
        }
      } else alertFunction('required_fields'); // Иначе уведомление Заполните обязательные поля!
    } catch (error) {
      catchHandler(error, 'editUserFormHandler');
    }
  }

  function changeStringValue(field, event) {
    setEditingUser({ ...editingUser, [field]: event.target.value });
  }

  return (
    <div className="edit-user-form__background" onMouseMove={(e) => e.stopPropagation()}>
      <form className="edit-user-form" id="edit-user">
        <img
          className="edit-user-form__close-button"
          src="../../icons/button/plus.svg"
          alt="close"
          onClick={hideEditUserForm}
        />
        <div className="edit-user-form__input-block">
          <Input
            required
            placeholder="samaccountname*"
            defaultValue={samaccountname}
            onChange={(e) => changeStringValue('samaccountname', e)}
          />
          <Input
            required
            placeholder="dn*"
            defaultValue={dn}
            onChange={(e) => changeStringValue('dn', e)}
          />
          <Input
            required
            placeholder="userprincipalname*"
            defaultValue={userprincipalname}
            onChange={(e) => changeStringValue('userprincipalname', e)}
          />
          <Input
            required
            placeholder="mail*"
            defaultValue={mail}
            onChange={(e) => changeStringValue('mail', e)}
          />
          <Input
            placeholder="sn"
            defaultValue={sn}
            onChange={(e) => changeStringValue('sn', e)}
          />
          <Input
            placeholder="givenname"
            defaultValue={givenname}
            onChange={(e) => changeStringValue('givenname', e)}
          />
          <Input
            placeholder="initials"
            defaultValue={initials}
            onChange={(e) => changeStringValue('initials', e)}
          />
          <Input
            required
            placeholder="сn*"
            defaultValue={cn}
            onChange={(e) => changeStringValue('сn', e)}
          />
          <Input
            required
            placeholder="displayname*"
            defaultValue={displayname}
            onChange={(e) => changeStringValue('displayname', e)}
          />
          <Input
            placeholder="description"
            defaultValue={description}
            onChange={(e) => changeStringValue('description', e)}
          />
          <Input
            placeholder="extensionattribute"
            defaultValue={extensionattribute11}
            onChange={(e) => changeStringValue('extensionattribute11', e)}
          />
          <Input
            placeholder="company"
            defaultValue={company}
            onChange={(e) => changeStringValue('company', e)}
          />
          <div className="edit-user-form__block">
            <CheckBox
              id="user-enabled"
              defaultChecked={enabled}
              onChange={(e) => setEditingUser({ ...editingUser, enabled: e.target.checked })}
            >
              Активен
            </CheckBox>
            <CheckBox
              id="fired"
              defaultChecked={fired}
              onChange={(e) => setEditingUser({ ...editingUser, fired: e.target.checked })}
            >
              Уволен
            </CheckBox>
          </div>
        </div>
        <Button className={classes.button} type="submit" onClick={editUserFormHandler}>Сохранить</Button>
      </form>
    </div>
  );
}

export default EditUserForm;
