import React from 'react';
import Close from '../../Close';

/**
* @component Список тегов
@prop {array} array (Обязательный) массив тегов,
@prop {onDelete} function (Обязательный) обработчик удаления
*/

function TagsList(props) {
  const { array, onDelete } = props;
  return (
    <div className="tags__list">
      {array.map((item, index) => (
        <p key={index} className="tags__tag">
          <Close onClick={() => onDelete(item, index)} />
          {item?.title || item}
        </p>
      ))}
    </div>
  );
}

export default TagsList;
