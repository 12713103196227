import React from 'react';
import { sortObjectKeys } from '../Common/functions';

/**
* @component База знаний - Панель - Иерархическая структура тем - элемент структуры
* @prop {object} object - объект темы
* @prop {scheme} object - схема темы
* @prop {activeTheme} object - объект активной(выбранной) темы
* @prop {setActiveTheme} function - обновление состояния активной темы
* @prop {openedEssences} array - массив открытых сущностей
* @prop {setOpenedEssences} function - обновление состояния массива открытых сущностей
* @prop {highlighted} array - выделенные темы (при поиске)
* @prop {openEssences} function - открытие сущностей
* @prop {getThemesQuestions} function - получение вопросов по выбранной теме
*/
function ThemeHierarchyLine(props) {
  const {
    object, scheme, activeTheme, setActiveTheme, openedEssences, setOpenedEssences, highlighted, openEssences, getThemesQuestions,
  } = props;

  const { id } = object;
  const parentKey = scheme ? Object.keys(scheme).filter((key) => scheme[key]?.parent)[0] : null; // ключ для создания иерархической структуры (если есть схема)
  const titleKey = scheme?.[parentKey]?.switch?.switch_column; // поле для получения заголовка отображаемого в структуре
  const isOpened = (id) => openedEssences.includes(id); // Сущность открыта?
  const isHighlighted = (id) => (highlighted.includes(id)); // Сущность выделена?
  const titleClass = `hierarchy__title ${activeTheme?.id === id ? 'hierarchy__title_active' : ''}`;
  const titleContentClass = `hierarchy__title-content ${isHighlighted(id) ? 'hierarchy__title-content_active' : ''}`;
  const themesSorting = 'asc'; // Сортировка тем иерархии
  const defineClass = (object) => { // Определить класс
    let result = 'hierarchy__expand';
    if (!object?.children) result += ' hierarchy__expand_hide';
    if (isOpened(object?.id)) result += ' hierarchy__expand_active';
    return result;
  };

  // --------------------Обработка действий------------------------

  // Выбрать сущность
  function chooseEssence(object) {
    setActiveTheme(object); // активировать тему
    const children = defineChildren(object); // найти детей
    getThemesQuestions([object.id, ...children]); // передать id тем для получения связанных вопросов
  }

  // Открыть/закрыть конкретную сущность
  function toggleEssence(event, id) {
    event.stopPropagation();
    if (openedEssences.includes(id)) closeEssences([id]); // если найдена среди активных - сущность закрывается
    else openEssences([id]); // если нет - сущность открывается
  }

  // Закрыть сущности
  function closeEssences(array) {
    // оставить только те, которых нет в array
    const newArray = openedEssences.filter((item) => !array.includes(item));
    setOpenedEssences(newArray);
  }

  // Определить id дочерних элементов объекта в иерархии
  function defineChildren(parent) {
    const result = []; // массив для записи id детей

    // Функция добавления детей в массив
    function addChild(object) {
      if (object?.children) { // если объект содержит children
        const { children } = object;
        Object.keys(children).forEach((key) => { // пройти по ключам children
          result.push(children[key].id); // и запись id в массив
          // если ключ содержит детей вызвать функцию заново передав ей дочерний элемент
          if (children[key]?.children) addChild(children[key]);
          // иначе выйти из рекурсии
        });
      } // иначе выйти из рекурсии
    }

    addChild(parent); // записать id дочерних элеменов

    return result; // вернуть массив
  }

  // --------------------Отображение-----------------------------

  // Показать детей объекта
  function showChildrens(object) {
    if (object?.children) {
      return (
        <div className={`hierarchy__children ${isOpened(object?.id) ? 'hierarchy__children_active' : ''}`}>
          {sortObjectKeys(object.children, titleKey, themesSorting).map((key) => {
            const child = object.children[key];
            return (
              <ThemeHierarchyLine
                key={key}
                object={child}
                scheme={scheme}
                activeTheme={activeTheme}
                setActiveTheme={setActiveTheme}
                openedEssences={openedEssences}
                setOpenedEssences={setOpenedEssences}
                highlighted={highlighted}
                openEssences={openEssences}
                getThemesQuestions={getThemesQuestions}
              />
            );
          })}
        </div>
      );
    } return null;
  }

  return (
    <div className="hierarchy__line">
      <div className={titleClass} onClick={() => chooseEssence(object)}>
        {/* Свернуть/развернуть сущность */}
        <div className={defineClass(object)} onClick={(event) => toggleEssence(event, id)} />
        <span className={titleContentClass}>
          {object[titleKey]}
        </span>
      </div>
      {showChildrens(object)}
    </div>
  );
}

export default ThemeHierarchyLine;
