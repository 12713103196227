import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { log } from '../../../../../utils/functions/others';
import {
  defineHideValue, dependentKeysHandler, sortFieldsByScheme, isFieldRequired,
} from '../../../Common/functions';
import Button from '../../../../../components/UI/Button/Button';
import classes from '../../../../../components/UI/Button/button.module.scss';
import InputFit from '../../../../../components/UI/InputFit/InputFit';
import IconButton from '../../../../../components/UI/Button/IconButton';
import ChooseForm from '../../../Common/ChooseForm/ChooseForm';
import Replace from '../../../Common/Replace/Replace';
import CardSetting from '../../../../../components/Card/CardSetting';

/**
* @component Управление Базой знаний - Вопросы - Карточка вопроса - вкладка Настройки
* @prop {question} object - Объект вопроса
* @prop {setQuestion} function - Обновление состояния объекта вопроса
* @prop {changeQuestion} function - Сохранение изменения вопроса (создание/изменение/удаление)
* @prop {initialObject} object - Исходный объект вопроса, до изменений (используется в chooseForm для сброса))
*/
function Settings(props) {
  const {
    changeQuestion, question, initialObject, setQuestion,
  } = props; // свойства переданные компоненту при вызове

  const scheme = useSelector((state) => state.kb.active_scheme.question?.scheme); // активная схема вопроса
  const [showChooseForm, setShowChooseForm] = useState(null); // состояние видимости формы выбора
  const [showReplace, setShowReplace] = useState(false); // состояние видимости формы поиска и замены
  const isNewQuestion = question?.id === 'new'; // вопрос создаётся?
  const dependentKeys = Object.keys(scheme).filter((key) => scheme[key]?.dependence); // Зависимые ключи
  const trackingKeys = dependentKeys.map((key) => scheme[key].dependence?.key); // Отслеживаемые ключи

  // Поля схемы для отображения в настройках
  let fields = Object.keys(scheme);
  fields = fields.filter((field) => scheme[field]?.type !== 'html'); // все кроме типа "html"(он показывается во вкладках)
  fields = sortFieldsByScheme({ // Сортировка полей в зависимости от параметра order
    scheme,
    key: 'order',
    fields,
  });

  // Изменить объект вопроса
  function changeSetting(setting) {
    const copy = { ...question }; // копия объекта для внесения изменений

    // Проверка наличия изменяемых ключей в списке отслеживаемых ключей
    const checkChangingKeys = Object.keys(setting).map((key) => trackingKeys.includes(key));

    // Если изменения в объекте затрагивают отслеживаемые ключи
    if (checkChangingKeys.includes(true)) {
      const result = dependentKeysHandler(setting, scheme, copy); // обновленный объект с обработанными ключами
      setQuestion(result);
      // Если изменения в объекте не затрагивают отслеживаемые ключи
    } else {
      setQuestion({
        ...question,
        ...setting,
      });
    }
  }

  // Подсветка обязательных полей
  // function validableClass(field_data, value) {
  //     // определения обязательности заполнения поля
  //     const isRequired = isFieldRequired(field_data, question)
  //     // определение наличия значения
  //     const isValueMissing = !value || value?.length === 0
  //     // если поле обязательно для заполнения, но значение отсутствует установить невалидный класс
  //     if (isRequired && isValueMissing) return "upu-card__setting upu-card__setting_invalid"
  //     // иначе установить валидный класс
  //     else return "upu-card__setting"
  // }

  // Подсветка обязательных полей
  const isInvalid = (field_data, value) => {
    const isRequired = isFieldRequired(field_data, question); // определения обязательности заполнения поля
    const isValueMissing = !value || value?.length === 0; // определение наличия значения
    // если поле обязательно, но значение отсутствует вернуть true иначе false
    return (isRequired && isValueMissing);
  };

  return (
    <div className="upu-card__block">
      {fields.map((field) => { // Проходим по ключам схемы
        const fieldData = scheme[field]; // настройки поля
        const { name, type } = fieldData; // наименование и тип текущего ключа в схеме
        const fieldSwitch = fieldData?.switch; // switch данные поля

        const value = question?.[field]; // значение текущего ключа в объекте вопроса
        const switchValue = question?.[`switch_${field}`]; // значение текущего ключа в объекте вопроса
        const isHide = defineHideValue(fieldData, question);

        // Не показывать поле если нужно его скрыть
        if (isHide) return null;

        // Если поле switch - показать форму выбора
        if (fieldSwitch) {
          return (
            <CardSetting key={field} title={name} invalid={isInvalid(fieldData, value)}>
              <div className="upu-card__setting-wrapper">
                <p className="upu-card__setting-title">{switchValue}</p>
                <IconButton
                  icon="edit"
                  onClick={() => {
                    setShowChooseForm(field);
                    log({ message: `Пользователь в карточке вопроса id: ${question.id} нажал на кнопку редактирования поля ${field}` });
                  }}
                />
              </div>
              {showChooseForm === field && (
              <ChooseForm
                key={field}
                scheme={scheme}
                field={field}
                handler={changeSetting}
                value={value}
                initialObject={initialObject}
                setShow={setShowChooseForm}
              />)}
            </CardSetting>
          );
        }
        return ( // Иначе показать универсальный инпут
          <CardSetting key={field} title={name} invalid={isInvalid(fieldData, value)}>
            <InputFit
              type={type}
              value={value}
              onChange={(value) => changeSetting({ [field]: value })}
              placeholder={name}
            />
          </CardSetting>
        );
      })}

      {/* Кнопка удалить только для редактируемого вопроса */}
      {!isNewQuestion && (// если вопрос не создается, а редактируется
      <CardSetting title="Удалить">
        <Button className={classes.button_ghost} onClick={() => changeQuestion('delete')}>
          Удалить
        </Button>
      </CardSetting>)}

      {/* Поиск и замена */}
      <CardSetting title="Поиск и замена">
        <Button
          className={classes.button_ghost}
          onClick={() => {
            setShowReplace(true);
            log({ message: `Пользователь в карточке вопроса id: ${question.id} нажал кнопку "Поиск и замена"` });
          }}
        >
          Показать
        </Button>
      </CardSetting>

      {showReplace && (
      <Replace
        scheme={scheme}
        question={question}
        setQuestion={setQuestion}
        setShow={setShowReplace}
      />)}
    </div>
  );
}

export default Settings;
