import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { catchHandler } from '../../../../utils/error_handling/error_handling';
import { clientSendData } from '../../../../utils/functions/requests';
import {
  checkLength,
  createPeriod,
  getMonthName,
  getPeriodByInterval,
} from '../../functions';
import TableWrapper from '../../Components/TableWrapper/TableWrapper';
import Loader from '../../../../components/UI/Loader';
import Canvas from '../../Components/Canvas/Canvas';

/**
* @component Сервис отчётов анкетирования - Расширенный отчёт
* 10 - Распределение увольняющихся по МВЗ
*/
function ReportMvz() {
  const isShowChart = useSelector((state) => state.reports.is_show_chart);// состояние видимости графика
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [rawReport, setRawReport] = useState([]); // сырые данные отчёта
  const [report, setReport] = useState([]); // готовые данные отчёта
  const [months, setMonths] = useState([]); // период по месяцам
  const [searchParams, setSearchParams] = useSearchParams(); // параметры ссылки
  const start_date = searchParams.get('start_date'); // дата начала
  const end_date = searchParams.get('end_date'); // дата окончания
  const period = !!(start_date && end_date); // наличие периода
  const title = 'Распределение увольняющихся по МВЗ';
  const filteredReport = report.filter((item) => item.value.length > 0).sort((a, b) => b.value.length - a.value.length);
  const interviewedTotal = rawReport.filter((row) => row.interviewed); // Прошли интервью (всего по всем подразделениям)

  useEffect(() => {
    getReportMvz(); // получить отчёт по выходному интервью
  }, []);

  // получить отчёт по выходному интервью
  async function getReportMvz() {
    try {
      const reqData = {
        type: 'getReportMvz',
        period,
        start_date,
        end_date,
      };
      setIsLoading(true); // состояние - загружается
      const result = await clientSendData('POST', '/get_oi_report_mvz', reqData);
      if (result?.report?.length > 0) {
        // если нет периода - создать на основании дат результата
        if (!period) setMonths(createPeriod(result.report, 'dismissal_date'));
        // Иначе - создать на основании дат периода
        else setMonths(getPeriodByInterval('month', start_date, end_date));

        setRawReport(result.report);
        createReport(result);
      } else setIsLoading(false); // загрузка завершена
    } catch (error) {
      catchHandler(error, 'getReportMvz');
      setIsLoading(false); // загрузка завершена
    }
  }

  // Создание отчёта на основе полученных данных
  function createReport(report) {
    setReport(report.mvz.map((m) => {
      if (!m.mvz) {
        // Уволенные у которых не указан МВЗ
        const dismissedNoMvz = report.report.filter((r) => !r.mvz);
        // Название МВЗ и массив уволенных из него
        return { title: 'МВЗ отсутствует', value: dismissedNoMvz };
      }
      // Уволенные в конкретном МВЗ
      const dismissed = report.report.filter((r) => r.mvz === m.mvz);
      // Название МВЗ и массив уволенных из него
      return { title: m.mvz, value: dismissed };
    }));

    setIsLoading(false); // загрузка завершена
  }

  if (isLoading) return <Loader />;
  if (isShowChart) {
    return (
      <Canvas
        id="reports-oi-filials__canvas"
        type="bar"
        title={title}
        label="Количество"
        legend_position="top"
        labels={filteredReport.map((row) => row.title)}
        values={filteredReport.map((row) => row.value.length)}
      />
    );
  }
  return (
    <TableWrapper id="reports-oi-filials" title={title} back_button chart_button>
      <table id="reports-oi-filials" className="upu-table">
        <thead className="upu-table__table-head">
            <tr className="upu-table__table-row">
              <td className="upu-table__table-data">Период</td>
              {months.map((month) => <td key={month} className="upu-table__table-data">{getMonthName(month)}</td>)}
              <td className="upu-table__table-data">Итого</td>
            </tr>
            <tr className="upu-table__table-row">
              <td className="upu-table__table-data" />
              {months.map((month) => (
                <React.Fragment key={month}>
                  <td className="upu-table__table-data">Уволено</td>
                  <td className="upu-table__table-data">Интервью</td>
                </React.Fragment>))}
              <td className="upu-table__table-data">Уволено</td>
              <td className="upu-table__table-data">Интервью</td>
            </tr>
        </thead>
        <tbody className="upu-table__table-body">
          {/* Проходим по отчёту */}
          {report.map((row, index) => {
            const interviewFilialTotal = row.value.filter((row) => row.interviewed); // Прошли интервью (всего в МВЗ)
            return (
              // Выводим строки
              <tr key={row.title + index} className="upu-table__table-row">
                {/* Первая ячейка - наименование МВЗ */}
                <td className="upu-table__table-data">{row.title}</td>
                {/* Далее проходим по месяцам */}
                {months.map((month) => {
                  // Находим уволившихся в текущем месяце
                  const filterValues = row.value.filter((value) => {
                    const dismissalDate = new Date(value.dismissal_date).getMonth(); // месяц увольнения
                    const fieldDate = new Date(month).getMonth(); // месяц в колонке
                    return dismissalDate === fieldDate; // вернуть совпадения месяцев
                  });
                  // Прошли интервью (на дату в текущем МВЗ)
                  const interviewed = filterValues.filter((row) => row.interviewed);
                  // Выводим ячейки
                  return (
                    <React.Fragment key={row.title + month}>
                      {/* Длина массива уволившихся в текущем месяце */}
                      <td className="upu-table__table-data">{checkLength(filterValues)}</td>
                      {/* Длина массива уволившихся в текущем месяце и прошедших выходное интервью */}
                      <td className="upu-table__table-data">{checkLength(interviewed)}</td>
                    </React.Fragment>
                  );
                })}

                {/* Общее количество  уволившихся */}
                <td className="upu-table__table-data">{checkLength(row.value)}</td>
                {/* Общее количество прошедших выходное интервью */}
                <td className="upu-table__table-data">{checkLength(interviewFilialTotal)}</td>
              </tr>
            );
          })}
        </tbody>
        <tfoot className="upu-table__table-foot">
          {/* Последняя строка - итого за месяц */}
          <tr className="upu-table__table-row">
            {/* Первая ячейка - Итого */}
            <td className="upu-table__table-data"><b>Итого</b></td>

            {months.map((month, index) => {
              // Находим уволившихся в текущем месяце
              const filterValues = rawReport.filter((value) => {
                const dismissalDate = new Date(value.dismissal_date).getMonth(); // месяц увольнения
                const fieldDate = new Date(month).getMonth(); // месяц в колонке
                return dismissalDate === fieldDate; // вернуть совпадения месяцев
              });
              // Прошли интервью (на дату во всех МВЗ)
              const interviewed = filterValues.filter((row) => row.interviewed);

              return (
                // Выводим ячейки
                <React.Fragment key={month + index}>
                  {/* Общее количество уволившихся в текущем месяце */}
                  <td className="upu-table__table-data">{filterValues.length}</td>
                  {/* Общее количество прошедших выходное интервью текущем месяце */}
                  <td className="upu-table__table-data">{interviewed.length}</td>
                </React.Fragment>
              );
            })}

            {/* Общее количество уволившихся */}
            <td className="upu-table__table-data">{rawReport.length}</td>
            {/* Общее количество прошедших выходное интервью */}
            <td className="upu-table__table-data">{interviewedTotal.length}</td>
          </tr>
        </tfoot>
      </table>
    </TableWrapper>
  );
}

export default ReportMvz;
