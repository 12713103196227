import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { createAction } from '../../../../utils/redux/store';
import Button from '../../../../components/UI/Button/Button';
import Input from '../../../../components/UI/Input';
import classes from '../../../../components/UI/Button/button.module.scss';

/**
* @component Сервис отчётов анкетирования - Базовый отчёт - Панель выбора - Выбор периода
*/
function Period() {
  const reportPeriod = useSelector((state) => state.reports.report_periоd); // настроки отчёта
  const [isActive, setIsActive] = useState(reportPeriod.period); // состояние выбора периода
  const [inputTypeStart, setInputTypeStart] = useState('text'); // состояние типа инпута
  const [inputTypeEnd, setInputTypeEnd] = useState('text'); //  состояние типа инпута

  // Изменить параметры дат
  function changeSetting(data) { // функция изменения объекта настроек
    createAction('SET_REPORT_PERIOD', { ...reportPeriod, ...data });
  }

  // Развернуть/свернуть
  function togglePeriod() {
    changeSetting({ period: !isActive }); // изменить настройки
    setIsActive(!isActive); // изменить состояние выбора
  }

  // Форматирование даты
  function formatDate(string_date) {
    // const date = new Date(string_date)
    // const result = `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
    return string_date;
  }

  return (
    <div className="reports__period" onClick={(e) => e.stopPropagation()}>
      <Button className={`${classes.button_ghost} ${classes.small} ${classes.full_width}`} onClick={togglePeriod}>{isActive ? 'убрать период' : 'указать период'}</Button>
      <div className={isActive ? 'reports__choose-period_active' : 'reports__choose-period'}>

        <Input
          type={inputTypeStart}
          onBlur={() => setInputTypeStart('text')}
          onFocus={() => setInputTypeStart('date')}
          onChange={(e) => changeSetting({ start_date: e.target.value })}
          placeholder={reportPeriod.start_date ? formatDate(reportPeriod.start_date) : 'От'}
        />

        <Input
          type={inputTypeEnd}
          onBlur={() => setInputTypeEnd('text')}
          onFocus={() => setInputTypeEnd('date')}
          onChange={(e) => changeSetting({ end_date: e.target.value })}
          placeholder={reportPeriod.end_date ? formatDate(reportPeriod.end_date) : 'До'}
        />
      </div>
    </div>
  );
}

export default Period;
