import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
// Компоненты
import NavPanel from '../NavPanel/NavPanel';
import NoticeNew from '../UI/NoticeNew';
import Notice from '../UI/Notice';
import Loader from '../UI/Loader';
// Функции
import { createAction } from '../../utils/redux/store';
import { deleteCookie } from '../../utils/functions/cookies';
import { catchHandler } from '../../utils/error_handling/error_handling';
import { clientSendData } from '../../utils/functions/requests';
import { setOperInfo } from '../../utils/functions/others';
// Стили
import './header.scss';

/**
 * @component Панель в верхней части окна, отображается на каждой странице приложения
*/
function Header() {
  const isAuth = useSelector((state) => state.auth.auth); // состояние аутентификации
  const isLogOut = useSelector((state) => state.auth.is_logout); // состояние выхода из системы
  const currentUser = useSelector((state) => state.users.current_user); // samaccountname текущего пользователя
  const [userAvatar, setUserAvatar] = useState(''); // aватар пользователя
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки

  useEffect(() => {
    getUserAvatar(currentUser); // получение
  }, [currentUser]);

  /**
   * @function Получение информации о текущем пользователе и установка аватара
   * @prop {name} string - samaccountname пользователя
  */
  async function getUserAvatar(name) {
    try {
      const reqData = {
        type: 'getUserData',
        fields: ['id', 'avatar'],
        name,
      };
      if (name) {
        const result = await clientSendData('POST', '/get_user_data', reqData);
        if (result) {
          setOperInfo({ user_id: result.id }); // Добавить id пользователя в оперативную информацию

          if (result.avatar?.data) {
            // Установка аватара из БД
            const reader = new FileReader(); // API чтения файлов
            const resBlob = new Blob([new Uint8Array(result.avatar.data)]);
            reader.onloadend = ((e) => setUserAvatar(e.target.result)); // Запись аватара в состояние
            reader.readAsDataURL(resBlob);
          }
        }
      }
    } catch (error) {
      catchHandler(error, 'getUserAvatar');
    }
  }

  // Функция состаривания сессии
  async function expireSession() {
    try {
      const confirm = window.confirm('Выйти из системы?');
      if (confirm) {
        const reqData = {
          type: 'expireSession',
        };
        setIsLoading(true);
        const result = await clientSendData('POST', '/expire_session', reqData);

        if (typeof result === 'number') {
          deleteCookie();
          createAction('SET_LOG_OUT', true);
        }
        setIsLoading(false);
      }
    } catch (error) {
      catchHandler(error, 'expireSession');
      setIsLoading(false);
    }
  }

  // Обработчик клика на бургер меню
  function burgerClick(e) {
    e.stopPropagation();
    createAction('TOGGLE_MENU', true);
  }

  return (
    <header className="header" onClick={() => createAction('TOGGLE_MENU', false)}>
      <img onClick={burgerClick} className="header__burger-button" src="../../icons/menu.svg" alt="burger_menu" title="Навигационная панель" />
      <div className="header__title">
<Link to="/" className="header__title-text">
<span className="header__title-text__blue">UNI</span>
<span className="header__title-text__green">PLACE</span>
</Link>
      </div>
      {!isLogOut && (
      <div className="header__buttons">

        {isAuth && (
        <Link to={`/user/${currentUser}`} state={{ source: 'user', user: currentUser }}>
          <img id="header__user" className="header__button" src={userAvatar || '../../icons/user_color.png'} alt="user" />
        </Link>
        )}
        <Link to="/" onClick={expireSession}>
          {isLoading
            ? <div className="header__button"><Loader /></div>
            : <img className="header__button__icon" src="../../icons/log-out.svg" alt="Выйти" title="Выйти" />}
        </Link>
      </div>
      )}
      <NavPanel />
      {/* <Notice /> */}
      <NoticeNew />
    </header>
  );
}

export default Header;
