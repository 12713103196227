import React from 'react';
import Service from '../../components/Service/Service';
import MobileTable from './MobileTable';

function MobileConnection() {
  const mobilePage = [
    { id: 1, title: 'Мобильная связь', component: <MobileTable /> },
  ];
  return (
    <Service id="mobile-connection" pages={mobilePage} horizonatal />
  );
}

export default MobileConnection;
