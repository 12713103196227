import React from 'react';
import { useSelector } from 'react-redux';
import { commentActivity, leaveComment } from '../functions';
import TextArea from '../../../../components/UI/TextArea';
import FinishButton from '../FinishButton';
import Answers from '../Answers';
import Service from '../../../../components/Service/Service';
import ServiceBody from '../../../../components/Service/ServiceBody';

// Одностраничное отображение
function SinglePageOutput() {
  const userAnswers = useSelector((state) => state.interview.user_answers); // ответы пользователя
  const interview = useSelector((state) => state.interview.interview); // все данные анкеты
  const isWiki = useSelector((state) => state.interview.is_wiki); // параметр "справочник"
  const {
    questionnaire, // информация об анкете
    answers, // ответы
    questions, // вопросы
  } = interview;

  return (
    <Service id="interview-single-page">
      <ServiceBody>
        {/* Заголовок анкеты */}
        <h2 id="interview__title" className="upu-service__title">{questionnaire.title}</h2>

        <div id="interview-single-page__wrapper" className="interview__wrapper">
          {questions.map((q) => (
            <div key={q.id} id="interview-single-page__question-answer" className="interview__question-answer">
              {/* <div
                className="interview__question-content"
                dangerouslySetInnerHTML={{ __html: q.question }}
              /> */}
              {/* Содержание вопроса */}
              <div className="interview__question-content">
                {q?.question.split('\n') // разделить строку по переносам
                  .map((row, index) => ( // показать каждую в параграфе
                  <p key={index} className="interview__question-row">{row}</p>))}
              </div>
              {/* Ответы */}
              <Answers answers={answers} question={q} />

              {/* Поле комментария */}
              {/* если режим справочника выкл, комментарии включены или на текущий вопрос можно ответить только комментарием */}
              {!isWiki && (questionnaire?.comments || q.output_id === 4) && (
              <div className="interview__comment-block">
                <TextArea // показывается поле для ввода комментария
                  id={`interview__textarea_${q.id}`}
                  placeholder="Комментарий"
                  disabled={commentActivity(userAnswers, q)}
                  onChange={(e) => leaveComment(e, userAnswers, q, questionnaire)}
                />
              </div>)}
            </div>
          ))}
          {/* Завершить опрос */}
          <FinishButton />
        </div>
      </ServiceBody>
    </Service>
  );
}

export default SinglePageOutput;
