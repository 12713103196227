import React, { useState } from 'react';
import { setOperInfo } from '../../../../utils/functions/others';
import CardHeader from '../../../../components/Card/CardHeader';
import TabPanel from '../../../../components/UI/TabPanel';
import History from './History';
import Main from './Main';
import Card from '../../../../components/Card/Card';

/**
 * @component Бронирование - Интерфейс администратора - карточка места
 * @prop {setShowCard} function - обновление состояния отображения карточки (открыть/закрыть)
 * @prop {placeId} number - id рабочего места
 */
function WorkplaceCard(props) {
  const { setShowCard, placeId } = props;

  const tabs = ['Главная', 'История']; // вкладки
  const [choice, setChoice] = useState(); // состояние выбора вкладки
  const content = {
    Главная: <Main closeHandler={closeHandler} placeId={placeId} />,
    История: <History placeId={placeId} />,
  };

  // Закрыть карточку
  function closeHandler() {
    setOperInfo({ subject_id: null }); // сброс id категории в оперативной информацию
    setShowCard(false); // закрытие карточки
  }

  return (
    <Card id="workplaces__booking-card" setShow={closeHandler}>
      <CardHeader>
        <TabPanel tabs={tabs} choice={choice} choiceHandler={setChoice} />
      </CardHeader>
      {content[choice]}
    </Card>
  );
}

export default WorkplaceCard;
