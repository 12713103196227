import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import Button from '../../../../components/UI/Button/Button';
import { catchHandler } from '../../../../utils/error_handling/error_handling';
import { alertFunction } from '../../../../utils/functions/alertFunction';
import { clientSendData } from '../../../../utils/functions/requests';
import { getCategories, getQuestions } from '../../functions';
import Answers from './Answers';
import Settings from './Settings';
import TabPanel from '../../../../components/UI/TabPanel';
import Logs from './Logs';
import { setOperInfo, log } from '../../../../utils/functions/others';
import Card from '../../../../components/Card/Card';
import CardHeader from '../../../../components/Card/CardHeader';
import CardBody from '../../../../components/Card/CardBody';
import CardFooter from '../../../../components/Card/CardFooter';

// Сервис анкетирования - вкладка "Вопросы" - карточка вопроса
function QuestionCard(props) {
  const { setShowCard, id } = props;

  const location = useLocation(); // Адрес
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [choice, setChoice] = useState(''); // состояние выбора вкладки
  const [currentQuestion, setCurrentQuestion] = useState(null); // текущий вопрос, отображаемый в карточке
  const currentDirection = useSelector((state) => state.questionnaire.current_direction); // текущее направление
  const [currentAnswers, setCurentAnswers] = useState([]); // ответы к текущему вопросу
  const [answersOutputs, setAnswersOutputs] = useState([]); // типы вывода ответов
  const isAllDirections = currentDirection.id === 'all'; // Выбраны все направления?
  const isNewQuestion = id === 'new'; // это вновь создаваесый вопрос?
  const tabs = ['Настройки', 'Ответы', !isNewQuestion && 'История']; // вкладки

  useEffect(() => {
    awaitRequests();
  }, []);

  // Ожидание выполнения необходимых запросов
  async function awaitRequests() {
    try {
      setOperInfo({ subject_id: id }); // запись id вопроса в оперативную информацию
      setIsLoading(true); // состояние - загружается
      await getCategories(location.pathname); // получение категорий
      await getQuestion(); // получение данных для вопроса
      if (id === 'new') { // если анкета создаётся
        setCurrentQuestion({
          id: 'new',
          // если выбрано конкретное направление, подставляются его данные,
          // иначе данные первого, доступного пользователю направления
          group: !isAllDirections ? currentDirection.title : null, // наименование текущего направления
          group_id: !isAllDirections ? currentDirection.id : null, // id текущего направления
        }); // новый объект вопроса в хранилище
      }
      setIsLoading(false); // загрузка завершена
    } catch (error) {
      catchHandler(error, 'awaitRequests');
    }
  }

  // Поулчить информацию о вопросе
  async function getQuestion() {
    try {
      const reqData = {
        type: 'getQuestion',
        question_id: id, // id вопроса
      };

      const result = await clientSendData('POST', '/get_question', reqData); // запрос в БД
      if (result) {
        setAnswersOutputs(result.answers_outputs); // запись типов вывода вопросов
        if (id !== 'new') { // вопрос уже существует, записать его данные в состояние
          setCurrentQuestion(result.question); // запись информации о вопросе
          setCurentAnswers(result.answers); // новый массив ответов в хранилище
        }
      }
    } catch (error) {
      catchHandler(error, 'getQuestion');
    }
  }

  // Отменить редактирование или создание и закрыть карточку
  function cancelAndClose() {
    setShowCard(false);
    log({ message: `Пользователь закрыл карточку вопроса id: ${currentQuestion.id}`, eventtype: 'info' });
    setOperInfo({ subject_id: null }); // сброс id вопроса в оперативной информации
  }

  // Функция обновления порядковых номеров ответов
  function refreshNumbers(answers) {
    // прохождение по каждому элементу массива и обновление порядкового номера как индекс + 1
    return answers.map((a, index) => ({ ...a, number: index + 1 }));
  }

  // Сохранить изменения
  async function saveQuestionChanges() {
    try {
      const reqData = {
        type: 'editQuestion',
        question_info: currentQuestion, // измененная информация о вопросе
        answers: currentAnswers, // измененная информация об ответах
      };
      const {
        question, group_id, category_id, output_id,
      } = currentQuestion;
      // Если текст вопроса пустой или не выбрана группа - уведомление
      if (!question || !group_id || !category_id || !output_id) alertFunction('required_fields', 'clientPost');
      else {
        setIsLoading(true); // состояние - загружается
        const result = await clientSendData('POST', '/edit_question', reqData);

        if (result === 'success') { // если успех
          alertFunction('save_settings', 'clientPost'); // уведомление - настройки сохранены
          await getQuestion();
          getQuestions(location.pathname, currentDirection); // ждем обновления информации и вопросах
          if (isNewQuestion) setShowCard(false); // если вопрос создаётся - закрыть карточку
          log({ message: `Пользователь сохранил карточку вопроса id: ${currentQuestion.id}`, eventtype: 'info' });
        }
        setIsLoading(false); // загрузка завершена
      }
    } catch (error) {
      catchHandler(error, 'saveQuestionChanges');
      setIsLoading(false);
    }
  }

  // Обработчик выбора вкладки
  function choiceHandler(tab) {
    setChoice(tab); // если редактор закрыт - то просто переключается вкладка
    log({ message: `Пользователь в карточке вопроса id: ${currentQuestion?.id} выбрал вкладку ${tab}`, eventtype: 'info' });
  }

  return (
    <Card id="questionnaire__questions-card" setShow={cancelAndClose} loading={isLoading}>
      <CardHeader>
          <TabPanel tabs={tabs} choice={choice} choiceHandler={choiceHandler} />
      </CardHeader>

      <CardBody>
        {choice === 'Настройки' && (
        <Settings // Вкладка настроек
          setShowCard={setShowCard}
          currentQuestion={currentQuestion}
          setCurrentQuestion={setCurrentQuestion}
          answersOutputs={answersOutputs}
        />)}

        {choice === 'Ответы' && (
        <Answers // Вкладка ответов
          getQuestion={getQuestion}
          currentQuestion={currentQuestion}
          refreshNumbers={refreshNumbers}
          currentAnswers={currentAnswers}
          setCurentAnswers={setCurentAnswers}
        />)}

        {choice === 'История' && (
        <Logs // Вкладка истории
          id={currentQuestion.id}
        />)}
      </CardBody>

      <CardFooter>
        <Button onClick={cancelAndClose}>Отменить</Button>
        <Button onClick={saveQuestionChanges}>Сохранить</Button>
      </CardFooter>
    </Card>
  );
}

export default QuestionCard;
