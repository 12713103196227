import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createAction } from '../../../../../utils/redux/store';

// Страница интервью - блок выбора вопросов - категории
function Categories() {
  const interview = useSelector((state) => state.interview.interview);
  const { questions, questionnaire, categories } = interview;
  const mainCategories = categories.filter((c) => !c.parent_id && c.direction_id === questionnaire.group_id);
  const [activeCategories, setActiveCategories] = useState(categories.map((c) => c.id));
  const currentCategory = useSelector((state) => state.interview.current_category);

  useEffect(() => {
    createAction('SET_DISPLAYED_QUESTIONS_INTERVIEW', questions); // установить вопросы
  }, []);

  // Фильтрация вопросов на основании выбранной категории
  function filterQuestions(category) {
    if (!category) {
      createAction('SET_DISPLAYED_QUESTIONS_INTERVIEW', questions); // установить вопросы
    } else {
      const filtered = questions.filter((q) => q.category_id === category.id || q.category_parent_id === category.id);
      createAction('SET_DISPLAYED_QUESTIONS_INTERVIEW', filtered); // установить вопросы
    }
  }
  // Открыть/закрыть категорию
  function toggleCategory(e, category) {
    e.stopPropagation();
    // Поиск выбранной категории среди активных
    const findId = activeCategories.find((ac) => ac === category.id);
    if (findId) {
      // если найдена - удаляется
      setActiveCategories(activeCategories.filter((ac) => ac !== category.id));
    } else {
      setActiveCategories([...activeCategories, category.id]); // если нет - добавляется
    }
  }

  function chooseCategory(category) {
    filterQuestions(category);
    createAction('SET_CURRENT_CATEGORY_INTERVIEW', category);
    createAction('SET_IS_SHOW_QUESTION', false);
  }

  // Категория выбрана?
  function isCurrent(id) {
    if (currentCategory && currentCategory.id === id) return true;
    return false;
  }

  // Категория активна?
  function isActive(id) {
    // Поиск выбранной категории среди активных
    const findId = activeCategories.find((ac) => ac === id);
    if (findId) return true; // если есть - возвращает true
    return false; // иначе false
  }

  // Функция отображения иерархии категорий
  function showCategoriesHierarchy(category) {
    return (
        <div className={isActive(category.id) ? 'interview-categories__category-block_active' : 'interview-categories__category-block'}>
            {/* Подкатегории */}
            {categories.map((c) => {
              const findSubCategories = categories.find((cat) => cat.parent_id === c.id); // Поиск подкатегорий
              if (c.parent_id === category.id) {
                return (
                    <div key={c.id} className="interview-categories__category">
                        <div
                          className={isCurrent(c.id) ? 'interview-categories__category-title interview-categories__category-title_active' : 'interview-categories__category-title'}
                          onClick={() => chooseCategory(c)}
                        >
                            {findSubCategories && (
                            <img
                              className={isActive(c.id) ? 'interview-categories__category-chevron_active' : 'interview-categories__category-chevron'}
                              onClick={(e) => toggleCategory(e, c)}
                              src="../../../../icons/chevron.svg"
                              alt="chevron"
                            />)}
                            {c.title}
                        </div>
                        {showCategoriesHierarchy(c)}
                    </div>
                );
              } return null;
            })}
        </div>
    );
  }

  return (
    <div className="interview-categories">
        {mainCategories.map((category) => {
          const findSubCategories = categories.find((cat) => cat.parent_id === category.id); // Поиск подкатегорий
          return (
            <div key={category.id} className="interview-categories__category">
                <div
                  className={isCurrent(category.id) ? 'interview-categories__category-title interview-categories__category-title_active' : 'interview-categories__category-title'}
                  onClick={() => chooseCategory(category)}
                >
                {findSubCategories && (
                <img
                  className={isActive(category.id) ? 'interview-categories__category-chevron_active' : 'interview-categories__category-chevron'}
                  onClick={(e) => toggleCategory(e, category)}
                  src="../../../../icons/chevron.svg"
                  alt="chevron"
                />)}
                    {category.title}
                </div>
                {showCategoriesHierarchy(category)}
            </div>
          );
        })}
    </div>
  );
}

export default Categories;
