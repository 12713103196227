import React from 'react';

function MenuLine({
  object, onItemClick, activeItem, setActiveItem, openedItems = [], setOpenedItems,
}) {
  const { id, title, children } = object;
  const isOpened = openedItems.includes(id);
  const isActive = activeItem === id;

  const toggleItem = (event) => {
    event.stopPropagation();
    setOpenedItems(isOpened ? openedItems.filter((item) => item !== id) : [...openedItems, id]);
  };

  const handleClick = (event) => {
    event.stopPropagation();
    onItemClick(id);
    setActiveItem(id);
  };

  return (
    <div className="hierarchy__line">
      <div
        className={`hierarchy__title ${isActive ? 'hierarchy__title_active' : ''} ${isOpened ? 'hierarchy__title_opened' : ''}`}
        onClick={handleClick}
      >
        {children && (
          <span
            className={`hierarchy__expand ${isOpened ? 'hierarchy__expand_active' : ''}`}
            onClick={toggleItem}
          />
        )}
        <span className="hierarchy__title-content">
          {title}
        </span>
      </div>
      {children && isOpened && (
        <div className="hierarchy__children hierarchy__children_active">
          {children.map((child) => (
            <MenuLine
              key={child.id}
              object={child}
              onItemClick={onItemClick}
              activeItem={activeItem}
              setActiveItem={setActiveItem}
              openedItems={openedItems}
              setOpenedItems={setOpenedItems}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default MenuLine;
