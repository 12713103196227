import React, { useEffect, useState } from 'react';
import Table from '../../components/Table/Table';
import THead from '../../components/Table/THead';
import TBody from '../../components/Table/TBody';
import TRow from '../../components/Table/TRow';
import { clientSendData } from '../../utils/functions/requests';
import { catchHandler } from '../../utils/error_handling/error_handling';
import { headers } from './common';
import TData from '../../components/Table/TData';
import ServiceBody from '../../components/Service/ServiceBody';
import Select from '../../components/UI/Select';
import { alertFunction } from '../../utils/functions/alertFunction';

function UserGroups() {
  const [userGroups, setUserGroups] = useState([]);
  const [users, setUsers] = useState(false);
  const [selUsers, setSelUsers] = useState({});
  const [changeData, setChangeData] = useState({});

  useEffect(() => {
    getUserGroups();
  }, []);

  async function getUserGroups() {
    try {
      const reqData = {
        type: 'getUserGroups',
      };
      const result = await clientSendData('POST', '/get_user_groups', reqData); // запрос в БД
      if (result) {
        setUserGroups(result.data); // запись в переменную
        setUsers(result.users);
      }
    } catch (error) {
      catchHandler(error, 'getUserGroups'); // обработчик ошибок
    }
  }

  function selectUser(user, groupId) {
    const selUser = {};
    selUser[groupId] = user.title;
    setSelUsers((state) => ({ ...state, ...selUser }));
    const dataObj = {
      action: 'add',
      user: user.id,
      group: groupId,
    };
    setChangeData(dataObj);
  }

  async function addUserGroup() {
    if (changeData.user && changeData.group) {
      await editUserGroups(changeData);
      await getUserGroups();
      setSelUsers({});
    } else {
      alertFunction('sd_user_group_no_select', 'client');
    }
  }

  async function editUserGroups(obj) {
    try {
      if (userGroups.filter((group) => group.id === obj.group)[0].users.filter((el) => el.user_id === obj.user).length > 0 && obj.action === 'add') {
        alertFunction('sd_user_group_exist', 'client');
      } else {
        const reqData = {
          type: 'editUserGroups',
          ...obj,
        };

        const result = await clientSendData('POST', '/edit_user_groups', reqData); // запрос в БД
        if (result?.success) { // если запрос выполнен успешно
          await alertFunction('save_settings', 'client');
        } else {
          alertFunction('bad_request', 'client');
        }
        setChangeData({});
      }
    } catch (error) { // если ошибка
      catchHandler(error, 'editUserGroups'); // обрабатываем ошибку
    }
  }
  async function deleteUserGroup(userId, groupId) {
    const dataObj = {
      action: 'delete',
      user: userId,
      group: groupId,
    };
    await editUserGroups(dataObj);
    await getUserGroups();
  }

  return (
    <ServiceBody>
      <Table id="user-in-groups">
            <THead
              title="Пользователи в группах"
              headers={headers.userGroups}
            />
            <TBody>
              {userGroups.map((d) => (
                <TRow key={`${d.id}row`}>
                      <TData>{d.title}</TData>
                      <TData key={d.id}>
                          <div className="tags__input-block">
                              <Select
                                id={d.id}
                                array={users}
                                className="tags__input"
                                onChoose={(user) => selectUser(user, d.id)}
                                defaultValue={selUsers[d.id]}
                                search
                              />
                              <div onClick={addUserGroup} className="tags__button">
                                  <img className="tags__img" src="../../icons/cancel.svg" alt="add-tag" />
                              </div>
                          </div>
                          <div className="tags__list">
                              {d.users.map((el) => <p className="tags__tag" key={el.user_id}>
                                                      <span onClick={() => deleteUserGroup(el.user_id, d.id)} className="upu__close">X</span>
                                                      {el.concat}
                                                   </p>)}
                          </div>
                      </TData>
                </TRow>
              ))}

            </TBody>
      </Table>
    </ServiceBody>);
}

export default UserGroups;
