// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#dismissal-log {
  font-size: 0.9rem;
  overflow-y: auto;
}
#dismissal-log tr {
  grid-template-columns: 1fr 1fr 1fr 1.5fr 4fr;
}
#dismissal-log thead tr:first-child, #dismissal-log tfoot tr:first-child {
  grid-template-columns: repeat(auto-fit, minmax(20px, 1fr));
}`, "",{"version":3,"sources":["webpack://./src/pages/ProbationControl/ProbationOutputInterview/Dismissal/dismissal.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,gBAAA;AACF;AACE;EACE,4CAAA;AACJ;AAGI;EACE,0DAAA;AADN","sourcesContent":["#dismissal-log {\n  font-size: 0.9rem;\n  overflow-y: auto;\n\n  & tr {\n    grid-template-columns: 1fr 1fr 1fr 1.5fr 4fr;\n  }\n  & thead,\n  & tfoot {\n    & tr:first-child {\n      grid-template-columns: repeat(auto-fit, minmax(20px, 1fr));\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
