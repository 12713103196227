import React, { useEffect, useRef, useState } from 'react';
import { sanitizeHtml } from '../../pages/Questionnaire/functions';
import EditorButton from '../UI/Button/EditorButton';
import Select from '../UI/Select';
import './textProcessor.scss';

/**
* @component Редактор текста на основе атрибута "contentEditable"
@prop {handler} function (Обязательный) Обработчик сохранения изменений
@prop {cancel} function (Обязательный) Обработчик закрытия редактора
@prop {deleting} function Обработчик удаления редактируемого элемента (ответ на вопрос в анкетировании)
@prop {content} string Контент для редактирования
*/

function TextProcessor(props) {
  const {
    handler, content, cancel, deleting = false,
  } = props;

  const ref = useRef(null); // Ссылка на поле ввода как DOM элемент
  const [fontSize, setFontSize] = useState('pt'); // размер шрифта в селекте

  useEffect(() => {
    setInnerHtml();
  }, []);

  // Элемент contentEditable, у которого изменяется размер шрифта, оборачивается в тег <font>
  // <font> имеет атрибут size, принимающий значения от 1 до 7
  // Размеры в title указаны в соответствии значения с размером шрифта в MS Word
  const fontSizes = [
    { id: 1, title: '7,5', size: '1' },
    { id: 2, title: '10', size: '2' },
    { id: 3, title: '12', size: '3' },
    { id: 4, title: '13,5', size: '4' },
    { id: 5, title: '18', size: '5' },
    { id: 6, title: '24', size: '6' },
    { id: 7, title: '36', size: '7' },
  ];

  function setInnerHtml() {
    // обновить html contentEditable-элемента
    ref.current.innerHTML = content;
  }

  // обработчик команд
  function command(commandText, data) {
    // функция устарела но поддерживается основными современными браузерами (chrome, safari, firefox, ie)
    document.execCommand(commandText, false, data || '');
  }

  // Обработчик выделения текста
  function highlightning(color) {
    command('styleWithCSS', true);
    command('hiliteColor', color);
    command('styleWithCSS', false);
  }

  // Изменить размер шрифта
  function chooseFontSize(object) {
    const { title, size } = object;
    setFontSize(title);
    command('fontSize', size);
  }

  return (
    <div className="text-processor">
      <div className="text-processor__panel">
        <div className="text-processor__buttons">
          <div className="text-processor__buttons-row">
            <EditorButton icon="justify-left" onClick={() => command('justifyLeft')} />
            <EditorButton icon="justify-center" onClick={() => command('justifyCenter')} />
            <EditorButton icon="justify-right" onClick={() => command('justifyRight')} />
            <EditorButton icon="justify-full" onClick={() => command('justifyFull')} />
          </div>

          <div className="text-processor__buttons-row">
            <EditorButton icon="italic" onClick={() => command('italic')} />
            <EditorButton icon="bold" onClick={() => command('bold')} />
          </div>

          <div className="text-processor__buttons-row">
            <div className="text-processor__color-block" onClick={() => highlightning('red')}>
                <div className="text-processor__color-block_red" />
            </div>
            <div className="text-processor__color-block" onClick={() => highlightning('yellow')}>
                <div className="text-processor__color-block_yellow" />
            </div>
            <div className="text-processor__color-block" onClick={() => highlightning('#28B47D')}>
                <div className="text-processor__color-block_green" />
            </div>
            <div className="text-processor__color-block" onClick={() => highlightning('initial')}>
                <div className="text-processor__color-block_initial" />
            </div>
          </div>

          <div className="text-processor__buttons-row">
            <EditorButton icon="undo" onClick={() => command('undo')} />
            <EditorButton icon="redo" onClick={() => command('redo')} />
          </div>

          <Select
            id="text-processor__font-size"
            array={fontSizes}
            onChoose={chooseFontSize}
            defaultValue={fontSize}
          />

        </div>

        <div className="text-processor__buttons">
          <div className="text-processor__buttons-row">
            {deleting && <EditorButton icon="delete" onClick={deleting} />}
          </div>
          <div className="text-processor__buttons-row">
            <EditorButton icon="check" onClick={() => handler(sanitizeHtml(ref.current.innerHTML))} />
          </div>
          <div className="text-processor__buttons-row">
            <EditorButton icon="cancel" onClick={cancel} />
          </div>
        </div>
      </div>

      <div
        ref={ref}
        contentEditable
        className="text-processor__contenteditable"
        suppressContentEditableWarning
      />
    </div>
  );
}

export default TextProcessor;
