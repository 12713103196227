import { catchHandler } from '../error_handling/error_handling';
import { alertFunction } from './alertFunction';

/**
 * @function Очистка HTML строки (оставить только текст)
 * @prop {string} string - (Обязательный) HTML строка
*/
export function clearHtmlString(string) {
  try {
    let result = '';
    if (string) {
      /** Заменить на пробелы
        - <[^>]*> - все html теги,
        - &quot; - все кавычки,
        - &nbsp; - все неразрывные пробелы
        - \n - все переносы строк
      */
      result = string.replace(/<[^>]*>|&quot;|&nbsp;|\n/gm, ' ');
      result = result.replaceAll(/\s+/g, ' '); // заменить подряд идущие пробелы на один
      result = result.trim(); // удалить пробелы по краям
    }
    return result;
  } catch (error) {
    catchHandler(error, 'clearHtmlString');
    return 'error';
  }
}

/**
 * @function Перевод строки в CamelCase
 * @prop {string} string - (Обязательный) строка
 * @prop {separator} string - (Обязательный) разделитель строки на слова
*/
export function stringToCamelCase(string, separator) {
  try {
    const stringArray = string.split(separator);
    const camelCaseString = stringArray.map((word) => {
      const wordArray = Array.from(word);
      const first = wordArray[0].toUpperCase();
      wordArray.shift();
      const result = [first, ...wordArray].join('');
      return result;
    });
    return camelCaseString.join('');
  } catch (error) {
    catchHandler(error, 'stringToCamelCase');
    return 'error';
  }
}

/**
 * @function Валидация samaccountname через регулярку
 * @prop {login} string - (Обязательный) samaccountname
*/
export function loginValidate(login) {
  try {
    if (/^[a-zA-Z1-9_]+$/.test(login) === false) { // Если в строке присутствуют любые символы кроме цифр, латиницы и _
      alertFunction('incorrect_encoding'); // Уведомление "Логин должен содержать только латинские символы"
      return false;
    }
    if (login.length < 5) { // Если длина строки меньше 5
      alertFunction('incorrect_length'); // Уведомление "Минимальная длина логина 5 символов"
      return false;
    }
    if (!Number.isNaN(+login[0])) { // Если первый символ - цифра
      alertFunction('incorrect_first_letter'); // Уведомление "Логин должен начинаться с буквы"
      return false;
    }
    return true;
  } catch (error) {
    catchHandler(error, 'loginValidate');
    return false;
  }
}

/**
 * @function Валидация email через регулярку
 * @prop {email} string - (Обязательный) email
*/
export function emailValidate(email) {
  try {
    const regExp = /^[a-zA-Z0-9._-]+@[unicon]+\.[ru]/;
    if (!regExp.test(email)) {
      alertFunction('incorrect_email');
      return false;
    } return true;
  } catch (error) {
    catchHandler(error, 'emailValidate');
    return false;
  }
}

/**
 * @function Поиск в строке
 * @prop {what} string - (Обязательный) строка, которую нужно найти
 * @prop {where} string - (Обязательный) строка, в которой выполняется поиск
*/
export function stringMatch(what, where, type) {
  if (!what || !where) return null;
  const whereToFind = where.toLowerCase();
  const whatToFind = what.trim().toLowerCase();
  if (type === 'all') return Array.from(whereToFind.matchAll(whatToFind));
  return whereToFind.match(whatToFind);
}
