import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createPortal } from 'react-dom';
import { catchHandler } from '../../../utils/error_handling/error_handling';
import { alertFunction } from '../../../utils/functions/alertFunction';
import { clientSendData } from '../../../utils/functions/requests';
import { getActiveScheme } from '../../KnowledgeBase/Common/functions';
import { compareObjectsValues } from '../../../utils/functions/others';
import { emailValidate } from '../../../utils/functions/stringHandling';
import Card from '../../../components/Card/Card';
import CardHeader from '../../../components/Card/CardHeader';
import CardSetting from '../../../components/Card/CardSetting';
import CardFooter from '../../../components/Card/CardFooter';
import Button from '../../../components/UI/Button/Button';
import Input from '../../../components/UI/Input';
import Loader from '../../../components/UI/Loader';
import CheckBox from '../../../components/UI/CheckBox';
import Hierarchy from '../../KnowledgeBase/Common/ChooseForm/Hierarchy';
import classes from '../../../components/UI/Button/button.module.scss';

/**
 * @component Администрирование базы знаний - редактирование подразделений - карточка подразделения - вкладка Настройки
 * @prop {setShowCard} function - обновление состояния отображения карточки (открыть/закрыть)
 * @prop {getDivisions} function - получение подразделений для обновления таблицы
 * @prop {openedDivision} object - объект текущего направления открытого в карточке
 * @prop {setOpenedDivision} function -обновление состояния открытого подразделения
*/
function Settings(props) {
  const {
    getDivisions, setShowCard, openedDivision, setOpenedDivision,
  } = props;

  const questionScheme = useSelector((state) => state.kb.active_scheme.question?.scheme) || {}; // схема вопроса
  const [isLoading, setIsLoading] = useState(false); // Состояние загрузки
  const [showChooseForm, setShowChooseForm] = useState(false);
  const [settings, setSettings] = useState(openedDivision); // имя набора вопросов
  const isEdited = !compareObjectsValues(openedDivision, settings); // показатель изменения исходного объекта
  const themesKey = Object.keys(questionScheme)?.find((field) => questionScheme?.[field]?.link_kb_themes); // ключ для связи схем вопросов и тем
  const divisionsTableNode = document.getElementById('divisions'); // DOM элемент с таблицей подразделений
  const initialObject = { [themesKey]: openedDivision?.exclude_themes }; // исходный объект для сброса выбор иерархии тем

  useEffect(() => {
    awaitRequests();
  }, []);

  async function awaitRequests() {
    setIsLoading(true);
    await getActiveScheme(openedDivision.id); // запросить активные схемы подразделения
    setIsLoading(false);
  }

  // Редактировать подразделение
  async function editDivision() {
    try {
      const reqData = {
        type: 'editDivision',
        settings, // настройки
      };

      if (validateDivision()) { // если валидация пройдена
        setIsLoading(true); // загружается
        const result = await clientSendData('POST', '/edit_division', reqData); // запрос в БД

        if (result === 'bad_request') { // если некорректный запрос
          alertFunction('bad_request', 'clientPost'); // уведомление
          setIsLoading(false); // загрузка окончена
        } else if (result === 'success') { // если успех
          await getDivisions();
          setOpenedDivision(settings); // обновить состояние текущего подразделения
          setIsLoading(false); // загрузка окончена
          setShowCard(false);
        }
      } else return;
    } catch (error) {
      catchHandler(error, 'editDivision'); // обработчик ошибок
      setIsLoading(false); // состояние - не загружается
    }
  }

  // Удалить подразделение
  async function deleteDivision() {
    try {
      let confirm = window.confirm('Действие повлечёт за собой каскадное удаление тем и вопросов. Продолжить?');

      if (confirm) {
        confirm = window.confirm('Вы уверены?');

        if (confirm) {
          const reqData = {
            type: 'deleteDivision',
            id: openedDivision.id,
          };

          setIsLoading(true); // загружается
          const result = await clientSendData('POST', '/delete_division', reqData); // запрос в БД

          if (result === 'bad_request') { // если некорректный запрос
            alertFunction('bad_request', 'clientPost'); // уведомление
            setIsLoading(false); // загрузка окончена
          } else if (result === 'success') { // если успех
            await getDivisions();
            setShowCard(false); // закрыть карточку
            setOpenedDivision({}); // сбросить объект открытого подразделения в хранилище
            setIsLoading(false); // загрузка окончена
          }
        } else return;
      } else return;
    } catch (error) {
      catchHandler(error, 'deleteDivision'); // обработчик ошибок
      setIsLoading(false); // состояние - не загружается
    }
  }

  // Валидация данных перед отправкой
  const validateDivision = () => {
    if (!settings.name) {
      alertFunction('enter_name', 'clientPost');
      return false;
    } if (settings.notify && !emailValidate(settings.mail_notify)) { // почта для уведомлений
      alertFunction('incorrect_email', 'clientPost');
      return false;
    } if (!Number.isInteger(+settings.limit_view) || settings.limit_view < 0) {
      alertFunction('verify_limit_view_number', 'clientPost');
      return false;
    } return true;
  };

  // Отменить изменения
  function cancelEditing() {
    setSettings(openedDivision);// сброс имён
  }

  // Изменить имя поля
  function changeSetting(field, value) {
    setSettings({ ...settings, [field]: value });
  }

  // Проверить на наличие значение
  const checkValue = (value) => value || '';

  if (isLoading) return <Loader />;
  return (
    <div className="upu-card__block">
      {/* Заголовок подразделения */}
      <CardSetting title="Заголовок" invalid={!settings.name}>
        <Input
          placeholder="Введите имя"
          value={checkValue(settings.name)}
          onChange={(e) => changeSetting('name', e.target.value)}
        />
      </CardSetting>

      {/* Название совокупности вопросов */}
      <CardSetting
        title="Ограничение просмотра записей на страницах"
        invalid={!Number.isInteger(+settings.limit_view) || settings.limit_view < 0}
      >
        <Input
          placeholder="Кол-во вопросов на странице"
          type="number"
          value={checkValue(settings.limit_view)}
          onChange={(e) => changeSetting('limit_view', e.target.value)}
        />
      </CardSetting>

      {/* Уведомление об изменениях */}
      <CardSetting title="Уведомление об изменениях" invalid={settings.notify && !settings.mail_notify}>
          <div className="upu-card__setting-wrapper">
              <CheckBox
                checked={settings.notify}
                onChange={(e) => changeSetting('notify', e.target.checked)}
              />
              <Input
                placeholder="Адрес уведомления"
                value={checkValue(settings.mail_notify)}
                disabled={!settings.notify}
                onChange={(e) => changeSetting('mail_notify', e.target.value)}
              />
          </div>
      </CardSetting>

      {/* Уведомление об изменениях */}
      <CardSetting title="Частота уведомления (в секундах)">
        <Input
          placeholder="Частота уведомления (секунды)"
          value={checkValue(settings.period_notify)}
          disabled={!settings.notify}
          onChange={(e) => changeSetting('period_notify', e.target.value)}
        />
      </CardSetting>

      {/* Название совокупности вопросов */}
      {settings.last_notify && (
      <CardSetting title="Дата и время последнего уведомления">
        {settings.last_notify}
      </CardSetting>)}

      {/* Удаление подразделения */}
      <CardSetting title="Удалить">
        <Button className={classes.button_ghost} onClick={deleteDivision}>Удалить</Button>
      </CardSetting>

      {/* Кнопка открытия окна выбора тем только если
          в схеме вопроса присутствует ключ c link_kb_themes
          и в этом поле есть switch данные */}
      {questionScheme[themesKey]?.switch && (
      <CardSetting title="Скрыть темы">
        <Button className={classes.button_ghost} onClick={() => setShowChooseForm(true)}>Выбрать</Button>
      </CardSetting>)}

      {showChooseForm && (// когда открыта форма
        createPortal( // создать портал
        <Card id="division__excludes" setShow={setShowChooseForm}>
            <CardHeader />
            <Hierarchy // показать иерархию
              scheme={questionScheme}
              field={themesKey}
              value={settings?.exclude_themes}
              initialObject={initialObject}
              handler={(item) => changeSetting('exclude_themes', item[themesKey])}
              divisionId={openedDivision.id}
            />
        </Card>,
        divisionsTableNode, // в DOM элементе с таблицей подразделений
        ))}

      {/* Кнопки управления изменения */}
      {isEdited && (
      <CardFooter>
        <Button onClick={cancelEditing}>Отменить</Button>
        <Button onClick={editDivision}>Сохранить</Button>
      </CardFooter>)}
    </div>
  );
}

export default Settings;
