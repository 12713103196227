import React from 'react';
import Card from '../../../components/Card/Card';
import CardBody from '../../../components/Card/CardBody';
import CardFooter from '../../../components/Card/CardFooter';
import CardHeader from '../../../components/Card/CardHeader';
import ObjectDisplay from '../../../components/Card/ObjectDisplay';
import Button from '../../../components/UI/Button/Button';

/**
 * @component Карточка записи лога
 * @prop {object} object - объект записи (данные строки из таблицы в БД)
 * @prop {setShow} function - функция обновления состояния отображения карточки (открыть/закрыть)
*/
function EntryCard(props) {
  const { object, setShow } = props;

  return (
    <Card id="logs__entry-card" setShow={setShow}>
      <CardHeader />
      <CardBody>
          <ObjectDisplay object={object} />
      </CardBody>
      <CardFooter>
          <Button onClick={() => setShow(false)}>Закрыть</Button>
      </CardFooter>
    </Card>
  );
}

export default EntryCard;
