import React, { useEffect, useState } from 'react';
import { clientSendData } from '../../utils/functions/requests';
import { catchHandler } from '../../utils/error_handling/error_handling';
import { headers } from './common';
import ServiceBody from '../../components/Service/ServiceBody';
import Table from '../../components/Table/Table';
import THead from '../../components/Table/THead';
import TBody from '../../components/Table/TBody';
import TRow from '../../components/Table/TRow';
import TData from '../../components/Table/TData';
import Wrapper from '../../components/Basic/Wrapper';
import CheckMark from '../../components/UI/CheckMark';
import EditorButton from '../../components/UI/Button/EditorButton';
import Input from '../../components/UI/Input';
import TFoot from '../../components/Table/TFoot';
import Cancel from '../../components/UI/Cancel';
import { alertFunction } from '../../utils/functions/alertFunction';
import TextArea from '../../components/UI/TextArea';
import Loader from '../../components/UI/Loader';
import Select from '../../components/UI/Select';
import Toggle from '../../components/UI/Toggle';

function Workplaces() {
  const [workplaces, setWorkplaces] = useState([]);
  const [additionalInfo, setAdditionalInfo] = useState({});
  const [createWorkplace, setCreateWorkplace] = useState(false);
  const [zones, setZones] = useState([]);
  const [editingRow, setEditingRow] = useState(null);
  const [changeData, setChangeData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  function makeWorkplace() {
    setCreateWorkplace(true);
    setChangeData({ data: '{}', change: true });
    setEditingRow(null);
  }

  function cancelCreating(e) {
    e.preventDefault(); // предотвращение действий формы по умолчанию
    setCreateWorkplace(false); // изменение состояния создания группы
    setChangeData({});
  }

  useEffect(() => {
    getWorkplaces();
  }, []);

  async function getWorkplaces() {
    try {
      const reqData = {
        type: 'getAdmWorkplaces',
      };
      setIsLoading(true); // загружается
      const result = await clientSendData('POST', '/get_adm_workplaces', reqData); // запрос в БД action: useState

      if (result) {
        setWorkplaces(result.data); // запись в переменную
        setAdditionalInfo(result.additionalInfo);
        setIsLoading(false);
      }
    } catch (error) {
      catchHandler(error, 'getAdmWorkplaces'); // обработчик ошибок
    } finally { setIsLoading(false); }
  }

  async function editWorkplace(editAction) {
    try {
      if (changeData.change || editAction === 'delete') {
        const validate = () => Object.keys(changeData).every((el) => Boolean(changeData[el]));
        const data = JSON.parse(changeData.data);
        if (validate || editAction === 'delete') {
          setChangeData((state) => ({ ...state, data }));
          const reqData = {
            type: 'editAdmWorkplace',
            action: editAction,
            changeData,
          };
          const result = await clientSendData('POST', '/edit_adm_workplace', reqData); // запрос в БД
          if (result.success) {
            alertFunction('save_settings', 'client');
          }
        }
      }
    } catch (error) {
      alertFunction('something_went_wrong', 'client');
      catchHandler(error, 'editAdmWorkplaceZone'); // обработчик ошибок
    } finally {
      setEditingRow(null);
      setCreateWorkplace(false);
      getWorkplaces();
    }
  }

  function changeWorkplace(workplace) {
    setEditingRow(workplace.id);
    setChangeData(workplace);
  }

  function chooseBlock(block) {
    setZones(additionalInfo.wpZones.filter((el) => el.block === block.title));
    setChangeData((state) => ({ ...state, block_title: block.title }));
  }

  async function switchPort(workplace) {
    try {
      const reqData = {
        type: 'admWorkplacesSwitchPort',
        hardware: workplace.hardware_id,
        port: workplace.port,
        status: workplace.enabled,
      };
      const result = await clientSendData('POST', '/adm_workplaces_switch_port', reqData); // запрос в БД action: useState
      getWorkplaces();
      // if (result) {
      //   setWorkplaces(result.data); // запись в переменную
      //   setAdditionalInfo(result.additionalInfo);
      //   setIsLoading(false);
      // }
    } catch (error) {
      catchHandler(error, 'getAdmWorkplaces'); // обработчик ошибок
    } finally { setIsLoading(false); }
  }

  return (
      <ServiceBody>
      {isLoading ? <Loader />
        : <Table id="workplaces">
              <THead headers={headers.workplaces} title="Рабочие зоны" />
              { /* Тело таблицы */}
              <TBody>
                  {workplaces.map((workplace) => (
                   <TRow key={workplace.id}>
                    <TData>{workplace.place}</TData>
                    {editingRow === workplace.id ? (
                      <>
                        <TData>
                          <Wrapper>
                          <Input
                            defaultValue={changeData?.title}
                            onChange={(e) => setChangeData((state) => ({ ...state, title: e.target.value, change: true }))}
                          />
                          <CheckMark onClick={() => editWorkplace('update')} />
                          <EditorButton onClick={() => editWorkplace('delete')} icon="delete" />
                          </Wrapper>
                        </TData>
                        <TData>
                          <Select
                            id="types-choose"
                            array={additionalInfo.wpTypes}
                            onChoose={(type) => setChangeData((state) => ({ ...state, type_id: type.id, type_title: type.title }))}
                            defaultValue={changeData.type_title}
                          />
                        </TData>
                        <TData>
                          <TextArea
                            defaultValue={changeData.data}
                            onChange={(e) => setChangeData((state) => ({ ...state, data: e.target.value, change: true }))}
                          />
                        </TData>
                        <TData>
                            <Input
                              defaultValue={changeData.port}
                              onChange={(e) => setChangeData((state) => ({ ...state, port: e.target.value, change: true }))}
                            />
                        </TData>
                      </>
                    )
                      : (
                      <>
                        <TData>
                          <span onClick={() => changeWorkplace(workplace)} className="clickable">{workplace.title}</span>
                        </TData>
                        <TData>{workplace.type_title}</TData>
                        <TData>{workplace.data}</TData>
                        <TData>{workplace.port}</TData>
                        <TData>
                          <Toggle
                            state={workplace.enabled}
                            disabled={Boolean(!workplace.enabled)}
                            onClick={() => switchPort(workplace)}
                          />
                          {workplace.enabled === null ? 'нет порта на оборудовании' : ''}
                          {workplace.connected ? 'Подключено' : ''}
                        </TData>
                      </>
                      )}
                   </TRow>
                  ))}
              </TBody>
            { /* Футер */ }
            <TFoot>
              {createWorkplace ? (
                  <TRow>
                      <TData>
                      <Wrapper>
                        <Select
                          id="create-block-choose"
                          array={additionalInfo.wpBlocks}
                          onChoose={(block) => chooseBlock(block)}
                          defaultValue={changeData.block_title}
                        />
                        <Select
                          id="create-zone-choose"
                          array={zones}
                          onChoose={(zone) => setChangeData((state) => ({ ...state, zone_id: zone.id, zone_title: zone.title }))}
                          defaultValue={changeData.zone_title}
                        />
                        <Input placeholder="Введите название места" defaultValue={changeData.title} onChange={(e) => setChangeData((state) => ({ ...state, title: e.target.value }))} />
                        <Select
                          id="create-types-choose"
                          array={additionalInfo.wpTypes}
                          onChoose={(type) => setChangeData((state) => ({ ...state, type_id: type.id, type_title: type.title }))}
                          defaultValue={changeData.type_title}
                        />
                        <Input placeholder="Введите параметры" defaultValue={changeData.data} onChange={(e) => setChangeData((state) => ({ ...state, data: e.target.value }))} />
                        <Input placeholder="Введите порт" defaultValue={changeData.port} onChange={(e) => setChangeData((state) => ({ ...state, port: e.target.value }))} />
                        <CheckMark onClick={() => editWorkplace('create')} />
                        <Cancel onClick={cancelCreating} />
                      </Wrapper>
                      </TData>
                  </TRow>
              ) : (
                  <TRow>
                      <TData onClick={makeWorkplace}>Создать рабочее место</TData>
                  </TRow>
              )}
            </TFoot>
          </Table>}
      </ServiceBody>
  );
}

export default Workplaces;
