// Тип заявки
export const taskTypes = [
  {
    title: 'Заявка на внос/вынос через дебаркайдер',
    id: 1,
  },

  {
    title: 'Заявка на внос/вынос через 3 этаж',
    id: 2,
  },

  {
    title: 'Заказ гостевых пропусков',
    id: 3,
  },

  {
    title: 'Заказ канцтоваров',
    id: 4,
  },

  {
    title: 'Заявка на устранение неисправностей в офисе',
    id: 5,
  },

  {
    title: 'Грузчики (перенос ТМЦ)',
    id: 6,
  },
];

// Тип договора
export const time = [
  {
    title: 'с 10.00 по 12.00',
    id: 3,
  },
  {
    title: 'с 14.00 по 17.00',
    id: 4,
  },
];

// Блоки
export const blocks = [
  {
    title: 'A',
    id: 1,
  },
  {
    title: 'B',
    id: 2,
  },
];

// Канцтовары
export const stationery = [
  {
    title: 'Ручки',
    id: 1,
  },
  {
    title: 'Карандаши',
    id: 2,
  },
  {
    title: 'Папки',
    id: 3,
  },
];

// Устранение неисправностей
export const troubleShooting = [
  {
    title: 'Вентиляция и кондиционирование',
    id: 1,
  },
  {
    title: 'Ремонт офисной мебели',
    id: 2,
  },
  {
    title: 'Освещение',
    id: 3,
  },
  {
    title: 'Аварийная заявка',
    id: 4,
  },
];
